import * as React from 'react';

function SvgHumidity(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#FFF"
          d="M13.5 6.079C12.688 5.019 11.016 3.327 8.486 1 3.495 5.222 1 8.869 1 11.94c0 4.605 3.351 7.484 7.485 7.484 2.963 0 5.525-1.48 6.737-4.014"
        />
        <path
          stroke="#FDFDFE"
          d="M15.106 4.538c2.88 2.505 4.394 4.665 4.394 6.52 0 1.32-.457 2.405-1.217 3.167-.801.804-1.93 1.245-3.177 1.245-1.246 0-2.376-.441-3.177-1.245-.76-.762-1.217-1.847-1.217-3.167 0-1.856 1.515-4.015 4.394-6.52z"
        />
      </g>
    </svg>
  );
}

export default SvgHumidity;
