import { Input, MenuItem, Select, Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import timePickerStyle from "./SingleTimePicker.style";

interface IPickerProps {
  classes: any;
  time?: string;
  desktop?: boolean;
  overWriteCss?: boolean;
  is12Hours?: boolean;
  onSetTime?: any;
  openPicker?: string;
  disabled?: boolean;
}

const TimePicker: React.FC<IPickerProps> = ({
  classes,
  time,
  desktop,
  overWriteCss,
  is12Hours,
  onSetTime,
  openPicker,
  disabled,
  ...props
}) => {
  const [selectedHour, setSelectedHour] = useState<any>(null);
  const [selectedMinutes, setSelectedMinutes] = useState<any>(null);
  const [selectedMidday, setSelectedMidday] = useState<any>();

  const onHourChange = (value: any) => {
    const maxValue = is12Hours ? 12 : 23;
    if (value > maxValue || value < 0 || value.length > 2) {
      return;
    }
    setSelectedHour(value);
    const currentSelectedtime = value.toString().padStart(2, "0") + ":" + selectedMinutes.toString().padStart(2, "0") + selectedMidday;
    onSetTime(openPicker, currentSelectedtime);
  };

  const onMinutesChange = (value: any) => {
    if (value > 59 || value < 0 || value.length > 2) {
      return;
    }
    setSelectedMinutes(value);
    const currentSelectedtime = selectedHour.toString().padStart(2, "0") + ":" + value.toString().padStart(2, "0") + selectedMidday;
    onSetTime(openPicker, currentSelectedtime);
  };

  const onMiddayChange = (value: any) => {
    setSelectedMidday(value);
    const currentSelectedtime = selectedHour.toString().padStart(2, "0") + ":" + selectedMinutes.toString().padStart(2, "0") + value;
    onSetTime(openPicker, currentSelectedtime);
  };

  useEffect(() => {
    if (!time) {
      setSelectedHour(0);
      setSelectedMinutes(0);
      is12Hours ? setSelectedMidday("am") : setSelectedMidday("");
      return;
    }

    const timeArray = time.split(":");
    const minutesAndMidday = timeArray[1];
    let hours = +timeArray[0];
    const minutes = +minutesAndMidday.substr(0, 2);
    let midday = minutesAndMidday.substr(2, 2) || "";
    setSelectedHour(hours);
    setSelectedMinutes(minutes);
    setSelectedMidday(midday);
  }, [time, selectedMidday]);

  return (
    <div className={classes.itemsContainer}>
      <Input
        type="number"
        value={selectedHour ?? ""}
        disableUnderline
        className={clsx(classes.timeInput, { [classes.grayColor]: overWriteCss })}
        name={"hours"}
        onChange={(e) => onHourChange(e.target.value)}
        disabled={disabled}
      />
      <Typography className={clsx(classes.colon, { [classes.grayColor]: overWriteCss })}>:</Typography>
      <Input
        type="number"
        value={selectedMinutes ?? ""}
        disableUnderline
        className={clsx(classes.timeInput, { [classes.grayColor]: overWriteCss })}
        name={"mins"}
        onChange={(e) => onMinutesChange(e.target.value)}
        disabled={disabled}
      />
      {is12Hours && (<Select
        labelId="label"
        id="am/pm"
        value={String(selectedMidday)}
        onChange={(e) => onMiddayChange(e.target.value)}
        className={clsx(classes.middayWrapper, { [classes.middayBlackWrapper]: overWriteCss })}
        inputProps={{
          classes: { icon: clsx(classes.pickerIcon, { [classes.blackPickerIcon]: overWriteCss }) }
        }}
        disabled={disabled}
      >
        <MenuItem value="am">am</MenuItem>
        <MenuItem value="pm">pm</MenuItem>
      </Select>)}
    </div>
  );

};

export default withStyles(timePickerStyle)(TimePicker);
