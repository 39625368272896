import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import { symbols } from "../../constants/sensorsUnitsSymbols";
import { HotMode, Unit } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import { getSensorValue } from "../../services/sensorServices";
import { AutoMode, AutoVRMode, ColdMode, DisconnectIcon, DryIcon, FanMode } from "../../svgComponents";
import useStyles from "./style";

const operationModesIcons: any = {
  COOL: <ColdMode white />,
  HEAT: <HotMode white />,
  AUTO: <AutoMode />,
  "Virtual Auto": <AutoVRMode />,
  DRY: <DryIcon />,
  FAN: <FanMode />
};

const FloorPlanItem = (props: any) => {
  const classes: any = useStyles();
  const {
    unitId,
    x,
    y,
    width = 120,
    height = 65,
    onClick,
    isEdit = false,
    isSelected = false,
    isConfig = false,
    floorPlans = {},
    isReposition = false,
    isMoving = false,
    type = "unit",
    ...restProps
  } = props;

  const allUnits = useStoreState((state) => state.units.allUnits);
  const allGroups = useStoreState((state) => state.groups.allGroups);
  const allSensors = useStoreState((state) => state.sensors.allSensors);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const { types, operationStatusesMirror } = useStoreState((state) => state);
  const { operationStatuses, operationModesExtended, sensorTypes } = types;

  const { on: powerOnVal } = operationStatusesMirror;


  const unit: any = allUnits[unitId] || allGroups[unitId];
  const isGroup = !!allGroups[unitId];
  const floorPlanLink = floorPlans[unitId];
  const sensor = allSensors[unitId];
  const isSensor = !!sensor;
  const isNew = !unitId || (!unit && !floorPlanLink && !sensor);

  const {
    ambientTemperature,
    activeSetpoint,
    activeOperationMode,
    activeOperationStatus,
    name,
    isVisible
  } = unit || {};

  const device = (unit && allDevices[unit.device]) || (sensor && allDevices[sensor.device]);
  const unitSensorDisconnected = (unit && !unit?.isConnected) || (sensor && sensor?.isConnected === false);

  const mode: any = operationModesExtended[activeOperationMode];

  const onUnitsCount = isGroup ?
    unit?.units.filter((id: any) => {
      const unitObj = allUnits[id];
      if (!unitObj) {
        return false;
      }

      const isUnitOn = unitObj?.activeOperationStatus === +powerOnVal;
      const isUnitConnected = unitObj?.isConnected;
      const isDeviceConnected = allDevices[unitObj?.device]?.isConnected;
      return isUnitOn && isUnitConnected && isDeviceConnected;
    }).length
    + unit?.sensors.filter((id: any) => {
      const sensor = allSensors[id];

      return sensor?.type === 129 && sensor?.readingValue === 1 && allDevices[sensor?.device]?.isConnected;
    }).length :
    0;


  const isDisconnected = !floorPlanLink && !isNew && !isGroup && (unitSensorDisconnected || !device?.isConnected);
  const isUnitOn = !isSensor && !isDisconnected && operationStatuses[activeOperationStatus] === "on";
  const isPowerOn = isGroup ? onUnitsCount : isUnitOn
  const isHeat = isPowerOn && mode === "HEAT";
  const isCool = isPowerOn && mode === "COOL";
  const isOther = isPowerOn && !isCool && !isHeat;
  const modeIcon: any = isGroup ?
    <Unit color={"#fff"} size={width / 3} /> :
    (isDisconnected ? <DisconnectIcon /> : (operationModesIcons[mode] || <Unit color={"#fff"} size={width / 3} />));

  if ((unitId && !unit && !sensor && !floorPlanLink) || (unit && !isGroup && !isVisible)) {
    return null;
  }

  const selectionType = type === "sensor" ? "Sensor" : (type === "link" ? "Link" : "Unit/Group");
  const sensorValue = isSensor && getSensorValue(true, types, sensor);

  const sensorType = sensorTypes[sensor?.type] || {};
  const { measurementUnits, enableMeasurementUnitSelection } = sensorType;
  const activeMeasurementUnit = enableMeasurementUnitSelection && sensor?.userData?.measurementUnitsType ? sensor?.userData?.measurementUnitsType : measurementUnits;
  const sensorUnit = symbols[activeMeasurementUnit] || "";


  const groupSetpoint = isGroup && unit?.units?.reduce((data: any, uId: string) => {
    const unit = allUnits[uId] || null
    if (!unit) {
      return data;
    }
    if (data === -1) {
      return Math.ceil(unit.activeSetpoint || 0);
    }

    if (data !== Math.ceil(unit.activeSetpoint || 0)) {
      return null;
    }

    return data;
  }, -1)

  return (
    <div
      className={clsx(
        classes.container,
        isNew && classes.isNewUnit,
        isDisconnected && classes.disconnected,
        isHeat && classes.isHeat,
        isCool && classes.isCool,
        isOther && classes.isOther,
        isEdit && classes.isEdit,
        isSelected && classes.isSelected
      )}
      style={{
        zIndex: 200,
        left: `${x}%`,
        top: `${y}%`,
        position: "absolute",
        width,
        height,
        boxSizing: "content-box",
        cursor: isEdit && isReposition && !isMoving ? "grab" : (isEdit && isReposition && isMoving ? "grabbing" : "pointer")
      }}
      onClick={(e: any) => onClick(e, isDisconnected)}
      {...restProps}
    >

      {isNew ?
        <Typography style={{ color: "#fff", fontSize: "14px" }} align="center">{isConfig ? t`Select ${selectionType}` : t`Drag and click to position ${selectionType}`}</Typography> :
        <div
          style={{
            zIndex: 200,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: !!floorPlanLink ? "row" : "column",
            justifyContent: !!floorPlanLink ? "space-evenly" : "center",
            alignItems: "center"
          }}
        >
          {!!floorPlanLink ?
            <Typography
              align="center"
              className={classes.linkText}
            >
              {floorPlanLink?.name}
            </Typography> :
            (
              isSensor ?
                <>
                  <Typography align="center" style={{
                    fontSize: `16px`,
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%"
                  }}>{sensor.name}</Typography>
                  <div className={classes.infoSection} style={{ justifyContent: "center" }}>
                    <span
                      className={classes.unitTemp}
                      style={{
                        fontSize: `16px`
                      }}
                    >
                      {`${sensorValue} ${sensorUnit}`}
                    </span>
                  </div>
                </>
                :
                <>
                  <Typography align="center" style={{
                    fontSize: `16px`,
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%"
                  }}>{name}</Typography>
                  <div className={classes.infoSection}>
                    <div className={classes.iconContainer}>
                      {modeIcon}
                    </div>
                    <div className={classes.infoContainer}>
                      {isGroup ?
                        <span
                          className={classes.unitTemp}
                          style={{
                            fontSize: `16px`
                          }}
                        >
                          {isPowerOn ? `${Math.round(groupSetpoint) || "--"}°` : t`OFF`}
                        </span> :
                        <span
                          className={classes.unitTemp}
                          style={{
                            fontSize: `16px`
                          }}
                        >
                          {isPowerOn || isNew ? `${Math.round(activeSetpoint as any) || "--"}°` : t`OFF`}
                        </span>
                      }
                      {!isGroup && <span
                        className={classes.roomTemp}
                        style={{ fontSize: `13px` }}
                      >
                        {t`Now`}{`: ${ambientTemperature || "--"}°`}
                      </span>}
                    </div>
                  </div>
                </>
            )
          }
        </div>}
    </div>
  );
};

export default FloorPlanItem;
