import { Button, Card, IconButton, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { Note, Settings as SvgSettings } from "../../icons/";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";
import { IUnit } from "../../models/Units";
import useStyles from "./UnitInfo.style";

interface IProps {
  unit: IUnit;
  site: ISite;
  device: IDevice;
  system: ISystem | null | undefined;
  setNotesAnch: any;
  selectedSysId: string | null;
  unitNotes: any;
  setOpenAlthermaSettings: (open: boolean) => void;
  brandNum: number;
}

//key is brand number, values is min req. version
const unitSettingsOptions: any = {
  10: 5,
  17: 5,
  7: 9

};

export default function UnitInfo(props: IProps) {
  const classes = useStyles();
  const {
    setNotesAnch,
    selectedSysId,
    setOpenAlthermaSettings,
    unitNotes = {},
    brandNum = -1
  } = props;
  const {
    id,
    serialNumber,
    privateId,
    capacity,
    capacityMeasurementUnits,
    airnet,
    model,
    type,
    permissions
  } = props.unit;


  const { canReadUnitConfigParams } = permissions;

  const siteName = props.site.name;
  const hvacBrands = useStoreState((state) => state.types.hvacBrands);
  const brandName =
    props.system && !_.isNil(_.find(hvacBrands, { value: props.system.brandNum }))
      ? _.find(hvacBrands, { value: props.system.brandNum }).name
      : null;
  const brand = brandName ? brandName : "--";
  const capacityMeasurementUnitTypes = useStoreState(
    (state) => state.types.capacityMeasurementUnitTypes
  );
  const unitTypesMirrror = useStoreState((state) => state.unitTypesMirrror);
  const { protocolVersion: deviceVersion } = props.device;
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const unitName = getUnitName(id, true);

  return (
    <Card className={classes.card}>
      <div className={classes.unitInfoHeader}>
        <Typography className={classes.unitInfoTitle}>{`${unitTypesMirrror[type]?.toUpperCase() === "SERVICE" ? "Indoor" : (unitTypesMirrror[type]?.toUpperCase() === "BSBOX" ? "Bs Box" : unitTypesMirrror[type])}` + t` Unit Information`}</Typography>
        <IconButton className={classes.attButton} onClick={(e: any) => setNotesAnch(e.currentTarget)}>
          <Note />
          {!!selectedSysId && !!unitNotes && !!Object.values(unitNotes).length && <span className={classes.badge}>{Object.values(unitNotes).length}</span>}
        </IconButton>
        {!props.device.isConnected &&
          <Typography className={classes.unitInfoDisconnectedText}>{t`- Unit device is disconnected`}</Typography>
        }
      </div>
      <div className={classes.itemsWrapper}>
        <div className={clsx(classes.item, classes.firstItem)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{unitName}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ minWidth: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit SN`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ minWidth: "12ch" }}>{serialNumber}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Brand`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "17ch" }}>{brand}</Typography>
        </div>
        <div className={classes.item} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Site Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{siteName}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`ID`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "7ch" }}> {privateId}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ minWidth: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Model`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ minWidth: "12ch" }}> {model}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
          <Typography className={classes.infoTitle}>{t`Capacity`}</Typography>
          {capacity !== 0 && (
            <div>
              <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>
                {`${capacity} ` || ""}
                {capacityMeasurementUnits &&
                  _.invert(capacityMeasurementUnitTypes)[capacityMeasurementUnits]}
              </Typography>
            </div>
          )}
        </div>
        {unitSettingsOptions.hasOwnProperty(brandNum) &&
          <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ borderLeft: "none" }}>
            <Tooltip title={deviceVersion < unitSettingsOptions[brandNum] ? t`the device version does not support this feature` : ""} arrow>
              <Button
                className={classes.althermaSettings}
                onClick={() => setOpenAlthermaSettings(true)}
                disabled={(deviceVersion < unitSettingsOptions[brandNum]) || !canReadUnitConfigParams}
              >
                <div className={classes.settingsIcon}>
                  <SvgSettings />
                </div>
                {t`Settings`}
              </Button>
            </Tooltip>
          </div>
        }
        {airnet && airnet !== 0 ? (
          <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
            <Typography className={classes.infoTitle}>{t`Airnet: `}</Typography>
            <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>{airnet}</Typography>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
}
