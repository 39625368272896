import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Slide,
  SlideProps,
  Typography
} from "@material-ui/core";
import { Edit, OpenWith } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab/";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { UnitPanel } from "../Control/ControlComponents";
import useStyles from "./floorPlan.style";

const Transition: any = React.forwardRef<any, SlideProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FloorPlan: React.FC = (props: any) => {
  const classes = useStyles();
  const [canvUnits, setCanUnits] = useState<any>([]);
  const [newAc, setNewAc] = useState<any>(null);
  const [newAcAnch, setNewAcAnch] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<"unit" | "group">("unit");
  const [controlItem, setControlItem] = useState<any>(null);
  const [selectUnitError, setError] = useState<boolean>(false);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allGroups = useStoreState((state) => state.groups.allGroups);
  const { siteId } = useStoreState((state) => state.selections.selections);
  const {
    unitTypes,
    operationStatuses,
    fanModes,
    operationModesExtended,
    swingModes,
    unitSubTypes,
    sensorTypes,
    sensorMeasurementUnits
  } = useStoreState((state) => state.types);
  const updateSelections = useStoreActions((actions) => actions.selections.updateSelections);
  const { getTemperatureScaleDisplay } = useStoreState((s) => s.users);
  const setActiveSetpoint = useStoreActions((state) => state.units.setActiveSetpoint);

  const setActiveFanMode = useStoreActions(
    (state) => state.units.setUnitActiveFanMode
  );

  const setActiveSwingMode = useStoreActions(
    (state) => state.units.setUnitActiveSwingMode
  );
  const setActiveOperationMode = useStoreActions(
    (state) => state.units.setActiveOperationMode
  );
  const setActiveOperationStatus = useStoreActions(
    (state) => state.units.changePowerState
  );

  const user = useStoreState((state) => state.users.me);

  const { temperatureScale = 0 } = user;

  const {
    fanModesMirror,
    operationStatusesMirror,
    operationModesMirror,
    swingModesMirror,
    temperatureScaleMirror
  } = useStoreState((state) => state);

  const params: any = useParams();
  const history: any = useHistory();

  useEffect(() => {
    if (!newAc) {
      setNewAcAnch(null);
    }
    const anch = document.getElementById("newAcImgId");
    setNewAcAnch(anch);
  }, [newAc]);

  // useEffect(() => {
  //     setSelectedItem("");
  // }, [selectedType]);

  useEffect(() => {
    if (!siteId && params.site) {
      updateSelections({ data: params.site, type: "site" });
      return;
    }

    if (!!siteId && siteId !== params.site) {
      history.push(`/floor-plan/${siteId}`);
    }

  }, [siteId, params.site]);

  const saveNewAc = () => {
    if (!selectedItem) {
      setError(true);
      return;
    }
    setCanUnits([...canvUnits, { ...newAc, itemId: selectedItem, type: selectedType }]);
    if (controlItem) {
      setControlItem({ ...controlItem, isEdit: false });
    }
    cancelNewAc();
  };

  const cancelNewAc = () => {
    if (controlItem?.isEdit) {
      setCanUnits([...canvUnits, { ...controlItem, isEdit: false }]);
      setControlItem({ ...controlItem, isEdit: false });

    }
    setNewAc(null);
    setSelectedItem("");
    setSelectedType("unit");
  };

  const changeType = (type: any) => {
    setSelectedType(type);
    setSelectedItem("");
  };

  const startDrag = (event: any) => {
    setControlItem({ ...controlItem, drag: true });
    setCanUnits(canvUnits.map((unit: any) => {
      if (controlItem.itemId === unit.itemId) {
        return { ...unit, drag: true };
      }
      return unit;
    }));
  };

  const dragItem = (event: any) => {
    let bounds = event.target.getBoundingClientRect();
    let x = event.clientX - bounds.left;
    let y = event.clientY - bounds.top;

    setCanUnits(canvUnits.map((unit: any) => {
      if (unit.drag) {
        return { ...unit, x, y };
      }
      return unit;
    }));
  };

  const stopDrag = () => {
    setControlItem({ ...controlItem, drag: false });
    setCanUnits(canvUnits.map((unit: any) => {
      if (unit.drag) {
        return { ...unit, drag: false };
      }
      return unit;
    }));
  };

  const editItem = () => {
    setSelectedType(controlItem.type);
    setSelectedItem(controlItem.itemId);
    setNewAc(controlItem);
    setControlItem({ ...controlItem, isEdit: true });
    setCanUnits(canvUnits.filter((unit: any) => unit.itemId !== controlItem.itemId));
  };

  const addAc = (event: any) => {

    if (!newAc) {
      let bounds = event.target.getBoundingClientRect();
      let x = event.clientX - bounds.left;
      let y = event.clientY - bounds.top;

      setNewAc({ x, y, unitId: null });
      setNewAcAnch(event.currentTarget);
    }
  };

  const itemsOptions: any = selectedType === "unit" ?
    _.orderBy(Object.values(allUnits), "name", "asc").map((item: any) => {
      if (selectedType === "unit" && (item.site !== siteId || +item.type !== +unitTypes.indoor)) {
        return;
      }
      return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
    }) :
    _.orderBy(Object.values(allGroups), "name", "asc").map((item: any) => {
      if (!item) {
        return null;
      }
      return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
    });

  const isCelsius = +temperatureScaleMirror.celsius === +temperatureScale;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideUnitSelection hideSystemSelection
          customGeneralNames={{ site: t`Select Site` }}
        />
        <div className={classes.page}>

          {!!siteId ?
            <div className={classes.page}
              onMouseMove={dragItem}
              onMouseUp={stopDrag}

            >
              <img src={"https://home3ds.com/wp-content/uploads/2018/11/PNG.png"}
                className={classes.planImg}
                onClick={addAc}
              />
              {
                newAc &&
                <img
                  id={"newAcImgId"}
                  style={{
                    position: "absolute",
                    left: newAc.x,
                    top: newAc.y,
                    width: "60px",
                    height: selectedType === "unit" ? "30px" : "60px",
                    zIndex: 100
                  }}
                  src={selectedType === "unit" ?
                    "https://uxwing.com/wp-content/themes/uxwing/download/11-household-and-furniture/air-conditioner.png" :
                    "https://cdn4.iconfinder.com/data/icons/heat-and-cooling-line/75/Heal_and_Cooling-09-512.png"
                  }
                />
              }
              {
                canvUnits.map((value: any, index: any) => {
                  const unitObject: any = value.type === "unit" && allUnits[value.itemId];
                  const isOn = +unitObject?.activeOperationStatus === 1;
                  const isHeat = +unitObject?.activeOperationMode === 1;
                  const isCool = +unitObject?.activeOperationMode === 0;
                  return (
                    <div
                      key={`ac-cnav-${index}`}
                      style={{
                        position: "absolute",
                        left: value.x,
                        top: value.y,
                        width: "60px",
                        height: "30px",
                        zIndex: 100
                      }}
                      className={clsx(
                        unitObject && isOn && isHeat && classes.heat,
                        unitObject && isOn && isCool && classes.cool,
                        unitObject && isOn && !isHeat && !isCool && classes.other
                      )}

                    >
                      <img
                        onClick={() => setControlItem(value)}
                        style={{
                          width: "100%"
                        }}
                        src={value.type === "unit" ?
                          "https://uxwing.com/wp-content/themes/uxwing/download/11-household-and-furniture/air-conditioner.png" :
                          "https://cdn4.iconfinder.com/data/icons/heat-and-cooling-line/75/Heal_and_Cooling-09-512.png"
                        }
                      />
                      <Typography>{value.type === "unit" ? unitObject.name : allGroups[value.itemId]?.name}</Typography>
                    </div>
                  );
                })
              }
            </div> :
            <FilterRequire type={t`site`} />
          }

        </div>
      </div>

      <Popover
        open={!!newAc}
        anchorEl={newAcAnch}
        onClose={cancelNewAc}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        classes={{
          paper: classes.popover
        }}
      >

        <ToggleButtonGroup
          value={selectedType}
          exclusive
          onChange={(e: any, type: any) => changeType(type)}
          aria-label="text alignment"
        >
          <ToggleButton value="unit" aria-label="left aligned">
            <Typography>{t`Unit`}</Typography>
          </ToggleButton>
          <ToggleButton value="group" aria-label="centered">
            <Typography>{t`Group`}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>

        <Select
          value={selectedItem}
          error={selectUnitError}
          onChange={(e: any) => setSelectedItem(e.target.value)}
          fullWidth
        >
          {[...itemsOptions]}
        </Select>

        <Button onClick={saveNewAc}>Save</Button>
      </Popover>

      {controlItem &&
        <Dialog
          open={controlItem?.itemId && !controlItem?.drag && !controlItem?.isEdit}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setControlItem(null)}
          maxWidth={"sm"}
          fullWidth
        >
          <DialogTitle>{`Control for ${controlItem?.type} ${controlItem?.type === "unit" ? allUnits[controlItem.itemId]?.name : allGroups[controlItem.itemId]?.name}`}</DialogTitle>
          <DialogContent style={{
            backgroundColor: "#29132e"
          }}>

            {
              controlItem?.type === "unit" ?
                <UnitPanel
                  tempSymbol={getTemperatureScaleDisplay()}
                  allUnits={allUnits}
                  groupId={null}
                  siteTemp={{}}
                  types={{
                    operationStatuses,
                    fanModes,
                    operationModesExtended,
                    swingModes,
                    unitTypes,
                    unitSubTypes
                  }}
                  unit={allUnits[controlItem.itemId]}
                  setActiveSetpoint={setActiveSetpoint}
                  setActiveOperationMode={setActiveOperationMode}
                  setActiveFanMode={setActiveFanMode}
                  setActiveSwingMode={setActiveSwingMode}
                  setActiveOperationStatus={setActiveOperationStatus}
                  operationModesMirror={operationModesMirror}
                  fanModesMirror={fanModesMirror}
                  swingModesMirror={swingModesMirror}
                  operationStatusesMirror={operationStatusesMirror}
                  temperatureScale={temperatureScale}
                  isCelsius={isCelsius}
                  navigateToSchedules={() => { }}
                  addMessage={() => { }}
                /> :
                <Typography>Group control is comming soon</Typography>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setControlItem(null)}>Close</Button>
            <IconButton onClick={startDrag}><OpenWith /></IconButton>
            <IconButton onClick={editItem}><Edit /></IconButton>
          </DialogActions>
        </Dialog>}
    </div>
  );

};

export default FloorPlan;
