import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  siteCard: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%"
  },
  column1Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "101px",
    justifyContent: "flex-end",
    marginRight: 10,
    alignItems: "center",
    cursor: "pointer"
  },
  indoorUnitsText: {
    fontFamily: "Roboto",
    fontSize: "13px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    flexDirection: "row",
    whiteSpace: "pre-line",
    width: "120px",
    marginTop: "10px"
  },
  column2Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "80px",
    justifyContent: "center"
  },
  modesCount: {
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    display: "flex",
    alignItems: "center",
    height: "32px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  modesIcons: { width: 18, height: 21, marginRight: 7 },
  indoorUnitsCountStyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    top: "66px",
    height: "14px",
    fontFamily: "Roboto",
    fontSize: "12px",
    letterSpacing: "normal",
    color: "#29132e"
  },
  onIndoorCountStyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    height: "40px",
    top: "22px",
    fontFamily: "Roboto",
    fontSize: "34px",
    letterSpacing: "normal",
    color: "#545964"
  },
  relativeContainer: {
    position: "relative",
    minWidth: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    minWidth: "170px"
  },
  row: {
    textAlign: "center",
    display: "flex"
  },
  powerRow: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  titlePower: {
    marginTop: "10px",
    maxWidth: "104px"
  },
  powerWidth: {
    minWidth: "104px"
  },
  powerInidcator: {
    fontFamily: "Roboto",
    fontSize: "9px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    position: "absolute",
    bottom: "-2px",
    left: "-7px"
  },
  rightPowerIndicator: {
    bottom: "-2px",
    right: "-18px",
    left: "unset"
  },
  dash: {
    background: "#fff",
    width: "1px",
    height: "10%"
  },
  dashDegree: {
    position: "absolute",
    height: "100%"
  },
  powerText: {
    marginTop: -5,
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
  },
  powerKW: {
    marginLeft: "1px", fontSize: "12px"
  },
  edges: {
    background: "#979797",
    height: "18px",
    marginTop: "-14px",
    width: "2px"
  },

  dialogPaper: {
    width: "fit-content",
    maxWidth: "95% !important",
    minWidth: "500px",
    minHeight: "485px",
    height: "fit-content",
    maxHeight: "calc(100vh - 140px)",
    borderRadius: "8px",
    justifyContent: "space-between",
    overflowy: "auto"
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between"
  },
  loaderHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  workingUnitsTitle: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize"
    },
    paddingBottom: "0px"
  },
  tableContainer: {
    display: "flex"
  },
  table: {
    background: theme.palette.background.paper
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  overWritePadding: {
    ...theme.tables.tableCell,
    padding: "10px 15px"
  },
  overWritePaddingRow: {
    fontSize: "0.8rem",
    padding: "0 15px"
  },
  tableCell: {
    ...theme.tables.tableCell
  },
  actionsHolder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    padding: "20px",
    paddingTop: "0px"
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  currentMaxContainer: {
    height: "100%",
    position: "absolute"
  },
  redCurrentMax: {
    background: "red",
    width: "2px",
    height: "18px",
    marginTop: "-4px"
  },
  cardHeader: {
    background: theme.palette.background.default,
    height: "30px",
    alignItems: "center",
    display: "flex",
    padding: "0px 10px",
    margin: "0 5px 15px 5px"
  },
  cardTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  tooltipTitle: {
    "&>p": {
      fontSize: "12px"
    }
  },
  reportsHeader: {
    width: "100%",
    height: "70px",
    paddingTop: "30px",
    paddingLeft: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start"
  },
  siteName: {
    color: "#79797d",
    fontSize: "14px",
    fontWeight: "bold",
    width: "140px",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },
  headerInfoContainer: {
    display: "flex",
    width: "350px",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  headerInfoSection: {
    display: "flex"

  },
  headerInfoVal: {
    fontSize: "13px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    marginRight: "6px"

  },
  headerInfoTitle: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#79797d"
  },
  headerInfoDivider: {
    width: "1px",
    height: "20px",
    backgroundColor: "#d5d2d5"
  },
  noteStyle: {
    marginTop: "20px"
  },
  boldFont: {
    fontWeight: "bold"
  },
  addMarginBottom: {
    marginBottom: "5px"
  },
  switchDisabled: {
    opacity: 0.7,
    "& span": {
      cursor: "default"
    }
  }
}));

export default useStyles;
