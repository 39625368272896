import { FilledInputProps, FormControl, makeStyles, TextField } from "@material-ui/core";
import { FilledTextFieldProps } from "@material-ui/core/TextField";
import clsx from "clsx";
import { FieldProps } from "formik";
import React from "react";

const useStyles = makeStyles((theme) => ({
  input: {
    "& label": {
      color: `${theme.palette.primary.light} !important`
    },
    "& .Mui-focused": {
      color: `${theme.palette.primary.light} !important`
    },
    "& div": {
      // backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    "& .MuiFilledInput-input": {
      color: theme.palette.primary.dark,
      fontSize: "20px"
    },
    "& .MuiFormLabel-filled": {
      color: theme.palette.primary.dark
    },
    "& :-webkit-autofill": {
      "-webkit-text-fill-color": `${theme.palette.primary.dark} !important`,
      "-webkit-box-shadow": "0 0 0 30px #ddd inset !important"
    },
    "& .MuiFilledInput-underline:before": {
      "border-bottom": "none",
      content: "none"
    },
    "& .MuiFilledInput-underline:after": {
      "border-bottom": "none",
      content: "none"
    },
    "& .MuiFilledInput-input:-webkit-autofill": {
      color: `${theme.palette.primary.dark} !important`,
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    "&:-internal-autofill-selected": {
      color: `${theme.palette.primary.dark} !important`
    }
  },
  margin: {
    "margin-top": "0.5rem",
    "margin-bottom": "0.5rem"
  }
}));
const InputField: React.FC<FieldProps & FilledTextFieldProps & FilledInputProps> = ({
  field,
  meta,
  form,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.margin} variant="filled">
      <TextField
        error={props.error}
        helperText={props.helperText}
        {...field}
        {...props}
        margin="dense"
        variant="filled"
        className={className || classes.input}
      />
    </FormControl>
  );
};

export default InputField;
