import * as React from 'react';

function AppLogic(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink"  {...props}>
      <defs>
        <path d="M.548.509c-.32.301-.5.705-.539 1.211L0 1.916h1.288c.005-.251.074-.448.206-.591a.679.679 0 0 1 .525-.214c.463 0 .693.266.693.795 0 .176-.044.343-.133.501-.089.158-.267.364-.535.617-.268.252-.452.508-.553.769-.084.216-.132.495-.146.836l-.004.213h1.137l.017-.288c.028-.255.121-.485.281-.686l.105-.118.36-.362c.28-.289.478-.552.59-.788.113-.237.168-.488.168-.755 0-.586-.172-1.041-.519-1.362C3.134.162 2.648 0 2.019 0 1.398 0 .908.17.548.509zm.866 5.257a.71.71 0 0 0-.203.52c0 .207.067.378.198.512a.697.697 0 0 0 .522.203c.216 0 .39-.068.522-.203a.699.699 0 0 0 .197-.512.713.713 0 0 0-.201-.52.7.7 0 0 0-.518-.204.697.697 0 0 0-.517.204z" id="a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="m17.865 5.281-5.763 6.143a1.004 1.004 0 0 0 0 1.357l5.763 6.142a.862.862 0 0 0 1.274 0l5.763-6.142a1.004 1.004 0 0 0 0-1.357L19.14 5.281a.862.862 0 0 0-1.274 0z" stroke="#A9A1A9" strokeWidth=".96" />
        <g transform="translate(16.67 8.602)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <path fill="#A9A1A9" mask="url(#b)" d="M-1 8.001h6v-9h-6z" />
        </g>
        <g stroke="#A9A1A9" strokeWidth=".96">
          <path d="M3.96 22.104h7.08c.53 0 .96.43.96.96v7.08c0 .53-.43.96-.96.96H3.96a.96.96 0 0 1-.96-.96v-7.08c0-.53.43-.96.96-.96zM25.686 22.104h7.08c.53 0 .96.43.96.96v7.08c0 .53-.43.96-.96.96h-7.08a.96.96 0 0 1-.96-.96v-7.08c0-.53.43-.96.96-.96zM11.667 12.104h-2.08a1.92 1.92 0 0 0-1.92 1.92v7.985" />
          <path d="M25.212 12.104h2.08c1.06 0 1.92.86 1.92 1.92v8.207" />
        </g>
      </g>
    </svg>
  );
}

export default AppLogic;
