import React from 'react';

function SvgHome(props) {
  return (
    <svg width={25} height={26} {...props}>
      <g fill="none" fillRule="evenodd">
        {/*<rect width={30} height={30} fill="#EFEFF2" rx={4} />*/}
        <path
          d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"
          stroke="#C9C4C9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.8}
        />
      </g>
    </svg>
  );
}

export default SvgHome;
