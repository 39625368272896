import * as React from 'react';

function SvgIconSwitchOff(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 45 25"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="icon-switch-off_svg__a" d="M.8.769H25V24H.8z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M36.213 4.5C40.513 4.5 44 7.858 44 12c0 4.142-3.486 7.5-7.787 7.5H13.788C9.486 19.5 6 16.142 6 12c0-4.142 3.486-7.5 7.788-7.5h22.425z"
          fillOpacity={0.6}
          fill="#D5D2D5"
        />
        <g transform="rotate(-180 12.5 12.25)">
          <mask id="icon-switch-off_svg__b" fill="#fff">
            <use xlinkHref="#icon-switch-off_svg__a" />
          </mask>
          <path
            d="M25 12.384C25 18.8 19.583 24 12.9 24 6.218 24 .8 18.8.8 12.384.8 5.97 6.219.768 12.9.768c6.683 0 12.1 5.2 12.1 11.616"
            fill="#D5D2D5"
            mask="url(#icon-switch-off_svg__b)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconSwitchOff;
