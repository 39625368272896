import { IconButton, Popover, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { modeIcons } from "../../constants/modes";
import assetsService from "../../services/assetsService";
import useStyles from "./ModeMenu.style";

const defaultKeys = {modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: {0: "activeSetpoint", 1: "activeSetpoint", 2: "activeSetpoint", 3: "activeSetpoint", 5: "activeSetpoint"}};
const keysBySubType: any = {
  0: defaultKeys,
  1: {modeKey: "heaterMode", powerKey: "heaterTankONOFF", ambientTempKey: "heaterRoomTemp", setpointKeyPerMode: {0: "heaterCoolingSP", 1: "heaterHeatingSP", 2: "", 3: "", 5: ""}},
  2: defaultKeys
};
const waterHeaterModesToNormalMap: any = {0: 2, 1: 1, 2: 0};

export default function ModeMenu(props: any) {
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { onClick, unit, supportedModes, serviceTypes } = props;
  const {subType = 0} = unit;
  const [unitMode, setUnitMode] = React.useState<number>(0);

  const { modeKey } = keysBySubType[subType] || defaultKeys;
  const mappedMode = unit[modeKey];
  const mode = subType === 1 ? waterHeaterModesToNormalMap[mappedMode] : mappedMode;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setUnitMode(mode);
  }, [mode]);

  return (
    <React.Fragment>
      <div className={classes.controlContainer}>
        <IconButton disabled={props.disabled} onClick={handleClick} className={classes.selectedButton}>
          {modeIcons[unitMode] ? (
            <img src={assetsService.getImagesPath(`${modeIcons[unitMode]}.svg`)}></img>
          ) : (
              <div>{serviceTypes.operationModesExtended[unitMode]?.charAt(0) || unitMode}</div>
            )}
        </IconButton>
        {<span>{serviceTypes.operationModesExtended[unitMode] || ""}</span> }
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{ className: classes.menu }}
      >
        {supportedModes.map((mode: number) => (
          <div key={mode} onClick={handleClose}>
            <div className={classes.controlContainer}>
              <IconButton onClick={() => onClick(mode)} className={classes.button}>
                {modeIcons[mode] ?
                <img
                  className={classes.icon}
                  src={assetsService.getImagesPath(`${modeIcons[mode]}.svg`)}
                ></img> :
                <Typography>{serviceTypes.operationModesExtended[mode] || ""}</Typography>
              }
              </IconButton>
            </div>
          </div>
        ))}
      </Popover>
    </React.Fragment>
  );
}
