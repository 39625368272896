import { alpha, createStyles } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "100%"
    },
    grid: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      flexWrap: "nowrap",
      maxWidth: "768px"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "5px 60px",
      boxSizing: "border-box",
      marginBottom: "15px"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    pageTitle: {
      fontFamily: "Roboto",
      fontSize: "30px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.primary.dark,
      textAlign: "center"
    },
    deviceImgStyle: {
      alignSelf: "center",
      maxWidth: "202px",
      maxHeight: "114px",
      position: "absolute"
    },
    deviceImageShadow: {
      position: "absolute",
      width: "206px",
      height: "206px",
      opacity: 0.19,
      WebkitFilter: "blur(36px)",
      filter: "blur(36px)"
    },
    deviceImageContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "206px",
      marginTop: "-10px",
      marginBottom: "-10px",
      position: "relative"
    },
    subTitle: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.primary.dark
    },
    indoorUnitsContainer: {
      minHeight: "76px",
      width: "100%",
      justifyContent: "space-between",
      padding: "0px 25px",
      alignItems: "center",
      flexFlow: "row nowrap",
      marginBottom: "15px"
    },
    indoorTypo: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.primary.dark
    },
    indoornumber: {
      width: "89px",
      height: "44px",
      borderRadius: "5px",
      "& div": { height: "100%" },
      "& input": {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        opacity: "0.5 !important"
      }
    },
    systemFields: {
      width: "100%",
      height: "44px",
      borderRadius: "6px",
      "& div": { height: "100%" },
      "& input": {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        opacity: "0.5 !important",
        paddingLeft: "10px"
      }
    },
    systemcontainer: {
      height: "195px",
      flexFlow: "column nowrap",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0px"
    },
    progressBar: {
      width: "240px"
    },
    systemRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "ceneter",
      marginBottom: "15px"
    },
    fieldWrapper: { width: "45%" },
    labelStyle: {
      width: "100%",
      marginBottom: "6px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      color: theme.palette.primary.dark,
      letterSpacing: "normal"
    },
    nextBtn: {
      margin: "20px auto",
      width: "95%",
      minHeight: "53px"
    },
    scrolledSection: {
      overflow: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    connectingText: {
      fontSize: "19px",
      fontWeight: "normal",
      lineHeight: "normal",
      color: theme.palette.primary.dark,
      marginBottom: "20px"
    },
    subconnectingText: {
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "normal",
      color: theme.palette.primary.dark,
      marginBottom: "20px"
    }
  });
