import React from "react";

const SvgGroup5 = (props) => (
  <svg width={34} height={34} {...props}>
    <path
      d="M16 2.413c0-.907-.771-1.547-1.765-1.389-3.2.747-5.735 3.576-5.735 6.94 0 3.364 2.372 6.194 5.735 6.94.938.16 1.765-.48 1.765-1.389V2.413zm2 29.174c0 .907.771 1.547 1.765 1.389 3.2-.747 5.735-3.576 5.735-6.939 0-3.415-2.372-6.192-5.735-6.939-.938-.162-1.765.426-1.765 1.387v11.102zM31.587 16c.907 0 1.547-.771 1.389-1.765-.747-3.2-3.576-5.735-6.94-5.735-3.364 0-6.194 2.372-6.94 5.735-.16.938.48 1.765 1.389 1.765h11.102zM2.413 18c-.907 0-1.547.771-1.389 1.765.747 3.2 3.576 5.735 6.94 5.735 3.364 0 6.194-2.372 6.94-5.735.16-.938-.48-1.765-1.389-1.765H2.413z"
      fill="none"
      fillRule="evenodd"
      stroke={props.maincolor || "#FDFDFE"}
      strokeWidth={1.6}
      opacity={props.opacity}
    />
  </svg>
);

export default SvgGroup5;
