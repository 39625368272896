import React from "react";

function MobileWRC(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="27.4"
          height="20.4"
          x="3.3"
          y="6.8"
          stroke="#AAA2AA"
          strokeWidth="1.6"
          rx="3"
        ></rect>
        <text
          fill="#AAA2AA"
          fontFamily="Roboto-Bold, Roboto"
          fontSize="8"
          fontWeight="bold"
        >
          <tspan x="8.832" y="20">
            70
          </tspan>
        </text>
        <rect
          width="13.765"
          height="12.63"
          x="6.718"
          y="10.685"
          stroke="#AAA2AA"
          strokeWidth="1.4"
          rx="1"
        ></rect>
        <path
          stroke="#AAA2AA"
          strokeLinecap="round"
          strokeWidth="1.6"
          d="M23.835 13.262l1.475-1.476a.3.3 0 01.425 0l1.475 1.476h0m-3.375 7.348l1.475 1.476a.3.3 0 00.425 0l1.475-1.476h0"
        ></path>
      </g>
    </svg>
  );
}

export default MobileWRC;