import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import ErrorBox from "../ErrorBox/ErrorBox";
import useStyles from "./DeleteDialog.style";

/**
 * @param open:boolean - true mean show the dialog (required)
 * @param detach - callback on click agree [you can return error] (required)
 * @param onClose - callback on close the dialog (required)
 * @param object:{id, name?, serial?, firstName?, lastName?} -  (required)
 * @param type:string - will be in header like Delete + [type] (optional)
 * @param headerTitle:string - object to delete (optional)
 * @param message:string - object to delete (optional)
 */
export default function DeleteDialog(props: any) {
  const [error, setError] = useState<any>(null);
  const { object: objectToDelete, onClose, type = "", detach, open, message, headerTitle } = props;
  const classes = useStyles();
  const fullName = !!objectToDelete?.name ? `${objectToDelete?.firstName} ${objectToDelete?.lastName}` : type;
  const itemName = objectToDelete?.name || objectToDelete?.serial || fullName;

  const handleClose = (event: any) => {
    event.stopPropagation();
    onClose();
  };

  const handleAgree = async (event: any) => {
    event.stopPropagation();
    const { id } = objectToDelete;
    const result = await detach(id);
    setError(result);
    !result && onClose();
  };

  if (!!error) {
    return <ErrorBox
      error={error}
      onClose={() => {
        setError(null);
        onClose();
      }}
    />;
  }
  return (
    <>
      {open && <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{headerTitle || (t`Delete ` + type)}</Typography>
          <IconButton disableRipple onClick={(event: any) => { onClose(); event.stopPropagation(); }} className={classes.overWriteIcon}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.DialogContentText}>
            {message || (t`Are you sure you want to delete ` + `${itemName}  ?`)}
          </Typography>
        </div>
        <div className={classes.dialogActions}>
          <Button
            onClick={handleAgree}
            className={classes.dialogButton}
            white
          >
            {t`Yes`}
          </Button>

          <Button
            className={classes.dialogButton}
            autoFocus={true}
            onClick={handleClose}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>}
    </>
  );
}
