import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexWrap: "wrap"
    },
    modeStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px",
      borderRadius: "6px",
      backgroundColor: `#e1dfe1`,
      marginRight: 15,
      marginTop: 10,
      width: "70px",
      height: "60px",
      "& *": {
        color: "white",
        fontSize: "20px"
      },
      marginBottom: 0
    },
    iconsWidth: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px",
      borderRadius: "6px",
      backgroundColor: `#e1dfe1`,
      marginRight: 15,
      marginTop: 10,
      minWidth: "70px",
      height: "60px",
      "& *": {
        color: "white",
        fontSize: "20px"
      },
      marginBottom: 0
    },
    activeModeStyle: {
      backgroundColor: "#ffffff",
      border: "solid 1px #e1dfe1"
    },

    fanStyleIcon: {
      "& *": {
        color: "#4b1c46",
        opacity: 1,
        fill: "#4b1c46"
      },
      padding: "10px 19px"
    },

    swingStyleIcon: {
      "& *": {
        color: "#4b1c46",
        opacity: 1,
        stroke: "#4b1c46"
      },
      padding: "4px 12px"

    },

    operationModes: {
      width: "70px",
      height: "60px",
      "& *": {
        color: "#968896",
        opacity: 1,
        stroke: "#968896"
      }
    },

    operationModesCool: {
      "& p": { color: "#F6F5F6" },
      "& *": {
        color: "#35a8e0",
        opacity: 1,
        stroke: "#35a8e0"
      }
    },
    operationModesHeat: {
      "& p": { color: "#F6F5F6" },
      "& *": {
        color: "#f05146",
        opacity: 1,
        stroke: "#f05146"
      }
    },

    inactiveText: {
      "& *": {
        color: "#4b1c46",
        opacity: 1,
        stroke: "#4b1c46"
      }
    },

    nameStyle: {
      fontSize: "16px",
      color: "#545964",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      fontFamily: "Roboto",
      letterSpacing: "-0.39px"
    },
    daysBtns: {
      cursor: "pointer",
      height: 40,
      width: 50,
      borderRadius: 8,
      margin: 4
    },
    hidden: {
      display: "none"
    }

  });
