import {
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import { ArrowForwardIos, Close, Email, MailOutline } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import Loading from "../../components/Loading/Loading";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { AddIcon, ArrowBack, MenuSearch as Search, UserPhoto } from "../../svgComponents";
import AddEditInvite from "./AddEditInvite";
import useStyle from "./UserList.style";

const UserList: React.FC = (props: any) => {
  const classes = useStyle();
  const history = useHistory();

  const isInitialized = useStoreState((s) => s.isInitialized);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const { customerId } = useStoreState((state) => state.selections.mobileSelections);
  const getCustomerUsers = useStoreActions((actions) => actions.customers.getCustomerUsers);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);

  const getInvites = useStoreActions((action) => action.invites.getInvites);
  const loggedInUser = useStoreState((state) => state.users.me);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [groups, setGroups] = useState<any>({});
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [filteredInvites, setFilteredInvites] = useState<any>([]);
  const [openNewUserDialog, handleOpenNewUserDialog] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<any>({});
  const [allInvites, setAllInvites] = useState<any>({});

  const { canAddInvite = true, canDeleteInvite = true, canShareInvite = true, canUpdateUser = true, canDeleteUser = true } = getCustomer(customerId) || {};

  useEffect(() => {
    if (!customerId) {
      return;
    }

    Promise.allSettled([
      getCustomerUsers(customerId),
      getInvites(customerId)
    ])
      .then((response: any) => {
        setAllUsers(response[0]?.value || {});
        setAllInvites(response[1]?.value || {});
      });
  }, [customerId]);

  useEffect(() => {
    filterUsers(allUsers, searchTerm);
  }, [customerId, allUsers, allInvites]);

  const filterUsers = (allUsers: any, searchTerm: string) => {
    const filteredUsers = !customerId ? [] : Object.values(allUsers).filter((user: any) => {
      const name = ((user?.firstName || "") + " " + (user?.lastName || "")).toUpperCase();
      const email = user?.email?.toUpperCase();
      const search = searchTerm?.toUpperCase();
      return (loggedInUser.id !== user.id && (name?.indexOf(search) >= 0 || email?.indexOf(search) >= 0));
    });

    const filteredInvites = !customerId ? [] : Object.values(allInvites).filter((invite: any) => {
      const email = invite?.email?.toUpperCase();
      const search = searchTerm?.toUpperCase();
      return (invite.customer === customerId && (email?.indexOf(search) >= 0));
    });
    setFilteredUsers(filteredUsers);
    setFilteredInvites(filteredInvites);
  };

  const handleSearchTermChange = (event: any) => {
    const { target: { value } } = event;
    setSearchTerm(value);
    filterUsers(allUsers, value);
  };

  setUnitUpdateStatus({ status: "" });

  if (!isInitialized) { return <Loading />; }

  return (
    <>
      <TopBar leftAction={() => history.push("/dashboard")}
        leftIconComponent={<ArrowBack />}
        screenTitle="userMgmt"
        rightIconComponent={<AddIcon />}
        rightAction={() => handleOpenNewUserDialog(true)}
        hideRightComponent={!canAddInvite}
        title={t`User Management`}
      />
      <div className={clsx(classes.contentArea, { [classes.iosContectArea]: isIOS })}>
        <TextField
          placeholder={t`Search User`}
          value={searchTerm}
          onChange={handleSearchTermChange}
          InputProps={{
            disableUnderline: true,
            classes: { root: classes.inputRoot },
            endAdornment:
              !searchTerm ? (<Search className={classes.searchIcon} />) : (
                <IconButton
                  onClick={() => { setSearchTerm(""); filterUsers(allUsers, ""); }}
                  className={classes.closeIconStyle}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              )
          }}
        />
        <div style={{ overflow: "auto", height: "100%" }}>
          {_.orderBy(filteredUsers, [(user: any) => user.firstName?.toUpperCase()],
            ["asc"]).map((user: any, index) => {
              return (
                <div key={`user-${user.id}`} className={classes.userRowHolder}>
                  <div style={{ paddingRight: "12px" }}>
                    <UserPhoto />
                  </div>
                  <div style={{ flex: 1, maxWidth: "calc(100% - 67px)" }}>
                    <Typography className={classes.username}>
                      {user?.firstName || ""}{" "}{user?.lastName || ""}
                    </Typography>
                  </div>
                  <ArrowForwardIos className={classes.arrowForward} />
                </div>
              );
            })
          }
          {_.orderBy(filteredInvites, [(user: any) => user.firstName?.toUpperCase()],
            ["asc"]).map((user: any, index) => {
              return (
                <div key={`user-${user.id}`} className={classes.userRowHolder}>
                  <div style={{ paddingRight: "12px" }}>
                    <MailOutline style={{ color: "#C9C4C9", fontSize: "33px" }} />
                  </div>
                  <div>
                    <Typography className={classes.roleText}>
                      {t`pending`}
                    </Typography>
                  </div>
                  <div className={classes.userMiddleHolder}>
                    <Typography className={classes.username}>
                      {user?.email}
                    </Typography>
                  </div>
                  <ArrowForwardIos className={classes.arrowForward} />

                </div>
              );
            })
          }
        </div>
      </div>
      {openNewUserDialog && <AddEditInvite newInvite customerId={customerId} groups={groups} close={() => handleOpenNewUserDialog(false)} />}

    </>
  );
};

export default UserList;
