import {
  Button as MuiButton,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import Switch from "../../../components/SmallSwitch/Switch";
import TimePicker from "../../../components/TimePicker/TimePicker";
import Button from "../../../cool_widgets/Button";
import Checkbox from "../../../cool_widgets/CoolCheckbox";
import DaysList from "../../../cool_widgets/DaysList/DaysList";
import { Fan1Icon, Fan2Icon, Fan3Icon, Fan4Icon, FanAutoIcon } from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../../services/timeService";
import { ArrowDownControl, ArrowUp } from "../../../svgComponents";
import useStyles from "./AddEditSchedulePopup.style";

const allFanModes = [0, 1, 2, 3, 4, 5];

const fanIcons = (subType: any, mode: any) => {
  switch (mode) {
    case 0: return subType === 2 ? <Typography>{t`LOW`}</Typography> : <Fan1Icon />;
    case 1: return <Fan2Icon />;
    case 2: return <Fan3Icon />;
    case 4: return <Fan4Icon />;
    case 3: return <FanAutoIcon />;
    case 5: return <Typography style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>{t`Very Low`}</Typography>;
    case "ON": return <Typography>{t`On`}</Typography>;
    case "OFF": return <Typography>{t`Off`}</Typography>;
  }
};

export default function ErrorBox(props: any) {
  const classes = useStyles();
  const { onClose, scheduleId, itemId, itemType, schedule, updateLocalSchedules, item, updateStoreUnit, isHalfCDegreeEnabled } = props;
  const types = useStoreState((state) => state.types);
  const { weekDays = [], timeFormat: timeFormatTypes, scheduleCategories } = types;
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const me = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const { temperatureScale = 1, timeFormat: myTimeFormat } = me;
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const isCelsius = +temperatureScaleMirror.celsius === +temperatureScale;
  const addScale = isHalfCDegreeEnabled ? 0.5 : 1;

  const [scheduleDisabled, setScheduleStatus] = useState<boolean>(false);
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [setpoint, setSetpoint] = useState<number>(isCelsius ? 24 : 75);
  const [days, setDays] = useState<any>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [enableFanSpeed, setEnableFanSpeed] = useState<boolean>(false);
  const [selectedFanSpeed, setSelectedFanSpeed] = useState<any>(null);
  const [setpointEnabled, enableSetpoint] = useState<boolean>(false);
  const [openPicker, setOpenPicker] = useState<string>("");
  const [weekDaysOptions] = useState<any>(Object.keys(weekDays));
  const [scheduleName, setScheduleName] = useState<string>("");
  const [tab, setTab] = useState<number>(scheduleCategories.weekly);
  const [dates, setDates] = useState<any>([]);
  const today = new Date();
  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);

  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;
  const { enableSetpointControl = false } = item?.capabilityFlags || {};

  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createObjectSchedules = useStoreActions((actions) => actions.schedules.createObjectSchedules);
  const editMode = scheduleId !== "new";

  const passScheduleInfo = (schedule: any) => {
    const { scheduleCategory, name, powerOnTime, powerOffTime, setpoint, days = [], isDisabled, dates = [], fanMode = null } = schedule;

    setScheduleStatus(isDisabled);
    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");
    setTab(scheduleCategory);
    setScheduleName(name || "");
    setpoint && (isHalfCDegreeEnabled ? setSetpoint(Math.floor(setpoint * 2) / 2) : setSetpoint(Math.round(setpoint)));
    setpoint && enableSetpoint(true);
    setDays(days as []);
    const selectedDates = dates.map((date: any) => new Date(date));
    setDates(selectedDates);
    fanMode !== null && setEnableFanSpeed(true);
    fanMode !== null && setSelectedFanSpeed(fanMode);

  };

  const hasValue = (value: any) => {
    return !!value || value === 0;
  };

  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates.concat();
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
  };

  useEffect(() => {
    if (scheduleId === "new") {
      return;
    }

    passScheduleInfo(schedule);
  }, []);

  const checkRequiredFields = () => {
    setErrorMsg("");

    if (!scheduleName) {
      setErrorMsg(t`Schedule Name is Required`);
      return false;
    }
    if (scheduleName.length < 3 || scheduleName.length > 25) {
      setErrorMsg(t`Schedule Name should be from 3 to 25 chars`);
      return false;
    }

    if (tab === scheduleCategories.calendar && dates.length === 0) {
      setErrorMsg(t`Pick one date at least`);
      return false;
    }
    if (tab === scheduleCategories.weekly && days.length === 0) {
      setErrorMsg(t`Pick one day at least`);
      return false;
    }

    if (!powerOnTime && !powerOffTime) {
      setErrorMsg(t`Add at least start or end time`);
      return false;
    }

    if (
      !(
        (powerOnTime) &&
        (powerOffTime)
      )
    ) {
      return true;
    }

    if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
      setErrorMsg(t`End time must be later than start time`);
      return false;
    }

    return true;
  };

  const editSchedule = () => {
    const {
      powerOnTime: defaultPowerOff,
      powerOffTime: defaultPowerOn,
      setpoint: defaultSetpoint
    } = schedule;

    const startHour =
      powerOnTime === ""
        ? defaultPowerOn === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOnTime, is12Hours);

    const endHour =
      powerOffTime === ""
        ? defaultPowerOff === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOffTime, is12Hours);

    let setpointValue: any;
    if (setpointEnabled) {
      setpointValue = setpoint;
    } else {
      if (defaultSetpoint) {
        setpointValue = null;
      } else {
        setpointValue = undefined;
      }
    }

    const data: any = {
      isDisabled: scheduleDisabled,
      name: scheduleName,
      powerOnTime: startHour,
      powerOffTime: endHour,
      setpoint: setpointValue,
      scheduleCategory: tab
    };

    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    updateSchedule({
      id: scheduleId,
      data
    })
      .then((schedule: any) => {
        updateLocalSchedules(schedule);
        onClose();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const createSchedule = () => {
    const startHour = powerOnTime ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
    const endHour = powerOffTime ? stringTimeToUTCMins(powerOffTime, is12Hours) : undefined;
    const setpointValue = setpointEnabled ? setpoint : undefined;
    const fanMode = enableFanSpeed ? selectedFanSpeed : null;

    const data: any = {
      isDisabled: scheduleDisabled,
      powerOnTime: startHour,
      powerOffTime: endHour,
      setpoint: setpointValue,
      name: scheduleName,
      scheduleCategory: tab,
      fanMode
    };

    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    createObjectSchedules({
      data,
      objId: itemId,
      objectType: itemType
    })
      .then((schedule: any) => {
        if (itemType === "unit") {
          const { schedules, id } = item;
          schedules.push(schedule.id);
          updateStoreUnit({ id, data: { schedules } });
        }
        updateLocalSchedules(schedule);
        onClose();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const save = () => {
    const allRequiredNotEmpty = checkRequiredFields();

    if (!allRequiredNotEmpty) {
      return;
    }

    if (scheduleId === "new") {
      createSchedule();
      return;
    }

    editSchedule();
  };

  const decreaseSetpoint = () => {
    setSetpoint(setpoint - addScale);
  };

  const increaseSetpoint = () => {
    setSetpoint(setpoint + addScale);
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day: string) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
  };

  const handleSetpoint = () => {
    enableSetpoint(!setpointEnabled);
  };

  const handleFanSpeed = () => {
    setEnableFanSpeed(!enableFanSpeed);
  };

  const changeScheduleStatus = () => {
    setScheduleStatus(!scheduleDisabled);
  };

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
  };

  const onSetTime = (time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
    setOpenPicker("");
  };

  const hasTime = (time: any) => {
    return time !== "" && time !== null && time >= 0;
  };

  const onInput = (event: any, powerTime: "start" | "end") => {
    const { target } = event.nativeEvent;

    if (target.defaultValue && target.defaultValue === target.value) {
      powerTime === "start" ? setPowerOnTime("") : setPowerOfftime("");
    }

    function iosClearDefault() {
      target.defaultValue = "";
    }
    window.setTimeout(iosClearDefault, 0);
  };

  const handlePowerOffTime = (event: any) => {
    const {
      target: { value }
    } = event;

    setPowerOfftime(value || "");
  };

  const handlePowerOnTime = (event: any) => {
    const {
      target: { value }
    } = event;

    setPowerOnTime(value || "");
  };

  const hasTimeIOS = (time: number | string) => {
    return time || time === 0;
  };

  return (
    <div className={classes.filtersPopup}>
      <div className={classes.filtersContainer}>
        <div className={classes.menuHeader}>
          <Typography className={classes.mainTitle}>{scheduleId === "new" ? t`Add Schedule` : t`Edit Schedule`}</Typography>
          <IconButton className={classes.iconBtn} onClick={onClose}><Clear /></IconButton>
        </div>
        <div style={{ maxHeight: "100%", overflow: "auto", padding: "0 10px", flex: 1 }}>
          <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", borderBottom: "solid 1px #d5d2d5", paddingBottom: "20px" }}>
            <TextField
              variant={"outlined"}
              label={"Schedule Name"}
              placeholder={"Schedule Name"}
              value={scheduleName}
              className={classes.inputClass}
              onChange={(e: any) => {
                setScheduleName(e.target.value);
              }}
            />
            <Switch
              checked={!scheduleDisabled}
              disableRipple={true}
              onChange={changeScheduleStatus}
              value={true}
            />
          </div>
          {/*<div className={classes.daysSection}>
                        <Typography  className={clsx(classes.textStyle, classes.addBtmMargin)} >{t`Choose Days`}</Typography>
                        <DaysList
                          days={weekDaysOptions}
                          activeDays={days}
                          action={addRemoveDay}
                          white={false}
                        />
                     </div>*/}
          <Tabs
            value={tab}
            onChange={(event: any, newValue: number) => setTab(newValue)}
            variant="fullWidth"
            aria-label="icon label tabs example"
            classes={{ indicator: classes.removeUnderline, root: classes.tabs }}
          >
            <Tab value={scheduleCategories.weekly} label={t`Weekly`} classes={{ selected: classes.selectedTab }} className={clsx(classes.tab, classes.leftBtnRadius)} />
            <Tab value={scheduleCategories.calendar} label={t`Calendar`} classes={{ selected: classes.selectedTab }} className={clsx(classes.tab, classes.removeLeftBorder, classes.rightBtnRadius)} />
          </Tabs>
          {tab === scheduleCategories.weekly && <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.tabContainer)}
          >
            <Typography
              className={classes.textTitle}
            >{t`Choose Days`}</Typography>
            <Grid container className={classes.daysContainer} id="days">
              <DaysList
                days={weekDaysOptions}
                activeDays={days}
                action={addRemoveDay}
                white={false}
              />
            </Grid>
          </Grid>}
          {tab === scheduleCategories.calendar && <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.tabContainer)}
          >
            <Grid container className={classes.daysContainer} id="dates">
              <DayPicker
                selectedDays={dates}
                onDayClick={handleDayClick}
                className={classes.calendar}
                disabledDays={
                  {
                    before: editMode ? undefined : today,
                    after: aYearFromToday
                  }}
                fromMonth={editMode ? undefined : today}
                toMonth={aYearFromToday}
              />
            </Grid>
          </Grid>}

          <div className={classes.timeSection}>
            {isIOS ? (
              <>
                <div
                  className={clsx(classes.iosHourButton, {
                    [classes.timeSelected]: !!powerOnTime
                  })}
                >
                  <InputLabel
                    className={classes.iosTimeLabel}
                  >
                    {powerOnTime
                      ? powerOnTime
                      : "Start Hour"}
                  </InputLabel>
                  <Input
                    className={classes.iosTimeInput}
                    disableUnderline
                    type="time"
                    value={powerOnTime}
                    onChange={handlePowerOnTime}
                    onInput={(event: any) => onInput(event, "start")}
                  />
                </div>
                <div
                  className={clsx(classes.iosHourButton, {
                    [classes.timeSelected]: !!powerOffTime
                  })}
                >
                  <InputLabel
                    className={classes.iosTimeLabel}
                  >
                    {powerOffTime ? powerOffTime : "End Hour"}
                  </InputLabel>
                  <Input
                    className={classes.iosTimeInput}
                    disableUnderline
                    type="time"
                    value={powerOffTime}
                    onChange={handlePowerOffTime}
                    onInput={(event: any) => onInput(event, "end")}
                  />
                </div>
              </>
            ) : (
              <>
                <MuiButton
                  variant="contained"
                  disableRipple
                  disableElevation
                  className={clsx(classes.timeContainer, {
                    [classes.timeSelected]: !!powerOnTime
                  })}
                  onClick={() => setOpenPicker("start")}
                >
                  {powerOnTime ? powerOnTime : "Start Hour"}
                </MuiButton>
                <MuiButton
                  disableElevation
                  variant="contained"
                  disableRipple
                  className={clsx(classes.timeContainer, {
                    [classes.timeSelected]: !!powerOffTime
                  })}
                  onClick={() => setOpenPicker("end")}
                >
                  {powerOffTime ? powerOffTime : "End Hour"}
                </MuiButton>
              </>
            )}
          </div>

          <div style={{
            display: "flex",
            flex: 1,
            margin: "10px 0"
          }}>
            {(itemType === "unit" ? enableSetpointControl : true) &&
              <div
                className={classes.setpointBox}
                style={{
                  borderRight: `1px solid #d8d8d8`
                }}
              >
                <div className={classes.controlHeader}>
                  <Typography
                    className={classes.textStyle}
                  >{t`Select setpoint`}</Typography>
                  <Checkbox
                    onChange={handleSetpoint}
                    checked={setpointEnabled}
                    className={classes.checkboxStyle}
                  />
                </div>

                <div
                  className={classes.selectModeContainer}
                >
                  <div
                    className={clsx(classes.setpointContainer, { [classes.wideSetpointcontainer]: isHalfCDegreeEnabled })}
                  >
                    <MuiButton
                      disabled={!setpointEnabled}
                      onClick={increaseSetpoint}
                      className={classes.controlArrowButton}
                    >
                      <ArrowUp />
                    </MuiButton>
                    <Typography
                      className={clsx(classes.setpointStyle, {
                        [classes.disabled]: !setpointEnabled
                      })}
                    >
                      {setpointEnabled ? (isHalfCDegreeEnabled ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint)) : isCelsius ? 24 : 75}
                      <span
                        className={classes.tempSymbolStyle}
                      >
                        {temperatureSymbol}
                      </span>
                    </Typography>
                    <MuiButton
                      disabled={!setpointEnabled}
                      onClick={decreaseSetpoint}
                      className={classes.controlArrowButton}
                    >
                      <ArrowDownControl />
                    </MuiButton>
                  </div>
                </div>
              </div>}

            {(itemType === "unit" ? enableSetpointControl : true) &&
              <div className={classes.setpointBox}>
                <div
                  className={classes.controlHeader}
                >
                  <Typography
                    className={classes.textStyle}
                  >{t`Select fan speed`}</Typography>
                  <Checkbox
                    onChange={handleFanSpeed}
                    checked={enableFanSpeed}
                    className={classes.checkboxStyle}
                  />
                </div>

                <div className={classes.selectModeContainer}>
                  <div
                    style={{
                      display: !enableFanSpeed ? "none" : "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      alignItems: "cneter",
                      flex: 1
                    }}
                  >

                    {(itemType === "unit" ? item?.supportedFanModes : allFanModes)?.map((mode: any, i: number) => {
                      const icon: any = fanIcons(item?.subType || 0, mode);
                      const isSelected = selectedFanSpeed === mode;

                      return (
                        <IconButton key={`mode-${i}`}
                          style={{
                            width: "55px",
                            height: "55px",
                            backgroundColor: isSelected ? "#4B1C46" : "#fff",
                            margin: "7px"
                          }}
                          onClick={() => setSelectedFanSpeed(mode)}
                        >
                          {
                            icon ?
                              React.cloneElement(icon,
                                isSelected ?
                                  {
                                    opacity: 0.6
                                  } :
                                  {
                                    colorblue: "#35A8E0",
                                    color1: "#4B1C46",
                                    color2: "#AAA2AA",
                                    maincolor: "#AAA2AA",
                                    opacity: 1
                                  }) :
                              types?.fanModesExtended[mode] || ""
                          }
                        </IconButton>)

                        ;
                    })}
                  </div>

                  {/* ////////// */}
                </div>
              </div>}
          </div>
        </div>
      </div>
      {errorMsg && (
        <InputLabel
          className={classes.errorLabelStyle}
        >{errorMsg}</InputLabel>
      )}
      <Button
        className={classes.applyBtn}
        onClick={save}
      >{t`Save`}</Button>
      {!isIOS && (
        <TimePicker
          show={!!openPicker}
          onSet={onSetTime}
          time={openPicker === "start" ? powerOnTime : powerOffTime}
          onDismiss={() => setOpenPicker("")}
          onClear={onClear}
          is12Hours={is12Hours}
        />
      )}
    </div>
  );
}
