import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";

export interface IAuditsFilter {
  [key: string]: boolean;
}

export interface IAuditsFilterProps {
  title: string;
  filters: IAuditsFilter;
  setFilter: (newFilter: IAuditsFilter) => void;
  getFilterName?: (filter: string) => string;
  getFilterJSX?: (filter: string) => JSX.Element;
  usersFilter?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  filterHeaderContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.5rem",
    borderBottom: "2px solid rgba(60, 60, 60, 0.2)"
  },
  filterHeader: {
    paddingLeft: "0.5rem",
    color: "#4b1c46"
  },
  filterList: {
    height: "100%",
    overflow: "auto"
  },
  limitShownText: {
    "& span": {
      whiteSpace: "nowrap",
      width: "160px",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  limitShownTextShort: {
    "& span": {
      whiteSpace: "nowrap",
      width: "125px",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  grayCheckbox: {
    "&$checked": {
      color: "#736973"
    }
  },
  listItem: {
    paddingLeft: "10px"
  }
}));

export const AuditsFilter: React.FC<IAuditsFilterProps & Partial<PaperProps>> = ({
  title,
  filters,
  setFilter,
  getFilterName,
  getFilterJSX,
  usersFilter,
  ...rest
}) => {
  const styles = useStyles();
  const [allToggled, setAllToggled] = useState(false);

  const handleToggle = (filterId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter: IAuditsFilter = { ...filters, [filterId]: !!event.target.checked };
    setFilter(newFilter);
  };

  const handleToggleUser = (filterId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = event;
    let newFilter: IAuditsFilter = filters;
    if (filters.all && !checked) {
      newFilter[filterId] = event.target.checked;
      newFilter.all = false;
    } else {
      newFilter[filterId] = event.target.checked;
      newFilter.all = Object.keys(newFilter).every((filterId: string) => filterId === "all" ? true : filters[filterId]);
    }
    setFilter({ ...newFilter });
  };

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter: IAuditsFilter = {};
    for (let key of Object.keys(filters)) {
      if (key !== "all") {
        newFilter[key] = !allToggled;
      }
    }
    setFilter(newFilter);
    setAllToggled(!allToggled);
  };

  const handleToggleAllInUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = event;
    const newFilter: IAuditsFilter = {};
    for (let key of Object.keys(filters)) {
      newFilter[key] = checked;
    }
    setFilter(newFilter);
  };

  const filterKeys = Object.keys(filters);
  const sortedFilterKeys = filterKeys.sort((a: string, b: string) => {
    if (getFilterName) {
      a = getFilterName(a);
      b = getFilterName(b);
    }
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  let isIndeterminate = false;
  let sameKey: boolean | undefined;
  for (let key of filterKeys) {
    if (_.isUndefined(sameKey)) {
      sameKey = filters[key];
    } else if (sameKey !== filters[key]) {
      isIndeterminate = true;
      break;
    }
  }

  return (
    <Paper {...rest}>
      <div className={styles.filterHeaderContainer}>
        <Typography variant="h6" className={styles.filterHeader}>
          {title}
        </Typography>
      </div>
      <List disablePadding={true} className={styles.filterList}>
        {!usersFilter && <ListItem dense autoFocus divider key={"all"} classes={{ gutters: styles.listItem }}>
          <ListItemText id={"all"} primary={t` All` + ` ${title}`} />
          <ListItemSecondaryAction>
            <Checkbox
              color="default"
              edge="end"
              onChange={handleToggleAll}
              checked={allToggled}
              indeterminate={isIndeterminate}
              inputProps={{ "aria-labelledby": `toggle all ${title}` }}
              classes={{ root: styles.grayCheckbox }}
            />
          </ListItemSecondaryAction>
        </ListItem>}
        {_.map(sortedFilterKeys, (key, id) => {
          if (key === "all") {
            return (
              <ListItem dense autoFocus divider key={"all"} classes={{ gutters: styles.listItem }}>
                <ListItemText id={"all"} primary={` All Users`} />
                <ListItemSecondaryAction>
                  <Checkbox
                    color="default"
                    edge="end"
                    onChange={handleToggleAllInUsers}
                    checked={filters[key]}
                    indeterminate={isIndeterminate}
                    inputProps={{ "aria-labelledby": `toggle all ${title}` }}
                    classes={{ root: styles.grayCheckbox }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
          return (
            <ListItem dense autoFocus divider key={id} classes={{ gutters: styles.listItem }}>
              <Tooltip arrow title={getFilterName ? getFilterName(key) : key} placement="top">
                <div className="asd" style={{ display: "flex" }}>
                  {getFilterJSX && getFilterJSX(key)}
                  <ListItemText className={getFilterJSX ? styles.limitShownTextShort : styles.limitShownText} id={`${id}`} primary={getFilterName ? getFilterName(key) : key} />
                </div>
              </Tooltip>
              <ListItemSecondaryAction>
                <Checkbox
                  color="default"
                  edge="end"
                  onChange={usersFilter ? handleToggleUser(key) : handleToggle(key)}
                  checked={filters[key]}
                  inputProps={{ "aria-labelledby": key }}
                  classes={{ root: styles.grayCheckbox }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};
