import { alpha } from "@material-ui/core";

export const isGradient = (value) => {
  return (
    value?.startsWith("linear-gradient") || value?.startsWith("radial-gradient")
  );
};

export const alphaExtended = (value, alphaValue) => {
  if (value.startsWith("linear-gradient")) {
    const gradientColors = value.match(/rgba?\([^()]+\)/g); // extracts rgba or rgb values
    if (gradientColors && gradientColors.length === 2) {
      const [start, end] = gradientColors;
      const startWithAlpha = alpha(start, alphaValue);
      const endWithAlpha = alpha(end, alphaValue);
      return value.replace(start, startWithAlpha).replace(end, endWithAlpha);
    } else {
      console.error("Gradient format not recognized.");
      return value;
    }
  } else {
    return alpha(value, alphaValue);
  }
};

export const placeBackgroundColor = (color) => {
  return {
    backgroundColor: `${isGradient(color) ? `transparent` : color} !important`,
    background: `${isGradient(color) ? color : undefined} !important`
  };
};

export function getHostname() {
  return localStorage.getItem("host") || window.location.hostname;
}
