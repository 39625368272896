import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import {
  AutoMode,
  AutoVRMode,
  ColdBlue,
  ColdIcon,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanAutoIcon,
  FanMode,
  HotRed,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingOff2,
  SwingVer
} from "../../svgComponents";
import modesListStyle from "./modesList.style";

const mappedTypes: any = {
  swingModes: "subTypesSwingModes",
  operationModesExtended: "subTypesOperationModes",
  fanModes: "subTypesFanModes"
};
const mappedTypesExtended: any = {
  swingModes: "swingModes",
  operationModesExtended: "operationModesExtended",
  fanModes: "fanModesExtended"
};

const ModesList = (props: any) => {
  const useStyle = makeStyles(modesListStyle);
  const classes = useStyle();

  const { activeModes = [], action, customClass, modeName, byText = false, disabled = false, subType = 0 } = props;

  const { container, modeStyle, activeModeStyle, nameStyle } = classes;
  const types = useStoreState((states) => states.types);

  //needs to be moved from here once we get all hrv fan icons
  const icons: any = {
    operationModesExtended: {
      0: <ColdBlue style={{ transform: "scale(1.889)" }} />,
      1: <HotRed style={{ transform: "scale(1.889)" }} />,
      2: <AutoMode maincolor="#aaa2aa" />,
      3: <DryIcon maincolor="#aaa2aa" />,
      5: <FanMode maincolor="#aaa2aa" />,
      7: <AutoVRMode maincolor="#aaa2aa" />
    },
    fanModes: {
      0: subType === 2 ? <Typography className={nameStyle}>{t`LOW`}</Typography> : <Fan1Icon color1="#4b1c46" />,
      1: <Fan2Icon color1="#4b1c46" />,
      2: <Fan3Icon color1="#4b1c46" />,
      3: <FanAutoIcon color1="#4b1c46" />,
      5: <Typography className={nameStyle}>{t`Very Low`}</Typography>,
      4: <Fan4Icon color1="#4b1c46" />
    },
    swingModes: {
      0: <SwingVer color1="#4b1c46" color2="#4b1c46" />,
      4: <SwingHor color1="#4b1c46" color2="#4b1c46" />,
      1: <Swing30 color1="#4b1c46" color2="#4b1c46" />,
      2: <Swing45 color1="#4b1c46" color2="#4b1c46" />,
      3: <Swing60 color1="#4b1c46" color2="#4b1c46" />,
      5: <Swing color1="#4b1c46" color2="#4b1c46" />,
      6: <SwingOff2 color1="#4b1c46" color2="#4b1c46" />
    }
  };

  const modesSelections = types[mappedTypes[modeName]];
  const modeNames = types[mappedTypesExtended[modeName]];

  const fanOrder: { [key: string | number]: number } = {
    6: 0,
    3: 1,
    4: 2,
    2: 3,
    7: 4,
    1: 5,
    0: 6,
    5: 7,
    ON: 8,
    OFF: 9
  }
  const supportedArray = modesSelections[subType] || [];
  const sortedSupportedArray = modeName === "fanModes" ? supportedArray.sort((a: string | number, b: string | number) => fanOrder[b] - fanOrder[a]) : supportedArray

  if (!modesSelections) {
    return null;
  }

  return (
    <div className={container}>
      {sortedSupportedArray.map((modeKey: any, index: number) => {
        const isSelected = activeModes?.indexOf(modeKey) > -1;

        const modeIcon = icons[modeName][modeKey] || (
          <Typography className={nameStyle}>{modeNames[modeKey] || modeKey}</Typography>
        );

        return (
          <div
            className={clsx(
              classes.iconsWidth,
              {
                [activeModeStyle]: !isSelected,
                [customClass]: isSelected
              }

            )}
            key={index}
            onClick={() => disabled ? {} : action(modeName, modeKey)}
          >
            {modeIcon}

          </div>
        );
      })}
    </div>
  );
};

export default ModesList;
