import { System as SystemSdk, Services as ServicesSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";
import { IUnit } from "./Units";

export interface ISystem {
  id: string;
  name: string;
  brand?: string;
  description?: string;
  type?: string;
  series?: string;
  capacity?: number;
  capacityMeasurementUnits?: number;
  line: number;
  device: string;
  units: string[];
  role: any;
  types: any;
  capacityMeasurement: any;
  brandNum?: number;
  mapping?: boolean;
  site: string;
  controlLine?: number;
  powerMeters: any[];
  operationStatus?: any;
  operationMode?: any;
  canAddNote: boolean;
  canDeleteNote: boolean;
}

export interface ISystemMap {
  [key: string]: ISystem;
}

export interface ISystemCustomized {
  IDU: number;
  ODU: number;
}

export interface ISystemCustomizedMap {
  [key: string]: ISystemCustomized;
}

interface ISystemPayload {
  systemId: string;
  state: number;
}

export interface ISystemsModel {
  allSystems: ISystemMap;
  allSystemCustomized: ISystemCustomizedMap;
  initialize: Action<ISystemsModel, any>;
  setAllSystemCustomized: Action<ISystemsModel, any>;
  getAllSystemCustomized: Thunk<ISystemsModel, void, any, IRootStoreModel, any>;
  onInitialized: ActionOn<ISystemsModel, IRootStoreModel>;
  getSystem: Computed<
    ISystemsModel,
    (id?: string | null) => any,
    IRootStoreModel
  >;
  getSystemName: Computed<
    ISystemsModel,
    (id: string | undefined) => string | undefined
  >;
  getLocalSystem: Computed<
    ISystemsModel,
    (id?: string | null) => any,
    IRootStoreModel
  >;
  updateSystem: Thunk<ISystemsModel, { systemId: string; updatedData: any }>;
  createSystem: Thunk<ISystemsModel, any>;
  clearSystemFilter: Thunk<ISystemsModel, string>;
  getSystemProData: Thunk<ISystemsModel, { systemId: string; data: any }>;
  getSystemById: Thunk<ISystemsModel, { systemId: string }>;
  systemTogglePower: Thunk<ISystemsModel, { id: string; data: any }>;
  changeSystemOperationMode: Thunk<ISystemsModel, { id: string; data: any }>;
  _storeUpdateSystem: Action<
    ISystemsModel,
    { systemId: string; updatedSystemData: any }
  >;
  _storeAddSystem: Action<ISystemsModel, { id: string; data: any }>;
  getSystemAlerts: Computed<
    ISystemsModel,
    (id: string, type: "all" | "unresolved") => IAlert[] | [],
    IRootStoreModel
  >;
  hasUnitsOn: Computed<ISystemsModel, (id: string) => boolean, IRootStoreModel>;
  hasControlUnits: Computed<
    ISystemsModel,
    (id: string) => boolean,
    IRootStoreModel
  >;
  getSystemMode: Computed<
    ISystemsModel,
    (id: string) => number,
    IRootStoreModel
  >;
  hasDisconnectedUnits: Computed<
    ISystemsModel,
    (id: string) => boolean,
    IRootStoreModel
  >;
  getSystemFirstUnitId: Computed<
    ISystemsModel,
    (id: string, unitType: number | undefined) => string | null,
    IRootStoreModel
  >;
  getSystemByName: Computed<
    ISystemsModel,
    (name?: string | null) => ISystem | undefined
  >;
  getSystemsIdsBySite: Computed<
    ISystemsModel,
    (id?: string | null) => string[] | [],
    IRootStoreModel
  >;
  getSystemsBySite: Computed<
    ISystemsModel,
    (id?: string | null) => any[] | [],
    IRootStoreModel
  >;
  getSystems: Thunk<
    ISystemsModel,
    void /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any
  >;
  updateLocalSystemPowerMeters: Action<
    ISystemsModel,
    { systemId: string; data: any }
  >;
  updateSystemPowerMeters: Thunk<
    ISystemsModel,
    { systemId: string; data: any }
  >;
  getSystemSchedules: Thunk<ISystemsModel, string>;
  createSystemSchedule: Thunk<ISystemsModel, { id: string; data: any }>;
  getSystemByIdLocaly: Computed<
    ISystemsModel,
    (id: string | null | undefined) => any,
    IRootStoreModel
  >;
  getSystemUnits: Computed<
    ISystemsModel,
    (id: string, type: "outdoor" | "service" | "indoor") => IAlert[] | [],
    IRootStoreModel
  >;
  acknowledgeSystems: Thunk<ISystemsModel, { systems: any }, any>;
  updateSystemOperationMode: Thunk<ISystemsModel, ISystemPayload, any>;
  changeSystemPowerState: Thunk<ISystemsModel, ISystemPayload, any>;
  getSystemPower: Computed<
    ISystemsModel,
    (id: string) => IUnit[] | any,
    IRootStoreModel
  >;
}

export const systemsModel: ISystemsModel = {
  allSystems: {},
  allSystemCustomized: {},

  initialize: action((state, payload) => {
    state.allSystems = payload;
  }),

  setAllSystemCustomized: action((state, payload) => {
    state.allSystemCustomized = payload;
  }),

  getAllSystemCustomized: thunk(async actions => {
    const allSystemsCustomized =
      await ServicesSdk.getProfessionalSystemsCustomized();
    if (allSystemsCustomized) {
      actions.setAllSystemCustomized(allSystemsCustomized);
    }
    return allSystemsCustomized;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),
  getSystems: thunk(async actions => {
    const allSystems = await SystemSdk.getSystems();
    if (allSystems) {
      actions.initialize(allSystems);
    }
    return allSystems;
  }),

  getSystem: computed(
    [
      state => state.allSystems,
      (state, storeState) => storeState.units.allUnits,
    ],
    (allSystems, allUnits) =>
      memo(id => {
        if (_.isNil(id)) {
          return undefined;
        }
        if (_.includes(id, "_")) {
          const unassignedUnits = Object.values(allUnits).filter(
            (unit: any) =>
              _.isNil(unit.system) &&
              unit.line.toString() === id.split("_")[0] &&
              unit.device === id.split("_")[1]
          );
          if (unassignedUnits.length) {
            const firstUnit = unassignedUnits[0];
            const system = {
              id,
              line: id.split("_")[0],
              name: `Line ${id.split("_")[0]} - unassigned units`,
              units: unassignedUnits.map((unit: any) => unit.id),
              device: firstUnit.device,
              site: firstUnit.site,
            };

            return system;
          }
        }
        return allSystems[id];
      }, 100)
  ),
  getSystemName: computed(state => id => {
    const noName = "-";

    if (!id) {
      return noName;
    }

    if (!state.allSystems[id]) {
      return noName;
    }

    return state.allSystems[id].name ? state.allSystems[id].name : noName;
  }),
  getSystemByName: computed([state => state.allSystems], allSystems =>
    memo(name => {
      if (_.isNil(name)) {
        return undefined;
      }
      return Object.values(allSystems).filter(system => {
        if (system.name.toLowerCase() === name.toLowerCase()) {
          return true;
        }
      })[0];
    }, 100)
  ),
  getSystemsIdsBySite: computed(
    [
      state => state.allSystems,
      (state, storeState) => storeState.devices.allDevices,
    ],
    (allSystems, allDevices) =>
      memo(id => {
        const siteDevices = Object.values(allDevices).filter(
          (device: any) => device.site === id
        );
        const systems: string[] = [];
        const devicesIds = siteDevices.map((device: any) => {
          device.systems.forEach((system: any) => {
            systems.push(system);
          });
        });
        return systems;
      }, 100)
  ),
  getSystemsBySite: computed(
    [
      state => state.allSystems,
      (state, storeState) => storeState.devices.allDevices,
    ],
    (allSystems, allDevices) =>
      memo(id => {
        const siteDevices = Object.values(allDevices).filter(
          (device: any) => device.site === id
        );
        const systems: any = siteDevices.reduce((data: any, device: any) => {
          if (device.serviceSystems?.length) {
            device.serviceSystems.forEach((system: any) => {
              if (allSystems[system]) {
                data.push(allSystems[system]);
              }
            });
          }
          return data;
        }, []);
        return systems;
      }, 100)
  ),

  getSystemById: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.getSystemById(payload.systemId);

    actions._storeUpdateSystem({
      systemId: payload.systemId,
      updatedSystemData,
    });
    return updatedSystemData;
  }),
  createSystem: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.create(payload);
    actions._storeAddSystem({
      id: updatedSystemData.id,
      data: updatedSystemData,
    });

    return updatedSystemData;
  }),

  updateSystem: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.update(
      payload.systemId,
      payload.updatedData
    );

    actions._storeUpdateSystem({
      systemId: payload.systemId,
      updatedSystemData,
    });
    return updatedSystemData;
  }),
  clearSystemFilter: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.clearSystemFilter(payload);
    return updatedSystemData;
  }),
  getSystemProData: thunk(async (actions, payload) => {
    const { systemId, data } = payload;
    return SystemSdk.getSystemProData(payload.systemId, {
      startTime: data.startTime,
      endTime: data.endTime,
    });
  }),

  systemTogglePower: thunk(async (actions, payload, { getStoreActions }) => {
    const storeActions: any = getStoreActions();
    await SystemSdk.changeSystemPower(payload.id, payload.data);
    actions._storeUpdateSystem({
      systemId: payload.id,
      updatedSystemData: { operationStatus: payload.data },
    });
    storeActions.units.updateMultipleUnitsLocally({
      filter: "system",
      filterVal: payload.id,
      key: "activeOperationStatus",
      value: payload.data,
    });
  }),

  changeSystemOperationMode: thunk(
    async (actions, payload, { getStoreActions }) => {
      const storeActions: any = getStoreActions();
      await SystemSdk.changeSystemOperationMode(payload.id, payload.data);
      actions._storeUpdateSystem({
        systemId: payload.id,
        updatedSystemData: { operationMode: payload.data },
      });
      storeActions.units.updateMultipleUnitsLocally({
        filter: "system",
        filterVal: payload.id,
        key: "activeOperationMode",
        value: payload.data,
      });
    }
  ),

  _storeUpdateSystem: action((state, payload) => {
    if (state.allSystems[payload.systemId]) {
      state.allSystems[payload.systemId] = {
        ...state.allSystems[payload.systemId],
        ...payload.updatedSystemData,
      };
    }
  }),
  _storeAddSystem: action((state, payload) => {
    state.allSystems = { ...state.allSystems, [payload.id]: payload.data };
  }),

  getSystemAlerts: computed(
    [
      state => state.allSystems,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.alerts.allOpenAlerts,
      (state, storeState) => storeState.units.isItMe,
    ],
    (systems, allUnits, allDevices, allSites, allOpenAlerts, unitIsItMe) =>
      memo((id, type = "all") => {
        const system = systems[id];
        const systemDevice = allDevices[system.device];
        const systemSite = allSites[systemDevice.site];
        let systemAlerts: IAlert[] = [];
        Object.values(allOpenAlerts).forEach((alert: IAlert) => {
          if (type === "unresolved" && !_.isUndefined(alert.clearTime)) {
            return;
          }
          if (systemSite.id === alert.site) {
            if (_.has(alert, "data.eventData.unitId")) {
              Object.values(allUnits).forEach((unit: IUnit) => {
                if (
                  alert.data.eventData.unitId &&
                  unitIsItMe(unit, alert.data.eventData.unitId) &&
                  unit.system === system.id
                ) {
                  systemAlerts.push(alert);
                }
              });
            } else if (
              alert.data.trigger.template.includes("DEVICE_DISCONNECT") &&
              systemDevice.serial === alert.data.eventData.deviceId
            ) {
              systemAlerts.push(alert);
            }
          }
        });
        return systemAlerts;
      }, 100)
  ),

  hasUnitsOn: computed(
    [(state, storeState) => storeState.units.allUnits],
    allUnits => id => {
      let hasUnitsOn = false;

      const serviceUnits = Object.values(allUnits).filter(
        unit => unit.system === id && unit.type === 3
      );
      const controlUnitsIds = serviceUnits.map((unit: any) => unit.controlUnit);

      const units = Object.values(allUnits).filter(unit => {
        if (
          _.includes(controlUnitsIds, unit.id) &&
          unit.activeOperationStatus === 1
        ) {
          return true;
        }
        return false;
      });
      if (units.length) {
        hasUnitsOn = true;
      }

      return hasUnitsOn;
    }
  ),
  hasControlUnits: computed(
    [(state, storeState) => storeState.units.allUnits],
    allUnits => id => {
      let hasControlUnits = false;
      const serviceUnits = Object.values(allUnits).filter(
        unit => unit.system === id && unit.type === 3
      );
      const controlUnits = _.filter(serviceUnits, (unit: any) => {
        if (_.isNil(unit.controlUnit)) {
          return false;
        }
        return true;
      });
      if (controlUnits.length) {
        hasControlUnits = true;
      }

      return hasControlUnits;
    }
  ),
  getSystemMode: computed(
    [(state, storeState) => storeState.units.allUnits],
    allUnits => id => {
      let systemMode = -1; // not specified

      const serviceUnits = Object.values(allUnits).filter(
        unit => unit.system === id && unit.type === 3
      );
      const controlUnitsIds = serviceUnits.map((unit: any) => unit.controlUnit);
      const controlUnits = Object.values(allUnits).filter(unit =>
        _.includes(controlUnitsIds, unit.id)
      );

      if (controlUnits.length > 0) {
        systemMode = controlUnits[0].activeOperationMode;
      } else {
        return -1;
      }

      for (let unit of controlUnits) {
        if (unit.activeOperationMode !== systemMode) {
          return -1;
        }
      }
      return systemMode;
    }
  ),

  hasDisconnectedUnits: computed(
    [(state, storeState) => storeState.units.allUnits],
    allUnits => id => {
      for (let unit of Object.values(allUnits)) {
        if (
          unit.system === id &&
          _.has(unit, "isConnected") &&
          !unit.isConnected
        ) {
          return true;
        }
      }

      return false;
    }
  ),

  getSystemFirstUnitId: computed(
    [(state, storeState) => storeState.units.allUnits],
    allUnits => (id, unitType) => {
      let systemFilteredUnits = Object.values(allUnits).filter(
        (unit: IUnit) => {
          // Filter out wrong typed units only if unit-type is specified.
          if (!_.isNil(unitType) && unitType !== unit.type) {
            return false;
          }

          // Filter in units with system assigned and it is the system requested.
          if (!_.isNil(unit.system) && unit.system === id) {
            return true;
          }

          // No match.
          return false;
        }
      );

      // Return the first filtered unit's ID.
      if (systemFilteredUnits.length) {
        // Outdoor units are sorted by line ID.
        if (unitType === 2) {
          systemFilteredUnits = _.sortBy(
            systemFilteredUnits,
            unit => unit.line
          );
        }

        return systemFilteredUnits[0].id;
      }

      // No unit found.
      return null;
    }
  ),
  updateSystemPowerMeters: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.assignPowerMeters(
      payload.systemId,
      { powerMeters: payload.data }
    );

    actions.updateLocalSystemPowerMeters(payload);

    return updatedSystemData;
  }),
  updateLocalSystemPowerMeters: action((state, payload) => {
    if (state.allSystems[payload.systemId]) {
      state.allSystems[payload.systemId].powerMeters = payload.data;
    }
  }),
  getSystemSchedules: thunk(async (actions, payload) => {
    const data = await SystemSdk.getSystemSchedules(payload);
    return data;
  }),
  createSystemSchedule: thunk(async (actions, payload) => {
    const data = await SystemSdk.createSystemSchedule(payload.id, payload.data);
    return data;
  }),
  getSystemUnits: computed(
    [
      state => state.allSystems,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.types,
    ],
    (systems, allUnits, allTypes) =>
      (id, type = "outdoor") => {
        const system = systems[id];
        if (!system) {
          return [];
        }
        const resources: any = [];
        Object.values(allUnits).forEach((unit: any) => {
          const {
            type: unitType,
            id: resourceId,
            subType,
            system,
            controlUnit = "",
            serviceUnits = [],
            otherUnits = [],
          } = unit;
          if (system !== id) {
            return;
          }
          if (type === "outdoor") {
            if (unitType === 2) {
              resources.push({ resourceId, unitType });
            }
            return;
          }

          if (type === "indoor") {
            if (
              unitType === 3 &&
              controlUnit &&
              allUnits[controlUnit]?.subType !== 1
            ) {
              resources.push({ resourceId, unitType });
            }
            if (
              unitType === 1 &&
              subType !== 1 &&
              serviceUnits.length === 0 &&
              otherUnits.length === 0
            ) {
              resources.push({ resourceId, unitType });
            }
            return;
          }

          if (
            type === "service" &&
            unitType === 3 &&
            controlUnit &&
            allUnits[controlUnit]?.subType !== 1
          ) {
            resources.push({ resourceId, unitType });
          }
        });
        return resources;
      }
  ),
  getSystemByIdLocaly: computed(
    [state => state.allSystems],
    allSystems => id => {
      if (!id) return undefined;
      return allSystems[id];
    }
  ),
  acknowledgeSystems: thunk(async (actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    return sdkSystem.acknowledgeSystems(payload);
  }),
  updateSystemOperationMode: thunk((actions, payload, { injections }) => {
    const { systemId, state } = payload;
    const { sdkSystem } = injections;
    return sdkSystem.changeSystemOperationMode(systemId, state);
  }),
  changeSystemPowerState: thunk((actions, payload, { injections }) => {
    const { systemId, state } = payload;
    const { sdkSystem } = injections;
    return sdkSystem.changeSystemPower(systemId, state);
  }),
  getSystemPower: computed(
    [(state, storeState) => storeState.units.allUnits],
    allUnits => id => {
      let maxPower = 0;
      let actualPower = 0;
      _.map(allUnits, (unit: IUnit) => {
        if (unit.type === 2 && unit.system === id) {
          maxPower =
            maxPower +
            (unit.maxPowerConsumption === -1 ? 0 : unit.maxPowerConsumption);
          actualPower =
            actualPower +
            (unit.lastPowerConsumption === -1 ? 0 : unit.lastPowerConsumption);
        }
      });
      return { maxPower, actualPower };
    }
  ),

  getLocalSystem: computed([state => state.allSystems], allSystems => id => {
    if (_.isNil(id)) {
      return undefined;
    }
    return allSystems[id];
  }),
};
