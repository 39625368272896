import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import _ from "lodash";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { TopBar } from "../../components";
import Button from "../../cool_widgets/Button";
import HVACImg from "../../images/coolmaster.png";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { countries } from "../../screens/SiteManagement/countryList";
import { ArrowBack } from "../../svgComponents";
import createSiteStyles from "./AddEditSite.style";
import { stateList } from "./stateList";
import assetsService from "../../services/assetsService";

interface CreateSiteProps {
  history: any;
  match: any;
}

const timeZones = tz.names().map((timezone) => ({
  value: timezone,
  label: `${timezone}  ${tz(timezone).format("Z")}`
}));

const AddEditSite: React.FC<CreateSiteProps> = ({ history, match }) => {
  const useStyles = makeStyles(createSiteStyles);
  const classes = useStyles();

  const { params } = match;
  const { siteId } = params;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [site, setSite] = useState<any>({});

  const startLoader = useStoreActions((actions) => actions.loader.startLoader);
  const finishLoader = useStoreActions(
    (actions) => actions.loader.finishLoader
  );
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const createNewSite = useStoreActions((actions) => actions.createSite);
  const updateSite = useStoreActions((action) => action.updateSite);
  const allSites = useStoreState((s) => s.sites.allSites);
  const { deviceRegistrationProgress } = useStoreState(
    (states) => states.devices
  );
  const { canUpdate = true } = site || {};

  useEffect(() => {
    if (!!siteId) {
      setIsEdit(true);
      setSite(allSites[siteId]);
    }
  }, [siteId]);

  const initialValues: any = {
    name: site?.name || "",
    description: site?.description || "",
    country: site?.country || "",
    city: site?.city || "",
    address: site?.address || "",
    postalCode: site?.postalCode || "",
    timezone: site?.timezone || "",
    state: site?.state || ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    country: Yup.string().required(t`required field`),
    city: Yup.string().required(t`required field`),
    address: Yup.string().required(t`required field`),
    timezone: Yup.string().required(t`required field`),
    postalCode: Yup.string(),
    state: Yup.string().when("country", {
      is: (value) => value === "US",
      then: Yup.string().required("Required"),
      otherwise: Yup.string()
    })
  });

  const handleFormdSubmit = (values: any, setFieldError: any) => {
    const { country, state } = values;
    const { customerId } = match.params;
    const siteData = {
      ...values,
      state: country === "US" && state ? state : ""
    };

    startLoader();
    if (isEdit) {
      updateSite({ siteId, updatedData: siteData })
        .then(() => {
          history.push(`/site-management?selectedSite=${siteId}`);
        })
        .catch((err: any) => {
          if (err?.message?.indexOf("exist") > -1) {
            setFieldError("name", err.message);
          }
          addMessage({
            message: err.message
          });
        })
        .finally(() => finishLoader());
    } else {
      siteData.customer = customerId;

      createNewSite(siteData)
        .then((res: any) => {
          if (_.isEmpty(deviceRegistrationProgress)) {
            history.push(`/site-management`);
          } else {
            history.push(
              `/device-registration/${res?.id}/user-selection/${customerId}`
            );
          }
        })
        .catch((err: any) => {
          if (err?.message?.indexOf("exist") > -1) {
            setFieldError("name", err.message);
          }
          addMessage({
            message: err.message
          });
        })
        .finally(() => finishLoader());
    }
  };

  const goBack = () => {
    if (_.isEmpty(deviceRegistrationProgress)) {
      history.push(`/site-management?selectedSite=${siteId}`);
    } else {
      history.push(
        `/device-registration/sites/user-selection/5f69e01144a68c254ff8a9df`
      );
    }
  };

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.userFormContainer}>
        <TopBar
          title={isEdit ? t`Edit Site` : t`Add Site`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
        />
        <Grid item className={classes.scrolledSection}>
          <div className={classes.pageContent}>
            <div className={classes.header}>
              <Typography
                className={classes.subTitle}
              >{t`Please add site information`}</Typography>
              <div className={classes.deviceImageContainer}>
                <div className={classes.deviceImageShadow} />
                <img
                  className={classes.deviceImgStyle}
                  src={assetsService.getImagesPathFromDomainFolder("cmnet.png")}
                  alt="coolmaster device"
                />
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setFieldError }) =>
              handleFormdSubmit(values, setFieldError)
            }
            enableReinitialize={true}
            validationSchema={validationSchema}
            render={({
              values,
              errors,
              touched,
              setFieldValue,
              dirty,
              ...formikProps
            }) => {
              return (
                // @ts-ignore
                <Form className={classes.form}>
                  <Grid container className={classes.inputsContainer}>
                    <TextField
                      disabled={!canUpdate}
                      name="name"
                      value={values.name}
                      placeholder={t`Site Name`}
                      variant="outlined"
                      className={classes.inputWrapperStyle}
                      onChange={(event: any) =>
                        setFieldValue("name", event.target.value)
                      }
                      error={!!errors.name}
                    />
                    <TextField
                      disabled={!canUpdate}
                      name="address"
                      value={values.address}
                      placeholder={t`Street Address`}
                      variant="outlined"
                      className={classes.inputWrapperStyle}
                      onChange={(event: any) =>
                        setFieldValue("address", event.target.value)
                      }
                      error={!!errors.address}
                    />

                    <div className={classes.selectContainer}>
                      <FormControl fullWidth>
                        <InputLabel
                          className={classes.selectLabel}
                          disableAnimation
                          shrink={false}
                        >
                          {values.country ? "" : "Country"}
                        </InputLabel>
                        <Select
                          disabled={!canUpdate}
                          variant="outlined"
                          placeholder="Country"
                          value={values.country}
                          onChange={(event: any) =>
                            setFieldValue("country", event.target.value)
                          }
                          error={!!errors.country}
                          className={classes.selectStyle}
                          MenuProps={{
                            classes: { paper: classes.menuItemsContainer }
                          }}
                        >
                          {!!countries.length ? (
                            countries.map((country: any) => (
                              <MenuItem value={country.code} key={country.code}>
                                {country.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              key={`no-countries-option`}
                              disabled
                              value={""}
                            >
                              {t`No Countries Options`}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>

                    <TextField
                      disabled={!canUpdate}
                      name="city"
                      value={values.city}
                      placeholder={t`City`}
                      variant="outlined"
                      className={classes.inputWrapperStyle}
                      onChange={(event: any) =>
                        setFieldValue("city", event.target.value)
                      }
                      error={!!errors.city}
                    />
                    {values.country === "US" && (
                      <div className={classes.selectContainer}>
                        <FormControl fullWidth>
                          <InputLabel
                            className={classes.selectLabel}
                            disableAnimation
                            shrink={false}
                          >
                            {values.state ? "" : "state"}
                          </InputLabel>
                          <Select
                            disabled={!canUpdate}
                            placeholder="state"
                            variant="outlined"
                            className={classes.selectStyle}
                            value={values.state}
                            onChange={(event: any) =>
                              setFieldValue("state", event.target.value)
                            }
                            error={!!errors.state}
                            MenuProps={{
                              classes: { paper: classes.menuItemsContainer }
                            }}
                          >
                            {!!stateList.length ? (
                              Object.values(stateList).map((state: any) => (
                                <MenuItem value={state.value} key={state.value}>
                                  {state.label}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                key={`no-states-option`}
                                disabled
                                value={""}
                              >
                                {t`No States Options`}
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                    <TextField
                      disabled={!canUpdate}
                      name="postalCode"
                      value={values.postalCode}
                      placeholder={t`Zip`}
                      variant="outlined"
                      className={classes.inputWrapperStyle}
                      onChange={(event: any) =>
                        setFieldValue("postalCode", event.target.value)
                      }
                      error={!!errors.postalCode}
                    />

                    <TextField
                      disabled={!canUpdate}
                      name="description"
                      value={values.description}
                      placeholder={t`Description`}
                      variant="outlined"
                      className={classes.inputWrapperStyle}
                      onChange={(event: any) =>
                        setFieldValue("description", event.target.value)
                      }
                      error={!!errors.description}
                    />
                    <div className={classes.selectContainer}>
                      <FormControl fullWidth>
                        <InputLabel
                          className={classes.selectLabel}
                          disableAnimation
                          shrink={false}
                        >
                          {values.timezone ? "" : "Timezone"}
                        </InputLabel>
                        <Select
                          disabled={!canUpdate}
                          placeholder={t`Timezone`}
                          variant="outlined"
                          value={values.timezone}
                          onChange={(event: any) =>
                            setFieldValue("timezone", event.target.value)
                          }
                          error={!!errors.timezone}
                          className={classes.selectStyle}
                          MenuProps={{
                            classes: { paper: classes.menuItemsContainer }
                          }}
                        >
                          {!!timeZones.length ? (
                            Object.values(timeZones).map((timeZone: any) => (
                              <MenuItem
                                value={timeZone.value}
                                key={timeZone.value}
                              >
                                {timeZone.label}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              key={`no-timeZones-option`}
                              disabled
                              value={""}
                            >
                              {t`No timeZones Options`}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item className={classes.buttonGrid}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.submitButtonStyle}
                      disabled={!dirty || !canUpdate}
                    >
                      {t`Save`}
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditSite;
