import * as React from 'react';

function SvgBoiler(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
      <defs>
        <filter id="boiler_svg__a" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.941176 0 0 0 0 0.317647 0 0 0 0 0.274510 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g filter="url(#boiler_svg__a)" fill="none" fillRule="evenodd">
        <g stroke="#FFF">
          <g strokeLinecap="round" strokeWidth={1.8}>
            <path d="M13.022 9.9a6.97 6.97 0 00-.286 8.193l.286.424h0l.454.692A6.54 6.54 0 0113.022 27h0M18.45 9.9a6.97 6.97 0 00-.287 8.193l.287.424h0l.454.692A6.54 6.54 0 0118.45 27h0M23.878 9.9a6.968 6.968 0 00-.287 8.193l.287.424h0l.454.692A6.54 6.54 0 0123.878 27h0" />
          </g>
          <circle cx={18} cy={18} r={14.3} strokeWidth={2} />
        </g>
      </g>
    </svg>
  );
}

export default SvgBoiler;
