import * as React from 'react';

function SvgWord(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#185ABD"
          strokeWidth={1.4}
          d="M15.91.7a1.3 1.3 0 01.966.43h0l1.804 2.005 3.086 3.43c.215.238.334.548.334.87h0V20a3.29 3.29 0 01-.967 2.333 3.286 3.286 0 01-2.333.967h0H5.2a3.29 3.29 0 01-2.333-.967A3.286 3.286 0 011.9 20h0V4c0-.911.37-1.736.967-2.333A3.286 3.286 0 015.2.7h0z"
        />
        <path
          fill="#185ABD"
          d="M15.062 16.8h-1.43l-1.667-5.796-1.747 5.796h-1.43L7.2 8.4h1.429l1.112 5.88 1.668-5.712H12.6l1.588 5.712L15.3 8.4h1.35z"
        />
      </g>
    </svg>
  );
}

export default SvgWord;
