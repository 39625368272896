import { Collapse, InputLabel, Paper } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import urlFlagMap from "../../constants/urlToFlagMapping";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import MenuDropDown from "../MobileMenuDropDown/MenuDropDown";
import useStyles from "./TopBar.style";

const generalNamesConst = {
  site: t`All Sites`,
  system: t`All Systems`,
  unit: t`All Units`
};

export default function TopSelectionsMenu(props: any) {

  const { screenTitle, hideSitesFilter, hideUnitsFilter, customGeneralNames = {}, close, reload = false, applySiteTypeFiltering, hideOutdoor, hideBsBox, hideIndoor, hideOther } = props;
  const generalNames = { ...generalNamesConst, ...customGeneralNames };
  const types = useStoreState((s) => s.types);
  const { unitTypes } = types;

  const classes = useStyles();
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const updateSelections = useStoreActions((a) => a.selections.updateMobileSelections);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const [filters, setFilters] = useState<any>({ customerId: selections.customerId, siteId: selections.siteId, unitId: selections.unitId });
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const unitTypesMirrror = useStoreState((s) => s.unitTypesMirrror);
  const hasMoreThanCustomer = Object.values(allCustomers)?.length > 1;
  const [customersOptions, setCustomersOptions] = useState<any>([]);
  const [sitesOptions, setSitesOptions] = useState<any>([]);
  const [controlUnits, setControlUnits] = useState<any>([]);
  const [indoorUnits, setIndoorUnits] = useState<any>([]);
  const [outdoorUnits, setOutdoorUnits] = useState<any>([]);
  const [bsBoxUnits, setBsBoxUnits] = useState<any>([]);
  const [otherUnits, setOtherUnits] = useState<any>([]);
  const [firstTimeLoadingOptions, setFirstTimeLoadingOptions] = useState<any>(true);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const userPref = useStoreState((s) => s.users.userPreferences);

  useEffect(() => {
    const sortedCustomerOptions = Object.values(allCustomers)
      .map((customer: any) => ({ ...customer, nickname: userPref?.nicknames?.[customer.id] || "" }))
      .sort((a: any, b: any) => {
        const aName = a.name?.toUpperCase() || ""
        const bName = b.name?.toUpperCase() || ""
        const aNickname = a.nickname?.toUpperCase() || ""
        const bNickname = b.nickname?.toUpperCase() || ""

        if (aNickname && bNickname)
          return aNickname.localeCompare(bNickname)
        else if (aNickname)
          return -1
        else if (bNickname)
          return 1
        else
          return aName.localeCompare(bName)
      })

    const customerItems = buildOptions(sortedCustomerOptions, "customer");
    setCustomersOptions(customerItems);
    setFirstTimeLoadingOptions(false);
  }, []);

  useEffect(() => {
    setSitesOptions(getOptions("site"));
    const controlUnits = getOptions("unit", "control");
    const indoorUnits = getOptions("unit", "indoor");
    const outdoorUnits = getOptions("unit", "outdoor");
    const bsBoxUnits = getOptions("unit", "bsBox");
    const otherUnits = getOptions("unit", "other");
    setControlUnits(controlUnits);
    setIndoorUnits(indoorUnits);
    setOutdoorUnits(outdoorUnits);
    setBsBoxUnits(bsBoxUnits);
    setOtherUnits(otherUnits);

  }, [filters, allUnits, allDevices]);

  const getOptions = (itemType: string, unitFilter?: string) => {
    let items: any = [];
    if (itemType === "site") {
      items = _.orderBy(
        Object.values(allSites).filter((site) => filters.customerId === site.customer && (urlFlagMap[screenTitle] ? sitesFlags[site?.id] && sitesFlags[site.id][urlFlagMap[screenTitle]] : true)),
        [(site) => site.name?.toUpperCase()],
        ["asc"]
      );
    }

    if (itemType === "unit") {
      items = _.orderBy(Object.values(allUnits), ["type", "name"], ["asc", "asc"]).filter((unit: any) => {
        const { site, type } = unit;
        const { isServiceSite } = sitesFlags[site] || {};

        if (!allDevices[unit.device]) { return false; }
        if (!allSites[unit.site]) { return false; }
        if (!allCustomers[unit.customer]) { return false; }

        const filterType = unitFilter === "control" ? unitTypes?.indoor : unitFilter === "indoor" ? unitTypes?.service : unitTypes[unitFilter || ""];
        if (!!unitFilter && type !== filterType || !unit.isVisible) {
          return false;
        }
        if (!applySiteTypeFiltering && type !== unitTypes?.indoor) {
          return false;
        }
        if (applySiteTypeFiltering && isServiceSite && type === unitTypes?.indoor) {
          return false;
        }

        if (applySiteTypeFiltering && !isServiceSite && (type === unitTypes?.service || type === unitTypes?.other || type === unitTypes?.outdoor || type === unitTypes.bsBox)) {
          return false;
        }

        return (
          (filters.customerId ? unit.customer === filters.customerId : true) &&
          (filters.siteId ? unit.site === filters.siteId : true) && (urlFlagMap[screenTitle] ? sitesFlags[unit?.site] && sitesFlags[unit.site][urlFlagMap[screenTitle]] : true)
        );
      });
    }

    return buildOptions(items, itemType);
  };

  const getUnitName = (unit: any) => {
    if (unit.type !== 3) {
      return unit.name;
    }

    const controlUnitId = unit.controlUnit;
    const controlUnitName = allUnits[controlUnitId]?.name || t`Unassigned`;
    const servAddress = unit.address;

    return `${controlUnitName} (${servAddress})`;
  };

  const buildOptions = (items: any, itemType: string) => {
    const options = items.map((item: any) => {
      if (itemType === "unit") {
        const type = item.type === 3 ? "indoor" : (unitTypesMirrror[item.type] || "");
        return {
          value: item.id,
          type,
          label: getUnitName(item)
        };
      } else {
        return {
          label: `${item.nickname || item.name}`,
          value: item.id
        };
      }
    });

    if (itemType !== "unit") {
      if (options.length) {
        options.unshift({ value: "", label: generalNames[itemType] });
      } else {
        options.unshift({ value: "", label: t`No ${itemType}s` });
      }
    }

    return options;
  };

  const submitFilters = (newFilters: any) => {
    close();
    updateSelections(newFilters);
  };

  const numberOfMenus = 3 - [!hasMoreThanCustomer, hideUnitsFilter, hideSitesFilter].filter(Boolean).length;

  if (firstTimeLoadingOptions || numberOfMenus === 0) {
    return null;
  }

  const unitsOptions = [...indoorUnits, ...controlUnits, ...outdoorUnits, ...bsBoxUnits, ...otherUnits];
  const allUnitsOptions = unitsOptions.length ? [{ value: "", label: t`All Units` }, ...unitsOptions] : [{ value: "", label: t`No Units` }];

  return (
    <Collapse in={true} collapsedHeight={40} className={classes.collapse}>
      <Paper elevation={4} className={clsx(classes.paper, { [classes.smallPaper]: numberOfMenus === 2, [classes.extraSmallPaper]: numberOfMenus === 1 })}>
        <div>
          {hasMoreThanCustomer && <div className={classes.dropDownContainer}>
            <InputLabel className={classes.label}>
              {t`Customer`}
            </InputLabel >
            <MenuDropDown
              onChange={(id: string) => {
                setFilters({ customerId: id, siteId: null, unitId: null });
                if (hideUnitsFilter && hideSitesFilter)
                  submitFilters({ customerId: id, siteId: null, unitId: null });
              }}
              value={filters.customerId}
              options={customersOptions}
            />
          </div>}
          {!hideSitesFilter && <div className={classes.dropDownContainer}>
            <InputLabel className={classes.label}>
              {t`Site`}
            </InputLabel>
            <MenuDropDown
              onChange={(id: string) => {
                setFilters({ ...filters, siteId: id, unitId: null });
                if (hideUnitsFilter || screenTitle === "alerts" || screenTitle === "audits")
                  submitFilters({ ...filters, siteId: id, unitId: null });
              }}
              value={filters.siteId}
              options={sitesOptions}
            />
          </div>}
          {!hideUnitsFilter && <div className={classes.dropDownContainer}>
            <InputLabel className={classes.label}>
              {t`Units`}
            </InputLabel>
            <MenuDropDown
              onChange={(id: string) => {
                const newFilters = !id ? ({ ...filters, unitId: null }) : ({ customerId: allUnits[id]?.customer || null, siteId: allUnits[id]?.site || null, unitId: id });
                setFilters(newFilters);
                submitFilters(newFilters);
              }}
              value={filters.unitId}
              options={allUnitsOptions}
            />
          </div>}
        </div>
      </Paper>
    </Collapse>
  );
}
