import React from "react";

const SvgAutoCopy = (props) => (
  <svg width={29} height={39} viewBox="0 0 29 39" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.maincolor || "#FDFDFE"}
      strokeLinecap="round"
      strokeWidth={1.8}
      opacity={props.opacity}
    >
      <path d="M4.68 29.027a13.716 13.716 0 01-4.028-9.726c0-7.595 6.158-13.753 13.754-13.753 1.575 0 3.087.265 4.497.752m5.122 3.173a13.716 13.716 0 014.133 9.829c0 7.596-6.157 13.754-13.753 13.754a13.71 13.71 0 01-4.74-.84" />
      <path d="M14.822 38.056L9.056 32.29l5.766-5.764M14.04.651l5.766 5.766-5.766 5.765" />
    </g>
  </svg>
);

export default SvgAutoCopy;
