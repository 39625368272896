import * as React from "react";

function SvgSchedulesCalendar(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <linearGradient
          id="SchedulesCalendar_svg__a"
          x1="100%"
          x2="0%"
          y1="26.858%"
          y2="28.597%"
        >
          <stop offset="0%" stopColor="#421A40" />
          <stop offset="100%" stopColor="#29132E" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          cx={12.5}
          cy={12.5}
          r={12.5}
          fill="url(#SchedulesCalendar_svg__a)"
        />
        <path
          fill="#E5E2E5"
          d="M8 4.763h1.5v1.5H8zm.75 7.75h1.5v1.5h-1.5zm3 0h1.5v1.5h-1.5zm3 0h1.5v1.5h-1.5zm.75-7.75H17v1.5h-1.5z"
        />
        <rect
          width={12.6}
          height={12.1}
          x={6.2}
          y={6.963}
          stroke="#E5E2E5"
          strokeWidth={1.4}
          rx={2}
        />
        <path fill="#E5E2E5" d="M6 7h13v4H6z" />
      </g>
    </svg>
  );
}

export default SvgSchedulesCalendar;
