import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
    iconBtnStyle: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    },
    dialogHeader: {
        paddingLeft: 20,
        paddingRight: 13,
        ...theme.dialogs.header,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        minHeight: 60
    },
    actionsHolder: {
        padding: "20px 0",
        display: "flex",
        justifyContent: "center"
    },
    headerTitle: {
        ...theme.dialogs.title
    },
    dialogContent: {
        flex: "1 1 auto",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        minWidth: "650px"
    },
    dropZone: {
        width: "90%",
        borderWidth: "2px",
        borderColor: "#BDB8BD"
    },
    dialog: {
        borderRadius: "0.5rem",

        boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
        border: " 1px solid #d5d2d5"
    },
    titleContent: {
        paddingLeft: 20,
        paddingRight: 13,
        ...theme.dialogs.header,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        minHeight: 60
    },
    overWriteIcon: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    },
    boldText: {
        fontWeight: "bold"
    },
    DialogContentText: {
        fontFamily: "Roboto",
        color: "#545964"
    },
    yesBtn: {
        marginRight: "17px"
    },
    headerSection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "30%",
        alignItems: "flex-start",
        "& button": {
            width: "200px",
            dispaly: "flex",
            justifyContent: "flex-start",
            margin: 0,
            marginLeft: "16px"
        }
    },
    dialogPaper: {
        maxWidth: "100% !important",
        maxHeight: "100% !important",
        width: "75%",
        height: "95%"
    },
    dialogPaperNewFP: {
        maxWidth: "unset",
        maxHeight: "unset",
        width: "unset",
        height: "unset"
    }
}));
export default useStyles;
