
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { useStoreState } from "../../models/RootStore";
import CustomDatesList from "./CustomDatesList/CustomDatesList";
import useStyles from "./SetbackScheduleTable.style";

const SetbackScheduleTable = (props: any) => {
    const { setbackSchedules, deleteSelectedSchedule, updateSetBackStatus, setOpenAddEdit } = props;
    const classes = useStyles();
    const allSites = useStoreState((s) => s.sites.allSites);
    const types = useStoreState((state) => state.types);
    const { scheduleCategories } = types;

    const renderDays = (setbackSchedule: any) => {
        const { scheduleCategory, days, dates } = setbackSchedule;
        switch (scheduleCategory) {
            case scheduleCategories.weekly:
                return <>
                    {days.map((day: any, index: number) => {
                        return <span className={classes.daysItem} key={day}> {day?.substring(0, 3)} </span>;
                    })}
                </>;

            case scheduleCategories.calendar:
                return <CustomDatesList dates={dates} />;
            default:
                return <></>;
        }
    };

    return (
        <Paper elevation={0} className={classes.paperTableContainer}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                align="left"
                            >{t`Name`}
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                align="left"
                            >{t`Site`}
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                align="left"
                            >{t`Days`}
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                align="center"
                            >
                                {t`STATUS`}
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.tableHeadCell }}
                                align="right"
                            >
                                {t`REMOVE`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {setbackSchedules.map((setbackSchedule: any) => {
                            const { id, name, units, site, permissions, isDisabled } = setbackSchedule;
                            return (
                                <TableRow
                                    hover={permissions?.canUpdate}
                                    tabIndex={-1}
                                    key={id}
                                    onDoubleClick={() => { if (permissions?.canUpdate) setOpenAddEdit(setbackSchedule); }}
                                    className={classes.tableRow}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{ root: clsx(classes.overWritePadding, classes.nameCell) }}
                                        align="left"
                                    >
                                        {name}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{ root: clsx(classes.overWritePadding, classes.nameCell) }}
                                        align="left"
                                    >
                                        {allSites[site]?.name}
                                    </TableCell>

                                    <TableCell
                                        classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                        align="left"
                                    >
                                        {renderDays(setbackSchedule)}
                                    </TableCell>

                                    <TableCell classes={{ root: classes.overWritePadding }} align="center">
                                        <CoolSwitch
                                            className={!permissions?.canUpdate && classes.disabledStyle}
                                            disabled={!permissions?.canUpdate}
                                            switchChange={() => {
                                                updateSetBackStatus(setbackSchedule);
                                            }}
                                            checked={!isDisabled}
                                            name="is2FA"
                                        />
                                    </TableCell>

                                    <TableCell classes={{ root: classes.overWritePadding }} align="right">
                                        <Delete
                                            disabled={!permissions?.canDelete}
                                            type={t`Schedule`}
                                            object={setbackSchedule}
                                            detach={() => deleteSelectedSchedule(id)}
                                            buttonClass={classes.deleteIcon}
                                        ></Delete>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default SetbackScheduleTable;
