import { Grid, IconButton, Typography, useTheme } from "@material-ui/core/";
import { AccessTime } from "@material-ui/icons/";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PowerMeterItem, UnitItem } from "../";
import { Switch } from "../../../../cool_widgets/Switch";
import { ArrowDownO } from "../../../../icons";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { SensorItem } from "../SensorItem";
import useStyle from "./GroupItem.style";

const ControlItem = (props: any) => {
  const { canControlSiteOperationStatus = true, openCollapse = true, addMessage, disableDragging, handleDragging, item, siteId, selectItem, selectedItem, changeSitePower, onDragEnd, isCelsius } = props;
  const {
    id: itemId,
    name,
    units = [],
    sensors = [],
    schedules = [],
    powerMeters = [],
    itemsOrder = [],
    permissions = {}
  } = item;
  const { canControlOperationStatus } = permissions;


  const [open, setOpen] = useState<boolean>((selectedItem?.firstTimeLoading && selectedItem.groupId === itemId) || false);
  const operationStatusesMirror = useStoreState((state) => state.operationStatusesMirror);
  const groupPowerControl = useStoreActions((state) => state.groups.changePowerState);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const setUnitsActiveOperationStatus = useStoreActions((state) => state.units.setUnitsActiveOperationStatus);
  const devices = useStoreState((state) => state.devices.allDevices);
  const allSensors = useStoreState((state) => state.sensors.allSensors);
  const getUnitsBy = useStoreState((state) => state.units.getUnitsBy);
  const theme: any = useTheme();

  const { on: powerOnVal } = operationStatusesMirror;
  const [disablePowerBtn, setDisablePowerBtn] = useState<boolean>(false);
  const classes = useStyle();

  useEffect(() => {
    if (!openCollapse)
      setOpen(true);
  }, [openCollapse]);

  useEffect(() => {
    let oneAtleastIsConnected: boolean = false;
    for (let x in units) {
      const id = units[x];
      const unit: any = allUnits[id];

      if (!unit || !unit?.isConnected || !devices[unit?.device || ""]?.isConnected) {
        continue;
      }

      oneAtleastIsConnected = true;
    }

    if (!oneAtleastIsConnected) {
      for (let x in sensors) {
        const id: any = sensors[x];
        const sensor = allSensors[id];

        if (!sensor || !devices[sensor?.device || ""]?.isConnected) {
          continue;
        }
        if (sensor?.type === 129) {
          oneAtleastIsConnected = true;
        }
      }
    }
    setDisablePowerBtn(!oneAtleastIsConnected);
  }, [item, powerOnVal]);

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const changeGroupPowerState = (event: any, powerOn: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    const state = powerOn ? 1 : 2;
    const siteUnits = getUnitsBy("site", siteId, { type: "control" });
    const connectedUnits = units.filter((id: any) => {
      const unitObj = allUnits[id];
      if (!unitObj) {
        return false;
      }
      const isUnitConnected = unitObj?.isConnected;
      const isDeviceConnected = devices[unitObj?.device]?.isConnected;
      return isUnitConnected && isDeviceConnected;
    });

    itemId === "allUnits" ? (siteUnits.length === units?.length) ? changeSitePower({ state, siteId }) : setUnitsActiveOperationStatus({ units: connectedUnits, operationStatus: state }) : groupPowerControl({ groupId: itemId, state })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const onUnitsCount = units.filter((id: any) => {
    const unitObj = allUnits[id];
    if (!unitObj) {
      return false;
    }

    const isUnitOn = unitObj?.activeOperationStatus === +powerOnVal;
    const isUnitConnected = unitObj?.isConnected;
    const isDeviceConnected = devices[unitObj?.device]?.isConnected;
    return isUnitOn && isUnitConnected && isDeviceConnected;
  }).length
    + sensors.filter((id: any) => {
      const sensor = allSensors[id];

      return sensor?.type === 129 && sensor?.readingValue === 1 && devices[sensor?.device]?.isConnected;
    }).length;

  return (
    <Fragment>
      <div
        {...handleDragging}
        className={clsx(classes.groupStyle, { [classes.selected]: selectedItem.isGroup && selectedItem.groupId === itemId })}
        onClick={() => selectItem(siteId, { isGroup: true, groupId: itemId, unitId: "", type: "" })}
      >
        <IconButton onClick={(event: any) => { event.stopPropagation(); setOpen(!open); }} className={classes.iconHolder}>
          <ArrowDownO style={{ transform: open ? "unset" : "rotate(270deg)" }}
            fill={theme.palette.background.controlArrowDown || null} />
        </IconButton>
        <div className={classes.innerContainer}>
          <div className={classes.locSec}>
            <Typography noWrap className={clsx(classes.nameStyle, { [classes.boldText]: selectedItem.isGroup && selectedItem.groupId === itemId })}>{name}</Typography>
          </div>
          {itemId !== "powerMeters" &&
            <Grid container className={clsx(classes.powerAlertsSchedulesContainer, { [classes.hidden]: itemId === "sensors" || (units.length === 0 && sensors.length === 0) })}>
              <div className={classes.notesSec}>
                {schedules.length > 0 && <AccessTime className={classes.noteIcon} />}
              </div>
              <Typography className={classes.unitsCount}>{onUnitsCount}/{(units.length + sensors.length)}</Typography>

              <Switch
                checked={onUnitsCount > 0}
                disableRipple={true}
                onClick={(e: any) => switchClick(e)}
                switchChange={(e: any) => changeGroupPowerState(e, !(onUnitsCount > 0))}
                value="checkedA"
                disabled={disablePowerBtn || (itemId === "allUnits" ? !canControlSiteOperationStatus : !canControlOperationStatus)}
              />
            </Grid>}
        </div>
      </div>
      <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(itemId)}>
        <Droppable droppableId="list">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {open &&
                itemsOrder.map((item: any, index: number) => {
                  const [id, show, type = "unit"] = item;
                  if (!show) {
                    return null;
                  }
                  if (allUnits[id]) {
                    return (
                      <Draggable isDragDisabled={disableDragging} key={`group-${itemId}-unit-${id}`} draggableId={id} index={index}>
                        {(provided: any) => (<div ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <UnitItem addMessage={addMessage} key={`u-group-${itemId}-unit-${id}`} selectedItem={selectedItem} unitId={id} siteId={siteId} selectItem={selectItem} groupId={itemId} hasSchedules={true} /></div>)}
                      </Draggable>
                    );
                  }
                  if (allSensors[id] && allSensors[id].isVisible) {
                    return (
                      <Draggable isDragDisabled={disableDragging} key={`group-${itemId}-sensor-${id}`} draggableId={id} index={index}>
                        {(provided: any) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <SensorItem selectedItem={selectedItem} sensorId={id} siteId={siteId} selectItem={selectItem} groupId={itemId} isCelsius={isCelsius} />
                          </div>)}
                      </Draggable>
                    );
                  }
                  return null;
                })
              }

              {open && !_.isEmpty(powerMeters) && powerMeters.map((powerMeter: any) =>
                !powerMeter[1] ? null : <PowerMeterItem key={`group-${itemId}-powerMeter-${powerMeter[0]}`} id={powerMeter[0]} selectedItem={selectedItem} siteId={siteId} selectItem={selectItem} groupId={itemId} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );

};

export default ControlItem;
