import {
  Customer as sdkCustomer,
  Site as sdkSite,
  Scheduler as sdkScheduler,
} from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";

interface ISchedulePayload {
  id: string;
  data: object;
}

interface IGetSchedulePayload {
  id: string | any;
  objectType: "unit" | "group" | "system";
  type?: undefined | string;
}

interface ICreateSchedulePayload {
  objId: string;
  objectType: "unit" | "group" | "system";
  data: any;
}

export interface IScheduleModel {
  schedules: any;
  getSchedule: Thunk<IScheduleModel, string, any>;
  getCustomerSchedules: Thunk<
    IScheduleModel,
    string | null | { id: any; type: any }
  >;
  deleteSchedule: Thunk<IScheduleModel, string, any>;
  updateSchedule: Thunk<IScheduleModel, ISchedulePayload, any>;
  removeSchedule: Action<IScheduleModel, any>;
  getObjectSchedules: Thunk<IScheduleModel, IGetSchedulePayload, any>;
  createObjectSchedules: Thunk<IScheduleModel, ICreateSchedulePayload, any>;
  createUnitsSchedule: Thunk<IScheduleModel, any>;
  getSiteSetbackSchedule: Thunk<
    IScheduleModel,
    string | null | { id: any; type: any }
  >;
  updateSetbackSchedule: Thunk<IScheduleModel, ISchedulePayload, any>;
  createSetbackSchedule: Thunk<IScheduleModel, any>;
}

export const scheduleModel: IScheduleModel = {
  schedules: [],
  getSchedule: thunk((actions, payload) => {
    return sdkScheduler.getScheduleById(payload);
  }),
  getCustomerSchedules: thunk((actions, payload) => {
    return sdkCustomer.getSchedules(payload);
  }),
  getSiteSetbackSchedule: thunk((actions, payload) => {
    return sdkSite.getSiteSetbackSchedule(payload);
  }),
  updateSetbackSchedule: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkScheduler.updateSetbackSchedule(id, data);
  }),
  createSetbackSchedule: thunk((actions, payload) => {
    const { data } = payload;
    return sdkScheduler.createSetbackSchedule(data);
  }),
  deleteSchedule: thunk((actions, payload) => {
    return sdkScheduler.delete(payload);
  }),
  removeSchedule: action((state, payload) => {
    const { schedules: newScedules } = state;
    delete newScedules[payload];

    state.schedules = newScedules;
  }),

  updateSchedule: thunk((actions, payload) => {
    const { id, data } = payload;

    return sdkScheduler.update(id, data);
  }),
  getObjectSchedules: thunk((actions, payload) => {
    const { id, objectType, type } = payload;
    return sdkScheduler.getObjectScheduleById(objectType, id, type);
  }),
  createObjectSchedules: thunk((actions, payload) => {
    const { objId, data, objectType } = payload;
    return sdkScheduler.createObjectSchedule(objectType, objId, data);
  }),
  createUnitsSchedule: thunk((actions, payload) => {
    return sdkScheduler.createUnitsSchedule(payload);
  }),
};
