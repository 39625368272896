import { Divider, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import { systemInfoPanelStyle } from "./SystemInfoPanel.style";

interface IProps {
  system: ISystem;
  systemServiceUnitsLength?: any;
  systemOutdoorUnitsLength?: any;
  systemBsBoxUnitsLength?: any;

}

const SystemInfoPanel: React.FC<IProps> = (props: IProps) => {
  const { system, systemServiceUnitsLength, systemOutdoorUnitsLength, systemBsBoxUnitsLength } = props;
  const classes = systemInfoPanelStyle();

  const allDevices = useStoreState((state) => state.devices.allDevices);
  const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);
  const capacityMeasurementUnitTypes = useStoreState(
    (state) => state.types.capacityMeasurementUnitTypes
  );
  const hvacBrands = useStoreState((state) => state.types.hvacBrands);
  const systemDevice: IDevice | undefined = allDevices[system.device];
  const brandName = !_.isNil(_.find(hvacBrands, { value: props.system.brandNum })) && _.find(hvacBrands, { value: system.brandNum }).name;
  const systemCapacityMeasurementUnitsText: string =
    !_.isNil(system.capacityMeasurementUnits) &&
      !_.isNil(capacityMeasurementUnitTypes) &&
      !_.isUndefined(
        _.findKey(
          capacityMeasurementUnitTypes,
          (cmuType) => cmuType === system.capacityMeasurementUnits
        )
      )
      ? `${_.findKey(
        capacityMeasurementUnitTypes,
        (cmuType) => cmuType === system.capacityMeasurementUnits
      )}`
      : "";

  const textualPairs = [
    {
      title: t`Series`,
      text: system.series
    },
    {
      title: t`System`,
      text: system.name
    },
    {
      title: t`Type`,
      text: system.type

    },
    {
      title: t`Capacity`,
      text: !_.isNil(system.capacity)
        ? `${system.capacity}` + systemCapacityMeasurementUnitsText
        : ""
    },
    {
      title: t`ODU/IDU/BS`,
      text: systemDevice
        ? (systemOutdoorUnitsLength ? systemOutdoorUnitsLength : getDeviceUnits(systemDevice.id, system.id, "outdoor").length) +
        " / " +
        (systemServiceUnitsLength || systemServiceUnitsLength === 0 ? systemServiceUnitsLength : [...getDeviceUnits(systemDevice.id, system.id, "service"), ...getDeviceUnits(systemDevice.id, system.id, "other")].length) +
        " / " +
        (systemBsBoxUnitsLength ? systemBsBoxUnitsLength : getDeviceUnits(systemDevice.id, system.id, "bsBox").length)
        : "none"
    }

  ];

  return (
    <Grid container spacing={1} className={classes.infoPanel} style={{ width: "19rem" }}>
      <Grid item xs={6}>
        <Typography className={classes.infoLabel}>{t`Brand`}</Typography>
      </Grid>
      <Grid item xs={6}>
        <img
          width={60}
          height={25}
          src={assetsService.getBrandLogo(
            `${system.brandNum}-grey.svg`
          )}
          onError={(event: any) =>
            (event.target.src = assetsService.getBrandLogo("empty.png"))
          }
          alt={brandName}
          className={classes.brandLogo}
        />
      </Grid>
      {_.map(textualPairs, (textualPair) => (
        <React.Fragment key={textualPair.title}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.infoLabel}>
              {textualPair.title}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ minWidth: "15ch", maxWidth: "15ch", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <Typography className={classes.infoData}>
              {textualPair.text}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default SystemInfoPanel;
