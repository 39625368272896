import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import tinycolor2 from "tinycolor2";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import {
  ArrowDownControl,
  ArrowUp,
  AutoMode,
  AutoVRMode,
  ColdMode,
  ControlWrapper,
  DryIcon,
  HotMode,
  ModeFanIcon,
  ScheduleOffIcon,
  SwitcherOffIcon,
  SwitcherOnIcon
} from "../../../../svgComponents";
import groupPanelStyles from "./GroupPanel.style";

interface IObject {
  [key: string]: any;
}

const colorRanges: any = {
  COOL: ["#35a8e0", "#2d2e82"],
  HEAT: ["#f8b133", "#f05146"],
  AUTO: ["#35a8e0", "#f05146"],
  DRY: ["#266101", "#266101"],
  FAN: ["#4f00ed", "#4f00ed"],
  default: ["#ffffff", "#000000"]
};

const modeLimits: any = {
  1: [16, 32],
  2: [60, 90]
};

const fDegree = 360 / 118;
const cDegree = 360 / 48;

const GroupPanel: React.FC<any> = (props) => {
  const useStyles = makeStyles(groupPanelStyles);
  const classes = useStyles();
  const theme = useTheme();

  const modeIcons: IObject = {
    COOL: <ColdMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    HEAT: <HotMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    AUTO: (
      <AutoMode
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    "Virtual Auto": (
      <AutoVRMode
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    DRY: (
      <DryIcon
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    FAN: (
      <ModeFanIcon
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    )
  };

  const changeGroupPower = useStoreActions((state) => state.groups.changePowerState);
  const changeGroupSetPoint = useStoreActions((state) => state.groups.changeSetPoint);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const devices = useStoreState((state) => state.devices.allDevices);
  const allSensors = useStoreState((state) => state.sensors.allSensors);

  const {
    group,
    navigateToSchedules,
    selectedSiteId,
    types,
    temperatureScale: userTempScale,
    addMessage,
    operationStatusesMirror,
    isCelsius,
    siteTemp,
    tempSymbol,
    changeSitePower,
    siteId,
    hideSchedules = false,
    canControlSiteOperationStatus = true
  } = props;
  const {
    id: groupId = "",
    units: groupUnits = [],
    name: groupName = "",
    schedules = [],
    sensors: groupSensors,
    permissions = {}
  } = group || {};

  const { canControlSetpoint, canControlOperationStatus } = permissions;

  const [currentColor, setCurrentColor] = useState<any>("#888");
  const [tempLimits, setTempLimits] = useState<any>(modeLimits[userTempScale]);
  const [angles, setAngles] = useState<any>([0, 360]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [mode, setMode] = useState<any>(null);
  const [setpoint, setSetpoint] = useState<any>(null);
  const [power, setPower] = useState<boolean>(false);
  const [keepUserChange, handleKeepingUserChange] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [resetGroupData, handleResetGroupData] = useState<boolean>(false);
  const [enableSetpointHandlers, setEnableSetpointHandlers] = useState<boolean>(false);

  const hasSchedules = schedules.length > 0;
  const { operationModesExtended } = types;
  const powerOnVal = operationStatusesMirror && +operationStatusesMirror.on;

  useEffect(() => {
    if (keepUserChange) {
      return;
    }

    let power: boolean = false;
    let setPoint: any = null;
    let mode: any = null;
    let first: boolean = true;
    let oneAtleastIsConnected: boolean = false;
    let allUnitsHasSetpointControl: boolean = false;
    let checkForNum: boolean = false;
    let checkForNumMode: boolean = false;

    for (let x in groupUnits) {
      const id = groupUnits[x];
      const unit = allUnits[id] || {};
      if (!unit || unit?.subType === 1 || !unit?.isVisible || !unit?.isConnected || !devices[unit?.device || ""]?.isConnected) {
        continue;
      }
      const { capabilityFlags = {} } = unit;
      const { enableOperationModeSelection = false, enableSetpointControl = false } = capabilityFlags;

      oneAtleastIsConnected = true;
      if (first) {
        power = unit.activeOperationStatus === powerOnVal;
        setPoint = enableSetpointControl ? unit.activeSetpoint : null;
        mode = enableOperationModeSelection ? unit.activeOperationMode : null;
        first = false;
        allUnitsHasSetpointControl = enableSetpointControl;
        if (!checkForNum && enableSetpointControl) {
          checkForNum = true;
        }
        if (!checkForNumMode && enableOperationModeSelection) {
          checkForNumMode = true;
        }
        continue;
      }
      if (!checkForNum && enableSetpointControl) {
        checkForNum = true;
        setPoint = unit.activeSetpoint;
      }
      if (!checkForNumMode && enableOperationModeSelection) {
        checkForNumMode = true;
        mode = unit.activeOperationMode;
      }
      if (!power && unit.activeOperationStatus === powerOnVal) {
        power = true;
      }
      if (enableSetpointControl && setPoint !== null && setPoint !== unit.activeSetpoint) {
        setPoint = null;
      }
      if (enableOperationModeSelection && mode !== null && mode !== unit.activeOperationMode) {
        mode = null;
      }
      if (allUnitsHasSetpointControl) {
        continue;
      }
      allUnitsHasSetpointControl = enableSetpointControl;
    }
    if (!oneAtleastIsConnected || !power) {
      for (let x in groupSensors) {
        const id = groupSensors[x];
        const sensor: any = allSensors[id];
        if (!sensor || !devices[sensor?.device || ""]?.isConnected) {
          continue;
        }
        if (sensor?.type === 129) {
          oneAtleastIsConnected = true;
          if (sensor?.readingValue === 1) {
            power = true;
          }
        }
      }
    }

    const modeText = mode === null ? "default" : operationModesExtended[mode];
    const limits = modeLimits[userTempScale];
    const defaultSetpoint = setPoint === null ? isCelsius ? 24 : 75 : setPoint;
    const minLimit = Math.min(limits[0], defaultSetpoint);
    const maxLimit = Math.max(limits[1], defaultSetpoint);
    const degree = isCelsius ? cDegree : fDegree;

    setTempLimits([minLimit, maxLimit]);
    setAngles([degree * minLimit, degree * maxLimit]);
    setSetpoint(setPoint === null ? "--" : Math.round(setPoint));
    setMode(modeText);
    setPower(power);
    setDisabled(!oneAtleastIsConnected);
    isFirstLoad && setIsFirstLoad(false);
    setEnableSetpointHandlers(allUnitsHasSetpointControl);
  }, [groupId, allUnits, resetGroupData, userTempScale, allSensors]);

  const delayedCall = useCallback(_.debounce((newValue: any) => {
    changeGroupSetPoint({ groupId, setPoint: newValue })
      .then(() => setTimeout(() => handleKeepingUserChange(false), 2000))
      .catch((err: any) => {
        addMessage({ message: err.message });
        handleKeepingUserChange(false);
        handleResetGroupData(!resetGroupData);
      });
  }, 5000), [resetGroupData]);

  const changeTemp = (isAdd: boolean) => {
    const newValue = setpoint === "--" ? (isCelsius ? 24 : 75) : (isAdd ? setpoint + 1 : setpoint - 1);
    !keepUserChange && handleKeepingUserChange(true);
    setSetpoint(newValue);
    delayedCall(newValue);
  };
  const handleTemp = (value: number) => {
    !keepUserChange && handleKeepingUserChange(true);
    setSetpoint(value);
    delayedCall(value);
  };

  useEffect(() => {
    if (setpoint === null || mode === null) {
      return;
    }
    const range = colorRanges[mode] ? colorRanges[mode] : colorRanges["default"];
    const c1 = range[0];
    const c2 = range[1];
    const absoluteVal = ((setpoint - tempLimits[0]) / (tempLimits[1] - tempLimits[0])) * 100;
    const c3 = tinycolor2.mix(c1, c2, absoluteVal);
    setCurrentColor(c3.toHexString());
  }, [setpoint, mode]);

  const togglePower = () => {
    const state = power ? 2 : 1; //if power is on sends off value and vice versa
    groupId === "allUnits" ? changeSitePower({ state, siteId }) :
      changeGroupPower({ groupId, state })
        .catch((error: any) => addMessage({ message: error.message }));
  };

  const noControl = mode === "DRY" || mode === "FAN" || groupId === "allUnits" || !enableSetpointHandlers;
  if (groupId === "sensors" || _.isEmpty(groupUnits)) {
    return (<> </>);
  }

  return (
    <div className={classes.groupView}>
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? Math.round(siteTemp.C) || "" : Math.round(siteTemp.F) || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography>{groupName}</Typography>
          <div className={classes.loaderContainer}>
          </div>
        </div>

        <div className={clsx(classes.powerContainer, { [classes.disabled]: disabled })}>
          <IconButton
            className={classes.powerIconStyle}
            onClick={() => togglePower()}
            disabled={disabled || (groupId === "allUnits" ? !canControlSiteOperationStatus : !canControlOperationStatus)}
          >
            {power ?
              <SwitcherOnIcon className={classes.powerOffButtonClick} /> :
              <SwitcherOffIcon className={classes.powerOnButtonClick} />
            }
          </IconButton>
          <Typography className={classes.powerTextStyle}>
            {power ? t`POWER OFF` : t`POWER ON`}
          </Typography>
        </div>
      </div>

      <div className={classes.secondRowContainer}>
        <div className={classes.controlDiv} >
          <ControlWrapper
            className={clsx(classes.controlWrapperStyle)}
            fill={power ? currentColor : "rgba(253,253,254, 0.5)"}
            width={336}
            height={326}
          />
          {!noControl ? (
            <Fragment>
              <div
                className={clsx(classes.sliderContainer)}
              >
                {<CircularSlider
                  size={320}
                  minValue={tempLimits[0]}
                  maxValue={tempLimits[1]}
                  startAngle={angles[0]}
                  endAngle={angles[1]}
                  coerceToInt={true}
                  angleType={{
                    direction: "cw",
                    axis: "-y"
                  }}
                  handle1={{
                    value:
                      setpoint === "--" ? tempLimits[0] : setpoint,
                    onChange: (value: any) => canControlSetpoint && handleTemp(value)
                  }}
                  arcColor={theme.palette.primary.light}
                  arcBackgroundColor={theme.palette.primary.light}
                  disabled={disabled || groupId === "allUnits"}
                />}
              </div>
              <div
                className={clsx(classes.innerControls)}
              >
                {(canControlSetpoint && groupId !== "allUnits") && <IconButton
                  onClick={() => changeTemp(true)}
                  className={clsx(classes.controlArrow, { [classes.disabled]: disabled || (tempLimits[1] - 1 < setpoint) })}
                  disabled={disabled || (tempLimits[1] - 1 < setpoint)}
                >
                  <ArrowUp />
                </IconButton>}

                <div className={classes.localSetpoint}>
                  <Typography className={clsx(classes.setPointStyle)}>  {setpoint === "--" || setpoint === null ? "--" : Math.round(setpoint)}</Typography>
                  <span>{isCelsius ? t`°C` : t`°F`}</span>
                </div>
                <div className={clsx(classes.ampTempContainer, classes.textColor)}>
                  {modeIcons[mode] || (mode !== "default" ? mode : "")}
                </div>
                {(canControlSetpoint && groupId !== "allUnits") && <IconButton
                  onClick={() => changeTemp(false)}
                  className={clsx(classes.controlArrow, { [classes.disabled]: disabled || (tempLimits[0] + 1 > setpoint) })}
                  disabled={disabled || (tempLimits[0] + 1 > setpoint)}
                >
                  <ArrowDownControl />
                </IconButton>}
              </div>
            </Fragment>
          ) : (
            <Typography className={classes.modeTitle}>{mode === "default" ? "--" : mode}</Typography>
          )}
        </div>
      </div>

      {groupId !== "allUnits" && <div className={classes.lastRowContainer}>
        <div className={classes.iconContainer}>
          {!hideSchedules &&
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.mainIconsStyle}
              name="schedule"
              onClick={() => navigateToSchedules(selectedSiteId, groupId)}
            >
              <ScheduleOffIcon />
              {hasSchedules && <span className={classes.badge}>{schedules.length}</span>}
            </IconButton>}
        </div>
      </div>}
    </div>
  );
};

export default GroupPanel;
