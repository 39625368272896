import {
  Paper,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AddEditGroupsPopup from "./AddEditGroupsPopup";
import ClickableRow from "./ClickableRow";
import useStyles from "./Groups.style";
import Row from "./Row";

const Groups: React.FC = (props: any) => {
  const classes = useStyles();

  const getSiteGroups = useStoreActions((a) => a.groups.getSiteGroups);
  const deleteGroup = useStoreActions((a) => a.groups.deleteGroupAPI);
  const updateGroup = useStoreActions((a) => a.groups.updateGroupAPI);
  const createGroup = useStoreActions((a) => a.groups.createGroup);
  const addGroup = useStoreActions((a) => a.groups.addGroup);
  const { addMessage } = useStoreActions((a) => a.errorMessage);

  const isInitialized = useStoreState((s) => s.isInitialized);
  const types = useStoreState((s) => s.types);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const sites = useStoreState((s) => s.sites.allSites);
  const allSensors = useStoreState((s) => s.sensors.allSensors);
  const { selections } = useStoreState((s) => s.selections);
  const { customerId, siteId = "" } = selections;
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const { canAddGroup = true } = getCustomer(customerId) || {};

  const [units, setUnits] = useState<any>({});
  const [sensors, setSensors] = useState<any>({});
  const [groups, setGroups] = useState<any>({});
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [editIdGroup, setEditIdGroup] = useState<string>("");
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [isDataReady, setDataReady] = useState<boolean>(false);
  const site = sites[siteId || ""];

  useEffect(() => {
    const { sensorTypes } = types;
    const sensors: any = {};

    Object.values(allSensors).forEach((sensor: any) => {
      if (sensorTypes[sensor.type]?.enableView) {
        sensors[sensor.id] = sensor;
      }
    });
    Object.values(allUnits).forEach((unit: any) => {
      if (!unit.isVisible || unit.type !== 1) {
        delete allUnits[unit.id];
      }
    });
    setUnits(allUnits);
    setSensors(sensors);
    setDataReady(true);
  }, []);

  useEffect(() => {
    !_.isEmpty(groups) && setGroups({});
    if (!isDataReady || !siteId) {
      return;
    }

    getSiteGroups(siteId).then((groups: any) => {
      const groupsWithExistItems: any = {};
      Object.values(groups).forEach((group: any) => {
        const { units, sensors: groupSensors, id } = group;

        group.units = units.filter((unitId: string) => allUnits[unitId]);
        group.sensors = groupSensors.filter((sensorId: string) => sensors[sensorId]);
        groupsWithExistItems[id] = group;
      });
      setGroups(groupsWithExistItems);
    }).catch((err: any) => addMessage({ message: err.message }));
  }, [siteId, isDataReady]);

  const deleteSelectedGroup = (id: string) => {
    deleteGroup(id).then(() => {
      delete groups[id];
      setGroups({ ...groups });
    }).catch((err: any) => {
      addMessage({ message: err.message });
    });
  };

  const updateEditedGroup = (groupId: string, data: any) => {
    return updateGroup({ groupId, data }).then((group: any) => {
      groups[group.id] = group;
      setGroups(groups);
    })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setEditIdGroup(""));
  };

  const createNewGroup = (data: any) => {
    return createGroup(data).then((group: any) => {
      groups[group.id] = group;
      setGroups(groups);
      addGroup(group);
    })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setEditIdGroup(""));
  };

  const handleGroupSelect = (id: any) => {
    setSelectedGroup(selectedGroup === id ? "" : id);
  };

  if (!isInitialized) {
    return <Loading />;
  }

  const groupObj = groups[selectedGroup] || {};
  const { id: groupId = "", units: groupUnits = [], sensors: groupSensors = [] } = groupObj;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          hideSystemSelection
          hideUnitSelection
          path={[t`Settings - Groups`]}
          screenTitle="groups"
          customGeneralNames={{ site: t`Select Site` }}
        />
        {!siteId ? <FilterRequire type={t`site`} /> :
          <>
            <div className={classes.btnContainer}>
              <Button className={classes.newbtn} disabled={!canAddGroup || !site?.permissions?.canCreateGroups} onClick={() => setEditIdGroup("new")}>{t`Add New`}</Button>
            </div>
            <Paper elevation={0} className={classes.paperTableContainer}>
              <div className={classes.groupsSection}>
                <Typography className={classes.title}>{t`Groups`}</Typography>
                <div className={classes.itemsContainer}>
                  {Object.values(groups).map((group: any, index: number) => {
                    const { canUpdate, canDelete } = group?.permissions || {};
                    return <ClickableRow
                      key={index}
                      name={group.name}
                      selected={group.id === groupId}
                      unitsNumber={group?.units?.length + group?.sensors?.length}
                      onDelete={() => deleteSelectedGroup(group.id)}
                      onEdit={(event: any) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setEditIdGroup(group.id);
                      }}
                      onClick={() => handleGroupSelect(group.id)}
                      canEdit={canUpdate}
                      canDelete={canDelete}
                    />;
                  })}
                </div>
              </div>
              <div className={classes.unitssSection}>
                <Typography className={classes.title}>{t`Units`}</Typography>
                <div className={clsx(classes.itemsContainer, classes.unitsItemsContainer)}>
                  {groupId &&
                    <>
                      <ClickableRow
                        key={siteId}
                        name={site?.name}
                        selected={true}
                        unitsNumber={groupUnits.length + groupSensors.length}
                        collapse={true}
                        removeMargin={true}
                        noIcon
                      />
                      {groupUnits.map((unitId: string, index: number) =>
                        <Row key={`${groupId}-${unitId}`} name={units[unitId]?.name} hideLine={(groupUnits.length - 1 === index) && groupSensors.length === 0} />
                      )}
                      {groupSensors.map((sensorId: any, index: number) =>
                        <Row key={`${groupId}-${sensorId}`} name={sensors[sensorId]?.name} hideLine={groupSensors.length - 1 === index} />
                      )}
                    </>
                  }
                </div>
              </div>
            </Paper>
          </>
        }
      </div>
      {editIdGroup &&
        <AddEditGroupsPopup
          onClose={() => setEditIdGroup("")}
          group={groups[editIdGroup]}
          sites={sites}
          units={units}
          sensors={sensors}
          unitTypes={types?.unitTypes}
          createGroup={createNewGroup}
          updateGroup={updateEditedGroup}
          site={site}
        />
      }
    </div >
  );
};

export default Groups;
