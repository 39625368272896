import { IconButton, makeStyles, Popover, TextField, Theme } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";

const style = makeStyles((theme: Theme) => ({
    row: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },
    show: {
        visibility: "visible",
        opacity: 1,
        transition: "visibility 0s, opacity 0.5s linear"
    },
    btn: {
        width: "50px",
        height: "50px"
    },
    popoverStyle: {
        width: "80%",
        height: "80px"
    }
}));

const EditRow = (props: any) => {
    const classes = style();
    const {
        unit,
        isOpen,
        setUnit,
        save,
        anch,
        setAnch,
        editRow
    } = props;

    const {
        name = "",
        serialNumber = "",
        model = "",
        capacity = ""
    } = unit;

    const changeUnit = (e: any) => {
        setUnit({ ...unit, [e.target.name]: e.target.value });
    };

    return (
        <Popover
            id={"edit-row-id"}
            open={isOpen}
            anchorEl={anch}
            onClose={() => setAnch(null)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            classes={{ paper: classes.popoverStyle }}
        >
            <div
                className={clsx(classes.row)}
            >
                <TextField label={`Name`} name={"name"} value={name} onChange={changeUnit} />
                <TextField label={"Serial Number"} name={"serialNumber"} value={serialNumber} onChange={changeUnit} />
                <TextField label={"Model"} value={model} name={"model"} onChange={changeUnit} />
                <TextField label={"Capacity"} value={capacity} name={"capacity"} onChange={changeUnit} />

                <IconButton className={classes.btn} onClick={() => save()}>
                    <Check style={{ color: "rgb(13 165 13)" }} />
                </IconButton>

                <IconButton className={classes.btn} onClick={() => editRow(null, null)}>
                    <Close style={{ color: "rgb(255 78 78)" }} />
                </IconButton>
            </div>
        </Popover>
    );

};

export default EditRow;