import { Action, action, Thunk, thunk } from 'easy-peasy';
import coolremoteSDK from 'coolremote-sdk';
import _ from 'lodash';
import { IRootStoreModel } from '../RootStore';

export interface IUserActions {
  deleteUser: Thunk<IRootStoreModel, { id: string }>;
  _storeDeleteUser: Action<IRootStoreModel, { userId: string }>;
}

export const userActions: IUserActions = {
  deleteUser: thunk(async (actions, payload) => {
    await coolremoteSDK.User.delete(payload.id);

    actions._storeDeleteUser({ userId: payload.id });
  }),

  _storeDeleteUser: action((state, payload) => {
    delete state.users.users[payload.userId];
  })
};
