import { alpha, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%"
  },
  contentArea: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#efeff2",
    maxHeight: "calc(100% - 83px)",
    height: "calc(100% - 83px)",
    justifyContent: "center"
  },
  controlContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTopRightRadius: "25px",
    borderTopLeftRadius: "25px",
    background: "rgba(22,18,32,1)",
    maxHeight: "calc(100% - 20px)"

    // flexFlow: "column nowrap",
    // height: "calc(100% - 20px)",
    // // maxHeight: "653px",
    // width: "100%",
    // borderLeft: "solid 2px #57455c",
    // borderRadius: 4,
    // borderBottomLeftRadius: 0,
    // borderTopLeftRadius: 0,
    // display: "flex",
    // minWidth: "432px",
    // background: "red",
    // /* height: 100%; */
    // minHeight: "calc(100% - 20px)"
  },
  inputRoot: {
    color: "white",
    fontFamily: "sans-serif",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    height: "34px",
    borderRadius: "6px",
    backgroundColor: "rgba(253, 253, 254, 0.1)",
    padding: "0 15px",
    marginRight: "20px",
    flex: 1
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  powerOffButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "34px",
    borderRadius: "6px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.colors.carminePink || "#f05347",
    padding: "0 10px",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: alpha(theme.palette.colors.carminePink || "#f05347", 0.7)
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.dark
    },
    "&:active": {
      backgroundColor: theme.palette.colors.carminePink || "#f05347"
    }
  },
  powerIconStyle: {
    "& path": { stroke: "#fdfdfe" },
    fontSize: "20px !important",
    marginRight: "5px"
  },
  itemsWrapper: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(255,255,255,0.26)"
    },
    padding: "0 15px",
    maxHeight: "calc(100% - 120px)"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    height: "100%",
    padding: 20
  },
  pageContentBox: {
    maxHeight: "100%",
    overflow: "auto",
    maxWidth: "calc(100vw - 240px)",
    display: "flex",
    padding: "16px",
    height: "100%"
  },
  cardsHolder: {
    // overflow: "auto",
    backgroundImage:
      "linear-gradient(to right, rgb(21, 17, 31) 0%, rgb(70, 28, 66))",
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    height: "100%"
  },
  leftSideContainer: {
    backgroundImage:
      theme.gradiants.control || "linear-gradient(to right, rgb(21, 17, 31) 0%, rgb(70, 28, 66))",
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "100%",
    maxHeight: "100%"
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 15px",
    maxHeight: "70px",
    minHeight: "70px",
    flex: 1
  },
  expandMoreIcon: {
    color: "white",
    fontSize: "30px",
    zIndex: 5
  },
  expandMoreIconWrapper: {
    background: theme.gradiants.collapsButton || "linear-gradient(180deg, rgba(22,18,32,1) 0%, rgba(22,18,32,1) 50%, rgba(255,255,255,0) 100%)",
    width: "43px",
    height: "43px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px"
  },
  controlSliderWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    zIndex: 1111,
    height: "calc(100% - 60px)"
  },
  controlSliderHeightIOS: {
    height: "calc(100% - 60px)"
  },
  controlSectionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "20px",
    backgroundColor: "transparent"
  },
  powerOffPT: {
    // width: "73%",
    color: "#fff",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  powerOffButtonText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#fff"
  }
}));

export default useStyles;
