import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./AlertInfoWindow.style";
import AlertsList from "./AlertsList";
import { IAlertMarker } from "./AlertsMap";
interface AlertInfoWindowProps {
  marker: IAlertMarker;
}

const AlertInfoWindow: React.FC<AlertInfoWindowProps> = ({ marker }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [alertsPerPage] = useState(1);
  const {siteAlerts} = marker;

  const [sortedAlerts, setSortedAlerts] = useState<any>(undefined);

  useEffect(() => {
    (async function() {
      const sortedAlertsBySeverity = _.sortBy(siteAlerts, (alert: any) =>  alert.severity);
      setSortedAlerts(sortedAlertsBySeverity);
    })();
  }, [marker]);

  if (!sortedAlerts || sortedAlerts.length === 0) {
    return <h1>No unresolved alerts</h1>;
  }

  const indexOfLastAlert = currentPage * alertsPerPage;
  const indexOfFirstPost = indexOfLastAlert - alertsPerPage;
  let currentAlert = sortedAlerts && sortedAlerts.slice(indexOfFirstPost, indexOfLastAlert);
  const alertTitle =
    currentAlert[0]?.severity.name;

  const data = {
    alert: currentAlert,
    site: marker.site.id,
    customer: marker.site.customer
  };
  const backgroundColor = currentAlert[0]?.severity.color;
  // Change page
  const paginate = (pageNumber: any) => {
    if (pageNumber > 0 && pageNumber < sortedAlerts.length + 1) {
      setCurrentPage(pageNumber);
      currentAlert = sortedAlerts.slice(pageNumber - 1, pageNumber);
    }
  };

  const Pagination = (itemsPerPage: any, totalItems: any, paginate: any) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className={classes.navPagination}>
        <Button disableRipple onClick={() => paginate(currentPage - 1)}>
          <ChevronLeft />
        </Button>
        <Typography>{`${currentPage} of ${sortedAlerts.length}`} </Typography>
        <Button disableRipple onMouseUp={() => paginate(currentPage + 1)}>
          <ChevronRight />
        </Button>
      </div>
    );
  };

  return (
    <Card elevation={0} className={classes.card}>
      <div className={classes.header} style={{ background: backgroundColor }}>
        <Typography style={{ marginRight: "0.5rem" }}>
          {alertTitle ? alertTitle : t`Error`}{" "}
        </Typography>
      </div>
      <CardContent className={classes.cardContent}>
        <AlertsList alerts={data} />
        {Pagination(alertsPerPage, sortedAlerts.length, paginate)}
      </CardContent>
    </Card>
  );
};

export default AlertInfoWindow;
