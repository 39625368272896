import React from "react";

const Humidity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    className={props.className}
    viewBox="0 0 80 80"
  >
    <g fill="none" fillRule="evenodd">
      <g strokeWidth="1.8">
        <g>
          <g>
            <path
              stroke="#FFF"
              strokeLinecap="round"
              d="M42.471 16.454C39.39 12.747 33.9 7.262 26 0 8.667 14.667 0 27.333 0 38c0 16 11.64 26 26 26 9.587 0 17.961-4.457 22.47-12.18"
              transform="translate(-98 -209) translate(98 209) translate(8 8)"
            />
            <path
              stroke="#FDFDFE"
              d="M49.002 11.185C59.639 20.333 65.1 28.225 65.1 34.938c0 4.838-1.691 8.802-4.47 11.591C57.696 49.473 53.567 51.1 49 51.1c-4.567 0-8.696-1.627-11.63-4.57-2.779-2.79-4.47-6.754-4.47-11.593 0-6.713 5.462-14.605 16.102-23.752z"
              transform="translate(-98 -209) translate(98 209) translate(8 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Humidity;
