import { alpha, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  unitStyle: {
    width: "100%",
    height: "50px",
    marginBottom: "6px",
    borderRadius: "6px",
    padding: "3px 9px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: alpha("#fff", 0.1),
    color: "#fff",
    paddingLeft: 0
  },
  unitRowRightSide: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    minWidth: "50%"
  },
  selected: { border: "solid 2px rgba(255, 255, 255, 0.6)" },
  tempSec: {
    display: "flex",
    alignItems: "center",
    fontSize: "26px",
    height: "100%"
  },
  tempInfo: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    justifyContent: "flex-start",
    marginLeft: "5px"
  },
  iconDiv: {
    display: "flex",
    justifyContent: "center",
    width: 28,
    maxWidth: 28,
    marginRight: 5,
    minWidth: 28,
    alignItems: "center"
  },
  roomTemp: {
    fontSize: "13px",
    fontWeight: 300,
    color: "#fff",
    whiteSpace: "nowrap",
    display: "flex"

  },
  tempText: {
    fontSize: "26px",
    fontWeight: 300,
    fontFamily: "RobotoLight",
    width: "60px",
    lineHeight: "unset"
  },
  locSec: {
    display: "flex",
    paddingLeft: "32px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    overflow: "hidden"
  },
  notesSec: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    width: "100%",
    maxWidth: "120px",
    justifyContent: "space-evenly"
  },
  iconStyle: {
    fontSize: "17.5px",
    transform: "scale(1.389)"
  },
  modeIcon: {
    margin: "0 5px"
  },
  noteIcon: {
    width: "20px",
    height: "20px",
    margin: "0 5px"
  },
  powerAlertsSchedulesContainer: {
    alignItems: "center",
    height: "100%",
    flexWrap: "nowrap",
    justifyContent: "flex-end"
  },
  smallIcons: {
    transform: "scale(0.5)",
    margin: 0
  },
  notVisibile: {
    visibility: "hidden"
  },
  removeElements: {
    display: "none"
  },
  removedStyle: {
    opacity: 0.5
  },
  errorColor: {
    color: "#f05146"
  },
  nameStyle: {
    fontSize: "20px",
    fontWeight: 300,
    fontFamily: "RobotoLight"
  },
  alertIcon: { width: "20px", height: "20px" },
  centerWithMargin: {
    marginLeft: "5px"
  },
  tempTextSensor: {
    fontSize: "26px",
    fontWeight: 300,
    fontFamily: "RobotoLight",
    minWidth: "120px",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: 5
  },
  offText: {
    color: alpha("#fff", 0.4)
  },
  boldText: {
    fontFamily: "RobotoMedium",
    fontWeight: 500
  },
  onOffSensor: {
    display: "flex",
    alignItems: "center",
    width: "34%",
    justifyContent: "space-between",
    height: "100%"
  },
  disconnectedIconContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      transform: "scale(2)"
    }
  },
  specialFont: {
    fontFamily: "-webkit-body",
    fontSize: "16px"
  },
  powerUnit: {
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: "4px"
  }
}));

export default useStyles;
