import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  invButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px"
  },
  invButton: {
    width: "100px",
    fontSize: "1rem",
    color: "#7DC1FF",
    margin: "0px 10px"
  },
  usersContainer: {
    height: "100%",
    display: "flex",
    overflow: "auto",
    paddingBottom: "1rem",
    padding: "0 16px"
  },
  tableContainer1: {
    height: "calc(100vh - 175px)",
    padding: "0.6rem 1rem 1rem 0",
    width: "auto"
  },
  tableHeadRow: {
    // height: '45px'
  },
  overWritePadding: {
    cursor: "pointer",
    ...theme.tables.tableCell,
    fontSize: "14px"
  },
  actionTableCell: {
    // padding: "0 0.8rem",
    cursor: "pointer",
    textAlign: "end",
    fontSize: "14px",
    ...theme.tables.tableCell
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  editIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "green"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },

  title: {
    borderBottom: "1px gray solid",
    marginBottom: "2rem",
    width: "100%",
    lineHeight: "50px"
  },
  addNewUser: {
    borderRadius: "4px",
    width: "15%",
    alignSelf: "flex-end",
    color: "#fff",
    background:
      "linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    textAlign: "center",
    marginRight: "1rem"
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconButton: {
    minWidth: "fit-content",
    padding: "0"
  },

  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box"
  },
  "[class^=\"NavigationBar\"]": {
    padding: 0
  },
  body: {
    background: "#fafafa"
  },
  emailColor: {
    color: "#0079ff"
  },
  iconsColumnWidth: { maxWidth: "7rem", width: "5rem" },
  limitWidth: {
    wordBreak: "break-word"
    //  width: "15%"
  },
  breakLine: {
    wordBreak: "break-word",
    padding: "0 15px",
    height: "35px"
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "20px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },

  table: {
    background: theme.palette.background.paper
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  searchIcon: {
    transform: "scale(0.7)"
  },
  disabledUser: {
    opacity: 0.7
  },
  lockIcon: {
    marginLeft: "4px"
  },
  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  tooltip: {
    color: "#7DC1FF",
    cursor: "pointer",
    width: "fit-content"
  },
  popoverPaper: {
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: "\"\"",
      position: "absolute",
      top: "40%",
      left: 0,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: "translate(-50%, 50%) rotate(225deg)",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))"
    },
    maxWidth: "20%",
    minHeight: "50px",
    padding: "5px",
    marginLeft: "15px"
  },
  popoverEntity: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowWrap: "anywhere",
    "&:nth-child(odd)": {
      background: "#f6f6f6",
    }
  },
  popoverLevel: {
    margin: "5px"
  },
  popoverEntityName: {
    color: "#7DC1FF",
    fontSize: "14px",
    marginRight: "15px"
  },
  popoverApps: {
    color: "#ababab",
    fontSize: "13.5px",
    minWidth: 100
  },
  popoverLevelTitle: {
    fontSize: "14px"
  },
  sortedCol: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      margin: "auto 5px"
    }
  },
  sortIcons: {
    fontSize: "20px"
  },
  sortableCol: {
    cursor: "pointer"
  },
  activeSortIcon: {
    color: "#513eee",
    fontSize: "23px"
  },
  flipIcon: {
    transform: "rotateX(180deg)"
  },
  disabledRow: {
    "& *": {
      color: "#c6c6c6"
    }
  }
}));

export default useStyles;
