import { alpha, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    width: "100%",
    borderRadius: "8px",
    height: "770px"
  },
  smallDialog: {
    maxWidth: "450px !important"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title

  },
  dialogContent: {
    display: "flex",
    flexFlow: "row wrap",
    width: "100%",
    flex: 1,
    padding: "0 20px",
    boxSizing: "border-box",
    overflowY: "auto",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(255,255,255,0.26)"
    }
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  subTitle: {
    fontSize: "22px",
    color: theme.palette.colors.controlButtonColor,
    width: "100%",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal"
  },
  noScheduleTitle: {
    color: theme.overrides.MuiButton.outlined.color,
    margin: "20px 0",
    fontFamily: "Roboto",
    fontSize: "23px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center"
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 10px 0px 10px"
  },
  bigIconBtnStyle: {
    width: 40,
    height: 40,
    borderRadius: 6,
    padding: 0
  },
  addIcon: {
    color: "#aaa2aa"
  },
  addEditHeader: {
    width: "100%",
    display: "flex",
    height: 50,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    marginTop: 15
  },
  scheduleInfoContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden"
  },
  scheduleInfoPaper: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    padding: "0 20px",
    justifyContent: "space-between",
    overflowY: "auto"
  },
  controlSec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%"
  },
  timeContainer: {
    height: "44px",
    width: "200px",
    fontSize: "16px",
    textTransform: "none"
  },
  selectModeStyle: {
    fontSize: "18px",
    lineHeight: "normal",
    fontWeight: "normal",
    margin: "10px",
    marginLeft: "0px",
    display: "flex",
    alignItems: "center",
    color: theme.overrides.MuiButton.contained.color
  },
  daysContainer: {
    padding: "0px 0px",
    margin: "0px 0px",
    justifyContent: "space-between",
    width: "auto"
  },
  container: {
    width: "100%",
    padding: "20px 0",
    maxWidth: "768px",
    display: "flex",
    borderTop: theme.overrides.MuiButton.outlined.border,
    flexDirection: "column",
    alignItems: "start"
  },
  modeContainer: {
    display: "flex",
    alignContent: "center",
    paddingTop: "12px"
  },
  popover: {
    minHeight: "280px",
    minWidth: "300px"
  },
  actionsContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  actionHolder: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "16px 0px"
  },
  saveBtn: {
    margin: "0 10px"
  },
  thumb: {
    backgroundColor: theme.palette.primary.dark
  },
  track: {
    backgroundColor: theme.palette.primary.light
  },
  thumbDisabled: {
    backgroundColor: "#d5d2d5"
  },
  trackDisabled: {
    backgroundColor: "#e6e4d6"
  },
  errorLabelStyle: {
    color: "red",
    marginTop: "4px",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "end",
    minHeight: "15px",
    height: "15px"
  },
  scheduleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "12px 10px",
    maxWidth: "47%",
    border: theme.overrides.MuiButton.outlined.border,
    borderRadius: "6px"
  },
  bodyRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    overflowY: "hidden"
  },
  scheduleHeader: {
    display: "flex",
    justifyContent: "space-between",
    height: "50px",
    borderBottom: theme.dialogs.header.borderBottom,
    margin: "10px",
    boxSizing: "border-box"
  },
  scheduleTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 5,
    "& *": {
      fontSize: "18px",
      fontWeight: "500",
      marginRight: "7px"
    }
  },
  nameStyle: {
    color: theme.overrides.MuiButton.outlinedPrimary.color,
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "normal"
  },
  scheduleControl: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "20%"
  },
  editIcon: {
    width: 19,
    height: 20
  },
  scheduleBody: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "6px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: "10px",
    paddingBottom: "20px",
    boxSizing: "border-box",
    overflowY: "hidden"
  },
  timeSection: {
    display: "flex",
    flexDirection: "column",
    height: "80px",
    justifyContent: "center"
  },
  scheduleStatusStyle: {
    fontSize: "18px"
  },
  dividerStyle: {
    backgroundColor: alpha("#fdfdfe", 0.1)
  },
  disabledBlack: {
    color: "black"
  },
  modeIcon: {
    "&> svg": {
      width: "40px",
      height: "40px"
    },
    margin: "5px",
    "& > g": { stroke: "black" },
    "& > path": { stroke: "black" }
  },
  blueCool: { "& > path": { stroke: "rgb(53, 168, 224)" } },
  redHeat: { "& > path": { stroke: "red" } },
  selectedMode: {
    background: "#d7d7d9",
    borderRadius: "5px"
  },
  modeButton: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #d5d2d5",
    borderRadius: "12px",
    height: "40px",
    width: "40px",
    marginRight: "12px"
  },
  selectedModeBtn: {
    background: "#eeecee",
    borderColor: "#eeecee"
  },
  modeIconBtn: {
    height: "29px",
    width: "29px"
  },
  noSchedules: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  addDeviceBtn: {
    color: theme.overrides.MuiButton.outlined.color,
    background: "#f6f5f6",
    margin: "0 1rem 0 1rem",
    padding: "10px 16px",
    boxShadow: "none",
    textTransform: "capitalize",
    "&:hover": {
      background: "#f6f5f6",
      boxShadow:
        "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    "&:active": {
      background: "#f6f5f6",
      boxShadow:
        "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    }
  },
  mainIconsStyle: {
    padding: "20px",
    background: "#f6f5f6",
    width: "80px",
    height: "80px",
    borderRadius: "50%"
  },
  status: {
    fontWeight: "bold",
    marginRight: "5px"
  },
  statusRow: {
    margin: "10px 0",
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  statusText: {
    display: "flex"
  },
  modeSetpointontainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  operationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  setpointContainer: {
    display: "flex",
    flexDirection: "column"
  },
  valueTitle: {
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    height: "100%",
    display: "block",
    width: "110px",
    color: theme.overrides.MuiButton.contained.color
  },
  checkboxStyle: { marginLeft: "5px" },
  selectModeContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  controlArrowButton: {
    width: "110px",
    "& path": {
      stroke: "#aaa2aa"
    }
  },
  setpointStyle: {
    width: "110px",
    minHeight: "63px",
    fontSize: "48px",
    fontWeight: "normal",
    lineHeight: "normal",
    textAlign: "center",
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  tempSymbolStyle: { fontSize: "20px" },
  hideSetpoint: {
    display: "none"
  },
  errorBorder: {
    border: "1px solid red"
  },
  errorRedText: {
    color: "red"
  },
  root: {
    width: "100%"
  },
  indicatorColor: {
    backgroundColor: theme.palette.primary.main
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },
  tabContainer: {
    width: "100%",
    padding: "9px 10px 16px 10px",
    borderRadius: " 0 0 6px 6px",
    background: alpha("#fdfdfe", 0.1),
    maxWidth: "768px",
    minHeight: "max-content"
  },
  nameInput: {
    width: "260px"
  },
  calendar: {
    fontFamily: "Roboto",
    "& .DayPicker-Month": {
      width: "350px",
      height: "290px"
    },
    "& .DayPicker-Weekday": {
      color: "#000"
    },
    "& .DayPicker-Day": {
      color: "#000"
    },
    "& .DayPicker-Day--disabled": {
      color: alpha("#000", 0.2)
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover": {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    "& .DayPicker:not(.DayPicker--interactionDisabled):hover": {
      backgroundColor: `${alpha("#000", 0.1)} !important`
    },
    "& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover": {
      backgroundColor: `${alpha("#000", 0.1)} !important`
    }
  }
}));

export default useStyles;
