import { createStyles } from "@material-ui/styles";

export const systemTableStyle = (theme: any) =>
  createStyles({
    HVACTable: {
      borderLeft: "1px solid grey"
    },
    tableHead: {
      background: "rgb(236,235,239,0.5)",
      color: "rgb(84,89,100)"
    },
    tableHeadCell: {
      padding: "0 15px",
      ...theme.tables.headerCell,
      height: 45,
      whiteSpace: "nowrap"
    },
    customTableHead: {
      border: "none",
      background: "white",
      borderTop: "1px solid grey",
      marginRight: "10px"
    },
    elevation: {
      boxShadow: "none"
    },
    customTableCell: {
      border: "none",
      marginRight: "10px",
      minWidth: "120px",
      display: "block",
      fontWeight: "normal"
    },
    customTable: {
      display: "table-cell",
      borderSpacing: "10px"
    },
    alertIcon: {
      color: "#f05347",
      marginTop: "4px",
      fontSize: "1.3rem"
    },
    hide: {
      visibility: "hidden"
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row"
    },

    tableWrapper: {
      width: "100%",
      height: "100%",
      overflow: "auto"
    },

    tableCell: {
      ...theme.tables.tableCell,
      padding: "0 15px",
      height: "35px"
    },
    customeTableCell: {
      fontSize: "0.75rem",
      color: "rgb(84, 89, 100)",
      border: "none"
    },
    tableRow: {
      cursor: "pointer"
    },
    HVACSection: {
      background: "red",
      flexDirection: "column",
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%"
    },

    brandLogo: {
      WebkitFilter: "grayscale(1) /* Google Chrome, Safari 6+ & Opera 15+ */",
      filter: "grayscale(1) /* Microsoft Edge and Firefox 35+ */",
      minWidth: "3.5rem"
    },
    minWidthStyle: {
      minWidth: "15ch",
      wordBreak: "break-all",
      maxWidth: "15ch",
      width: "15ch"
    },
    pageContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      height: "100%",
      width: "100%",
      flex: 1,
      overflow: "auto"
    },
    systemTableFooter: {
      position: "absolute",
      top: "5px",
      right: "20px",
      zIndex: 1
    },
    removeBottomBorder: { "& td": { border: 0 } },
    lessWidth: {
      minWidth: "12ch",
      wordBreak: "break-all",
      width: "auto"
    },
    removePadding: {
      padding: "0px"
    },
    dialogHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      ...theme.dialogs.header,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    headerTitle: {
      ...theme.dialogs.title
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
    dialogContent: {
      padding: "24px 24px 40px 24px",
      display: "flex",
      overflow: "hidden"
      // maxHeight: "100%",
      // height: "100%",
      // width: "100%"
    },
    dialog: {
      height: "55%"
    }
  });
