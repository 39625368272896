import {
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { TopBar } from "../../components";
import Button from "../../cool_widgets/Button";
import HVACImg from "../../images/coolmaster.png";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import styles from "./systemDetection.style";

interface SiteProps {
  history: any;
  match: any;
}

const maxScanningTime = 120000;
const waitingInterval = 5000;
let passedTime = 0;

const SystemDetection: React.FC<SiteProps> = ({ history, match }) => {
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [device, setDevice] = useState<any>({});
  const [deviceFound, setDeviceFound] = useState<boolean>(false);

  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const types = useStoreState((state) => state.types);
  const getDeviceTree = useStoreActions((actions) => actions.getDeviceTree);

  const { unitTypes, hvacBrands = [] } = types;
  const { params } = match;
  const { deviceId } = params;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const getBrandName = (value: any) => {
    return hvacBrands?.filter((item: any) => item.value === value)[0]?.name;
  };

  useEffect(() => {
    getDeviceTree({ id: deviceId })
      .then((response: any) => {
        const { systems = {} } = response;

        if (!_.isEmpty(systems)) {
          setProgressPercentage(50);
          getDeviceTree({ id: deviceId }).then((response: any) => {
            setDevice(response);
            setProgressPercentage(100);
          });
        }

        passedTime += waitingInterval;
        setDeviceFound(true);
      })
      .catch((err: any) => {
        setProgressPercentage(100);

        addMessage({
          message: err.message
        });

        history.push("/site-management");
      });
  }, [addMessage, deviceId, getDeviceTree, history]);

  useEffect(() => {
    if (passedTime === 0) {
      return;
    }

    if (!deviceFound) {
      return;
    }

    if (!_.isEmpty(device)) {
      return;
    }

    function progress() {
      if (progressPercentage > 95 && _.isEmpty(device)) {
        getDeviceTree({ id: deviceId }).then((response: any) => {
          setDevice(response);
        });
        setProgressPercentage(100);
        clearInterval(timer);
        return;
      }

      getDeviceTree({ id: deviceId }).then((response: any) => {
        const { systems } = response;
        if (systems && !_.isEmpty(systems)) {
          setDevice(response);
          setProgressPercentage(100);
          clearInterval(timer);
        }

        passedTime += waitingInterval;
        setProgressPercentage((passedTime / maxScanningTime) * 100);
      });
    }
    const timer = setInterval(progress, waitingInterval);
    return () => {
      clearInterval(timer);
    };
  }, [
    device,
    deviceFound,
    deviceId,
    getDeviceTree,
    progressPercentage
  ]);

  const goNext = () => {
    const { location: { pathname } } = history;
    const isDeviceRegister = pathname.includes("device-registration");
    history.push(`/${isDeviceRegister ? "device-registration/" : ""}device/${deviceId}/edit-detected-systems`);
  };

  const { units = {}, systems = {}, name = "" } = device;
  const systemsArray = Object.values(systems);
  const unitsArray = Object.values(units);
  const systemsNumber = systemsArray.length;
  const indoorUnitsNumber = unitsArray.filter(
    (unit: any) => unit.type === unitTypes.indoor
  ).length;
  const isReady = !_.isEmpty(systems) || (progressPercentage === 100);

  return (
    <div
      className={classes.screenContainer}
    >
      <Grid container className={classes.grid}>
        <TopBar
          title={t`Connect Device`}
          leftIconComponent={undefined}
          hideRightComponent={true}
        />
        <Grid item className={classes.scrolledSection}>
          <div className={classes.pageContent}>
            <div className={classes.header}>
              <Typography className={classes.pageTitle}>
                {isReady
                  ? t`Congratulations!`
                  : t`Lets Connect your HVAC Device`}
              </Typography>
              <Typography className={classes.subTitle}>
                {isReady &&
                  systemsNumber + " " + t`systems connected to device: CoolMasterNet ` + name}
              </Typography>
              <div className={classes.deviceImageContainer}>
                <div className={classes.deviceImageShadow} />
                <img
                  src={HVACImg}
                  alt={"HVAC"}
                  className={classes.deviceImgStyle}
                />
              </div>
            </div>
          </div>
          {!isReady ? (
            <>
              <Typography className={classes.connectingText}>
                {t`Discovering connected systems/units`}
              </Typography>
              <Typography className={classes.subconnectingText}>
                {t`This may take up to 1 minute`}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progressPercentage}
                className={classes.progressBar}
              />
            </>
          ) : (
            <>
              <Grid container className={classes.indoorUnitsContainer}>
                <Typography
                  className={classes.indoorTypo}
                >{t`Indoor Units `}</Typography>
                <TextField
                  variant="outlined"
                  disabled
                  InputProps={{ className: classes.indoorTypo }}
                  className={classes.indoornumber}
                  value={`# ${indoorUnitsNumber}`}
                />
              </Grid>
              {systemsArray.map((system: any, i: number) => {
                const { name, brandNum, model, units } = system;
                const unitsNumber = Object.values(units).length;
                // outdoor units number not done, currently I'm showing all units number the system has

                return (
                  <Grid
                    container
                    key={`system-item-${i}`}
                    className={classes.systemcontainer}
                  >
                    <div className={classes.systemRow}>
                      <div className={classes.fieldWrapper}>
                        <InputLabel className={classes.labelStyle}>
                          {t`System name`}
                        </InputLabel>
                        <TextField
                          value={name}
                          className={classes.systemFields}
                          variant={"outlined"}
                        />
                      </div>
                      <div className={classes.fieldWrapper}>
                        <InputLabel className={classes.labelStyle}>
                          {t`Brand`}
                        </InputLabel>
                        <TextField
                          value={getBrandName(brandNum) || "not specified"}
                          className={classes.systemFields}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={classes.systemRow}>
                      <div className={classes.fieldWrapper}>
                        <InputLabel className={classes.labelStyle}>
                          {t`Model`}
                        </InputLabel>
                        <TextField
                          value={model || "not specified"}
                          className={classes.systemFields}
                          variant={"outlined"}
                        />
                      </div>
                      <div className={classes.fieldWrapper}>
                        <InputLabel className={classes.labelStyle}>
                          {t`Outdoor units`}
                        </InputLabel>
                        <TextField
                          value={unitsNumber}
                          className={classes.systemFields}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
              <Button
                className={classes.nextBtn}
                onClick={goNext}
              >
                {t`Next`}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SystemDetection;
