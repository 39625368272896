import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import daysListStyles from "./daysList.style";

const daysS: any = {
  Friday: "Fri",
  Monday: "Mon",
  Saturday: "Sat",
  Sunday: "Sun",
  Thursday: "Thu",
  Tuesday: "Tue",
  Wednesday: "Wed"
};

const DaysList = (props: any) => {
  const { days, activeDays, action, disablePointer = false, white = true} = props;
  const useStyles = makeStyles(daysListStyles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {days.map((day: any, index: number) => {
        const selected = activeDays.indexOf(day) > -1;
        return (
          <div
            className={clsx(classes.dayStyle, {
              [classes.disableClickPointer]: disablePointer,
              [classes.notWhite]: !white,
              [white ? classes.activeDayStyle : classes.notWhiteActiveDayStyle]: selected
            })}
            key={index}
            onClick={() => disablePointer ? {} : action(day)}
          >
            <Typography>{daysS[day]}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default DaysList;
