import { alpha } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
export default (theme: any) =>
  createStyles({
    mainIconsStyle: {
      padding: "11px",
      background: theme.gradiants.button || "#4b1c46",
      width: "62px",
      height: "62px",
      color: alpha("#fdfdfe", 0.6),
      "&:hover": {
        background: theme.gradiants.button || "#5d3358",
      },
      "&.Mui-disabled": {
        background: theme.gradiants.disabledButton || "#4b1c46",
        color: alpha("#fdfdfe", 0.6),
      },
    },
    iconContainer: { objectFit: "contain", zIndex: 99999 },
    groupView: {
      width: "100%",
      height: "100%",
      background:
        theme.gradiants.panel ||
        "linear-gradient(to bottom, rgba(22,18,32,1) 0%, rgba(58,24,58,0.7) 53%)",
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "center",
      borderTopRightRadius: "25px",
      borderTopLeftRadius: "25px",
      padding: 0,
      paddingTop: "40px",
      paddingBottom: "20px",
      minHeight: "500px",
      overflow: "auto",
      maxHeight: "100%",
      boxSizing: "border-box",
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px",
    },
    powerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "77px",
    },
    powerIconStyle: { padding: 0, zIndex: 100 },
    powerTextStyle: {
      marginTop: "8px",
      color: "white",
      height: "16px",
      opacity: 0.5,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    powerOnButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
          opacity: 1,
        },
        "& path": {
          stroke: "#f05146",
        },
      },
    },
    powerOffButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
        },
        "& path": {
          fill: "#000",
        },
      },
    },
    secondRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "column nowrap",
      paddingBottom: "20px",
    },
    lastRowContainer: {
      display: "flex",
      height: "62px",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "100%",
    },
    controlDiv: {
      maxHeight: "326px",
      maxWidth: "336px",
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "326px",
      minWidth: "336px",
      "@media (max-width: 328px)": {
        minWidth: "312px",
      },
    },
    sliderContainer: {
      marginTop: "-20px",
      maxWidth: "320px",
      maxHeight: "320px",
      zIndex: 1,
    },
    innerControls: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center",
    },
    controlArrow: {
      background: "transparent !important",
      zIndex: 100,
      width: "60px",
      height: "60px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `#f05146 !important`,
        },
        transition: "all 1s",
      },
    },
    setPointStyle: {
      fontSize: "65px",
      color: "#fff",
    },
    ampTempContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    controlWrapperStyle: {
      position: "absolute",
      "@media (max-width: 328px)": {
        transform: "scale(0.93)",
      },
    },
    modeTitle: {
      fontSize: "50px",
      color: "#fff",
      fontWeight: "bold",
      position: "absolute",
    },
    loaderContainer: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loaderStyle: { color: alpha("#fff", 0.7) },
    alertMessageStyle: {
      fontSize: "16px",
      color: "#f05146",
      lineHeight: "16px",
    },
    nameSection: {
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      width: "calc(100% - 120px)",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      maxHeight: "60px",
      "& p": {
        whiteSpace: "unset",
        textOverflow: "unset",
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: "22px",
      },
      padding: "0 15px",
      textAlign: "center",
    },
    iconStyle: {
      fontSize: "17.5px",
    },
    modeIcon: {
      margin: "0 5px",
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: "-10px",
      marginRight: "-4px",
    },
    siteTemp: {
      width: "55px",
      opacity: 0.5,
      fontSize: 26,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
      minWidth: "fit-content",
    },
    scaleStyle: {
      fontSize: 14,
    },
    badge: {
      position: "absolute",
      top: "-5px",
      right: "0px",
      padding: "3px 7px",
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontSize: "12px",
      lineHeight: "normal",
      borderRadius: "10px",
    },
    disabled: {
      opacity: 0.5,
    },
    textColor: { color: theme.palette.colors.menuFont },
    tempHolder: {
      position: "relative",
      display: "flex",
      marginLeft: "25px",
      "& span": {
        color: "white",
        width: "25px",
      },
    },
  });
