import { makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  dialog: {
    width: "100%",
    borderRadius: "8px",
    maxWidth: "850px",
    height: "100%",
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden",
    padding: "25px",
    height: "calc(100% - 70px)",
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  emailField: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "space-between",
    marginBottom: "15px",
  },
  emailTitle: {
    color: "#747881",
    marginBottom: "15px",
  },
  expireField: {
    display: "flex",
    width: "100%",
  },
  buttonsContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  datePickerRoot: {
    flexDirection: "row-reverse",
  },
  resendButton: {
    width: 110,
    color: theme.palette.primary.main,
    paddingRight: 40,
  },
  errorLabelStyle: {
    color: "#D32F2F",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "15px",
    minHeight: "15px",
  },
}));
