import { IconButton, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { t } from "ttag";
import DateRangePicker from "../../cool_widgets/DateRangePicker";
import { Maximize, ZoomOut } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import GraphPlaceHolder from "../Reports/GraphPlaceHolder";

const AlertsReport = ({ siteId, classes, setIsLoading, maximize, id, maxed, isLoading, updatedDate }: any) => {

  const [loadDataFailed, setLoadDataFailed] = useState<boolean>(false);
  const getData = useStoreActions((actions) => actions.ReportsData.getAlertsData);
  const container: any = useRef(null);
  const firstUpdate = useRef(true);

  const reportsData = useStoreState((state) => state.ReportsData.alertsGraphData);
  const setInfo = useStoreActions((action) => action.ReportsData.setAlertsGraphData);
  const { date, data, system: sysId } = reportsData;

  useEffect(() => {
    updatedDate && setInfo({ date: updatedDate });
  }, [updatedDate]);

  useEffect(() => {
    if (!data.length && !!siteId && !!date) {
      setLoadDataFailed(true);
    }
  }, [data]);

  useEffect(() => {
    setLoadDataFailed(false);
    //prevent firing at first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!siteId || !date) {
      return;
    }
    setIsLoading(true);
    getData({ type: sysId ? "system" : "site", id: sysId || siteId, date })
      .then(() => {
        container?.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

      })
      .catch(() => setLoadDataFailed(true))
      .finally(() => setIsLoading(false));
  }, [siteId, date, sysId]);

  const isMaxed = id === maxed;

  return (
    <div className={clsx(classes.reportContainer, isMaxed && classes.maxedContainer)} id={!data.length ? "alertsXX" : "alertsGraphId"}>
      {data?.length > 1 &&
        <>
          <IconButton
            style={{
              position: "absolute",
              bottom: "40%",
              right: 5,
              zIndex: 100
            }}
            onClick={() => {
              container?.current?.scrollTo({
                top: 0,
                left: container?.current.scrollLeft + 150,
                behavior: "smooth"
              });

            }}>
            <ChevronRight style={{ fontSize: "40px" }} />
          </IconButton>
          <IconButton
            style={{
              position: "absolute",
              bottom: "40%",
              left: 5,
              zIndex: 100
            }}
            onClick={() => {
              container?.current?.scrollTo({
                top: 0,
                left: container?.current.scrollLeft - 150,
                behavior: "smooth"
              });

            }}>
            <ChevronLeft style={{ fontSize: "40px" }} />
          </IconButton>
        </>
      }
      <div className={classes.reportContainerHeader}>
        <Typography className={classes.reportTitle}>{t`Alerts Statistics by System`}</Typography>
        <div className={classes.headerMid}>
          <DateRangePicker disabled={!siteId} date={date} setDate={(date: any) => setInfo({ date })} autoSelectMonth={true} />
        </div>
        <div className={classes.reportHeaderActions}>
          <IconButton onClick={() => maximize(id)} data-html2canvas-ignore="true">
            {isMaxed ? <ZoomOut /> : <Maximize />}
          </IconButton>
        </div>
      </div>
      <div className={classes.reportContainerBody}>
        {date ? data?.length ?
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%"
            }}
          >
            <ScrollContainer innerRef={container} vertical={false} style={{ display: "inline-block", minWidth: "55%", height: "95%", overflowY: "hidden", overflowX: "scroll", width: "calc(100% - 30px)" }} hideScrollbars={false}>
              <ResponsiveContainer width={data?.length > 1 ? data?.length * (20 + 160) : "100%"} height={"100%"}>
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  maxBarSize={40}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 25
                  }}
                >
                  <XAxis
                    dataKey="name"
                    tick={{ fontSize: "12px", fontWeight: "bold" }}
                    interval={0}
                  />
                  <YAxis
                    domain={[0, "dataMax + 10"]}
                    tick={{ fontSize: "12px", fontWeight: "bold" }}
                    tickFormatter={(value: any) => Math.round(value * 10) / 10}

                  />
                  {!_.isEmpty(data) && <Tooltip cursor={{ fill: "rgba(128,128,128,0.1)" }} />}
                  <Bar
                    dataKey={"anomalyCount"}
                    name="Anomaly"
                    fill={"#9f45e4"}
                    stackId={"a1"}
                  />
                  <Bar
                    dataKey={"communicationCount"}
                    fill={"#35a8e0"}
                    name="Communication"
                    stackId={sysId ? "a2" : "a1"}
                  />
                  <Bar
                    dataKey={"indoorErrorCount"}
                    fill={"#fcbc00"}
                    name="Maintenance"
                    stackId={sysId ? "a3" : "a1"}
                  />
                  <Bar
                    dataKey={"systemErrorCount"}
                    fill={"#ff0000"}
                    name="System Error"
                    stackId={sysId ? "a4" : "a1"}
                  />

                </BarChart>
              </ResponsiveContainer>
            </ScrollContainer>
          </div>
          : (isLoading ? null : <GraphPlaceHolder loadDataFailed={true} type="system/date" />) :
          <GraphPlaceHolder loadDataFailed={loadDataFailed} />
        }
      </div>

    </div >
  );
};

export default AlertsReport;
