import {
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { symbols } from "../../constants/sensorsUnitsSymbols";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { Add, Refresh as SvgRefresh } from "../../icons/";
import { DeleteIcon, EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { AddIcon } from "../../svgComponents";
import EditParamPopUp from "./EditParamPopUp";
import styles from "./UnitStatsTable.module.css";
import useStyles from "./UnitStatsTable.style";
import UserDefinedParamsTab from "./UserDefinedParamsTab";

export default function UnitStatsTable(props: any) {
  const {
    isLoading,
    onRefresh,
    updateTime,
    autoupdate,
    setAutoupdate,
    endTime,
    isToday,
    paramsTable,
    showHideAllParams,
    showHideParam,
    unit,
    reFetchData,
    timezone,
    updateParamRow,
    sensorsRows,
    powerMetersRows,
    resetParams,
    unitType,
    brand,
    deleteParam,
    disableUpdateBtn,
    system
  } = props;

  const classes = useStyles();
  const { canCreateCustomParameters, canDeleteCustomParameters, canUpdateCustomParameters } = system?.permissions || {};
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const { deleteCustomParam } = useStoreActions((action) => action.units);
  const deleteServiceParams = useStoreActions((action) => action.deleteServiceParams);
  const customParameterOperationsOptionsObject = useStoreState((s) => s.customParameterOperationsOptionsObject);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedRow, setEditedRow] = useState<any>(null);
  const [editedRowIndex, setEditedRowIndex] = useState<any>(null);
  const [values, setValues] = useState<any>({});
  const [paramsTechData, setParamsTechData] = useState<any>({});
  const [tab, setTab] = useState<number>(0);
  const [toDeleteItem, setToDeleteItem] = useState<any>(null);
  const [customParamToEdit, setCustomParamToEdit] = useState<any>(null);
  const [customParamsTable, setCustomParamsTable] = useState<any>({});

  useEffect(() => {
    const paramsFileUrl = `${process.env.PUBLIC_URL}/assets/professional.amsiautomation.com/paramsData.json`;
    fetch(paramsFileUrl)
      .then((res) => res.json())
      .then((res) => {
        setParamsTechData(res);
      });
  }, []);

  useEffect(() => {
    if (isLoading && !_.isEmpty(values)) {
      setValues({});
    }

  }, [isLoading]);

  useEffect(() => {
    const customParams = _.reduce(paramsTable, (obj: any, param: any) => {
      if (param?.isCustomParam) {
        obj[param.code] = param;
        return obj;
      } else {
        return obj;
      }
    }, {});
    setCustomParamsTable(customParams);

  }, [paramsTable]);

  const getStringFormula = (formula: any) => {
    let stringFormula = "";
    formula.forEach((option: any, index: number) => {
      const { type, constant, paramCode, operation } = option;
      if (index !== 0) {
        stringFormula = stringFormula + customParameterOperationsOptionsObject[operation]?.name + " ";
      }
      if (type === 1) {
        stringFormula = stringFormula + paramsTable[paramCode]?.name + " ";
      } else {
        stringFormula = stringFormula + constant + " ";
      }
    });

    return stringFormula;
  };

  const openParamsList = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDeleteClose = () => {
    setToDeleteItem(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    customParamToEdit && setCustomParamToEdit(null);
    setTimeout(() => {
      setTab(0);
    }, 500);
  };
  const editParam = (row: any, index: number) => {
    if (row.editable) {
      setEditedRow(row);
      setEditedRowIndex(index);

      setOpenEditDialog(true);
    }
  };
  const closeDialog = () => {
    setOpenEditDialog(false);
    setEditedRow(null);
  };
  const setNewValue = (newValue: any, index: number) => {
    let temp = { ...values };
    temp[index] = newValue;
    setValues(temp);
  };
  const sensorAndPowerMetersData = _.isEmpty(sensorsRows) ? powerMetersRows : _.isEmpty(powerMetersRows) ? sensorsRows : { ...sensorsRows, ...powerMetersRows };
  const sensorAndPowerTitle = _.isEmpty(sensorsRows) ? t`Power Meters` : _.isEmpty(powerMetersRows) ? t`Sensors` : t`Sensors And Power Meters`;

  const numOfUnitParams = Object.values(paramsTable)?.filter((param: any) => !!param && !param.isCustomParam)?.length;
  const numOfShowingUnits: any = Object.values(paramsTable).filter((row: any) => row?.isShowing && !row.isCustomParam).length;
  const allUnitsShowed = numOfUnitParams === numOfShowingUnits;

  const numOfShowingCustom: any = Object.values(customParamsTable).filter((row: any) => row?.isShowing).length;
  const numOfCustomParams = Object.values(customParamsTable)?.length;
  const allCustomShowed = numOfShowingCustom === numOfCustomParams;

  const numOfSensorsAndPower = Object.values(sensorAndPowerMetersData)?.filter((param: any) => !!param)?.length;
  const numOfShowingSensorsAndPower: any = Object.values(sensorAndPowerMetersData).filter((row: any) => row?.isShowing).length;
  const allSensorsAndPowerShowed = numOfSensorsAndPower === numOfShowingSensorsAndPower;

  const allShowed = numOfUnitParams + numOfSensorsAndPower === numOfShowingUnits + numOfShowingSensorsAndPower;
  const someShowed = !allShowed && (numOfShowingUnits + numOfShowingSensorsAndPower !== 0);
  const dateTime = `${dateFormat} ${timeFormat}`;

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.parametersHeader}>
          <Typography variant="h6" className={classes.title}>{t`Parameters`}</Typography>
          <IconButton disableRipple className={clsx(classes.iconBtnStyle, classes.addIcon, someShowed && classes.pushedAddIcon)} onClick={(e: any) => openParamsList(e)} id="open-unit-params">
            <Add disabled={isLoading} />
          </IconButton>
        </div>
        <div className={classes.autoUpdateContainer}>
          <Tooltip arrow interactive placement="top-start" title={disableUpdateBtn ? t`Auto update is only available for periods shorter than 24 hours` : t`Refresh data automatically with new real time data`} >
            <FormControlLabel
              classes={{ label: classes.autoRefreshLabel, root: classes.autoUpdate }}
              control={
                <Checkbox
                  color="default"
                  classes={{ root: clsx(classes.removePaddingRight, classes.grayCheckbox) }}
                  checked={autoupdate}
                  disabled={disableUpdateBtn}
                  onChange={() => setAutoupdate(!autoupdate)}
                  value="checkedA"
                />
              }
              label={t`AUTO UPDATE`}
            />
          </Tooltip>
          <Typography variant="body2" className={classes.lastUpdatedTitle}>
            {updateTime
              ? t`Last updated at:` + ` ${moment(updateTime).tz(timezone).format(dateTime)}`
              : t`No unit data `}
          </Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} aria-label="refresh icon" onClick={onRefresh ?? _.noop} disabled={autoupdate || disableUpdateBtn}>
            <SvgRefresh className={isLoading ? styles.refreshStyleLoading : styles.refreshStyle} />
          </IconButton>
        </div>
      </div>

      {!isLoading && (<Popover
        id="unitParamsListPopup"
        disableAutoFocus={false}
        disableEnforceFocus={false}
        classes={{ paper: classes.dialogStyle }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className={classes.dialogHeader}>
          {_.isEmpty(paramsTable) ?
            <Typography className={classes.headerTitle}>{t`System Parameters`}</Typography> :
            <Tabs
              value={tab}
              onChange={(event: any, newValue: number) => {
                setTab(newValue);
                newValue === 0 && setCustomParamToEdit(null);
              }}
              variant="fullWidth"
              aria-label="icon label tabs example"
              classes={{ root: classes.tabRoot, indicator: classes.indicatorColor }}
            >
              <Tab value={0} label={t`System Parameters`} className={classes.tab} />
              <Tab value={1} label={t`User Defined Params`} className={classes.tab} />
            </Tabs>
          }
          <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        {tab === 0 || _.isEmpty(paramsTable) ?
          <>
            <Typography className={classes.subTitle}>{t`Unit Parameters`}</Typography>
            {numOfUnitParams > 0 ? <List className={clsx(classes.paramsPopupList, { [classes.paramsWithNoSensors]: numOfSensorsAndPower === 0 })} >
              <ListItem disableGutters dense button onClick={(event: any) => showHideAllParams(event.target.checked, "unit")}>

                <Checkbox
                  color="default"
                  checked={allUnitsShowed}
                  classes={{ root: classes.grayCheckbox }}
                />

                <ListItemText id={`checkbox-text-all`} primary={t`All`} />
              </ListItem>

              {_.orderBy(Object.values(paramsTable), ["plottable", "isCalculated", "name"], ["desc", "desc", "asc"]).map((row: any, index: number) => {
                if (!row || !row.hideInTable || row?.isCustomParam) {
                  return null;
                }
                const isSelected = row?.isShowing;
                return (
                  <Tooltip
                    arrow
                    title={row.isCustomParam ? getStringFormula(row.formula) : row.techInfo ? `${row.techInfo}` : `${row.name}`}
                    placement="top-start"
                    classes={{ popper: classes.popper }}
                    key={`param-show-${index}`}
                  >
                    <ListItem disableGutters dense button onClick={(event: any) => {
                      event.stopPropagation();
                      showHideParam(row.code, row.type);
                    }}>
                      <Checkbox
                        color="default"
                        checked={isSelected}
                        classes={{ root: classes.grayCheckbox }}
                      />
                      <ListItemText id={`checkbox-text-${index}`} primary={row.name} />
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
              : <Typography style={{ padding: "20px 15px", fontSize: "18px" }}>{t`No parameters for this unit`}</Typography>
            }
            {numOfSensorsAndPower > 0 &&
              (<>
                <Typography className={classes.subTitle}>{sensorAndPowerTitle}</Typography>
                <List className={classes.paramsPopupList} >
                  <ListItem disableGutters dense button onClick={(event: any) => showHideAllParams(event.target.checked, "sensorPower")}>

                    <Checkbox
                      color="default"
                      checked={allSensorsAndPowerShowed}
                      classes={{ root: classes.grayCheckbox }}
                    />

                    <ListItemText id={`checkbox-text-all`} primary={t`All`} />
                  </ListItem>

                  {_.orderBy(Object.values(sensorAndPowerMetersData), ["type", "name"], ["desc", "asc"]).map((row: any, index: number) => {
                    if (!row || !row.hideInTable) {
                      return null;
                    }
                    const isSelected = row?.isShowing;
                    return (
                      <ListItem key={`param-show-${index}`} disableGutters dense button onClick={(event: any) => showHideParam(row.id, row.type)}>
                        <Checkbox
                          color="default"
                          checked={isSelected}
                          classes={{ root: classes.grayCheckbox }}
                        />
                        <ListItemText id={`checkbox-text-${index}`} primary={row.name} />
                      </ListItem>

                    );
                  })}
                </List>
              </>)}
          </> :
          <>
            {<List className={classes.paramsPopupList} >
              {canCreateCustomParameters &&
                <ListItem disableGutters dense button onClick={() => { setCustomParamToEdit({}); handleClose(); }}>
                  <AddIcon color="#736973" style={{ transform: "scale(0.8)", marginTop: "-2px" }} />
                  <ListItemText id={`checkbox-text-all`} primary={t`Add new`} />
                </ListItem>}
              {numOfCustomParams > 0 && <>
                <ListItem disableGutters dense button onClick={(event: any) => showHideAllParams(event.target.checked, "custom")}>

                  <Checkbox
                    color="default"
                    checked={allCustomShowed}
                    classes={{ root: classes.grayCheckbox }}
                  />

                  <ListItemText id={`checkbox-text-all`} primary={t`All`} />
                </ListItem>

                {_.orderBy(Object.values(customParamsTable), ["plottable", "name"], ["desc", "asc"]).map((row: any, index: number) => {
                  if (!row || !row.hideInTable) {
                    return null;
                  }
                  const isSelected = row?.isShowing;
                  return (
                    <ListItem key={`param-show-${index}`} disableGutters dense button onClick={(event: any) => {
                      event.stopPropagation();
                      showHideParam(row.code, row.type);
                    }}>
                      <Tooltip
                        arrow
                        title={row.isCustomParam ? getStringFormula(row.formula) : row.techInfo ? `${row.techInfo}` : `${row.name}`}
                        placement="top-start"
                        classes={{ popper: classes.popper }}
                        key={`param-show-${index}`}
                      >
                        <div className={classes.customParamToolTipChild}>
                          <Checkbox
                            color="default"
                            checked={isSelected}
                            classes={{ root: classes.grayCheckbox }}
                          />
                          <ListItemText id={`checkbox-text-${index}`} primary={row.name} />
                        </div>
                      </Tooltip>
                      <div>
                        {canUpdateCustomParameters && <IconButton
                          classes={{ root: classes.overWriteIcon }}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            setCustomParamToEdit(row);
                            handleClose();
                          }}>
                          <EditIcon />
                        </IconButton>}
                        {canDeleteCustomParameters &&
                          <IconButton
                            disableRipple
                            onClick={(event: any) => {
                              event.stopPropagation();
                              setToDeleteItem(row);
                            }}
                            classes={{ root: classes.overWriteIcon }}
                          >
                            <DeleteIcon />
                          </IconButton>}
                      </div>
                    </ListItem>

                  );
                })}
              </>}
            </List>
            }
          </>
        }
      </Popover>)}

      <div className={clsx(classes.tableWrapper, { [classes.largeWrapper]: (!_.isEmpty(sensorsRows) || !_.isEmpty(powerMetersRows)) && numOfShowingSensorsAndPower !== 0 })}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <colgroup>
            <col style={{ width: "20px" }} />
            <col style={{ minWidth: "20ch" }} />
            <col style={{ width: "20px" }} />
            <col style={{ minWidth: "60px" }} />
            <col style={{ width: "20px" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={clsx(classes.tableCellAlignLeft, classes.nameCell)}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Name`}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Value`}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={clsx(classes.tableCell, classes.rangeCell)}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Value range`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <EditParamPopUp row={editedRow} open={openEditDialog} unit={unit} onClose={closeDialog}
              setValue={setNewValue} reload={reFetchData} editedRowIndex={editedRowIndex}
              newValue={values[editedRowIndex]} resetParams={resetParams} />
            {(autoupdate ? true : !isLoading) && paramsTable && _.orderBy(Object.values(paramsTable), ["plottable", "isCalculated", "name"], ["desc", "desc", "asc"])?.map((row: any, index: number) => {
              if (!row) {
                return null;
              }
              const {
                isShowing,
                enumVal,
                name,
                hvac_param_name,
                value,
                measurementUnits,
                code,
                isChecked,
                slider,
                plottable,
                editable,
                hideInTable,
                isCustomParam,
                formula
              } = row;
              if (!isShowing || !hideInTable) {
                return null;
              }
              const displayedValue = enumVal ? enumVal : value;
              return (

                <TableRow hover className={clsx(!plottable && classes.tableRowUnplottable)} role="checkbox" tabIndex={-1} key={`graphData-${index}`}>
                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", backgroundColor: "white"
                    }}
                  >
                    {plottable ?

                      <Tooltip
                        arrow
                        title={t`Show parameter on graph`}
                        placement="top-start"
                        classes={{ popper: classes.popper }}
                      >
                        <Checkbox
                          color="default"
                          checked={isChecked}
                          onChange={(event: any) => {
                            updateParamRow(code, "param");
                          }}
                          classes={{ root: classes.grayCheckbox }}
                          disabled={isLoading}
                        />
                      </Tooltip>

                      :
                      < span />}
                  </TableCell>

                  <Tooltip
                    arrow
                    title={isCustomParam ? getStringFormula(formula) : paramsTechData && paramsTechData[code] ? `${paramsTechData[code]}` : `${name}`}
                    placement="top-start"
                    classes={{ popper: classes.popper }}
                  >
                    <TableCell
                      style={{ backgroundColor: "white" }}
                      className={clsx(classes.tableCellAlignLeft, classes.nameCell)}
                    >
                      {name}

                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    arrow
                    title={paramsTechData && paramsTechData[code] ? `${paramsTechData[code]}` : `${hvac_param_name}`}
                    placement="top-start"
                    classes={{ popper: classes.popper }}
                  >
                    <TableCell
                      style={{ backgroundColor: "white" }}
                      className={
                        clsx(classes.tableCellAlignLeft, classes.smallCell)}
                    >
                      {isCustomParam ? <Typography className={classes.customLabel}>{t`CUSTOM`}</Typography> : hvac_param_name}

                    </TableCell>
                  </Tooltip>

                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                    }}
                  >

                    <div className={classes.tableCellValue}>
                      <div className={classes.editContainer}>
                        {editable && <IconButton onClick={() => editParam(row, index)}><EditIcon /></IconButton>}
                      </div>
                      <Typography>
                        {editable && values[index] ? values[index] : displayedValue}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell
                    className={classes.tableCellAlignLeft}
                    style={{
                      paddingLeft: "15px", minWidth: "60px", maxWidth: "60px", backgroundColor: "white"
                    }}
                  >
                    {measurementUnits}
                  </TableCell>

                  <TableCell
                    className={clsx(classes.tableCell, classes.rangeCell)}
                    style={{
                      paddingLeft: "auto", backgroundColor: "white"
                    }}
                  >
                    {slider}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {
        (!_.isEmpty(sensorsRows) || !_.isEmpty(powerMetersRows)) && numOfShowingSensorsAndPower !== 0 &&
        <>
          <div className={classes.parametersHeader}>
            <Typography variant="h6" className={classes.title}>{_.isEmpty(sensorsRows) ? t`Power Meters` : _.isEmpty(powerMetersRows) ? t`Sensors` : t`Sensors And Power Meters`}</Typography>
          </div>
          <div className={classes.smallWrapper}>
            <Table stickyHeader aria-label="sticky table" className={classes.table}>
              <colgroup>
                <col style={{ width: "20px" }} />
                <col style={{ minWidth: "20ch" }} />
                <col style={{ minWidth: "60px" }} />
                <col style={{ width: "20px" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    align={"left"}
                    classes={{ stickyHeader: classes.tableHeadStyle }}
                  >
                    {``}
                  </TableCell>

                  <TableCell
                    className={classes.tableCellAlignLeft}
                    align={"left"}
                    classes={{ stickyHeader: classes.tableHeadStyle }}
                  >
                    {t`Name`}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align={"left"}
                    classes={{ stickyHeader: classes.tableHeadStyle }}
                  >
                    {t`Value`}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    align={"left"}
                    classes={{ stickyHeader: classes.tableHeadStyle }}
                  >
                    {``}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    align={"left"}
                    classes={{ stickyHeader: classes.tableHeadStyle }}
                  >
                    {t`Value range`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(autoupdate ? true : !isLoading) && _.orderBy(Object.values(sensorsRows), ["name"], ["asc"])?.map((row: any, index: number) => {
                  const {
                    id,
                    isChecked,
                    isShowing,
                    displayValue,
                    name,
                    slider,
                    unit = "",
                    tempSign
                  } = row;

                  if (!isShowing) {
                    return null;
                  }

                  return (

                    <TableRow hover className={clsx(true && classes.tableRowUnplottable)} role="checkbox" tabIndex={-1} key={`sensor-row-${index}`}>
                      <TableCell
                        className={classes.tableCell}
                        style={{
                          paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                        }}
                      >
                        <Tooltip
                          arrow
                          title={t`Show sensor on graph`}
                          placement="top-start"
                          classes={{ popper: classes.popper }}
                        >
                          <Checkbox
                            color="default"
                            checked={isChecked}
                            onChange={() => updateParamRow(id, "sensor")}
                            classes={{ root: classes.grayCheckbox }}
                            disabled={isLoading}
                          />
                        </Tooltip>
                      </TableCell>

                      <Tooltip
                        arrow
                        title={`${name}`}
                        placement="top-start"
                        classes={{ popper: classes.popper }}
                      >
                        <TableCell
                          style={{ backgroundColor: "white" }}
                          className={
                            clsx(classes.tableCellAlignLeft, classes.nameCell)}
                        >
                          {name}

                        </TableCell>
                      </Tooltip>
                      <TableCell
                        className={classes.tableCell}
                        style={{
                          paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                        }}
                      >
                        {displayValue}
                      </TableCell>

                      <TableCell
                        className={classes.tableCellAlignLeft}
                        style={{
                          paddingLeft: "15px", minWidth: "60px", maxWidth: "60px", backgroundColor: "white"
                        }}
                      >
                        {symbols[unit] || ""}{tempSign}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          paddingLeft: "auto", minWidth: "200px", backgroundColor: "white"
                        }}
                      >
                        {slider}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {(autoupdate ? true : !isLoading) && _.orderBy(Object.values(powerMetersRows), ["name"], ["asc"])?.map((row: any, index: number) => {

                  const {
                    id,
                    isChecked,
                    isShowing,
                    displayValue,
                    name,
                    slider
                  } = row;

                  if (!isShowing) {
                    return null;
                  }

                  return (

                    <TableRow hover className={clsx(true && classes.tableRowUnplottable)} role="checkbox" tabIndex={-1} key={`sensor-row-${index}`}>
                      <TableCell
                        className={classes.tableCell}
                        style={{
                          paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                        }}
                      >
                        <Tooltip
                          arrow
                          title={t`Show power meter on graph`}
                          placement="top-start"
                          classes={{ popper: classes.popper }}
                        >
                          <Checkbox
                            color="default"
                            checked={isChecked}
                            onChange={() => updateParamRow(id, "power")}
                            classes={{ root: classes.grayCheckbox }}
                            disabled={isLoading}
                          />
                        </Tooltip>
                      </TableCell>

                      <Tooltip
                        arrow
                        title={`${name}`}
                        placement="top-start"
                        classes={{ popper: classes.popper }}
                      >
                        <TableCell
                          style={{ backgroundColor: "white" }}
                          className={
                            clsx(classes.tableCellAlignLeft, classes.nameCell)}
                        >
                          {name}
                        </TableCell>
                      </Tooltip>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                        }}
                      >
                        {displayValue}
                      </TableCell>

                      <TableCell
                        className={classes.tableCellAlignLeft}
                        style={{
                          paddingLeft: "15px", minWidth: "60px", maxWidth: "60px", backgroundColor: "white"
                        }}
                      >
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          paddingLeft: "auto", minWidth: "200px", backgroundColor: "white"
                        }}
                      >
                        {slider}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </>
      }
      {
        toDeleteItem && (
          <Dialog
            open={toDeleteItem}
            onClose={(event: any) => {
              event.stopPropagation();
              handleDeleteClose();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialog }}
          >
            <div className={classes.titleContent}>
              <Typography className={classes.headerTitle}>{t`Delete User Defined Parameter`}</Typography>
              <IconButton disableRipple onClick={() => setToDeleteItem(null)} className={classes.overWriteIcon}>
                <Close color="#7f7692" />
              </IconButton>
            </div>
            <div className={classes.dialogContent}>
              <Typography className={classes.DialogContentText}>
                {t`Are you sure you want to delete ` + toDeleteItem?.name + ` ?`}
              </Typography>
            </div>
            <div className={classes.deleteDialogActions}>
              <Button
                onClick={(event: any) => {
                  event.stopPropagation();
                  deleteCustomParam(toDeleteItem.id)
                    .then(() => {
                      deleteParam(toDeleteItem.code);
                      deleteServiceParams(toDeleteItem.id);
                    })
                    .catch((err: any) => addMessage({ message: err.message }))
                    .finally(() => handleDeleteClose());
                }}
                className={classes.yesBtn}
                white width={130}
                marginRight
              >
                {t`Yes`}
              </Button>

              <Button
                autoFocus={true}
                onClick={(event: any) => {
                  event.stopPropagation();
                  handleDeleteClose();
                }}
                width={130}
              >
                {t`No`}
              </Button>
            </div>
          </Dialog>
        )
      }
      {
        customParamToEdit &&
        <UserDefinedParamsTab
          unitType={unitType}
          brand={brand}
          editMode={!_.isEmpty(customParamToEdit)}
          open={customParamToEdit}
          customParam={customParamToEdit}
          handleClose={handleClose}
          objectOptions={{ "-999": { code: -999, name: t`Constant` }, ...paramsTable }}
          options={_.orderBy(Object.values(paramsTable), ["plottable", "name"], ["desc", "asc"]).filter((param: any) => (param && !param.isCustomParam))}
        />
      }
    </Paper >
  );

}
