import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { useStoreActions } from "../../models/RootStore";
import useStyles from "./LogOut.style";

export default function LogOut() {
  const [open, setOpen] = React.useState(false);
  const doLogout = useStoreActions((a) => a.doLogout);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    setOpen(false);
    doLogout();
  };

  return (
    <>
      <div className={classes.dropDownItem} onClick={handleClickOpen}>{t`Log out`}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      // fullWidth
      >
        <div className={classes.titleContent}>
          <Typography variant="h6">{t`Log out`}</Typography>
        </div>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description" className={classes.DialogContentText}>
            {t`Are you sure you want to log-out?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
        <Button
        onClick={handleAgree}
        className={classes.yesBtn}
        white
        width={100}
        marginRight
        >
        {t`Yes`}
        </Button>
          <Button
            autoFocus={true}
            onClick={handleClose}
            width={100}
          >
            {t`No`}
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}
