import { FilledInputProps, FormControl, makeStyles, TextField } from "@material-ui/core";
import { FilledTextFieldProps } from "@material-ui/core/TextField";
import clsx from "clsx";
import { FieldProps } from "formik";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
  input: {
    fontSize: `${theme.textField.loginForm.fontSize} !important`,
    width: "354px",
    "& label": {
      color: theme.textField.loginForm.color
    },
    "& .Mui-focused": {
      color: theme.textField.loginForm.color
    },
    "& div": {
      backgroundColor: theme.textField.loginForm.backgroundColor
    },
    "& .MuiFilledInput-input": {
      color: theme.textField.loginForm.color,
      fontSize: theme.textField.loginForm.fontSize,
      borderRadius: "4px"
    },
    "& .MuiFormLabel-filled": {
      color: theme.textField.loginForm.color
    },
    "& :-webkit-autofill": {
      "-webkit-text-fill-color": `${theme.textField.loginForm.color} !important`,
      "-webkit-box-shadow": `${theme.textField.loginForm.boxShadow} !important`
    },
    "& .MuiFilledInput-underline:before": {
      "border-bottom": "none",
      content: "none"
    },
    "& .MuiFilledInput-underline:after": {
      "border-bottom": "none",
      content: "none"
    },
    "& .MuiFilledInput-input:-webkit-autofill": {
      color: theme.textField.loginForm.color,
      backgroundColor: theme.textField.loginForm.backgroundColor,
      borderRadius: "4px"
    },
    "&:-internal-autofill-selected": {
      color: theme.textField.loginForm.color
    }
  },
  margin: {
    "margin-top": "0.5rem",
    "margin-bottom": "0.5rem",
    "margin-right": "1.2rem",
    "margin-left": "1.2rem"
  },
  textField: {
    width: "200"
  }
}));
const InputField: React.FC<FieldProps & FilledTextFieldProps & FilledInputProps> = ({
  field,
  meta,
  form,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
      <TextField
        error={props.error}
        helperText={props.helperText}
        {...field}
        {...props}
        margin="dense"
        variant="filled"
        className={classes.input}
        // color='secondary'
      />
    </FormControl>
  );
};

export default InputField;
