import {
  Dialog,
  DialogContentText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { AddEditScript, ApplyToDialog } from "../../components/AddEditScript";
import Delete from "../../components/Delete/Delete";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./CommissioningList.style";

const ScriptsList: React.FC = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const isInitialized = useStoreState((s) => s.isInitialized);
  const selections = useStoreState((state) => state.selections.selections);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const types = useStoreState((state) => state.types);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const allSites = useStoreState((s) => s.sites.allSites);

  const {
    getSiteScripts,
    createScript,
    updateScript,
    deleteScript,
    runProcedure
  } = useStoreActions((action) => action.scripts);

  const [openAddScript, setOpenAddScript] = useState<boolean>(false);
  const [scripts, setScripts] = useState<any>({});
  const [editScript, setEditScript] = useState<any>(null);
  const [applyToId, setApplyToId] = useState<string>("");
  const [showGoToPopup, handleShowGoToPopup] = useState<boolean>(false);
  const sortedProcedures = _.orderBy(Object.values(scripts), [(script: any) => script.name?.toLowerCase()], ["asc"]);
  const { customerId, siteId } = selections;
  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canCreateProcedures, canRunProcedures, canDeleteProcedures, canUpdateProcedures } = permissions || {};

  useEffect(() => {
    if (!siteId) {
      return;
    }

    getSiteScripts({ siteId, type: 1 })
      .then((res: any) => setScripts(res))
      .catch((err: any) => addMessage({ message: err.message }));
  }, [siteId]);

  const addNewScript = (data: any, siteId: string) => {
    return createScript({ site: siteId, ...data })
      .then((resp: any) => setScripts({ ...scripts, [resp.id]: { ...resp } }))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const updateExistingScript = (data: any, scriptId: string) => {
    return updateScript({ id: scriptId, data })
      .then((resp: any) => setScripts({ ...scripts, [scriptId]: { ...scripts[scriptId], ...resp } }))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const deleteCurrentScript = (id: string) => {
    return deleteScript(id)
      .then(() => {
        delete scripts[id];
        setScripts({ ...scripts });
      })
      .catch((err: any) => addMessage({ message: err.message }));

  };
  const closeDialog = () => {
    setOpenAddScript(false);
    setEditScript(null);
  };

  const getBrandName = (brandNum: any) => {
    const brand = _.find(types.hvacBrands, { value: brandNum });
    return !_.isNil(brand) && brand.name ? brand.name : "";
  };

  const runNewProcedureInstance = (id: string, units: any, systems: any) => {
    runProcedure({ id, data: { units, systems } })
      .then(() => {
        setApplyToId("");
        handleShowGoToPopup(true);
      })
      .catch((err: any) => addMessage({ message: err.message }));

  };
  if (!isInitialized) { return <Loading />; }

  const onGo = () => {
    history.push("/commissioning-logs");
  };

  const onStay = () => {
    handleShowGoToPopup(false);
  };

  const LeavePagePopUp = () => {
    return (
      <Dialog
        open={true}
        onClose={onStay}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.dialogStyle }}
      >
        <DialogContentText
          className={clsx(classes.contentStyle, {
            [classes.textNotCentered]: false
          })}
        >{t`Go to procuders logs page`}</DialogContentText>
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <Button white marginRight onClick={onStay} variant="contained">
            {t`Stay on current page`}
          </Button>
          <Button onClick={onGo} variant="contained">
            {t`Ok`}
          </Button>
        </div>
      </Dialog>
    );
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          screenTitle="commissioningProdecuders"
        />
        {!siteId ? <FilterRequire type={t`site`} /> :
          <>
            <div className={classes.headerButtons}>
              <Button
                disabled={!canCreateProcedures}
                width={235}
                onClick={() => setOpenAddScript(true)}
              >
                {t`Add New Procedure Script`}
              </Button>
            </div>
            <Paper elevation={0} className={classes.paperTableContainer}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Name`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Brand`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Description`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`run`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`EDIT`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`REMOVE`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedProcedures.map((script: any) => {
                      const { canDelete, canUpdate, canRun } = script?.permissions || {};
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={script.id}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"
                          >
                            {script.name}
                          </TableCell>
                          <TableCell
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"

                          >
                            {getBrandName(script.userSelections?.brand) || "All"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                            align="left"
                          >
                            {script.description}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"
                          >
                            <LightTooltip title={t`run`}>
                              <IconButton disabled={!canRunProcedures || !canRun} disableRipple onClick={() => setApplyToId(script.id)} className={classes.iconBtnStyle}>
                                <PlayArrow className={classes.iconColor} />
                              </IconButton>
                            </LightTooltip>
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            <LightTooltip title={t`Edit commissioning script`}>
                              <IconButton
                                disabled={!canUpdate}
                                disableRipple
                                onClick={() => setEditScript(script)}
                                className={classes.iconBtnStyle}
                              >
                                <EditIcon />
                              </IconButton>
                            </LightTooltip>
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            <Delete
                              disabled={!canDeleteProcedures || !canDelete}
                              type={t`commissioning script`}
                              object={script}
                              detach={() => deleteCurrentScript(script.id)}
                            ></Delete>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>}
        {(openAddScript || editScript) &&
          <AddEditScript
            close={closeDialog}
            createScript={addNewScript}
            editScript={editScript}
            updateScript={updateExistingScript}
            siteId={siteId}
            canEdit={canUpdateProcedures}
          />
        }
        {
          applyToId && <ApplyToDialog
            close={() => setApplyToId("")}
            onSave={runNewProcedureInstance}
            brand={scripts[applyToId]?.userSelections?.brand}
            siteId={siteId}
            customerId={customerId}
            procedureId={applyToId}
          />
        }
        {showGoToPopup && <LeavePagePopUp />}
      </div>
    </div>
  );
};

export default ScriptsList;
