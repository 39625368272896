import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 83px)",
      maxHeight: "calc(100% - 83px)",
      display: "flex",
      background: "white",
      flexFlow: "column nowrap",
      justifyContent: "space-between"
    },
    pageContent: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      flex: 1,
      overflow: "auto",
      padding: "0 10px"
    },
    section: {
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5"
    },
    nameSection: {
      minHeight: 80,
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5"
    },
    moreInfo: {
      minHeight: "max-content",
      paddingBottom: "6px",
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    controlSection: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "max-content",
      padding: "0 10px"

    },
    controlSectionHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      boxSizing: "border-box",
      height: "60px"
    },
    controlSectionTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964"
    },
    controlSectionBody: {
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box"
    },
    saveButton: {
      margin: "25px auto",
      width: "calc(100% - 20px) !important",
      minHeight: 50

    },
    textStyle: {
      width: "100%",
      height: 50,
      "& div": {
        height: 50
      }
    },
    unitListVisibility: {
      display: "flex",
      padding: "0 20px 23px 20px",
      justifyContent: "space-between"
    },
    normalSection: {
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5",
      minHeight: 60,
      padding: "0 10px"
    },
    noContentContainer: {
      boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
      backgroundColor: "#fefefe",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "100%",
      height: "100%",
      padding: 20
    },
    grayOval: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "136px",
      height: "136px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      borderRadius: "50%"
    },
    arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
    noUnitText: {
      fontSize: "26px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#545964",
      marginTop: "43px"
    }
  });
