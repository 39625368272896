import * as React from "react";

function SvgArrowDownO(props) {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" {...props}>
      <path
        fill={props.fill || "#7F7692"}
        fillRule="evenodd"
        d="M.236 1.495l5.16 5.245c.34.347.87.347 1.21 0l5.14-5.245a.877.877 0 000-1.235.833.833 0 00-.595-.26.832.832 0 00-.595.26L5.991 4.92 1.447.282a.83.83 0 00-.595-.26.826.826 0 00-.594.26.87.87 0 00-.022 1.213z"
      />
    </svg>
  );
}

export default SvgArrowDownO;
