import React from "react";

const SvgHeat = (props: any) => (
  <svg width={40} height={40} {...props}>
    <path
      d="M28.334 19.948a8.389 8.389 0 1 1-16.777-.002 8.389 8.389 0 0 1 16.777.002zm-8.388 16.776v-4.438m0-24.677V3.171m8.388 31.398l-2.259-3.911M13.735 9.284L11.56 5.515m19.03 20.671h.004l3.882 2.244M9.221 13.848l-3.803-2.196m31.306 8.39h-4.442m-24.67-.002H3.171m31.305-8.388l-3.802 2.196M5.418 28.43l3.883-2.244h.002M28.334 5.514l-2.175 3.769M13.817 30.659l-2.257 3.91"
      fill="none"
      fillRule="evenodd"
      stroke={"#F05146"}
      strokeLinecap="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgHeat;
