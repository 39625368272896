import React from "react";

const SvgModeFan = props => (
  <svg width={32} height={32} {...props}>
    <path
      d="M17 1.413c0-.907.771-1.547 1.765-1.389 3.2.747 5.735 3.576 5.735 6.94 0 3.364-2.372 6.194-5.735 6.94-.938.16-1.765-.48-1.765-1.389V1.413zm-2 29.174c0 .907-.771 1.547-1.765 1.389-3.2-.747-5.735-3.576-5.735-6.939 0-3.415 2.372-6.192 5.735-6.939.938-.162 1.765.426 1.765 1.387v11.102zM1.413 15c-.907 0-1.547-.771-1.389-1.765C.771 10.035 3.6 7.5 6.964 7.5c3.364 0 6.194 2.372 6.94 5.735.16.938-.48 1.765-1.389 1.765H1.413zm29.174 2c.907 0 1.547.771 1.389 1.765-.747 3.2-3.576 5.735-6.94 5.735-3.364 0-6.194-2.372-6.94-5.735-.16-.938.48-1.765 1.389-1.765h11.102z"
      fill="none"
      fillRule="evenodd"
      stroke="#FDFDFE"
      strokeWidth={1.6}
      opacity={0.4}
    />
  </svg>
);

export default SvgModeFan;
