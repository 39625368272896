import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  content: {
    // margin: '1rem',
    height: "100%",
    marginTop: 0
  },
  wrapper: {
    height: "calc(100vh - 220px)",
    padding: "0 1rem 1rem",
    flexFlow: "row nowrap",
    background: "rgb(236, 235, 239)"
  },
  view: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    position: "relative"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  container: {
    padding: "3rem"
  },
  tableContainer: {
    height: "calc(100% - 53px)",
    // padding: "1rem",
    width: "auto"
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progress: {
    height: "calc(100vh - 125px)",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  hidden: {
    display: "none"
  },
  tableHeadCell: {
    borderBottom: "none"
  },

  alertInfoContainer: {
    height: "100%"
  },
  selected: {
    backgroundColor: "#f4f4f4"
  },
  headContainer: { display: "flex", alignItems: "center", maxWidth: "200px" },
  blueFilter: { fill: "#1976d2" },
  filterStyle: { marginLeft: "10px" },
  dateColumn: { minWidth: "135px" },
  tablePaper: {
    background: "#fefefe",
    padding: "20px",
    paddingTop: 0,
    borderRadius: "4px",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    border: "solid 1px #ebebeb",
    height: "100%"
  },
  tablePagesHeader: { display: "flex", justifyContent: "flex-end" },
  columnsTitles: {
    border: "none",
    ...theme.tables.headerCell
  },
  pagingStyle: { border: "none" },
  cellStyle: {
    fontSize: theme.tables.tableCell.fontSize,
    height: "35px",
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem",
    minHeight: "70px",
    background: theme.palette.background.default
  },
  contentHeaderContainer: {
    display: "flex",
    padding: "16px",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "70px"
  },
  shareButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },
  showWhiteSpace: {
    whiteSpace: "pre"
  },
  typeKey: {
    width: "7px",
    height: "7px",
    borderRadius: "100%",
    backgroundColor: "#000"
  },
  iconBtn: {
    borderRadius: "6px",
    height: 30,
    width: 25,
    padding: 0,
    marginBottom: 0
  },
  filterItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#efeff2",
    borderRadius: 6,
    marginRight: 15,
    padding: "0 8px",
    height: 32,
    maxWidth: "100%"
  },
  filterItemText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    whiteSpace: "nowrap",
    marginRight: 8,
    maxWidth: "calc(100% - 26px)",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  filterItemCloseIcon: {
    transform: "scale(0.5)",
    marginLeft: "-0.5px"
  },
  filterItemIconBtn: {
    backgroundColor: "#aaa2aa",
    borderRadius: "50%",
    width: 18,
    height: 18,
    padding: 0
  },
  filterItemsContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "calc(100% - 120px)",
    overflow: "auto",
    height: "100%",
    width: "100%"
  },
  ackButtonContainer: {
    marginTop: "20px",
    marginBottom: "40px"
  },
  ackButton: {
    height: "40px !important",
    borderRadius: "6px !important",
    border: "solid 1px #413044 !important"
  },
  ackButtonLable: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
  },
  ackButtonText: {
    fontSize: "16px",
    color: "#545964",
    textTransform: "capitalize"
  },
  ackButtonIcon: {
    fontSize: "19px",
    marginRight: "7px",
    color: "#00b259"
  },
  cutLongNames: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  clearTime: {
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#545964"
  },
  alertStatus: {
    fontFamily: "Roboto",
    fontSize: 14,
    width: "110px",
    color: "#35a8e0"
  },
  closedAlert: {
    color: "#00b259"
  },
  openAlert: {
    color: "#f5833c"
  }
}));
export default useStyles;
