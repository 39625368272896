import * as React from 'react';

function SvgCheckboxChecked(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={17} height={17} x={0.5} y={0.5} fill="#FFF" stroke="#D5D2D5" rx={3} />
        <path
          fill="#5C465D"
          fillRule="nonzero"
          d="M6.744 13.5a1.194 1.194 0 01-.848-.377l-2.204-2.356a1.176 1.176 0 01.061-1.668 1.19 1.19 0 011.677.06l1.366 1.459 5.819-5.787a1.19 1.19 0 011.662.015c.458.455.465 1.192.015 1.654l-6.688 6.654a1.2 1.2 0 01-.839.346h-.021z"
        />
      </g>
    </svg>
  );
}

export default SvgCheckboxChecked;
