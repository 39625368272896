import { Button, Input, MenuItem, Select, Slide, Typography, withStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import timePickerStyle from "./timePicker.style";

interface IPickerProps {
  classes: any;
  time?: string;
  onSet?: any;
  onDismiss?: any;
  onClear?: any;
  desktop?: boolean;
  show?: boolean;
  overWriteCss?: boolean;
  is12Hours?: boolean;
  black?: boolean;
}

const TimePicker: React.FC<IPickerProps> = ({
  classes,
  time,
  onDismiss,
  onSet,
  desktop,
  show,
  onClear,
  overWriteCss,
  is12Hours,
  ...props
}) => {
  const [selectedHour, setSelectedHour] = useState<any>(null);
  const [selectedMinutes, setSelectedMinutes] = useState<any>(null);
  const [selectedMidday, setSelectedMidday] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const onHourChange = (value: any) => {
    const maxValue = is12Hours ? 12 : 23;
    const minValue = is12Hours ? 1 : 0;
    if (value > maxValue || value < minValue || value.length > 2) {
      return;
    }
    setSelectedHour(value);
  };
  const onMinutesChange = (value: any) => {
    if (value > 59 || value < 0 || value.length > 2) {
      return;
    }
    setSelectedMinutes(value);
  };

  useEffect(() => {
    if (!show) {
      return;
    }

    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (!time) {
      setSelectedHour(0);
      setSelectedMinutes(0);
      is12Hours ? setSelectedMidday("am") : setSelectedMidday("");
      return;
    }

    const timeArray = time.split(":");
    const minutesAndMidday = timeArray[1];
    let hours = +timeArray[0];
    const minutes = +minutesAndMidday.substr(0, 2);
    let midday = minutesAndMidday.substr(2, 2) || "";
    setSelectedHour(hours);
    setSelectedMinutes(minutes);
    setSelectedMidday(midday);
  }, [time]);

  const setTime = () => {
    const currentSelectedtime = selectedHour.toString().padStart(2, "0") + ":" + selectedMinutes.toString().padStart(2, "0") + selectedMidday;
    onSet(currentSelectedtime);
    setOpen(false);
  };

  const onDismissClick = () => {
    setOpen(false);
    onDismiss && onDismiss();
  };

  const clearTime = () => {
    onClear();
    setOpen(false);
  };

  const getContent = () => {

    return (
      <div className={clsx(classes.pickerContainer, { [classes.schedulePickerContainer]: overWriteCss })}>
        <div className={classes.actionsContainer}>
          <Button onMouseUp={onDismissClick} className={classes.btnStyle}>Dismiss</Button>
          <div>
            <Button onMouseUp={clearTime} className={classes.btnStyle}>Clear</Button>
            <Button onMouseUp={setTime} className={classes.btnStyle}>Set</Button>
          </div>
        </div>
        <div className={clsx(classes.itemsContainer, { [classes.scheduleItemsContainer]: overWriteCss })}>
          <div className={classes.pickerWrapper}>
            <KeyboardArrowUp className={clsx(classes.pickerIcon, { [classes.schedulePickerIcon]: overWriteCss })} onClick={() => onHourChange((+selectedHour + 1) + "")} />

            <Input
              type="number"
              value={selectedHour ?? ""}
              disableUnderline
              className={clsx(classes.timeInput, { [classes.scheduleTimeInput]: overWriteCss })}
              name={"hours"}
              onChange={(e) => onHourChange(e.target.value)}
            />
            <KeyboardArrowDown className={clsx(classes.pickerIcon, { [classes.schedulePickerIcon]: overWriteCss })} onClick={() => onHourChange((+selectedHour - 1) + "")} />
          </div>

          <div className={clsx(classes.colonWrapper, { [classes.scheduleColonWrapper]: overWriteCss })}>
            <Typography>:</Typography>
          </div>

          <div className={classes.pickerWrapper}>
            <KeyboardArrowUp className={clsx(classes.pickerIcon, { [classes.schedulePickerIcon]: overWriteCss })} onClick={() => onMinutesChange((+selectedMinutes + 1) + "")} />
            <Input
              type="number"
              value={selectedMinutes ?? ""}
              disableUnderline
              className={clsx(classes.timeInput, { [classes.scheduleTimeInput]: overWriteCss })}
              name={"mins"}
              onChange={(e) => onMinutesChange(e.target.value)}
            />
            <KeyboardArrowDown className={clsx(classes.pickerIcon, { [classes.schedulePickerIcon]: overWriteCss })} onClick={() => onMinutesChange((+selectedMinutes - 1) + "")} />
          </div>
          {is12Hours && (<Select
            labelId="label"
            id="am/pm"
            value={String(selectedMidday)}
            onChange={(e) => setSelectedMidday(e.target.value)}
            className={clsx(classes.middayWrapper, { [classes.middayBlackWrapper]: props.black })}
            inputProps={{
              classes: { icon: clsx(classes.pickerIcon, { [classes.blackPickerIcon]: props.black }) }
            }}
          >
            <MenuItem value="am">am</MenuItem>
            <MenuItem value="pm">pm</MenuItem>
          </Select>)}
        </div>
      </div>
    );
  };

  if (overWriteCss) {
    return (
      <div>
        {getContent()}
      </div>
    );
  }
  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      {getContent()}
    </Slide>
  );
};

export default withStyles(timePickerStyle)(TimePicker);
