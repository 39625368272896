import * as React from 'react';

function SvgCritical(props) {
  return (
    <svg width="15px" height="15px" viewBox="0 0 48 48" {...props}>
      <g fill="#EF3B2F">
        <circle cx={23.899} cy={23.899} r={23.899} fillOpacity={0.15} />
        <circle cx={24.696} cy={24.696} r={5.577} />
      </g>
    </svg>
  );
}

export default SvgCritical;
