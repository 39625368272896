import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
const drawerWidth = 377;

export default makeStyles((theme: any) => ({
  dialogPaper: {
    width: "600px",
    maxWidth: "95% !important",
    // minWidth: "850px",
    minHeight: "600px",
    height: "fit-content",
    maxHeight: "calc(100vh - 100px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden",
    position: "absolute",
    top: "70px",
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogContent: {
    overflow: "hidden",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
  },
  tableContainer1: {
    height: "calc(100vh - 175px)",
    padding: "0.6rem 1rem 1rem 0",
    width: "auto",
  },
  tableHeadRow: {
    height: "45px",
  },
  overWritePadding: {
    height: "55px",
    padding: "0 0.8rem",
    cursor: "pointer",
    ...theme.tables.tableCell,
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell,
  },
  actionsContainer: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px 20px",
    width: "100%",
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  mainSelect: { marginBottom: "20px", width: "300px !important" },
  container: {
    width: "100%",
    marginBottom: "5px",
    paddingTop: "5px",
  },
  subContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "5px",
    padding: "5px 20px",
  },
  overWritecontainerPadding: {
    paddingLeft: "0",
  },
}));
