import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  items: {
    display: "flex",
    alignItems: "center",
    padding: ".3rem"
  },
  allAnomaliesItem: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgb(236,235,239)",
    padding: "0 1rem"
  },
  allAlertItem: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgb(236,235,239)",
    padding: "0 1rem"
  },
  triggersItem: {
    marginLeft: "1rem",
    border: "1px solid lightgray",
    margin: ".5rem"
  },
  cardRow: {
    border: "1px solid lightgray",
    margin: ".5rem",
    padding: "5px"
  },
  title: {
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.primary.main
  },
  switchIcon: {
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  trapsDisabled: {
    opacity: "0.5"
  },
  secondaryTitle: {
    color: "#4b1c46",
    padding: "20px 24px",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 800,
    backgroundColor: "rgb(236,235,239)"
  },
  secondaryDiv: {
    backgroundColor: "rgb(236,235,239)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
  },
  container: {
    justifyContent: "space-evenly",
    marginTop: "1rem"
  },
  nameContainer: {
    margin: "2rem"
  },
  nameTitle: {
    color: "#4b1c46",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 800
  },
  plusIcon: {
    "&:hover": {
      "& $addIcon": {
        display: "none"
      },
      "& $addOnHoverIcon": {
        display: "inline"
      }
    },
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  addIcon: {
    display: "block"
  },
  addOnHoverIcon: {
    display: "none"
  },
  switch: {
    display: "flex",
    justifyContent: "flex-end"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    },
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialog: {
    width: "100%",
    borderRadius: "8px"
  },
  paperTableContainer: {
    boxSizing: "border-box",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },
  tableContainer: {
    display: "flex",
    height: "100%"
  },
  mediumWidth: { width: "60%", wordBreak: "break-word" },

  dialogActions: {
    padding: "1.5rem",
    overflowY: "hidden"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  dialogContent: {
    // padding: "0",
    flex: "1 1 auto",
    overflowY: "auto",
    // paddingBottom: 0,
    padding: 16,
    height: "600px"
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  boxContainer: {
    borderRadius: "4px",
    width: "45%"
  },
  blockContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    maxHeight: "300px"
  },
  firstRowBlock: {
    marginBottom: "1rem"
  },
  trapsContainer: {
    border: "solid 1px #d5d2d5",
    borderRadius: "4px",
    width: "45%",
    maxHeight: "620px"
  },
  allSitesItem: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgb(236,235,239)",
    padding: "0 0.9rem 0 1rem"
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "14px",
    marginRight: "30px",
    alignItems: "center",
    display: "flex"
  },
  overflowContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "200px",
    paddingTop: "2rem"
  },
  addMargin: {
    marginRight: "12px !important"
  },
  txtField: {
    height: "40px",
    width: "300px",
    "& div": {
      height: "40px"
    },
    marginBottom: 20
  },
  firstRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  blockContainer2: {
    width: "45%",
    maxHeight: "100%"
  },
  overflowContainer2: {
    height: "calc(100% - 68px)"
  },
  longBlockContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    height: "100%"
  },
  selectRoot: {
    height: 30,
    width: 150,
    "&>div": {
      width: "130px"
    }
  },
  selectMenu: {
    padding: 0,
    width: "80%",
    fontSize: "15px",
    paddingLeft: "5px",
    "&:focus": {
      background: "#fff"
    },
    "&:hover": {
      background: "#fff"
    }
  },
  inputStyle: {
    height: 30,
    width: "90%",
    padding: "0 6px"
  },
  disabledDiv: {
    pointerEvents: "none",
    opacity: 0.4
  },
  inputCheckbox: {
    padding: 0,
    "& svg": {
      width: 16
    }
  },
  contactIcon: {
    color: "#AAAAAA",
    border: "solid 1px",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    background: "#E7E7E7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -8
  },
  contactsTooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginRight: 4,
    "& span": {
      color: "#ccc",
      fontWeight: 400
    }
  },
  svgUserIcon: {
    marginLeft: 7
  },
  alertsTypesList: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  searchInput: {
    marginTop: 8,
    backgroundColor: theme.palette.colors.white,
    "& div": {
      paddingRight: 6
    }
  },
  link: {
    color: "#52ACFF",
    textDecoration: "underline",
    paddingTop: 5,
    display: "block",
    marginTop: 5,
    textAlign: "start"
  },
  centerLink: {
    color: "#52ACFF",
    textDecoration: "underline",
    paddingTop: 5,
    display: "block",
    marginTop: "25%",
    textAlign: "center"
  },
  alertsTitlesList: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    padding: 6
  },
  errorsListContainer: {
    padding: "0 20px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: 6,
    height: 330,
    overflow: "hidden"
  },
  errorsList: {
    overflow: "auto",
    height: 230,
    paddingRigh: 8
  },
  errorListItem: {
    border: "solid 1px #ccc",
    borderRadius: 6,
    backgroundColor: "#eee",
    padding: "0 6px",
    margin: "6px 0"
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "end",
    "& button": {
      height: 25
    }
  },
  selectContainer: {
    display: "flex",
    alignItems: "center"
  }
}));
export default useStyles;
