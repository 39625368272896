import React from "react";

function MobileRemoteControl(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <defs>
        <path id="a" d="M0 .263h17.66v4.74H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(8.096 3.683)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            fill="#AAA2AA"
            d="M1.082 5.003L-.001 3.918A12.431 12.431 0 018.83.263c3.335 0 6.47 1.299 8.831 3.655L16.58 5.003a10.9 10.9 0 00-7.75-3.207 10.902 10.902 0 00-7.747 3.207"
            mask="url(#b)"
          ></path>
        </g>
        <path
          fill="#AAA2AA"
          d="M16.927 19.736a1.65 1.65 0 111.652-1.65c0 .91-.74 1.65-1.652 1.65m3.555-5.954h-7.11c-.659 0-1.186.496-1.186 1.103v13.232c0 .606.527 1.102 1.186 1.102h7.11c.658 0 1.185-.496 1.185-1.102V14.885c0-.607-.527-1.103-1.185-1.103M21.66 11.7a6.65 6.65 0 00-4.733-1.96 6.65 6.65 0 00-4.734 1.96l-1.082-1.083a8.172 8.172 0 015.816-2.409c2.197 0 4.263.856 5.815 2.409L21.66 11.7z"
        ></path>
      </g>
    </svg>
  );
}

export default MobileRemoteControl;