import * as React from "react";

function SvgChangeoverGraph(props) {
  return (
    <svg
      width="255px"
      height="174px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="43.557%"
          y1="21.294%"
          x2="50%"
          y2="93.686%"
          id="changeoverGraph_svg__b"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#35A8E0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="36.093%"
          y1="0%"
          x2="50%"
          y2="93.686%"
          id="changeoverGraph_svg__d"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#35A8E0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="56.262%"
          y1="15.851%"
          x2="50%"
          y2="100%"
          id="changeoverGraph_svg__e"
        >
          <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
          <stop stopColor="#F05146" offset="100%" />
        </linearGradient>
        <path
          d="M0 0l69.793 90.542a8 8 0 0012.889-.295l34.658-49.489a8 8 0 0112.98-.175l40.428 54.544.16 44.873H0V0z"
          id="changeoverGraph_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h255v174H0z" />
        <text
          fontFamily="Roboto-Regular, Roboto"
          fontSize={10}
          fill="#7F7182"
          transform="translate(8 12)"
        >
          <tspan x={20.319} y={156.386}>
            {"Cooling"}
          </tspan>
        </text>
        <text
          fontFamily="Roboto-Medium, Roboto"
          fontSize={11}
          fontWeight={400}
          fill="#00B259"
          transform="translate(8 12)"
        >
          <tspan x={152.011} y={51.408}>
            {"Room"}
          </tspan>
          <tspan x={152.011} y={63.408}>
            {"Setpoint"}
          </tspan>
        </text>
        <g fill="#7F7182" fontFamily="Roboto-Regular, Roboto">
          <text fontSize={8.47} transform="translate(229.637 61.329)">
            <tspan x={0} y={8}>
              {"T"}
            </tspan>
          </text>
          <text fontSize={7} transform="translate(229.637 61.329)">
            <tspan x={5.232} y={6.156}>
              {"h"}
            </tspan>
          </text>
        </g>
        <g fill="#7F7182" fontFamily="Roboto-Regular, Roboto">
          <text fontSize={8.47} transform="translate(229.637 87.329)">
            <tspan x={0} y={8}>
              {"T"}
            </tspan>
          </text>
          <text fontSize={7} transform="translate(229.637 87.329)">
            <tspan x={5.232} y={6.156}>
              {"c"}
            </tspan>
          </text>
        </g>
        <path stroke="#545964" strokeLinecap="square" d="M224.5 76.5v-24" />
        <path
          fill="#545964"
          d="M222.11 53.171l2.457-4 2.457 4zm0 21l2.457 4 2.457-4z"
        />
        <path
          stroke="#545964"
          strokeLinecap="square"
          d="M224.5 102.5l.067-21.779"
        />
        <path
          fill="#545964"
          d="M222.11 83.171l2.457-4 2.457 4zm0 19l2.457 4 2.457-4z"
        />
        <text
          fontFamily="Roboto-Regular, Roboto"
          fontSize={10}
          fill="#7F7182"
          transform="translate(8 12)"
        >
          <tspan x={130.319} y={156.386}>
            {"Cooling"}
          </tspan>
        </text>
        <text
          fontFamily="Roboto-Regular, Roboto"
          fontSize={10}
          fill="#7F7182"
          transform="translate(8 12)"
        >
          <tspan x={81.319} y={156.386}>
            {"Heating"}
          </tspan>
        </text>
        <g transform="translate(8 12)">
          <mask id="changeoverGraph_svg__c" fill="#fff">
            <use xlinkHref="#changeoverGraph_svg__a" />
          </mask>
          <path
            fill="url(#changeoverGraph_svg__b)"
            opacity={0.7}
            mask="url(#changeoverGraph_svg__c)"
            d="M123 20h50v126h-50z"
          />
          <path
            fill="url(#changeoverGraph_svg__d)"
            opacity={0.7}
            mask="url(#changeoverGraph_svg__c)"
            d="M-1 0h77v146H-1z"
          />
          <path
            fill="url(#changeoverGraph_svg__e)"
            opacity={0.6}
            mask="url(#changeoverGraph_svg__c)"
            d="M76 20h47v126H76z"
          />
        </g>
        <path
          d="M8.268 13.502l69.05 89.27a8 8 0 0012.87-.29l34.741-49.366a8 8 0 0112.91-.24l41.07 53.975h0"
          stroke="#FCBC00"
          strokeWidth={1.155}
          strokeLinecap="round"
        />
        <path
          stroke="#F05146"
          strokeWidth={1.155}
          strokeLinecap="round"
          strokeDasharray="1.539999973773956,3.850000000000001"
          d="M8.444 106.499h236.348"
        />
        <path
          stroke="#35A8E0"
          strokeWidth={1.155}
          strokeLinecap="round"
          strokeDasharray="1.539999973773956,3.850000000000001"
          d="M8.444 48.723h236.348"
        />
        <path
          stroke="#00B259"
          strokeWidth={1.155}
          strokeLinecap="round"
          d="M8.444 78.727h236.348"
        />
        <text
          fontFamily="Roboto-Medium, Roboto"
          fontSize={11}
          fontWeight={400}
          fill="#FCBC00"
          transform="translate(8 12)"
        >
          <tspan x={24.616} y={21.166}>
            {"Room Temperature"}
          </tspan>
        </text>
        <path
          d="M8.17 5.452c0 4.67.064 53.67.192 147H240.17"
          stroke="#545964"
          strokeWidth={1.16}
        />
      </g>
    </svg>
  );
}

export default SvgChangeoverGraph;
