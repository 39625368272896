const flags: any = {
  control: "hasControl",
  systemDiag: "hasSysDiag",
  unitDiag: "hasUnitDiag",
  anomalies: "hasAnomalies",
  operationalAnomalies: "hasOperationalAnomalies", // same as anomalies-hasAnomalies
  alerts: "hasAlertsLog",
  audits: "hasAuditsLog",
  siteMgmt: "hasSiteMgmt",
  wrc: "hasWRC",
  settingsAlerts: "hasNotifications",
  tenantReports: "hasUsageAnalysis",
  powerDistribution: "hasZoneSettings",
  scheduler: "hasPPDReports",
  commissioningProdecuders: "hasProcedures",
  commissioningLogs: "hasProceduresLogs",
  unitStats: "hasUnitStatistics",
  userMgmt: "hasUserManagementUsers",
  unitSettings: "hasUnitControl",
  groups: "hasGroupMgmt",
  reports: "hasReports",
  dashboard: "hasDashboard",
  Setback: "hasAwayMode",
  changeover: "hasAutoChangeover",
  automationLogic: "hasAutomationLogic",
  smartRuleReports: "hasSmartAlerts",
  advancedSearch: "hasAdvancedSearch",
  advancedSearchLogs: "hasAdvancedSearchLogs",
};

export default flags;
