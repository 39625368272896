import {
  Button as MuiButton,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { BsGraphUp } from "react-icons/bs";
import { t } from "ttag";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import Switch from "../../cool_widgets/Switch/Switch";
import { Fan1Icon, Fan2Icon, Fan3Icon, Fan4Icon, FanAutoIcon } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../services/timeService";
import { ArrowBack, ArrowDownControl, ArrowUp, CloseIcon } from "../../svgComponents";
import { Checkbox } from "../Checkbox";
import TimePicker from "../TimePicker/TimePicker";
import ErrorBox from "../WarningBox/ErrorBox";
import addEditScheduleStyles from "./addEditSchedule.style";

const isEqual = (arr1: any, arr2: any) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  let equal = true;
  arr1.forEach((day: string, index: number) => {
    if (!equal) {
      return;
    }

    if (!arr2.includes(day)) {
      equal = false;
      return;
    }

    if (!arr1.includes(arr2[index])) {
      equal = false;
      return;
    }

  });
  return equal;
};

const allFanModes = [0, 1, 2, 3, 4, 5];

const fanIcons = (subType: any, mode: any) => {
  switch (mode) {
    case 0: return subType === 2 ? <Typography>{t`LOW`}</Typography> : <Fan1Icon />;
    case 1: return <Fan2Icon />;
    case 2: return <Fan3Icon />;
    case 4: return <Fan4Icon />;
    case 3: return <FanAutoIcon />;
    case 5: return <Typography style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>{t`Very Low`}</Typography>;
  }
};

const AddEditSchedule: React.FC<any> = (props: any) => {

  const user = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);
  const timeFormat = useStoreState((state) => state.users.timeFormat);

  const types = useStoreState((state) => state.types);
  const { weekDays = [], timeFormat: timeFormatTypes, scheduleCategories, curveTempModes } = types;

  const { temperatureScale, timeFormat: myTimeFormat } = user;

  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createScheduleAPI = useStoreActions((actions) => actions.schedules.createObjectSchedules);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [scheduleDisabled, setScheduleStatus] = useState<boolean>();
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [setpoint, setSetpoint] = useState<number>(temperatureScale === 2 ? 75 : 24);
  const [days, setDays] = useState<[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [setpointEnabled, enableSetpoint] = useState<boolean>(false);
  const [enableFanSpeed, setEnableFanSpeed] = useState<boolean>(false);
  const [selectedFanSpeed, setSelectedFanSpeed] = useState<any>(null);
  const [schedule, setSchedule] = useState<any>({ name: "" });
  const [openPicker, setOpenPicker] = useState<string>("");
  const [openConfirmDialog, handleConfirmDialog] = useState<string>("");
  const [disableAdding, setDisableAdding] = useState<boolean>(false);
  const [scheduleName, setScheduleName] = useState<string>("");
  const [tab, setTab] = useState<number>(scheduleCategories.weekly);
  const today = new Date();
  const [dates, setDates] = useState<any>([]);
  const [dirty, setDirty] = useState<boolean>(false);

  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);

  const { addItemSchedule, itemId, isGroup, scheduleData = {}, editMode, hideSchedulesPanel, closeAddEditSchedule, saveScheduleData, isHalfCDegreeEnabled } = props;
  const unit = useStoreState((state) => state.units.allUnits[itemId]);
  const { capabilityFlags = {} } = unit || {};
  const { enableSetpointControl = false, enableFanModeSelection = false, enableCurveTempSelection = false } = capabilityFlags;

  const changeScale = isHalfCDegreeEnabled ? 0.5 : 1;

  const title = editMode ? t`Edit Schedule` : t`Add Schedule`;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

  const useStyles = makeStyles(addEditScheduleStyles);
  const classes = useStyles();

  const hasValue = (value: any) => {
    return !!value || value === 0;
  };

  const CurveTempIcon = ({ i }: { i: number }) => i ?
    <div style={{ width: 24, height: 24, position: "relative" }}>
      <span className={classes.curveTempIcon}>{i}</span>
      <BsGraphUp style={{ height: 24, width: 24 }} />
    </div>
    : <div style={{ display: "grid" }}>
      <BsGraphUp style={{ height: 24, width: 24 }} />
      <span className={classes.curveTempOffIcon} >{t`OFF`}</span>
    </div>;

  const passScheduleInfo = (schedule: any) => {
    const { name, powerOnTime, powerOffTime, setpoint, days = [], dates = [], isDisabled, scheduleCategory, fanMode } = schedule || {};

    setScheduleStatus(isDisabled);
    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");
    setScheduleName(name || "");
    setTab(scheduleCategory);

    setpoint && (isHalfCDegreeEnabled ? setSetpoint(Math.floor(setpoint * 2) / 2) : setSetpoint(Math.round(setpoint)));
    setpoint && enableSetpoint(true);
    !_.isNil(fanMode) && setEnableFanSpeed(true);
    !isNaN(fanMode) && setSelectedFanSpeed(fanMode);
    setDays(days as []);
    const selectedDates = dates.map((date: any) => new Date(date));
    setDates(selectedDates);
  };
  useEffect(() => {
    if (!editMode) {
      return;
    }

    setSchedule(scheduleData);
    passScheduleInfo(scheduleData);
  }, [is12Hours]);

  const checkRequiredFields = () => {
    setErrorMsg("");

    if (!scheduleName) {
      setErrorMsg(t`Schedule Name is Required`);
      return false;
    }
    if (scheduleName.length < 3 || scheduleName.length > 25) {
      setErrorMsg(t`Schedule Name should be from 3 to 25 chars`);
      return false;
    }

    if (tab === scheduleCategories.calendar && dates.length === 0) {
      setErrorMsg(t`Pick one date at least`);
      return false;
    }
    if (tab === scheduleCategories.weekly && days.length === 0) {
      setErrorMsg(t`Pick one day at least`);
      return false;
    }

    if (!powerOnTime && !powerOffTime) {
      setErrorMsg(t`Add at least start or end time`);
      return false;
    }

    if (
      !(
        (powerOnTime) &&
        (powerOffTime)
      )
    ) {
      return true;
    }

    if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
      setErrorMsg(t`End time must be later than start time`);
      return false;
    }

    return true;
  };

  const editSchedule = () => {
    const {
      powerOnTime: defaultPowerOff,
      powerOffTime: defaultPowerOn,
      setpoint: defaultSetpoint
    } = schedule;

    const startHour =
      powerOnTime === ""
        ? defaultPowerOn === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOnTime, is12Hours);

    const endHour =
      powerOffTime === ""
        ? defaultPowerOff === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOffTime, is12Hours);

    let setpointValue: any;
    if (setpointEnabled) {
      setpointValue = setpoint;
    } else {
      if (defaultSetpoint) {
        setpointValue = null;
      } else {
        setpointValue = undefined;
      }
    }

    const data: any = {
      isDisabled: scheduleDisabled,
      name: scheduleName,
      powerOnTime: startHour,
      powerOffTime: endHour,
      setpoint: setpointValue,
      scheduleCategory: tab,
      fanMode: enableFanSpeed ? selectedFanSpeed : null
    };

    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    updateSchedule({
      id: scheduleData.id as string,
      data
    })
      .then((data: any) => {
        saveScheduleData(scheduleData.id, data);
        closeAddEditSchedule();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        setDisableAdding(false);
      });
  };

  const createSchedule = () => {
    const startHour = powerOnTime ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
    const endHour = powerOffTime ? stringTimeToUTCMins(powerOffTime, is12Hours) : undefined;
    const setpointValue = setpointEnabled ? setpoint : undefined;

    const data: any = {
      isDisabled: scheduleDisabled,
      powerOnTime: startHour,
      powerOffTime: endHour,
      setpoint: setpointValue,
      name: scheduleName,
      scheduleCategory: tab,
      fanMode: enableFanSpeed ? selectedFanSpeed : null
    };
    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    createScheduleAPI({
      data,
      objId: itemId as string,
      objectType: isGroup ? "group" : "unit"
    })
      .then((schedule: any) => {
        addItemSchedule(isGroup, itemId, schedule.id);
        saveScheduleData(schedule.id, schedule);
        closeAddEditSchedule();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        setDisableAdding(false);
      });
  };

  const save = () => {

    const allRequiredNotEmpty = checkRequiredFields();

    if (!allRequiredNotEmpty) {
      return;
    }
    setDisableAdding(true);

    if (!scheduleData.id) {
      createSchedule();
      return;
    }

    editSchedule();
  };

  const decreaseSetpoint = () => {
    if (setpoint < 1) {
      return;
    }
    const decreasedSetpoint = setpoint - changeScale;
    setSetpoint(decreasedSetpoint);
    !dirty && setDirty(true);
  };

  const increaseSetpoint = () => {
    const increasedSetpoint = setpoint + changeScale;
    setSetpoint(increasedSetpoint);
    !dirty && setDirty(true);
  };

  const cancel = () => {
    setOpenDialog(false);
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
    !dirty && setDirty(true);
  };

  const handleSetpoint = () => {
    enableSetpoint(!setpointEnabled);
    !dirty && setDirty(true);
  };

  const handleFanSpeed = () => {
    !enableFanSpeed && setSelectedFanSpeed(0)
    setEnableFanSpeed(!enableFanSpeed);
    !dirty && setDirty(true);
  };

  const changeScheduleStatus = () => {
    setScheduleStatus(!scheduleDisabled);
    !dirty && setDirty(true);
  };
  const currentDays: string[] = days;

  const weekDaysArray = Object.keys(weekDays);

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
    !dirty && setDirty(true);
  };

  const onSetTime = (time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
    setOpenPicker("");
    !dirty && setDirty(true);
  };

  const hasTime = (time: any) => {
    return time !== "" && time !== null && time >= 0;
  };

  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates.concat();
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
    !dirty && setDirty(true);
  };

  const checkIfCanGoBack = (goTo: any) => {
    if (editMode && schedule.name === "") {
      goTo === "close" ? hideSchedulesPanel() : closeAddEditSchedule();
      return;
    }
    if ((schedule.powerOnTime || schedule.powerOnTime === 0 ? schedule.powerOnTime : "") !== stringTimeToUTCMins(powerOnTime, is12Hours)) {
      handleConfirmDialog(goTo);
      return;
    }
    if ((schedule.powerOffTime || schedule.powerOffTime === 0 ? schedule.powerOffTime : "") !== stringTimeToUTCMins(powerOffTime, is12Hours)) {
      handleConfirmDialog(goTo);
      return;
    }
    if (schedule.isDisabled !== scheduleDisabled) {
      handleConfirmDialog(goTo);
      return;
    }

    const currentSetpoint = isHalfCDegreeEnabled ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint);
    const oldSetpoint = isHalfCDegreeEnabled ? Math.floor(schedule.setpoint * 2) / 2 : Math.round(schedule.setpoint);

    if ((schedule.setpoint || schedule.setpoint === 0) ? !setpointEnabled ? true : oldSetpoint !== currentSetpoint : setpointEnabled) {
      handleConfirmDialog(goTo);
      return;
    }

    if (schedule.isDisabled !== scheduleDisabled) {
      handleConfirmDialog(goTo);
      return;
    }
    if (!isEqual(schedule.days || [], days)) {
      handleConfirmDialog(goTo);
      return;
    }
    if (schedule.name !== scheduleName) {
      handleConfirmDialog(goTo);
      return;
    }
    if (!isEqual((schedule?.dates || []).map((date: any) => moment(date).unix()), dates.map((date: any) => moment(date).unix()))) {
      handleConfirmDialog(goTo);
      return;
    }

    goTo === "close" ? hideSchedulesPanel() : closeAddEditSchedule();
  };

  const Header = () => <div className={classes.headerStyle}>
    <IconButton onClick={() => checkIfCanGoBack("hide")} className={classes.bigIconBtnStyle}>
      <ArrowBack />
    </IconButton>
    <Typography style={{ color: "#fff", fontSize: 26 }}>{title}</Typography>
    <IconButton onClick={() => checkIfCanGoBack("close")} className={classes.bigIconBtnStyle}>
      <CloseIcon style={{ transform: "scale(1.3)" }} />
    </IconButton>
  </div>;

  return (
    <div className={classes.scheduleInfoContainer}>
      <Header />
      <div className={classes.backgroundContainer}>
        <div className={classes.pageContent}>
          <div className={classes.bodyRow}>
            <TextField
              variant={"outlined"}
              label={"Schedule Name"}
              placeholder={"Schedule Name"}
              value={scheduleName}
              className={classes.inputClass}
              onChange={(e: any) => {
                setScheduleName(e.target.value);
                !dirty && setDirty(true);
              }}
            />
            <div className={classes.controlSec}>
              <Switch
                checked={!scheduleDisabled}
                disableRipple={true}
                onChange={changeScheduleStatus}
                value={true}
              />
            </div>
          </div>
          <Tabs
            value={tab}
            onChange={(event: any, newValue: number) => setTab(newValue)}
            variant="fullWidth"
            aria-label="icon label tabs example"
          >
            <Tab value={scheduleCategories.weekly} label={t`Weekly`} className={classes.tab} />
            <Tab value={scheduleCategories.calendar} label={t`Calendar`} className={classes.tab} />
          </Tabs>
          {tab === scheduleCategories.weekly && <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.tabContainer)}
          >
            <Typography
              className={classes.selectModeStyle}
            >{t`Choose Days`}</Typography>
            <Grid container className={classes.daysContainer} id="days">
              <DaysList
                days={weekDaysArray}
                activeDays={currentDays}
                action={addRemoveDay}
              />
            </Grid>
          </Grid>}
          {tab === scheduleCategories.calendar && <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.tabContainer)}
          >
            <Typography
              className={classes.selectModeStyle}
            >{t`Choose Dates`}</Typography>
            <Grid container className={classes.daysContainer} id="dates">
              <DayPicker
                selectedDays={dates}
                onDayClick={handleDayClick}
                className={classes.calendar}
                disabledDays={
                  {
                    before: editMode ? undefined : today,
                    after: aYearFromToday
                  }}
                fromMonth={editMode ? undefined : today}
                toMonth={aYearFromToday}
              />
            </Grid>
          </Grid>}

          <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.container)}
          >
            <Grid className={classes.startEndTimeContainer}>
              <MuiButton
                disableElevation
                disableRipple
                variant="contained"
                className={clsx(classes.timeContainer, {
                  [classes.timeSelected]: !!powerOnTime
                })}
                onClick={() => setOpenPicker("start")}
              >
                {powerOnTime
                  ? powerOnTime
                  : "Start Time"}
              </MuiButton>
              <MuiButton
                disableElevation
                disableRipple
                variant="contained"
                className={clsx(classes.timeContainer, {
                  [classes.timeSelected]: !!powerOffTime
                })}
                onClick={() => setOpenPicker("end")}
              >
                {powerOffTime
                  ? powerOffTime
                  : "End Time"}
              </MuiButton>
            </Grid>
          </Grid>

          {(unit ? enableSetpointControl : true) &&
            <Grid
              container
              className={clsx(classes.startEndTimeContainer, classes.container)}
            >
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>

                <Typography
                  className={classes.selectModeStyle}
                >{t`Select Setpoint`}</Typography>
                <Checkbox
                  onChange={handleSetpoint}
                  checked={setpointEnabled}
                  className={classes.checkboxStyle}
                />
              </div>

              <div className={clsx(classes.selectModeContainer, !setpointEnabled && classes.collapse)}>

                <Grid className={clsx(classes.setpointContainer, {
                  [classes.hide]: !setpointEnabled,
                  [classes.wideSetpointcontainer]: isHalfCDegreeEnabled
                })}>
                  <MuiButton
                    disableElevation
                    disableRipple
                    disabled={!setpointEnabled}
                    onClick={increaseSetpoint}
                    className={classes.controlArrowButton}
                  >
                    <ArrowUp />
                  </MuiButton>
                  <Typography className={classes.setpointStyle}>
                    {setpointEnabled ? (isHalfCDegreeEnabled ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint)) : temperatureScale === 1 ? "24" : "75"}
                    <span className={classes.tempSymbolStyle}>
                      {temperatureSymbol()}
                    </span>
                  </Typography>
                  <MuiButton
                    disableElevation
                    disableRipple
                    disabled={!setpointEnabled}
                    onClick={decreaseSetpoint}
                    className={classes.controlArrowButton}
                  >
                    <ArrowDownControl />
                  </MuiButton>
                </Grid>
              </div>
            </Grid>}

          {!enableCurveTempSelection && (unit ? enableFanModeSelection : true) &&
            <Grid container className={clsx(classes.startEndTimeContainer, classes.container)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.selectModeStyle}>{t`Select Fan Speed`}</Typography>
                <Checkbox onChange={handleFanSpeed} checked={enableFanSpeed} className={classes.checkboxStyle} />
              </div>

              <div className={clsx(classes.selectModeContainer, !enableFanSpeed && classes.collapse)}>
                <div style={{ display: !enableFanSpeed ? "none" : "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "cneter", flex: 1 }}>
                  {(unit ? unit?.supportedFanModes : allFanModes)?.map((mode: any, i: number) => {
                    const icon: any = fanIcons(unit?.subType || 0, mode);
                    const isSelected = selectedFanSpeed != null && +selectedFanSpeed === +mode;

                    return (
                      <IconButton
                        key={`mode-${i}`}
                        style={{
                          width: "55px",
                          height: "55px",
                          backgroundColor: isSelected ? "#4B1C46" : "#fff",
                          margin: "7px"
                        }}
                        onClick={() => {
                          setSelectedFanSpeed(mode);
                          !dirty && setDirty(true);
                        }}
                      >
                        {icon ? React.cloneElement(icon, isSelected ? { opacity: 0.6 } : { colorblue: "#35A8E0", color1: "#4B1C46", color2: "#AAA2AA", maincolor: "#AAA2AA", opacity: 1 }) : types?.fanModesExtended[mode] || ""}
                      </IconButton>);
                  })}
                </div>
              </div>
            </Grid>
          }

          {unit && enableCurveTempSelection &&
            <Grid container className={clsx(classes.startEndTimeContainer, classes.container)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.selectModeStyle}>{t`Select Curve Temp`}</Typography>
                <Checkbox onChange={handleFanSpeed} checked={enableFanSpeed} className={classes.checkboxStyle} />
              </div>

              <div className={clsx(classes.selectModeContainer, !enableFanSpeed && classes.collapse)}>
                <div style={{ display: !enableFanSpeed ? "none" : "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "cneter", flex: 1 }}>
                  {(Object.keys(unit?.supportedcurveTempModes || curveTempModes))?.map((mode: string, i: number) => {
                    const icon: any = <CurveTempIcon i={+mode} />;
                    // tslint:disable-next-line: triple-equals
                    const isSelected = !isNaN(selectedFanSpeed) && selectedFanSpeed == mode;
                    return (
                      <IconButton
                        key={`mode-${i}`}
                        style={{
                          width: "55px",
                          height: "55px",
                          backgroundColor: isSelected ? "#4B1C46" : "#fff",
                          margin: "7px"
                        }}
                        onClick={() => {
                          setSelectedFanSpeed(+mode);
                          !dirty && setDirty(true);
                        }}
                      >
                        {icon && React.cloneElement(icon, isSelected ? { opacity: 0.6 } : { colorblue: "#35A8E0", color1: "#4B1C46", color2: "#AAA2AA", maincolor: "#AAA2AA", opacity: 1 })}
                      </IconButton>);
                  })}
                </div>
              </div>
            </Grid>
          }

          {errorMsg && (
            <InputLabel
              className={classes.errorLabelStyle}
            >{errorMsg}</InputLabel>
          )}

          <MuiButton disableElevation disableRipple type="submit" variant="contained" onClick={save} disabled={disableAdding || !dirty} className={classes.redBtn}>
            {t`Save`}
          </MuiButton>
          {openConfirmDialog && <ErrorBox
            title={t`Discard Changes`}
            error={"Are you sure you want to discard changes made on this page?"}
            onAccept={() => openConfirmDialog === "close" ? hideSchedulesPanel() : closeAddEditSchedule()}
            onClose={() => handleConfirmDialog("")} />}
          <TimePicker
            show={!!openPicker}
            onSet={onSetTime}
            time={openPicker === "start" ? powerOnTime : powerOffTime}
            onDismiss={() => setOpenPicker("")}
            onClear={onClear}
            is12Hours={is12Hours}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditSchedule;
