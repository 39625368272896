import { Site, Timer, Unit } from "coolremote-sdk";
import { thunk, Thunk } from "easy-peasy";

export interface ITimersModel {
  get: Thunk<ITimersModel, void>;
  deleteTimer: Thunk<ITimersModel, string>;
  createTimer: Thunk<ITimersModel, any>;
  createMultiTimers: Thunk<
    ITimersModel,
    {
      units: string[];
      site: string;
      turnOffInMinutes: number;
      isActive: boolean;
    }
  >;
  getTimers: Thunk<
    ITimersModel,
    { siteId: string | null; unitId?: string | null }
  >;
  getUnitTimers: Thunk<ITimersModel, any>;
}

export const timersModel: ITimersModel = {
  get: thunk(actions => {
    return Timer.getTimers();
  }),
  getTimers: thunk((actions, payload) => {
    return Site.getSiteTimers(payload);
  }),
  deleteTimer: thunk((actions, id) => {
    return Timer.delete(id);
  }),
  createTimer: thunk((actions, payload) => {
    return Timer.createTimer(payload);
  }),
  createMultiTimers: thunk((actions, payload) => {
    return Timer.createMultiTimers(payload);
  }),
  getUnitTimers: thunk((actions, payload) => {
    return Unit.getUnitTimers(payload);
  }),
};
