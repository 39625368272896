import { Grid, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, Formik } from "formik";
import * as queryString from "query-string";
import React, { useState } from "react";
import { Redirect, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { SupportTicket } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ReportProblem from "../ReportProblem/ReportProblem";
import CustomConfirmationDialog from "../../components/CustomConfirmationDialog/CustomConfirmationDialog";
import assetsService from "../../services/assetsService";
import InputField from "./InputField";
import useStyles from "./Login.style";

interface ILoginState {
  username: string;
  password: string;
  error?: string;
}

const LoginSchema = Yup.object({
  password: Yup.string().required(),
  username: Yup.string().required().min(6, "Too Short!").max(50, "Too Long!")
});

const Login: React.FC<any> = (props) => {
  const configFile = useStoreState((s) => s.config);
  const doLogin = useStoreActions((a) => a.doLogin);
  const verify2FA = useStoreActions((a) => a.verify2FA);
  const [open2FADialog, setOpen2FADialog] = useState<boolean>(false);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const location = useLocation();
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [loginState, setLoginState] = useState<ILoginState>({
    username: "",
    password: ""
  });
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState<any>(null);
  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;
  const [error2FA, setError2FA] = useState<any>(null);

  const { supportImg = null, urls } = configFile || {};

  const goToControlApp = () => {
    let env = "";
    if (
      window.location.origin.indexOf("-dev") !== -1 ||
      window.location.origin.indexOf("localhost") !== -1
    ) {
      env = "dev";
    } else if (window.location.origin.indexOf("-stg") !== -1) {
      env = "stg";
    } else {
      env = "prod";
    }

    const controlUrl = urls[env];
    window.location.href = controlUrl;
  };

  const cancel = () => {
    setOpen2FADialog(false);
    setError2FA(null);
  };

  const handleVerify = (code: string) => {
    verify2FA({ code }).catch((response: any) => {
      if (
        response?.hasNoPermission &&
        window.location.href.includes("airconnectpro")
      ) {
        goToControlApp();
      }
      if (response) {
        setError2FA(
          response.message
            ? t`The code you entered is invalid or expired. Please check again you entered the right code, or try logging in again to receive a new code.`
            : null
        );
      }
    });
  };

  const handleLogin = (values: ILoginState) => {
    doLogin({
      username: values.username,
      password: values.password
    })
      .then((res: any) => {
        setOpen2FADialog(res?.is2FA);
      })
      .catch((response: any) => {
        if (
          response?.hasNoPermission &&
          window.location.href.includes("airconnectpro")
        ) {
          goToControlApp();
        }
        if (response) {
          setLoginState({ ...loginState, error: response.message });
        }
      });
  };
  const handleForgotPassword = () => {
    return history.push("/forgot-password");
  };

  if (isLoggedIn) {
    const params = queryString.parse(location.search);

    return (
      <div>
        <p>{t`user already logged in`}</p>
        <Redirect to={(params.redirect as string) || "/dashboard"} />
      </div>
    );
  }

  return (
    <div className={classes.Login}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("logo.png")}
            className={classes.logo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.style.visibility = "hidden";
            }}
          />
        </div>
        {!!configFile ? (
          <div className={classes.interactionSection}>
            <Typography
              className={classes.title}
            >{t`Welcome! Please login`}</Typography>
            {errorBox}

            <Formik
              initialValues={loginState}
              onSubmit={handleLogin}
              enableReinitialize={true}
              validationSchema={LoginSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleSubmit,
                errors,
                touched,
                values,
                ...restFormikProps
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container className={classes.container}>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        tabIndex={1}
                        label={t`Username`}
                        value={values.username}
                        name="username"
                        component={InputField}
                        error={
                          errors.username && touched.username ? true : false
                        }
                        helperText={
                          errors.username && touched.username
                            ? errors.username
                            : ""
                        }
                      />
                      {errors.username ? (
                        <Typography style={{ color: "red" }}> </Typography>
                      ) : null}
                    </Grid>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        type="password"
                        tabIndex={2}
                        label={t`Password`}
                        value={values.password}
                        name="password"
                        component={InputField}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item className={classes.fieldContainer}>
                      {loginState.error ? (
                        <span className={classes.error}>
                          {loginState.error}
                        </span>
                      ) : null}
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        title={t`LOGIN`}
                        className={classes.buttonLogin}
                        uppercase
                        bold
                        red
                        onClick={handleSubmit}
                      >
                        {t`LOGIN`}
                      </Button>
                      <button type="submit" style={{ display: "none" }}>
                        login2
                      </button>
                      {/* *this second button is only to capture the "enter" button.. the first button uses on mouse up due to bug in MUI */}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            <Link
              component="button"
              variant="body2"
              onClick={handleForgotPassword}
              className={classes.forgotPassBtn}
            >
              {t`Forgot Password?`}
            </Link>
          </div>
        ) : null}
      </div>
      {configFile?.enableOpenTicket && <Button
        disableRipple
        variant="contained"
        className={classes.reportProblemButton}
        onClick={() => {
          if (configFile?.supportBtnLink?.length) {
            window?.open(configFile?.supportBtnLink, '_blank')?.focus();
            return;
          }
          setOpenReport(true)
        }}
        title={t`Open Support Ticket`}
        red
      >
        {
          supportImg ?
            <img src={assetsService.getResourcePath(supportImg)} alt="" style={{ width: "3.4rem", height: '3.4rem' }} /> :
            <SupportTicket width={"2rem"} height={"2rem"} />
        }
      </Button>}
      {openReport && (
        <ReportProblem
          openReport
          location={"login"}
          onClose={() => setOpenReport(false)}
        />
      )}

      <CustomConfirmationDialog
        onCancel={cancel}
        onConfrim={handleVerify}
        onClose={cancel}
        title={t`Additional authentication required`}
        text={t`You account requires an additional authentication code that was sent to your email address. Please enter the code below`}
        confrimLabel={t`Submit`}
        cancelLabel={t`Cancel`}
        openDialog={open2FADialog}
        errorText={error2FA}
      />
    </div>
  );
};

export default Login;
