import React from "react";

const SvgSwingCopy = (props) => (
  <svg
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    {...props}
  >
    <g fill="none" fillRule="evenodd" opacity={props.opacity}>
      <path
        d="M16.1895 31.6055L13.6406 39H12.0996L15.3105 30.4688H16.2949L16.1895 31.6055ZM18.3223 39L15.7676 31.6055L15.6562 30.4688H16.6465L19.8691 39H18.3223ZM18.1992 35.8359V37.002H13.5586V35.8359H18.1992Z"
        fill={props.color1 || "#FDFDFE"}
      />
      <path
        d="M24.4277 37.5059V32.6602H25.8457V39H24.5098L24.4277 37.5059ZM24.627 36.1875L25.1016 36.1758C25.1016 36.6016 25.0547 36.9941 24.9609 37.3535C24.8672 37.709 24.7227 38.0195 24.5273 38.2852C24.332 38.5469 24.082 38.752 23.7773 38.9004C23.4727 39.0449 23.1074 39.1172 22.6816 39.1172C22.373 39.1172 22.0898 39.0723 21.832 38.9824C21.5742 38.8926 21.3516 38.7539 21.1641 38.5664C20.9805 38.3789 20.8379 38.1348 20.7363 37.834C20.6348 37.5332 20.584 37.1738 20.584 36.7559V32.6602H21.9961V36.7676C21.9961 36.998 22.0234 37.1914 22.0781 37.3477C22.1328 37.5 22.207 37.623 22.3008 37.7168C22.3945 37.8105 22.5039 37.877 22.6289 37.916C22.7539 37.9551 22.8867 37.9746 23.0273 37.9746C23.4297 37.9746 23.7461 37.8965 23.9766 37.7402C24.2109 37.5801 24.377 37.3652 24.4746 37.0957C24.5762 36.8262 24.627 36.5234 24.627 36.1875Z"
        fill={props.color1 || "#FDFDFE"}
      />
      <path
        d="M30.1992 32.6602V33.6914H26.625V32.6602H30.1992ZM27.6562 31.1074H29.0684V37.248C29.0684 37.4434 29.0957 37.5938 29.1504 37.6992C29.209 37.8008 29.2891 37.8691 29.3906 37.9043C29.4922 37.9395 29.6113 37.957 29.748 37.957C29.8457 37.957 29.9395 37.9512 30.0293 37.9395C30.1191 37.9277 30.1914 37.916 30.2461 37.9043L30.252 38.9824C30.1348 39.0176 29.998 39.0488 29.8418 39.0762C29.6895 39.1035 29.5137 39.1172 29.3145 39.1172C28.9902 39.1172 28.7031 39.0605 28.4531 38.9473C28.2031 38.8301 28.0078 38.6406 27.8672 38.3789C27.7266 38.1172 27.6562 37.7695 27.6562 37.3359V31.1074Z"
        fill={props.color1 || "#FDFDFE"}
      />
      <path
        d="M30.8789 35.9004V35.7656C30.8789 35.3086 30.9453 34.8848 31.0781 34.4941C31.2109 34.0996 31.4023 33.7578 31.6523 33.4688C31.9062 33.1758 32.2148 32.9492 32.5781 32.7891C32.9453 32.625 33.3594 32.543 33.8203 32.543C34.2852 32.543 34.6992 32.625 35.0625 32.7891C35.4297 32.9492 35.7402 33.1758 35.9941 33.4688C36.248 33.7578 36.4414 34.0996 36.5742 34.4941C36.707 34.8848 36.7734 35.3086 36.7734 35.7656V35.9004C36.7734 36.3574 36.707 36.7812 36.5742 37.1719C36.4414 37.5625 36.248 37.9043 35.9941 38.1973C35.7402 38.4863 35.4316 38.7129 35.0684 38.877C34.7051 39.0371 34.293 39.1172 33.832 39.1172C33.3672 39.1172 32.9512 39.0371 32.584 38.877C32.2207 38.7129 31.9121 38.4863 31.6582 38.1973C31.4043 37.9043 31.2109 37.5625 31.0781 37.1719C30.9453 36.7812 30.8789 36.3574 30.8789 35.9004ZM32.291 35.7656V35.9004C32.291 36.1855 32.3203 36.4551 32.3789 36.709C32.4375 36.9629 32.5293 37.1855 32.6543 37.377C32.7793 37.5684 32.9395 37.7188 33.1348 37.8281C33.3301 37.9375 33.5625 37.9922 33.832 37.9922C34.0938 37.9922 34.3203 37.9375 34.5117 37.8281C34.707 37.7188 34.8672 37.5684 34.9922 37.377C35.1172 37.1855 35.209 36.9629 35.2676 36.709C35.3301 36.4551 35.3613 36.1855 35.3613 35.9004V35.7656C35.3613 35.4844 35.3301 35.2188 35.2676 34.9688C35.209 34.7148 35.1152 34.4902 34.9863 34.2949C34.8613 34.0996 34.7012 33.9473 34.5059 33.8379C34.3145 33.7246 34.0859 33.668 33.8203 33.668C33.5547 33.668 33.3242 33.7246 33.1289 33.8379C32.9375 33.9473 32.7793 34.0996 32.6543 34.2949C32.5293 34.4902 32.4375 34.7148 32.3789 34.9688C32.3203 35.2188 32.291 35.4844 32.291 35.7656Z"
        fill={props.color1 || "#FDFDFE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4705 10.2775C24.4705 9.79723 24.0621 9.45831 23.5363 9.54219C21.8423 9.9374 20.4999 11.4355 20.4999 13.2162C20.4999 14.997 21.7558 16.4952 23.5363 16.8903C24.0324 16.9753 24.4705 16.6364 24.4705 16.155V10.2774V10.2775Z"
        stroke={props.color1 || "#FFF"}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5293 25.7227C25.5293 26.2028 25.9377 26.5417 26.4635 26.4578C28.1574 26.0627 29.4999 24.5648 29.4999 22.7844C29.4999 20.9763 28.244 19.5059 26.4635 19.1109C25.9673 19.0248 25.5293 19.3361 25.5293 19.8449V25.7228V25.7227Z"
        stroke={props.color1 || "#FFF"}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7225 17.4706C33.2027 17.4706 33.5416 17.0622 33.4577 16.5364C33.0625 14.8425 31.5644 13.5 29.7837 13.5C28.003 13.5 26.5047 14.7559 26.1096 16.5364C26.0246 17.0326 26.3636 17.4706 26.8449 17.4706H32.7225H32.7225Z"
        stroke={props.color1 || "#FFF"}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2773 18.5294C16.7971 18.5294 16.4582 18.9378 16.5421 19.4636C16.9373 21.1576 18.4354 22.5 20.2161 22.5C21.9968 22.5 23.4951 21.2441 23.8902 19.4636C23.9752 18.9674 23.6362 18.5294 23.1549 18.5294H17.2773Z"
        stroke={props.color1 || "#FFF"}
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default SvgSwingCopy;
