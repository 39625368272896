
const hasValue = (value: any) => {
    return !!value || value === 0;
};

export const getSensorValue = (
    isCelsius: any,
    types: any,
    sensor: any
) => {

    const {
        type,
        readingValue = 0,
        userData = {},
        isWritable
    } = sensor || {};

    const {
        rangeMax,
        rangeMin
    } = userData;

    const { sensorTypes } = types;
    const sensorType = sensorTypes[type] || {};
    const { measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin, enableMinMaxSelection } = sensorType;
    const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;

    const hasUserValues = hasValue(rangeMax) && hasValue(rangeMin);
    const doConvert = enableMinMaxSelection && hasUserValues;

    if (type === 130 && isWritable) {
        return Math.round((doConvert ? ((((readingValue - valueMin) * (+rangeMax - +rangeMin)) / (valueMax - valueMin)) + +rangeMin) : readingValue) * 10) / 10;
    }

    if (type === 7) {
        let convertedValue = Math.round((enableMinMaxSelection ?
            hasValue(rangeMax) && hasValue(rangeMin) ? (((readingValue - valueMin) * (+rangeMax - +rangeMin)) / (valueMax - valueMin)) + +rangeMin
                : readingValue : readingValue) * 10) / 10;

        return convertedValue;
    }

    let item = null;
    switch (activeMeasurementUnit) {
        case 1:
            item = Math.round((isCelsius ? readingValue : ((readingValue * 9 / 5) + 32)) * 10) / 10;
            break;
        case 4:
            item = type === 129 ? +readingValue === 0 ? `OFF` : `ON` : +readingValue === 1 ? `OPEN` : `CLOSE`;
            break;
        case "":
            let convertedValue = Math.round((enableMinMaxSelection ?
                hasValue(rangeMax) && hasValue(rangeMin) ? (((readingValue - valueMin) * (+rangeMax - +rangeMin)) / (valueMax - valueMin)) + +rangeMin
                    : readingValue : readingValue) * 10) / 10;
            item = convertedValue;
            break;

        default:
            item = Math.round(readingValue * 10) / 10;
            break;
    }

    return item;

};
