import {
  Dialog,
  IconButton,
  InputLabel,
  TextField,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Search } from "../../icons/";
import useStyles from "./AddEditGroupsPopup.style";
import ClickableRow from "./ClickableRow";
import Row from "./Row";

const AddEditGroup = (props: any) => {
  const classes = useStyles();
  const { site, group, sites, units, sensors, unitTypes, onClose, createGroup, updateGroup } = props;

  const [siteSensors, setSiteSensors] = useState<any>([]);
  const [siteUnits, setSiteUnits] = useState<any>([]);
  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [selectedSensors, setSelectedSensors] = useState<any>({});
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [errors, setErrors] = useState<any>({ name: false, items: false });
  const siteId = site?.id;
  const [filteredUnits, setFilteredUnits] = useState<any>([]);
  const [filteredSensors, setFilteredSensors] = useState<any>([]);

  useEffect(() => {
    if (group) {
      const { name = "", units: groupUnits = [], sensors: groupSensors = [] } = group;
      const unitsObject: any = {};
      const sensorsObject: any = {};

      groupUnits.forEach((unitId: string) => {
        unitsObject[unitId] = true;
      });
      groupSensors.forEach((sensorId: string) => {
        sensorsObject[sensorId] = true;
      });

      setGroupName(name);
      setSelectedUnits(unitsObject);
      setSelectedSensors(sensorsObject);
    }
    const sensorsMappedToSites: any = {};
    const unitsMappedToSites: any = {};

    const siteSensors: any = [];
    const siteUnits: any = [];
    Object.values(sensors).forEach((sensor: any) => {
      const { site = "", id } = sensor;
      if (siteId === site) {
        siteSensors.push(sensor);
      }
    });
    Object.values(units).forEach((unit: any) => {
      const { site = "", id, type, subType } = unit;
      if (type !== unitTypes?.indoor) {
        return;
      }
      if (subType === 1) {
        return;
      }
      if (siteId === site) {
        siteUnits.push(unit);
      }
    });

    setSiteSensors(siteSensors);
    setSiteUnits(siteUnits);
    setFilteredUnits(siteUnits);
    setFilteredSensors(siteSensors);
  }, []);

  const handleSave = () => {
    const units = Object.keys(selectedUnits);
    const sensors = Object.keys(selectedSensors);
    const selectedItemsLength = units.length + sensors.length;

    if (!groupName && !selectedItemsLength) {
      setErrors({ name: true, items: true });
      return;
    }
    if (!groupName) {
      setErrors({ name: true, items: false });
      return;
    }
    if (!selectedItemsLength) {
      setErrors({ name: false, items: true });
      return;
    }

    setErrors({ name: false, items: false });
    const data: any = { name: groupName, units, sensors };
    if (group) {
      updateGroup(group.id, data);
      return;
    }
    data.site = siteId;
    createGroup(data);
  };

  const onUnitSelect = (event: any, id: string) => {
    const { target: { checked } } = event;
    if (checked) {
      selectedUnits[id] = true;
    } else {
      delete selectedUnits[id];
    }

    setSelectedUnits({ ...selectedUnits });
  };

  const onSensorSelect = (event: any, id: string) => {
    const { target: { checked } } = event;
    if (checked) {
      selectedSensors[id] = true;
    } else {
      delete selectedSensors[id];
    }

    setSelectedSensors({ ...selectedSensors });
  };

  const delayedFiltering = useCallback(_.debounce((searchText: string) => {
    if (!searchText) {
      setFilteredUnits(siteUnits);
      setFilteredSensors(siteSensors);
    } else {
      const searchTextLowerCase = searchText.toLowerCase();
      setFilteredUnits(siteUnits.filter((unit: any) => unit?.name?.toLowerCase()?.indexOf(searchTextLowerCase) > -1));
      setFilteredSensors(siteSensors.filter((sensor: any) => sensor?.name?.toLowerCase()?.indexOf(searchTextLowerCase) > -1));
    }
  }, 500), [siteUnits, siteSensors]);

  const selectedUnitsLength = Object.keys(selectedUnits).length;
  const selectedSensorsLength = Object.keys(selectedSensors).length;
  const siteUnitsLength = siteUnits.length;
  const siteSensorsLength = siteSensors.length;

  return (
    <Dialog classes={{ paperWidthLg: classes.dialogStyle }} aria-labelledby="add-edit-groups-popup" open={true} maxWidth="lg">
      <div className={classes.dialogTitle}>
        <Typography className={classes.dialogText}>{group ? t`Edit Group` : t`Add New Group`}</Typography>
        <IconButton onClick={onClose} className={classes.clearButton}>
          <Clear style={{ color: "#7f7692", fontSize: 25 }} />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <InputLabel className={classes.labelStyle}>
          {t`Group Name`}
          <TextField
            variant="outlined"
            placeholder="Create name for the group"
            margin="none"
            value={groupName}
            onChange={(event: any) => setGroupName(event.target.value)}
            InputProps={{ classes: { root: classes.outlinedInputRoot, notchedOutline: classes.notchedOutline } }}
            error={errors.name && !groupName}
          />
        </InputLabel>
        <div className={classes.selectUnitContainer}>
          <Typography className={classes.selectUnitText}>{t`Select Units`}</Typography>
          {errors.items && <Typography className={classes.errorText}>{t`Please select one unit or sensor at least`}</Typography>}
        </div>

        <TextField
          onChange={(e: any) => delayedFiltering(e.target.value)}
          InputProps={{
            startAdornment: <Search />
          }}
          placeholder={t`Search Units`}
          variant="outlined"
          className={classes.searchField}
        />
        <div className={classes.itemsContainer}>
          <ClickableRow
            key={siteId}
            name={site?.name}
            selected={true}
            unitsNumber={selectedUnitsLength + selectedSensorsLength}
            noIcon
          />
          <div className={classes.unitsHolder}>
            {(siteUnitsLength + siteSensorsLength === 0) && <Row name={t`this site has no units`} hideLine />}
            {filteredUnits.map((unit: any, index: number) => {
              const { id, name } = unit;

              return (
                <Row
                  key={`${siteId}-${id}`}
                  name={name}
                  checked={!!selectedUnits[id]}
                  onCheck={(event: any) => onUnitSelect(event, id)}
                  hideLine={(siteSensorsLength === 0 && siteUnitsLength - 1 === index)}
                />);
            })}
            {filteredSensors.map((sensor: any, index: number) => {
              const { id, name } = sensor;

              return (
                <Row
                  key={`${siteId}-${id}`}
                  name={name}
                  checked={!!selectedSensors[id]}
                  onCheck={(event: any) => onSensorSelect(event, id)}
                  hideLine={siteSensorsLength - 1 === index}
                />);
            })}
          </div>
        </div>
      </div>
      <div className={classes.actionsHolder}>
        <Button white width={150} marginRight onClick={onClose}>{t`Cancel`}</Button>
        <Button width={150} onClick={handleSave}>{t`Save`}</Button>
      </div>
    </Dialog>
  );
};

export default AddEditGroup;
