import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./Sites";

export interface ICustomer {
  id: string;
  name?: string;
  description?: string;
  enableTriggers?: boolean;
  sites: string[];
  triggers: string[];
  role: any;
  enableInstallerRole?: boolean;
  useWebsocket?: boolean;
  canAddGroup?: boolean;
}

export interface ICustomerMap {
  [key: string]: ICustomer;
}

export interface ICustomersModel {
  allCustomers: ICustomerMap;
  initialize: Action<ICustomersModel, any>;
  onInitialized: ActionOn<ICustomersModel, IRootStoreModel>;
  getCustomerName: Computed<
    ICustomersModel,
    any
  >;
  getCustomer: Computed<
    ICustomersModel,
    (id: string | undefined | null) => any
  >;
  getCustomerSites: Computed<
    ICustomersModel,
    (id: string) => ISite[],
    IRootStoreModel
  >;
  getCustomerTraps: Thunk<IRootStoreModel, { customerId: string }>;
  getCustomerUsers: Thunk<IRootStoreModel, string>;
  getCustomerByName: Computed<
    ICustomersModel,
    (name: string) => ICustomer | null
  >;
  getSites: Thunk<IRootStoreModel, { customerId: string; brand?: number }>;
  getCustomers: Thunk<ICustomersModel, void, any, IRootStoreModel>;
}

export const customersModel: ICustomersModel = {
  allCustomers: {},
  initialize: action((state, payload) => {
    state.allCustomers = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  getCustomerByName: computed(state => name => {
    const foundCustomers = _.filter(
      _.values(state.allCustomers),
      (customer: ICustomer) => {
        return customer.name === name;
      }
    );

    if (foundCustomers.length > 0) {
      if (foundCustomers.length > 1) {
      }

      return foundCustomers[0];
    }

    return null;
  }),
  getCustomerName: computed(
    [
      state => state.allCustomers,
      (state, storeState: any) => storeState?.users?.userPreferences
    ],
    (allCustomers, userPref) => (id: any) => {

      if (!id || !allCustomers[id] || !allCustomers[id].name) {
        return "-";
      }

      return (userPref?.nicknames && userPref.nicknames[id]) || allCustomers[id]?.name || "";
    }),
  getCustomer: computed(state => id => {
    if (!id || !state.allCustomers[id]) {
      return undefined;
    }

    return state.allCustomers[id];
  }),
  getCustomerSites: computed(
    [(state, storeState) => storeState.sites.allSites],
    allSites => id => {
      return Object.values(allSites).filter(
        (site: any) => site.customer === id
      );
    }
  ),
  getCustomerTraps: thunk(async (actions, payload) => {
    const data: any = await coolremoteSDK.Customer.getTraps(payload.customerId);
    return data;
  }),
  getCustomerUsers: thunk(async (actions, payload) => {
    const data: any = await coolremoteSDK.Customer.getCustomerUsers(payload);
    return data;
  }),
  getSites: thunk(async (actions, payload) => {
    const { customerId, brand = -1 } = payload;
    const data: any = await coolremoteSDK.Customer.getCustomerSites(
      customerId,
      brand
    );
    return data;
  }),
  getCustomers: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    const customers: any = await sdkCustomer.getCustomers();
    actions.initialize(customers);

    return customers;
  }),
};
