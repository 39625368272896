import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  clickableRow: {
    flex: 1,
    maxWidth: 492,
    height: 50,
    marginBottom: 10,
    padding: "0px 10px 0px 15px",
    borderRadius: 6,
    border: "solid 1px #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"

  },
  selected: {
    backgroundColor: "#edebed"

  },
  leftSection: {
    display: "flex",
    alignItems: "center"
    // width: 138 // 134

  },
  unitsNumber: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    fontWeight: "normal",
    // lineHeight: 18,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#545964",
    width: 42,
    marginRight: 32,
    minWidth: 42
  },
  groupName: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "normal",
    // lineHeight: 18,
    letterSpacing: "normal",
    // textAlign: "right",
    color: "#545964",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(100% - 140px)"
  },
  iconBtn: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  arrowIcon: {
    width: 12,
    height: 7,
    "& path": {
      fill: "#7f7692"
    }
  },
  arrowIconBtn: {
    width: 22,
    height: 22,
    padding: 0,
    borderRadius: 6
  },
  addRightMargin: {
    marginRight: 71
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  removeMargin: {
    marginBottom: 0
  }
}));
export default useStyles;
