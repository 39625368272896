import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  actionIcon: {
    "&hover": {
      backgroundColor: "white"
    }
  },
  title: {
    backgroundColor: "rgb(236,235,239)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    padding: "4px 24px"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialog: {
    // border: '1px solid  rgba(0, 0, 0, 0.54)',
    // borderRadius: '3px',
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto"
  },
  agreeBtn: {
    backgroundColor: "rgba(41, 19, 46, 0.95)",
    color: "white",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    border: "1px solid rgba(41, 19, 46, 0.95)",
    borderRadius: "3px",
    marginRight: ".5rem",
    "&:hover": {
      backgroundColor: "rgba(41, 19, 46, 0.95)",
      color: "white"
    }
  },
  closeBtn: {
    color: "rgba(41, 19, 46, 0.95)",
    backgroundColor: "white",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginLeft: ".5rem",
    "&:hover": {
      color: "rgba(41, 19, 46, 0.95)",
      backgroundColor: "white"
    }
  },
  dialogActions: {
    display: "flex",
    // justifyContent: 'space-between',
    justifyContent: "center"
  },
  btn: {
    padding: "1rem"
  },
  "& select + &label": {
    "padding-left": ".5rem"
  },
  select: {
    "& select + &label": {
      "padding-left": ".5rem"
    }
  },

  actionBtnContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  imgContainer: {
    paddingRight: "10%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
    maxHeight: "375px"
  },
  deviceImg: {
    width: "100%",
    objectFit: "cover"
  },
  modalActions: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end"
  },
  inputFieldWithLabel: {
    "& div": {
      marginTop: "0px"
    },
    "&>div": {
      marginBottom: "1rem"
    }
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  arrowIcon: {
    fontSize: "30px"
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  imgStyle: {
    maxWidth: "100%"
  },
  requiredError: {
    color: "#FF0000",
    marginTop: "-9px"
  }
}));
export default useStyles;
