import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Delete from "../../components/Delete/Delete";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import CoolButton from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import SvgArrow from "../../icons/Arrow";
import { CheckboxChecked, Close, Search } from "../../icons/index";
import SvgUser from "../../icons/User1";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IScheduledReport } from "../../models/ScheduledReports";
import useStyle from "./OneTenant.style";

const TenantList: React.FC<{ scheduledReportType: string }> = (props: any) => {
  const scheduledReportType = props.scheduledReportType
  const isPPDReport = scheduledReportType === "ppd"

  const classes = useStyle();
  const getZonesBySiteId = useStoreActions((action) => action.zones.getZonesBySiteId);
  const getSystemsBySiteId = useStoreState((s) => s.sites.getSiteSystems);

  const scheduledReports = useStoreState((s) => s.scheduledReports.allReports);
  const createReport = useStoreActions((action) => action.scheduledReports.createReport);
  const updateReport = useStoreActions((action) => action.scheduledReports.updateReport);
  const getReport = useStoreActions((action) => action.scheduledReports.getReport);
  const removeReport = useStoreActions((action) => action.scheduledReports.removeReport);
  const allSites = useStoreState((s) => s.sites.allSites);

  const selections = useStoreState((s) => s.selections.selections);
  const types = useStoreState((s) => s.types);
  const [siteZones, setSiteZones] = useState<any>([]);
  const [siteSystems, setSiteSystems] = useState<any>([]);
  const [chosenSite, setChosenSite] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<any>({});
  const [selectedReportUsers, setSelectedReportUsers] = useState<any[]>([]);
  const [showOneReport, setShowOneReport] = useState(false);
  const [oneReportName, setOneReportName] = useState("");
  const [format, setFormat] = useState<string>("csv");
  const [granularity, setGranularity] = useState<string>("hour");
  const [reportFormat, setReportFormat] = useState<number>(1);
  const [searchVal, setSearchVal] = useState<string>("");
  const [timeUnit, setTimeUnit] = useState<string>("");
  const [timeUnitSubSelect, setTimeUnitSubSelect] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [dataReportTypes, setDataReportTypes] = useState<any>({});
  const dataReportTypesNames: any = {
    modeHours: t`Overall Cooling & Heating hours`,
    powerConsumption: t`Power Consumption*`,
    eventCountBySystem: t`Alerts Statistics by System`,
    systemDiversity: t`System Diversity Indication`,
    unitStandbyTime: t`Unit Standby Time`,
    setpointMaintenance: t`Setpoint Maintenance`
  }

  const [showDeleteReport, setShowDeleteReport] = useState<boolean>(false);
  const [reportError, setReportError] = useState<string>("");
  const [reportUserErr, setReportUserErr] = useState<string>("");
  const [isMissingName, setIsMissingName] = useState<boolean>(false);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const [zonesToSave, setZonesToSave] = useState<any>({});
  const [systemsToSave, setSystemsToSave] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [specificDay, setSpecificDay] = useState<number>(2);
  const [distributeExcessPower, setDistributeExcessPower] = useState<boolean>(true);
  const [generateMultipleReports, setGenerateMultipleReports] = useState<boolean>(false);
  const { siteId } = selections;
  const { canCreatePowerReportSchedules, canDeletePowerReportSchedules, canUpdatePowerReportSchedules } = allSites?.[siteId || ""]?.permissions || {};

  const ppdReportSchema = Yup.object().shape({
    name: Yup.string().required(),
    format: Yup.string().required(),
    timeUnit: Yup.string().required(),
    subTimeUnit: Yup.string().when('timeUnit', {
      is: 'day',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required()
    }),
    granularity: Yup.string().required(),
  });

  const dataReportSchema = Yup.object().shape({
    name: Yup.string().required(),
    timeUnit: Yup.string().required(),
    subTimeUnit: Yup.string().when('timeUnit', {
      is: 'day',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required()
    }),
    dataReportTypes: Yup.array().min(0)
  });

  let userSchema = Yup.object().shape({
    userName: Yup.string().required(),
    userEmail: Yup.string().email().required()
  });

  const fetchReports = async () => {
    if (!chosenSite) {
      return;
    }
    const reports = await getReport({ id: chosenSite, type: types.scheduledReportTypes[scheduledReportType] });
    setSelectedReports(reports);
  };

  useEffect(() => {
    const dataReportTypes: any = {}
    Object.entries(types.dataReportTypes).forEach(([key, id]) => {
      dataReportTypes[key] = {
        // name: key
        //   .match(/([A-Z]?[^A-Z]*)/g)
        //   ?.slice(0, -1)
        //   .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        //   .join(" "),
        checked: selectedReport?.dataReportTypes?.includes(id) || false,
        id
      }
    })
    setDataReportTypes(dataReportTypes);
  }, [selectedReport]);

  // get reports on site selection
  useEffect(() => {
    fetchReports();
  }, [chosenSite, scheduledReportType]);

  //refresh the list on add report
  useEffect(() => {
    setSelectedReports(scheduledReports);
  }, [scheduledReports]);

  useEffect(() => {
    setReportFormat(types?.ppdReportFormatTypes["CoolAutomation"]);
    (async () => {
      if (selections.siteId) {
        setChosenSite(selections.siteId);
        const zones = await getZonesBySiteId(selections.siteId);
        setSiteZones(Object.values(zones));
        const systems = await getSystemsBySiteId(selections.siteId);
        setSiteSystems(Object.values(systems)?.filter((sys: any) => !!sys));
      }
    })();
  }, [selections.siteId]);

  // Load tenant list when list is ready
  useEffect(() => {
    (async () => {
      if (chosenSite) {
        const zones = await getZonesBySiteId(chosenSite);
        setSiteZones(Object.values(zones));
        const systems = await getSystemsBySiteId(chosenSite);
        setSiteSystems(Object.values(systems)?.filter((sys: any) => !!sys));
      }

    })();
  }, [selections.siteId]);

  if (!isInitialized) { return <Loading />; }

  const onFilterSelect = async (id: string | null, queryType: any) => {

    if (!id || queryType === "customer") {
      setChosenSite("");
      return;
    }

    if (queryType === "site") {
      setChosenSite(id);
    }

  };

  const onSave = async () => {
    let systemsSelected: any = [];
    _.forEach(systemsToSave, (value, key) => {
      if (value) {
        systemsSelected.push(key);
      }
    });
    let zonesSelected: any = [];
    _.forEach(zonesToSave, (value, key) => {
      if (value) {
        zonesSelected.push(key);
      }
    });

    const ppdData: any = {
      format,
      granularity,
      zones: zonesSelected,
      systems: systemsSelected,
      distributeExcessPower,
      reportFormatType: reportFormat,
      reportPerTenant: generateMultipleReports
    }

    const dataReportData: any = {
      dataReportTypes: Object.values(dataReportTypes).reduce((result: any, item: any) => {
        if (item.checked) {
          result.push(item.id);
        }
        return result;
      }, [])
    }

    const data: any = {
      name: oneReportName,
      users: selectedReportUsers,
      timeUnit,
      subTimeUnit: timeUnitSubSelect,
      ...(isPPDReport ? ppdData : dataReportData),
    };

    if (timeUnitSubSelect === "SpecificDay") {
      data.specificDay = specificDay;
    }

    const isValid = await (isPPDReport ? ppdReportSchema : dataReportSchema).isValid(data);
    if (!isValid) {
      try {
        await (isPPDReport ? ppdReportSchema : dataReportSchema).validate(data);
      } catch (e) {
        const error: any = e;
        setReportError(error.path);
      }
      return;
    }

    if (isPPDReport) {
      if (!Object.keys(zonesToSave).length && !Object.keys(systemsToSave).length) {
        setError("Please select at least one system or tenant");
        return;
      }
    }
    else if (!dataReportData.dataReportTypes?.length) {
      setError("Please select at least one data report");
      return;
    }

    if (!selectedReport) {
      createReport({ ...data, site: chosenSite, type: types.scheduledReportTypes[scheduledReportType] }).then((res: any) => {
        setSelectedReports({ ...selectedReports, [res.id]: { ...data, ...res } });
      });
    } else {
      updateReport({ data, reportId: selectedReport.id }).then(() => {
        setSelectedReports({ ...selectedReports, [selectedReport.id]: { ...selectedReport, ...data } });
      });
    }

    onCleanUp();
    setShowOneReport(false);

  };

  const ordinal_suffix_of = (i: number) => {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const onDelete = (reportId: any) => {
    // need to use 'then' as the <Delete> creates memory leak using async\await
    removeReport({ reportId: reportId.id }).then(() => {
      setShowDeleteReport(false);
      onCleanUp();
    });
  };

  const onCancle = () => {
    onCleanUp();
    setReportUserErr("");
    setReportError("");
    setGenerateMultipleReports(false);
  };

  const onSelectReport = (report: IScheduledReport) => {
    setOneReportName(report.name);
    setFormat(report.format);
    setGranularity(report.granularity);
    setReportFormat(report?.reportFormatType || 1);
    setTimeUnit(report.timeUnit);
    setTimeUnitSubSelect(report.subTimeUnit);
    setSelectedReportUsers(report.users);
    setDistributeExcessPower(!!report.distributeExcessPower);
    setGenerateMultipleReports(report.reportPerTenant || false);
    if (report.subTimeUnit === "SpecificDay") {
      setSpecificDay(report.specificDay || 2);
    }
    if (report.zones) {
      let zones: any = {};
      for (let zone of report.zones) {
        zones[zone] = true;
      }
      setZonesToSave(zones);
    }
    if (report.systems) {
      let systems: any = {};
      for (let system of report.systems) {
        systems[system] = true;
      }
      setSystemsToSave(systems);
    }

    setShowDeleteReport(true);
    setShowOneReport(true);
    setSelectedReport(report);

  };

  const onCleanUp = () => {
    setShowDeleteReport(false);
    setSelectedReport(null);
    setOneReportName("");
    setFormat("csv");
    setGranularity("hour");
    setReportFormat(1);
    setTimeUnit("");
    setTimeUnitSubSelect("");
    setSelectedReportUsers([]);
    setZonesToSave({});
    setSystemsToSave({});
    setError("");
    setReportError("");
    setShowOneReport(false);
    setSpecificDay(2);
    setDistributeExcessPower(true);
  };

  const onSaveUser = async () => {
    const newUser = { userName, userEmail };
    const isValid = await userSchema.isValid(newUser);

    if (!isValid) {
      try {
        await userSchema.validate(newUser);

      } catch (e) {
        const error: any = e;
        setReportUserErr(error.path);
      }

      return;
    }

    setSelectedReportUsers([...selectedReportUsers, newUser]);
    setShowAddUser(false);
    setUserEmail("");
    setUserName("");
  };

  const deleteUser = (user: any) => {
    const email = user.id;
    const userList = _.clone(selectedReportUsers);
    _.remove(userList, (user) => user.userEmail === email);
    setSelectedReportUsers(userList);
  };
  const handleCheckZone = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (id === "all") {
      if (event.target.checked) {
        const ids = siteZones.reduce((result: any, item: any) => {
          result[item.id] = true;
          return result;
        }, {});
        setZonesToSave(ids);
        return;
      }
      setZonesToSave({});
      return;
    }
    const newZonesToSave: any = { ...zonesToSave, [id]: !!event.target.checked };
    setZonesToSave(newZonesToSave);
    if (newZonesToSave[id] === false) {
      delete newZonesToSave[id];
      setZonesToSave(newZonesToSave);
    }
  };
  const handleCheckSystem = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {

    if (id === "all") {
      if (event.target.checked) {
        const ids = siteSystems.reduce((result: any, item: any) => {
          if (!item) {
            return result;
          }
          result[item.id] = true;
          return result;
        }, {});
        setSystemsToSave(ids);
        return;
      }
      setSystemsToSave({});
      return;
    }

    const newSystemsToSave: any = { ...systemsToSave, [id]: !!event.target.checked };
    setSystemsToSave(newSystemsToSave);
    if (newSystemsToSave[id] === false) {
      delete newSystemsToSave[id];
      setSystemsToSave(newSystemsToSave);
    }
  };

  // List of all users that will get the report
  const recipientsList = () => {

    const usersByFilter = !searchVal
      ? selectedReportUsers
      : _.filter(selectedReportUsers, (oneUser) => {
        return _.startsWith(oneUser.userName.toUpperCase(), searchVal.toUpperCase());
        // return oneUser.userName.includes(searchVal);
      });

    return (
      <Grid container={true} className={classes.scheduledUserList}>
        <TableContainer className={classes.schedulerTableContainer}>
          <Paper elevation={0} className={classes.paperTableContainer}>
            <Table stickyHeader={true} className={classes.schedulerTable} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    classes={{ root: classes.schedulerTableHeadRow }}
                    align="left"
                  >{t`RECIPIENTS`}</TableCell>

                  <TableCell
                    classes={{ root: classes.schedulerTableHeadRow }}
                    align="right"
                  >{t`DEL`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(usersByFilter, (oneUser, i) => {
                  return (
                    <Tooltip
                      title={oneUser.userEmail}
                      key={i}
                      enterDelay={500}>
                      <TableRow role="checkbox" tabIndex={-1} key={i} className={classes.rowStyle}>
                        <TableCell align={"left"} size="small" className={classes.cellStyle}>
                          <div className={classes.userHolder}>
                            <SvgUser className={classes.userIcon} />
                            <Typography variant="body2" className={classes.userNameCell}>
                              {oneUser.userName}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align={"left"} size="small">
                          <Typography variant="body2" className={classes.delCell}>
                            <Delete
                              disabled={!canUpdatePowerReportSchedules}
                              type={t`Reports Users`}
                              object={{ name: oneUser.userName, id: oneUser.userEmail }}
                              detach={deleteUser}
                              buttonClass={classes.deleteIcon}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </TableContainer>
      </Grid>
    );
  };

  const addUserToReportModel = () => {
    return (
      <Dialog
        open={showOneReport}
        onClose={onCancle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        classes={{ paper: classes.dialog }}
      >
        <DialogContent>
          <Grid container={true} >
            <Grid item={true} xs={12} className={classes.newUserStyle}>
              <TextField id="outlined-basic"
                label="User Name"
                variant="outlined"
                className={classes.reportNameStyle}
                value={userName}
                error={reportUserErr === "userName"}
                helperText={reportUserErr === "userName" ? "missing  user name" : ""}
                onChange={(e: any) => {

                  setUserName(e.target.value);
                }} />
            </Grid>

            <Grid item={true} xs={12} className={classes.newUserStyle}>
              <TextField id="outlined-basic"
                label="User Email"
                variant="outlined"
                className={classes.reportNameStyle}
                value={userEmail}
                error={reportUserErr === "userEmail"}
                helperText={reportUserErr === "userEmail" ? "type in a valid Email" : ""}
                onChange={(e: any) => {
                  setUserEmail(e.target.value);
                }} />

            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActionsStyle}>
          <CoolButton
            width={150}
            white
            marginRight
            onClick={() => {
              setUserEmail("");
              setUserName("");
              setShowAddUser(false);
            }}>
            {t`Cancel`}
          </CoolButton>
          <CoolButton
            width={150}
            onClick={() => {
              onSaveUser();
            }}>
            {t`Save`}
          </CoolButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={[t`Power Distribution`]}
          onSiteSelect={(id) => onFilterSelect(id, "site")}
          onCustomerSelect={(id) => onFilterSelect(id, "customer")}
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection={true}
          hideUnitSelection={true}
          screenTitle="scheduler"
        />
        {!!chosenSite &&
          <div className={classes.buttonContainer}>
            <CoolButton
              disabled={!canCreatePowerReportSchedules}
              onClick={() => {
                setShowOneReport(true);
                if (isMissingName) {
                  setIsMissingName((false));
                }
              }}>{t`Add New`}</CoolButton>
          </div>}
        {!chosenSite ? (
          <FilterRequire type={t`site`} />
        ) : (
          <Grid container={true} className={classes.usersContainer}>
            <TableContainer className={classes.tableContainer}>
              <Paper elevation={0} className={classes.paperTableContainer}>
                <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`REPORT NAME`}</TableCell>

                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`RECIPIENTS`}</TableCell>

                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`FREQUENCY`}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!_.isEmpty(selectedReports) &&
                      _.orderBy(selectedReports, [(item: any) => item?.name?.toUpperCase()],
                        ["asc"]).map((report: any) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={`reports-${report?.id}`}
                              onDoubleClick={() => {
                                onSelectReport(report);
                              }}>
                              <TableCell align={"left"} size="small">
                                <Typography variant="body2" className={classes.userNameCell}>
                                  {report.name}
                                </Typography>
                              </TableCell>

                              <Tooltip
                                title={report.users.length ? _.map(report.users, (user) => user.userName).join(", ") : "No Recipients"}
                                key={report.id}
                                enterDelay={500}>
                                <TableCell align={"left"} size="small">
                                  <Typography variant="body2" className={classes.userNameCell}>
                                    {report.users.length}
                                  </Typography>
                                </TableCell>
                              </Tooltip>

                              <TableCell align={"left"} size="small">
                                <Typography variant="body2" className={classes.userNameCell}>
                                  {report.subTimeUnit} every {report.timeUnit}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );

                        })}
                  </TableBody>
                </Table>
              </Paper>
            </TableContainer>
          </Grid>
        )}
        {showOneReport &&
          <Dialog
            open={showOneReport}
            onClose={onCancle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            fullWidth
            classes={{ paper: classes.reportDialog }}
          >
            <div className={classes.dialogHeader}>
              <Typography className={classes.headerTitle}>{showDeleteReport ? t`Edit Report` : t`Add New Report`}</Typography>
              <IconButton disableRipple className={classes.iconBtnStyle} onClick={onCancle}><Close color="#7f7692" /></IconButton>
            </div>
            <div className={classes.dialogContent}>
              {showDeleteReport &&
                <div className={classes.delIconEditReport}>
                  <Delete
                    disabled={!canDeletePowerReportSchedules}
                    type={t`Report Schedule`}
                    object={selectedReport}
                    detach={onDelete}
                    buttonClass={classes.deleteIcon}
                  />
                </div>
              }
              <Grid container={true} className={classes.oneReportContainer}>

                <Grid item={true} xs={isPPDReport ? 4 : 6} className={classes.nameContainer}>
                  <Typography
                    className={classes.headerColumnTitle}
                  >{t`Report Name`}</Typography>
                  <TextField id="outlined-basic"
                    disabled={!canUpdatePowerReportSchedules}
                    label="Report Name"
                    variant="outlined"
                    className={classes.reportNameStyle}
                    value={oneReportName}
                    error={reportError === "name"}
                    helperText={reportError === "name" ? "missing report name" : ""}
                    onChange={(e: any) => {
                      if (isMissingName) {
                        setIsMissingName((false));
                      }
                      setOneReportName(e.target.value);
                    }} />

                  {!isPPDReport ?
                    <>
                      <Typography className={clsx(classes.headerStyle, classes.add20TopMargin)}>{t`Select Report`}</Typography>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {Object.keys(dataReportTypes).map((key) => {
                          return (
                            <InputLabel style={{ width: '50%' }} className={classes.itemName}>
                              <Checkbox
                                disabled={!canUpdatePowerReportSchedules}
                                checked={dataReportTypes?.[key]?.checked}
                                onChange={({ target: { checked } }: any) => {
                                  setDataReportTypes({
                                    ...dataReportTypes,
                                    [key]: {
                                      ...dataReportTypes[key],
                                      checked: checked
                                    }
                                  })
                                }}
                                checkedIcon={<CheckboxChecked />}
                                className={classes.smallCheckbox}
                                color="default"
                                variant="outlined"
                                edge="end"
                              />
                              {dataReportTypesNames?.[key]}
                            </InputLabel>
                          )
                        })}
                      </div>
                      <Typography className={classes.error}>{error}</Typography>
                    </>
                    : <div className={classes.rowContainer}>
                      <InputLabel className={classes.inputLabelStyle}>{t`File Format`}
                        <Select
                          disabled={!canUpdatePowerReportSchedules}
                          labelId="reportOptionsFormat"
                          value={format}
                          disableUnderline
                          className={classes.formatSelect}
                          onChange={(event: any) => setFormat(event.target.value)}
                          IconComponent={SvgArrow}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <MenuItem value={"pdf"}>PDF</MenuItem>
                          <MenuItem value={"csv"}>CSV</MenuItem>
                        </Select>
                      </InputLabel>
                      <InputLabel className={classes.inputLabelStyle}>{t`Granularity`}
                        <Select
                          disabled={!canUpdatePowerReportSchedules}
                          labelId="reportOptionsGranularity"
                          value={granularity}
                          className={classes.formatSelect}
                          IconComponent={SvgArrow}
                          disableUnderline
                          onChange={(event: any) => setGranularity(event.target.value)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <MenuItem value={"hour"}>{t`1 hour`}</MenuItem>
                          <MenuItem value={"day"}>{t`1 day`}</MenuItem>
                        </Select>
                      </InputLabel>
                    </div>
                  }
                  {selections.siteId && !!allSites[selections.siteId]?.supportedReportTypes?.length &&
                    <div className={classes.rowContainer}>
                      {isPPDReport && <>
                        <InputLabel className={classes.inputLabelStyle}>{t`Report Format`}
                          <Select
                            labelId="reportOptionsFormat"
                            value={reportFormat}
                            disableUnderline
                            className={classes.formatSelect}
                            onChange={(event: any) => setReportFormat(+event.target.value)}
                            IconComponent={SvgArrow}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {
                              Object.keys(types.ppdReportFormatTypes).map((type: any) => (
                                <MenuItem key={`type-${type}`} value={+types.ppdReportFormatTypes[type]}>{`${type}`}</MenuItem>
                              ))
                            }
                          </Select>
                        </InputLabel>
                      </>
                      }
                    </div>}

                  <Grid container={true} className={classes.timeSelectionsContainer}>
                    <Typography
                      className={classes.headerStyle}
                    >{t`Choose Frequency`}</Typography>

                    <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.btnGroupTimeunits}>
                      <Button disabled={!canUpdatePowerReportSchedules} disableRipple disableElevation onClick={() => { setTimeUnit("day"); setTimeUnitSubSelect(""); }} className={clsx(classes.btnText, timeUnit === "day" && classes.selectedBtn)}>Daily</Button>
                      <Button disabled={!canUpdatePowerReportSchedules} disableRipple disableElevation onClick={() => setTimeUnit("week")} className={clsx(classes.btnText, timeUnit === "week" && classes.selectedBtn)}>Weekly</Button>
                      <Button disabled={!canUpdatePowerReportSchedules} disableRipple disableElevation onClick={() => setTimeUnit("month")} className={clsx(classes.btnText, timeUnit === "month" && classes.selectedBtn)}>Monthly</Button>
                    </ButtonGroup>
                    {reportError && (reportError === "timeUnit" || reportError === "subTimeUnit") &&
                      <div className={classes.btnGroupErr}>{t`Please Select Frequency`}</div>}

                    <Grid item={true} xs={12} className={classes.selectTime}>
                      {timeUnit === "week" &&
                        <>
                          <div className={classes.btnsContainer}>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Sun")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Sun" })} >Sun</div>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Mon")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Mon" })} >Mon</div>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Tue")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Tue" })} >Tue</div>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Wed")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Wed" })} >Wed</div>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Thu")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Thu" })} >Thu</div>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Fri")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Fri" })} >Fri</div>
                            <div onClick={() => !canUpdatePowerReportSchedules ? {} : setTimeUnitSubSelect("Sat")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Sat" })} >Sat</div>
                          </div>
                        </>
                      }
                      {timeUnit === "month" &&
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="monthSubUnit"
                            name="controlled-radio-buttons-group"
                            value={timeUnitSubSelect}
                            onChange={(event: any) => setTimeUnitSubSelect(event.target.value)}
                          >
                            <FormControlLabel value="End" control={<Radio disabled={!canUpdatePowerReportSchedules} className={classes.radio} />} label="End Of Month" />
                            <FormControlLabel value="SpecificDay" control={<Radio disabled={!canUpdatePowerReportSchedules} className={classes.radio} />} label="Specific Day" />
                          </RadioGroup>
                        </FormControl>
                      }
                      {timeUnit === "month" && timeUnitSubSelect === "SpecificDay" &&
                        <Select
                          disabled={!canUpdatePowerReportSchedules}
                          labelId="reportOptionsFormat"
                          value={specificDay}
                          disableUnderline
                          className={classes.formatSelect}
                          onChange={(event: any) => setSpecificDay(event.target.value)}
                          IconComponent={SvgArrow}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {new Array(27).fill(0).map((_, i) => {
                            const dayName = ordinal_suffix_of(i + 2);
                            return <MenuItem key={i + 2} value={i + 2}>{dayName}</MenuItem>;
                          })}
                        </Select>
                      }
                    </Grid>
                    {isPPDReport && <>
                      <InputLabel className={clsx(classes.itemName, classes.add20TopMargin, { [classes.itemNameSelected]: distributeExcessPower })}>
                        <Checkbox
                          disabled={!canUpdatePowerReportSchedules}
                          checked={distributeExcessPower}
                          onChange={({ target: { checked } }: any) => setDistributeExcessPower(checked)}
                          checkedIcon={<CheckboxChecked />}
                          className={classes.smallCheckbox}
                          color="default"
                          variant="outlined"
                          edge="end"
                        />
                        {t`Distribute Excess Power to all units`}
                      </InputLabel>
                      <InputLabel className={clsx(classes.itemName, classes.add20TopMargin, { [classes.itemNameSelected]: generateMultipleReports })}>
                        <Checkbox
                          disabled={!canUpdatePowerReportSchedules}
                          checked={generateMultipleReports}
                          onChange={({ target: { checked } }: any) => {
                            setGenerateMultipleReports(checked);
                            if (checked) {
                              const ids = siteZones.reduce((result: any, item: any) => {
                                result[item.id] = true;
                                return result;
                              }, {});
                              setZonesToSave(ids);
                              setSystemsToSave({});
                            }
                          }}
                          checkedIcon={<CheckboxChecked />}
                          className={classes.smallCheckbox}
                          color="default"
                          variant="outlined"
                          edge="end"
                        />
                        {t`Generate Multiple Reports - one for Each selected Tenant`}
                      </InputLabel>
                    </>
                    }
                  </Grid>

                </Grid>
                {isPPDReport &&
                  <Grid container={true} xs={4} className={classes.middleSideRecipients}>
                    <div className={classes.middleSideSection}>


                      <Typography className={classes.headerColumnTitle}>{t`Select Tenants`}</Typography>
                      <div className={classes.middleSideSectionScrollable}>
                        {siteZones.length > 1 &&
                          <InputLabel className={clsx(classes.itemName, { [classes.itemNameSelected]: Object.keys(zonesToSave)?.length === siteZones?.length }, { [classes.itemNameDisabled]: (!canUpdatePowerReportSchedules || generateMultipleReports) })}>
                            <Checkbox
                              disabled={!canUpdatePowerReportSchedules || generateMultipleReports}
                              color="default"
                              checked={Object.keys(zonesToSave)?.length === siteZones?.length}
                              onChange={handleCheckZone("all")}
                              edge="end"
                              variant="outlined"
                              checkedIcon={<CheckboxChecked />}
                              className={classes.smallCheckbox}
                            />
                            {t`All Tenants`}
                          </InputLabel>}
                        {
                          siteZones.length ? _.orderBy(siteZones, [(item: any) => item?.name?.toUpperCase()],
                            ["asc"]).map((zone: any) => (
                              <InputLabel key={`zoneItem-${zone.id}`} className={clsx(classes.itemName, { [classes.itemNameSelected]: zonesToSave[zone.id] }, { [classes.itemNameDisabled]: (!canUpdatePowerReportSchedules || generateMultipleReports) })}>
                                <Checkbox
                                  disabled={!canUpdatePowerReportSchedules || generateMultipleReports}
                                  color="default"
                                  checked={!!zonesToSave[zone.id]}
                                  onChange={handleCheckZone(zone.id)}
                                  value={`zone${zone.id}`}
                                  edge="end"
                                  variant="outlined"
                                  checkedIcon={<CheckboxChecked />}
                                  className={classes.smallCheckbox}
                                />
                                {zone.name}
                              </InputLabel>

                            ))
                            :
                            <Typography className={classes.itemName}>{t`No tenants`}</Typography>
                        }
                      </div>
                    </div>
                    <div className={classes.middleSideSection}>
                      <Typography className={classes.headerColumnTitle}>{t`Select systems`}</Typography>
                      <div className={classes.middleSideSectionScrollable}>
                        {siteSystems.length > 1 &&
                          <InputLabel className={clsx(classes.itemName, { [classes.itemNameSelected]: Object.keys(systemsToSave)?.length === siteSystems?.length }, { [classes.itemNameDisabled]: (!canUpdatePowerReportSchedules || generateMultipleReports) })}>
                            <Checkbox
                              disabled={!canUpdatePowerReportSchedules || generateMultipleReports}
                              color="default"
                              checked={Object.keys(systemsToSave)?.length === siteSystems?.length}
                              onChange={handleCheckSystem("all")}
                              edge="end"
                              variant="outlined"
                              checkedIcon={<CheckboxChecked />}
                              className={classes.smallCheckbox}
                            />
                            {t`All Systems`}
                          </InputLabel>}
                        {siteSystems.length ?
                          _.orderBy(siteSystems, [(item: any) => item?.name?.toUpperCase()],
                            ["asc"]).map((system: any) => {
                              if (!system) {
                                return null;
                              }
                              return (
                                <InputLabel key={`systemItem-${system.id}`} className={clsx(classes.itemName, { [classes.itemNameSelected]: systemsToSave[system.id] }, { [classes.itemNameDisabled]: (!canUpdatePowerReportSchedules || generateMultipleReports) })}>
                                  <Checkbox
                                    disabled={!canUpdatePowerReportSchedules || generateMultipleReports}
                                    color="default"
                                    checked={!!systemsToSave[system.id]}
                                    onChange={handleCheckSystem(system.id)}
                                    value={`system${system.id}`}
                                    edge="end"
                                    variant="outlined"
                                    checkedIcon={<CheckboxChecked />}
                                    className={classes.smallCheckbox}
                                  />
                                  {system.name}
                                </InputLabel>
                              );
                            })
                          :
                          <Typography className={classes.itemName}>{t`No systems`}</Typography>
                        }
                      </div>
                    </div>
                    <Typography className={classes.error}>{error}</Typography>
                  </Grid>
                }

                < Grid container={true} xs={isPPDReport ? 4 : 6} className={classes.leftSideRecipients} >

                  <div className={classes.addRecipients}>
                    <Typography className={classes.headerTitleNoBorder}>{t`Choose Recipients`}</Typography>
                    <IconButton disabled={!canUpdatePowerReportSchedules} disableRipple className={clsx(classes.iconBtnStyle, classes.addIcon)} onClick={() => setShowAddUser(true)}>
                      <AddIcon />
                    </IconButton>
                  </div>

                  <Grid container={true} className={classes.reportsFormatWrtapper}>
                    <TextField id="outlined-basic"
                      placeholder={t`Search User`}
                      variant="outlined"
                      className={classes.searchUserScheduler}
                      value={searchVal}
                      // error={isMissingName}
                      // helperText={!isMissingName ? '' : 'need report name to save'}
                      onChange={(e: any) => {
                        setSearchVal(e.target.value);
                      }}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        )

                      }}

                    />

                    {selectedReportUsers && recipientsList()}
                  </Grid>

                  <Grid container={true} className={classes.reportsFormatWrtapper}>
                  </Grid>

                </Grid>

                {showAddUser && addUserToReportModel()}
              </Grid>
            </div>
            <div className={classes.actionsHolder}>
              <CoolButton disabled={!canUpdatePowerReportSchedules}
                white
                marginRight
                width={150}
                onClick={onCancle}>
                {t`Cancel`}
              </CoolButton>
              <CoolButton disabled={!canUpdatePowerReportSchedules}
                width={150}
                onClick={onSave}>
                {t`Save`}
              </CoolButton>
            </div>
          </Dialog>}
      </div>
    </div>
  );
};

export default TenantList;
