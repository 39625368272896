import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IGroup {
  id: string;
  units: string[];
  name?: string;
  site: string;
  customer: string;
  description?: string;
  role: any;
  type?: any;
}

export interface IGroupMap {
  [key: string]: IGroup;
}

interface IPowerPayload {
  groupId: string;
  state: number;
}

interface ISetPointPayload {
  groupId: string;
  setPoint: number;
}

export interface IGroupsModel {
  allGroups: IGroupMap;
  initialize: Action<IGroupsModel, any>;
  onInitialized: ActionOn<IGroupsModel, IRootStoreModel>;
  changePowerState: Thunk<IGroupsModel, IPowerPayload>;
  changeSetPoint: Thunk<IGroupsModel, ISetPointPayload>;
  getTenants: Computed<IGroupsModel>;
  getGroup: Thunk<IGroupsModel, string, any>;
  getGroupName: Computed<
    IGroupsModel,
    (id: string | undefined) => string | undefined
  >;
  selectedGroups: IGroupMap;
  setSelectedGroups: Action<IGroupsModel, any>;
  getGroupsBySiteId: Thunk<IGroupsModel, string>;
  getSelectedTenants: Computed<IGroupsModel>;
  createGroup: Thunk<IGroupsModel, any>;
  updateGroup: Thunk<IGroupsModel, { id: string; data: any }>;
  updateGroupsState: Action<IGroupsModel, IGroup>;
  addGroup: Action<IGroupsModel, IGroup>;
  deleteGroup: Thunk<IGroupsModel, string>;
  removeGroup: Action<IGroupsModel, string>;
  deleteGroupAPI: Thunk<IGroupsModel, string>;
  updateGroupAPI: Thunk<
    IGroupsModel,
    {
      groupId: string;
      data: any;
    }
  >;
  getCustomerGroups: Thunk<IGroupsModel, string>;
  getMygroups: Thunk<IGroupsModel>;
  getSiteGroups: Thunk<IGroupsModel, string>;
  getLocalGroup: Computed<
    IGroupsModel,
    (id?: string | null | undefined) => any,
    IRootStoreModel
  >;
}

export const groupsModel: IGroupsModel = {
  allGroups: {},

  initialize: action((state, payload) => {
    state.allGroups = { ...state.allGroups, ...payload };
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),

  changeSetPoint: thunk((actions, payload) => {
    const { groupId, setPoint } = payload;

    return coolremoteSDK.Group.setActiveSetpoint(groupId, setPoint);
  }),
  changePowerState: thunk((actions, payload, { getStoreActions }) => {
    const { groupId, state } = payload;

    return coolremoteSDK.Group.setActiveOperationStatus(groupId, {
      operationStatus: state,
    }).then(() => {
      const storeActions: any = getStoreActions();
      storeActions.units.updateMultipleUnitsLocally({
        filter: "groups",
        filterVal: groupId,
        key: "activeOperationStatus",
        value: state,
      });
    });
  }),
  getTenants: computed(state => {
    const tenants = _.filter(state.allGroups, group => group.type === "2");
    return tenants || [];
  }),
  ////////////////////////////////////

  selectedGroups: {},
  getGroup: thunk(async (actions, payload) => {
    return coolremoteSDK.Group.getGroupById(payload);
  }),

  getGroupName: computed(state => id => {
    const noName = "-";

    if (!id) {
      return noName;
    }

    if (!state.allGroups[id]) {
      return noName;
    }

    return state.allGroups[id].name ? state.allGroups[id].name : noName;
  }),

  setSelectedGroups: action((state, payload) => {
    const newGroups: IGroupMap = _(Object.values(payload))
      .map((group: any) => {
        const newGroup: IGroup = { ...group };
        return newGroup;
      })
      .keyBy("id")
      .value();

    state.selectedGroups = newGroups;
  }),

  getSelectedTenants: computed(state => {
    const tenants = _.filter(state.selectedGroups, group => group.type === "2");
    return tenants || [];
  }),

  getGroupsBySiteId: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Site.getGroups(payload);
    // actions.setSelectedGroups(response);
    actions.initialize(response);
    return response;
  }),

  deleteGroup: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Group.delete(payload);
    actions.removeGroup(payload);
    return response;
  }),

  createGroup: thunk(async (actions, payload) => {
    const newGroup = await coolremoteSDK.Group.createGroup(payload);
    actions.addGroup(newGroup);
    return newGroup;
  }),

  updateGroup: thunk(async (actions, payload) => {
    const updatedGroup = await coolremoteSDK.Group.update(
      payload.id,
      payload.data
    );
    actions.updateGroupsState(updatedGroup);
    return updatedGroup;
  }),

  addGroup: action((state, payload) => {
    state.allGroups[payload.id] = payload;
  }),

  removeGroup: action((state, payload) => {
    delete state.allGroups[payload];
  }),

  updateGroupsState: action((state, payload) => {
    state.allGroups[payload.id] = payload;
  }),
  deleteGroupAPI: thunk(async (actions, payload) => {
    return coolremoteSDK.Group.delete(payload);
  }),
  updateGroupAPI: thunk(async (actions, payload) => {
    const { groupId, data } = payload;
    return coolremoteSDK.Group.update(groupId, data);
  }),
  getCustomerGroups: thunk(async (actions, payload) => {
    return coolremoteSDK.Customer.getCustomerGroups(payload);
  }),
  getMygroups: thunk(async (actions, payload) => {
    return coolremoteSDK.Group.getGroups();
  }),
  getSiteGroups: thunk(async (actions, siteId) => {
    return coolremoteSDK.Site.getGroups(siteId);
  }),
  getLocalGroup: computed([state => state.allGroups], allGroups => id => {
    if (_.isNil(id)) {
      return undefined;
    }
    return allGroups[id];
  }),
};
