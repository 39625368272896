import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: any) => ({

  root: {
    width: "100%",
    height: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    display: "flex",
    background: "white",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  pageContent: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    flex: 1,
    height: "calc(100% - 80px)",
    maxHeight: "calc(100% - 80px)"
  },
  block: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px"
  },
  titleAndIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  lowZIndex: {
    zIndex: 1
  },
  tooltip: {
    backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: "10px",
    marginBottom: "10px"
  },
  tooltipArrow: {
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  screenTitle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.dark
  },
  slider: {
    width: "70%",
    margin: "1rem 0",
    color: "grey",
  },
  sliderValueLabel: {
    left: "unset",
    "& :after": {
      content: `""`,
      position: "absolute",
      top: "97%",
      left: "50%",
      zIndex: "-1",
      marginLeft: "-6px",
      borderWidth: "6px",
      borderStyle: "solid",
      borderColor: "currentColor transparent transparent transparent"
    },
    "& span": {
      justifyContent: "center",
      width: "70px",
      transform: "unset",
      display: "flex",
      wordWrap: "break-word",
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: "500",
      lineHeight: "1.4em",
      borderRadius: "4px"
    }
  },
  sliderRoot: {
    touchAction: "auto"
  },
  sliderContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  sliderTime: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlined.color
  },
  refreshStyle: {
    width: "20px",
    height: "20px"
  },
  iconBtnStyle: {
    border: theme.overrides.MuiButton.outlined.border,
    width: "30px",
    height: "30px"
  },
  hidden: {
    visibility: "hidden"
  },
  tuneIcon: {
    padding: 0
  },
  paramsTable: {
    overflowY: "auto",
    height: "100%",
    marginTop: "30px",
    padding: "0 15px"
  },
  tableHead: {
    borderTop: theme.overrides.MuiButton.outlined.border
  },
  tableRow: {
    "&:nth-of-type(odd), &:hover": {
      backgroundColor: "#f5f4f5 !important"
    },
    "&:nth-of-type(even), &:hover": {
      backgroundColor: "unset !important"
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f4f5 !important"
    }
  },
  loaderContainer: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    height: "100%"
  },
  tableCell: {
    padding: "15px",
    textAlign: "left",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlined.color,
    borderBottom: "none",
    wordBreak: "break-word"
  },
  unitNameCell: {
    width: "120px"
  },
  nameCell: {
    lineHeight: 1.25,
    color: theme.overrides.MuiButton.outlinedPrimary.color,
    borderRight: theme.overrides.MuiButton.outlined.border,
    borderBottom: "none"
  },
  bold: {
    fontWeight: "bold"
  },
  tableContainer: {
    height: "calc(100% - 20px)",
    width: "auto"
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90px",
    display: "block"
  },
  noDataMsg: {
    width: "calc(100% + 120px)",
    textAlign: "center",
    lineHeight: "40px"
  }
}));

export default useStyles;
