import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  ontainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between"
  },

  viewTstatContainer: {
    justifyContent: "initial"
  },

  switchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 20,
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    height: 50,
    flex: 1,
    maxWidth: '33%'
  },
  scheduleSwitchContainer: {
    flex: "unset",
    margin: "0"
  },
  switch: {
    "&:disabled": {
      opacity: 0.6
    }
  },

  viewSwitchContainer: {
    marginRight: "10px",
    width: "32.18%"
  },

  viewUnitItemContainer: {
    width: "33%"
  },

  UnitLimitsContainer: {
    width: "48.18%"
  },

  switchTitle: {
    fontSize: "15px",
    color: "#545964",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  }
}));
export default useStyles;
