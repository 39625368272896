import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Delete } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./AutomationRule.style";
import { getCustomOptions } from "./conditionUtils";
import { CustomAutoComplete, CustomedTextField, CustomSelect } from "./CustomedComponents";

const actionOptions = [
  { value: 1, enum: "unitOperationStatuses", name: "ON/OFF" },
  { value: 3, data_unit_of_measurement: "°C", name: "Setpoint" },
  { value: 4, name: "Fan", enum: "unitFanModes" },
  { value: 2, enum: "unitOperationModes", name: "Mode" },
  { value: 7, name: "Alert" }
];

const actionWRCOptions = [
  { value: 8, enum: "trueFalseEnum", name: "WRC On/Off" },
  { value: 9, enum: "trueFalseEnum", name: "WRC Mode" },
  { value: 10, enum: "trueFalseEnum", name: "WRC Setpoint" },
];

const hRVactionOptions = [
  { value: 1, enum: "unitOperationStatuses", name: "ON/OFF" },
  { value: 4, name: "Fan", enum: "unitFanModes" },
  { value: 2, enum: "unitOperationModes", name: "Mode" },
  { value: 7, name: "Alert" }
];
const onOffSensorEnums = [{ value: "0", name: t`OFF` }, { value: 1, name: t`ON` }];
const systemActionOptions = [
  { value: 1, enum: "unitOperationStatuses", name: "ON/OFF" },
  { value: 3, data_unit_of_measurement: "°C", name: "Setpoint" },
  { value: 4, name: "Fan", enum: "unitFanModes" },
  { value: 2, enum: "unitOperationModes", name: "Mode" }
];

const sensorActionOptions = [
  { value: 6, name: "Value" },
  { value: 7, name: "Alert" }
];

const soruceOptions = [
  { value: "systems", name: "System" },
  { value: "outputSensors", name: "Output Device" },
  { value: "indoors", name: "Indoor Unit" },
  { value: "alert", name: "Alert" },
  { value: "WRCLocks", name: "WRC locks" }
];

const ruleParameters: any = {
  4: {
    allBrands: true,
    code: 52,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "unitFanModes",
    hvac_param_name: "FanSpeed",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Fan Speed"
  },
  2: {
    allBrands: true,
    code: 51,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationModes",
    hvac_param_name: "Mode",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Mode"
  },
  1: {
    allBrands: true,
    code: 48,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationStatuses",
    hvac_param_name: "IndoorOnOffStatus",
    max: 1,
    min: 0,
    plotable: true,
    showInGraph: false,
    title: "ON/OFF"
  },
  49: {
    allBrands: true,
    code: 49,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "RoomTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Room Temp"
  },
  3: {
    allBrands: true,
    code: 50,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "SetTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Set Temp"
  },
  57: {
    allBrands: true,
    code: 57,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "",
    hvac_param_name: "SiteTemp",
    max: 150,
    min: -50,
    plotable: true,
    showInGraph: true,
    title: "Site Temperature"
  },
  8: {
    code: 8,
    enum: "trueFalseEnum",
    data_unit_of_measurement: "",
    title: "WRC On/Off",
    hvac_param_name: "WRC On/Off",
    max: 1,
    min: 0,
    plotable: true,
    allBrands: true,
    enabledInTriggers: true,
    showInGraph: true
  },
  9: {
    code: 9,
    enum: "trueFalseEnum",
    data_unit_of_measurement: "",
    title: "WRC Mode",
    hvac_param_name: "WRC Mode",
    max: 10,
    min: 0,
    plotable: true,
    allBrands: true,
    enabledInTriggers: true,
    showInGraph: true
  },
  10: {
    code: 10,
    enum: "trueFalseEnum",
    data_unit_of_measurement: "",
    title: "WRC Setpoint",
    hvac_param_name: "WRC Setpoint",
    max: 100,
    min: 0,
    plotable: true,
    allBrands: true,
    enabledInTriggers: true,
    showInGraph: true
  }
};

const selectItemLabels: any = {
  systems: t`Select System`,
  outputSensors: t`Select Sensor`,
  indoors: t`Select Indoor Unit`,
  "": t`Select`
};

export default (props: any) => {
  const classes = styles();
  const {
    errors,
    touched,
    index,
    action,
    setFieldValue,
    serviceParamTypes,
    customEnumOptions,
    site,
    values,
    deleteAction,
    setValues,
    disabled = false,
    duplicateAction = null
  } = props;

  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  return (
    <div className={clsx(classes.conditionContainer, classes.marginBottom)}>
      <CustomSelect
        className={classes.width180}
        error={getIn(errors, `actions[${index}].source`) && getIn(touched, `actions[${index}].source`)}
        placeholder="Select Source"
        value={action.source}
        name="source"
        onChange={(event: any) => {
          setFieldValue(`actions[${index}].source`, event.target.value);
          setFieldValue(`actions[${index}].itemId`, "");
          setFieldValue(`actions[${index}].action`, "");
          setFieldValue(`actions[${index}].value`, "");
        }}
        options={soruceOptions}
        disabled={disabled}
      />
      {action.source !== "alert" &&
        <>
          <CustomAutoComplete
            error={getIn(errors, `actions[${index}].itemId`) && getIn(touched, `actions[${index}].itemId`)}
            placeholder={selectItemLabels[action.source || ""]}
            onChange={(event: any, value: any) => {
              setFieldValue(`actions[${index}].itemId`, value);
              if (action.source === "indoors" && action.itemId && (action.itemId.subType !== value?.subType)) {
                setFieldValue(`actions[${index}].value`, "");
                if (+action?.action === 3) {
                  setFieldValue(`actions[${index}].action`, "");
                }
              }
            }}
            value={action.itemId}
            options={action.source ? site[action.source] : []}
            name="itemId"
          />
          <CustomSelect
            disabled={disabled || !action.itemId}
            className={classes.width130}
            error={getIn(errors, `actions[${index}].action`) && getIn(touched, `actions[${index}].action`)}
            placeholder="Action"
            name="action"
            value={action.action}
            options={action.source === "systems" ? systemActionOptions : action.source === "outputSensors" ? sensorActionOptions : (action?.itemId?.subType === 2 ? hRVactionOptions : action.source === "WRCLocks" ? actionWRCOptions : actionOptions)}
            onChange={(event: any) => {
              setFieldValue(`actions[${index}].action`, event.target.value);
              setFieldValue(`actions[${index}].value`, "");
            }}
          />
          {action.action === 7 ? <></> :
            action.source === "outputSensors" ?
              <>
                {action.itemId?.type === 129 ?
                  <CustomSelect
                    disabled={disabled}
                    className={classes.width130}
                    error={getIn(errors, `actions[${index}].value`) && getIn(touched, `actions[${index}].value`)}
                    placeholder="Value"
                    name="value"
                    value={action.value}
                    onChange={(event: any) => setFieldValue(`actions[${index}].value`, event.target.value)}
                    options={onOffSensorEnums}
                  /> :
                  <CustomedTextField
                    disabled={disabled}
                    className={classes.width130}
                    error={getIn(errors, `actions[${index}].value`) && getIn(touched, `actions[${index}].value`)}
                    onChange={(event: any) => setFieldValue(`actions[${index}].value`, event.target.value)}
                    name="value"
                    type="number"
                    value={action.value}
                    label={t`Value`}
                  />}
              </> :
              ruleParameters[action.action]?.enum ?
                <CustomSelect
                  disabled={disabled}
                  className={classes.width130}
                  error={getIn(errors, `actions[${index}].value`) && getIn(touched, `actions[${index}].value`)}
                  placeholder="Value"
                  name="value"
                  value={action.value}
                  onChange={(event: any) => setFieldValue(`actions[${index}].value`, event.target.value)}
                  options={getCustomOptions(action.source, ruleParameters[action.action]?.enum, action.itemId.subType, serviceParamTypes, customEnumOptions)}
                /> :
                <CustomedTextField
                  disabled={disabled}
                  className={classes.width130}
                  error={getIn(errors, `actions[${index}].value`) && getIn(touched, `actions[${index}].value`)}
                  onChange={(event: any) => setFieldValue(`actions[${index}].value`, event.target.value)}
                  name="value"
                  type="number"
                  value={action.value}
                  label={t`Value` + `${temperatureScale === 2 ? "°F" : "°C"}`}
                />
          }
        </>
      }
      <div className={classes.buttonsContainer}>
        {!!duplicateAction && <Tooltip title={t`Duplicate action`} >
          <Button
            disabled={disabled}
            onClick={duplicateAction}
            variant="text"
            size="small"
            className={classes.duplicateButton}
          >
            {t`Duplicate`}
          </Button>
        </Tooltip>
        }
        <IconButton
          disabled={disabled}
          className={clsx(classes.iconBtnStyle, classes.deleteIconNotInGroup)}
          onClick={deleteAction}
        >
          <Delete style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
    </div>
  );
};
