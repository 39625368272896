import React from "react";

const SvgSearch = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd" strokeLinecap="round">
            <g stroke="#AAA2AA" strokeWidth="1.42">
                <g transform="translate(-154 -773) translate(154 773)">
                    <circle cx="8.011" cy="8.011" r="7.268" />
                    <path d="M13.462 13.462L16.957 16.957" />
                </g>
            </g>
        </g>
    </svg>

);

export default SvgSearch;
