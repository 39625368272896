import { Sensor as SensorSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  computed,
  Computed,
  memo,
  thunk,
  Thunk,
} from "easy-peasy";
import { t } from "ttag";
import { IRootStoreModel } from "./RootStore";

export interface ISensor {
  id: string;
  name: string;
  readingValue?: number | string;
  availableReadingValues?: any;
  readingValueTimestamp?: number;
  type: number;
  enableOperationMode: boolean;
  readingValueMeasurementUnits: number;
  device: string;
  deviceSerial: string;
  site: string;
  dataAddress: string;
  userData: any;
  schedules?: any;
  canControl?: boolean;
  isConnected?: boolean;
  isVisible?: boolean;
}

export interface ISensorMap {
  [key: string]: ISensor;
}

export interface ISensorsModel {
  allSensors: ISensorMap;
  siteSensors: ISensorMap;
  getSiteSensors: Thunk<ISensorsModel, string>;
  getAllSensors: Thunk<ISensorsModel>;
  getSensorById: Thunk<ISensorsModel, string>;
  getSensorsByDevice: Thunk<ISensorsModel, string>;

  initialize: Action<ISensorsModel, any>;
  onInitialized: ActionOn<ISensorsModel, any>;
  updateSensor: Thunk<ISensorsModel, { id: string; updatedData: any }>;
  updateSensorValue: Thunk<
    ISensorsModel,
    { id: string; data: { value: number } }
  >;
  setSiteSensors: Action<ISensorsModel, any>;
  setAllSensors: Action<ISensorsModel, any>;
  _storeCreateSensor: Action<ISensorsModel, { id: string; data: ISensor }>;
  _storeUpdateSensor: Action<ISensorsModel, { id: string; data: any }>;
  getSensorName: Computed<
    ISensorsModel,
    (sensorId: string | null | undefined) => string,
    IRootStoreModel
  >;
  getSensor: Computed<
    ISensorsModel,
    (sensorId: string | null | undefined) => ISensor | null,
    IRootStoreModel
  >;
  getSiteSensorsAsParams: Computed<
    ISensorsModel,
    (id: string, minMaxOnly?: boolean) => any,
    IRootStoreModel
  >;
  getSensorStats: Thunk<ISensorsModel, { id: string; data: any }>;
  getAllSensorsStats: Thunk<ISensorsModel, { params: any; data: any }>;
  getCustomersSensorsData: Computed<ISensorsModel, () => any, IRootStoreModel>;
  addScheduleToSensorEntity: Action<
    ISensorsModel,
    { sensorId: string; scheduleId: string }
  >;
  deleteScheduleFromSensorEntity: Action<
    ISensorsModel,
    { sensorId: string; scheduleId: string }
  >;
  createSensorSchedule: Thunk<ISensorsModel, { sensorId: string; data: any }>;
  getSensorSchedules: Thunk<ISensorsModel, string>;
  getSensorByIdLocaly: Computed<
    ISensorsModel,
    (id: string | null | undefined) => any,
    IRootStoreModel
  >;
  getSensorData: Computed<
    ISensorsModel,
    (id: string | null | undefined) => any,
    IRootStoreModel
  >;
  addUpdateSensors: Action<ISensorsModel, any>;
}

export const sensorsModel: ISensorsModel = {
  allSensors: {},
  siteSensors: {},

  getAllSensors: thunk(async actions => {
    const allSensors = await SensorSdk.getMySensors();
    actions.initialize(allSensors);
    return allSensors;
  }),

  getSiteSensors: thunk(async (actions, payload) => {
    const siteSensors = await SensorSdk.getSiteSensors(payload);
    actions.addUpdateSensors(siteSensors);
    return siteSensors;
  }),

  getSensorById: thunk(async (actions, payload) => {
    return SensorSdk.getSensorById(payload);
  }),

  getSensorsByDevice: thunk(async (actions, payload) => {
    const data = await SensorSdk.getSensorsByDevice(payload);
    return data;
  }),

  initialize: action((state, payload) => {
    state.allSensors = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),

  updateSensor: thunk(async (actions, payload) => {
    const data = await SensorSdk.update(payload.id, payload.updatedData);

    // Why call _storeCreate_storeCreateSensor()? Because it assigns the answer as-is.
    actions._storeCreateSensor({ id: data.id, data });
    return data;
  }),

  setSiteSensors: action((state, payload) => {
    state.siteSensors = payload;
  }),

  setAllSensors: action((state, payload) => {
    state.allSensors = payload;
  }),
  addUpdateSensors: action((state, payload) => {
    state.allSensors = { ...state.allSensors, ...payload };
  }),
  _storeCreateSensor: action((state, payload) => {
    if (state.allSensors[payload.id]) {
      state.allSensors[payload.id] = {
        ...state.allSensors[payload.id],
        ...payload.data,
      };
    } else {
      state.allSensors = { ...state.allSensors, [payload.id]: payload.data };
    }
  }),

  _storeUpdateSensor: action((state, payload) => {
    if (state.allSensors[payload.id]) {
      state.allSensors = {
        ...state.allSensors,
        [payload.id]: { ...state.allSensors[payload.id], ...payload.data },
      };
    }
  }),
  updateSensorValue: thunk(async (actions, payload) => {
    const { id, data } = payload;
    const response = await SensorSdk.updateSensorValues(id, data);

    return response;
  }),
  getSensorName: computed([state => state.allSensors], allSensors =>
    memo(sensorId => {
      if (!sensorId) {
        return "-";
      }
      return allSensors[sensorId]?.name;
    }, 100)
  ),
  getSensor: computed(
    [
      state => state.allSensors,
      (state, storeState: any) => storeState.types,
      (state, storeState: any) =>
        storeState.users.getTemperatureScaleDisplayPlainText,
    ],
    (allSensors, allTypes, tempScaleSign) =>
      memo(sensorId => {
        if (!sensorId) {
          return null;
        }
        const { sensorTypes, sensorMeasurementUnits } = allTypes;

        const sensor = allSensors[sensorId];
        if (!sensor) {
          return null;
        }
        const { type, readingValue = 0, userData = {} } = sensor;
        const sensorType = sensorTypes[type] || {};
        const {
          enableMinMaxSelection,
          measurementUnits,
          enableMeasurementUnitSelection,
          valueMax,
          valueMin,
        } = sensorType;
        const activeMeasurementUnit =
          enableMeasurementUnitSelection && userData?.measurementUnitsType
            ? userData?.measurementUnitsType
            : measurementUnits;
        const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
        const { rangeMax: userMax, rangeMin: userMin } = userData;
        const value = +readingValue;
        const analogSensor = !sensorUnit || type === 130 || type === 7;
        const hasUserMinMax =
          analogSensor &&
          enableMinMaxSelection &&
          hasValue(userMax) &&
          hasValue(userMin);
        const enums: any =
          sensorUnit === "Open/Close"
            ? type === 129
              ? { 0: t`OFF`, 1: t`ON` }
              : { 1: t`OPEN`, 0: t`CLOSE` }
            : null;

        const userMinMax = hasUserMinMax ? { userMin, userMax } : null;
        return {
          ...sensor,
          selected: false,
          sensorUnit,
          unit: activeMeasurementUnit,
          enums,
          userMinMax,
          valueMinMax: { min: valueMin, max: valueMax },
          tempSign:
            sensorUnit === "Temperature" && type !== 7 ? tempScaleSign() : "",
        };

        // return sensor;
      }, 100)
  ),
  getSensorStats: thunk(async (actions, payload) => {
    const data = await SensorSdk.getSensorStats(payload.id, payload.data);
    return data;
  }),
  getSiteSensorsAsParams: computed(
    [
      state => state.allSensors,
      (state, storeState: any) => storeState.types,
      (state, storeState: any) => storeState.users.me.temperatureScale,
      (state, storeState: any) =>
        storeState.users.getTemperatureScaleDisplayPlainText,
    ],
    (allSensors, allTypes, tempScale, tempScaleSign) =>
      (siteId, onlyHasUserMinMax = true) => {
        if (!siteId) {
          return { sensors: {}, sensorsParams: {} };
        }
        const { sensorTypes, sensorMeasurementUnits } = allTypes;
        let sensors: any = {};
        let sensorsAsParams: any = {};

        Object.values(allSensors).forEach((sensor: any) => {
          if (sensor.site === siteId) {
            const { type, readingValue, userData = {} } = sensor;
            const sensorType = sensorTypes[type] || {};
            const {
              enableMinMaxSelection,
              measurementUnits,
              enableMeasurementUnitSelection,
              valueMax,
              valueMin,
            } = sensorType;
            const activeMeasurementUnit =
              enableMeasurementUnitSelection && userData?.measurementUnitsType
                ? userData?.measurementUnitsType
                : measurementUnits;
            const sensorUnit =
              sensorMeasurementUnits[activeMeasurementUnit]?.name;
            const { rangeMax: userMax, rangeMin: userMin } = userData;
            const analogSensor = !sensorUnit || type === 130 || type === 7;
            const hasUserMinMax =
              analogSensor &&
              enableMinMaxSelection &&
              hasValue(userMax) &&
              hasValue(userMin);
            const enums: any =
              sensorUnit === "Open/Close"
                ? type === 129
                  ? { 0: t`OFF`, 1: t`ON` }
                  : { 1: t`OPEN`, 0: t`CLOSE` }
                : null;

            const userMinMax = hasUserMinMax ? { userMin, userMax } : null;
            const id = sensor.id;
            const newSensor = {
              valueMax,
              valueMin,
              name: sensor.name,
              sensorUnit,
              unit: activeMeasurementUnit,
              enums,
              slider: null,
              userMinMax,
              tempSign:
                sensorUnit === "Temperature" && type !== 7
                  ? tempScaleSign()
                  : "",
            };
            sensors[id] = newSensor;
            if (onlyHasUserMinMax && userMinMax) {
              sensorsAsParams[id] = { id, userMin, userMax };
            } else {
              sensorsAsParams[id] = userMinMax
                ? { id, userMin, userMax }
                : { id };
            }
          }
        });
        return { sensors, sensorsAsParams };
      }
  ),
  getAllSensorsStats: thunk(async (actions, payload, { getState }) => {
    const data = await SensorSdk.getMultiSensorsStats(
      payload.params,
      payload.data
    );
    return data;
  }),
  getCustomersSensorsData: computed(
    [
      state => state.allSensors,
      (state, storeState: any) => storeState.types,
      (state, storeState: any) => storeState.sites.allSites,
      (state, storeState: any) => storeState.users.me.temperatureScale,
      (state, storeState: any) =>
        storeState.users.getTemperatureScaleDisplayPlainText,
    ],
    (allSensors, allTypes, allSites, tempScale, tempScaleSign) => () => {
      const { sensorTypes } = allTypes;
      const options: any = {};
      const sensorsPerSite: any = {};
      const customerSensors: any = {};

      Object.values(allSensors).forEach((sensor: any) => {
        const { site: siteId, type, id } = sensor;
        if (!sensorTypes[type]?.enableView) {
          return;
        }

        options[type] = true;
        customerSensors[id] = sensor;

        if (sensorsPerSite[siteId]) {
          sensorsPerSite[siteId].push(id);
        } else {
          sensorsPerSite[siteId] = [id];
        }
      });

      const optionsArray = Object.keys(options).map((key: string) => {
        return { value: key, name: sensorTypes[key]?.name || "Unknown" };
      });
      return { options: optionsArray, sensorsPerSite, customerSensors };
    }
  ),
  createSensorSchedule: thunk((actions, payload) => {
    return SensorSdk.createSensorSchedule(payload.sensorId, payload.data);
  }),
  getSensorSchedules: thunk((actions, payload) => {
    return SensorSdk.getSensorSchedules(payload);
  }),
  addScheduleToSensorEntity: action((state, payload) => {
    const { sensorId, scheduleId } = payload;
    if (state.allSensors[sensorId]) {
      state.allSensors[sensorId].schedules.push(scheduleId);
      state.allSensors = state.allSensors;
    }
  }),
  deleteScheduleFromSensorEntity: action((state, payload) => {
    const { sensorId, scheduleId } = payload;
    if (state.allSensors[sensorId]) {
      state.allSensors[sensorId].schedules.filter(
        (id: string) => id === scheduleId
      );
      state.allSensors = state.allSensors;
    }
  }),
  getSensorByIdLocaly: computed(
    [state => state.allSensors],
    allSensors => id => {
      if (!id) return undefined;
      return allSensors[id];
    }
  ),
  getSensorData: computed(
    [state => state.allSensors, (state, storeState: any) => storeState.types],
    (allSensors, allTypes) => id => {
      if (!id || !allSensors[id]) return undefined;
      const sensor = allSensors[id];
      const { sensorTypes, sensorMeasurementUnits } = allTypes;
      const { type, readingValue, userData = {} } = sensor;
      const sensorType = sensorTypes[type] || {};

      const {
        enableMinMaxSelection,
        measurementUnits,
        enableMeasurementUnitSelection,
        valueMax,
        valueMin,
      } = sensorType;
      const activeMeasurementUnit =
        enableMeasurementUnitSelection && userData?.measurementUnitsType
          ? userData?.measurementUnitsType
          : measurementUnits;
      const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
      const { rangeMax: userMax, rangeMin: userMin } = userData;
      const analogSensor = !sensorUnit || type === 130 || type === 7;
      const hasUserMinMax =
        analogSensor &&
        enableMinMaxSelection &&
        hasValue(userMax) &&
        hasValue(userMin);
      const userMinMax = hasUserMinMax ? { userMin, userMax } : null;

      return { userMinMax, valueMax, valueMin };
    }
  ),
};

const hasValue = (value: any) => {
  return !!value || value === 0;
};
