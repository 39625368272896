import { IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { EditIcon } from "../../logos";
import { useStoreState } from "../../models/RootStore";
import styles from "./noteStyle";

const NoteBox = (props: any) => {

    const { note, allUsers, deleteNote, canDeleteNote = true } = props;
    const { id, date, note: noteMsg, unit, user } = note;
    const { dateFormat, timeFormat } = useStoreState((state) => state.users);
    const classes = styles();

    return (
        <div className={classes.noteContainer}>
            <div className={classes.supportDataSection}>
                <div style={{
                    display: "flex",
                    maxWidth: "calc(100% - 100px)",
                    justifyContent: "flex-end", alignItems: "center"
                }}>
                    <Typography className={classes.username}>{allUsers && allUsers[user]?.username || t`Unknown`}</Typography>
                    <Typography className={classes.lightData}>{moment(date).format(`${dateFormat} ${timeFormat}`)}</Typography>
                    <Typography className={classes.lightData}>{!!unit ? t`Unit Note` : t`System Note`}</Typography>
                </div>
                <div id="actions">
                    <LightTooltip title={t`Edit Note`}>
                        <IconButton disableRipple style={{ display: "none" }} className={classes.iconBtnStyle}>
                            <EditIcon />
                        </IconButton>
                    </LightTooltip>
                    <Delete
                        disabled={!canDeleteNote}
                        type={t`Note`}
                        object={{ name: t`this note` }}
                        detach={() => canDeleteNote && deleteNote(id)}
                    ></Delete>
                </div>
            </div>
            <Typography className={classes.noteMsg}>{noteMsg}</Typography>
        </div>
    );
};

export default NoteBox;
