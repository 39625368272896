import { createStyles } from "@material-ui/core/styles";

const style = (theme: any) =>
  createStyles({
    dateRangeLabel: {
      width: "170px",
      height: "36px",
      borderRadius: "3px",
      border: "solid 1px #d5d2d5",
      backgroundColor: "#fff",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      color: "#47505a",
      textAlign: "center",
      alignItems: "center",
      display: "flex",
      justifyContent: "center"
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.2)"
    },
    rangePickerContainer: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      boxShadow: "5px 5px 10px 0px rgba(138,138,138,1)"
    },
    calendarButtons: {
      display: "flex",
      flexFlow: "row nowrap",
      width: "100%",
      justifyContent: "flex-end",
      paddingRight: "20px",
      alignItems: "center",
      marginTop: "-10px",
      paddingBottom: "10px"
    },
    container: {

      "& .rdrEndEdge": {
        color: `${theme.palette.colors.dateRangeEnd} !important`,
      },

      "& .rdrStartEdge": {
        color: `${theme.palette.colors.dateRangeEnd} !important`,
      },


      "& .rdrInRange": {
        backgroundColor: `${theme.palette.colors.dateRangeWithen} !important`
      },

      "& .rdrDayHovered>*": {
        borderColor: `${theme.palette.primary.main} !important`,
      },

      "& .rdrDays:hover": {
        borderColor: `${theme.palette.primary.main} !important`,
      },

      "& .rdrDayToday .rdrDayNumber span:after": {
        background: `${theme.palette.primary.main} !important`,
      },

      "& .rdrDayInPreview": {
        borderColor: `${theme.palette.primary.main} !important`,
      },

      "& .rdrDayStartPreview": {
        borderColor: `${theme.palette.primary.main} !important`,
      },

      "& .rdrDayEndPreview": {
        borderColor: `${theme.palette.primary.main} !important`,
      }

    }
  });


export default style;