import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import {
  formatDistanceStrict,
  setHours,
  setMinutes,
  setSeconds,
  startOfHour,
  startOfMinute,
  sub
} from "date-fns";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip as RechartsToolTip,
  XAxis,
  YAxis
} from "recharts";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import SvgArrow from "../../icons/Arrow";
import { CheckboxChecked } from "../../icons/index";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ExportExcel from "./ExportExcel";
import useStyle from "./OneTenant.style";

const basicReolution = 2;


const TenantReport: React.FC = (props: any) => {
  const classes = useStyle();

  const [tenants, setTenants] = useState([]);
  const [sortByConsumption, setSortByConsumption] = useState(false);
  const [measureUnit, setMeasureUnit] = useState<string>("kWh");
  const [reports, setReports] = useState<any>({});
  const [showSpinner, setSshowSpinner] = useState<boolean>(false);
  const [filterByState, setFilterByState] = useState<string>("");
  const [graphType, setGraphType] = useState<string>("unit");
  const [canSeeGraphData, setCanSeeGraphData] = useState<boolean>(true);
  const [dataArr, setDataArr] = useState<any>([]);
  const [selectedItemId, setSelectedItemId] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<any>({ startTime: null, endTime: null });
  const [distributeExcessPower, setDistributeExcessPower] = useState<boolean>(true);

  const container: any = useRef(null);
  const graphContainer: any = useRef(null);
  // const allunits = useStoreState((s) => s.units.allUnits);
  const [allunits, setUnits] = useState<any>({});
  const getUnits = useStoreActions((a) => a.units.getAllUnits);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const getZonesByCustomerId = useStoreActions((action) => action.zones.getZonesBySiteId);
  const selections = useStoreState((s) => s.selections.selections);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const getPowerUsage = useStoreActions((action) => action.sites.getPowerUsage);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const sites = useStoreState((s) => s.sites.allSites);
  const { siteId, dateRange } = selections;
  // Set date picker on 7 days on loading screen
  useEffect(() => {
    const { dateRange } = selections;
    if (!!dateRange) {
      updateSelections({ type: "time", data: null });

    }
    getUnits().then((res: any) => {
      setUnits(res)
    })
  }, []);

  useEffect(() => {
    if (selections.siteId) {
      getZonesByCustomerId(selections.siteId)
        .then((zones: any) => {
          setTenants(zones);
        });
    }
  }, [selections.siteId]);

  useEffect(() => {
    if (!selections.dateRange || !selections.siteId) {
      return;
    }
    fetchPowerUsage(distributeExcessPower);
  }, [selections.dateRange, selections.siteId]);

  // Refresh graph data on fetch and on change system view
  useEffect(() => {
    if (_.isEmpty(reports) || (_.isEmpty(reports.zones) && _.isEmpty(reports.units))) {
      selectOption(null);
      return;
    }
    if (!dataArr?.length) {
      const tenantIds = Object.keys(reports.zones);
      if (tenantIds.length) {
        selectOption("zones", tenantIds[0]);
        return;
      }
      const sysIds = Object.keys(reports.systems);
      if (sysIds.length) {
        selectOption("systems", sysIds[0]);
        return;
      }
      const unassIds = Object.keys(reports.unassignedBySys);
      if (unassIds.length) {
        selectOption("unassigned", unassIds[0]);
        return;
      }
    }

    if (selectedItemId?.id) {
      selectOption(selectedItemId.type, selectedItemId.id);
    }

  }, [reports]);

  useEffect(() => {
    if (!selectedItemId) {
      return;
    }
    selectOption(selectedItemId.type, selectedItemId.id);
  }, [graphType]);

  useEffect(() => {
    if (!dataArr.length || graphType === "time") {
      return;
    }
    setDataArr(_.orderBy(dataArr, [sortByConsumption ? "power" : "name"], [sortByConsumption ? "desc" : "asc"]));
  }, [sortByConsumption]);

  const fetchPowerUsage = async (distributeExcessPower: boolean) => {

    if (!selections.siteId) {
      return;
    }

    let data: any;
    let startTime: any;
    let endTime: any;
    let distanceBetweenDates = "";

    setSshowSpinner(true);

    if (!selections.dateRange) {
      endTime = new Date();
      startTime = sub(endTime, { days: 7 });
    }

    if (selections.dateRange) {
      startTime = new Date(selections.dateRange.startDate);
      endTime = new Date(selections.dateRange.endDate);
    }

    // Always use round hours
    startTime = startOfHour(startOfMinute(startTime));
    endTime = startOfHour(startOfMinute(endTime));

    // Days always start at 00:00 and end at 23:59
    startTime = setHours(startTime, 0);
    endTime = setMinutes(setHours(endTime, 23), 59);
    endTime = setSeconds(endTime, 59);

    // calculate amount of time units in date - to show in graph
    distanceBetweenDates = formatDistanceStrict(startTime, endTime, { unit: "day" });

    setSelectedTime({
      startTime: moment.tz(moment(startTime).format("YYYY-MM-DD"), "gmt").startOf("day").valueOf(),
      endTime: moment.tz(moment(endTime).format("YYYY-MM-DD"), "gmt").endOf("day").valueOf()
    });
    getPowerUsage({
      siteId: selections.siteId,
      startTime: moment.tz(moment(startTime).format("YYYY-MM-DD"), "gmt").startOf("day").valueOf(),
      endTime: moment.tz(moment(endTime).format("YYYY-MM-DD"), "gmt").endOf("day").valueOf(),
      resolution: basicReolution,
      distributeExcess: distributeExcessPower
    })
      .then((data: any) => {

        if (data) {
          // create unassigned Units by systems
          const unassignedUnitsId = Object.keys(data.units);
          const unassignedUnitBySystems = _.reduce(unassignedUnitsId, (obj: any, unitId: string) => {
            const unit = allunits[unitId];
            const systemId: any = unit?.system;

            if (unit?.zones?.length) {
              return obj;
            }

            if (!obj[systemId]) {
              obj[systemId] = {};
            }
            obj[systemId][unitId] = data.units[unitId];
            return obj;
          }, {});

          data.unassignedBySys = unassignedUnitBySystems;

          setReports({ ...data });
        }
      })
      .catch((error: any) => {
        addMessage({ message: t`Something went wrong (request timeout). Please try again later` });
      })
      .finally(() => setSshowSpinner(false));
  };

  const CustomTooltip = (props: any) => {
    const isPPD = !!selectedItemId?.id && !!reports?.ppds[selectedItemId?.id];
    const { active, payload, label } = props;
    let avail: any = 100;
    const data: any = payload?.reduce((obj: any, item: any, index: any) => {
      const { name, value, fill, payload: itemData, unit } = item;

      if (itemData?.id && graphType === "unit") {
        avail = reports?.units[itemData?.id]?.availability || 100;
      }

      if (unit === "time") {
        obj.timeItems.push(<Typography key={`time-bar-${index}`} style={{ color: fill }}> {`${name}: ${Math.round(value * 10) / 10}`}</Typography>);
        obj.totalTime += value;
      }

      if (unit === "power") {
        obj.powerItems.push(<Typography key={`power-bar-${index}`} style={{ color: fill }}> {`${name}: ${Math.round(value * 10) / 10}`}</Typography>);
        obj.totalPower += value;
      }

      return obj;
    }, { totalTime: 0, totalPower: 0, powerItems: [], timeItems: [] });

    if (active) {
      return (
        <div className={classes.graphTooltip} >
          <div className={classes.tooltipHeader}>
            <Typography>
              {graphType === "time" ? moment(label).format("DD/MM") : (allunits[label]?.name || label)}
            </Typography>
            {avail && !isPPD && graphType === "unit" &&
              <Typography>
                {t`Data Availability: ` + `${Math.round(avail * 10) / 10}%`}
              </Typography>
            }
          </div>

          <div style={{
            display: "flex"
          }}>

            <div className={classes.tooltipSection}>

              {!isPPD && <Typography style={{ fontWeight: 400 }}> {t`Power ` + `(${measureUnit}): `} {Math.round(data?.totalPower * 10) / 10}</Typography>}
              {
                data?.powerItems?.length ? data?.powerItems : <span />
              }
            </div>

            {!isPPD && <div className={classes.tooltipInfoContainer}>
              <div className={classes.tooltipSection}>
                <Typography style={{ fontWeight: 400 }}>  {t`Runtime: `}{Math.round(data?.totalTime * 10) / 10}</Typography>
                {
                  data?.timeItems?.length ? data?.timeItems : <span />
                }
              </div>
            </div>
            }
          </div>
        </div>
      );
    }

    return null;
  };

  if (!isInitialized) { return <Loading />; }

  const dropDownGraphOptions = () => {
    return (
      <FormControl variant="outlined" className={classes.graphDropDown}>
        <Select
          labelId="graphOptions"
          value={graphType}
          className={classes.graphDropDownStyle}
          onChange={(event: any) => setGraphType(event.target.value)}
          IconComponent={SvgArrow}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem value={"unit"}>{t`Units View`}</MenuItem>
          <MenuItem value={"time"}>{t`Time View`}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const oneTenantUsageData = (isPPD: boolean) => {
    let obj = selectedItemId?.id && selectedItemId.type !== "unassigned" && reports[selectedItemId?.type][selectedItemId?.id];

    if (!obj && !dataArr?.length) {
      return;
    }

    if (!obj) {
      obj = dataArr.reduce((objData: any, unit: any) => {

        const {
          onTime,
          power,
          powerCool,
          powerHeat,
          powerMissing,
          powerOther,
          excessPower = 0,
          measurementUnits = "kWh"
        } = unit;

        return {
          onTime: objData.onTime += onTime,
          power: objData.power += power,
          powerCool: objData.powerCool += powerCool,
          powerHeat: objData.powerHeat += powerHeat,
          powerOther: objData.powerOther += powerOther,
          powerMissing: objData.powerMissing += powerMissing,
          excessPower: objData.excessPower += excessPower,
          measurementUnits
        };

      }, {
        onTime: 0,
        power: 0,
        powerCool: 0,
        powerHeat: 0,
        powerMissing: 0,
        powerOther: 0,
        excessPower: 0,
        measurementUnits: "kWh"
      });
    }

    const {
      onTime,
      power,
      powerCool,
      powerHeat,
      powerMissing,
      powerOther,
      measurementUnits,
      excessPower = 0
    } = obj;

    return (
      <>
        <div>
          {!isPPD &&
            <>
              <Button variant="contained" className={clsx(classes.btnUsage, classes.coolUsage, filterByState === "cool" && classes.activeCoolUsage)}
                disableRipple
                onClick={() => setFilterByState("cool")}
              >
                <Typography>
                  <span className={classes.btnUsageTextState} >{t`Cool`}</span>
                  <span className={classes.btnUsageTextDate} >{powerCool?.toFixed(1)} </span>
                  <span className={classes.btnUsageTextType} >{measurementUnits || t`kWh`}</span>
                </Typography>
              </Button>

              <Button variant="contained" className={clsx(classes.btnUsage, classes.heatUsage, filterByState === "heat" && classes.activeHeatUsage)}
                disableRipple
                onClick={() => setFilterByState("heat")}
              >
                <Typography>
                  <span className={classes.btnUsageTextState} >{t`Heat`}</span>
                  <span className={classes.btnUsageTextDate} >{powerHeat?.toFixed(1)} </span>
                  <span className={classes.btnUsageTextType} >{measurementUnits || t`kWh`}</span>
                </Typography>
              </Button>

              <Button variant="contained" className={clsx(classes.btnUsage, { [classes.otherUsageSelected]: filterByState === "other" })}
                disableRipple
                onClick={() => setFilterByState("other")}

              >
                <Typography>
                  <span className={classes.btnUsageTextState} >{t`Other`}</span>
                  <span className={classes.btnUsageTextDate} >{powerOther?.toFixed(1)} </span>
                  <span className={classes.btnUsageTextType} >{measurementUnits || t`kWh`}</span>
                </Typography>
              </Button>
              <Button variant="contained" className={clsx(classes.btnUsage, classes.missingUsage, filterByState === "missing" && classes.activeMissingUsage)}
                disableRipple
                onClick={() => setFilterByState("missing")}

              >
                <Typography>
                  <span className={classes.btnUsageTextState} >{t`Estimated`}</span>
                  <span className={classes.btnUsageTextDate} >{powerMissing?.toFixed(1)} </span>
                  <span className={classes.btnUsageTextType} >{measurementUnits || t`kWh`}</span>
                </Typography>
              </Button>
            </>}
          {!distributeExcessPower && !isPPD &&
            <Button variant="contained" className={clsx(classes.btnUsage, classes.excessUsage, filterByState === "excess" && classes.activeExcessUsage)}
              disableRipple
              onClick={() => setFilterByState("excess")}

            >
              <Typography>
                <span className={classes.btnUsageTextState} >{t`Excess`}</span>
                <span className={classes.btnUsageTextDate} >{excessPower?.toFixed(1)} </span>
                <span className={classes.btnUsageTextType} >{measurementUnits || t`kWh`}</span>
              </Typography>
            </Button>}
          <Button variant="contained" className={clsx(classes.btnUsage, filterByState === "total" && classes.activeBtnUsage)}
            disableRipple
            onClick={() => setFilterByState("")}
          >
            <Typography>
              <span className={classes.btnUsageTextState} >{t`Total`}</span>
              <span className={classes.btnUsageTextDate} >{power?.toFixed(1)} </span>
              <span className={classes.btnUsageTextType} >{measurementUnits || t`kWh`}</span>
            </Typography>
          </Button>

          {!isPPD && <Button variant="contained" className={classes.btnUsage}
            disableRipple
            onClick={() => _.noop}
          >
            <Typography>
              <span className={classes.btnUsageTextState} >{t`Runtime`}</span>
              <span className={classes.btnUsageTextDate} >{(onTime / 60)?.toFixed(1)}</span>
              <span className={classes.btnUsageTextType} >{t`Hrs`}</span>
            </Typography>
          </Button>}

        </div>
        {dropDownGraphOptions()}
      </>
    );
  };

  const labelContent = () => {
    return (
      <div>
        {!isPPD ?
          <>
            <div className={classes.graphLabelText}>
              <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconHeat)} />
              {t`Heat`}
            </div>
            <div className={classes.graphLabelText}>
              <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconCool)} />
              {t`Cool`}
            </div>
            <div className={classes.graphLabelText}>
              <div className={classes.graphLabelIcon} />
              {t`Other`}
            </div>
            <div className={classes.graphLabelText}>
              <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconMissing)} />
              {t`Estimated`}
            </div>
            {!distributeExcessPower &&
              <div className={classes.graphLabelText}>
                <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconExcess)} />
                {t`Excess`}
              </div>}
            <div className={classes.graphLabelText}>
              <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconTime)} />
              {t`Runtime`}
            </div>
          </> :
          <div className={classes.graphLabelText}>
            <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconPower)} />
            {t`Power Consumption (kWh)`}
          </div>
        }
      </div>
    );
  };

  const selectOption = (type: string | null, id?: string) => {

    if (!id || !type) {
      setSelectedItemId(null);
      setDataArr([]);
      return;
    }

    setSelectedItemId({ type, id });
    let measurementUnit: any = null;

    if (type === "unassigned" && graphType === "time") {

      const units = reports?.unassignedBySys[id] ? Object.keys(reports.unassignedBySys[id]) : [];

      const dayArrObj = units.reduce((obj: any, unitId: any) => {

        const unit = reports?.units[unitId];
        if (!unit) {
          return obj;
        }

        for (let item of unit.dayArr) {
          const {
            onTime,
            onTimeCool,
            onTimeHeat,
            onTimeMissing,
            onTimeOther,
            power,
            powerCool,
            powerHeat,
            powerMissing,
            powerOther,
            timestamp

          } = item;
          if (!obj[timestamp]) {
            obj[timestamp] = item;
          }
          else {
            obj[timestamp].onTime += onTime;
            obj[timestamp].onTimeCool += onTimeCool;
            obj[timestamp].onTimeHeat += onTimeHeat;
            obj[timestamp].onTimeMissing += onTimeMissing;
            obj[timestamp].onTimeOther += onTimeOther;
            obj[timestamp].power += power;
            obj[timestamp].powerCool += powerCool;
            obj[timestamp].powerHeat += powerHeat;
            obj[timestamp].powerMissing += powerMissing;
            obj[timestamp].powerOther += powerOther;
            obj[timestamp].timestamp += timestamp;
          }
        }
        if (!measurementUnit && unit.measurementUnits) {
          measurementUnit = unit.measurementUnits;
        }

        return obj;
      }, {});

      setDataArr(Object.values(dayArrObj));
      return;

    }
    if (graphType === "unit") {
      const units = (type === "unassigned" ? (reports?.unassignedBySys[id] ? Object.keys(reports?.unassignedBySys[id]) : []) : reports[type][id]?.units) || [];

      const unitsData = units.reduce((arr: any, unitId: any) => {
        const unitReport = reports.units[unitId];
        const unitObj = allunits[unitId];
        if (!measurementUnit && unitReport?.measurementUnits) {
          measurementUnit = unitReport.measurementUnits;
        }
        if (!unitReport || !unitObj) {
          return arr;
        }

        let unitName = unitReport?.name;
        if (+unitObj?.type === 3) {
          const controlUnitId: any = unitObj.controlUnit;
          const controlName = allunits[controlUnitId]?.name || t`Unassigned`;
          unitName = `${controlName} (${unitObj.address})`;
        }
        return [...arr, { ...unitReport, name: unitName }];
      }, []);
      setDataArr(_.orderBy(unitsData, sortByConsumption ? "power" : "name", sortByConsumption ? "desc" : "asc"));
    }
    else {
      setDataArr(reports[type][id]?.dayArr);
      measurementUnit = reports[type][id]?.measurementUnits || "kWh";
    }

    setMeasureUnit(measurementUnit || "kWh");
  };

  const getSystems = () => {
    const systems = Object.keys(reports.systems).map((sys: any) => ({ name: `${allSystems[sys]?.name} units`, id: sys, allUnitsHaveCapacity: reports.systems[sys].allUnitsHaveCapacity }));
    const sortedSystems = _.orderBy(systems, [(system: any) => system?.name?.toUpperCase()],
      ["asc"]);
    return (
      sortedSystems.map((system: any) => {
        const { id, name, allUnitsHaveCapacity } = system;

        if (selections.systemId && selections.systemId !== id) {
          return null;
        }
        const numOfUnits = reports.systems[id]?.units?.length;
        const units = system?.errorUnits?.join("\n");
        return (
          <Tooltip
            title={<span style={{ whiteSpace: "pre-line", fontSize: "14px" }}>{!allUnitsHaveCapacity ? t`Some/All of the units do not have capacity defined` + "\n" + (units || "") : `${name} (${numOfUnits} Units)`}</span>}
            key={id}
            enterDelay={200}>
            <Button
              disableRipple
              key={id}
              onClick={() => {
                selectOption("systems", id);
              }}
              className={clsx(classes.listStyle, { [classes.selectedItem]: id === selectedItemId?.id && selectedItemId?.type === "systems" }, { [classes.capacityFlagged]: !allUnitsHaveCapacity })}
            >
              {`${name} (${numOfUnits} Units)`}

            </Button>
          </Tooltip>

        );
      })
    );
  };

  const getTenants = () => {
    const tenantsArr = _.orderBy(tenants, [(tenant: any) => tenant?.name?.toUpperCase()],
      ["asc"]).map((zone: any, index: number) => {
        const allUnitsHaveCapacity = !!reports?.zones[zone?.id]?.allUnitsHaveCapacity;
        const units = reports?.zones[zone?.id]?.errorUnits?.join("\n");

        return (
          <Tooltip
            title={<span style={{ whiteSpace: "pre-line", fontSize: "14px" }}>{!allUnitsHaveCapacity ? t`Some/All of the units do not have capacity defined` + "\n" + (units || "") : `${zone.name} (${zone?.units?.length} Units)`}</span>}
            key={index}
            enterDelay={200}>
            <Button
              disableRipple
              key={index}
              onClick={() => {
                selectOption("zones", zone.id);
              }}
              className={clsx(classes.listStyle, { [classes.selectedItem]: zone.id === selectedItemId?.id, [classes.capacityFlagged]: !allUnitsHaveCapacity })}
            >
              {`${zone.name} (${zone?.units?.filter((u: any) => !!reports?.units[u])?.length} Units)`}
            </Button>
          </Tooltip>

        );
      });

    return tenantsArr;
  };

  const getPPDs = () => {
    const tenantsArr = _.orderBy(Object.values(reports.ppds), [(ppd: any) => ppd?.name?.toUpperCase()],
      ["asc"]).map((ppd: any, index: number) => {

        return (
          <Button
            disableRipple
            key={index}
            onClick={() => {
              selectOption("ppds", ppd.id);
              setFilterByState("");
            }}
            className={clsx(classes.listStyle, { [classes.selectedItem]: ppd.id === selectedItemId?.id })}
          >
            {`${ppd.name} (${ppd?.units?.length} Units)`}
          </Button>

        );
      });

    return tenantsArr;
  };
  const getUnAssignedUnits = () => {
    const systems = Object.keys(reports.unassignedBySys).map((sys: any) => ({ id: sys, name: `${allSystems[sys]?.name}  unassigned units` }));
    const sorted = _.orderBy(systems, [(system: any) => system?.name?.toUpperCase()],
      ["asc"]);
    return (
      sorted.map((system: any) => {
        const { id, name } = system;
        if (selections.systemId && id !== selections.systemId) {
          return null;
        }

        return (
          <Button
            disableRipple
            key={id}
            onClick={() => {
              selectOption("unassigned", id);
            }}
            className={clsx(classes.listStyle, { [classes.selectedItem]: selectedItemId?.id === id && selectedItemId?.type === "unassigned" })}
          >
            {name}
          </Button>
        );
      })
    );
  };

  const numOfZones = Object.keys(reports?.zones || {})?.length || 0;
  const numberOfPPDs = Object.keys(reports?.ppds || {})?.length || 0;
  const numberOfSystems = Object.keys(reports?.systems || {})?.length || 0;
  const numberOfUnUssignedSystems = Object.keys(reports?.unassignedBySys || {})?.length || 0;
  const isPPD = !!selectedItemId?.id && !!reports?.ppds[selectedItemId?.id];

  const getHoursVal = (x: any, name: any) => {
    if (name === "label") {
      return 0;
    }
    return x[name] / 60;
  };

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props;
    const radius = 10;

    if (value < 0.3) {
      return <span />;
    }

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="10px"
          fontWeight="bold"
        >
          {value && value !== 0 ? Math.round(value * 10) / 10 : ""}
        </text>
      </g>
    );
  };

  const totalLabelNames = { time: "onTime", power: "power" };

  switch (filterByState) {
    case "heat":
      totalLabelNames.time = "onTimeHeat";
      totalLabelNames.power = "powerHeat";
      break;

    case "cool":
      totalLabelNames.time = "onTimeCool";
      totalLabelNames.power = "powerCool";
      break;

    case "other":
      totalLabelNames.time = "onTimeOther";
      totalLabelNames.power = "powerOther";
      break;

    case "missing":
      totalLabelNames.time = "onTimeMissing";
      totalLabelNames.power = "powerMissing";
      break;

    case "excess":
      totalLabelNames.time = "";
      totalLabelNames.power = "excessPower";
      break;

    default:
      totalLabelNames.time = "onTime";
      totalLabelNames.power = "power";
      break;
  }

  const needScroll = container?.current?.offsetWidth < 80 * dataArr.length;

  const siteObj: any = selections.siteId && sites[selections.siteId];
  const formatter = siteObj?.powerReportStructure;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={[t`Power Distribution`]}
          showDateRangePicker={true}
          hideUnitSelection={true}
          hideSystemSelection={true}
          customGeneralNames={{ site: t`Select Site` }}
          screenTitle="powerDistribution"
          noRangeLimit={true}
        />
        {(!siteId || !dateRange) ? (
          <FilterRequire type={t`site and date range`} />
        ) : (
          <Grid container={true} className={classes.tableContainer}>
            {showSpinner &&
              <div className={classes.spinner}>
                <CircularProgress size={80} />
              </div>
            }
            <div className={classes.paperLeftContainer} >
              <div className={classes.scrollContainer}>
                <div className={classes.HeaderHolder}>
                  <Typography className={classes.reportTitle}>{t`Tenants`}</Typography>
                  <Typography className={classes.reportCounterStyle}>{numOfZones}</Typography>
                </div>
                {!!Object.keys(reports).length && getTenants()}

                <div className={clsx(classes.HeaderHolder, classes.addTopMargin)}>
                  <Typography className={classes.reportTitle}>{t`Power Meters`}</Typography>
                  <Typography className={classes.reportCounterStyle}>{numberOfPPDs}</Typography>
                </div>
                {!!Object.keys(reports).length && getPPDs()}

                <div className={clsx(classes.HeaderHolder, classes.addTopMargin)}>
                  <Typography className={classes.reportTitle}>{t`Systems`}</Typography>
                  <Typography className={classes.reportCounterStyle}>{numberOfSystems}</Typography>
                </div>
                {!!Object.keys(reports).length && getSystems()}

                <div className={clsx(classes.HeaderHolder, classes.addTopMargin)}>
                  <Typography className={classes.reportTitle}>{t`Un-Assigned Units`}</Typography>
                  <Typography className={classes.reportCounterStyle}>{numberOfUnUssignedSystems}</Typography>
                </div>
                {!!Object.keys(reports).length && getUnAssignedUnits()}
              </div>
              <div className={classes.borderLine} />
            </div>

            <div className={classes.rightContainer} >
              <div className={classes.headerContainer}>
                <Typography className={classes.reportTitle} >{t`Detailed Analysis`}</Typography>
                {!!selections.siteId && !showSpinner &&
                  <ExportExcel
                    pageData={reports}
                    selections={selections}
                    chosenSystem={"allSystems"}
                    selectedTime={selectedTime}
                    zones={tenants}
                    getPowerUsage={getPowerUsage}
                    addMessage={addMessage}
                    basicReolution={basicReolution}
                  />}
              </div>
              <div id="divider" className={classes.divider} />
              {!canSeeGraphData &&
                <Typography className={classes.capacityFlaggedTitle} >{t`Missing Unit/s Capacity Values. Please set in Site Management`}</Typography>
              }
              <div className={classes.chipContainer}>
                {dataArr?.length > 0 && oneTenantUsageData(isPPD)}
              </div>
              <div className={classes.graphSection}>
                <div className={classes.graphContainer} ref={graphContainer}>
                  {needScroll &&
                    <IconButton
                      onClick={() => {
                        container?.current?.scrollTo({
                          top: 0,
                          left: container?.current.scrollLeft - 150,
                          behavior: "smooth"
                        });

                      }}>
                      <ChevronLeft style={{
                        fontSize: "40px"
                      }} />
                    </IconButton>
                  }
                  <ScrollContainer innerRef={container} vertical={false} style={{ display: "inline-block", width: needScroll ? "80%" : "90%", height: "100%", overflowY: "hidden" }} hideScrollbars={false} >
                    <ResponsiveContainer width={Math.max((150 * dataArr.length), (graphContainer?.current?.offsetWidth * 0.85))} height="100%">
                      <BarChart
                        data={dataArr}
                        barCategoryGap={"16%"}
                        barGap={"4"}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 10,
                          bottom: 50
                        }}
                        style={{ minWidth: "100%" }}
                        barSize={30}

                      >
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey={graphType === "time" ? "timestamp" : "id"}
                          tickFormatter={(val: any) => graphType === "time" ? moment(val).format("DD/MM") : (formatter ? `${allunits[val]?.name} (${allunits[val]?.serialNumber || t`undefined`})` : allunits[val]?.name)}
                          tick={{ fontSize: '12px', fontWeight: 400, wordWrap: 'break-word', width: 120 }}
                          interval={0}
                          angle={-30}
                          textAnchor="end"
                        />
                        <YAxis
                          yAxisId={"power"}
                          domain={dataArr?.length ? ["0", "dataMax+5"] : ["0", "0"]}
                          tickFormatter={(value: any) => Math.round(value * 10) / 10}
                          tick={{ fontSize: "11px", fontWeight: 400 }}
                          label={{
                            value: t`Power Consumption (kWh)`,
                            angle: -90,
                            position: "right",
                            offset: -30,
                            fontSize: "10px",
                            fontWeight: "bold",
                            style: { textAnchor: "middle" }
                          }}
                        />
                        {!isPPD &&
                          <YAxis
                            yAxisId={"time"}
                            domain={dataArr?.length ? ["0", "dataMax+5"] : ["0", "0"]}
                            orientation="right" tickFormatter={(value: any) => Math.round(value * 10) / 10}
                            tick={{ fontSize: "11px", fontWeight: 400 }}
                            label={{
                              value: t`Runtime`,
                              angle: 90,
                              position: "right",
                              offset: -30,
                              fontSize: "10px",
                              fontWeight: "bold",
                              style: { textAnchor: "middle" }
                            }}
                          />}

                        {dataArr?.length && <RechartsToolTip content={<CustomTooltip />} cursor={{ fill: "rgba(128,128,128,0.1)" }} />}
                        {isPPD &&
                          <Bar
                            yAxisId={"power"}
                            dataKey={"power"}
                            stackId="a"
                            name={t`Power`}
                            fill="#666"
                            unit="power"
                            maxBarSize={60}
                          />}
                        {(!isPPD && !filterByState || filterByState === "cool") && <Bar
                          yAxisId={"power"}
                          dataKey={"powerCool"}
                          name={t`Cool`}
                          stackId="a"
                          fill="#35a8e0"
                          unit="power"
                          maxBarSize={60}
                        />}
                        {(!isPPD && !filterByState || filterByState === "heat") &&
                          <Bar
                            yAxisId={"power"}
                            dataKey="powerHeat"
                            name={t`Heat`}
                            stackId="a"
                            fill="#f05347"
                            unit="power"
                            maxBarSize={60}

                          />}
                        {(!isPPD && !filterByState || filterByState === "other") && <Bar
                          yAxisId={"power"}
                          dataKey="powerOther"
                          name={t`Other`}
                          stackId="a"
                          fill="#aaa2aa"
                          unit="power"
                          maxBarSize={60}

                        />}
                        {(!isPPD && !filterByState || filterByState === "missing") && <Bar
                          yAxisId={"power"}
                          dataKey="powerMissing"
                          name={t`Estimated`}
                          stackId="a"
                          fill="#7a6095"
                          unit="power"
                          maxBarSize={60}

                        />}
                        {((!isPPD && !filterByState || filterByState === "excess") && !distributeExcessPower) && <Bar
                          yAxisId={"power"}
                          dataKey="excessPower"
                          name={t`Excess`}
                          stackId="a"
                          fill="#4b1c46"
                          unit="power"
                        />}
                        <Bar dataKey={(item: any) => getHoursVal(item, "label")}
                          yAxisId={"power"} name={t`total`} stackId="a" fill="#7a6095"
                          maxBarSize={60}
                        >
                          <LabelList dataKey={totalLabelNames?.power} content={renderCustomizedLabel} />
                        </Bar>

                        {(!isPPD && !filterByState || filterByState === "cool") &&
                          <Bar
                            yAxisId={"time"}
                            dataKey={(item: any) => getHoursVal(item, "onTimeCool")}
                            name={t`Cool`}
                            stackId="b"
                            fill="#5e5764"
                            unit="time"
                            maxBarSize={60}
                          />}
                        {(!isPPD && !filterByState || filterByState === "heat") &&
                          <Bar
                            yAxisId={"time"}
                            dataKey={(item: any) => getHoursVal(item, "onTimeHeat")}
                            name={t`Heat`}
                            stackId="b"
                            fill="#5e5764"
                            unit="time"
                            maxBarSize={60}
                          />}
                        {(!isPPD && !filterByState || filterByState === "other") &&
                          <Bar
                            yAxisId={"time"}
                            dataKey={(item: any) => getHoursVal(item, "onTimeOther")}
                            name={t`Other`}
                            stackId="b"
                            fill="#5e5764"
                            unit="time"
                            maxBarSize={60}
                          />}
                        {(!isPPD && !filterByState || filterByState === "missing") &&
                          <Bar
                            yAxisId={"time"}
                            dataKey={(item: any) => getHoursVal(item, "onTimeMissing")}
                            name={t`Estimated`}
                            stackId="b"
                            fill="#5e5764"
                            unit="time"
                            maxBarSize={60}
                          />}
                        {!isPPD &&
                          <Bar dataKey={(item: any) => getHoursVal(item, "label")} name={t`total`}
                            yAxisId={"time"} stackId="b" fill="#5e5764" maxBarSize={60}
                          >
                            <LabelList dataKey={(item: any) => getHoursVal(item, totalLabelNames?.time)} content={renderCustomizedLabel} />
                          </Bar>
                        }

                      </BarChart>
                    </ResponsiveContainer>
                  </ScrollContainer>

                  {needScroll &&
                    <IconButton
                      onClick={() => {
                        container?.current?.scrollTo({
                          top: 0,
                          left: container?.current.scrollLeft + 150,
                          behavior: "smooth"
                        });

                      }}>
                      <ChevronRight style={{
                        fontSize: "40px"
                      }} />
                    </IconButton>
                  }

                </div>
                <div className={classes.legendContainer} >
                  {labelContent()}
                  {graphType === "unit" &&
                    <InputLabel className={clsx(classes.itemName, classes.graphCheckbox, { [classes.itemNameSelected]: distributeExcessPower })}>
                      <Checkbox
                        checked={sortByConsumption}
                        onChange={({ target: { checked } }: any) => {
                          setSortByConsumption(checked);
                        }}
                        checkedIcon={<CheckboxChecked />}
                        className={classes.smallCheckbox}
                        color="default"
                        variant="outlined"
                        edge="end"
                      />
                      {t`Sort By Consumption`}
                    </InputLabel>}
                  <InputLabel className={clsx(classes.itemName, classes.graphCheckbox, { [classes.itemNameSelected]: distributeExcessPower })}>
                    <Checkbox
                      checked={distributeExcessPower}
                      onChange={({ target: { checked } }: any) => {
                        setDistributeExcessPower(checked);
                        if (selections.siteId) {
                          fetchPowerUsage(checked);
                        }
                        if (!checked && filterByState === "excess") {
                          setFilterByState("");
                        }
                      }}
                      checkedIcon={<CheckboxChecked />}
                      className={classes.smallCheckbox}
                      color="default"
                      variant="outlined"
                      edge="end"
                    />
                    {t`Distribute Excess Power to all units`}
                  </InputLabel>
                </div>
              </div>
            </div>
          </Grid>
        )}
      </div>

    </div>
  );
};

export default TenantReport;
