import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { PlayArrow, Stop } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { ApplyToDialog } from "../../components/AddEditScript";
import Delete from "../../components/Delete/Delete";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { Download } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { toC, toF } from "../../services/converter";
import { checkDateInRange } from "../../services/timeService";
import useStyles from "./CommissioningLogs.style";

const ScriptsList: React.FC = (props: any) => {
  const classes = useStyles();

  const isInitialized = useStoreState((s) => s.isInitialized);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allSites = useStoreState((s) => s.sites.allSites);
  const selections = useStoreState((state) => state.selections.selections);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const types = useStoreState((state) => state.types);
  const user = useStoreState((s) => s.users.me);
  const stepResultStatus = useStoreState((s) => s.stepResultStatus);
  const reportResults = useStoreState((s) => s.reportResults);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const procedureStatuses = useStoreState((state) => state.procedureStatuses);
  const setCallBacks = useStoreActions((action) => action.setCallBacks);
  const resetCallBacks = useStoreActions((action) => action.resetCallBacks);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);

  const {
    getProcedureInstance,
    stopProcedureInstance,
    deleteCommissioningReport,
    getProceduresInstances,
    getCommissioningReportsBySite,
    getSiteScripts,
    runProcedure
  } = useStoreActions((action) => action.scripts);
  const { stepsTypesMirror, commandsOptions, conditionsOptions }: any = useStoreState((s) => s);
  const { temperatureScale = 1 } = user;
  const { procedureStepTypes, procedureRunningStates } = types;

  const [reports, setReports] = useState<any>([]);
  const [siteTimezone, setSiteTimezone] = useState<any>(moment.tz.guess());
  const [reportsObject, setReportsObject] = useState<any>({});
  const [procedures, setProcedures] = useState<any>({});
  const [proceduresInstances, setProceduresInstances] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [scriptToFetch, setScriptToFetch] = useState<any>("");
  const [selectedReRun, setSelectedReRun] = useState<string>("");
  const { customerId, siteId } = selections;
  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canRunProcedures, canDeleteProcedureReports } = permissions || {};

  useEffect(() => {
    resetCallBacks();
    setCallBacks({ messageName: "PROCEDURE_INSTANCE_UPDATED", func: setScriptToFetch });
  }, []);

  useEffect(() => {
    if (!siteId || !selections.dateRange || !scriptToFetch) {
      return;
    }

    if (siteId !== scriptToFetch.siteId) {
      return;
    }

    if (proceduresInstances[scriptToFetch.procedureInstanceId]) {
      const updatedScript = _.cloneDeep(proceduresInstances[scriptToFetch.procedureInstanceId]);
      updatedScript.runningState = scriptToFetch.state;
      proceduresInstances[updatedScript.id] = updatedScript;
      setProceduresInstances({ ...proceduresInstances });
    } else {
      getProcedureInstance({ id: scriptToFetch.procedureInstanceId, type: 1 })
        .then((instance: any) => {
          instance.timezone = siteTimezone;
          proceduresInstances[instance.id] = instance;
          setProceduresInstances({ ...proceduresInstances });
        });
    }

    const isToday = moment(selections?.dateRange?.endDate).isSame(moment(new Date()), "day");

    if (scriptToFetch.status !== 2 && isToday) {
      // const { [scriptToFetch.procedureInstanceId]: toDelete, ...newProceduresInstances } = proceduresInstances;
      // setProceduresInstances(newProceduresInstances);
      const { dateRange } = selections;
      const { startDate, endDate } = dateRange || {};

      const startTime = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) - 54000000;
      const endTime = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59) + 54000000;

      getCommissioningReportsBySite({ id: siteId, data: { startTime, endTime, type: 1 } })
        .then((response: any) => {
          Object.values(response).forEach((report: any) => {
            const { id, startTime } = report;

            if (!checkDateInRange(moment(startDate).format("MM/DD/YYYY"),
              moment(endDate).format("MM/DD/YYYY"),
              moment(startTime).tz(siteTimezone).format("MM/DD/YYYY"))
            ) {
              delete response[id];
            }
            report.timezone = siteTimezone;
          });

          setReportsObject(response);
        });
    }

  }, [scriptToFetch]);

  useEffect(() => {
    if (selections.dateRange) {
      return;
    }
    updateSelections({
      type: "time",
      data: {
        startDate: new Date(new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date()
      }
    });

  }, [selections.dateRange]);

  useEffect(() => {
    setReports(_.orderBy(reportsObject, ["startTime"], ["desc"]));
  }, [reportsObject]);

  useEffect(() => {
    if (!selections.dateRange) {
      return;
    }

    const { dateRange } = selections;
    const { startDate, endDate } = dateRange || {};

    if (!siteId) {
      setLoading(false);
      return;
    }

    const startTime = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) - 54000000;
    const endTime = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59) + 54000000;
    setLoading(true);
    const { timezone = "" } = allSites[siteId || ""];
    setSiteTimezone(timezone || moment.tz.guess());

    Promise.all([getSiteScripts({ siteId, type: 1 }),
    getCommissioningReportsBySite({ id: siteId, data: { startTime, endTime, type: 1 } }),
    getProceduresInstances({ siteId, type: 1 })])
      .then((response: any) => {
        Object.values(response[1]).forEach((report: any) => {
          const { id, startTime } = report;
          if (!checkDateInRange(moment(startDate).format("MM/DD/YYYY"),
            moment(endDate).format("MM/DD/YYYY"),
            moment(startTime).tz(siteTimezone).format("MM/DD/YYYY"))
          ) {
            delete response[1][id];
          }
          report.timezone = siteTimezone;
        });

        Object.values(response[2]).forEach((instance: any) => {
          instance.timezone = siteTimezone;
        });

        setProcedures(response[0]);
        setReportsObject(response[1]);
        setProceduresInstances(response[2]);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      })
      .finally(() => setLoading(false));
  }, [selections.dateRange, siteId]);

  const runNewProcedureInstance = (id: string, units: any, systems: any) => {

    runProcedure({ id, data: { units, systems } })
      .then(() => {
        setSelectedReRun("");
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };

  const deleteCurrentReport = (id: string) => {
    return deleteCommissioningReport(id)
      .then(() => {
        delete reportsObject[id];
        setReportsObject({ ...reportsObject });
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const stop = (id: string) => {
    return stopProcedureInstance(id)
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const download = (report: any) => {
    const { results = {}, resultsSummary, startTime, endTime, procedure, timezone } = report;

    const startDate = moment(startTime).tz(timezone).format(`${dateFormat} ${timeFormat}`);
    const endDate = moment(endTime).tz(timezone).format(`${dateFormat} ${timeFormat}`);
    const reportName = `${procedures[procedure]?.name || ""}.${startDate}-${endDate}`;
    const headers = ["Type", "Action", "Operator", "Value", "Result", "Date/Time"].join(",") + "\r\n";
    let rows = "";

    Object.keys(results).forEach((unitId: string) => {
      const unit = allUnits[unitId];

      if (!unit) {
        return;
      }

      rows += rows ? "\r\n" : "";
      rows += unit?.name + "\r\n" + headers;

      report.results[unitId].forEach((step: any) => {
        let { type, action, condition, command, value, result, timestamp = "", scale, operator } = step;

        if (type === procedureStepTypes.wait) {
          value = value / 60000 + " (minutes)";
        } else {
          if ((scale) && temperatureScale !== scale) {
            const scaleUnit = temperatureScale === 2 ? " (°F)" : " (°C)";
            value = (temperatureScale === 1 ? toC(+value) : toF(+value)) + scaleUnit;
          } else if (scale) {
            const scaleUnit = temperatureScale === 2 ? " (°F)" : " (°C)";
            value = value + scaleUnit;
          }
          else {
            const enumText: any = command ? commandsOptions[command]?.enum :
              condition ? conditionsOptions[condition]?.enum : "";
            const enumValues = types[enumText] || {};
            value = enumValues[value] || value;
          }
        }

        const row: any = [
          stepsTypesMirror[type] || "-",
          conditionsOptions[condition || command]?.name || "-",
          operator,
          value,
          stepResultStatus[result],
          timestamp ? moment(timestamp).tz(timezone).format(`${dateFormat}   ${timeFormat}`) : ""

        ];

        rows += row.join(",") + "\r\n";
      });
    });

    let link = window.document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(rows || "no data"));
    link.setAttribute("download", `${reportName}.csv`);
    link.click();
  };

  if (!isInitialized) { return <Loading />; }

  const selectedReport = reports.filter((report: any) => report.procedure === selectedReRun)[0];

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          showDateRangePicker
          screenTitle="commissioningLogs"
        />
        {!siteId ? <FilterRequire type={t`site`} /> : <>
          <div className={classes.headerButtons}>
          </div>
          {loading ?
            <Paper elevation={0} className={clsx(classes.paperTableContainer, classes.loaderPaper)}>
              <div className={classes.loaderContainer}>
                <CircularProgress />
                <Typography variant="h5">{t`Loading Reports`}</Typography>
              </div>
            </Paper>
            : <>
              <Paper elevation={0} className={clsx(classes.paperTableContainer, classes.halfTable)}>
                <Typography className={classes.title}>{t`Running Procedures`}</Typography>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Name`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Status`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Date/Time`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`stop`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.orderBy(proceduresInstances, ["createdAt"], ["desc"]).map((script: any) => {
                        const isScriptStopped = script.runningState === procedureRunningStates["stopped"] || script.runningState === procedureRunningStates["completedSuccess"] || script.runningState === procedureRunningStates["completedPartialSuccess"] || script.runningState === procedureRunningStates["completedFailure"];
                        const createdAtFormatted = (script.timezone ? moment(script.createdAt).tz(script.timezone) : moment(script.createdAt))?.format(`${dateFormat}   ${timeFormat}`);

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={`script-${script.id}`}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"
                            >
                              {procedures[script?.procedure]?.name || ""}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"
                            >
                              {procedureStatuses[script?.runningState] || ""}
                            </TableCell>
                            <TableCell
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"

                            >
                              {createdAtFormatted}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"
                            >
                              <IconButton disableRipple disabled={isScriptStopped || !canRunProcedures} onClick={() => stop(script.id)} className={classes.iconBtnStyle}>
                                <Stop className={clsx(classes.iconColor, { [classes.disabledIcon]: isScriptStopped })} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <Paper elevation={0} className={classes.paperTableContainer}>
                <Typography className={classes.title}>{t`Procedures Reports`}</Typography>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Procedure`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Status`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Start Date/Time`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`End Date/Time`}</TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.tableHeadCell, classes.iconsColumnWidth) }}
                          align="left"
                        >{t`DOWNLOAD`}</TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.tableHeadCell, classes.iconsColumnWidth) }}
                          align="left"
                        >{t`RERUN`}</TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.tableHeadCell, classes.iconsColumnWidth) }}
                          align="left"
                        >{t`REMOVE`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports.map((report: any) => {
                        const procedure = procedures[report.procedure] || {};
                        const { permissions: { canDelete = false } = {}, name = "" } = procedure;
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={`row-${report.id}`}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: classes.overWritePadding }}
                              align="left"
                            >
                              {name}
                            </TableCell>
                            <TableCell classes={{ root: clsx(classes.overWritePadding) }} align="left">
                              {reportResults[report?.resultsSummary] || ""}
                            </TableCell>
                            <TableCell classes={{ root: clsx(classes.overWritePadding, classes.timeWidth) }} align="left">
                              {moment(report.startTime).tz(report.timezone).format(`${dateFormat}   ${timeFormat}`)}
                            </TableCell>
                            <TableCell classes={{ root: clsx(classes.overWritePadding, classes.timeWidth) }} align="left">
                              {moment(report.endTime).tz(report.timezone).format(`${dateFormat}   ${timeFormat}`)}
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="center">
                              <IconButton disableRipple onClick={() => download(report)} className={classes.iconBtnStyle}>
                                <Download style={{ fontSize: 21 }} />
                              </IconButton>
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="center">
                              <IconButton disabled={!canRunProcedures} disableRipple onClick={() => setSelectedReRun(report.procedure)} className={classes.iconBtnStyle}>
                                <PlayArrow className={classes.iconColor} />
                              </IconButton>
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="center" style={{ opacity: !canDelete ? 0.4 : 1 }}>
                              <Delete
                                disabled={!canDeleteProcedureReports || !canDelete}
                                type={t`commissioning report`}
                                object={{ id: report.id, name }}
                                detach={() => deleteCurrentReport(report.id)}
                              ></Delete>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {
                selectedReRun && <ApplyToDialog
                  close={() => setSelectedReRun("")}
                  onSave={runNewProcedureInstance}
                  brand={procedures[selectedReRun]?.userSelections?.brand}
                  customerId={customerId}
                  siteId={siteId}
                  procedureId={procedures[selectedReRun]?.id}
                  selectedUnits={Object.keys(selectedReport?.results || {})}
                />
              }
            </>}
        </>}
      </div>
    </div>
  );
};

export default ScriptsList;
