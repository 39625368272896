import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import ArrowIcon from "../../icons/ArrowLong";
import useStyles from "./AlthermaUnitSettings.style";

const EmptyScreen = ({message, showIcon = false, loader = false}: any) => {
    const classes = useStyles();

    return (
      <Grid container direction={"column"} className={classes.noContentContainer}>
        {showIcon && <div className={classes.grayOval}>
          <ArrowIcon className={classes.arrowIcon} />
        </div>}
        {loader && <CircularProgress />}
        {message && <Typography className={classes.noUnitText}>
        {message}
        </Typography>}
      </Grid>
    );
};

export default EmptyScreen;
