import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Slide,
} from "@material-ui/core/";
import { Close, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isIOS } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import scheduletypes from "../../constants/scheduletypes";
import { OnGrey as PowerIcon } from "../../icons";
import { ArrowBack, MobileLogo } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { SearchIcon } from "../../svgComponents";
import FilterRequire from "../MobileFilterRequire/FilterRequire";
import useStyle from "./Control.style";
import { GroupItem, GroupPanel, PowerMeterPanel, UnitPanel } from "./ControlComponents";
import { SensorPanel } from "./ControlComponents/SensorPanel";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Control = () => {
  const classes = useStyle();
  const history = useHistory();
  const types = useStoreState((state) => state.types);
  const setActiveSetpoint = useStoreActions((state) => state.units.setActiveSetpoint);
  const fetchAllUnits = useStoreActions((state) => state.units.getUnits);
  const fetchSiteUnits = useStoreActions((action) => action.units.fetchSiteUnits);
  const fetchSiteSensors = useStoreActions((action) => action.sensors.getSiteSensors);
  const setActiveFanMode = useStoreActions((state) => state.units.setUnitActiveFanMode);
  const setActiveSwingMode = useStoreActions(
    (state) => state.units.setUnitActiveSwingMode
  );
  const setActiveOperationMode = useStoreActions(
    (state) => state.units.setActiveOperationMode
  );
  const setActiveOperationStatus = useStoreActions(
    (state) => state.units.setUnitActiveOperationStatus
  );
  const getSiteFullAddress = useStoreState(
    (state) => state.sites.getSiteFullAddress
  );
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const getSiteGroups = useStoreActions((actions) => actions.groups.getSiteGroups);

  const {
    fanModesMirror,
    operationStatusesMirror,
    operationModesMirror,
    swingModesMirror,
    temperatureScaleMirror
  } = useStoreState((state) => state);

  const user = useStoreState((state) => state.users.me);

  const {
    getControlUnitsBySite,
    getSitePowerMeters
  } = useStoreState((state) => state.selections);

  const selections = useStoreState((state) => state.selections.mobileSelections);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const updateUnit = useStoreActions(
    (a) => a.units._storeUpdateUnit
  );
  const getSite = useStoreState((state) => state.sites.getSite);

  const { allUnits } = useStoreState((s) => s.units);
  const { allSensors } = useStoreState((s) => s.sensors);
  const { changeSitePower } = useStoreActions((state) => state.sites);
  const { getTemperatureScaleDisplay } = useStoreState((s) => s.users);
  const userPreferences = useStoreState((s) => s.users.userPreferences);
  const updateUserPreferences = useStoreActions((a) => a.users.updateUserPreferences);
  const allPowerMeters = useStoreState((state) => state.powerMeters.allPowerMeters);
  const fetchSitePowerMeters = useStoreActions((state) => state.powerMeters.fetchSitePowerMeters);
  const getSensors = useStoreActions((state) => state.sensors.getAllSensors);
  const getUserPreferences = useStoreActions((a) => a.users.getUserPreferences);
  const setUserPreferences = useStoreActions((a) => a.users.setUserPreferences);
  const { allSites } = useStoreState((s) => s.sites);
  const { professionalLanguage: language } = userPreferences;

  const { temperatureScale = 0 } = user;
  const { sensorTypes, sensorMeasurementUnits } = types;
  const isCelsius = +temperatureScaleMirror.celsius === +temperatureScale;
  const { siteId, customerId } = selections;

  const [allGroups, setAllGroups] = useState<any>({});
  const [searchValue, setSearchValue] = useState<any>("");
  const [newSitePowerInfo, setNewSitePowerInfo] = useState<any>(null);
  const [fullSiteGroups, setFullSiteGroups] = useState<any>({});
  const [filteredUnitsGroups, setFilteredUnitsGroup] = useState<any>({});
  const [siteGroupsArray, setSiteGroupsArray] = useState<any>([]);
  const [filteredSiteGroupsArray, setFilteredSiteGroupsArray] = useState<any>([]);
  const [allSchedules, setAllSchedules] = useState<any>({});
  const [unitsTogroupsMapping, setUnitsToGroupsMapping] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({
    groupId: "",
    unitId: "",
    sensorId: "",
    isGroup: true
  });
  const [externalTemp, setExternalTemp] = useState<any>({ C: null, F: null });
  const [openSchedulesPanel, handleSchedulesPanel] = useState<boolean>(false);
  const [siteOffset, setSiteOffset] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [preferencesLoaded, setPreferencesLoaded] = useState<boolean>(false);
  let timer: any = null;

  const onSelectItem = (siteId: any, itemSelectedIds: any) => {
    handleSchedulesPanel(false);
    setSelectedItem(itemSelectedIds);
  };

  useEffect(() => {
    if (_.isEmpty(userPreferences) && !preferencesLoaded) {
      getUserPreferences().then((res: any) => {
        setUserPreferences(res);
        setPreferencesLoaded(true);
      })
        .catch(() => setPreferencesLoaded(true));
    } else {
      setPreferencesLoaded(true);
    }
    getSensors()
  }, []);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    getCustomerSchedules({ id: customerId, type: scheduletypes.control })
      .then((res: any) => setAllSchedules(res))
      .catch((err: any) => addMessage({ message: err.message }));
  }, [customerId, temperatureScale]);

  useEffect(() => {
    if (!siteId) {
      clearInterval(timer);
      return;
    }

    const customerObj = allCustomers[customerId];
    const shouldNotUseWebsocket = customerObj && customerObj?.useWebsocket === false;

    if (!shouldNotUseWebsocket) {
      clearInterval(timer);
      return;
    }

    timer = setInterval(() => {
      fetchSiteUnits(siteId);
      fetchSiteSensors(siteId);
      fetchSitePowerMeters(siteId);
    }, 15000);

    return () => clearInterval(timer);

  }, [siteId]);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    fetchSiteUnits(siteId);
    fetchSiteSensors(siteId);
    fetchSitePowerMeters(siteId);
  }, [siteId]);

  const changeSearchValue = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);

    if (_.isEmpty(siteGroupsArray)) {
      return;
    }

    if (!value) {
      setFilteredSiteGroupsArray(siteGroupsArray);
      setFilteredUnitsGroup({ ...fullSiteGroups });
      return;
    }
    const { groupsObjectF, groupsArrayF } = filterUnits(value, siteGroupsArray, fullSiteGroups);
    setFilteredUnitsGroup(groupsObjectF);
    setFilteredSiteGroupsArray(groupsArrayF);
  };

  const filterUnits = (value: string, siteGroupsArray: any, fullSiteGroups: any) => {
    const lowCaseValue = value.toLowerCase();
    let newFilteredData = Object.create(filteredUnitsGroups);
    let filteredGroupsArray: any = [];

    siteGroupsArray.forEach((group: any) => {
      const groupId = group[0];
      const { units, name, powerMeters = [], itemsOrder = [] } = fullSiteGroups[groupId];
      const unitsMap = new Map();
      let atleastOneUnitMatch = false;
      const powerMetersMap = new Map();

      itemsOrder.forEach((unit: any) => {
        if (allUnits[unit[0]]) {
          if ((allUnits[unit[0]]?.name)?.toLowerCase().includes(lowCaseValue)) {
            atleastOneUnitMatch = true;
            unitsMap.set(unit[0], true);
            return;
          }
        }
        if (allSensors[unit[0]]) {
          if ((allSensors[unit[0]]?.name)?.toLowerCase().includes(lowCaseValue)) {
            atleastOneUnitMatch = true;
            unitsMap.set(unit[0], true);
            return;
          }
        }

        unitsMap.set(unit[0], false);
      });

      powerMeters.forEach((item: any) => {
        if ((allPowerMeters[item[0]]?.name).toLowerCase().includes(lowCaseValue)) {
          atleastOneUnitMatch = true;
          powerMetersMap.set(item[0], true);
          return;
        }
        powerMetersMap.set(item[0], false);
      });

      const groupMatchSearchValue: any = (fullSiteGroups[groupId]?.name || "").toLowerCase().includes(lowCaseValue);
      newFilteredData[groupId] = { ...newFilteredData[groupId], id: groupId, name, itemsOrder: Array.from(unitsMap), powerMeters: Array.from(powerMetersMap) };
      (atleastOneUnitMatch || groupMatchSearchValue) && filteredGroupsArray.push([groupId, atleastOneUnitMatch ? false : true]);
    });

    return { groupsObjectF: newFilteredData, groupsArrayF: filteredGroupsArray };
  };

  useEffect(() => {
    setFilteredSiteGroupsArray([]);
    setSelectedItem({
      unitId: "",
      sensorId: "",
      groupId: "",
      type: "",
      isGroup: false
    });
    handleSchedulesPanel(false);

    if (!siteId || !preferencesLoaded) {
      return;
    }

    Promise.all([getSiteGroups(siteId)])
      .then((res: any) => {
        const allGroups = res[0];

        const siteSensors: any = {};

        Object.values(allSensors).forEach((sensor: any) => {
          if (sensor.site === siteId && sensorTypes[sensor?.type].enableView) {
            siteSensors[sensor.id] = sensor;
          }
        });

        const sensors = _.orderBy(siteSensors, [(sensor: any) => sensor?.name], ["asc"]);
        const storedUnitOrders = userPreferences?.serviceStoredUnitOrders || {};
        const specificSiteUnitOrder = storedUnitOrders[siteId] || {};
        const allUnitsOrder = new Map(specificSiteUnitOrder["allUnits"] || []);
        const allPowerMetersOrder = new Map(specificSiteUnitOrder["powerMeters"] || []);
        const powerMeters = getSitePowerMeters(siteId, allPowerMetersOrder, allPowerMeters);

        const units: any = getControlUnitsBySite(siteId, allUnitsOrder, allUnits);
        if (_.isEmpty(units) && _.isEmpty(sensors) && _.isEmpty(powerMeters)) {
          return;
        }

        const site = getSite(siteId);
        const timezone = site?.timezone || "";
        const offset = moment().tz(timezone).utcOffset();
        setSiteOffset(offset);

        const temp = site?.lastTemperature;
        const fTemp = (temp * 9 / 5) + 32;
        setExternalTemp({ C: temp || null, F: fTemp });

        const storedGroupsOrders = userPreferences?.serviceStoredGroupsOrders || {};
        let specificSiteGroupOrder: any = new Map(storedGroupsOrders[siteId] || []);
        const sortedSiteGroups: any = new Map();

        let groups: any = {};
        let unitsTogroups: any = {};
        const groupsMap: any = new Map();
        const groupsValues = allGroups[siteId];
        const unitsInSiteGroups: any = {};

        Object.keys(allGroups).forEach((groupId) => {
          const group = allGroups[groupId];
          const { id = "", units = [], sensors = [] }: any = group;
          const storedOrderedUnitsMap = new Map(specificSiteUnitOrder[id] || []);
          const currentGroupUnitsMap = new Map();
          const sensorIds: any = [];
          const unitIds: any = [];

          units.forEach((unitId: any) => {
            const unit = allUnits[unitId];
            if (!unit || !unit?.isVisible) {
              return;
            }
            unitIds.push(unitId);
            currentGroupUnitsMap.set(unitId, true);
            storedOrderedUnitsMap.set(unitId, true);
            unitsInSiteGroups[unitId] = true;
            if (!unitsTogroups[unitId]) {
              unitsTogroups[unitId] = [id];
            } else {
              unitsTogroups[unitId] = [...(unitsTogroups[unitId] || []), id];
            }
          });

          Object.values(sensors).forEach((sensorId: any) => {
            const sensor = siteSensors[sensorId];
            if (!sensor) {
              return;
            }
            sensorIds.push(sensorId);
            currentGroupUnitsMap.set(sensorId, true);
            storedOrderedUnitsMap.set(sensorId, true);
            if (!unitsTogroups[sensorId]) {
              unitsTogroups[sensorId] = [id];
            } else {
              unitsTogroups[sensorId] = [...(unitsTogroups[sensorId] || []), id];
            }
          });

          if (unitIds.length || sensorIds.length) {
            Array.from(storedOrderedUnitsMap).forEach((unit: any) => ((!allUnits[unit[0]] && !allSensors[unit[0]]) || !currentGroupUnitsMap.has(unit[0])) && storedOrderedUnitsMap.delete(unit[0]));
            group.units = unitIds;
            group.sensors = sensorIds;
            group.itemsOrder = Array.from(storedOrderedUnitsMap);
            groups[id] = group;
            groupsMap.set(id, true);
            sortedSiteGroups.set(id, true);
          }
        });
        setAllGroups(allGroups);

        const arrayOfSortedGroups = Array.from(specificSiteGroupOrder.keys());
        if ((arrayOfSortedGroups.pop() === "sensors" && arrayOfSortedGroups.pop() === "allUnits") || Array.from(specificSiteGroupOrder.keys()).pop() === "allUnits") {
          specificSiteGroupOrder.delete("allUnits");
          specificSiteGroupOrder.delete("sensors");
        }
        specificSiteGroupOrder = new Map([...specificSiteGroupOrder, ..._.orderBy([...sortedSiteGroups.entries()], [(groupId: any) => groups[groupId[0]]?.name], ["asc"])]);
        const unGroupedUnits = _.isEmpty(groups) ? units : units.filter((item: any) => !unitsInSiteGroups[item[0]]);

        if (unGroupedUnits.length !== 0) {
          groups = { ...groups, allUnits: { name: _.isEmpty(groups) ? t`All Units` : t`Ungrouped Units`, id: "allUnits", units: unGroupedUnits.map((item: any) => item[0]), sensors: [], itemsOrder: unGroupedUnits } };
          groupsMap.set("allUnits", true);
          specificSiteGroupOrder.set("allUnits", true);
        }
        if (sensors.length !== 0) {
          const storedOrderedSensorsMap = new Map(specificSiteUnitOrder?.sensors || []);
          sensors.forEach(({ id }: any) => {
            storedOrderedSensorsMap.set(id, true);
          });

          groups = { ...groups, sensors: { name: t`All Sensors`, id: "sensors", units: [], sensors, itemsOrder: Array.from(storedOrderedSensorsMap) } };
          groupsMap.set("sensors", true);
          specificSiteGroupOrder.set("sensors", true);
        }
        if (powerMeters.length !== 0) {
          groups = { ...groups, powerMeters: { name: t`All Power Meters`, id: "powerMeters", units: [], sensors: [], powerMeters } };
          groupsMap.set("powerMeters", true);
          specificSiteGroupOrder.set("powerMeters", true);
        }

        Array.from(specificSiteGroupOrder).forEach((group: any) => !groupsMap.has(group[0]) && specificSiteGroupOrder.delete(group[0]));

        const { groupsObjectF, groupsArrayF } = searchValue ? filterUnits(searchValue, Array.from(specificSiteGroupOrder), groups) : { groupsObjectF: groups, groupsArrayF: Array.from(specificSiteGroupOrder) };

        setFilteredUnitsGroup(groupsObjectF);
        setFilteredSiteGroupsArray(groupsArrayF);
        setFullSiteGroups(groups);
        setSiteGroupsArray(Array.from(specificSiteGroupOrder));
        setUnitsToGroupsMapping(unitsTogroups);
      });

  }, [siteId, preferencesLoaded, allSensors]);

  const onDragEnd = (result: any) => (groupId: any = "") => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder = reorder(
      !groupId ? filteredSiteGroupsArray : filteredUnitsGroups[groupId]?.itemsOrder,
      result.source.index,
      result.destination.index
    );
    if (!groupId) {
      setFilteredSiteGroupsArray(newOrder);
      const currentStored = userPreferences?.serviceStoredGroupsOrders || {};
      const currentSiteId = siteId || "";

      if (!currentStored[currentSiteId]) {
        currentStored[currentSiteId] = [];
      }
      currentStored[currentSiteId] = newOrder;
      updateUserPreferences({ serviceStoredGroupsOrders: currentStored });
      return;
    }

    filteredUnitsGroups[groupId].itemsOrder = newOrder;
    setFilteredUnitsGroup({ ...filteredUnitsGroups });
    const currentStored = userPreferences?.serviceStoredUnitOrders || {};
    const currentSiteId = siteId || "";

    if (!currentStored[currentSiteId]) {
      currentStored[currentSiteId] = {};
    }
    currentStored[currentSiteId] = { ...currentStored[currentSiteId], [groupId]: newOrder };
    updateUserPreferences({ serviceStoredUnitOrders: currentStored });
  };

  const addItemSchedule = (isGroup: boolean, itemId: any, scheduleId: any) => {
    if (isGroup) {
      allGroups[itemId]?.schedules?.push(scheduleId);
      allGroups[itemId] && setAllGroups(allGroups);
      fullSiteGroups[itemId]?.schedules?.push(scheduleId);
      fullSiteGroups[itemId] && setFullSiteGroups({ ...fullSiteGroups });
      return;
    }
    const unit = allUnits[itemId];
    unit && updateUnit({ id: unit.id, data: { ...unit, schedules: [...(unit.schedules || []), scheduleId] } });
  };

  const deleteItemSchedule = (isGroup: boolean, itemId: any, scheduleId: any) => {
    if (isGroup) {
      const group = allGroups[itemId];
      group.schedules = group.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
      setAllGroups(allGroups);
      const group1 = fullSiteGroups[itemId];
      group1.schedules = group.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
      setFullSiteGroups(fullSiteGroups);
      return;
    }

    const unit = allUnits[itemId];
    const schedules = unit?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
    unit && updateUnit({ id: unit.id, data: { ...unit, schedules } });

  };

  const isSliderOpen = selectedItem.unitId || selectedItem.groupId || selectedItem.sensorId;
  const { canControlSiteOperationStatus = true } = allSites[siteId || ""]?.permissions || {};
  const isDragLocked = userPreferences?.lockItemsReorder || false;

  return (
    <>
      <TopBar
        hideUnitsFilter
        leftIconComponent={false ? <ArrowBack /> : <MobileLogo />}
        leftAction={() => history.push("/dashboard")}
        screenTitle="control"
      />
      {!siteId ? (
        <FilterRequire type={t`site`} />
      ) : (<div className={classes.contentArea}>
        <div id="itemsContainer" className={classes.leftSideContainer}>
          <div id="searchHeader" className={classes.searchBar}>
            <Input
              placeholder={t`Search Unit`}
              value={searchValue}
              onChange={changeSearchValue}
              disableUnderline={true}
              classes={{ root: classes.inputRoot }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchValue && (
                  <IconButton
                    onClick={() => {
                      setSearchValue("");
                      setFilteredSiteGroupsArray(siteGroupsArray);
                      setFilteredUnitsGroup({ ...fullSiteGroups });
                    }}
                    className={classes.closeIconStyle}
                  >
                    <Close />
                  </IconButton>
                )
              }
            />
            {<Button
              disableRipple
              className={classes.powerOffButton}
              onClick={() => setNewSitePowerInfo({ state: 2, siteId })}
              disabled={!canControlSiteOperationStatus}
            >
              <PowerIcon className={classes.powerIconStyle} />
              <span className={language === "en" ? classes.powerOffButtonText : classes.powerOffPT}>{t`ALL OFF`}</span>
            </Button>
            }
          </div>
          <div className={classes.itemsWrapper}>
            <DragDropContext
              onDragEnd={(result: any) => onDragEnd(result)("")}
            >
              <Droppable droppableId="droppable" type="groupsDropDown">
                {(provided: any) => (
                  <div ref={provided.innerRef}>
                    {filteredSiteGroupsArray.map(
                      (item: any, index: number) => (
                        <Draggable
                          key={`group-${item[0]}`}
                          draggableId={item[0]}
                          index={index}
                          isDragDisabled={searchValue || isDragLocked}
                        >
                          {(provided: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <GroupItem
                                handleDragging={provided.dragHandleProps}
                                key={`u-group-${item[0]}`}
                                onDragEnd={onDragEnd}
                                changeSitePower={changeSitePower}
                                selectedItem={selectedItem}
                                item={filteredUnitsGroups[item[0]]}
                                siteId={siteId}
                                selectItem={onSelectItem}
                                hasSchedules={true}
                                disableDragging={!!searchValue || isDragLocked}
                                addMessage={addMessage}
                                openCollapse={item[1]}
                                isCelsius={isCelsius}
                                canControlSiteOperationStatus={canControlSiteOperationStatus}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <Slide
          direction="up"
          in={isSliderOpen}
          mountOnEnter
          unmountOnExit
        >
          <div className={clsx(classes.controlSliderWrapper, { [classes.controlSliderHeightIOS]: isIOS })}>
            <div className={classes.controlSectionHeader}>
              <div
                className={classes.expandMoreIconWrapper}
                onClick={() => setSelectedItem({ unitId: "", groupId: "", sensorId: "", isGroup: true })}
              >
                <ExpandMore className={classes.expandMoreIcon} />
              </div>
            </div>
            <div className={classes.controlContainer}>
              {openSchedulesPanel ?
                <ScheduleList
                  isGroup={selectedItem.isGroup}
                  item={selectedItem.isGroup ? fullSiteGroups[selectedItem.groupId] : allUnits[selectedItem.unitId]}
                  hideSchedulesPanel={() => handleSchedulesPanel(false)}
                  allSchedules={allSchedules}
                  setAllSchedules={setAllSchedules}
                  deleteItemSchedule={deleteItemSchedule}
                  addItemSchedule={addItemSchedule}
                  unitGroups={selectedItem.isGroup ? [] : unitsTogroupsMapping[selectedItem.unitId] || []}
                  allGroups={fullSiteGroups}
                />
                : <>
                  {selectedItem.isGroup && fullSiteGroups[selectedItem.groupId] && (
                    <GroupPanel
                      group={fullSiteGroups[selectedItem.groupId]}
                      key={selectedItem.groupId}
                      user={user}
                      types={types}
                      navigateToSchedules={() => handleSchedulesPanel(true)}
                      tempSymbol={getTemperatureScaleDisplay()}
                      temperatureScale={temperatureScale}
                      operationStatusesMirror={operationStatusesMirror}
                      isCelsius={isCelsius}
                      addMessage={addMessage}
                      siteTemp={externalTemp}
                      siteId={siteId}
                      changeSitePower={changeSitePower}
                      canControlSiteOperationStatus={canControlSiteOperationStatus}
                    />
                  )}
                  {selectedItem.type === "unit" && selectedItem.unitId && allUnits[selectedItem.unitId] && (
                    <UnitPanel
                      tempSymbol={getTemperatureScaleDisplay()}
                      allUnits={allUnits}
                      groupId={selectedItem.groupId}
                      siteTemp={externalTemp}
                      key={selectedItem.unitId}
                      unit={allUnits[selectedItem.unitId]}
                      setActiveSetpoint={setActiveSetpoint}
                      setActiveOperationMode={setActiveOperationMode}
                      setActiveFanMode={setActiveFanMode}
                      setActiveSwingMode={setActiveSwingMode}
                      setActiveOperationStatus={setActiveOperationStatus}
                      operationModesMirror={operationModesMirror}
                      fanModesMirror={fanModesMirror}
                      swingModesMirror={swingModesMirror}
                      operationStatusesMirror={operationStatusesMirror}
                      temperatureScale={temperatureScale}
                      isCelsius={isCelsius}
                      navigateToSchedules={() => handleSchedulesPanel(true)}
                      addMessage={addMessage}
                    />
                  )}
                  {selectedItem.type === "sensor" && (
                    <SensorPanel
                      timezoneOffset={siteOffset}
                      key={selectedItem.sensorId}
                      tempSymbol={getTemperatureScaleDisplay()}
                      siteTemp={externalTemp}
                      types={{ sensorTypes, sensorMeasurementUnits }}
                      sensor={allSensors[selectedItem.sensorId]}
                      isCelsius={isCelsius}
                    />
                  )}
                  {selectedItem.type === "powerMeter" &&
                    <PowerMeterPanel
                      tempSymbol={getTemperatureScaleDisplay()}
                      siteTemp={externalTemp}
                      id={selectedItem.id}
                      isCelsius={isCelsius}
                      timezoneOffset={siteOffset}
                    />
                  }
                </>}
            </div>
          </div>
        </Slide>
      </div>)}
      <ConfirmationDialog
        onCancel={() => setNewSitePowerInfo(null)}
        onConfrim={() => { changeSitePower(newSitePowerInfo); setNewSitePowerInfo(null); }}
        onClose={() => setNewSitePowerInfo(null)}
        openDialog={!!newSitePowerInfo}
      />
    </>
  );
};
export default Control;
