import {
  Card,
  Dialog,
  Grid,
  InputBase as Input, ListSubheader, Menu,
  MenuItem,
  Slider,
  Typography
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { Unit as sdkUnit } from "coolremote-sdk";
import _, { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { SubTypesModesList } from "../../components/ModesList";
import { modeIcons } from "../../constants/modes";
import scheduletypes from "../../constants/scheduletypes";
import StyledButton from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Plus, ScheduleOffIcon } from "../../icons";
import { Arrow as SvgArrow } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import assetsService from "../../services/assetsService";
import { FanMode, AutoDSMode } from "../../svgComponents";
import NewApplySettings from "./ApplyToTree";
import ScheduleIUnitItem from "./ScheduleIUnitItemA";
import SwitchItem from "./SwitchItem";
import useStyles from "./UnitSettings.style";
import { dsRanges } from "../../constants/unitsConsts";
import SliderDS from "../../components/Slider/index";

function Alert(text: any) {
  return <MuiAlert elevation={6} variant="filled" {...text} />;
}
const unitSubTypesTitles: any = { 0: "Control AC", 1: "Water Heater", 2: "Control HRV", 3: "Sanitary Water Heater" };
const unitSubTypesModes: any = {
  operationModesExtended: "subTypesOperationModes",
  fanModes: "subTypesFanModes",
  swingModes: "subTypesSwingModes"
};

const brandsAllowingHRV: { [brandNo: number]: boolean } = {
  1: true, //"Daikin"
  19: true, //"ME"
  6: true, //"samsung",
  4: true
}

const UnitSetting: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const isInitialized = useStoreState((s) => s.isInitialized);
  const temperatureScale = useStoreState((state) => state.users.me.temperatureScale);
  const [useTemperatureScale, setUseTemperatureScale] = useState(temperatureScale);
  const selections = useStoreState((s) => s.selections.selections);
  const createScheduleAPI = useStoreActions((actions) => actions.schedules.createObjectSchedules);
  const [indoorUnits, setIndoorUnits] = useState<any>([]);
  const [selectedUnit, setSelectedUnit] = useState<IUnit | null>(null);
  const [unitsToSave, setUnitsToSave] = useState<any>([]);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const { getUnitByIdSDK } = useStoreActions((actions) => actions.units);
  const types = useStoreState((states) => states.types);
  const getCustomerSchedules = useStoreActions((actions) => actions.schedules.getCustomerSchedules);
  const {
    getTimers,
    createTimer,
    createMultiTimers
  } = useStoreActions((actions) => actions.timers);
  const [open, setOpen] = React.useState(false);
  const [autoTimerEnabled, setAutoTimerEnabled] = useState<boolean>(false);

  const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const updateStoreUnit = useStoreActions((actions) => actions.units._storeUpdateUnit);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [allCustomerSchedules, setSllCustomerSchedules] = useState<any>({});
  const [supportedValues, setSupportedValues] = useState<any>({});
  const [tempLimitFlags, setTempLimitFlags] = useState<any>({
    enableCoolMode: false,
    enableHeatMode: false,
    enableAutoMode: false
  });

  const [tStatFlags, setTStatFlags] = useState<any>({
    enableOnoff: false,
    enableSetpoint: false
  });
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const tempSymbol = useStoreState((s) => s.users.getTemperatureScaleDisplay())
  const isFahrenheit = +temperatureScaleMirror.fahrenheit === temperatureScale;
  const [isUnitVisible, setIsUnitVisible] = useState<boolean | undefined>(true);
  const [createNewSchedule, setCreateNewSchedule] = useState<any>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
  const updateUnit = useStoreActions((action) => action.units.updateUnit);
  const setUnitLocally = useStoreActions((action) => action.units.setUnitLocally);
  const updateUnitsAndSchedules = useStoreActions((action) => action.units.updateUnitsAndSchedules);
  const schedulesMemo = useCallback(() => schedules(), [allCustomerSchedules, selectedUnit, createNewSchedule, allCustomerSchedules]);
  const [helperMessgae, setHelperMessgae] = useState<string>("");
  const [disableAllSettings, setDisableAllSettings] = useState<boolean>(false);
  const [timerMins, setTimerMins] = useState<any>("0");
  const [timerHours, setTimerHours] = useState<any>("0");
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [timers, setTimers] = useState<any>({});
  const [timerIsActive, setTimerIsActive] = useState<boolean>(false);
  const [showSetpiontErrorMsg, handleSetpiontErrorMsg] = useState<boolean>(false);
  const [unitSubType, setUnitSubType] = useState<number>(0);
  const [subTypeSelectAnchor, setSubTypeSelectAnchor] = useState<null | HTMLElement>(null);

  const { subType = 0, brand, capabilityFlags = {}, permissions = {} } = selectedUnit || {};
  const { canUpdate, canUpdateSchedules, canCreateSchedules, canDeleteSchedules } = permissions;

  const memoSideMenu = useCallback(() => sideMenu(), [selections, unitsToSave, subType]);
  const {
    enableAppSettingsOnOffLock = false, enableAppSettingsSetpointChangeLock = false,
    enableAppSettingsModeControl = false, enableAppSettingsFanControl = false,
    enableAppSettingsSwingControl = false, enableAppSettingsLimitCoolingSetpoint = false,
    enableAppSettingsLimitHeatingSetpoint = false, enableAppSettingsLimitAutoSetpoint = false,
    enableAppSettingsSetpointControlResolution = false, enableAppSettingsTimer = false
  } = capabilityFlags;

  const getSchedules = () => {
    getCustomerSchedules({ id: selections.customerId, type: scheduletypes.unitLimits }).then((res: any) => {
      setSllCustomerSchedules(res);
    });
  };

  const userTemperatureScale = useStoreState((s) => s.users.me.temperatureScale || 1);
  const isCelsius = +temperatureScaleMirror.celsius === +userTemperatureScale;
  const defaultCool = isCelsius ? 28 : 82;
  const defaultHeat = isCelsius ? 18 : 64;
  const [bottomLimit, setBottomLimit] = useState<number>(defaultHeat);
  const [topLimit, setTopLimit] = useState<number>(defaultCool);
  const { createDualSetpiontRule, updateDualSetpiontRule, getUnitDualSetpiontRule, createMultiDualSetpiontRules } = useStoreActions((actions) => actions.units);
  const [ruleId, setRuleId] = useState<string>("");

  const updateDate = () => {

    if (!selections.siteId) {
      return;
    }

    getTimers({ siteId: selections.siteId }).then((timers: any) => {
      const mappedTimers: any = {};
      Object.values(timers).forEach((timer: any) => {
        const { units = [""] } = timer;
        const unitId = units[0];
        mappedTimers[unitId] = timer;
      });
      setTimers(mappedTimers);
      setDataReady(true);
    }).catch(() => setDataReady(true));
  }

  useEffect(() => {
    updateDate()
  }, [selections.siteId]);

  // get schedules on change
  useEffect(() => {
    if (!selections.customerId) {
      return;
    }
    getSchedules();
  }, [selections.customerId]);

  // update indoor units when all units change or when site\customer\scale change
  useEffect(() => {
    const filterType = 1;

    const indoorUnitsOnly = Object.values(allUnits).filter((unit: any) => {
      const unitSystemId = unit.system;
      const unitDevice = allDevices[unit.device];
      if (_.isUndefined(unitDevice)) { return false; }
      const unitSite = allSites[unitDevice.site];
      if (_.isUndefined(unitSite)) { return false; }
      const unitCustomerId = unitSite.customer;
      if (_.isUndefined(unitCustomerId)) { return false; }

      return (
        unit.type === filterType &&
        (selections.customerId ? unitCustomerId === selections.customerId : true) &&
        (selections.siteId ? unitSite.id === selections.siteId : true) &&
        (selections.systemId ? unitSystemId === selections.systemId : true)
      );
    });

    if (!_.isEqual(indoorUnits, indoorUnitsOnly)) {
      setIndoorUnits(indoorUnitsOnly);
      setUseTemperatureScale(temperatureScale);
    }
  }, [allUnits, selections.siteId, selections.customerId, temperatureScale]);

  // When entering the page - if unit was selected already - show it
  useEffect(() => {
    if (selections.unitId) {
      getUnitByIdSDK(selections.unitId).then((unit: IUnit) => {
        setSelectedUnit(unit);
      })

      getUnitDualSetpiontRule(selections.unitId).then((dualSetpoint: any) => {
        if (dualSetpoint) {
          setRuleId(dualSetpoint.id);
          const {
            heatSP,
            coolSP,
          } = dualSetpoint || {}
          setTopLimit(coolSP);
          setBottomLimit(heatSP)
        } else {
          setTopLimit(defaultCool);
          setBottomLimit(defaultHeat);
        }
      })
    } else {
      setSelectedUnit(null);
    }
  }, [selections.unitId, temperatureScale]);

  // update supported values when they change in the unit from all units
  useEffect(() => {
    if (!selectedUnit) {
      return;
    }

    const values: any = {};
    const unit = _.cloneDeep(selectedUnit);
    if (unit) {
      values.temperatureLimits = unit.temperatureLimits;
      setTStatFlags({
        enableOnoff: unit.enableOnoff || false,
        // enableMode: unit.enableMode || false,
        enableSetpoint: unit.enableSetpoint || false
        // enableOnState: unit.enableOnState || false
      });
      setTempLimitFlags({
        enableCoolMode: unit.enableCoolMode || false,
        enableHeatMode: unit.enableHeatMode || false,
        enableAutoMode: unit.enableAutoMode || false
      });

    }

    setSupportedValues({ ...supportedValues, ...values });
    setUnitSubType(unit?.subType || 0);

  }, [
    selectedUnit && selectedUnit.enableOnoff, selectedUnit && selectedUnit.enableMode,
    selectedUnit && selectedUnit.enableSetpoint, selectedUnit && selectedUnit.enableOnState,
    selectedUnit && selectedUnit.enableCoolMode, selectedUnit && selectedUnit.enableHeatMode,
    selectedUnit && selectedUnit.enableAutoMode
  ]);

  const debouncedSetData = debounce(async () => {
    const values: any = {};
    if (selectedUnit) {
      values.supportedOperationModes = selectedUnit.supportedOperationModes;
      values.supportedFanModes = selectedUnit.supportedFanModes;
      values.supportedSwingModes = selectedUnit.supportedSwingModes;
      values.temperatureLimits = selectedUnit.temperatureLimits;
      values.isHalfCDegreeEnabled = selectedUnit.isHalfCDegreeEnabled || false;
      setTStatFlags({
        enableOnoff: selectedUnit.enableOnoff || false,
        // enableMode: unit.enableMode || false,
        enableSetpoint: selectedUnit.enableSetpoint || false
        // enableOnState: unit.enableOnState || false
      });
      setIsUnitVisible(selectedUnit.isVisible);
      setTempLimitFlags({
        enableCoolMode: selectedUnit.enableCoolMode || false,
        enableHeatMode: selectedUnit.enableHeatMode || false,
        enableAutoMode: selectedUnit.enableAutoMode || false
      });
      setAutoTimerEnabled(selectedUnit.showControlAppAutoOffTimer || false);
      const { isActive = false, turnOffInMinutes = 0 } = timers[selectedUnit.id] || {};
      setTimerMins(+turnOffInMinutes % 60);
      setTimerHours(Math.floor(+turnOffInMinutes / 60));
      setTimerIsActive(isActive);
    }
    setSupportedValues(values);
  }, 500)

  // update supported values when selecting a unit
  useEffect(() => {
    if (!dataReady) {
      return;
    }
    debouncedSetData();
    return debouncedSetData.cancel;
  }, [selectedUnit, dataReady]);

  // Update tStatLock when it changes
  useEffect(() => {
    if (selectedUnit) {
      setTStatFlags({
        enableOnoff: selectedUnit.enableOnoff,
        // enableMode: selectedUnit.enableMode,
        enableSetpoint: selectedUnit.enableSetpoint
        // enableOnState: selectedUnit.enableOnState
      });
    }

  }, [
    selectedUnit && selectedUnit.enableOnoff, selectedUnit && selectedUnit.enableMode,
    selectedUnit && selectedUnit.enableSetpoint, selectedUnit && selectedUnit.enableOnState
  ]);

  // Update tempLimits flags when it changes
  useEffect(() => {
    if (selectedUnit) {
      setTempLimitFlags({
        enableCoolMode: selectedUnit.enableCoolMode,
        enableHeatMode: selectedUnit.enableHeatMode,
        enableAutoMode: selectedUnit.enableAutoMode
      });
    }

  }, [selectedUnit && selectedUnit.enableCoolMode, selectedUnit && selectedUnit.enableHeatMode, selectedUnit && selectedUnit.enableAutoMode
  ]);

  // update selected unit when user selected a unit or when indoor units change
  useEffect(() => {
    if (!selections.unitId) {
      return;
    }

    const unit = _.find(indoorUnits, (unit) => unit.id === selections.unitId);
    if (!unit) {
      return;
    }

    const unitDevice = allDevices[unit.device];
    if (unitDevice && !unitDevice.isConnected) {
      setDisableAllSettings(true);
      setHelperMessgae(t`Device is disconnected`);
    }

    if (unitDevice && unitDevice.isConnected) {
      setDisableAllSettings(false);
    }

    if (wasControlChange(unit)) {
      return;
    }

    // getUnitByIdSDK(selections.unitId).then((unit: IUnit) => {
    //   setSelectedUnit(unit);
    // })

  }, [selections.unitId, indoorUnits, allDevices]);

  const wasControlChange = (unit: any) => {
    if (!selectedUnit || !unit) {
      return false;
    }

    const checkSelectedUnit: any = _.clone(selectedUnit);
    const checkUpdatedSelectedUnit: any = _.clone(unit);

    delete checkSelectedUnit.activeSetpoint;
    delete checkSelectedUnit.activeOperationMode;
    delete checkSelectedUnit.activeOperationStatus;
    delete checkSelectedUnit.activeFanMode;
    delete checkSelectedUnit.activeSwingMode;

    delete checkUpdatedSelectedUnit.activeSetpoint;
    delete checkUpdatedSelectedUnit.activeOperationMode;
    delete checkUpdatedSelectedUnit.activeOperationStatus;
    delete checkUpdatedSelectedUnit.activeFanMode;
    delete checkUpdatedSelectedUnit.activeSwingMode;

    if (_.isEqual(checkSelectedUnit, checkUpdatedSelectedUnit)) {
      return true;
    }

    return false;
  };

  const getUnitKeyOfMode = (typesMode: string) => {
    let unitModeName: any = "";
    switch (typesMode) {
      case "operationModesExtended":
        unitModeName = "supportedOperationModes";
        break;
      case "fanModes":
        unitModeName = "supportedFanModes";
        break;
      case "swingModes":
        unitModeName = "supportedSwingModes";
        break;
      case "cooling":
        unitModeName = 0;
        break;
      case "heat":
        unitModeName = 1;
        break;
      case "auto":
        unitModeName = 2;
        break;
      default:
        unitModeName = null;
        break;
    }
    return unitModeName;
  };

  const changeSupportedValues = (e: any, mode: string) => {
    const isChecked = e.target.checked;
    const modeName = getUnitKeyOfMode(mode);
    const updatedValues: any = { ...supportedValues };

    if (!modeName) {
      return;
    }
    if (!isChecked) {
      updatedValues[modeName] = [];
    } else {
      const supportedSubTypesModesName = unitSubTypesModes[mode];
      const values = types[supportedSubTypesModesName]?.[subType] || [];
      updatedValues[modeName] = values;
    }
    setSupportedValues(updatedValues);
  };

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + (useTemperatureScale === 1 ? " °C" : " °F");
    return `${valueToShow}`;
  };

  if (!isInitialized) { return <Loading />; }
  const updateValues = (modeName: string, modeNumber: any) => {
    const unitModeName = getUnitKeyOfMode(modeName);
    if (!unitModeName || !supportedValues[unitModeName]) {
      return;
    }

    const locValues: any = { ...supportedValues };
    let supported: any = locValues[unitModeName] || [];
    const checkIndex = supported.indexOf(modeNumber);
    if (checkIndex > -1) {
      supported = supported.filter((item: any) => item !== modeNumber);
    } else {
      supported = [...supported, +modeNumber];
    }

    locValues[unitModeName] = supported;
    setSupportedValues(locValues);
  };
  const handleSetCooling = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[0] = newValue;
    setSupportedValues(locValues);
  };
  const handleSetHeat = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[1] = newValue;
    setSupportedValues(locValues);
  };
  const handleSetAuto = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[2] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetTempLimitRange = (flagName: string) => {
    const nextFlagState = _.clone(tempLimitFlags);
    nextFlagState[flagName] = !nextFlagState[flagName];
    setTempLimitFlags(nextFlagState);
  };

  const handleAgree = (units: any) => {
    setUnitsToSave(units);
  };
  const handleSave = (unitId: any, multi: boolean = false) => {
    setDataReady(false);
    let isActive = timerIsActive;
    let hrs = timerHours ? +timerHours : 0;
    let mins = timerMins ? +timerMins : 0;

    // if (timerIsActive && ((hrs + mins) === 0) && enableAppSettingsTimer) {
    //   setTimerIsActive(false);
    //   handleTimerErrorMsg(true);
    //   setTimeout(() => {
    //     handleTimerErrorMsg(false);
    //   }, 5000);
    //   isActive = false;
    // }

    if (!(bottomLimit <= topLimit - 2)) {
      handleSetpiontErrorMsg(true);
      setTopLimit(defaultHeat);
      setBottomLimit(defaultCool);
      setTimeout(() => {
        handleSetpiontErrorMsg(false);
      }, 5000);
    }

    const data: any = {
      units: !multi ? [unitId] : unitsToSave,
      supportedOperationModes: enableAppSettingsModeControl ? supportedValues.supportedOperationModes : undefined,
      supportedFanModes: enableAppSettingsFanControl ? supportedValues.supportedFanModes : undefined,
      supportedSwingModes: enableAppSettingsSwingControl ? supportedValues.supportedSwingModes : undefined,
      temperatureLimits: (enableAppSettingsLimitAutoSetpoint || enableAppSettingsLimitCoolingSetpoint || enableAppSettingsLimitHeatingSetpoint) ? supportedValues.temperatureLimits : undefined,
      isVisible: isUnitVisible,
      enableOnoff: enableAppSettingsOnOffLock ? (tStatFlags.enableOnoff || false) : undefined,
      // enableMode: enableAppSettingsModeLock ? (tStatFlags.enableMode || false) : undefined,
      enableSetpoint: enableAppSettingsSetpointChangeLock ? (tStatFlags.enableSetpoint || false) : undefined,
      // enableOnState: (tStatFlags.enableOnState || false),
      enableCoolMode: enableAppSettingsLimitCoolingSetpoint ? (tempLimitFlags.enableCoolMode || false) : undefined,
      enableHeatMode: enableAppSettingsLimitHeatingSetpoint ? (tempLimitFlags.enableHeatMode || false) : undefined,
      enableAutoMode: enableAppSettingsLimitAutoSetpoint ? (tempLimitFlags.enableAutoMode || false) : undefined,
      isHalfCDegreeEnabled: enableAppSettingsSetpointControlResolution ? (supportedValues.isHalfCDegreeEnabled) : undefined,
      showControlAppAutoOffTimer: enableAppSettingsTimer ? autoTimerEnabled : undefined,
      site: selections.siteId
    };
    Object.keys(data).forEach((key: string) => {
      if (data[key] === undefined) {
        delete data[key];
      }
    });

    if (multi) {

      if (bottomLimit <= topLimit - 2) {
        createMultiDualSetpiontRules({
          heatSP: bottomLimit,
          coolSP: topLimit,
          units: unitsToSave,
          site: selections.siteId || ""
        }).catch((err: any) => addMessage({ message: err.message }));
      }

      data.sourceUnit = unitId;
      const timersAPIs: any = [];

      if (enableAppSettingsTimer) {
        const units = [];

        for (let i = 0; i < unitsToSave.length; i++) {
          const id = unitsToSave[i];
          units.push(id);
        }

        let data: any = { units, site: selections.siteId, isActive, turnOffInMinutes: hrs * 60 + mins };

        createMultiTimers(data).then(updateDate)

        // if (!isActive && mins === 0 && hrs === 0) {
        //   unitsWithnoTimers = [];
        // }

        // if (unitsWithnoTimers.length) {
        //   selections.siteId && timersAPIs.push(createMultiTimers({ units: unitsWithnoTimers, site: selections.siteId, isActive, turnOffInMinutes: hrs * 60 + mins }));
        // }
        // if (unitsWithTimer.length) {
        //   let data: any = { timers: unitsWithTimer, site: selections.siteId, isActive, turnOffInMinutes: hrs * 60 + mins };
        //   if (!isActive) delete data.turnOffInMinutes;
        //   selections.siteId && timersAPIs.push(updateMultiTimers(data));
        // }
      }

      updateUnitsAndSchedules({ updatedData: data }).then(() => {
        for (let i = 0; i < unitsToSave.length; i++) {
          updateStoreUnit({
            id: unitsToSave[i],
            data
          });
        }
      });
      if (timersAPIs.length) {
        Promise.all(timersAPIs)
          .catch((err: any) => addMessage({ message: err.message }))
          .finally(() => {
            updateDate();
          });
      } else {
        setDataReady(true);
      }
      return;
    }
    delete data.units;
    delete data.site;
    updateUnit({ id: unitId, updatedData: data })
      .then((res: any) => {
        getUnitByIdSDK(unitId).then((unit: IUnit) => {
          setSelectedUnit(unit);
        })
        setOpen(true)
      })
      .catch((err: any) => addMessage({ message: err.message }));

    if (bottomLimit <= topLimit - 2) {
      ruleId ?
        updateDualSetpiontRule({
          ruleId,
          heatSP: bottomLimit,
          coolSP: topLimit
        }).catch((err: any) => addMessage({ message: err.message }))
        :
        createDualSetpiontRule({
          heatSP: bottomLimit,
          coolSP: topLimit,
          unit: unitId
        }).catch((err: any) => addMessage({ message: err.message }));
    }

    if (enableAppSettingsTimer) {
      // if (!isActive && hrs === 0 && mins === 0) {
      //   setDataReady(true);
      //   return;
      // }
      selections.siteId && createTimer({ unit: unitId, isActive, turnOffInMinutes: hrs * 60 + mins })
        .then((timerResp: any) => {
          setTimers({ ...timers, [unitId]: timerResp });
          setDataReady(true);
        })
        .catch((err: any) => {
          addMessage({ message: err.message });
          setDataReady(true);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    const values: any = {};
    const unit = selectedUnit;
    if (unit) {
      values.supportedOperationModes = unit.supportedOperationModes;
      values.supportedFanModes = unit.supportedFanModes;
      values.supportedSwingModes = unit.supportedSwingModes;
      values.isHalfCDegreeEnabled = unit.isHalfCDegreeEnabled || false;
      values.temperatureLimits = unit.temperatureLimits; setTStatFlags({
        enableOnoff: unit.enableOnoff || false,
        // enableMode: unit.enableMode || false,
        enableSetpoint: unit.enableSetpoint || false
        // enableOnState: unit.enableOnState || false
      });
      setIsUnitVisible(unit.isVisible);
      setTempLimitFlags({
        enableCoolMode: unit.enableCoolMode || false,
        enableHeatMode: unit.enableHeatMode || false,
        enableAutoMode: unit.enableAutoMode || false
      });
      const { isActive = false, turnOffInMinutes = 0 } = timers[unit.id] || {};
      setTimerMins(+turnOffInMinutes % 60);
      setTimerHours(Math.floor(+turnOffInMinutes / 60));
      setTimerIsActive(isActive);
      setSupportedValues(values);
    }

  };

  const noUnitSelect = () => {
    return <FilterRequire type={t`unit`} />;

  };

  const sideMenu = () => {
    return <NewApplySettings
      save={handleAgree}
      units={unitsToSave}
      noPopup={true}
      oneCustomer={{ id: selections.customerId }}
      oneSite={selections.siteId}
      allTenants={[]}
      tenantId="9999"
      noDisable={true}
      unitSettings
      subType={subType}
    />;
  };

  const saveSchedule = async (data: any) => {
    data.type = scheduletypes.unitLimits;
    await createScheduleAPI({
      data,
      objId: selectedUnit ? selectedUnit.id : "",
      objectType: "unit"
    });
    setSelectedSchedule(null);
    getSchedules();
  };

  const onUpdateSchedule = async (data: any) => {
    // data.type = scheduletypes.unitLimits;
    await updateSchedule({ id: selectedSchedule.id, data });
    setSelectedSchedule(null);
    getSchedules();
  };

  const onUpdateDisableState = async (data: any) => {

    data.type = scheduletypes.unitLimits;
    await updateSchedule({ id: data.id, data: { isDisabled: data.isDisabled } });
  };

  const onDelete = async (idObj: any) => {
    await deleteSchedule(idObj.id);
    getSchedules();
  };

  const schedules = () => {
    const unitSchedules = _.reduce(allCustomerSchedules, (arr: any[], item: any) => {
      if (item.unit === selections.unitId) {
        arr.push(item);
      }

      return arr;
    }, []);

    return (
      <>
        <Dialog
          aria-labelledby="add-edit-schedule-dialog"
          open={createNewSchedule}
          maxWidth="lg"
          classes={{ paperWidthLg: classes.dialogStyle }}
          fullWidth
        >
          <ScheduleIUnitItem
            capabilityFlags={capabilityFlags}
            selectedSchedule={selectedSchedule}
            onSave={selectedSchedule ? onUpdateSchedule : saveSchedule}
            onCancle={() => {
              setCreateNewSchedule(!createNewSchedule);
              setSelectedSchedule(null);
            }}
            canEdit={true}
            canEditScheule={canUpdateSchedules}
            canDelete={canDeleteSchedules}
            title={!selectedSchedule ? "Add Schedule " : "Edit Schedule"}
            useTemperatureScale={useTemperatureScale}
          />
        </Dialog>

        <div className={classes.scheduleContainer}>
          <div className={classes.newScheduleBtn} >
            <Typography className={classes.headerTitle}>Schedules</Typography>
            <StyledButton
              disabled={!canCreateSchedules}
              white
              width={187}
              onClick={() => setCreateNewSchedule(!createNewSchedule)}
              startIcon={<Plus className={classes.plusIcon} />}
            >{t`Add Schedule`}

            </StyledButton>
          </div>

          < >
            {_.map(unitSchedules, (schedule) => {

              return (
                <ScheduleIUnitItem
                  capabilityFlags={capabilityFlags}
                  selectedSchedule={schedule}
                  onSave={updateSchedule}
                  onCancle={() => setCreateNewSchedule(!createNewSchedule)}
                  canEdit={false}
                  canEditScheule={canUpdateSchedules}
                  canDelete={canDeleteSchedules}
                  key={schedule.id}
                  onDelete={onDelete}
                  onEditAction={() => {
                    setCreateNewSchedule(!createNewSchedule);
                    setSelectedSchedule(schedule);
                  }}
                  onDisableUpdate={onUpdateDisableState}
                  useTemperatureScale={useTemperatureScale}
                />
              );
            })}
          </>
        </div>
      </>
    );
  };

  const preventMinusAndE = (event: any) => {
    const { keyCode } = event;
    if (keyCode === 189 || keyCode === 69) {
      event.preventDefault();
    }
  };

  const inputChange = (value: any, action: any, min: number, max: number) => {
    if (+value === 0 && value.length > 2) {
      return action("0");
    }
    if (+value > max || +value < min || value.length > 2) {
      return;
    }
    action(+value);
  };

  const canSee = true;
  const updateTstatFlags = (key: string, value: boolean) => setTStatFlags({ ...tStatFlags, [key]: value });

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          hideSystemSelection={true}
          disableSystemUpdate={true}
          path={[t`Unit App Controls`]}
          hideOutdoor
          hideBsBox
          hideIndoor
          hideOther
          screenTitle="unitSettings"
          countControlUnits
          customGeneralNames={{ unit: t`Select Unit` }}
        />
        {!selections.unitId && noUnitSelect()}
        {selections.unitId &&
          <Grid
            container
            className={classes.container}
            spacing={3}
          >
            <Grid item xs={9} className={classes.gridLeft}>
              {(supportedValues.temperatureLimits && selectedUnit) &&
                <Card className={clsx(classes.modeContent, classes.modeContentUnitSettings)}>

                  <>
                    <div className={clsx(classes.settingsHeaderContainer, { [classes.withMessage]: disableAllSettings })}>
                      <Typography className={clsx(classes.headerTitle, classes.cutLongNames)}>{selectedUnit && selectedUnit?.name}</Typography>
                      <Typography className={classes.errorTitle}>{disableAllSettings ? helperMessgae : ""}</Typography>
                      {!!brandsAllowingHRV[+brand] &&
                        <>
                          <div
                            className={clsx(classes.selectSubType, subType === 1 && classes.disabledItem)}
                            onClick={(event: any) => canUpdate && subType !== 1 && setSubTypeSelectAnchor(event.currentTarget)}
                          >
                            <Typography className={classes.selectText}>{unitSubTypesTitles[subType]}</Typography>
                            <SvgArrow className={classes.arrowIcon} />
                          </div>
                          <Menu
                            style={{ marginTop: "45px" }}
                            onClick={(event: any) => event.stopPropagation()}
                            id="subType-menu"
                            anchorEl={subTypeSelectAnchor}
                            open={!!subTypeSelectAnchor}
                            onClose={() => {
                              setSubTypeSelectAnchor(null);
                              setUnitSubType(selectedUnit?.subType || 0);
                            }}
                          >
                            <MenuItem
                              onClick={() => setUnitSubType(0)}
                              className={unitSubType === 0 ? classes.selected : ""}
                            >
                              {t`Control AC`}
                            </MenuItem>
                            {+brand !== 19 && <MenuItem
                              onClick={() => setUnitSubType(2)}
                              className={unitSubType === 2 ? classes.selected : ""}
                            >
                              {t`Control HRV`}
                            </MenuItem>}
                            {(+brand === 19 || +brand === 4 || +brand === 6) &&
                              <MenuItem
                                onClick={() => setUnitSubType(3)}
                                className={unitSubType === 3 ? classes.selected : ""}
                              >
                                {t`Sanitary Water Heater`}
                              </MenuItem>}
                            <ListSubheader style={{ margin: "13px 0 0 0" }}>
                              <StyledButton onClick={(event: any) => {
                                sdkUnit.updateUnitSubType(selections?.unitId, { subType: unitSubType })
                                  .then((unit: any) => {
                                    setUnitLocally(unit);
                                    setSelectedUnit(unit); // to refresh the page
                                  })
                                  .catch((err: any) => addMessage({ msg: err.message }))
                                  .finally(() => setSubTypeSelectAnchor(null));
                              }}>{t`Submit`}</StyledButton>
                            </ListSubheader>
                          </Menu>
                        </>}
                      {/* {brand === 4 &&
                        <>
                          <div
                            className={clsx(classes.selectSubType, subType === 1 && classes.disabledItem)}
                            onClick={(event: any) => canUpdate && subType !== 1 && setSubTypeSelectAnchor(event.currentTarget)}
                          >
                            <Typography className={classes.selectText}>{unitSubTypesTitles[subType]}</Typography>
                            <SvgArrow className={classes.arrowIcon} />
                          </div>
                          <Menu
                            style={{ marginTop: "45px" }}
                            onClick={(event: any) => event.stopPropagation()}
                            id="subType-menu"
                            anchorEl={subTypeSelectAnchor}
                            open={!!subTypeSelectAnchor}
                            onClose={() => {
                              setSubTypeSelectAnchor(null);
                              setUnitSubType(selectedUnit?.subType || 0);
                            }}
                          >
                            <MenuItem
                              onClick={() => setUnitSubType(0)}
                              className={unitSubType === 0 ? classes.selected : ""}
                            >
                              {t`Control AC`}
                            </MenuItem>
                            <MenuItem
                              onClick={() => setUnitSubType(3)}
                              className={unitSubType === 3 ? classes.selected : ""}
                            >
                              {t`Sanitary Water Heater`}
                            </MenuItem>
                            <ListSubheader style={{ margin: "13px 0 0 0" }}>
                              <StyledButton onClick={(event: any) => {
                                sdkUnit.updateUnitSubType(selections?.unitId, { subType: unitSubType })
                                  .then((unit: any) => {
                                    setUnitLocally(unit);
                                    setSelectedUnit(unit); // to refresh the page
                                  })
                                  .catch((err: any) => addMessage({ msg: err.message }))
                                  .finally(() => setSubTypeSelectAnchor(null));
                              }}>{t`Submit`}</StyledButton>
                            </ListSubheader>
                          </Menu>
                        </>} */}
                    </div>

                    <div className={classes.tStatContainer}>
                      {enableAppSettingsOnOffLock &&
                        <SwitchItem
                          disabled={!canUpdate}
                          onChange={(value: boolean) => updateTstatFlags("enableOnoff", value)}
                          value={tStatFlags.enableOnoff}
                          title={t`Allow On/Off`}
                        />}
                      {enableAppSettingsSetpointChangeLock &&
                        <SwitchItem
                          disabled={!canUpdate}
                          onChange={(value: boolean) => updateTstatFlags("enableSetpoint", value)}
                          value={tStatFlags.enableSetpoint}
                          title={t`Allow setpoint change`}
                        />}
                      <div className={classes.switchContainer}>
                        <Typography className={classes.switchTitle}>{t`Show unit in control unit list`}</Typography>
                        <CoolSwitch
                          disabled={!canUpdate}
                          checked={isUnitVisible}
                          onChange={() => setIsUnitVisible(!isUnitVisible)}
                        />
                      </div>
                    </div>

                    < div className={classes.modeCardUnitSettings}>

                      <div className={clsx(classes.limitsLineGroup, classes.addRightMargin)}>
                        {enableAppSettingsModeControl &&
                          <Card className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <Typography className={classes.unitTitle}>{t`Allow mode settings`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={
                                  !!(supportedValues.supportedOperationModes &&
                                    supportedValues.supportedOperationModes.length)
                                }
                                onChange={(e: any) =>
                                  changeSupportedValues(e, "operationModesExtended")
                                }
                              />
                            </div>
                            <SubTypesModesList
                              subType={subType}
                              disabled={!canUpdate}
                              modeName={"operationModesExtended"}
                              activeModes={supportedValues.supportedOperationModes}
                              action={updateValues}
                            />
                          </Card>}
                        {(enableAppSettingsFanControl && !!supportedValues.supportedFanModes) &&
                          <Card className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <FanMode className={classes.fanIcon} />
                                <Typography className={clsx(classes.unitTitle, classes.modesTitle)}>{t`Allow fan speed control`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={
                                  !!(supportedValues.supportedFanModes &&
                                    supportedValues.supportedFanModes.length)
                                }
                                onChange={(e: any) =>
                                  changeSupportedValues(e, "fanModes")
                                }
                              />
                            </div>
                            <SubTypesModesList
                              subType={subType}
                              disabled={!canUpdate}
                              modeName={"fanModes"}
                              activeModes={supportedValues.supportedFanModes}
                              action={updateValues}
                            // customClass={classes.fanModes}
                            />
                          </Card>}
                        {enableAppSettingsSwingControl &&
                          <Card className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}>
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <img
                                  src={assetsService.getImagesPath(`louver.svg`)}
                                  className={classes.imgIconStyle}
                                ></img>
                                <Typography className={classes.unitTitle}>{t`Allow swing control`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={
                                  !!(supportedValues.supportedSwingModes &&
                                    supportedValues.supportedSwingModes.length)
                                }
                                onChange={(e: any) =>
                                  changeSupportedValues(e, "swingModes")
                                }
                              />
                            </div>
                            <SubTypesModesList
                              subType={subType}
                              disabled={!canUpdate}
                              modeName={"swingModes"}
                              activeModes={supportedValues.supportedSwingModes}
                              action={updateValues}

                            />
                          </Card>}
                        {enableAppSettingsTimer &&
                          <Card
                            className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <ScheduleOffIcon color="#4b1c46" className={classes.scheduleIcon} />
                                <Typography className={classes.unitTitle}>
                                  {t`Auto Timer Off`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={timerIsActive}
                                onChange={() => setTimerIsActive(!timerIsActive)}
                              />
                            </div>
                            <div className={clsx(classes.sliderHolder, classes.timeInputsContainer)}>
                              <Typography>{t`Duration`}</Typography>
                              <Input
                                disabled={!timerIsActive || !canUpdate}
                                value={timerHours}
                                onChange={({ target: { value } }: any) => inputChange(value, setTimerHours, 0, 23)}
                                onKeyDown={preventMinusAndE}
                                onBlur={(event: any) => event.target.value === "" && setTimerHours(0)}
                                className={classes.timeInput}
                                type="number"
                              />
                              <Typography className={classes.add15leftMargin}>{t`Hours`}</Typography>
                              <Input
                                disabled={!timerIsActive || !canUpdate}
                                value={timerMins}
                                onChange={({ target: { value } }: any) => inputChange(value, setTimerMins, 0, 59)}
                                onKeyDown={preventMinusAndE}
                                onBlur={(event: any) => event.target.value === "" && setTimerMins(0)}
                                className={clsx(classes.timeInput, classes.add15leftMargin)}
                                type="number"
                              />
                              <Typography className={classes.add15leftMargin}>{t`Minutes`}</Typography>
                            </div>

                          </Card>}
                      </div>
                      <div className={classes.limitsLineGroup}>
                        {enableAppSettingsLimitHeatingSetpoint &&
                          <Card
                            className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <img
                                  src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
                                  className={classes.imgIconStyle}
                                ></img>
                                <Typography className={classes.unitTitle}>
                                  {t`Limit heating setpoint range`}</Typography>
                              </div>

                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={tempLimitFlags.enableHeatMode}
                                onChange={() =>
                                  handleSetTempLimitRange("enableHeatMode")
                                }
                              />
                            </div>
                            <div className={classes.sliderHolder}>
                              {tempLimitFlags.enableHeatMode &&
                                <div className={classes.sliderAndLabelContainer}>
                                  <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][0])}</Typography>
                                  <Slider
                                    className={classes.heatSlider}
                                    onChange={handleSetHeat}
                                    aria-labelledby="discrete-slider-always"
                                    min={useTemperatureScale === 1 ? 0 : 32}
                                    max={useTemperatureScale === 1 ? 50 : 122}
                                    value={supportedValues.temperatureLimits[1]}
                                    valueLabelDisplay="off"
                                    step={1}
                                    disabled={!tempLimitFlags.enableHeatMode || !canUpdate}
                                  />
                                  <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][1])}</Typography>
                                </div>
                              }
                            </div>

                          </Card>}
                        {enableAppSettingsLimitCoolingSetpoint &&
                          <Card
                            className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <img
                                  src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
                                  className={classes.imgIconStyle}
                                ></img>
                                <Typography className={classes.unitTitle}>
                                  {t`Limit cooling setpoint range`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={tempLimitFlags.enableCoolMode}
                                onChange={() =>
                                  handleSetTempLimitRange("enableCoolMode")
                                }
                              />
                            </div>
                            <div className={classes.sliderHolder}>
                              {tempLimitFlags.enableCoolMode &&
                                <div className={classes.sliderAndLabelContainer}>
                                  <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[0][0])}</Typography>
                                  <Slider
                                    className={classes.coolSlider}
                                    onChange={handleSetCooling}
                                    aria-labelledby="discrete-slider-always"
                                    min={useTemperatureScale === 1 ? 0 : 32}
                                    max={useTemperatureScale === 1 ? 50 : 122}
                                    value={supportedValues.temperatureLimits[0]}
                                    valueLabelDisplay="off"
                                    step={1}
                                    disabled={!tempLimitFlags.enableCoolMode || !canUpdate}
                                  />
                                  <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[0][1])}</Typography>
                                </div>
                              }
                            </div>

                          </Card>}
                        {enableAppSettingsLimitAutoSetpoint &&
                          <Card
                            className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <img
                                  src={assetsService.getImagesPath(`auto.svg`)}
                                  className={classes.imgIconStyle}
                                ></img>
                                <Typography className={classes.unitTitle}>
                                  {t`Limit auto setpoint range`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={tempLimitFlags.enableAutoMode}
                                onChange={() =>
                                  handleSetTempLimitRange("enableAutoMode")
                                }
                              />
                            </div>
                            <div className={classes.sliderHolder}>
                              {tempLimitFlags.enableAutoMode &&
                                <div className={classes.sliderAndLabelContainer}>
                                  <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[2][0])}</Typography>
                                  <Slider
                                    className={classes.autoSlider}
                                    onChange={handleSetAuto}
                                    aria-labelledby="discrete-slider-always"
                                    step={1}
                                    min={temperatureScale === 1 ? 0 : 32}
                                    max={temperatureScale === 1 ? 50 : 122}
                                    value={supportedValues.temperatureLimits[2]}
                                    valueLabelDisplay="off"
                                    disabled={!tempLimitFlags.enableAutoMode || !canUpdate}
                                  />
                                  <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[2][1])}</Typography>
                                </div>
                              }
                            </div>
                          </Card>}

                        {enableAppSettingsLimitAutoSetpoint && <Card
                          className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                        >
                          <div className={classes.modeHeader}>
                            <div className={classes.unitModeHeader}>
                              <AutoDSMode maincolor={"#545964"} className={classes.AutoDSMode} />
                              <Typography className={classes.unitTitle}>
                                {t`Dual Setpoint (Virtual Auto)`}</Typography>
                            </div>
                          </div>
                          <div className={classes.sliderHolder}>
                            <div className={classes.sliderAndLabelContainer}>
                              <SliderDS
                                getAriaLabel={(index: number) => index === 0 ? "heat" : "cool"}
                                min={dsRanges[isCelsius ? 'c' : 'f'][0]}
                                max={dsRanges[isCelsius ? 'c' : 'f'][1]}
                                marks={dsRanges[isCelsius ? 'c' : 'f'].map(x => ({ value: x, label: `${x} ${tempSymbol}` }))}
                                value={[bottomLimit, topLimit]}
                                onChange={(_event: any, values: any) => {
                                  if (!canUpdate) {
                                    return;
                                  }
                                  const [bottom, top] = values[0] > values[1] ? [values[1], values[0]] : values;
                                  if (!(bottom <= top - 2)) {
                                    return;
                                  }
                                  if (bottom !== bottomLimit) {
                                    setBottomLimit(bottom);
                                  }
                                  if (top !== topLimit) {
                                    setTopLimit(top);
                                  }
                                }}
                                disabled={!canUpdate}
                              />
                            </div>
                          </div>
                        </Card>}

                        {enableAppSettingsSetpointControlResolution &&
                          <Card
                            className={clsx(classes.modeCardScheculeView, classes.removeRightMargin, { [classes.hide]: isFahrenheit })}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <Typography className={classes.unitTitle}>
                                  {t`Setpoint Control Resolution`}</Typography>
                              </div>
                              <div className={classes.setpointResolutionContainer}>
                                <Typography>0.5 C</Typography>
                                <CoolSwitch
                                  disabled={!canUpdate}
                                  checked={!supportedValues.isHalfCDegreeEnabled}
                                  onChange={(e: any) => setSupportedValues({ ...supportedValues, isHalfCDegreeEnabled: !e.target.checked })}
                                  classes={{
                                    root: classes.root,
                                    switchBase: classes.switchBase,
                                    thumb: classes.thumb,
                                    track: classes.track,
                                    checked: classes.checked
                                  }}
                                />
                                <Typography>1 C</Typography>
                              </div>
                            </div>
                          </Card>}
                        {enableAppSettingsTimer &&
                          <Card
                            className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                          >
                            <div className={classes.modeHeader}>
                              <div className={classes.unitModeHeader}>
                                <Typography className={classes.unitTitle}>
                                  {t`Auto Timer Off Button`}</Typography>
                              </div>
                              <CoolSwitch
                                disabled={!canUpdate}
                                checked={autoTimerEnabled}
                                onChange={(e: any) => setAutoTimerEnabled(e.target.checked)}
                              />
                            </div>
                          </Card>
                        }
                      </div>
                    </div>
                  </>

                  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Successfully saved!
                    </Alert>
                  </Snackbar>

                  <div className={classes.unitActions}>
                    {showSetpiontErrorMsg && <Typography className={classes.errorMsg}>{t`Cool has to be higher than heat by at least 2C degrees`}</Typography>}
                    <div className={classes.cancelBtn}>
                      <StyledButton
                        // disableRipple
                        white
                        disabled={!canUpdate}
                        disableElevation
                        onClick={() => handleCancel()}
                        variant="contained"
                        className={classes.cancelActionBtn}>{t`CANCEL`}
                      </StyledButton>
                    </div>
                    <div className={clsx(classes.saveBtn)}>
                      <StyledButton
                        disabled={!canUpdate}
                        disableElevation
                        onClick={() => handleSave(selectedUnit?.id)}
                        variant="contained"
                        className={clsx(classes.saveActionBtn,
                          { [classes.disabledSaveBtn]: false })}
                      >{t`SAVE`}
                      </StyledButton>
                    </div>
                  </div>
                </Card>
              }

              {canSee && (supportedValues.temperatureLimits && selectedUnit) && schedulesMemo()}

            </Grid>
            {canSee &&
              <Grid item xs={3}>
                <Card
                  className={classes.sideMenuContent}
                >
                  <div className={classes.settingsHeaderContainer}>
                    <Typography variant="h6" className={classes.headerTitle}>
                      {t`Copy Settings To`}
                    </Typography>
                  </div>
                  <Grid className={classes.unitsContainer}>
                    {memoSideMenu()}
                  </Grid>
                  <div className={classes.saveBtn}>
                    <StyledButton
                      width={140}
                      onClick={() => handleSave(selectedUnit?.id, true)}
                      disabled={!unitsToSave.length}
                      className={classes.applyBtn}

                    >{t`Apply`}
                    </StyledButton>
                  </div>
                </Card>
              </Grid>
            }
          </Grid>

        }
      </div>
    </div>
  );
};

export default UnitSetting;
