import { createStyles } from "@material-ui/styles";

export const datesListStyles = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    dateStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      padding: "5px 10px",
      margin: "3px",
      borderRadius: "3px",
      background: "#efeff2",
    },
    textStyle: {},
  });
