import { CircularProgress, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./PowerConsumptionGraph.style";

const PowerConsumptionGraph = () => {
  const classes = useStyles();
  const getMonthlyPowerData = useStoreActions((actions) => actions.ReportsData.getMonthlyPowerData);
  const { selections } = useStoreState((s) => s.selections);
  const { customerId, siteId } = selections;
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    setLoading(true);
    const lastSixMonths = moment().subtract(5, "months");
    const today = moment();

    getMonthlyPowerData({
      date: {
        startDate: lastSixMonths.toDate(),
        endDate: today.toDate()
      },
      customerId,
      siteId
    })
      .then((res: any) => {
        setData(res?.data);
        setLoading(false);
      });
  }, [customerId, siteId]);

  const CustomizedAxisTick = (props: any) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize="12px" fontWeight="bold">
          {moment.utc(payload.value).format("MMM")}
        </text>
      </g>
    );
  };

  return (
    <div className={classes.powerGraphContainer}>
      <div className={classes.cardHeader}>
        <Typography className={classes.cardTitle}>{t`HVAC Power Consumption`}</Typography>
      </div>

      <div className={classes.doubleGraphCont}>
        {!loading && data ?
          <ResponsiveContainer width="100%" height={"100%"}>
            <div>
              <p style={{ fontSize: "12px" }}>kW/h</p>
              <BarChart
                data={data}
                width={350}
                height={150}
                margin={{
                  top: 0,
                  right: 20,
                  left: 0,
                  bottom: 10
                }}
              >
                <XAxis
                  dataKey="timestamp"
                  tick={<CustomizedAxisTick />}
                  interval={0}
                />
                {data?.length && <YAxis
                  yAxisId="left"
                  domain={[0, "dataMax+1000"]}
                  name={t`Power`}
                  tick={{ fontSize: "10px" }}
                  tickFormatter={(value: any) => Math.round(value)}
                />}
                {
                  data?.length &&
                  <Tooltip
                    formatter={(value: any) => `${Math.round(value)} kW/h`}
                    labelFormatter={(val: any) => moment.utc(val).format("MMM, YYYY")}
                    cursor={{ fill: "rgba(128,128,128,0)" }}
                  />
                }
                <Legend
                  iconSize={10}
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                  wrapperStyle={{
                    marginTop: "-20px"
                  }}
                />
                <Bar
                  dataKey={"power"}
                  name={t`Consumption`}
                  stackId="a"
                  yAxisId="left"
                  fill={"#4b1c46"}
                  barSize={15}
                />
                <Bar
                  dataKey={"predicted"}
                  name={t`Forecast`}
                  stackId="a"
                  yAxisId="left"
                  fill={"#9d829b"}
                  barSize={15}
                />
              </BarChart>
            </div>
          </ResponsiveContainer>
          :
          <div className={classes.loaderHolder}><CircularProgress /></div>
        }
      </div>
    </div>
  );
};

export default PowerConsumptionGraph;
