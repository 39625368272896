import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: any) => ({
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  view: {
    display: "flex",
    height: "100%"
  },
  sideMenuContent: {
    height: "calc(100vh - 200px)",
    backgroundColor: "white"
  },

  user: {
    display: "flex",
    justifyContent: "space-between"
  },
  endActionTypeSelect: {
    marginLeft: "1rem",
    height: "2rem"
  },
  onEndSelect: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  errorTitle: {
    color: "red",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px"
  },

  eWrcDisableContainer: {
    width: "20%",
    display: "flex",
    flexDirection: "row-reverse",
    minHeight: "44px",
    alignItems: "center",
    justifyContent: "center"
  },

  switchTitle: {
    display: "inline-block"
  },
  endTypeLabel: {
    fontFamily: "RobotoMedium",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    display: "flex",
    alignItems: "center"
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column"
  },
  userContainer: {
    margin: "0 1rem 0 1rem",
    borderBottom: "1px solid lightgrey",
    "&:first-of-type": {
      margin: "-1rem 1rem 0 1rem"
    }
  },
  container: {
    height: "90%",
    overflowY: "auto",
    padding: "2rem"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.5rem",
    borderBottom: "2px solid rgba(60, 60, 60, 0.2)",
    margin: "1rem",
    zIndex: 100
  },
  headerApplyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1rem",
    height: 65,
    minHeight: 65,
    borderBottom: "solid 1.2px #e1e2ea",
    marginBottom: 20
  },

  settingsHeaderContainer: {
    display: "flex",
    alignItems: "center",
    height: 65,
    minHeight: 65,
    borderBottom: "solid 1.2px #e1e2ea",
    marginBottom: 20,
    marginLeft: "2rem",
    width: "94%"
  },

  settingsHeaderTitles: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between"
  },

  headerTitle: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
  },
  unitContainer: {
    border: "1px solid rgba(60, 60, 60, 0.2)",
    height: "5rem",
    margin: "1rem",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "1rem",
    boxShadow: "0 2px 0 -1px rgba(205, 205, 205, 0.5)"
  },
  unitsContainer: {
    overflow: "auto",
    height: "50%"
  },
  selectedUnit: {
    backgroundColor: "#edebed"
  },
  modeHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative"
  },
  alertGroupHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    margin: "1rem"
  },
  textField: {
    marginLeft: "10px",
    " & div": {
      height: "30px",
      width: "160px"
    }
  },
  alertGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    margin: "1rem"
  },
  icons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  switch: {
    display: "flex",
    justifyContent: "flex-end"
  },
  overrideFontSize: {
    fontSize: "2rem",
    "& svg": {
      width: "38px"
    }
  },
  rowContainer: {
    display: "flex",
    alignItems: "center"
  },
  alertTypesItem: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "0.5rem",
    border: "1px solid lightgray"
  },
  tempRangSlider: {
    marginBottom: "1rem"
  },
  gridLeft: {

  },
  restrictContainer: {
    marginTop: "1.75rem",
    marginRight: "2rem",
    borderBottom: "1px solid #e1e2ea",
    display: "flex",
    justifyContent: "space-between"
  },

  unitRestrictContainer: {
    marginBottom: "1.5rem",
    marginTop: 0
  },
  modeTitle: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "2rem",
    "& >svg>path": {
      stroke: "#4b1c46 !important"
    }
  },

  unitModeTitle: {
    marginLeft: 0
  },

  title: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "1.3rem",
    display: "inline",
    marginLeft: ".5rem"
  },

  topTitle: {
    fontFamily: "RobotoMedium",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    alignItems: "center",
    display: "flex"
  },

  unitTitle: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "1.3rem",
    display: "inline",
    marginLeft: ".5rem",
    marginTop: "0.5rem"
  },

  modesTitle: {
    // marginTop: "0.5rem"
  },

  modeImg: {
    marginBottom: "1.3rem",
    width: "26px",
    height: "26px",
    position: "absolute",
    top: "0",
    left: "0"
  },

  modeSlider: {
    marginBottom: "1.3rem"
  },
  modeContainer: {
    border: "solid 1px #d5d2d5",
    padding: "1rem 2rem 1rem 1rem ",
    margin: "0 1rem 1rem 1rem",
    height: "8rem",
    alignItems: "center",
    display: "inline-block",
    width: "30.3%",
    borderRadius: "6px",
    backgroundColor: "#ffffff"
  },
  wrcScheduleModeContainer: {
    height: "9rem"
  },

  wrcModeContainer: {
    width: "48%",
    margin: "1rem 0 2rem 0"
  },

  modeContainerUnitSettings: {
    width: "45%"
  },

  "@media (max-width: 1640px)": {
    modeContainerUnitSettings: {
      height: "95%"
    }
  },

  isVisibleContainerUnitSettings: {
    width: "48%",
    height: "3rem",
    display: "flex",
    justifyContent: "space-between",
    margin: "0"
  },
  isVisibleTitle: {
    display: "inline-block"
  },

  modesContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    width: "94%"
  },

  disabledView: {
    margin: "0 auto",
    width: "94.5%",
    height: "17.5rem"

  },

  saveActionBtn: {
    color: "#fff",
    height: "40px",
    fontSize: "16px",
    minWidth: "120px",
    fontWeight: "normal",
    lineHeight: "normal",
    borderRadius: "4px",
    textTransform: "uppercase"
  },
  disabledSaveBtn: {
    background: "#fff",
    color: "#4b1c46"
  },
  cancelActionBtn: {
    color: "#4b1c46",
    height: "40px",
    fontSize: "16px",
    minWidth: "120px",
    background: "#fff",
    fontWeight: 500,
    lineHeight: "normal",
    borderRadius: "4px",
    textTransform: "uppercase",
    border: "solid 1px #413044"
  },

  scheduleWrapper: {
    marginTop: "3rem",
    height: "500px"
  },

  gridContainer: {
    display: "inline-block"
  },
  unitActions: {
    flexDirection: "row-reverse",
    width: "97.5%",
    margin: "auto 0"
  },

  tStatContainer: {
    height: " 5rem",
    width: "95%",
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    margin: "0 auto",
    alignItems: "center"
  },

  tStatUnitContainer: {
    justifyContent: "space-between",
    height: " 4rem"
  },

  "@media (max-width: 1830px)": {
    tStatContainer: {
      width: "94.5%"
    }
  },

  modeContent: {
    overflow: "auto",
    backgroundColor: "white",
    width: "100%"
  },
  modeContentUnitSettings: {
    padding: "1rem 0"
  },
  tableContainer: {
    height: "calc(100vh - 175px)",
    width: "auto"
  },
  tableContainer1: {
    height: "calc(100vh - 185px)",
    margin: "auto",
    width: "95.5%"
  },
  tableHeadCell: {
    height: 56,
    padding: "16px 15px",
    color: theme.tables.headerCell.color,
    borderBottom: theme.tables.headerCell.borderBottom,
    textTransform: theme.tables.headerCell.textTransform,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    "& p": {
      fontSize: theme.tables.headerCell.fontSize,
      fontWeight: theme.tables.headerCell.fontWeight,
      lineHeight: theme.tables.headerCell.lineHeight,
      letterSpacing: theme.tables.headerCell.letterSpacing
    }
  },
  userName: {
    margin: "1rem 1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    "& >svg": {
      marginRight: "0.5rem"
    }
  },
  modeCardUnitSettings: {
    height: "32rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },

  emptyCard: {
    width: "45%"
  },

  limitsLineGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%"
  },

  unitVisibleToggleContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "47.5%"
  },

  switchListContainer: {
    width: "47.5%"
  },

  tooltip: {
    zIndex: 0,
    width: "4rem",
    height: "4rem"
  },
  tooltipBox: {
    lineHeight: ".4em",
    fontSize: "1rem",
    textAlign: "center",
    backgroundColor: "inherit",
    color: "rgba(97, 97, 97, 0.9)",
    padding: "0"
  },
  addIcon: {
    width: "30px",
    height: "30px",
    borderRadius: "4px",
    backgroundColor: "#f6f5f6",
    color: "#7f7692"
  },

  fanModes: {
    "& *": {
      fill: "#4b1c46"
    }
  },
  unitListVisibility: {
    display: "flex",
    padding: "0 20px 23px 20px",
    justifyContent: "space-between"
  },

  saveBtn: {
    display: "flex",
    justifyContent: "flex-start",
    marginRight: "20px",
    "& button": {
      width: "10rem"
    }
  },

  saveLocksBtn: {
    // marginRight: 0,
  },
  applyBtn: {
    marginLeft: "1.1rem",
    marginTop: "1rem"
  },

  scheduleContainer: {
    overflowY: "auto",
    width: "100%",
    padding: "12px 12px 0px 12px"
  },
  newScheduleBtn: {
    display: "flex",
    justifyContent: "space-between"
  },

  plusIcon: {
    padding: "2px",
    borderRadius: "5px",
    "& svg ": {
      "& g": {
        "& rect": {
          fill: "none"
        }
      }
    },
    "&:hover": {
      "& $addAlertIcon": {
        display: "none"
      },
      "& $addOnHoverIcon": {
        display: "inline"
      }
    }
  },

  nameSettingContainer: {
    display: "flex",
    marginBottom: "20px",
    alignItems: "baseline"
  },
  timeSettingContainer: {
    width: "93%",
    display: "flex",
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    marginBottom: "1rem",
    paddingBottom: 8
  },

  "@media (max-width: 1200px)": {
    timeSettingContainer: {
      padding: "0 90px 7px 0"
    }
  },

  timeSettingsTitle: {
    display: "inline-block",
    color: "#545964",
    fontSize: "15px",
    fontFamily: "RobotoBold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginRight: 15
  },

  weekDaysConatiner: {
    display: "flex",
    alignItems: "center",
    "& div": {
      "& *": {
        marginTop: 0
      }
    }
  },

  timeBtns: {
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    color: "#545964",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },

  timeCheckbox: {
    color: "#545964",
    fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginRight: "1.5rem",
    display: "inline-block"
  },

  grayCheckbox: {
    "&$checked": {
      color: "#736973"
    }
  },
  rightTimeBtn: {
    marginLeft: ".5rem",
    marginRight: "1.5rem"
  },

  topTitlePosition: {
    marginRight: "1.5rem",
    fontSize: "16px"
  },

  nameInput: {
    "& input": {
      color: "#545964",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal"
    }
  },

  schedulerContainer: {
    width: "32%"

  },

  limitsSchedulerLineGroup: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "0"
  },

  modeCardUnitScheduleSettings: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: "1rem"
  },

  tStatScheduleContainer: {
    height: "initial",
    marginBottom: "1rem",
    margin: "initial",
    width: "100%",
    justifyContent: "space-between"
  },
  modeContentUnitScheduleSettings: {
    padding: " 1rem"
  },

  localTimePicker: {
    width: 280,
    height: 140,
    position: "relative",
    "& div button": {
      color: "#545964"
    },

    "& > div > div": {
      width: "280px",
      height: "187px",
      border: "1px solid #d5d2d5",
      backgroundColor: "white"
    }
  },

  viewModeContent: {
    border: "solid 1px #968896",
    margin: "1rem 5px",
    width: "98%"
  },

  viewNameSettingContainer: {
    border: "none",
    marginBottom: 0,
    padding: "11px 0 7px 0",
    justifyContent: "space-between",
    width: "100%"
  },

  editNameSettingContainer: {
    width: "100%"
  },

  viewTopTitle: {
    fontSize: "16px",
    color: "#545964",
    marginLeft: 0
  },

  viewModeContainer: {
    height: "initial",
    margin: "0 .5rem 1rem 0",
    width: "33%"

  },

  viewEWrcModeContainer: {
    margin: "0 .5rem 1rem 0"
  },

  editModeContainer: {
    margin: "0",
    width: "33%"
  },

  isWrc: {
    width: "49%"
  },

  viewLimitsSchedulerLineGroup: {
    display: "flex",
    // flexDirection: "column",
    width: "calc(100% + 20px)",
    margin: "0",
    justifyContent: "flex-start"

  },

  "@media (max-width: 1700px)": {
    viewLimitsSchedulerLineGroup: {
      // width: "98.3%"
    }
  },

  viewTimeSettingContainer: {
    justifyContent: "flex-start",
    border: "none",
    width: "100%",
    marginLeft: "-7px",
    paddingRight: 0
  },
  editTimeSettingContainer: {
    width: "100%"
  },

  viewTimeBtns: {
    display: "inline-block"
  },

  unitScheduleActions: {
    flexDirection: "row-reverse",
    marginLeft: "1.5rem",
    width: "100%"
  },

  viewDegree: {
    color: "#545964",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: "2.5rem"
  },

  autoSlider: {
    left: "10px",
    "& .MuiSlider-root": {
      left: "10px"
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: "rgba(97, 97, 97, 0.9)",
        fontSize: "1rem",
        width: "50px"
      }
    }
  },

  heatSlider: {
    color: "#f05146",
    left: "10px",
    "& .MuiSlider-root": {
      left: "10px",
      color: "#f05146"
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: "rgba(97, 97, 97, 0.9)",
        fontSize: "1rem",
        width: "50px"
      }
    },
    "& .MuiSlider-rail": {
      color: "rgba(180, 175, 203, 0.63)"
    }
  },

  coolSlider: {
    color: "#35a8e0",
    left: "10px",
    "& input": {
      fontSize: "8px"
    },
    "& .MuiSlider-root": {
      left: "10px",
      color: "#35a8e0"
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: "rgba(97, 97, 97, 0.9)",
        fontSize: "1rem",
        width: "50px"
      }
    },
    "& .MuiSlider-rail": {
      color: "rgba(180, 175, 203, 0.63)"
    }
  },

  confirmationDialog: {
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },

  confirmationTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e"
  },
  alertGroupstitle: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
  },

  confirmationDialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0
  },
  confirmationContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  confirmationDialogActions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "1rem"
  },
  addContactIcon: {
    marginRight: "2px"
  },
  contactActionsContainer: {
    display: "flex",
    alignItems: "center"
  },
  innerTableContainer: {
    marginBottom: "2rem"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle1: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 100,
    maxHeight: "85vh"
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
    right: 0,
    background: theme.palette.colors.white,
    marginRight: 10,
    width: "100%"
  },

  actionsHolderScheduler: {
    padding: "20px 8px 5px 0",
    display: "flex",
    justifyContent: "flex-end"
  },

  actionBtnWrapper: {
    width: "18rem",
    display: "flex",
    justifyContent: "space-between"
  },
  tableData: {
    padding: "6px 24px 6px 11px"
  },
  cutLongNames: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "70%"
  },
  sliderAndLabelContainer: {
    display: "flex"
  },
  rightLabelForamt: {
    width: "60px",
    textAlign: "center",
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  leftLabelForamt: {
    width: "60px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  root: {
    width: "500px"
  },
  indicatorColor: {
    backgroundColor: theme.palette.primary.main
  },
  selectModeStyle: {
    fontSize: "13px",
    lineHeight: "normal",
    fontWeight: "normal",
    margin: "10px 4px",
    display: "flex",
    alignItems: "center",
    color: theme.overrides.MuiButton.contained.color,
    width: "100%",
    marginLeft: "4px",
    marginBottom: "16px"
  },
  marginSelectModeStyleDates: {
    margin: "22px 24px 0px"
  },
  daysContainer: {
    padding: "0px 0px",
    margin: "0px 0px",
    justifyContent: "space-between",
    width: "auto"
  },
  calendar: {
    fontFamily: "Roboto",
    "& .DayPicker-Month": {
      width: "290px",
      height: "290px"
    },
    "& .DayPicker-Weekday": {
      color: theme.palette.colors.black
    },
    "& .DayPicker-Day": {
      color: theme.palette.colors.black
    },
    "& .DayPicker-Day--disabled": {
      color: alpha(theme.palette.colors.black || "#000", 0.2)
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover": {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    "& .DayPicker:not(.DayPicker--interactionDisabled):hover": {
      backgroundColor: `${alpha(theme.palette.colors.black || "#000", 0.1)} !important`
    },
    "& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover": {
      backgroundColor: `${alpha(theme.palette.colors.black || "#000", 0.1)} !important`
    }
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  switchItem: {
    margin: "2.5px !important",
    minWidth: "32%"
  },
  dialogContainer: {
    position: "relative",
    overflow: "hidden",
    minWidth: "900px"
  },
  schedules: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  divider: {
    margin: "20px",
    borderTop: "solid 1px #d5d2d5"
  },
  txtfield: {
    height: "45px",
    "&:div": {
      height: "45px"
    }
  },
  inputField: {
    width: 292,
    height: 45,
    padding: "7px 15px",
    borderRadius: 4,
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    marginLeft: 15
  }

}));
export default useStyles;
