import * as React from "react";

function SvgDashboardMobile(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path id="DashboardMobile_svg__a" d="M.001 0H26v26H.001z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="DashboardMobile_svg__b" fill="#fff">
            <use xlinkHref="#DashboardMobile_svg__a" />
          </mask>
          <path
            fill={props.color || "#AAA2AA"}
            d="M6.343 21.463c1.9-1.38 4.21-2.137 6.656-2.137 2.443 0 4.756.756 6.658 2.135a10.72 10.72 0 01-13.314.002M13 2.219c5.945 0 10.781 4.836 10.781 10.78 0 2.62-.94 5.024-2.5 6.893-2.336-1.804-5.23-2.787-8.28-2.787-3.056 0-5.944.986-8.278 2.79A10.732 10.732 0 012.22 13C2.22 7.055 7.056 2.219 13 2.219m0-2.22c-7.169 0-13 5.834-13 13 0 7.169 5.831 13 13 13 7.168 0 13-5.831 13-13 0-7.166-5.832-13-13-13"
            mask="url(#DashboardMobile_svg__b)"
          />
        </g>
        <path
          fill={props.color || "#AAA2AA"}
          d="M12.984 15.616a2.616 2.616 0 002.535-3.294L18.54 9.3a1.3 1.3 0 10-1.84-1.839l-3.022 3.02a2.614 2.614 0 00-2.99 3.741 2.597 2.597 0 002.296 1.394"
        />
      </g>
    </svg>
  );
}

export default SvgDashboardMobile;
