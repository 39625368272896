import * as React from 'react';

function SvgAutomationLogic(props) {
  return (
    <svg width="24px" height="24px" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.734 13.316a2.237 2.237 0 110-4.474 2.237 2.237 0 010 4.474zm6.614-1.136V9.976l-1.836-.49a4.964 4.964 0 00-.288-.636l.965-1.67-1.558-1.557-1.731 1a4.995 4.995 0 00-.546-.218l-.519-1.94h-2.202l-.52 1.94a4.773 4.773 0 00-.544.22L7.837 5.623 6.279 7.18l.96 1.661a4.84 4.84 0 00-.283.646l-1.835.49v2.203l1.726.462c.081.28.188.55.313.808l-.88 1.526 1.557 1.558 1.454-.842c.288.158.593.29.91.39l.432 1.61h2.202l.431-1.61c.318-.1.623-.232.911-.39l1.454.842 1.558-1.558-.881-1.526a5.12 5.12 0 00.313-.808l1.727-.462z"
          fillOpacity={props.opacity || 0.6}
          fill="#FFF"
        />
        <path
          d="M22.023 13.832c-1.133 4.63-5.31 8.066-10.29 8.066-4.978 0-9.155-3.435-10.288-8.066m.065-5.305C2.733 4.026 6.848.714 11.735.714c4.946 0 9.1 3.391 10.265 7.976"
          strokeOpacity={props.opacity || 0.6}
          stroke="#FFF"
          strokeWidth={1.3}
        />
        <path
          strokeOpacity={props.opacity || 0.6}
          stroke="#FFF"
          strokeWidth={1.3}
          d="M23.99 6.116l-1.666 3.456-3.456-1.666m-19.324 8.74L1.21 13.19l3.457 1.666"
        />
      </g>
    </svg>
  );
}

export default SvgAutomationLogic;
