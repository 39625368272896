import * as React from "react";

function SvgMobileCalendar(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(.658 .243)"
        stroke="#AAA2AA"
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill="#D8D8D8"
          d="M5.053.5h1.276v1.276H5.053zm1.138 10.243h1.276v1.276H6.191zm4.552 0h1.276v1.276h-1.276zm4.553 0h1.276v1.276h-1.276zM16.434.5h1.276v1.276h-1.276z"
        />
        <rect
          width={19.087}
          height={19.087}
          x={1.838}
          y={2.976}
          strokeWidth={1.4}
          rx={2}
        />
        <path
          strokeLinecap="square"
          strokeWidth={1.4}
          d="M2.845 8.536h17.073"
        />
      </g>
    </svg>
  );
}

export default SvgMobileCalendar;
