import {
  Dialog,
  IconButton,
  Typography
} from "@material-ui/core";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import UserDetailsField from "../../screens/UserManagement/UserDetailsField";
import UserDetailsMyProfile from "../../screens/UserManagement/UserDetailsMyProfile";
import useStyles from "./ProfileEdit.style";

const UserSchema = Yup.object().shape({
  firstName: Yup.string().max(20, t`Too Long!`),
  lastName: Yup.string().max(20, t`Too Long!`),
  email: Yup.string()
    .email(t`Invalid email`)
    .required(t`Required`),
  username: Yup.string()
    .min(6, t`Too Short!`)
    .max(50, t`Too Long!`)
    .required(t`Required`),
  phone: Yup.string()
    .test(
      "phone validation",
      t`Phone number should be 10 chars minimum.`,
      (value) => {
        if (!value) {
          return true;
        }

        let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
        newValue = newValue.replace(/\s/g, "");

        if (newValue.length > 9 || newValue.length === 0) {
          return true;
        }
        return false;
      }
    ),
  showClearFilterAlerts: Yup.boolean()
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string().matches(
    /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    t`must be at least 8 chars and contain 1 capital letter and 1 digit`
  ),
  newPassword: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t`must be at least 8 chars and contain 1 capital letter and 1 digit`)
    .notRequired()
    .when("password", {
      is: (val) => val !== undefined,
      then: Yup.string().required(t`Required`)
    }),
  repeatPassword: Yup.string()
    .when("newPassword", {
      is: (val) => val !== undefined && val !== "",
      then: Yup.string().required(t`Required`)
        .oneOf([Yup.ref("newPassword"), null], t`Passwords must match`)
    })
});

const ProfileEdit: React.FC<RouteComponentProps<any> & any> = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [openCustomersNicknames, setOpenCustomersNicknames] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(null);

  const updateUserPref = useStoreActions((action) => action.users.updateUserPreferences);
  const userPref = useStoreState((state) => state.users.userPreferences);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const user = useStoreState((state) => state.users.me);
  const updateMe = useStoreActions((action) => action.users.updateMe);
  const types = useStoreState((state) => state.types);
  const updatePassword = useStoreActions((action) => action.users.updatePassword);
  const selections = useStoreState((state) => state.selections.selections);
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const { customerId } = selections;
  const displayFlags = displayFlagsMap[customerId || ""] || {};
  const getAllUnits = useStoreActions((action) => action.units.getAndReplaceUnits);

  const [open, setOpen] = React.useState(false);

  const { temperatureScale, pressureScale } = types;
  const userPreferences = useStoreState((state) => state.users.userPreferences);

  const initialValues: any = {
    firstName: user.firstName ? user.firstName : "",
    lastName: user.lastName ? user.lastName : "",
    username: user.username ? user.username : "",
    email: user.email ? user.email : "",
    phone: user?.phone ? user?.phone : "",
    temperatureScale: user.temperatureScale ? user.temperatureScale : 1,
    measurementUnits: user.measurementUnits ?? 1,
    language: userPreferences.professionalLanguage || "en",
    lockItemsReorder: userPreferences.lockItemsReorder || false,
    timeFormat: user.timeFormat,
    dateFormat: user.dateFormat,
    showClearFilterAlerts: !!userPref?.showClearFilterAlerts,
    is2FA: user?.is2FA || false
  };

  const passwordInitialValues: any = {
    password: "",
    newPassword: "",
    repeatPassword: ""
  };

  const onPasswordChangeSubmit = (values: any) => {
    if (values.password && values.newPassword && values.repeatPassword) {
      updatePassword({
        oldPassword: values.password,
        newPassword: values.newPassword
      })
        .then(() => closePasswordChange())
        .catch((e: any) => setPasswordError(e.message));
    }
  };

  const updateCustomersNicknames = (values: any) => {
    updateUserPref({ nicknames: values })
      .finally(() => {
        toggleCustomersNicknamesDialog(false);
      })
  }

  const closePasswordChange = () => {
    setPasswordOpen(false);
  };

  const toggleCustomersNicknamesDialog = (isOpen: boolean) => {
    setOpenCustomersNicknames(isOpen);
  };

  const onUserEditSubmit = (values: any, actions: any) => {
    const { showClearFilterAlerts, lockItemsReorder, ...userData } = values;
    values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values.phone.replace(/\s/g, "");
    const isShowAlertChanged = userPref.showClearFilterAlerts !== showClearFilterAlerts;
    const isLanguageChanged = userPref.professionalLanguage !== values.language;
    const isLockChanged = userPref.lockItemsReorder !== lockItemsReorder;
    const isTempScaleChanged = values.temperatureScale !== user.temperatureScale;
    const payload = {
      ...userData
    };
    updateMe(payload)
      .then(() => {
        isTempScaleChanged && getAllUnits();
        return (isShowAlertChanged || isLanguageChanged || isLockChanged) ? updateUserPref({ showClearFilterAlerts, professionalLanguage: values.language, lockItemsReorder: values.lockItemsReorder }) : false;
      })
      .then(() => {
        handleClose();
      })
      .catch((e: any) => setSubmitError(e.message));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const passwordBox = passwordError ? (
    <ErrorBox error={passwordError} onClose={() => setPasswordError(null)} />
  ) : null;

  return (
    <>
      <div className={classes.dropDownItem} onClick={handleClickOpen}>{t`My Profile`}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        className={classes.dialog}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`My Profile`}</Typography>
          <IconButton disableRipple onClick={() => setOpen(false)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onUserEditSubmit}
          enableReinitialize={true}
          validationSchema={UserSchema}
        >
          {({ handleSubmit, errors, touched, ...restFormikProps }) => (
            <form onSubmit={handleSubmit} className={classes.dialogContent}>
              <UserDetailsMyProfile
                errors={errors}
                touched={touched}
                user={user}
                temperatureScale={temperatureScale}
                pressureScale={pressureScale}
                showLanguage={displayFlags.enableLanguageSelection}
                setPasswordOpen={setPasswordOpen}
                passwordOpen={passwordOpen}
                toggleCustomersNicknamesDialog={toggleCustomersNicknamesDialog}
                {...restFormikProps}
                {...props}
              />
              <div className={classes.buttonsContainer}>
                {submitError &&
                  <ErrorBox error={submitError} onClose={() => setSubmitError(null)} />
                }
                <Button
                  onClick={handleClose}
                  width={150}
                  white
                  marginRight
                >
                  {t`Cancel`}
                </Button>
                <Button
                  onMouseUp={handleSubmit}
                  width={150}
                >
                  {t`Submit`}
                </Button>
              </div>
            </form>
          )}
        </Formik>
        {passwordOpen && <Dialog
          open={passwordOpen}
          onClose={closePasswordChange}
          maxWidth="xs"
          fullWidth
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{t`Change Password`}</Typography>
            <IconButton disableRipple className={classes.iconBtnStyle} onClick={closePasswordChange}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
          <Formik
            initialValues={passwordInitialValues}
            onSubmit={onPasswordChangeSubmit}
            enableReinitialize={true}
            validationSchema={PasswordSchema}
          >
            {({ handleSubmit, values, dirty, errors, touched, ...restFormikProps }) => (
              <form onSubmit={handleSubmit} className={classes.dialogContent}>
                <div className={classes.PasswordChangeContent}>
                  <Field
                    label={t`Current Password`}
                    value={values.password}
                    name="password"
                    type="password"
                    component={UserDetailsField}
                    className={classes.passwordfield}
                    error={errors.password && touched.password ? true : false}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : t`8+ characters, 1 capital, 1 number`
                    }
                  />
                  <Field
                    label={t`New Password`}
                    value={values.newPassword}
                    name="newPassword"
                    type="password"
                    component={UserDetailsField}
                    className={classes.passwordfield}
                    error={errors.newPassword ? true : false}
                    helperText={
                      errors.newPassword
                        ? errors.newPassword
                        : t`8+ characters, 1 capital, 1 number`
                    }
                  />
                  <Field
                    label={t`Confirm New Password`}
                    value={values.repeatPassword}
                    name="repeatPassword"
                    type="password"
                    component={UserDetailsField}
                    className={classes.passwordfield}
                    error={errors.repeatPassword ? true : false}
                    helperText={
                      errors.repeatPassword
                        ? errors.repeatPassword
                        : t`8+ characters, 1 capital, 1 number`
                    }
                  />
                </div>

                <div className={classes.buttonsContainer}>
                  {passwordBox}
                  <Button
                    onClick={closePasswordChange}
                    width={150}
                    white
                    marginRight
                  >
                    {t`Cancel`}
                  </Button>
                  <Button
                    type="submit"
                    width={150}
                    disabled={!dirty}

                  >
                    {t`Save Password`}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Dialog>}

        {openCustomersNicknames && <Dialog
          open={openCustomersNicknames}
          onClose={() => toggleCustomersNicknamesDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{t`Update Customers Nicknames`}</Typography>
            <IconButton
              disableRipple
              className={classes.iconBtnStyle}
              onClick={() => toggleCustomersNicknamesDialog(false)}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
          <Formik
            initialValues={Object.keys(allCustomers).reduce((obj: any, id: any) => {
              obj[id] = (userPref?.nicknames && userPref?.nicknames[id]) || "";
              return obj;
            }, {})}
            onSubmit={updateCustomersNicknames}
            enableReinitialize={true}
          >
            {({ handleSubmit, values, dirty, errors, touched, ...restFormikProps }) => (
              <form onSubmit={handleSubmit} className={classes.dialogContent}>
                <div className={classes.PasswordChangeContent}>
                  {
                    Object.keys(allCustomers).map((id: any) => {

                      return (
                        <Field
                          label={`${allCustomers[id]?.name}`}
                          value={values[id]}
                          name={`${id}`}
                          component={UserDetailsField}
                          className={classes.passwordfield}
                        />

                      )
                    })
                  }

                </div>

                <div className={classes.buttonsContainer}>
                  {passwordBox}
                  <Button
                    onClick={() => toggleCustomersNicknamesDialog(false)}
                    width={150}
                    white
                    marginRight
                  >
                    {t`Cancel`}
                  </Button>
                  <Button
                    type="submit"
                    width={150}
                    disabled={!dirty}

                  >
                    {t`Save`}
                  </Button>
                </div>
              </form>
            )}
          </Formik>

        </Dialog>}
      </Dialog>
    </>
  );
};
export default ProfileEdit;
