import {
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Arrow as SvgArrow } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { manuallySetSystemViewStyle } from "./Device/ManuallySetSystems.style";

interface IProps {
  title: string;
  closeModal: any;
  classes?: any;
  import?: boolean;
  deviceId?: string;
}

export default withStyles(manuallySetSystemViewStyle)(function ManuallySetSensors(props: IProps) {
  const { classes, deviceId } = props;
  const {
    modalActions,
    systemAttributes
  } = classes;

  const updateSensor = useStoreActions((action) => action.sensors.updateSensor);
  const getSensorsByDevice = useStoreActions((action) => action.sensors.getSensorsByDevice);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [deviceSensors, setDeviceSensors] = React.useState<any>();
  const [sensorData, setSensorData] = React.useState<any>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>([]);
  const [stringRanges, setStringRanges] = useState<any>({});
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  const types = useStoreState((state) => state.types);
  const { sensorTypes, sensorMeasurementUnits } = types;

  useEffect(() => {
    if (!deviceId) {
      return;
    }

    getSensorsByDevice(deviceId)
      .then((sensors: any) => {

        const sensorObject: any = {};

        Object.values(sensors).forEach((sensor: any) => {
          const { id } = sensor;
          stringRanges[sensor.id] = sensor?.userData?.rangeMax ? `${sensor?.userData?.rangeMin}-${sensor?.userData?.rangeMax}` : "0-100";
          sensorObject[id] = { ...sensor };
        });

        setSensorData(sensorObject);
      });

  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    const updateAll: any = [];
    Object.values(sensorData).forEach((data: any) => {
      const emptyName = errors[data.id]?.nameError;
      const rangeError = errors[data.id]?.rangeError;
      if (rangeError || emptyName) {
        return;
      }
      const defaultUserData = sensorTypes[data.type].enableMeasurementUnitSelection ? (data?.userData?.measurementUnitsType ? data.userData : { ...data.userData, measurementUnitsType: 1 }) : data.userData;
      const updatedData: any = {
        name: data.name,
        type: data.type,
        userData: defaultUserData,
        model: data.model,
        isVisible: data.isVisible,
      };
      updateAll.push(updateSensor({ id: data.id as string, updatedData }));

    });
    Promise.all(updateAll)
      // .then(() => {
      //   return getSensorsByDevice(deviceId as string);
      // })
      .then(() => {
        // setDeviceSensors({ ...res });
        props.closeModal();
        setIsSaving(false);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });

  };
  const getColumnHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          >
            {t`Sensor name`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          >
            {t`Type`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          />

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          >
            {t`Units`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          >
            {t`Model`}
          </TableCell>
          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          >
            {t`CA Line - Id`}
          </TableCell>
          <TableCell
            classes={{ root: classes.tableHeadCell }}
            align="left"
          >
            {t`Visible`}
          </TableCell>
        </TableRow>
      </TableHead>

    );
  };

  const handleInput = (data: any, type: string, id: string) => {
    const newSensorData: any = sensorData[id];
    if (type === "name") {
      if (!data) {
        setErrors({ ...errors, [id]: { ...errors[id], nameError: "Required" } });
      } else {
        setErrors({ ...errors, [id]: { ...errors[id], nameError: "" } });
      }
      newSensorData.name = data;
    }
    if (type === "model") {
      newSensorData.model = data;
    }
    setSensorData({ ...sensorData, [id]: newSensorData });
  };

  const handleChange = (data: any, type: string, id: string) => {
    const newSensorData: any = sensorData[id];
    if (!newSensorData) {
      console.log(`error - didn't find ${id} sensor`);
    }
    if (type === "name" || type === "model" || type === "type" || type === 'isVisible') {
      newSensorData[type] = data;
    } else if (type === "minMax") {
      setErrors({ ...errors, [id]: { ...errors[id], rangeError: "" } });
      const regex = /\d+(\.\d+)?-\d+(\.\d+)?/ ///\d+-{1,}\d+/g;//\d+(\.\d+)?$
      const range = data.match(regex) || "";
      if (!range) {
        setErrors({ ...errors, [id]: { ...errors[id], rangeError: "follow the pattern min-max" } });
        return;
      }
      const minMax = range[0].split("-");
      const min = +minMax[0];
      const max = +minMax[1];
      if (min >= max) {
        setErrors({ ...errors, [id]: { ...errors[id], rangeError: "min should be smaller than max" } });
        return;
      }
      newSensorData.userData = { ...newSensorData.userData, ...{ rangeMin: min, rangeMax: max } };
    } else if (type === "normalStateValue" || type === "measurementUnitsType") {
      newSensorData.userData = { ...newSensorData.userData, ...data };
    }
    setSensorData({ ...sensorData, [id]: newSensorData });
  };

  const disableEdit = Object.values(sensorData || {}).every(({ canUpdate = true }: any) => !canUpdate);

  return (
    <Dialog classes={{ paperWidthLg: classes.smallDialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
      <div className={classes.dialogTitle}>
        <Typography className={classes.dialogText}>{t`Edit Aux Devices`}</Typography>
        <IconButton disableRipple onClick={() => props.closeModal()} className={classes.iconBtnStyle}>
          <Close />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <Paper className={systemAttributes}>
          <Card key="1" className={classes.otherFormWarpper}>
            <Typography className={classes.otherFormWarpperTitle}>
              {props.title}
            </Typography>

            <div className={classes.scrollableTable}>
              <form onSubmit={() => {
              }} style={{
                display: "flex",
                flexFlow: "column",
                flex: 1,
                height: "100%"
              }}>
                {isSaving ? (<div className={classes.loader}>
                  <CircularProgress />
                  <Typography className={classes.message}> Saving</Typography>
                </div>)
                  :
                  <TableContainer >
                    <Table
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      {getColumnHeader()}
                      <TableBody>

                        {sensorData && _.sortBy(sensorData, ["dataAddress"], ["asc"]).map((sensor: any) => {
                          const { availableTypes, id, dataAddress, name = "", model = "", type, userData = {}, canUpdate = true, isVisible = false } = sensor;
                          const { normalStateValue = "0", measurementUnitsType = "1" } = userData;
                          const rangeError = errors[id] && errors[id]?.rangeError || "";
                          const nameError = errors[id] && errors[id]?.nameError || "";
                          const rangeString = stringRanges[id];
                          return (
                            <TableRow key={id}>
                              <TableCell
                                align="left"
                                style={{ width: "150ch", height: "44px", maxHeight: "44px" }}
                              >
                                <FormControl
                                  className={clsx(classes.inputFormWrapper, classes.textField)}
                                  variant="filled"
                                >
                                  <OutlinedInput
                                    className={clsx(classes.inputFieldStyle)}
                                    disabled={!canUpdate}
                                    onChange={((event) => {
                                      handleInput(event.target.value, "name", id);
                                    })}
                                    onBlur={((event) => {
                                      handleChange(event.target.value, "name", id);
                                    })}
                                    value={name}
                                    margin="dense"
                                    error={!!nameError}
                                  />
                                  {nameError && <FormHelperText className={classes.rangeError}>{nameError}</FormHelperText>}
                                </FormControl>
                              </TableCell>

                              <TableCell align="left" className={classes.mediumCell}>
                                {availableTypes.length === 1 ?
                                  <Typography>
                                    {sensorTypes[type].name}
                                  </Typography>
                                  : <Select
                                    disabled={!canUpdate}
                                    variant="outlined"
                                    labelId="select-label"
                                    id="ssytems-select"
                                    value={type}
                                    onChange={(event: any) => {
                                      handleChange(event.target.value, "type", id);
                                    }}
                                    className={classes.unFocusedSelect}
                                    IconComponent={SvgArrow}
                                    style={{ width: "150px", height: "44px", maxHeight: "44px" }}
                                    margin="none"
                                    displayEmpty
                                  >
                                    {
                                      availableTypes.map((type: any, index: number) => (
                                        <MenuItem key={`option-${index}`} value={type}>{sensorTypes[type].name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                }
                              </TableCell>

                              <TableCell align="left">
                                <Typography className={classes.mediumCell}>
                                  {sensorTypes[type].enableNormalModeSelection ?
                                    <>
                                      <Select
                                        disabled={!canUpdate}
                                        variant="outlined"
                                        labelId="select-label"
                                        id="ssytems-select"
                                        value={normalStateValue}
                                        onChange={(event: any) => {
                                          handleChange({ normalStateValue: event.target.value }, "normalStateValue", id);
                                        }}
                                        className={classes.unFocusedSelect}
                                        IconComponent={SvgArrow}
                                        style={{ width: "180px", height: "44px", maxHeight: "44px" }}
                                        margin="none"
                                        displayEmpty
                                      >
                                        <MenuItem key={`option-${0}`} value={1}>{t`Open = Normal`}</MenuItem>
                                        <MenuItem key={`option-${1}`} value={0}>{t`Close = Normal`}</MenuItem>
                                      </Select>
                                    </>
                                    : sensorTypes[type].enableMinMaxSelection ?
                                      <>
                                        <OutlinedInput
                                          disabled={!canUpdate}
                                          onChange={((event) => {
                                            setStringRanges({ ...stringRanges, [id]: event.target.value });
                                          })}
                                          onBlur={((event) => {
                                            handleChange(event.target.value, "minMax", id);
                                          })}
                                          value={rangeString}
                                          margin="dense"
                                          className={classes.inputFieldStyle}
                                          placeholder={t`min-max`}
                                          error={!!rangeError}
                                        />
                                        {rangeError && <FormHelperText className={classes.rangeError}>{rangeError}</FormHelperText>}
                                      </>
                                      : <> </>
                                  }
                                </Typography>
                              </TableCell>

                              <TableCell align="left" className={classes.mediumCell}>
                                {sensorTypes[type].enableMeasurementUnitSelection &&
                                  <Select
                                    disabled={!canUpdate}
                                    variant="outlined"
                                    labelId="select-label"
                                    id="ssytems-select"
                                    value={measurementUnitsType}
                                    onChange={(event: any) => {
                                      handleChange({ measurementUnitsType: event.target.value }, "measurementUnitsType", id);
                                    }}
                                    className={classes.unFocusedSelect}
                                    IconComponent={SvgArrow}
                                    style={{ width: "150px", height: "44px", maxHeight: "44px" }}
                                    margin="none"
                                    displayEmpty
                                  >
                                    {
                                      Object.values(sensorMeasurementUnits).map((MUnit: any, index: number) => (
                                        MUnit.enableSelection && <MenuItem key={`option-${index}`} value={index + 1}>
                                          {MUnit.name === "Temperature" ? temperatureScale === 1 ? "°C" : "°F" : MUnit.name}
                                        </MenuItem>))
                                    }
                                  </Select>
                                }
                              </TableCell>

                              <TableCell align="left" className={classes.wideCell}>
                                <FormControl
                                  className={clsx(classes.inputFormWrapper, classes.textField)}
                                  variant="filled"
                                >
                                  <OutlinedInput
                                    disabled={!canUpdate}
                                    className={clsx(classes.inputFieldStyle)}
                                    onChange={((event) => {
                                      handleInput(event.target.value, "model", id);
                                    })}
                                    onBlur={((event) => {
                                      handleChange(event.target.value, "model", id);
                                    })}
                                    value={model}
                                    margin="dense"
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell align="left">
                                <Typography className={classes.caRow}>
                                  {dataAddress}
                                </Typography>
                              </TableCell>

                              <TableCell align="left">
                                <Checkbox
                                  onChange={(event: any) => { handleChange(event.target.checked, "isVisible", id) }}
                                  checked={isVisible}
                                  className={classes.checkboxStyle}
                                />
                              </TableCell>

                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>}
              </form>
            </div>
          </Card>
          <div className={modalActions}>

            <Button
              disabled={disableEdit}
              title={t`close edit`}
              variant="contained"
              onClick={props.closeModal}
              width={150}
              white="white"
              marginRight={true}
            >
              {t`Cancel`}
            </Button>

            <Button
              disabled={disableEdit}
              title={t`save system`}
              type="submit"
              width={150}
              onClick={handleSave}
            >
              {t`Save`}
            </Button>
          </div>
        </Paper>
      </div>
    </Dialog>
  );

});
