import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { t } from "ttag";
import FilterRequest from "../../icons/MobileFilterRequire";

import useStyles from "./FilterRequire.style";

const FilterRequire: React.FC<any> = (props) => {
  const { type } = props;
  const classes = useStyles();

  return (
    <Grid container direction={"column"} className={classes.noContentContainer}>
      <div className={classes.imageContainer}>
        <FilterRequest />
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.noUnitText}>
          {t`Please select a ` + type + t` using the filters at the top of the page`}
        </Typography>
      </div>
    </Grid>
  );
};

export default FilterRequire;
