import * as React from "react";

function SvgAlertBell(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <linearGradient
          id="alertBell_svg__a"
          x1="100%"
          x2="0%"
          y1="26.858%"
          y2="28.597%"
        >
          <stop offset="0%" stopColor="#421A40" />
          <stop offset="100%" stopColor="#29132E" />
        </linearGradient>
        <filter id="alertBell_svg__b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.896230 0 0 0 0 0.885402 0 0 0 0 0.896230 0 0 0 1.000000 0"
          />
        </filter>
        <path id="alertBell_svg__c" d="M0 .34h14.846v11.41H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={12.5} cy={12.5} r={12.5} fill="url(#alertBell_svg__a)" />
        <g filter="url(#alertBell_svg__b)">
          <g transform="translate(5 4.897)">
            <mask id="alertBell_svg__d" fill="#fff">
              <use xlinkHref="#alertBell_svg__c" />
            </mask>
            <path
              fill="#4B1C46"
              d="M13.526 10.237a.503.503 0 01-.44-.497V6.007A5.675 5.675 0 007.419.339a5.674 5.674 0 00-5.666 5.668v3.739c0 .25-.188.465-.437.497l-.698.09a.712.712 0 00.093 1.417h13.417a.716.716 0 00.716-.667c.043-.673-.47-.738-1.318-.846"
              mask="url(#alertBell_svg__d)"
            />
          </g>
          <path fill="#4B1C46" d="M10.364 17.735a2.03 2.03 0 004.06 0h-4.06z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgAlertBell;
