import { alpha, Switch } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React from "react";

const GreenSwitch = withStyles((theme: any) =>
    createStyles({
        root: {
            width: 40,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
            display: "flex",
            alignItems: "center"
        },
        switchBase: {
            padding: 0,
            top: "unset",
            color: "#AFA3AF",
            "&$checked": {
                transform: "translateX(22px)",
                color: theme.palette.colors.activeGreen || "#70CB93",
                "& + $track": {
                    backgroundColor: alpha(theme.palette.colors.activeGreen || "#70CB93", 0.4),
                    opacity: 1,
                    border: `1px solid transparent`
                }
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff"
            }
        },
        thumb: {
            width: 15,
            height: 15
        },
        track: {
            height: "9px",
            width: "35px",
            borderRadius: 26 / 2,
            border: `1px solid transparent`,
            backgroundColor: alpha("#fff", 0.2),
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"])
        },
        checked: {},
        focusVisible: {}
    })
)(({ classes, ...props }: any) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                width: "60px",
                justifyContent: "flex-end",
                cursor: "pointer"
            }}
            onClick={props.onChange}
        >
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                onChange={() => { }}
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                }}
                {...props}
            />
        </div>
    );
});

export default GreenSwitch;
