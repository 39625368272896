import {
  CircularProgress,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./DisconnectedUnitsDialog.style";

export default (function UnassignedUnitsDialog(props: any) {
  const { close, deviceId, deviceName, actions, type } = props;
  const classes = useStyles();

  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getDeviceUnits = useStoreActions((actions) => actions.devices.getDeviceUnitsAPI);
  const deleteUnit = useStoreActions((action) => action.deleteUnit1);
  const types = useStoreState((state) => state.types);
  const { unitTypes } = types;

  const [units, setUnits] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    getDeviceUnits({ id: deviceId }).then((unitsResp: any) => {
      const unitsTemp: any = {};
      Object.values(unitsResp).forEach((unit: any) => {
        if (unitTypes[type] === unit.type && !unit.isConnected) {
          unitsTemp[unit.id] = unit;
        }
      });

      setUnits(unitsTemp);
      setIsLoading(false);
    })
      .catch((err: any) => addMessage({ message: err.message }));
  }, [deviceId]);

  const deleteCurrentUnit = (id: string) => {
    units[id].deleteInProgress = true;
    setUnits({...units});
    deleteUnit(id).then(() => {
      actions.fetchData();
      delete units[id];
      setUnits({...units});
    })
    .catch((err: any) => {
      units[id].deleteInProgress = false;
      setUnits({...units});
      addMessage({ message: err.message });
    });
  };

  return (
    <Dialog
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="unassign-dialog"
      open={deviceId}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Disconnected Units`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>
      <div className={classes.dialogContent}>
        {isLoading ? <div className={classes.loaderHolder}><CircularProgress /></div> : <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.tableHeadCell }} style={{width: 20, maxWidth: 20, padding: 0 }} align="left">
                </TableCell>
                <TableCell classes={{ root: classes.tableHeadCell }} style={{ width: 200 }} align="left">
                  {t`UNIT NAME`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(units).map(({name, id, deleteInProgress = false, canDelete = true }: any) =>  (
                  <TableRow key={`unit-row-${id}`} classes={{ root: classes.overWritePaddingRow }}>
                    <TableCell classes={{ root: classes.overWritePadding }} align="center"  style={{ maxWidth: 20, padding: 0 }}>
                      {deleteInProgress ?
                        <CircularProgress className={classes.loader} /> :
                        <IconButton disabled={!canDelete} disableRipple onClick={() => deleteCurrentUnit(id)} classes={{ root: classes.iconBtnStyle }}>
                          <DeleteIcon />
                        </IconButton>}
                    </TableCell>
                    <TableCell classes={{ root: classes.overWritePadding }} align="left">
                      {name}
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
        <div className={classes.actionsHolder}>
          <Button width={160} onMouseDown={close}>{t`Done`}</Button>
        </div>
      </div>
    </Dialog>
  );
});
