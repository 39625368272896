import {
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IZone } from "../../models/Zones";
import { MenuSearch as Search } from "../../svgComponents";
import NewApplySettings from "../Settings/NewApplySettings";
import useStyle from "./OneTenant.style";

const TenantList: React.FC = (props: any) => {
  const classes = useStyle();
  const sites = useStoreState((s) => s.sites.allSites);
  const saveNewZone = useStoreActions((action) => action.zones.createZone);
  const delZone = useStoreActions((action) => action.zones.deleteZone);
  const updateZone = useStoreActions((action) => action.zones.updateZone);
  const updateUnitZones = useStoreActions((action) => action.units.storeUpdateUnitsZones);
  const updateUnitsArrayZones = useStoreActions((action) => action.units.storeUpdateUnitsArrayZones);
  const getZonesBySiteId = useStoreActions((action) => action.zones.getZonesBySiteId);
  const getUnit = useStoreState((s) => s.units.getUnit);
  const [tenants, setTenants] = useState<any>({});
  const [tenantName, setTenantName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [showOneTenant, setShowOneTenant] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isMissingName, setIsMissingName] = useState<boolean>(false);
  const [isMissingUnitsToSave, setIsMissingUnitsToSave] = useState<boolean>(false);
  const [existName, setExistName] = useState<string>("");

  const isInitialized = useStoreState((s) => s.isInitialized);
  const [unitsToSave, setUnitsToSave] = useState<any>([]);
  const selections = useStoreState((s) => s.selections.selections);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { siteId = "" } = selections;
  const { canCreateZones } = sites[siteId || ""]?.permissions || {};

  useEffect(() => {
    setTenants({});
    if (!siteId) {
      return;
    }
    (async () => {
      const zones = await getZonesBySiteId(siteId);
      setTenants(zones);
    })();
  }, [siteId]);
  useEffect(() => {
    setIsMissingUnitsToSave(unitsToSave?.length < 1);
  }, [unitsToSave]);

  if (!isInitialized) { return <Loading />; }

  const onSave = async () => {
    if (!tenantName || existName) {
      setIsMissingName(true);
      return;
    }
    if (unitsToSave?.length < 1) {
      setIsMissingUnitsToSave(true);
      return;
    }
    let newZone: any = {};

    if (!isEditing) {
      newZone = await saveNewZone({
        data: {
          name: tenantName,
          units: [...unitsToSave],
          description: "",
          site: siteId || ""
        }
      });
    } else {
      newZone = await updateZone({
        id: tenantId,
        data: {
          name: tenantName,
          units: [...unitsToSave],
          description: ""
        }
      });
      setIsEditing(!isEditing);
    }

    setTenants({ ...tenants, [newZone.id]: newZone });
    updateUnitsArrayZones({ units: unitsToSave, zone: newZone, add: true });

    setShowOneTenant(false);
    // reseting one Tenant page
    setTenantName("");

    // Reset checked units, while leaving selected units on tenant edit\new page
    setUnitsToSave([]);
  };
  const onDelete = async (payload: any) => {
    const unitsToUpdate = [..._.find(tenants, { id: payload.id }).units];
    let message = null;
    await delZone(payload.id)
      .then(() => {
        const { [payload.id]: zoneToDelete, ...tenantsToSave } = tenants;
        setTenants(tenantsToSave);
      })
      .catch((e: any) => {
        message = e.message;
      });

    // Update all units' groups data
    _.forEach(unitsToUpdate, (unitId) => {
      updateUnitZones({ unitId, zone: { id: payload.id }, add: false });
    });
    return message;
  };

  const onEdit = (tenant: IZone) => {
    setIsEditing(!isEditing);
    setTenantId(tenant.id);

    setTenantName(tenant.name ? tenant.name : "");

    const units: any = [];
    tenant.units?.forEach((unitId: string) => {
      const unit = getUnit(unitId);
      if (unit) {
        units.push(unitId);
      }
    });
    setUnitsToSave(units);
    setShowOneTenant(true);
  };

  const onCancle = () => {
    if (isMissingName) {
      setIsMissingName(false);
      setExistName("");
    }
    setIsMissingUnitsToSave(false);

    if (isEditing) {
      setIsEditing(false);
    }

    setShowOneTenant(false);
    setTenantName("");
    // Reset checked units, while leaving selected units on tenant edit\new page
    setUnitsToSave([]);
  };

  const tenantList = () => {
    return (
      <Grid container={true} className={classes.tenantsContainer}>
        <Paper elevation={0} className={classes.tenantsPaper}>
          <TableContainer className={classes.tenantsTableContainer}>
            <Table stickyHeader={true} className={classes.tenantsTable} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`TENANT NAME`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`UNITS`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`EDIT`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`REMOVE`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  _.orderBy(searchTerm?.length ? Object.values(tenants)
                    .filter((item: any) => item?.name?.toUpperCase()?.includes(searchTerm?.toUpperCase()))
                    : Object.values(tenants), [(item: any) => item?.name?.toUpperCase()],
                    ["asc"])
                    .map((zone: any, index: number) => {
                      const { canUpdate, canDelete } = zone.permissions || {};
                      return (
                        <TableRow
                          hover={true}
                          tabIndex={-1}
                          key={index}
                          classes={{ root: classes.overWritePadding }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: classes.overWritePadding }}
                            align="left"
                          >
                            {zone.name}
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            {zone?.units.length}
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            <IconButton disableRipple disabled={!canUpdate} onClick={() => { onEdit(zone); }} className={classes.overWriteIcon}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            <Delete
                              disabled={!canDelete}
                              type={t`Tenant`}
                              object={zone}
                              detach={onDelete}
                              buttonClass={classes.deleteIcon}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    );
  };

  const handleAgree = (units: any) => {
    setUnitsToSave(units);
  };

  const oneTenant = () => {
    const { canUpdate = true } = tenants[tenantId] || {};

    return (
      <Dialog
        open={showOneTenant}
        onClose={onCancle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        classes={{ paper: classes.dialog }}
      >
        {false && <div className={classes.loaderContainer}>
        </div>}
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Tenant`}</Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} onClick={onCancle}><Close color="#7f7692" /></IconButton>
        </div>

        <div className={classes.dialogContentZone}>
          <Grid item={true} xs={4} className={classes.nameContainer}>
            <Typography
              className={classes.headerStyle}
            >{t`Tenants`}</Typography>
            <TextField id="outlined-basic"
              label="Tenants Name"
              variant="outlined"
              disabled={!canUpdate}
              classes={{ root: classes.textFieldValue }}
              value={tenantName}
              error={isMissingName}
              helperText={!isMissingName ? "" : existName ? existName : "need tenant name to save"}
              onChange={(e: any) => {
                if (isMissingName) {
                  setIsMissingName((false));
                }
                setTenantName(e.target.value);
                const findexistName = Object.values(tenants).find((item: any) => (item.name).trim() === (e.target.value).trim());
                if (findexistName) {
                  setExistName("* Tenant Name already exist");
                  setIsMissingName((true));
                }
                else {
                  setExistName("");
                }
              }} />
          </Grid>
          <Grid item={true} xs={8}>
            <Paper className={classes.rightCard} elevation={0}>
              <Typography className={classes.headerStyle}>{t`Unit selection`}</Typography>
              <FormControl component="fieldset" className={classes.unitListContainer}>
                <div className={classes.tenantUnitLists} >
                  {isMissingUnitsToSave && <p className={classes.error}>{t`*Check at least one unit`}</p>}
                  <NewApplySettings
                    disabled={!canUpdate}
                    save={handleAgree}
                    units={unitsToSave}
                    noPopup={true}
                    oneSite={siteId}
                    allTenants={tenants}
                    tenantId={tenantId}
                    useServiceUnits
                    showServiceUnits
                  />
                </div>

              </FormControl>
            </Paper>
          </Grid>

        </div>

        <div className={classes.actionsHolder}>
          <Button
            white
            width={150}
            marginRight
            disabled={!canUpdate}
            onClick={onCancle}>
            {t`Cancel`}
          </Button>
          <Button
            disabled={!canUpdate}
            width={150}
            onClick={() => {
              onSave();
            }}>
            {t`Save`}
          </Button>
        </div>
      </Dialog>
    );
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot, input: classes.inputBase },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );
  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={[t`Power Distribution`]}
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection={true}
          hideUnitSelection={true}
          screenTitle="tenantReports"
          searchComponent={searchComponent}
        />
        {!!siteId &&
          <div className={classes.buttonContainer}>
            <Button
              disabled={!canCreateZones}
              onClick={() => {
                setShowOneTenant(true);
                setIsMissingName(false);
                setIsMissingUnitsToSave(false);
              }}>
              {t`Add New Tenant`}
            </Button>
          </div>}
        {siteId && tenantList()}
        {showOneTenant && oneTenant()}
        {!siteId && <FilterRequire type={t`site`} />}
      </div>
    </div>
  );
};

export default TenantList;
