import { Card, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./ApplySettings.style";

import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { ArrowDownO, CheckboxChecked } from "../../icons";
import Checkbox from "./Checkbox";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#545964",
      fontSize: "14px",
      width: "100%",
      minHeight: "40px",
      "&:hover > $content": {
        backgroundColor: "transparent"
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #fff)`,
        color: "var(--tree-view-color)"
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent"
      }
    },
    content: {
      color: "#545964",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "10px",
      fontWeight: 400,
      "$expanded > &": {
        fontWeight: 400
      }
    },
    group: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "31px"
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
      padding: 0,
      display: "flex"
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: "9px",
      paddingLeft: 0
    },
    customerIconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "13px",
      "& div": { display: "none" },
      marginLeft: "10px"
    },
    labelIcon: {
      marginRight: "10px"
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      fontSize: 15
    },
    iconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "5px"
    },
    customerGroup: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "21px"
    },
    // label: {
    //     color: "#545964",
    //     padding: 0
    // },
    customerContent: {
      backgroundColor: "rgba(170, 162, 170, 0.1)"
    }
  })
);

const StyledTreeItemWrapper = function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div
          className={clsx(classes.labelRoot, { [className]: className })}
        >
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      } as any}
      {...other}
    />
  );
};

const Checkbox1 = (props: any) => {
  const classes = useStyles();
  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

export default function NewApplySettings(props: any) {
  const localClasses = useTreeItemStyles();
  const { wrc = false, unitSettings = false, subType = 0, save } = props;

  const getUnits = useStoreState((state) => state.units.getUnitsByWithHiddenUnits);
  const selections = useStoreState((s) => s.selections.selections);
  const allSites = useStoreState((state) => state.sites.allSites);

  const [currentSelectedUnits, setCurrentSelectedUnits] = useState<any>({});

  useEffect(() => {
    setCurrentSelectedUnits({});
    save([]);
  }, [selections.unitId, subType]);

  const siteNode = () => {
    const items: any = [];
    const site: any = allSites[props.oneSite];
    if (!site) {
      return null;
    }
    const siteUnits = getUnits("site", site.id, { type: "indoor", subType });
    const filteredsiteUnits = wrc || unitSettings ? siteUnits.filter(({ permissions }: any) => permissions?.canUpdate) : siteUnits;
    const notChecked = filteredsiteUnits.some((unit: any) => unit.id !== selections.unitId && !currentSelectedUnits[unit.id]);
    const indeterminate = filteredsiteUnits.some((unit: any) => unit.id !== selections.unitId && currentSelectedUnits[unit.id]);

    site && items.push(
      <StyledTreeItemWrapper
        key={`site-1`}
        nodeId={`site-1`}
        labelText={site.name}
        node={filteredsiteUnits.length > 0 ? <Checkbox1
          style={{ opacity: props.disabled ? 0.5 : 1 }}
          disabled={props.disabled}
          checked={!notChecked}
          indeterminate={notChecked && indeterminate}
          onChange={(event: any) => {
            const { target: { checked } } = event;
            if (checked) {
              filteredsiteUnits.forEach((unit: any) => currentSelectedUnits[unit.id] = true);
            } else {
              filteredsiteUnits.forEach((unit: any) => delete currentSelectedUnits[unit.id]);
            }
            setCurrentSelectedUnits({ ...currentSelectedUnits });
            save(Object.keys({ ...currentSelectedUnits }));
          }}
        /> : undefined}
        color="#545964"
        bgColor="#fff"
        classes={{ iconContainer: localClasses.customerIconContainer, group: localClasses.customerGroup, label: localClasses.label, content: localClasses.customerContent }}
      >
        {unitsNodes(filteredsiteUnits)}
      </StyledTreeItemWrapper>
    );
    return items;
  };

  const unitsNodes = (units: any) => {
    const items = [];
    for (const i in units) {
      let unit = units[i];
      if (!unit || unit.id === selections.unitId) {
        continue;
      }

      const { id, name, system } = unit;
      unit && items.push(
        <StyledTreeItemWrapper
          key={`unit-${id}`}
          nodeId={`unit-${id}`}
          labelText={name}
          style={{ opacity: props.disabled ? 0.5 : 1 }}
          node={
            <Checkbox1
              disabled={props.disabled}
              checked={!!currentSelectedUnits[id]}
              onChange={(event: any) => {
                // Save new units
                if (event.target.checked) {
                  const unitsToSave = { ...currentSelectedUnits, [id]: true };
                  setCurrentSelectedUnits(unitsToSave);
                  save(Object.keys(unitsToSave));
                } else {
                  delete currentSelectedUnits[id];
                  setCurrentSelectedUnits({ ...currentSelectedUnits });
                  save(Object.keys(currentSelectedUnits));
                }
              }}
            />}
          color="#545964"
          bgColor="#fff"
        />);
    }
    return items;
  };

  return (
    <Grid
    >
      <TreeView
        // className={classes.root}
        defaultExpanded={["site-1"]}
        defaultCollapseIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ marginLeft: "7px", marginRight: "2px" }} /> </>}
        defaultExpandIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ transform: "rotate(-90deg)", marginLeft: "7px", marginRight: "2px" }} /></>}
        defaultEndIcon={<div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} />}
      >
        {siteNode()}
      </TreeView>

    </Grid>
  );

}
