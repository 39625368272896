import * as yup from "yup";

export const unitExcelSchema = {
    "Airnet address (daikin only)": {
        prop: "airnet",
        type: String
    },
    "Capacity": {
        prop: "capacity",
        type: Number
    },
    "Device": {
        prop: "device",
        type: String

    },
    "Group address (=ID)": {
        prop: "privateId",
        type: String

    },
    "Master/Slave (outdoor only)": {
        prop: "task",
        type: String

    },
    "Model": {
        prop: "model",
        type: String

    }, "S/N": {
        prop: "serial",
        type: String

    }, "System Name": {
        prop: "system",
        type: String

    }, "Unit Name": {
        prop: "name",
        type: String

    }, "indoor/outdoor": {
        prop: "type",
        type: String

    },
    "line": {
        prop: "line",
        type: String

    },
    "Visible": {
        prop: "isVisible",
        type: String

    }
};

export const unitProps: any = {

    "Unit Name": {
        dbName: "name"
    },

    "System": {
        dbName: "system"
    },
    "indoor/outdoor": {
        dbName: "type"
    },
    "Group address (=ID)": {
        dbName: "controlUnit"
    },
    "Capacity": {
        dbName: "capacity"
    },
    "Model": {
        dbName: "model"
    },
    "S/N": {
        dbName: "serialNumber"
    },
    "Master/Slave (ODU only)": {
        dbName: "task"
    },
    "A-Net (daikin only)": {
        dbName: "airnet"
    },
    "Central (Daikin Only)": {
        dbName: "address"
    },
    "CA Device": {
        dbName: "device"
    }, "CA line #": {
        dbName: "line"
    },
    "Visible": {
        dbName: "isVisible"

    }
};
export const unitSchema = yup.object().shape({
    name: yup.string().required(),
    airnet: yup.number(),
    capacity: yup.number()
        .integer(),
    // device: yup.string().required(),
    // privateId: yup.string().required(),
    type: yup.number(),
    model: yup.string(),
    serial: yup.number(),
    // system: yup.string().required(),
    task: yup.number(),
    controlUnit: yup.string(),
    isVisible: yup.boolean()
    // line: yup.number().required(),
});
export const systemProps: any = {
    "System Name": {
        dbName: "name"
    },
    "CA Device serial": {
        dbName: "device"
    },
    "CA Device Line #": {
        dbName: "line"
    },
    "Brand": {
        dbName: "brand"
    },
    "Capacity": {
        dbName: "capacity"
    },
    "Capacity Units": {
        dbName: "measurementUnits"
    },
    "Type": {
        dbName: "type"
    },
    "Series": {
        dbName: "series"
    }
};
export const systemSchema = yup.object().shape({
    name: yup.string().required(),
    // device: yup.string().required(),
    line: yup.number().required(),
    // brand: yup.string().required(),
    capacity: yup.number(),
    measurementUnits: yup.string(),
    type: yup.string(),
    series: yup.string()
});
export const deviceProps: any = {
    "Device SN": {
        dbName: "serial"
    },
    "Pin": {
        dbName: "pin"
    }
    // 'Site Name': {
    //     dbName: 'site'
    // },
};
export const deviceSchema = yup.object().shape({
    pin: yup.string().required(),
    serial: yup.string().required()
});
export const siteProps: any = {
    "Customer Name": {
        dbName: "customerName"
    },
    "Site Name": {
        dbName: "name"
    },
    "City": {
        dbName: "city"
    },
    "Adrress": {
        dbName: "address"
    },
    "Postal/Zip code": {
        dbName: "postalCode"
    },
    "Country": {
        dbName: "country"
    },
    "State": {
        dbName: "state"
    },
    "Time Zone": {
        dbName: "timezone"
    }
};
export const siteSchema = yup.object().shape({
    name: yup.string().required(),
    customerName: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    address: yup.string().required(),
    state: yup.string(),
    postalCode: yup.string().required(),
    timezone: yup.string().required()
});
