import {
  Button,
  Card,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  ZAxis
} from "recharts";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { Delete, FloorPlan, List } from "../../icons";
import SvgOfflineBoltOutlined from "../../icons/OfflineBoltOutlined";
import { DeleteIcon, EditIcon } from "../../logos";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import DeviceCard from "./DeviceCardMgmt";
import useStyles from "./SiteViewMgmt.style";
import SystemsView from "./SystemsViewmgmt";

export default (function SiteView(props: any) {
  const { site, actions, handlePowerMappingDialog, enableAirQuality, airQualityParams, serviceParams } = props;
  const classes: any = useStyles();
  const siteFlags = useStoreState((state) => state.sites.sitesFlags);
  const [pageMode, setPageMode] = useState<"list" | "plans">("list");

  if (_.isUndefined(site)) {
    return null;
  }

  const {
    devices,
    address,
    city,
    country,
    state,
    postalCode,
    id,
    name,
    powerPackageEnabled = false,
    floorPlans = [],
    defaultFloorPlan = "",
    permissions: {
      canUpdate,
      canCreateDevices,
      canDelete,
      canCreateFloorPlans,
      canReadSiteSubscriptions,
      canAssignSiteSubscriptions
    }
  } = site;

  const { hasFloorPlans } = siteFlags[id] || {};

  const airQualityData = [
    { name: t`Good Air Quality`, first: 20, second: 20, third: 20, fourth: 20, fifth: 20, line: 40 }
  ];
  const airQualityRanges = [0, 20, 40, 60, 80, 100];

  const airQualityStatus = () => {
    const mainAirQualityParam = airQualityParams[101];
    if (mainAirQualityParam >= airQualityRanges[0] && mainAirQualityParam <= airQualityRanges[1]) {
      return (t`Very bad Air Quality`);
    }
    else if (mainAirQualityParam >= airQualityRanges[1] && mainAirQualityParam <= airQualityRanges[2]) {
      return (t`Bad Air Quality`);
    }
    else if (mainAirQualityParam >= airQualityRanges[2] && mainAirQualityParam <= airQualityRanges[3]) {
      return (t`Medium Air Quality`);
    }
    else if (mainAirQualityParam >= airQualityRanges[3] && mainAirQualityParam <= airQualityRanges[4]) {
      return (t`Good Air Quality`);
    }
    else if (mainAirQualityParam >= airQualityRanges[4] && mainAirQualityParam <= airQualityRanges[5]) {
      return (t`Excellent Air Quality`);
    }
  };

  const ParamsDataTooltip = () => {
    const codes = Object.keys(airQualityParams);
    return <>{codes.map((code: any) => <Typography key={code}>{serviceParams[code]?.title}: {airQualityParams[code]} {serviceParams[code]?.data_unit_of_measurement}</Typography>)}</>;
  };

  const handlePageModeChange = (e: any, mode: "list" | "plans") => {
    if (!mode) {
      return;
    }
    setPageMode(mode);
  };

  const isListView = pageMode === "list";

  return (
    <>
      <Grid item xs={12}>
        <Card
          elevation={0}
          className={classes.siteBar}
          style={{ backgroundColor: "rgb(246, 245, 246, 0.5)" }}
        >
          <div className={classes.siteTitleContainer}>
            <div className={classes.siteNameContainer}>
              <Typography className={classes.siteNameStyle}>{name}</Typography>
              <Typography className={classes.siteAddressStyle}>
                {`${address && address}${city && ", " + city}${(country === "US" && state) ?
                  ", " + state : ""}${country !== "US" ? ", " + country : ""
                  }${postalCode && ", " + postalCode}`}
              </Typography>
            </div>
            {enableAirQuality &&
              <div className={classes.airQualityContainer}>
                <Typography className={classes.airQualityTitle}>{airQualityStatus()}</Typography>

                <Tooltip title={<ParamsDataTooltip />} classes={{ tooltip: classes.tooltip }}>
                  <div>
                    <ResponsiveContainer height={40} width={"100%"}>
                      <ComposedChart
                        layout="vertical"
                        data={airQualityData}
                        margin={{ left: 50, right: 50 }}
                        stackOffset="expand"
                        value={30}
                      >
                        <XAxis xAxisId={"Bar"} hide type="number" />
                        <YAxis
                          hide
                          yAxisId={"Bar"}
                          type="category"
                          dataKey="name"
                          stroke="#000"
                          fontSize="12"
                        />

                        <Bar xAxisId={"Bar"} yAxisId={"Bar"} dataKey="first" radius={[20, 0, 0, 20]} fill="#d1b9e7" stackId="a" />
                        <Bar xAxisId={"Bar"} yAxisId={"Bar"} dataKey="second" fill="#f5cfb7" stackId="a" />
                        <Bar xAxisId={"Bar"} yAxisId={"Bar"} dataKey="third" fill="#fae8c8" stackId="a" />
                        <Bar xAxisId={"Bar"} yAxisId={"Bar"} dataKey="fourth" fill="#32d92d" stackId="a"></Bar>
                        <Bar xAxisId={"Bar"} yAxisId={"Bar"} dataKey="fifth" radius={[0, 20, 20, 0]} fill="#bedddf" stackId="a" />

                        <XAxis xAxisId={"Scatter"} type="number" domain={[0, 100]} dataKey="x" stroke="#000"
                          ticks={airQualityRanges}
                        />
                        <YAxis yAxisId={"Scatter"} hide type="number" dataKey="y" />
                        <ZAxis zAxisId="Scatter" type="number" dataKey="z" range={[60, 100]} name="score" />
                        <Scatter
                          position={"top"}
                          fill={"#000"}
                          xAxisId="Scatter"
                          yAxisId="Scatter"
                          zAxisId="Scatter"
                          data={[{ x: airQualityParams[101], y: 100, z: 100 }]}
                          shape="triangle"
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </Tooltip>
              </div>
            }
          </div>

          {hasFloorPlans &&
            <ToggleButtonGroup
              value={pageMode}
              exclusive
              onChange={handlePageModeChange}
              aria-label="text alignment"
              style={{
                height: "40px",
                marginRight: "35px",
                backgroundColor: "#fff"
              }}
              color="primary"
            >
              <ToggleButton
                value="list"
                classes={{ root: classes.toggleBtn, selected: classes.toggleBtnSelected }}
                style={{
                  textTransform: "none"
                }}
              >
                <List />
                <Typography>{t`Systems`}</Typography>
              </ToggleButton>
              <ToggleButton
                value="plans"
                classes={{ root: classes.toggleBtn, selected: classes.toggleBtnSelected }}
                style={{
                  textTransform: "none"
                }}
              >
                <FloorPlan />
                <Typography>{t`Floor Plan`}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          }

          <div className={classes.siteActionsContainer}>
            {/* {
              canReadSiteSubscriptions &&
              <div className={classes.siteActionBtnBox}>
                <Button
                  disabled={!canAssignSiteSubscriptions}
                  disableRipple
                  variant="contained"
                  color="default"
                  onMouseUp={() => actions.openSubscriptions(id)}
                  style={{ margin: "0 1rem 0 1rem", boxShadow: "0" }}
                  classes={{ contained: classes.addDeviceBtn }}
                >
                  {t`Subscriptions`}
                </Button>
              </div>
            } */}
            <div className={clsx(classes.siteActionBtnBox, hasFloorPlans && classes.siteActions)} style={{ width: "unset" }}>
              <Button
                disabled={!canCreateDevices}
                disableRipple
                variant="contained"
                color="default"
                onMouseUp={() => actions.openAddDevice(id)}
                style={{ margin: "0 1rem 0 1rem", boxShadow: "0" }}
                classes={{ contained: classes.addDeviceBtn }}
              >
                {t`+ Add Device`}
              </Button>
            </div>

            <div className={classes.siteActions}>
              <Tooltip arrow title={t`Edit Site`}>
                <IconButton
                  disabled={!canUpdate}
                  disableRipple
                  onClick={() => actions.openAddEditSite(id)}
                  className={classes.iconBtnStyle} >
                  <EditIcon className={classes.svgIcon} />
                </IconButton>
              </Tooltip>
              <IconButton
                disabled={!canDelete}
                className={clsx(classes.iconBtnStyle, !canDelete && classes.disabledStyle)}
                onClick={() => { actions.setOpenToDeleteItem(true); actions.setToDeleteItem({ type: "site", data: site }); }}
              >
                <Delete style={{ width: 20, height: 20 }} />
              </IconButton>
            </div>
          </div>

        </Card>
      </Grid >
      <Grid item xs={12}>
        {isListView ? devices.map((device: any) => {
          const { id: deviceId, numOfControlUnits, numOfOutdoorUnits, numOfServiceUnits, numOfBsBoxUnits, canAddSystem = true } = device;
          const hasOnlyControlUnit = !!numOfControlUnits && !numOfOutdoorUnits && !numOfServiceUnits && !numOfBsBoxUnits;

          return (
            <Card elevation={0} key={`device-${device.id}`} className={classes.card}>
              <DeviceCard device={device} actions={actions} />
              <Grid item xs={10} className={classes.systemsContainer}>
                <SystemsView hasOnlyControlUnit={hasOnlyControlUnit} device={device} actions={actions} />
                <Grid
                  item
                  className={classes.addSystemContainer}
                >
                  <Button disableRipple className={classes.addNewSystem} disabled={!canAddSystem} onClick={() => actions.openAddEditSystem(deviceId, true)}>
                    <Add className={classes.addIcon} />
                  </Button>
                </Grid>
              </Grid>
            </Card>
          );
        }) :
          <Card elevation={0} key={`siteFloorPlans-${id}`} className={clsx(classes.card, classes.wrap)}>
            {
              floorPlans.map((plan: any) => {
                const { canDelete: canDeletePlan, canUpdate: canUpdatePlan } = plan.permissions || {}
                return (
                  <div key={plan.id} className={clsx(classes.floorPlanBox, plan.id === defaultFloorPlan && classes.isDefultFP)}>
                    <div className={classes.floorPlanBoxHead}>
                      <Typography className={classes.floorPlanName}>{`${plan.id === defaultFloorPlan ? "(D) " : ""}${plan.name}`}</Typography>
                      <div>
                        <IconButton disabled={!canUpdatePlan} onClick={() => actions.openFloorPlanDialog(id, { ...plan, isEdit: true })}>
                          <EditIcon />
                        </IconButton>
                        <IconButton disabled={!canDeletePlan} onClick={() => actions.setToDeleteFloorPlan(plan.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      height: "calc(100% - 40px)",
                      justifyContent: "center"
                    }}>
                      <img src={plan.url} style={{ maxWidth: "100%" }} />
                    </div>
                  </div>
                )
              })
            }
            <Grid
              item
              style={{
                margin: "10px",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                disableRipple
                disabled={!canCreateFloorPlans}
                className={classes.addNewSystem}
                style={{ height: "180px" }}
                onClick={() => actions.openFloorPlanDialog(id)}>
                <Add className={classes.addIcon}
                />
              </Button>
            </Grid>
          </Card>
        }
      </Grid>
    </>
  );
});
