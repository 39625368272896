import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  title: {
    backgroundColor: "#f6f6f7",
    // color: "#29132e",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  container: {
    border: "1px solid #d5d2d5",
    borderRadius: "4px",
    marginBottom: "1.5rem"
  },
  systemOverviewContent: {
    padding: ".5rem"
  },

  btn: {
    marginBottom: "1.5rem",
    marginRight: "1.5rem"
  },
  dialogWidth: {
    width: "1394px",
    maxWidth: "95%"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0
  }
}));

export default useStyles;
