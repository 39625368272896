import { alpha } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
const groupPanelStyles = (theme: any) =>
  createStyles({
    mainIconsStyle: {
      padding: "11px",
      background: theme.palette.colors.controlButtonColor,
      width: "62px",
      height: "62px",
      color: alpha("#fdfdfe", 0.6),
      "&:hover": {
        background: theme.palette.colors.controlButtonColorHover,
      },
      "&.Mui-disabled": {
        background: "#4b1c46",
        color: alpha("#fdfdfe", 0.6),
      },
    },
    iconContainer: { objectFit: "contain", zIndex: 8 },
    groupView: {
      width: "calc(100% - 9px)",
      marginTop: "43px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.26)",
      },
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px",
    },
    powerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    powerIconStyle: {
      padding: 0,
      zIndex: 100,
      "&:hover": {
        opacity: "0.5",
      },
    },
    powerTextStyle: {
      marginTop: "8px",
      color: "white",
      height: "16px",
      opacity: 0.5,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    powerOnButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
          opacity: 1,
        },
        "& path": {
          stroke: "#f05146",
        },
      },
    },
    powerOffButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
        },
        "& path": {
          fill: "#000",
        },
      },
    },
    secondRowContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexFlow: "column nowrap",
      paddingBottom: "30px",
      minHeight: "457px",
      justifyContent: "flex-end",
    },
    lastRowContainer: {
      display: "flex",
      height: "62px",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "100%",
      marginTop: "49px",
      marginBottom: "10px",
    },
    controlDiv: {
      maxHeight: "326px",
      maxWidth: "336px",
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "326px",
      minWidth: "336px",
      "@media (max-width: 328px)": {
        minWidth: "312px",
      },
    },
    sliderContainer: {
      marginTop: "-20px",
      maxWidth: "320px",
      maxHeight: "320px",
      zIndex: 1,
    },
    innerControls: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center",
      zIndex: 1000,
    },
    controlArrow: {
      background: "transparent !important",
      zIndex: 100,
      width: "60px",
      height: "60px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `#f05146 !important`,
        },
        transition: "all 1s",
      },
    },
    localSetpoint: {
      display: "flex",
      marginLeft: "25px",
      "& span": {
        color: "white",
        width: "25px",
      },
    },
    setPointStyle: {
      fontSize: "65px",
      color: "#fff",
    },
    ampTempContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    controlWrapperStyle: {
      position: "absolute",
      transform: "scale(1.19)",
      "@media (max-width: 328px)": {
        transform: "scale(0.93)",
      },
    },
    modeTitle: {
      fontSize: "50px",
      color: "#fff",
      fontWeight: "bold",
      position: "absolute",
    },
    loaderContainer: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loaderStyle: { color: alpha("#fff", 0.7) },
    alertMessageStyle: {
      fontSize: "16px",
      color: "#f05146",
      lineHeight: "16px",
    },
    nameSection: {
      display: "flex",
      flexFlow: "column nowrap",
      maxWidth: "calc(100% - 140px)",
      padding: "0 20px",
      alignItems: "center",
      minHeight: "60px",
      justifyContent: "space-between",
      "& p": {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: "22px",
      },
    },
    iconStyle: {
      fontSize: "17.5px",
    },
    modeIcon: {
      margin: "0 5px",
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: "-10px",
      marginRight: "-4px",
    },
    siteTemp: {
      width: "55px",
      opacity: 0.5,
      fontSize: 26,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
    },
    scaleStyle: {
      fontSize: 14,
    },
    badge: {
      position: "absolute",
      top: "-5px",
      right: "0px",
      padding: "3px 7px",
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontSize: "12px",
      lineHeight: "normal",
      borderRadius: "10px",
    },
    disabled: {
      opacity: 0.5,
    },
    textColor: { color: theme.palette.colors.menuFont },
  });

export default groupPanelStyles;
