import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { CheckboxChecked } from "../../icons";
import useStyles from "./Row.style";

const Row = (props: {name: string; onCheck?: any; checked?: boolean, hideLine?: boolean}) => {
  const classes = useStyles();
  const {name, checked, onCheck, hideLine} = props;
  return (
    <div className={clsx(classes.rowContainer, {[classes.hideLine]: hideLine})}>
    <Typography className={classes.nameStyle}>{name}</Typography>
    {onCheck && <Checkbox
                  checked={checked}
                  onChange={onCheck}
                  color="default"
                  edge="end"
                  variant="outlined"
                  onClick={(event: any) => event.stopPropagation()}
                  checkedIcon={<CheckboxChecked />}
                  className={classes.grayCheckbox}
                />}
    </div>
  );
};

export default Row;
