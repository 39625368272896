import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import InfoIcon from "@material-ui/icons/Info";
import Label from "@material-ui/icons/Label";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { ArrowDownO, CheckboxChecked, Close } from "../../icons";
import styles from "./AddRule.style";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
({
  root: {
    color: "#545964",
    fontSize: "14px",
    width: "100%",
    minHeight: "40px",
    "&:hover > $content": {
      backgroundColor: "transparent"
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, #fff)`,
      color: "var(--tree-view-color)"
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent"
    }
  },
  content: {
    color: "#545964",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingRight: "10px",
    fontWeight: 400,
    "$expanded > &": {
      fontWeight: 400
    }
  },
  group: {
    borderLeft: "1px solid #d5d2d5",
    marginLeft: "31px"
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
    padding: 0,
    display: "flex"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: "9px",
    paddingLeft: 0
  },
  labelIcon: {
    marginRight: "10px"
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    fontSize: 15
  },
  iconContainer: {
    alignItems: "center",
    width: "unset",
    marginRight: "5px"
  }
})
);

const Checkbox1 = (props: any) => {
  const classes = styles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={clsx(classes.labelRoot, { [className]: className })}>
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      } as any}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  ({
    root: {
      height: "100%",
      flexGrow: 1,
      maxWidth: 400,
      maxHeight: "calc(100% - 65px)",
      overflow: "auto",
      background: "#fff",
      padding: "0 20px",
      paddingTop: "20px"
    },
    actions: {
      backgroundColor: "#fff",
      display: "flex",
      position: "absolute",
      justifyContent: "flex-end",
      width: "100%",
      bottom: "10px",
      right: "20px"
    },
    indeterminate: {
      color: "#fff"
    },
    indetCheckbox: {
      "& > span": {
        background: "red !important",
        border: "1px solid gray"
      },
      "& svg": { width: "23px", height: "23px" }
    },
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": { width: "18px", height: "18px", borderRadius: "3px", backgroundColor: "#fff" }
    },
    labelStyle: {
      marginLeft: "-19px",
      "& p": { fontWeight: "bold" }
    },
    treeHeader: {
      borderBottom: "solid 1px #d5d2d5",
      width: "1005",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      maxHeight: "60px !important",
      backgroundColor: "#f6f6f7",
      alignItems: "center",
      minHeight: "60px"
    },
    headerTitle: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#29132e"
    },
    customerIconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "13px",
      "& div": { display: "none" },
      marginLeft: "10px"
    },
    customerGroup: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "21px"
    },
    label: {
      color: "#545964",
      padding: 0
    },
    customerContent: {
      backgroundColor: "rgba(170, 162, 170, 0.1)"
    },
    IconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
  })
);

export default ({ userPref, siteSensors, allSites, allCustomers, selectedSensors, customerSensors, onClose, onSave, mode }: any) => {
  const classes = useStyles();
  const [currentSelectedSensors, setCurrentSelectedSensors] = useState<any>({});
  const [sensorsOfSelectedType, setSensorsOfSelectedType] = useState<any>({});
  const [sitesPerCustomers, setSitesPerCustomers] = useState<any>({});

  useEffect(() => {
    const sensorsMappedPerSite: any = {};
    const selectedItems: any = {};
    const sitesMappedToCustomers: any = {};
    selectedSensors.forEach((id: any) => {
      selectedItems[id] = true;
    });
    setCurrentSelectedSensors(selectedItems);

    Object.keys(siteSensors).forEach((siteId: string) => {
      if (!allSites[siteId]) {
        return;
      }
      const { customer } = allSites[siteId];
      const sensorsIds = siteSensors[siteId];
      const typeSensorsIds: any = [];
      let siteIdAdded = false;

      sensorsIds.forEach((sensorId: string) => {
        if (mode === "powerMeter" || +customerSensors[sensorId]?.type === + mode) {
          if (!siteIdAdded) {
            if (sitesMappedToCustomers[customer]) {
              sitesMappedToCustomers[customer].push(siteId);
            } else {
              sitesMappedToCustomers[customer] = [siteId];
            }
            siteIdAdded = true;
          }
          typeSensorsIds.push(sensorId);
        }
      });

      if (typeSensorsIds.length) {
        sensorsMappedPerSite[siteId] = typeSensorsIds;
      }
    });

    setSitesPerCustomers(sitesMappedToCustomers);
    setSensorsOfSelectedType(sensorsMappedPerSite);
  }, []);

  const customersNodes = () => {
    const items: any = [];

    for (let customerId in sitesPerCustomers) {
      const { name = "" } = allCustomers[customerId] || {};
      const customerName = (userPref?.nicknames && userPref.nicknames[customerId]) || name;

      customerName && items.push(
        <StyledTreeItem
          key={`customer-${customerId}`}
          nodeId={`customer-${customerId}`}
          labelText={customerName}
          classes={{ iconContainer: classes.customerIconContainer, group: classes.customerGroup, label: classes.label, content: classes.customerContent }}
          color="#545964"
          bgColor="#fff"
        >{sitesNodes(sitesPerCustomers[customerId])}</StyledTreeItem>);
    }

    return items;
  };

  const sitesNodes = (sites: any) => {
    const items = [];

    for (const i in sites) {
      const siteId: any = sites[i];
      const { id, name } = allSites[siteId];
      const checked = sensorsOfSelectedType[id].every((sensorId: string) => currentSelectedSensors[sensorId]);
      const indeterminate = sensorsOfSelectedType[id].some((sensorId: string) => currentSelectedSensors[sensorId]);

      id && items.push(
        <StyledTreeItem
          key={`site-${id}`}
          nodeId={`site-${id}`}
          labelText={name}
          node={<Checkbox1
            indeterminate={!checked && indeterminate}
            checked={checked}
            onChange={(event: any) => {
              if (!event.target.checked) {
                sensorsOfSelectedType[id].forEach((sensorId: string) => delete currentSelectedSensors[sensorId]);
                setCurrentSelectedSensors({ ...currentSelectedSensors });
              } else {
                sensorsOfSelectedType[id].forEach((sensorId: string) => currentSelectedSensors[sensorId] = true);
                setCurrentSelectedSensors({ ...currentSelectedSensors });
              }
            }}
          />}
          color="#545964"
          bgColor="#fff"
        >
          {sensorsNodes(sensorsOfSelectedType[id])}
        </StyledTreeItem>);
    }

    return items;
  };

  const sensorsNodes = (sensors: any) => {
    const items = [];
    for (const i in sensors) {
      const sensor = customerSensors[sensors[i]];
      const { id, name } = sensor;

      sensor && items.push(
        <StyledTreeItem
          key={`unit-${id}`}
          nodeId={`unit-${id}`}
          labelText={name}
          node={
            <Checkbox1
              checked={!!currentSelectedSensors[id]}
              onChange={(event: any) => {
                if (event.target.checked) {
                  currentSelectedSensors[id] = true;
                  setCurrentSelectedSensors({ ...currentSelectedSensors });
                } else {
                  delete currentSelectedSensors[id];
                  setCurrentSelectedSensors({ ...currentSelectedSensors });
                }
              }
              }
            />}
          color="#545964"
          bgColor="#fff"
        />);
    }
    return items;
  };

  return (
    <div>
      <div className={classes.treeHeader}>
        <Typography className={classes.headerTitle}>{t`Select Units`}</Typography>
        <IconButton disableRipple onClick={onClose} className={classes.IconBtnStyle}><Close color="#7f7692" /></IconButton>
      </div>
      <TreeView
        className={classes.root}
        defaultExpanded={["1"]}
        defaultCollapseIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ marginLeft: "7px", marginRight: "2px" }} /> </>}
        defaultExpandIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ transform: "rotate(-90deg)", marginLeft: "7px", marginRight: "2px" }} /></>}
        defaultEndIcon={<div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} />}
      >
        {customersNodes()}
      </TreeView>
      <div className={classes.actions}>
        <Button width={130} onClick={() => onSave(Object.keys(currentSelectedSensors))}>{t`Save`}</Button>
      </div>
    </div>
  );
};
