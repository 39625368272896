import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dialogStyle: {
    width: "fit-content",
    maxWidth: "95%",
    display: "flex",
    minHeight: "fit-content",
    maxHeight: "calc(100% - 140px)",
    height: "calc(100% - 140px)",
    minWidth: 564,
    overflow: "hidden"
  },
  clearButton: {
    width: "30px",
    height: "30px",
    padding: 0,
    borderRadius: 6
  },
  dialogText: {
    ...theme.dialogs.title
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 15,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialogContent: {
    padding: 20,
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden",
    paddingRight: 50,
    height: "100%"
  },
  actionsHolder: {
    padding: 30,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 50,
    paddingTop: 20
  },
  outlinedInputRoot: {
    fontSize: 14,
    marginTop: 10,
    lineHieght: "normal",
    fontWeight: "normal",
    "& input": {
      height: "50px",
      padding: 0,
      paddingLeft: "15px",
      paddingRight: "15px"
    },
    "& input:focus + fieldset": {
      "&$notchedOutline": {
        border: "1px solid #4b1c46"
      }
    },
    "& ::placeholder": {
      fontSize: 14,
      color: "#545964",
      lineHieght: "normal",
      fontWeight: "normal"
    }
  },
  notchedOutline: {},
  labelStyle: {
    // color: theme.palette.colors.lightWhite,
    // width: "90%",
    display: "flex",
    // background: theme.gradient.default,
    // width: "100%",
    // marginBottom: "9px",
    flexFlow: "column nowrap",
    fontFamily: "RobotoBold",
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  selectUnitText: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
  },
  itemsContainer: {
    height: "100%",
    maxHeight: "100%",
    overflow: "auto",
    width: "calc(100% + 40px)"
  },
  errorText: {
    color: "#f44336",
    fontSize: 14
  },
  selectUnitContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  unitsHolder: {
    overflow: "auto",
    maxHeight: "calc(100% - 100px)",
    maxWidth: 492,
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent",
      borderRadius: 2
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#dfdde1"
    }
  },
  searchField: {
    width: "100%",
    marginBottom: "12px",
    height: "50px",
    "& div": {
      height: "50px",
      paddingLeft: "12px"
    },
    "& input": {
      paddingLeft: "10px"
    }
  }
}));
export default useStyles;
