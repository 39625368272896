import {
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import { Site as SDKSite, SmartRule as SDKSmartRule } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Close } from "../../icons";
import SvgQuestionMark from "../../icons/QuestionMark";
import { useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import NewApplySettings from "../Settings/NewApplySettings";
import useStyle from "./SmartRules.style";

const SmartRules: React.FC = (props: any) => {
  const classes = useStyle();
  const [smartRules, setSmartRules] = useState<any[]>([]);
  const [smartRuleName, setSmartRuleName] = useState<string | undefined>(undefined);
  const [smartRuleData, setSmartRuleData] = useState<any>({});
  const [smartRuleType, setSmartRuleType] = useState("0");
  const [smartRuleId, setSmartRuleId] = useState("");
  const [showOneSmartRule, setShowOneSmartRule] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unitsToSave, setUnitsToSave] = useState<string[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [clickedHead, setClickedHead] = useState("");
  const [lookupAnchor, setAnchor] = useState(null);
  const [filtersList, setFiltersList] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const tableHasFilters = !!selectedFilters["typeName"]?.length;
  const isInitialized = useStoreState((s) => s.isInitialized);
  const selections = useStoreState((s) => s.selections.selections);
  const { siteId = "", customerId = "" } = selections;
  const allUnits = useStoreState((s) => s.units.allUnits);
  const [error, setError] = useState(null);

  const allSites = useStoreState((s) => s.sites.allSites);
  const { canCreateSmartRules, canUpdateSmartRules } = allSites?.[siteId || ""]?.permissions || {};

  // will get the smartRuleTypes from types after add it from backend side
  // const smartRuleTypes: any = {
  //   "0": "abnormalWorkingHours",
  //   "1": "frequentSetpointChanges",
  //   "2": "setpointMaintenance",
  //   "3": "unitStandbyTime"
  // };
  const smartRuleTypesMirror: any = {
    "0": "Abnormal unit operation time",
    "1": "High rate of Setpoint Changes",
    "2": "SetPoint Maintenance",
    "3": "unit standby time"
  };
  const smartRuleTypesInputs: any = {
    "0": [{
      min: 0,
      max: 100,
      defaultValue: 80,
      key: "samplesOnPercentage",
      label: t`Threshold for 'normally' OFF `,
      get rangeHint() { return `(${this.min}-${this.max}%)`; }
    }],
    "1": [{
      // min: 0,
      defaultValue: 5,
      key: "setpointChangesThreshold",
      label: t`Setpoint Changes (Per Hour) Threshold `
      // get rangeHint() { return `(Min ${this.min})`; }
    }],
    "2": [{
      min: 1,
      max: 12,
      defaultValue: 4,
      key: "numberOfHoursToCheck",
      label: t`Time length to calculate percentage `,
      get rangeHint() { return `(${this.min}-${this.max}Hrs)`; }
    },
    {
      min: 1,
      max: 100,
      defaultValue: 30,
      key: "outOfRangePercentage",
      label: t`Percent time off setpoint `,
      get rangeHint() { return `(${this.min}-${this.max}%)`; }
    }
    ],
    "3": [{
      min: 1,
      max: 12,
      defaultValue: 4,
      key: "numberOfHoursToCheck",
      label: t`Time length to calculate percentage `,
      get rangeHint() { return `(${this.min}-${this.max}hours)`; }
    },
    {
      min: 1,
      max: 100,
      defaultValue: 30,
      key: "thermOnPercentage",
      label: t`Percent standby time `,
      get rangeHint() { return `(${this.min}-${this.max}%)`; }
    }
    ]
  };
  const getInputs = (smartRuleType: string) => {
    return <>
      {smartRuleTypesInputs[smartRuleType]?.map((inputData: any) => {
        let val = smartRuleData?.[inputData.key];
        if (val === undefined) {
          val = inputData.defaultValue;
          setSmartRuleData({ ...smartRuleData, [inputData.key]: val });
        }
        const hasError = (
          (val === "") ||
          (val !== undefined && isNaN(+val)) ||
          (typeof inputData.min === "number" && +val < inputData.min) ||
          (typeof inputData.max === "number" && +val > inputData.max)
        );
        return <>
          <div className={classes.textFieldLabelDiv}            >
            {inputData.label + (inputData.rangeHint || "")}
          </div>
          <TextField
            disabled={!canUpdateSmartRules}
            key={inputData.key}
            placeholder={`${inputData.label + (inputData.rangeHint || "")}`}
            variant="outlined"
            classes={{ root: classes.textFieldValue }}
            value={val || ""}
            // defaultValue={inputData.defaultValue}
            error={hasError}
            helperText={hasError && (inputData.label + t` is required ` + (inputData.rangeHint || ""))}
            onChange={({ target: { value } }) => {
              // if (
              //   (+value >= inputData.min && +value <= inputData.max) ||
              //   (!inputData.min && +value <= inputData.max) ||
              //   (!inputData.max && +value >= inputData.min) ||
              //   (!isNaN(+value)
              // )
              setSmartRuleData({ ...smartRuleData, [inputData.key]: value });
            }} />
        </>;
      }
      )}
    </>;
  };
  const smartRuleTypesDescriptions: any = {
    "0": <>{t`Unit is turned ON at a time it is  'normally' OFF.   The analysis is made for each slot of hour & weekday and is calculated automatically.
              The frequency threshold settings define what OFF occasions frequency is considered as 'normal' unit operation status.
              - Example: a Meeting room unit that was actually OFF during Wednesday at 8pm, in 7 times out of 10 occurrences (70%). If the threshold is set to 60% it means that the auto logic assumes the unit should be OFF on Wednesday at 8pm. Therefore, an alert will trigger when the unit is turned ON on a Wed at 8pm. But, if the Threshold was set to 80%, the logic will assume that unit ON during Wed at 8pm is also normal, and an alert will not trigger.
              - Potential Impact: High energy consumption. Higher wear and tear of HVAC systems, higher maintenance & support costs.
              - Things to look into when this happens:`}
      <ul key={"ui-" + 0}>
        <li key={"li-" + 0}>Use schedules to turn OFF units when not needed.</li>
        <li key={"li-" + 1}>Use Interlock on unit operation, with presence sensors.</li>
        <li key={"li-" + 2}>Restrict user controls during non working periods.</li>
      </ul>
    </>,
    "1": <>{t`Too many SetPoint changes within 1 hour. Users can set the threshold of maximum changes before sending an alert.
              Usually happens when the original room temp is far from the comfort setpoint. Users tend to 'play' with the setpoint to get their comfort level.
              - Example: in an office space where setpoint is expected to be quite fixed, a low rate of changes is expected, so a threshold of 3 is reasonable, and an alert will trigger if more then 3 changes occur during 1 hour.
              - Potential Impact: inefficient unit operation resulting in higher energy consumption. Tenant comfort is affected.
              - Things to look into when this happens:`}
      <ul key={"ui-" + 1}>
        <li key={"li-" + 0}>Apply schedules with unit setpoint that mostly satisfy tenants' comfort.</li>
        <li key={"li-" + 1}>Limit/Block local setpoint changes.  Set a schedule to turn unit ON before people arrive at the office so they come in to a comfort temperature.</li>
        <li key={"li-" + 2}>Use setback to keep the room in comfort temp zone.</li>
      </ul>
    </>,
    "2": <>{t`Alert will trigger when the room temp is not meeting the set point in high percent of the runtime (when unit is ON)
              Users can set what percentage of time that the setpoint is off range (±2C) will be considered as an anomaly. They can also define for what timeslot window the percentage is calculated.
              - Example: if defined at 30% and 12 hours time slot window, and the unit was off setpoint in 40% of occurrences between 8am-8pm, then the alert will trigger
              - Potential Impact: High energy consumption. Comfort is affected. High wear and tear of HVAC systems resulting in higher maintenance & support costs.
              - Things to look into when this happens:`}
      <ul key={"ui-" + 2}>
        <li key={"li-" + 0}>Check the room to see if a window/door is open. Consider adding door/window sensors and activate interlocks</li>
        <li key={"li-" + 1}>Verify setpoint is set correctly for the space.</li>
        <li key={"li-" + 2}>Check refrigerant levels and potential leaks.</li>
        <li key={"li-" + 3}>Verify AC unit capacity meets space needs.</li>
        <li key={"li-" + 4}>Unit was turned on for too short time and didn't have the time to reach the setpoint before it was turned off.</li>
        <li key={"li-" + 5}>Required setpoint is too far from starting room temp and it takes long time to get to the setpoint. (Consider applying setbacks)</li>
      </ul>
    </>,
    "3": <>{t`Alert will trigger when the unit was on standby (no demand) at a low percent of the runtime.
              Users can set what percentage of Standby time that anything below it will be considered as an anomaly. They can also define for what timeslot window the percentage is calculated.
              - Example: if defined at 30% and a 12 hours time slot window, and the unit was on standby in 10% of occurrences between 8am-8pm, then the alert will trigger.
              - Potential Impact: High energy consumption. Comfort is affected. High wear and tear of HVAC systems resulting in higher maintenance & support costs.
              - Things to look into when this happens:`}
      <ul key={"ui-" + 3}>
        <li key={"li-" + 0}>check space to see if a window/door is open.</li>
        <li key={"li-" + 1}>Verify setpoint is set correctly for the space.</li>
        <li key={"li-" + 2}>Check refrigerant levels and potential leaks.</li>
        <li key={"li-" + 3}>Verify AC unit capacity meets space needs.</li>
      </ul>
    </>
  };

  useEffect(() => {
    if (!siteId)
      return;
    setIsLoading(true);
    SDKSite.getSiteSmartRules(siteId)
      .then((res: any) => setSmartRules(Object.values(res)))
      .catch((e: any) => { setError(e.message); })
      .finally(() => { setIsLoading(false); });

  }, [refresh, siteId]);

  useEffect(() => {
    const filtersListVal: any = {};
    smartRules.forEach((rule) => {
      filtersListVal[smartRuleTypesMirror?.[rule.type]] = true;
    });
    setFiltersList(Object.keys(filtersListVal));
  }, [smartRules]);

  if (!isInitialized) { return <Loading />; }

  const onSave = async () => {
    if (!smartRuleName) {
      setSmartRuleName("");
    }

    const newSmartRuleData: any = {};
    const emptyValues = smartRuleTypesInputs[smartRuleType]?.filter(({ key }: { key: string }) => {
      if (smartRuleData[key] === "" || smartRuleData[key] === undefined) {
        newSmartRuleData[key] = "";
        return true;
      }
      newSmartRuleData[key] = smartRuleData[key];
      return false;
    });
    if (emptyValues?.length) {
      setSmartRuleData(newSmartRuleData);
    }

    if (!siteId || !unitsToSave?.[0]) {
      setUnitsToSave([]);
    }
    if (!smartRuleName || emptyValues.length || !siteId || !unitsToSave?.[0])
      return;
    const data = {
      name: smartRuleName,
      data: newSmartRuleData,
      type: smartRuleType,
      units: unitsToSave.filter((unitId) => !!allUnits?.[unitId])
    };
    if (isEditing) {
      SDKSmartRule.updateSmartRule(smartRuleId, data).then(() => {
        setSmartRules(smartRules.map((rule: any) => rule.id === smartRuleId ? ({ ...rule, ...data }) : rule));
        setIsEditing(false);
        onClose();
      })
        .catch((e: any) => { setRefresh(true); setError(e.message); });
    }
    else {
      SDKSmartRule.createSmartRule({ ...data, site: siteId }).then((res: any) => {
        setSmartRules([...smartRules, res]);
        onClose();
      });
    }
  };
  const onDelete = async (payload: any) => {
    if (!siteId)
      return;
    let message = null;
    setSmartRules(smartRules.filter((rule: any) => rule.id !== payload.id));
    await SDKSmartRule.delSmartRule(payload.id)
      .catch((e: any) => {
        setRefresh(true);
        message = e.message;
      });
    return message;
  };

  const onEdit = (smartRule: any) => {
    setIsEditing(!isEditing);
    setSmartRuleId(smartRule.id);

    setSmartRuleName(smartRule.name ? smartRule.name : "");
    setUnitsToSave(smartRule.units);
    setSmartRuleData(smartRule.data);
    setSmartRuleType(smartRule.type);
    setShowOneSmartRule(true);
  };

  const onClose = () => {

    if (isEditing) {
      setIsEditing(false);
    }

    setShowOneSmartRule(false);
    setSmartRuleName(undefined);
    setSmartRuleType("0");
    setSmartRuleData({});
    setUnitsToSave(undefined);
  };
  const updateClickedHead = (event: any, columnHead: string) => {
    setClickedHead(columnHead);
    setAnchor(event.currentTarget);
  };

  const onApply = (newSelectedFilters: any) => {
    const selectedFiltersObject = { [clickedHead]: newSelectedFilters };
    setSelectedFilters({ ...selectedFilters, ...selectedFiltersObject });
    setClickedHead("");
  };

  const setRuleStatus = (smartRule: any) => {
    const data = {
      name: smartRule.name,
      data: smartRule.data,
      type: smartRule.type,
      units: smartRule.units,
      isEnabled: !smartRule.isEnabled
    };
    SDKSmartRule.updateSmartRule(smartRule.id, data).then(() => {
      setSmartRules(smartRules.map((rule: any) => rule.id === smartRule.id ? ({ ...rule, ...data }) : rule));
    })
      .catch((e: any) => { setRefresh(true); setError(e.message); });
  };

  const smartRuleList = () => {
    return siteId && (!!smartRules?.length || isLoading) ? (
      <Grid container={true} className={classes.smartRulesContainer}>
        <Paper elevation={0} className={classes.smartRulesPaper}>
          <TableContainer className={classes.smartRulesTableContainer}>
            <Table stickyHeader={true} className={classes.smartRulesTable} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >
                    {t`RULE NAME`}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                    onClick={(e: any) => updateClickedHead(e, "typeName")}
                  >
                    <div
                      className={classes.headContainer}
                    >
                      {t`TYPE`}
                      <FilterList
                        className={clsx(classes.filterStyle, {
                          [classes.blueFilter]: !_.isEmpty(selectedFilters.typeName)
                        })}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="center"
                  >
                    {t`STATUS`}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="center"
                  >
                    {t`DELETE`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? <Paper elevation={0} className={clsx(classes.paperTableContainer, classes.loaderPaper)}>
                  <Paper className={classes.loaderContainer}>
                    <CircularProgress />
                    <Typography variant="h5">{t`Loading Smart Rules`}</Typography>
                  </Paper>
                </Paper>
                  : _.orderBy(searchTerm?.length ? smartRules?.filter((item: any) => (item?.name?.toUpperCase()?.includes(searchTerm?.toUpperCase()) || item?.units?.find((unit: any) => allUnits[unit]?.name?.toUpperCase()?.includes(searchTerm?.toUpperCase())))) : smartRules, [(item: any) => item?.name?.toUpperCase()],
                    ["asc"]).filter((rule) => !tableHasFilters || selectedFilters?.["typeName"]?.indexOf(smartRuleTypesMirror?.[rule.type]) !== -1).map((rule: any, index: number) => {
                      const { canDelete, canUpdate } = rule?.permissions || {};

                      return (
                        <TableRow
                          hover={true}
                          tabIndex={-1}
                          key={index}
                          classes={{ root: classes.overWritePadding }}
                          onDoubleClick={() => { onEdit(rule); }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: classes.overWritePadding }}
                            align="left"
                          >
                            {rule.name}
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            {smartRuleTypesMirror?.[rule.type]}
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="center">
                            <CoolSwitch
                              className={!canUpdate && classes.disabledStyle}
                              disabled={!canUpdate}
                              switchChange={() => {
                                setRuleStatus(rule);
                              }}
                              checked={rule.isEnabled}
                              name="is2FA"
                            />
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="center">
                            <Delete
                              disabled={!canDelete}
                              type={t`Rule`}
                              object={rule}
                              detach={onDelete}
                              buttonClass={classes.deleteIcon}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {clickedHead && (
          <Lookup
            filtersList={filtersList}
            appliedFilters={selectedFilters?.[clickedHead]}
            onApply={onApply}
            lookupAnchor={lookupAnchor}
            onClose={() => setClickedHead("")}
            tableHasFilters={tableHasFilters}
            clearAllFilters={() => setSelectedFilters({})}
          />
        )}
      </Grid>
    )
      : <Typography className={classes.noData}>{t`There are no smart rules for this site`}</Typography>;
  };

  const handleAgree = (units: any) => {
    setUnitsToSave(units);
  };
  const oneSmartRule = () => {
    if (!siteId)
      return;

    return (
      <Dialog
        open={showOneSmartRule}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        classes={{ paper: classes.dialog }}
      >

        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Auto Learning Adaptive Rules`}</Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} onClick={onClose}><Close color="#7f7692" /></IconButton>
        </div>

        <div className={classes.dialogContent}>
          <Grid item={true} xs={7} className={classes.nameContainer}>
            <div className={classes.textFieldLabelDiv}            >
              {t`Rule Name`}
              <LightTooltip title={t`Rule Name`}>
                <IconButton disableRipple aria-label={t`Rule Name`} classes={{ root: classes.iconButton }}>
                  <SvgQuestionMark style={{ color: "#fff" }} />
                </IconButton>
              </LightTooltip>
            </div>
            <TextField
              disabled={!canUpdateSmartRules}
              id="outlined-basic"
              // label={t`Rule Name`}
              variant="outlined"
              placeholder={t`Enter rule name`}
              classes={{ root: classes.textFieldValue }}
              value={smartRuleName}
              error={smartRuleName === ""}
              helperText={smartRuleName === "" && t`*Need rule name to save`}
              onChange={(e: any) => setSmartRuleName(e.target.value)}
            />
            <div className={classes.textFieldLabelDiv}            >
              {t`Rule Type`}
              <LightTooltip title={t`Rule Type`}>
                <IconButton disableRipple aria-label={t`Rule Type`} classes={{ root: classes.iconButton }}>
                  <SvgQuestionMark style={{ color: "#4b1c46" }} />
                </IconButton>
              </LightTooltip>
            </div>
            <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }} >
              {/* <InputLabel id="smart-rule-type-label">Rule Type</InputLabel> */}
              <Select
                disabled={!canUpdateSmartRules}
                style={{ marginBottom: 30 }}
                variant="outlined"
                labelId="smart-rule-type-label"
                value={smartRuleType}
                // label="Rule Type"
                onChange={(event: any) => {
                  setSmartRuleType(event.target.value as string);
                }}
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  getContentAnchorEl: null
                }}
              >
                {Object.entries(smartRuleTypesMirror).map(([value, name]: any) => <MenuItem key={value} style={{ paddingLeft: 10 }} value={value}>{name}</MenuItem>)}
              </Select>
            </FormControl>
            <Typography className={classes.headerStyle}>
              {t`Rule Settings`}
            </Typography>
            {getInputs(smartRuleType)}
            <Typography className={classes.noteStyle}>
              <strong style={{ fontWeight: "bold" }}>{t`Note: `}</strong>{smartRuleTypesDescriptions[smartRuleType]}
            </Typography>
          </Grid>

          <Grid item={true} xs={5}>
            <Paper className={classes.rightCard} elevation={0}>
              <Typography className={classes.headerStyle}>{t`Unit selection`}</Typography>
              <FormControl component="fieldset" className={classes.unitListContainer}>
                <div className={classes.smartRuleUnitLists} >
                  {unitsToSave?.length === 0 && <p className={classes.error}>{t`*Check at least one unit`}</p>}
                  <NewApplySettings
                    disabled={!canUpdateSmartRules}
                    save={handleAgree}
                    units={unitsToSave || []}
                    noPopup={true}
                    oneSite={siteId}
                    useControlUnits={true}
                  />
                </div>

              </FormControl>
            </Paper>
          </Grid>

        </div>

        <div className={classes.actionsHolder}>
          {/* <Button
            white
            width={150}
            marginRight
            onClick={onClose}>
            {t`Cancel`}
          </Button> */}
          <Button
            disabled={!canUpdateSmartRules}
            width={150}
            onClick={() => {
              onSave();
            }}>
            {t`Apply`}
          </Button>
        </div>
      </Dialog>
    );
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot, input: classes.inputBase },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );
  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={[t`Automation`]}
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection={true}
          hideUnitSelection={true}
          countControlUnits
          hideIndoor
          hideOther
          hideBsBox
          hideOutdoor
          screenTitle="smartRuleReports"
          searchComponent={searchComponent}
        />
        {!!siteId &&
          <div className={classes.buttonContainer}>
            <Button
              disabled={!canCreateSmartRules}
              onClick={() => { setShowOneSmartRule(true); setRefresh(false); }}
            >
              {t`Add New Rule`}
            </Button>
          </div>}
        {siteId && smartRuleList()}
        {showOneSmartRule && oneSmartRule()}
        {error && <ErrorBox error={error} onClose={() => { setError(null); }} />}
        {!siteId && <FilterRequire type={t`site`} />}
      </div>
    </div>
  );
};

export default SmartRules;
