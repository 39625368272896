import { makeStyles } from '@material-ui/core/styles';
import { placeBackgroundColor } from '../../services/utils';

export const useStyles = makeStyles((theme: any) => ({
  view: {
    width: '100%',
    height: '100%'
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.5em',
    alignItems: 'center',
    height: 'auto',
    width: '300px',
    padding: '10px 10px 0 10px',
    flex: 1,
    ...placeBackgroundColor(theme.palette.background.topDropDownDropDown),
    '&>div>header': {
      backgroundColor: '#556972',
      borderTop: 'none',
      color: 'white',
      justifyContent: 'center'
    },
    '&>div>header:hover': {
      backgroundColor: '#556972',
      borderTop: 'none',
      color: 'white'
    },
    '&>div>header>span': {
      padding: '0.2em'
    }
  },

  modal: {
    '&>div': {
      top: '10%'
    },
    '&>div>div': {
      top: '0',
      width: '100%'
    }
  },

  select: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    marginBottom: '10px',
    '&>div': {
      padding: '0',
      height: 'auto',
      width: 'initial',
      margin: '0.5rem'
    },
    '&>div>div>select': {
      width: '100%'
    },
    '&>div>div>select>option': {
      whiteSpace: 'normal',
      maxWidth: '50px'
    },
    '&>div>div>select:disabled': {
      display: 'none'
    }
  },

  Dropdown: {
    background: 'rgba(255, 255, 255, 0.15)',
    margin: '0 0 10px',
    borderRadius: '6px',
    '& label': {
      color: 'red',
      background: 'red'
    }
  },

  submitButton: {
    backgroundColor: '#556972',
    color: 'white',
    borderRadius: '0',
    margin: '0'
  },
  titleContainer: {
    display: 'inline'
  },
  indoor: {
    fontSize: '1.5rem',
    paddingBottom: '0.5rem'
  },
  outdoor: {
    paddingBottom: '0.5rem'
  },
  hidden: {
    display: 'none'
  },
  outdoorChild: {
    marginLeft: '1rem'
  },
  '@media (min-width: 800px)': {
    '& modal > div': {
      left: '20%'
    }
  },
  menuWrapper: {
    width: '100%'
  },
  input: {
    color: theme.palette.colors.menuFont
  },
  textField: {
    marginBottom: "10px",
    "& fieldset": {
      borderColor: "rgba(170, 162, 170, 0.3)",
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "rgba(170, 162, 170, 0.3) !important"
      }
    }
  }
}));
