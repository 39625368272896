import { Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";

import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import styles from "./SwitchItem.style";
const SwitchItem = (props: any) => {
  const { title, onChange, value, disabled = false, hideSwitch = false, classes: classesProps = {} } = props;
  const { switchContainerProps } = classesProps;
  const classes = styles();

  return (
    <div className={clsx(classes.switchContainer, switchContainerProps)}    >
      <Typography className={classes.switchTitle}>{title}</Typography>
      {!hideSwitch &&
        <CoolSwitch
          disabled={disabled}
          checked={!value}
          onChange={({ target: { checked } }: any) => onChange(!checked)}
        />}
    </div>
  );
};

export default SwitchItem;
