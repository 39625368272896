import { makeStyles, Theme } from "@material-ui/core";
import { BorderBottom } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    width: "100vw",
    height: "calc(100% - 85px)",
    background: "rgb(236, 235, 239)"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto"
  },
  IOSContentArea: {
    height: "calc(100% - 40px)"
  },
  title: {
    color: theme.palette.primary.veryDark || "#15111f",
    fontSize: "20px",
    fontWeight: 500
  },
  titleRow: {
    height: "55px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px",
    borderBottom: `1px solid ${theme?.palette?.colors?.lightGray || "#e5e2e5"}`
  },
  groupRow: {
    height: "50px",
    minHeight: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 12px",
    padding: "0 8px",
    borderBottom: `1px solid ${theme?.palette?.colors?.lightGray || "#e5e2e5"}`,
    position: "relative"
  },
  groupName: {
    color: theme.palette.primary.veryDark || "#15111f",
    fontSize: "16px",
    fontWeight: "normal"
  },
  arrow: {
    color: "#7f7692"
  }
}));

export default useStyles;
