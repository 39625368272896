import { createStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
const unitPanelStyle = (theme: any) =>
  createStyles({
    selected: { background: alpha("#AAA2AA", 0.3) },
    mainIconsStyle: {
      padding: "11px",
      fontSize: "16px",
      background: theme.palette.colors.controlButtonColor,
      width: "62px",
      height: "62px",
      color: alpha("#fdfdfe", 0.6),
      "&:hover": {
        background: theme.palette.colors.controlButtonColorHover,
      },
      "&.Mui-disabled": {
        background: theme.palette.colors.controlButtonColor,
        color: alpha("#fdfdfe", 0.6),
      },
    },
    unitView: {
      display: "flex",
      flexFlow: "column nowrap",
      height: "100%",
      width: "calc(100% - 7px)",
      marginTop: "35px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.26)",
      },
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px",
      maxWidth: 730,
      alignSelf: "center",
    },
    siteTemp: {
      width: "55px",
      opacity: 0.5,
      fontSize: 26,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
    },
    scaleStyle: {
      fontSize: 14,
    },
    nameSection: {
      display: "flex",
      flexFlow: "column nowrap",
      maxWidth: "calc(100% - 140px)",
      padding: "0 20px",
      alignItems: "center",
      minHeight: "60px",
      justifyContent: "space-between",
      "& p": {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: "26px",
        lineHeight: "26px",
      },
    },
    loaderContainer: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loaderStyle: { color: alpha("#fff", 0.7) },
    powerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: 77,
      height: 74,
    },
    powerIconStyle: {
      padding: 0,
      zIndex: 100,
      "&:hover": {
        opacity: "0.5",
      },
    },
    powerOffButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
        },
        "& path": {
          fill: "#000",
        },
      },
    },
    powerOnButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
          opacity: 1,
        },
        "& path": {
          stroke: "#f05146",
        },
      },
    },
    powerTextStyle: {
      marginTop: "8px",
      color: "white",
      height: "16px",
      opacity: 0.5,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    redErrorContainer: {
      width: "155px",
      height: "155px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexFlow: "column nowrap",
      marginTop: "122px",
    },
    bigAlertIcon: {
      transform: "scale(1.5)",
      marginBottom: "21px",
    },
    alertBigMessageStyle: {
      lineHeight: "29px",
      fontSize: "22px",
      letterSpacing: "normal",
      color: "#f05146",
    },
    secondRowContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexFlow: "column nowrap",
      paddingBottom: "30px",
      height: "440px",
      justifyContent: "flex-end",
    },
    sensorWrapperStyle: {
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "50%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    powerWrapperStyle: {
      height: "100%",
      width: "100%",
      backgroundColor: alpha(theme.palette.colors.white, 0.1),
      borderRadius: "50%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "column nowrap",
    },
    iconStyle: {
      fontSize: "17.5px",
    },
    modeIcon: {
      margin: "0 5px",
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: 0,
    },
    alertContainer: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      minHeight: "70px",
      marginBottom: "30px !important",
    },
    alertIcon: { marginBottom: "6px" },
    alertMessageStyle: {
      fontSize: "16px",
      color: "#f05146",
      lineHeight: "16px",
    },

    filterAlert: {
      color: "#f7b500",
    },
    controlDiv: {
      maxHeight: "326px",
      maxWidth: "336px",
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "326px",
      minWidth: "336px",
      "@media (max-width: 328px)": {
        minWidth: "312px",
      },
    },
    controlWrapperStyle: {
      position: "absolute",
      transform: "scale(1.19)",
      "@media (max-width: 328px)": {
        transform: "scale(0.93)",
      },
    },
    sliderContainer: {
      marginTop: "-20px",
      maxWidth: "320px",
      maxHeight: "320px",
      zIndex: 1,
    },
    innerControls: {
      width: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center",
    },
    controlArrow: {
      background: "transparent !important",
      zIndex: 100,
      width: "60px",
      height: "60px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `#f05146 !important`,
        },
        transition: "all 1s",
      },
    },
    setPointStyle: {
      fontSize: "65px",
      color: "#fff",
      height: 65,
      lineHeight: "normal",
    },
    setPointDiscription: {
      fontSize: "20px",
      color: "#fff",
      height: 65,
      lineHeight: "normal",
    },
    timeDateStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    ampTempContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      margin: "5px 0px 0px 20px",
      "& span": {
        fontSize: "12px",
        alignSelf: "flex-start",
        color: "white",
        width: "20px",
      },
    },
    ampTempStyle: {
      fontSize: "24px",
      color: "#fff",
    },
    dsmodeStyle: {
      fontSize: "20px",
      color: "#fff",
      position: "absolute",
      top: "45px"
    },
    modeTitle: {
      fontSize: "50px",
      color: "#fff",
      fontWeight: "bold",
      position: "absolute",
    },
    lastRowContainer: {
      display: "flex",
      height: "62px",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
    },
    iconContainer: { objectFit: "contain", zIndex: 1050 },
    rightSpace: { marginRight: "10px" },
    showStyle: { visibility: "visible" },
    paper: {
      padding: "10px 0px",
      borderRadius: "31px",
      width: "62px",
      boxShadow: `0 2px 4px 0 ${alpha("#000", 0.5)}`,
      backgroundColor: "#fdfdfe",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "10px",
      "& li": {
        display: "flex",
        justifyContent: "center",
        borderRadius: "25px",
        padding: 0,
        width: "50px",
        height: "50px",
      },
    },
    removeElements: {
      display: "none",
    },
    innerControlsSensor: {
      width: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
    },
    measurementUnitsStyle: {
      fontWeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: " #ffffff",
      lineHeight: "11px",
      fontFamily: "-webkit-body",
      fontSize: "24px",
      alignSelf: "flex-end",
      marginBottom: "18px",
    },
    valueContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 80,
      position: "relative",
      flexFlow: "row wrap",
      margin: "7px 0",
    },
    valueContainerColumn: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      height: 94,
    },
    sensorSliderContainer: {
      marginTop: "11px",
      maxWidth: "320px",
      maxHeight: "152px",
      zIndex: 1,
      marginBottom: 10,
    },
    sensorValues: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
    },
    sensorValue: {
      fontFamily: "Roboto",
      fontSize: 60,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
      paddingRight: 5,
    },
    sensorValuesContainer: {
      position: "absolute",
      top: "134px",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      width: "200px",
      right: "68px",
    },
    sideArrowStyle: {
      height: 40,
    },
    oppositeSideArrowStyle: {
      height: 40,
      transform: "rotate(180deg)",
    },
    iconBtn: {
      background: "transparent",
    },
    valueControl: {
      position: "absolute",
      top: "145px",
      width: "270px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "row wrap",
    },
    disabledIcon: {
      opacity: 0.5,
    },
    tempUnit: {
      alignSelf: "flex-start",
      marginLeft: "-10px",
      fontFamily: "Roboto",
      fontSize: "40px",
      marginTop: "20px",
    },
    sensorIcon: {
      height: 80,
      width: 80,
      marginBottom: 17,
      marginTop: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dataTimeFont: {
      height: 20,
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "normal",
      lineHeight: 1.11,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    timeStyle: {
      marginRight: 20,
    },
    sensorValueStyle: {
      fontFamily: "Roboto",
      fontSize: 80,
      fontWeight: "normal",
      lineHeight: "80px",
      letterSpacing: "normal",
      color: "#ffffff",
      paddingRight: 5,
    },
    offText: {
      color: alpha("#fff", 0.4),
    },
    addTopMargin: {
      marginTop: 75,
    },
    measurementUnit: {
      fontWeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: " #ffffff",
      lineHeight: "11px",
      fontFamily: "-webkit-body",
      fontSize: "24px",
      alignSelf: "flex-end",
      marginBottom: "18px",
    },
    badge: {
      position: "absolute",
      top: "-5px",
      right: "0px",
      padding: "3px 7px",
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontSize: "12px",
      lineHeight: "normal",
      borderRadius: "10px",
    },
    redError: {
      color: "#f05146",
    },
    percentStyle: {
      fontSize: 40,
      fontFamily: "Roboto",
      marginBottom: "24px",
    },
    hidden: {
      visibility: "hidden",
    },
    airQualityContainer: {
      width: "100%",
      marginTop: "20px",
    },
    airQualityTitle: {
      margin: "20px 0",
      color: "white",
      fontSize: "22px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    tooltip: {
      maxWidth: "unset",
      backgroundColor: "white",
      color: "black",
    },
    power: {
      color: "#fff",
      fontSize: 24,
      fontWeight: 600,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    powerUnit: {
      fontSize: "18px",
      lineHeight: "24px",
      marginLeft: "4px",
    },
    thirdRowContainer: {
      height: "127px",
      borderRadius: "6px",
      backgroundColor: alpha("#ffffff", 0.1),
      width: "368px",
      maxWidth: "calc(100% - 46px)",
      minWidth: "fit-content",
      marginLeft: "auto",
      marginTop: "21px",
      marginRight: "auto",
      minHeight: "127px",
    },
    ovalIcon: {
      width: "40px",
      height: "40px",
      backgroundColor: alpha("#ffffff", 0.1),
      padding: 0,
      "&:hover": {
        backgroundColor: alpha("#ffffff", 0.1),
      },
    },
    tankText: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
      marginBottom: 5,
      alignSelf: "flex-start",
    },
    paddingContainer: {
      maxWidth: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 35px 0px 20px",
      alignItems: "center",
      height: "100%",
    },
    icon: {
      color: "#ffffff",
      fontSize: 30,
    },
    mainTempText: {
      fontSize: "42px",
      margin: "0 15px",
      lineHeight: "55px",
    },
    tempText: {
      fontSize: "21.4px",
      margin: 0,
      marginBottom: 2,
    },
    tempSymbolStyle: {
      fontSize: "22.6px",
    },
    tempIcon: {
      marginTop: "-7px",
      marginRight: "3px",
    },
    columnFlex: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      marginTop: 10,
    },
    ovalBoosterIcon: {
      width: "50px",
      height: "50px",
      padding: 0,
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
      },
      marginBottom: 7,
    },
    boosterOff: {
      opacity: 0.5,
    },
    modeNowText: {
      fontSize: 18,
      lineHeight: "24px",
    },
    boilerIcon: {
      fontSize: 36,
    },
    iconDiv: {
      fontSize: 16,
      color: "white",
      margin: "10px auto 10px",
    },
    localSetpoint: {
      display: "flex",
      marginLeft: "25px",
      "& span": {
        color: "white",
        width: "25px",
      },
    },
    dialogStyle: {
      padding: "31px 26px",
      minWidth: "330px",
      width: "330px",
      height: "auto",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 0,
      fontSize: "26px",
      fontWeight: 600,
      lineHeight: "normal",
      color: theme.palette.colors.mainIconSelected,
      marginBottom: "13px",
    },
    dialogContentStyle: {
      padding: 0,
      marginBottom: "26px",
      overflow: "hidden",
    },
    cancelButtonStyle: { width: "129px" },
    dialogActionStyle: { padding: 0, margin: 0, justifyContent: "flex-start" },
    confirmButtonStyle: {
      color: theme.palette.colors.lightBlack,
      border: `solid 1px ${theme.palette.colors.iconSelected}`,
      width: "129px",
      boxShadow: "none",
    },
    timeInput: {
      width: "90px",
      height: "40px",
      "& input": {
        "-moz-appearance": "textfield",
        textAlign: "center",
        padding: "0",
        paddingRight: "5px",
      },
    },
    switchContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    timeInputsContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      paddingTop: "20px",
      alignSelf: "self-start",
      justifyContent: "space-between",
    },
    label: {
      marginLeft: "20px",
      marginTop: "10px",
    },
    durationLabel: {
      marginTop: "10px",
    },
    root: {
      width: 56,
      height: 24,
      padding: "5px 7px !important",
      marginRight: -6,
    },
    switchBase: {
      left: 6,
      padding: 0,
      color: "#d5d2d5",
      "&$checked": {
        left: 0,
        transform: "translateX(26px)",
        opacity: 1,
        color: "#4b1c46",
        "& + $track": {
          backgroundColor: "rgba(75, 28, 70, 0.5)",
          border: "none",
          opacity: 1,
        },
      },
      "&$focusVisible $thumb": {
        color: "green",
      },
    },
    thumb: {
      width: 24,
      height: 23,
      boxShadow: "none",
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: "rgba(213, 210, 213, 0.6)",
      opacity: 1,
      height: 15,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
    curveTempIcon: {
      position: "absolute",
      top: -13,
      left: 7,
      border: "1px solid white",
      borderRadius: "50%",
      width: 15,
      height: 15,
      fontSize: 12,
    },
    curveTempOffIcon: {
      fontSize: 12,
      marginTop: 3,
    },
    runningTimer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    hiddenStyle: {
      visibility: "hidden",
    },
    dsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "15px",
    },
    dsInnerControls: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center",
    },
    dsSetPointStyle: {
      fontSize: "20px",
      color: "#fff",
      height: 30,
      lineHeight: "normal",
    },
    dsSetpoint: {
      display: "flex",
      marginLeft: "16px",
      "& span": {
        color: "white",
        fontSize: "12px",
        width: "14px",
      },
    },
    dsModeStyle: {
      fontSize: "12px",
      color: "#fff",
      paddingLeft: "3px",
    },
    dsControlArrow: {
      background: "transparent !important",
      zIndex: 100,
      width: "60px",
      height: "40px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `#f05146 !important`,
        },
        transition: "all 1s",
      },
    },

    dsModeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      "& span": {
        fontSize: "12px",
        alignSelf: "flex-start",
        color: "white",
        width: "20px",
      },
    },
  });
export default unitPanelStyle;
