import {
  alpha,
  Dialog, DialogActions, DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  OutlinedInput as Input,
  Switch,
  Typography,
  Button as MUIButton
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import { BsGraphUp } from "react-icons/bs";
import { IoIosTimer } from "react-icons/io";
import { useHistory } from "react-router-dom";
import tinycolor2 from "tinycolor2";
import { t } from "ttag";

import { Add, Remove } from "@material-ui/icons";
import _ from "lodash";
import Button from "../../../../cool_widgets/Button";
import {
  AlertControl,
  ArrowUp,
  AutoMode,
  AutoVRMode,
  Boiler,
  ColdIcon,
  ControlWrapper,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanAutoIcon,
  FanMode,
  FixedSpeed,
  HeatIcon,
  ScheduleOffIcon,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingOff2,
  SwingVer,
  Temperature
} from "../../../../icons/index";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { ArrowDownControl, ColdMode, HotMode, SwitcherOffIcon as SvgPowerOff, SwitcherOnIcon as SvgPowerOn } from "../../../../svgComponents";
import unitPanelStyle from "./UnitPanel.style";
import Counter from "../../../../components/Counter/Counter";
import { CircularSliderWithChildren } from "../../../../components/CirclularSlider";
import { dsRanges } from "../../../../constants/unitsConsts";

interface IObject {
  [key: string]: any;
}

const colorRanges: any = {
  COOL: ["#35a8e0", "#2d2e82"],
  HEAT: ["#f8b133", "#f05146"],
  AUTO: ["#35a8e0", "#f05146"],
  DRY: ["#266101", "#266101"],
  FAN: ["#4f00ed", "#4f00ed"],
  default: ["#ffffff", "#000000"],
  HH: ["#f8b133", "#f05146"],
  HAUX: ["#f8b133", "#f05146"]
};

const mainIconsColors = {
  colorblue: "#35A8E0",
  color1: "#4B1C46",
  color2: "#AAA2AA",
  maincolor: "#AAA2AA",
  opacity: 1
};

const defaultKeys = { modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: { 0: "activeSetpoint", 1: "activeSetpoint", 2: "activeSetpoint", 3: "activeSetpoint", 5: "activeSetpoint", 6: "activeSetpoint", 7: "activeSetpoint" } };
const keysBySubType: any = {
  0: defaultKeys,
  1: { modeKey: "heaterMode", powerKey: "heaterTankONOFF", ambientTempKey: "heaterRoomTemp", setpointKeyPerMode: { 0: "heaterCoolingSP", 1: "heaterHeatingSP", 2: "", 3: "", 5: "" } },
  2: defaultKeys
};

const defaultTempLimits = { 1: [16, 32], 2: [60, 90] };
const waterHeaterModesMap: any = { 0: 2, 1: 1, 2: 0 };
const waterHeaterModesToNormalMap: any = { 0: 2, 1: 1, 2: 0 };

const UnitPanel: React.FC<any> = (props) => {

  const history = useHistory();
  const useStyles = makeStyles(unitPanelStyle);
  const classes: any = useStyles();

  const {
    unit,
    setActiveSetpoint,
    setActiveOperationMode,
    operationModesMirror,
    setActiveFanMode,
    setActiveSwingMode,
    addMessage,
    operationStatusesMirror,
    isCelsius,
    siteTemp,
    tempSymbol
  } = props;

  const {
    updateWaterHeaterDHWTankSwitch,
    updateWaterHeaterDHWTankSetpoint,
    updateWaterHeaterMode,
    updateWaterHeaterBooster,
    updateWaterHeaterCoolingSetpoint,
    updateWaterHeaterHeatingSetpoint,
    updateUnitLocally
  } = useStoreActions((actions) => actions.units);
  const userPref = useStoreState((state) => state.users.userPreferences);
  const types = useStoreState((state) => state.types);
  const unitPowerControl = useStoreActions((state) => state.units.changePowerState);
  const { getTimers, createTimer, deleteTimer, getUnitTimers } = useStoreActions((actions) => actions.timers);
  const { siteId = "" } = useStoreState((s) => s.selections.mobileSelections);

  const { operationStatuses, fanModesExtended, operationModesExtended, swingModes, unitTypes, unitSubTypes, hvacBrands = [], curveTempModes } = types;

  const [clickedIcon, setClickedIcon] = useState<string>("");
  const [startTemp, setStart] = useState<number>(0);
  const [endTemp, setEnd] = useState<number>(48);
  const [startAngle, setStartAngle] = useState<number>(0);
  const [endAngle, setEndAngle] = useState<number>(360);
  const [currentColor, setCurrentColor] = useState<any>("#888");
  const [localActiveSetpoint, setLocalActiveSetPoint] = useState<any>("");
  const [tanklocalActiveSetpoint, setTankLocalActiveSetPoint] = useState<any>(0);
  const [openAutoTimer, setOpenAutoTimer] = useState<boolean>(false);
  const [timerIsActive, setTimerIsActive] = useState<boolean>(false);
  const [runningTimer, setRunningTimer] = useState<any>(null);
  const [unitTimer, setUnitTimer] = useState<any>(null);
  const [timerMins, setTimerMins] = useState<any>("0");
  const [timerHours, setTimerHours] = useState<any>("0");

  const {
    id: unitId,
    name,
    activeFanMode,
    activeCurveTempMode = activeFanMode,
    activeSwingMode,
    temperatureLimits,
    enableAutoMode,
    enableCoolMode,
    enableHeatMode,
    supportedOperationModes = [],
    supportedFanModes = [],
    supportedCurveTempModes = supportedFanModes,
    supportedSwingModes = [],
    message,
    enableSetpoint,
    enableOnState,
    enableOnoff,
    filter,
    schedules = [],
    isHalfCDegreeEnabled,
    capabilityFlags = {},
    subType = 0,
    heaterTankSP,
    heaterBooster,
    brand,
    isConnected,
    heaterTankTemp,
    showControlAppAutoOffTimer = false,
    permissions = {},
    realActiveOperationMode = 7
  } = unit;

  const { canControlFanMode, canControlOperationMode, canControlOperationStatus, canControlSetpoint, canControlSwingMode, canControlBooster, canUpdateTimers } = permissions;

  const CurveTempIcon = ({ i }: { i: number }) => i ?
    <div style={{ width: 24, height: 24, position: "relative" }}>
      <span className={classes.curveTempIcon}>{i}</span>
      <BsGraphUp style={{ height: 24, width: 24 }} />
    </div>
    : <div style={{ display: "grid" }}>
      <BsGraphUp style={{ height: 24, width: 24 }} />
      <span className={classes.curveTempOffIcon} >{t`OFF`}</span>
    </div>;

  const mainIcons: IObject = {
    operation: {
      0: <ColdIcon />,
      1: <HeatIcon />,
      2: <AutoMode />,
      3: <DryIcon />,
      5: <FanMode />,
      4: <span style={{ color: "rgb(240, 81, 70)" }}>HAUX</span>,
      6: <span style={{ color: "rgb(240, 81, 70)" }}>HH</span>,
      7: <AutoVRMode />,
    },
    fan: {
      0: subType === 2 ? <Typography>{t`LOW`}</Typography> : <Fan1Icon />,
      1: <Fan2Icon />,
      2: <Fan3Icon />,
      4: <Fan4Icon />,
      3: <FanAutoIcon />,
      5: <Typography style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>{t`Very Low`}</Typography>,
      ON: <Typography>{t`On`}</Typography>,
      OFF: <Typography>{t`Off`}</Typography>
    },
    curveTempModes: {
      // 0: <div >
      //   <BsGraphUp style={{ height: 24, width: 24 }} />
      //   <span className={classes.curveTempOffIcon} >{t`OFF`}</span>
      // </div>
      0: <Typography>{t`Off`}</Typography>
      // 1: <CurveTempIcon i={1} />,
      // 2: <CurveTempIcon i={2} />,
      // 3: <CurveTempIcon i={3} />,
      // 4: <CurveTempIcon i={4} />,
      // 5: <CurveTempIcon i={5} />,
      // 6: <CurveTempIcon i={6} />,
      // 7: <CurveTempIcon i={7} />,
      // 8: <CurveTempIcon i={8} />,
      // 9: <CurveTempIcon i={9} />
    },
    swing: {
      0: <SwingVer />,
      4: <SwingHor />,
      1: <Swing30 />,
      2: <Swing45 />,
      3: <Swing60 />,
      5: <Swing />,
      6: <SwingOff2 />,
      7: <Typography>{t`On`}</Typography>
    }
  };

  const createMenu = (activeMode: number, handleChoose: any, supportedArray: Array<number | string>, type: string, modeTypes: any) => {
    const icons = mainIcons[type];
    if (!supportedArray.length) { return <></>; }
    const fanOrder: { [key: string | number]: number } = {
      3: 0,
      6: 1,
      4: 2,
      2: 3,
      7: 4,
      1: 5,
      0: 6,
      5: 7,
      ON: 8,
      OFF: 9
    }
    const sortedSupportedArray = supportedArray.sort((a, b) => fanOrder[a] - fanOrder[b])
    return (
      <>
        {(type === "fan" ? sortedSupportedArray : supportedArray).map((mode: (number | string), i: number) => {
          const selected = `${activeMode}` === mode;
          const icon = icons[mode];

          return (
            <MenuItem
              key={`mode-${i}`}
              style={{ backgroundColor: selected ? alpha("#AAA2AA", 0.3) : "" }}
              onClick={() => handleChoose(mode)}
            >
              {icon ? React.cloneElement(icon, mainIconsColors) : (modeTypes[mode] || "")}
            </MenuItem>
          );
        })}
      </>
    );
  };

  const {
    enableOperationStatusSelection = false,
    enableOperationModeSelection = false,
    enableFanModeSelection = false,
    enableSwingModeSelection = false,
    enableSetpointControl = false,
    enableAmbientTemperature = false,
    enableHeaterTankSPControl = false,
    enableHeaterBoosterStatusSelection = false,
    setpointControlModes = {},
    enableHeaterTankTemp = false,
    enablefanModeOnOffSelection = false,
    enableCurveTempSelection = false
  } = capabilityFlags;

  const powerOffVal = operationStatusesMirror && +operationStatusesMirror.off;
  const powerOnVal = operationStatusesMirror && +operationStatusesMirror.on;

  const enabledMode: IObject = {
    0: enableCoolMode,
    1: enableHeatMode,
    2: enableAutoMode,
    5: true,
    4: true,
    6: true,
    7: true
  };

  const { modeKey, powerKey, ambientTempKey, setpointKeyPerMode } = keysBySubType[subType] || defaultKeys;
  const mappedMode = unit[modeKey];
  const mode = subType === 1 ? waterHeaterModesToNormalMap[mappedMode] : mappedMode;
  const activeSetpoint = unit[setpointKeyPerMode[mode]];
  const ambientTemperature = unit[ambientTempKey];
  const power = unit[powerKey];
  const isPowerOn = power === powerOnVal;

  const unitBrand = subType === 1 ? hvacBrands.find((hvacBrand: any) => hvacBrand.value === brand) : {};
  const isBoosterSupported = !!unitBrand.hasBooster;
  const isBoosterOn = +heaterBooster === +powerOnVal;

  const temperatureScale = useStoreState((state) => state.users.me.temperatureScale);
  const defaultCool = isCelsius ? 28 : 82;
  const defaultHeat = isCelsius ? 18 : 64;
  const [bottomLimit, setBottomLimit] = useState<number>(defaultCool);
  const [topLimit, setTopLimit] = useState<number>(defaultHeat);
  const { createDualSetpiontRule, updateDualSetpiontRule, getUnitDualSetpiontRule } = useStoreActions((actions) => actions.units);
  const [ruleId, setRuleId] = useState<string>("");

  // useEffect(() => {
  //   if (unitId) {
  //     getUnitDualSetpiontRule(unitId).then((dualSetpoint: any) => {
  //       if (dualSetpoint) {
  //         const {
  //           id,
  //           heatSP,
  //           coolSP,
  //         } = dualSetpoint || {}
  //         setRuleId(id);
  //         setTopLimit(heatSP);
  //         setBottomLimit(coolSP)
  //       } else {
  //         setTopLimit(defaultHeat);
  //         setBottomLimit(defaultCool);
  //       }
  //     })
  //   }
  // }, [unitId, temperatureScale]);


  const handleDualSetpoint2 = (type: string, value: number) => {
    let tempTop = topLimit;
    let tempBottom = bottomLimit;
    switch (type) {
      case "Cool":
        if (value >= (topLimit + 2)) {
          setBottomLimit(value);
        }
        else {
          return;
        }
        break;
      case "Heat":
        if (value <= (bottomLimit - 2)) {
          setTopLimit(value);
        }
        else {
          return;
        }
        break;
      default:
        break;
    }
    delayedDualSetpiontRule(ruleId, tempTop, tempBottom);
  };

  const delayedDualSetpiontRule = useCallback(_.debounce(async (ruleId, top, bottom) => {
    try {
      ruleId ?
        await updateDualSetpiontRule({
          ruleId,
          heatSP: top,
          coolSP: bottom
        })
        :
        await createDualSetpiontRule({
          heatSP: top,
          coolSP: bottom,
          unit: unitId
        })
    } catch (err: any) {
      addMessage({ message: err.message })
    }
  }, 1000), []);


  useEffect(() => {

    if (!unit || !temperatureLimits || !enableSetpointControl || (!isVA && !setpointControlModes[mode])) { return; }

    if (!enabledMode[mode]) {
      setStart(0);
      setEnd(isCelsius ? 100 : 140);
      setStartAngle(5);
      setEndAngle(355);
    } else {
      const limits = isCelsius ? defaultTempLimits[1] : defaultTempLimits[2];
      const midByType = isCelsius ? 24 : 75;
      const defaultMin = limits[0];
      const defaultMax = limits[1];

      const minLimit = Math.min(
        isVA ? temperatureLimits?.[realActiveOperationMode]?.[0] || defaultMin : temperatureLimits?.[mode]?.[0] || defaultMin,
        activeSetpoint
      );
      const maxLimit = Math.max(
        isVA ? temperatureLimits?.[realActiveOperationMode]?.[1] || defaultMax : temperatureLimits?.[mode]?.[1] || defaultMax,
        activeSetpoint
      );

      if (minLimit === startTemp && maxLimit === endTemp) { return; }

      setStart(minLimit);
      setEnd(maxLimit);
      const startAngle = (180 * +minLimit) / midByType;
      const endAngle = (180 * +maxLimit) / midByType;
      const issue = (startAngle >= endAngle) || (endAngle > 360);

      setStartAngle(!issue ? startAngle : 5);
      setEndAngle(!issue ? endAngle : 355);
    }
  }, [temperatureLimits, unit.id, activeSetpoint, mode, enableCoolMode, enableHeatMode, enableAutoMode]);

  useEffect(() => {
    const newVal = isHalfCDegreeEnabled && isCelsius ? Math.floor(activeSetpoint * 2) / 2 : Math.round(activeSetpoint);
    setLocalActiveSetPoint(newVal);
  }, [activeSetpoint, subType === 1 ? mode : false]);

  useEffect(() => {
    if (!enableHeaterTankSPControl) {
      return;
    }
    setTankLocalActiveSetPoint(Math.round(heaterTankSP));
  }, [heaterTankSP]);

  useEffect(() => {
    if (!enableOperationModeSelection) {
      setCurrentColor("#fff");
      return;
    }
    const mode1 = isVA ? operationModesExtended[realActiveOperationMode] : operationModesExtended[mode];

    const range = colorRanges[mode1] ? colorRanges[mode1] : colorRanges["default"];
    const c1 = range[0];
    const c2 = range[1];
    const absoluteVal = ((localActiveSetpoint - startTemp) / (endTemp - startTemp)) * 100;
    const c3 = tinycolor2.mix(c1, c2, absoluteVal);
    setCurrentColor(c3.toHexString());
  }, [localActiveSetpoint, operationModesExtended, mode, endTemp, startTemp]);

  const togglePower = () => {
    const value = operationStatuses[power] === "on" ? powerOffVal : powerOnVal;

    if (subType === 1) {
      updateWaterHeaterDHWTankSwitch({ id: unitId, data: { operationStatus: +value } })
        .then(() => updateUnitLocally({ id: unitId, unit: { heaterTankONOFF: value } }))
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }
    unitPowerControl({ unitId, state: value })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };

  const toggleBoilerBooster = () => {
    if (+activeFanMode !== 0) {
      return;
    }

    setActiveFanMode({ unitId, data: 1 })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };

  useEffect(() => {
    if (!unitId) {
      return;
    }
    getUnitTimers(unitId)
      .then((resp: any) => {
        setRunningTimer(resp);
      });
  }, [power, openAutoTimer]);

  const openAutoTimerDialog = () => {
    getTimers({ unitId, siteId }).then((timers: any) => {
      Object.values(timers).forEach((timer: any) => {
        const { units = [""] } = timer;
        if (units[0] === unitId) {
          const { isActive = false, turnOffInMinutes = 0 } = timer;
          setUnitTimer(timer);
          setTimerIsActive(isActive);
          setTimerMins(+turnOffInMinutes % 60);
          setTimerHours(Math.floor(+turnOffInMinutes / 60));
        }
      });
      setOpenAutoTimer(true);
    })
      .catch((err: any) => addMessage({ message: err.message }));
  };
  const closeAutoTimerDialog = () => {
    setOpenAutoTimer(false);
  };

  const onHoursChange = (value: any) => {
    if (value > 99 || value < 0 || value.length > 2) {
      return;
    }
    setTimerHours(value);
  };

  const onMinutesChange = (value: any) => {
    if (value > 59 || value < 0 || value.length > 2) {
      return;
    }
    setTimerMins(value);
  };

  const handleSaveTimer = () => {
    let hrs = timerHours ? +timerHours : 0;
    let mins = timerMins ? +timerMins : 0;
    createTimer({ unit: unitId, isActive: timerIsActive, turnOffInMinutes: hrs * 60 + mins })
      .then((res: any) => {
        closeAutoTimerDialog();
      })
      .then(() => {
        updateUnitLocally({ id: unitId, unit: { hasActiveTimer: timerIsActive } });
      });
  };

  const handleClose = () => {
    setClickedIcon("");
  };


  const handleChoose = (data: string) => {
    handleClose();

    if (clickedIcon === "operation") {
      if (subType === 1) {
        const waterHeaterEnum = waterHeaterModesMap[data];
        updateWaterHeaterMode({ id: unitId, data: { operationMode: waterHeaterEnum } })
          .then(() => updateUnitLocally({ id: unitId, unit: { heaterMode: waterHeaterEnum } }))
          .catch((err: any) => addMessage({ message: err.message }));
        return;
      }
      setActiveOperationMode({ id: unitId, mode: data })
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }

    if (clickedIcon === "fan") {
      setActiveFanMode({ unitId, data })
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }

    if (clickedIcon === "curveTempModes") {
      setActiveFanMode({ unitId, data })
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }

    if (clickedIcon === "swing") {
      setActiveSwingMode({ unitId, data })
        .catch((err: any) => addMessage({ message: err.message }));
    }
  };

  const handleIconClick = (e: any, name: string, isDisabled = false) => {
    if (isDisabled) {
      return;
    }
    setClickedIcon(name === clickedIcon ? "" : name);
  };
  const changeBooster = (value: any) => {
    const changeValueTo = value === 0 ? 1 : 0;
    updateWaterHeaterBooster({ id: unitId, data: { operationStatus: changeValueTo } })
      .then(() => updateUnitLocally({ id: unitId, unit: { heaterBooster: changeValueTo } }))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const delayedCallTank = useCallback(_.debounce((newValue: any) => {
    updateWaterHeaterDHWTankSetpoint({ id: unitId, data: { setpoint: newValue } })
      .then(() => {
        updateUnitLocally({ id: unitId, unit: { heaterTankSP: newValue } });
      })
      .catch((err: any) => {
        setTankLocalActiveSetPoint(Math.round(heaterTankSP));
        addMessage({ message: err.message });
      });
  }, 1000), []);

  const handleSliderChange = (updatedSetPoint: any) => {
    setLocalActiveSetPoint(updatedSetPoint);
    delayedSetpointChange(updatedSetPoint, mode);
  };

  const changeTemp = (isAdd: boolean) => {
    const addScale = isHalfCDegreeEnabled && isCelsius ? 0.5 : 1;
    if (
      (isAdd && (localActiveSetpoint === endTemp || localActiveSetpoint > endTemp)) ||
      (!isAdd && (localActiveSetpoint === startTemp || localActiveSetpoint < startTemp))
    ) { return; }

    const updatedSetPoint = isAdd ? localActiveSetpoint + addScale : localActiveSetpoint - addScale;
    handleSliderChange(updatedSetPoint);
  };

  const delayedSetpointChange = useCallback(_.debounce((newValue: any, mode: any) => {
    if (subType === 1) {
      const API = mode === +operationModesMirror.HEAT ? updateWaterHeaterHeatingSetpoint : updateWaterHeaterCoolingSetpoint;
      API({ id: unitId, data: { setpoint: newValue } })
        .then(() => {
          updateUnitLocally({
            id: unitId, unit: mode === +operationModesMirror.HEAT ?
              { heaterHeatingSP: newValue } : { heaterCoolingSP: newValue }
          });
        })
        .catch((err: any) => {
          const newVal = isHalfCDegreeEnabled && isCelsius ? Math.floor(activeSetpoint * 2) / 2 : Math.round(activeSetpoint);
          setLocalActiveSetPoint(newVal);
          addMessage({ message: err.message });
        });
      return;
    }
    setActiveSetpoint({ id: unitId, setpoint: newValue })
      .catch((err: any) => {
        addMessage({ message: err.message });
        const newVal = isHalfCDegreeEnabled && isCelsius ? Math.floor(activeSetpoint * 2) / 2 : Math.round(activeSetpoint);
        setLocalActiveSetPoint(newVal);
      });
  }, 1000), []);

  const changeTankTemp = (isAdd: boolean) => {
    const endTemp = 100;
    const startTemp = 0;
    if (
      (isAdd && (tanklocalActiveSetpoint === endTemp || tanklocalActiveSetpoint > endTemp)) ||
      (!isAdd && (tanklocalActiveSetpoint === startTemp || tanklocalActiveSetpoint < startTemp))
    ) { return; }

    const updatedSetPoint = isAdd ? tanklocalActiveSetpoint + 1 : tanklocalActiveSetpoint - 1;
    setTankLocalActiveSetPoint(updatedSetPoint);
    delayedCallTank(updatedSetPoint);
  };

  const currentMode = enableOperationModeSelection ? operationModesExtended[mode] : "";
  const allowedToControlSetpoint = setpointControlModes[mode] && enableSetpointControl;
  const isVA = operationModesExtended[mode] === `Virtual Auto`;
  const hasError = message && message.replace(/\s/g, "").toUpperCase() !== "OK";
  const isBoilerBoosterOn = +activeFanMode === 1;


  return (
    <div className={classes.unitView} >
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? siteTemp.C || "" : siteTemp.F || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography >{name}</Typography>
        </div>

        {enableOperationStatusSelection &&
          <div className={classes.powerContainer}>
            <IconButton
              className={classes.powerIconStyle}
              disabled={!canControlOperationStatus || enableOnoff || (power === 2 && enableOnState)}
              onClick={togglePower}
            >
              {isPowerOn ?
                <SvgPowerOn className={classes.powerOffButtonClick} /> :
                <SvgPowerOff className={classes.powerOnButtonClick} />
              }
            </IconButton>
            <Typography className={classes.powerTextStyle}>
              {isPowerOn ? t`POWER OFF` : t`POWER ON`}
            </Typography>
          </div>}
      </div>
      <div className={classes.secondRowContainer}>
        {hasError && (
          <div className={classes.alertContainer}>
            <AlertControl className={classes.alertIcon} />
            <Typography className={classes.alertMessageStyle}>{message}</Typography>
            <Typography className={classes.alertMessageStyle}>{t`HVAC Error`}</Typography>
          </div>
        )}
        {(filter && !hasError && userPref.showClearFilterAlerts) && (
          <div className={classes.alertContainer}>
            <AlertControl className={classes.filterIcon} color="#f7b500" />
            <Typography className={classes.filterMessageStyle}>{t`Filter`}</Typography>
          </div>
        )}

        <div className={classes.controlDiv}>
          <ControlWrapper className={classes.controlWrapperStyle} fill={isPowerOn ? currentColor : "rgba(253,253,254, 0.5)"} width={336} height={326} />

          {
            isVA &&
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              paddingBottom: "18px",
              position: "absolute",
              zIndex: 1042
            }}>
              <CircularSliderWithChildren
                handle2={{ value: bottomLimit, onChange: (num: any) => canControlSetpoint && handleDualSetpoint2("Cool", num), color: "#57abff", showValue: true }}
                handle1={{ value: topLimit, onChange: (num: any) => canControlSetpoint && handleDualSetpoint2("Heat", num), color: "#f25146", showValue: true }}
                minValue={dsRanges[isCelsius ? 'c' : 'f'][0]}
                size={280}
                maxValue={dsRanges[isCelsius ? 'c' : 'f'][1]}
                coerceToInt={true}
                startAngle={10}
                endAngle={350}
                arcColor={"#fff"}
                arcBackgroundColor={"rgba(255,255,255,0.3)"}
                handleSize={16}
                disabled={enableSetpoint || hasError || !canControlSetpoint}
              />
            </div>
          }

          {isVA && <Typography className={classes.dsmodeStyle}>{
            React.cloneElement(mainIcons.operation[realActiveOperationMode], {
              transform: "scale(0.6)"
            })
          }</Typography>}

          {allowedToControlSetpoint || isVA ? (
            <>
              {!isVA && <div className={clsx(classes.sliderContainer)}>
                <CircularSlider
                  size={280}
                  minValue={startTemp}
                  maxValue={endTemp}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  coerceToInt={true}
                  angleType={{
                    direction: "cw",
                    axis: "-y"
                  }}
                  handle1={{
                    value: localActiveSetpoint,
                    onChange: (value: any) => { canControlSetpoint && setLocalActiveSetPoint(value); }
                  }}
                  arcColor={"#48314A"}
                  arcBackgroundColor={"#48314A"}
                  disabled={enableSetpoint || hasError || !canControlSetpoint || isVA}
                />
              </div>}
              <div className={clsx(classes.innerControls)}>
                {canControlSetpoint &&
                  <IconButton
                    disabled={enableSetpoint || hasError || !canControlSetpoint || isVA}
                    onClick={() => !(endTemp - 1 < localActiveSetpoint) && changeTemp(true)}
                    className={clsx(classes.controlArrow, { [classes.disabledIcon]: hasError, [classes.hidden]: endTemp - 1 < localActiveSetpoint || isVA })}
                  >
                    <ArrowUp />
                  </IconButton>}
                <div className={classes.tempHolder}>
                  <Typography className={clsx(classes.setPointStyle)}>{localActiveSetpoint || localActiveSetpoint === 0 ? localActiveSetpoint : activeSetpoint}</Typography>
                  <span>{isCelsius ? t`°C` : t`°F`}</span>
                </div>
                <div className={classes.ampTempContainer}>
                  <Temperature />
                  <Typography className={classes.ampTempStyle}>{Math.round(ambientTemperature || 0)}</Typography>
                  <span>{isCelsius ? t`°C` : t`°F`}</span>
                </div>
                {canControlSetpoint &&
                  <IconButton
                    onClick={() => !(startTemp + 1 > localActiveSetpoint) && changeTemp(false)}
                    className={clsx(classes.controlArrow, { [classes.disabledIcon]: hasError, [classes.hidden]: startTemp + 1 > localActiveSetpoint || isVA })}
                    disabled={enableSetpoint || hasError || !canControlSetpoint || isVA}
                  >
                    <ArrowDownControl />
                  </IconButton>}
              </div>
            </>
          ) : (<Typography className={classes.modeTitle}>{currentMode}</Typography>)}
        </div>
      </div>

      <div className={clsx(classes.runningTimer, (!isPowerOn || !runningTimer?.[0]?.isActive) && classes.hiddenStyle)} >
        <IoIosTimer style={{ fontSize: "30px", color: "#fff", marginRight: "7px" }} />
        {
          <Counter
            isRunning={isPowerOn}
            time={!!runningTimer && (isPowerOn ? runningTimer[0]?.remainingMinutes || 0 : runningTimer[0]?.turnOffInMinutes || 0)}
          />
        }
      </div>

      {(enableHeaterBoosterStatusSelection || enableHeaterTankSPControl) &&
        <div className={classes.thirdRowContainer}>
          <div className={classes.paddingContainer}>
            {enableHeaterTankSPControl &&
              <div style={{ display: "flex", flexFlow: "column nowrap", alignItems: "center", paddingRight: "20px" }}>
                <Typography className={classes.tankText}>{t`Tank`}</Typography>
                <div style={{ display: "flex" }}>
                  {canControlSetpoint && <IconButton disableRipple onClick={() => changeTankTemp(false)} className={classes.ovalIcon}><Remove className={classes.icon} /></IconButton>}
                  <Typography className={clsx(classes.tankText, classes.mainTempText)}>{tanklocalActiveSetpoint}<span className={classes.tempSymbolStyle}>{tempSymbol}</span></Typography>
                  {canControlSetpoint && <IconButton disableRipple onClick={() => changeTankTemp(true)} className={classes.ovalIcon}><Add className={classes.icon} /></IconButton>}
                </div>
                {enableHeaterTankTemp &&
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Temperature className={classes.tempIcon} />
                    <Typography className={clsx(classes.tankText, classes.tempText)}>{heaterTankTemp}{tempSymbol.substr(1, 1)}</Typography>
                  </div>}
              </div>}
            {(enableHeaterBoosterStatusSelection && isBoosterSupported) &&
              <div className={clsx(classes.columnFlex, { [classes.boosterOff]: !isBoosterOn })}>
                <IconButton disableRipple disabled={!canControlBooster} onClick={() => changeBooster(heaterBooster)} className={classes.ovalBoosterIcon}>
                  <Boiler className={classes.boilerIcon} />
                </IconButton>
                <Typography className={clsx(classes.tankText, classes.modeNowText)}>{t`Booster`}</Typography>
              </div>}
          </div>
        </div>}

      <div className={clsx(classes.lastRowContainer, { [classes.hidden]: hasError })}>
        <div className={clsx(classes.iconContainer, classes.rightSpace, !supportedOperationModes.length && classes.removeElements)}>
          <div className={clsx(classes.paper, clickedIcon === "operation" ? classes.showStyle : classes.removeElements)}>
            {enableOperationModeSelection && createMenu(mode, handleChoose, supportedOperationModes, "operation", operationModesExtended)}
          </div>
          {enableOperationModeSelection &&
            <IconButton disabled={!canControlOperationMode} className={classes.mainIconsStyle} onClick={(e: any) => handleIconClick(e, "operation")}>
              {mainIcons.operation[mode]
                ? React.cloneElement(mainIcons.operation[mode], { opacity: 0.6 })
                : operationModesExtended[mode] || mode}
            </IconButton>}
        </div>
        {/*fan*/}
        {activeFanMode !== 15 && !enableCurveTempSelection &&
          <div className={clsx(classes.iconContainer, classes.rightSpace, !supportedFanModes.length && classes.removeElements)}>
            <div className={clsx(classes.paper, clickedIcon === "fan" ? classes.showStyle : classes.removeElements)}>
              {enableFanModeSelection && createMenu(activeFanMode, handleChoose, supportedFanModes, "fan", fanModesExtended)}
            </div>
            {enableFanModeSelection &&
              <IconButton disabled={!canControlFanMode} className={classes.mainIconsStyle} onClick={(e: any) => handleIconClick(e, "fan", activeFanMode === 13)}>
                {mainIcons.fan[activeFanMode]
                  ? React.cloneElement(mainIcons.fan[activeFanMode], { opacity: 0.6 })
                  : fanModesExtended[activeFanMode] || (activeFanMode === 13 ? <FixedSpeed /> : activeFanMode)}
              </IconButton>}
          </div>
        }

        {/*Curve Temp*/}
        {enableCurveTempSelection && <div className={clsx(classes.iconContainer, !supportedCurveTempModes?.length ? classes.removeElements : classes.rightSpace)}>
          <div className={clsx(classes.paper, clickedIcon === "curveTempModes" ? classes.showStyle : classes.removeElements)}>
            {enableCurveTempSelection && createMenu(activeCurveTempMode, handleChoose, Object.keys(curveTempModes), "curveTempModes", Object.keys(curveTempModes))}
          </div>
          <IconButton disabled={!canControlFanMode} className={classes.mainIconsStyle} onClick={(e: any) => !canControlFanMode ? {} : handleIconClick(e, "curveTempModes")}>
            {React.cloneElement(<CurveTempIcon i={activeCurveTempMode} />, { opacity: 0.6 })}
          </IconButton>
        </div>}

        {/*swing*/}
        {enableSwingModeSelection && <div className={clsx(classes.iconContainer, classes.rightSpace, !supportedSwingModes.length && classes.removeElements)}>
          <div className={clsx(classes.paper, clickedIcon === "swing" ? classes.showStyle : classes.removeElements)}>
            {enableSwingModeSelection && createMenu(activeSwingMode, handleChoose, supportedSwingModes, "swing", swingModes)}
          </div>
          <IconButton disabled={!canControlSwingMode} className={classes.mainIconsStyle} onClick={(e: any) => handleIconClick(e, "swing")}>
            {mainIcons.swing[activeSwingMode]
              ? React.cloneElement(mainIcons.swing[activeSwingMode], { opacity: 0.6 })
              : swingModes[activeSwingMode] || activeSwingMode}
          </IconButton>
        </div>
        }
        {subType !== 1 &&
          <div className={clsx(classes.iconContainer, { [classes.rightSpace]: showControlAppAutoOffTimer || enablefanModeOnOffSelection })}>
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.mainIconsStyle}
              name="schedule"
              onClick={() => history.push(`/unit/${unitId}/schedules`)}
            >
              <ScheduleOffIcon />
              {!!schedules.length && <span className={classes.badge}>{schedules.length}</span>}
            </IconButton>
          </div>}
        {showControlAppAutoOffTimer &&
          <div className={clsx(classes.iconContainer, enablefanModeOnOffSelection && classes.rightSpace)}>
            <IconButton
              id="autoTimer"
              disableFocusRipple
              disableRipple
              className={classes.mainIconsStyle}
              name="schedule"
              onClick={openAutoTimerDialog}
              disabled={!canUpdateTimers}
            >
              <IoIosTimer style={{ fontSize: "45px" }} />
            </IconButton>
          </div>}
        {enablefanModeOnOffSelection &&
          <div className={classes.iconContainer} style={{ position: "relative" }}>
            <IconButton
              className={classes.mainIconsStyle}
              disabled={!canControlOperationStatus || enableOnoff || (power === 2 && enableOnState)}
              onClick={toggleBoilerBooster}
            >
              {isBoilerBoosterOn ?
                <SvgPowerOn className={classes.powerOffButtonClick} style={{ position: "absolute" }} /> :
                <SvgPowerOff className={classes.powerOnButtonClick} style={{ position: "absolute" }} />
              }
            </IconButton>
            <Typography className={classes.powerTextStyle} style={{ position: "absolute", whiteSpace: "nowrap", left: -6, fontSize: 12, marginTop: 5 }}>
              {isBoilerBoosterOn ? t`BOOSTER ON` : t`BOOSTER OFF`}
            </Typography>
          </div>}
        {!!openAutoTimer &&
          <Dialog
            open={!!openAutoTimer}
            onClose={closeAutoTimerDialog}
            aria-labelledby="responsive-dialog-title"
            classes={{ paper: classes.dialogStyle }}
          >
            <DialogTitle
              disableTypography
              id="responsive-dialog-title"
              className={classes.dialogTitle}
            >
              {t`Auto Timer Off`}
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentStyle }}>
              <div className={classes.switchContainer}>
                <Typography className={classes.durationLabel}>{t`Enabled/Disabled`}</Typography>
                <Switch
                  checked={timerIsActive}
                  disableRipple={true}
                  onChange={(e: any) => setTimerIsActive(e.target.checked)}
                  classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                  }}
                />
              </div>
              <div className={classes.timeInputsContainer}>
                <Typography className={classes.durationLabel}>{t`Duration`}</Typography>
                <div>
                  <Input
                    disabled={!timerIsActive}
                    value={timerHours}
                    onChange={(e) => onHoursChange(e.target.value)}
                    className={classes.timeInput}
                    type="number"
                    inputProps={{ min: 0, max: 99 }}
                  />
                  <Typography className={classes.label}>{t`Hours`}</Typography>
                </div>

                <div>
                  <Input
                    disabled={!timerIsActive}
                    value={timerMins}
                    onChange={(e) => onMinutesChange(e.target.value)}
                    className={classes.timeInput}
                    type="number"
                    inputProps={{ min: 0, max: 59 }}
                  />
                  <Typography className={classes.label}>{t`Minutes`}</Typography>
                </div>
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActionStyle}>
              <Button
                onClick={closeAutoTimerDialog}
                marginRight
                red
                width={120}
              >
                {t`Cancel`}
              </Button>

              <Button
                onClick={() => handleSaveTimer()}
                white
                width={120}
                disabled={!(+timerMins || +timerHours)}
              >
                {t`Submit`}
              </Button>
            </DialogActions>
          </Dialog>}
      </div>
    </div>
  );
};

export default UnitPanel;
