import { AlertGroup } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IAlertContact {
  contact: string;
  sendEmail: boolean;
  sendSms: boolean;
}
export interface IAlertGroup {
  id: string;
  name: string;
  enable: boolean;
  contacts: IAlertContact[];
  deviceDisconnectedEnable: boolean;
  cleanFilterEnable: boolean;
  indoorUnitErrorEnable: boolean;
  outdoorUnitErrorEnable: boolean;
  unitDisconnectedEnable: boolean;
  traps: string[];
  sites: string[];
  allUserTrapsEnable: boolean;
  deviceHealthLowIstat: boolean;
  deviceHealthLowOstat: boolean;
  deviceHealthLowPstat: boolean;
  deviceHealthLowLqstat: boolean;
  deviceHealthLowSstat: boolean;
}

export interface alertGroupMap {
  [key: string]: IAlertGroup;
}

export interface IAlertGroupsModel {
  getSiteAlertGroups: Thunk<IAlertGroupsModel, string>;
  getAlertGroupById: Thunk<IAlertGroupsModel, string>;
  allAlertGroups: alertGroupMap;
  initialize: Action<IAlertGroupsModel, any>;
  onInitialized: ActionOn<IAlertGroupsModel, IRootStoreModel>;
  createAlertGroup: Thunk<IAlertGroupsModel, any>;
  updateAlertGroup: Thunk<IAlertGroupsModel, { id: string; updatedData: any }>;
  updateContactAlertGroup: Thunk<IAlertGroupsModel, { id: string; contactId: string; updatedData: any }>;
  updateContactsAlertGroup: Thunk<IAlertGroupsModel, { id: string; contacts: any[]; }>;
  getAlertGroupContacts: Computed<IAlertGroupsModel, (id: string) => any[], IRootStoreModel>;
  getAlertGroupContactData: Computed<
    IAlertGroupsModel,
    (id: string, contactId: string, service: string) => boolean
  >;
  deleteAlertGroup: Thunk<IRootStoreModel, { id: string }>;
  addContactToAlertGroup: Thunk<
    IRootStoreModel,
    { id: string; contactId: string; sendEmail: boolean; sendSms: boolean }
  >;
  removeContactFromAlertGroup: Thunk<IRootStoreModel, { id: string; contactId: string }>;

}

export const alertGroupsModel: IAlertGroupsModel = {
  allAlertGroups: {},

  getSiteAlertGroups: thunk(async (actions, payload) => {
    const data = await AlertGroup.getSiteAlertGroups(payload);
    return data;
  }),
  getAlertGroupById: thunk(async (actions, payload) => {
    const data = await AlertGroup.getAlertGroupById(payload);
    return data;
  }),

  initialize: action((state, payload) => {
    state.allAlertGroups = payload;
  }),

  updateAlertGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkAlertGroup } = injections;
    const data = await sdkAlertGroup.update(payload.id, payload.updatedData);
    return data;
  }),
  updateContactAlertGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkAlertGroup } = injections;
    const data = await sdkAlertGroup.updateContact(payload.id, payload.contactId, payload.updatedData);
    return data;
  }),
  updateContactsAlertGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkAlertGroup } = injections;
    const data = await sdkAlertGroup.updateAlertGroupContacts(payload.id, payload.contacts);
    return data;
  }),

  createAlertGroup: thunk(async (actions, payload) => {
    const newAlertGroupData = await AlertGroup.createAlertGroup(payload);

    return newAlertGroupData;
  }),
  deleteAlertGroup: thunk(async (actions, payload) => {
    await AlertGroup.delete(payload.id);
  }),
  addContactToAlertGroup: thunk(async (actions, payload) => {
    const data = { sendEmail: payload.sendEmail, sendSms: payload.sendSms };
    const temp = await AlertGroup.addContact(payload.id, payload.contactId, data);
    return temp;
  }),

  removeContactFromAlertGroup: thunk(async (actions, payload) => {
    const data = await AlertGroup.removeContact(payload.id, payload.contactId);
    return data;
  }),

  getAlertGroupContacts: computed(
    [(state) => state.allAlertGroups, (state, storeState) => storeState.contacts.allContacts],
    (allAlertGroups, contacts) => (id) => {
      if (!allAlertGroups[id]) { return []; }
      return Object.values(contacts).filter((contact: any) =>
        _.find(allAlertGroups[id].contacts, { contact: contact.id })
      );
    }
  ),
  getAlertGroupContactData: computed(
    [(state) => state.allAlertGroups],
    (allAlertGroups) => (id, contactId, service) => {
      if (!allAlertGroups[id]) return false;
      if (
        service === "sms" &&
        !_.isNil(_.find(allAlertGroups[id].contacts, { contact: contactId, sendSms: true }))
      ) {
        return true;
      }
      if (
        service === "email" &&
        !_.isNil(_.find(allAlertGroups[id].contacts, { contact: contactId, sendEmail: true }))
      ) {
        return true;
      }
      return false;
    }
  ),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  )
};
