import React from "react";
import Button from "../../cool_widgets/Button";
import { Disconnect, MoreVert } from "../../icons";
import styles from "./AddRule.style";
import Condition from "./Condition";
import { GButton } from "./CustomedComponents";

export default (props: any) => {
  const {
    errors,
    touched,
    conditionsUnitsType,
    groupIndex,
    noSingleConditions,
    groupsLastIndex,
    unGroup,
    showWranning,
    isUnitsSelected,
    name,
    outdoorParams,
    indoorParams,
    onSelect,
    deleteCondition,
    conditions,
    relation,
    indexKey,
    setFieldValue,
    disabled,
    unitTypes,
    allParamsMap,
    serviceParamTypes,
    enumParams,
    isFirstConditionFake,
    moveProps = {}
  } = props;
  const classes = styles();
  const { inGroup } = props;

  return (
    <>
      <div className={classes.groupContainer}>
        <div {...moveProps} className={classes.groupContent}>
          <div className={classes.groupLeftPart}>
            <MoreVert className={classes.whiteMoreVert} /></div>
          <div className={classes.ingroupConditionsContainer}>
            {conditions.map((condition: any, index: any) =>
              <Condition
                enumParams={enumParams}
                serviceParamTypes={serviceParamTypes}
                errors={errors}
                touched={touched}
                showWranning={showWranning}
                isUnitsSelected={isUnitsSelected}
                disabled={disabled}
                inGroup
                key={`${name}.${index}`}
                index={index}
                condition={condition}
                indoorParams={indoorParams}
                outdoorParams={outdoorParams}
                deleteCondition={() => deleteCondition(index)}
                setFieldValue={setFieldValue}
                onSelect={onSelect}
                name={name}
                lastCondition={index !== conditions.length - 1}
                conditionsUnitsType={conditionsUnitsType}
                groupKey={indexKey}
                unitTypes={unitTypes}
                allParamsMap={allParamsMap}
              />)}
          </div>
          <div onClick={unGroup} className={classes.dissconnnectRect}>
            <Disconnect className={classes.disconnectIcon} />
          </div>
        </div>
      </div>
      {noSingleConditions && groupsLastIndex === groupIndex ? null : isFirstConditionFake && groupsLastIndex === groupIndex ? <Button disabled className={classes.addButton} >And</Button> :
        <GButton disabled={disabled} selected={relation} onClick={(text: any) => setFieldValue(`groups[${indexKey}].relation`, text)} />
      }
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`groups[${indexKey}].relation`}
        value="and"
        checked={relation === "and"}
        disabled={disabled}
      />
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`groups[${indexKey}].relation`}
        value="or"
        checked={relation === "or"}
        disabled={disabled}
      />
    </>
  );
};
