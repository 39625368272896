import { makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  permissionsBox: {
    marginTop: "20px",
    borderTop: `1px solid #D9D9D9`,
    padding: "10px 0",
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  popoverEntity: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowWrap: "anywhere",
    "&:nth-child(odd)": {
      background: "#f6f6f6",
    }
  },
  popoverLevel: {
    margin: "5px",
    width: "70%",
    padding: "0 20px"
  },
  popoverEntityName: {
    color: "#7DC1FF",
    fontSize: "14px",
    marginRight: "15px",
    flex: 1
  },
  popoverApps: {
    color: "#ababab",
    fontSize: "14px",
    flex: 2
  },
  popoverLevelTitle: {
    fontSize: "14px"
  },
  summaryHead: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  summaryTitle: {
    fontSize: "18px",
    alignItems: "center",
    fontWeight: "bold"
  },
  expandIcon: {
    transform: "rotate(-90deg)",
    transition: "all 0.5s",
    marginRight: "10px",
    fontSize: "18px",
    fontWeight: "bold"

  },
  expandedIcon: {
    transform: "rotate(0deg)"
  }

}));
