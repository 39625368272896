import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import styles from "./AddEditScript.style";
import ItemsTree from "./ItemsTree";

export default (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { customerId, siteId, close, brand, procedureId, onSave, selectedUnits } = props;

  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);

  const [brandSystems, setBrandSystems] = useState<any>({});
  const [controlUnits, setControlUnits] = useState<any>({});
  const [systemsToSitesMap, setSystemsToSitesMap] = useState<any>({});
  const [customers, setCustomers] = useState<any>({});

  useEffect(() => {
    getBrandSystems(brand);
  }, []);

  const getBrandSystems = (brand: any) => {
    let systemsMappedToSites: any = {};
    let customers: any = { [customerId]: { ...allCustomers[customerId] } };
    let systems: any = {};
    let currentUnits: any = {};

    for (const system in allSystems) {
      const { brandNum, site } = allSystems[system];

      if (!brand || (!_.isUndefined(brandNum) && brandNum === brand)) {
        if (systemsMappedToSites[site]) {
          systemsMappedToSites[site][system] = true;
        } else {
          systemsMappedToSites[site] = { [system]: true };
        }

        const fullSystem: any = { ...allSystems[system], unitsObject: {} };
        const { units } = fullSystem;

        if (units.length === 0) {
          systems[system] = fullSystem;
          continue;
        }

        for (const i in units) {
          const unitId = units[i];
          const unit = allUnits[unitId];

          if (!unit) {
            continue;
          }

          if (unit.type !== 3 || !unit.isVisible) {
            continue;
          }

          fullSystem.unitsObject[unit.id] = unit;
          currentUnits[unit.id] = unit;
        }
        systems[system] = fullSystem;
      }
    }

    setCustomers(customers);
    setControlUnits(currentUnits);
    setBrandSystems(systems);
    setSystemsToSitesMap(systemsMappedToSites);
  };

  const saveSelectedItems = (units: any, systems: any) => {
    onSave(procedureId, units, systems);
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.treeDialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Apply to`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>
      <ItemsTree
        allSystems={brandSystems}
        sitesWithSystems={systemsToSitesMap}
        allSites={{ [siteId]: allSites[siteId] }}
        customers={customers}
        allUnits={allUnits}
        onSave={saveSelectedItems}
        close={close}
        previouslySelectedUnitsIds={selectedUnits}
      />
    </Dialog>
  );
};
