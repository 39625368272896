import {
  Button as MUIButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography
} from "@material-ui/core/";
import { Check, MailOutline } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { MdBrokenImage } from "react-icons/md";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { t } from "ttag";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import alertSeverities from "../../constants/alertSeverities";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { downloadAs } from "../../services/download";
import useStyles from "./AlertInfo.style";
import UnitsInfoDialog from "./UnitsInfoDialog";
const AlertInfo: React.FC<any> = (props: any) => {
  const {
    alert,
    open,
    setOpenTroubleshoot,
    generateReport,
    addResolvedDescription,
    updateAlertLocally,
    allSites,
    refreshAlerts
  } = props;
  const classes: any = useStyles();
  const cleanFilterUnit = useStoreActions((action) => action.units.cleanFilterUnit);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState(null);
  const [initialResolveReson, setInitialResolve] = useState<any>("");
  const [resolvedAlertDescription, setResolvedAlertDescription] = useState<any>("");
  const [autoAck, setAutoAck] = useState<boolean>(false);
  const [expiryTime, setExpiryTime] = useState<any>(null);
  const [autoAckTimeout, setAutoAckTimeout] = useState<boolean>(false);
  const [ackDescription, setAckDescription] = useState<any>(alert?.acknowledgedDescription || "");
  const [openAck, setOpenAck] = useState<boolean>(false);
  const [openSiteUnitsInfo, setOpenSiteUnitsInfo] = useState<boolean>(false);
  const [siteUnitsInfo, setSiteUnitsInfo] = useState<any>(null);
  const [ackTimeError, setAckTimeError] = useState<string>("");
  const [openUnAck, setOpenUnAck] = useState<boolean>(false);
  const [prevFile, setPrevFile] = useState<any>(null);
  const sendAlertEmail = useStoreActions((actions) => actions.alerts.sendAlertEmail);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const { eventTypes, trapPriorityTypes, eventTypesToSeverityMapping } = useStoreState((state) => state.types);
  const updateAlert = useStoreActions((actions) => actions.alerts.updateAlert);
  const clearSystemFilter = useStoreActions((actions) => actions.systems.clearSystemFilter);
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const setAcknowledgedEvent = useStoreActions((actions) => actions.alerts.setAcknowledgedEvent);
  const createAutoAckRule = useStoreActions((actions) => actions.alerts.createAutoAckRule);
  const cancelAutoAckRule = useStoreActions((actions) => actions.alerts.cancelAutoAckRule);
  const { permissions } = alert || {};
  const { canAcknowledgeUnAcknowledge, canClose, canShareByEmail, canUpdate } = permissions || {};
  const closeEvent = useStoreActions((actions) => actions.alerts.closeEvent);
  const resolveDescExist = resolvedAlertDescription && resolvedAlertDescription !== initialResolveReson;
  const enableAutoAck = alert?.type === eventTypes.cleanFilter || alert?.type === eventTypes.indoorUnitError || alert?.type === eventTypes.outdoorUnitError || alert?.type === eventTypes.unitDisconnected;
  const severties: any = alertSeverities;
  const isTrapTemplate = !!alert?.trapTemplateId;
  const isLowLineQualityError = eventTypes.lowLineQualityError === alert.type;
  const { dateFormat, timeFormat, me } = useStoreState((state) => state.users);
  const { username } = me;
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { eventTypesMirror } = useStoreState((state) => state);
  const eventClearTypesMirror = useStoreState((state) => state.eventClearTypesMirror);
  const getDuplicateDaikinAirnetsUnits = useStoreActions((action) => action.sites.getDuplicateDaikinAirnetsUnits);
  const getMissingDaikinAirnetsUnits = useStoreActions((action) => action.sites.getMissingDaikinAirnetsUnits);
  const clearSiteFilter = useStoreActions((actions) => actions.sites.clearSiteFilter);

  const getSystemsNames = (ids: string[]) => {
    let names = "";
    const lastIndex = ids.length - 1;
    ids.forEach((id: string, index: number) => names += getSystem(id)?.name + (lastIndex === index ? "" : ", "));
    return names;
  };

  const handleOpen = (state: boolean) => {
    setIsOpen(state);
  };
  const handleAgree = (type: string) => {
    let api: any = null;
    const id = alert?.alertItemContainerIds?.unitId;
    switch (type) {
      case "system":
        const systemId: any = allUnits[id]?.system;
        api = clearSystemFilter(systemId);
        break;
      case "site":
        const siteId: any = allUnits[id]?.site;
        api = clearSiteFilter(siteId);
        break;
      case "unit":
        const controlUnitId: any = allUnits[id]?.controlUnit || id;
        if (!controlUnitId) {
          return;
        }
        api = cleanFilterUnit({ id: controlUnitId });
        break;
      default:
        break;
    }

    api.then(() => {
      updateAlertLocally(alert, { status: "Closed" });
    })
      .catch(({ message }: any) => {
        setError(message);
      })
      .finally(() => handleOpen(false));
  };

  const handleEmailDialog = () => {
    setEmails([]);
    setEmailDialogOpen(!isEmailDialogOpen);
  };
  const handleEmails = (emails: string[]) => {
    setEmails(emails);
  };
  const sendAlertByEmail = () => {
    sendAlertEmail({ emails, id: alert.id })
      .then(() => setEmailDialogOpen(false));
  };
  const validateEmail = (email: any) => {
    return isEmail(email);
  };
  const addLabeledEmail = (email: string, index: number, removeEmail: (index: number) => void) => {
    return (
      <div data-tag className={classes.emailPart} key={index}>
        {email}
        <span data-tag-handle onClick={() => removeEmail(index)}>
          ×
        </span>
      </div>
    );
  };
  const handleInputsChange = (event: any) => {
    const {
      target: { value }
    } = event;
    setResolvedAlertDescription(value);
  };
  const save = () => {
    updateAlert({ id: alert.id, data: { userText: resolvedAlertDescription } })
      .then(() => {
        addResolvedDescription(resolvedAlertDescription, alert.id);
        setInitialResolve(resolvedAlertDescription);
      })
      .catch((err: any) => {
        setError(err.message);
      });
  };
  useEffect(() => {
    setInitialResolve("");
    setResolvedAlertDescription("");
    if (!alert || alert.userText === undefined) { return; }
    setInitialResolve(alert.userText);
    setResolvedAlertDescription(alert.userText);
  }, [alert]);

  useEffect(() => {
    if (
      !alert ||
      (alert && alert.status !== "Closed") ||
      (alert && alert.userText === resolvedAlertDescription) ||
      !resolvedAlertDescription
    ) {
      return;
    }

  }, [resolvedAlertDescription]);
  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
  const isAckAlert = alert?.status === "Acknowledged";
  const isAutoAckAlert = alert?.status === "Auto Acknowledged";
  const isOpenAlert = alert?.status === "Open";
  const isControlActionType = alert?.type === eventTypes.controlAction;
  const isClosedAlert = alert?.status === "Closed";
  const isUnitDisAlert = alert?.description?.toUpperCase() === "UNITS DISCONNECTED";
  const isCleanFilter = alert?.type === eventTypes.cleanFilter;
  const disconnectedUnits = isUnitDisAlert && alert?.unitIds.map((unitId: any) => allUnits[unitId]?.name || "");
  const hasSys = alert?.alertItemContainerIds?.unitId && allUnits[alert?.alertItemContainerIds?.unitId]?.system;
  const severity = severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]];
  const systemObj = hasSys && getSystem(hasSys);
  const isAutomationLogicAlert = alert.type === eventTypes.commercialAction;
  const isScheduleFailures = alert.type === eventTypes.scheduleFailures;
  const isSiteMissingDaikinAirnets = alert.type === eventTypes.siteMissingDaikinAirnets;
  const isSiteDuplicateDaikinAirnets = alert.type === eventTypes.siteDuplicateDaikinAirnets;
  const isServiceAction = alert.type === eventTypes.serviceAction;
  const isInterlockAlert = isAutomationLogicAlert || isControlActionType || isServiceAction;

  const closeAckDialog = () => {
    setOpenAck(false);
    setAckDescription("");
    setAutoAck(false);
    setExpiryTime(null);
    setAckTimeError("");
    setAutoAckTimeout(false);
  };

  useEffect(() => {
    if (autoAckTimeout) {
      if (!expiryTime) {
        setAckTimeError(t`Time is required`);
        return;
      }
      if (expiryTime < 1 || expiryTime > 240) {
        setAckTimeError(t`Time should be between 1 and 240`);
        return;
      }
      setAckTimeError("");
    } else {
      !!expiryTime && setExpiryTime("");
      ackTimeError && setAckTimeError("");
    }

  }, [autoAckTimeout, expiryTime]);

  const handleCancelAutoAckRule = (id: string) => {
    cancelAutoAckRule(id)
      .then(() => {
        updateAlertLocally(alert, { status: "Acknowledged" });
        refreshAlerts();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setOpenUnAck(false));
  };
  const handleCloseEvent = (id: string) => {
    const timezone = allSites[alert?.alertItemContainerIds?.siteId]?.timezone || moment.tz.guess();
    closeEvent(id)
      .then((res: any) => {
        updateAlertLocally(alert, {
          status: "Closed",
          clearReason: eventClearTypesMirror[res?.clearReason],
          clearTime: moment(res?.clearTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          closeActor: username
        });
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const handleAckConfirm = () => {
    if (ackTimeError) {
      return;
    }
    const timezone = allSites[alert?.alertItemContainerIds?.siteId]?.timezone || moment.tz.guess();
    const acknowledgedTime = moment(new Date().getTime()).tz(timezone).format(`${dateFormat}   ${timeFormat}`);
    if (autoAck) {
      let data: any = {
        description: ackDescription,
        eventType: alert?.type,
        system: alert?.alertItemContainerIds?.systemId
      };
      if (alert?.errorCode) {
        data.errorCode = alert?.errorCode;
      }
      if (autoAckTimeout) {
        data.expiryTimeStamp = moment().add(expiryTime, "hours").valueOf();
      }

      createAutoAckRule(data)
        .then((res: any) => {
          updateAlertLocally(alert, { status: "Auto Acknowledged", acknowledgedDescription: ackDescription, acknowledgedTime, acknowledgedActor: username, autoAcknowledgeId: res?.id });
          refreshAlerts();
        })
        .catch((err: any) => addMessage({ message: err.message }));

    } else {
      setAcknowledgedEvent({ id: alert.id, data: { value: true, description: ackDescription } })
        .then(() => {
          updateAlertLocally(alert, { status: "Acknowledged", acknowledgedDescription: ackDescription, acknowledgedTime, acknowledgedActor: username });
        })
        .catch((err: any) => addMessage({ message: err.message }));
    }
    closeAckDialog();
  };

  const onExpiryTimeChange = (value: any) => {
    if (value > 240 || value < 0) {
      return;
    }
    setExpiryTime(value);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  const getSiteUnitsInfo = (siteId: string, type: number) => {
    if (isSiteMissingDaikinAirnets) {
      getMissingDaikinAirnetsUnits(siteId)
        .then((res: any) => {
          setSiteUnitsInfo(res);
          setOpenSiteUnitsInfo(true);
        })
        .catch();

    } else if (isSiteDuplicateDaikinAirnets) {
      getDuplicateDaikinAirnetsUnits(siteId)
        .then((res: any) => {
          setSiteUnitsInfo(res);
          setOpenSiteUnitsInfo(true);
        })
        .catch();
    }
  };

  const selectFileToPreview = (url: string) => {
    const ext: any = _.last(url?.split("."));
    let isPhoto = false;
    let isDoc = false;
    let isPdf = false;

    if (["jpg", "jpeg", "png", "gif", "webp"].indexOf(ext?.toLowerCase()) > -1) {
      isPhoto = true;
    }
    else if (["doc", "docx", "xls", "xlsx"].indexOf(ext?.toLowerCase()) > -1) {
      isDoc = true;
    }
    else if (ext.toLowerCase() === "pdf") {
      isPdf = true;
    }
    setPrevFile({ url, isPhoto, isDoc, isPdf });
  };
  return (
    <>
      {errorBox}
      {open && (
        <Grid container className={classes.infoContainer}>
          <div className={clsx(classes.header, severity?.id && classes[severity?.id])}>
            <div className={classes.headerInfo}>
              <Typography className={classes.alertTypeStyle}>
                {
                  isLowLineQualityError && t`Line Quality` ||
                  severity?.name?.toUpperCase() === "ANOMALIES" && t`Anomaly Rule` ||
                  isScheduleFailures && t`Schedule Failure` ||
                  (isAutomationLogicAlert && t`Automation Logic: ` + alert?.description) ||
                  alert?.alertType
                }
                {alert?.errorCode && ` (${alert?.errorCode})`}
              </Typography>
              <Typography className={classes.alertIdStyle}>
                {t`ID: `}{alert.shortId}
              </Typography>

            </div>
            <div className={classes.headerActions}>
              <Typography className={classes.statusBox}>{alert?.status}</Typography>
            </div>
          </div>

          <div className={classes.wrapper}>
            <div className={classes.content}>
              <div className={classes.desc}>
                <Typography className={classes.descTitle}>
                  {t`Description`}
                </Typography>
                <Typography className={classes.descInfo}>
                  {alert.errorDescription || alert.description}
                </Typography>
                {!alert?.trapFileURL && isTrapTemplate && <Typography className={classes.troubleshoot} onClick={() => { setOpenTroubleshoot(true); generateReport(alert?.trapTemplateId, alert.alertItemContainerIds.unitId); }}>{t`Troubleshoot guide`}</Typography>}
                {alert?.trapFileURL && <Typography className={classes.troubleshoot} onClick={() => selectFileToPreview(alert?.trapFileURL)}>{t`Troubleshoot guide`}</Typography>}
              </div>
              <div className={classes.infoRow}>
                <div className={classes.line}></div>
                <div className={classes.line}></div>
              </div>
              {alert?.errorCode &&
                <div className={classes.infoRow}>
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{t`Error Code`}</div>
                    <div className={classes.infoVal}>{alert.errorCode}</div>
                  </div>
                </div>}

              <div className={classes.infoRow}>
                {alert.unitName &&
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{t`Unit`}</div>
                    <div className={clsx(classes.infoVal, classes.cutLongNames)}>{alert.unitName}</div>
                  </div>}

                {isLowLineQualityError ?
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{alert.systemName === "multiple" ? t`Systems` : t`System`}</div>
                    <div className={classes.infoVal}>{alert.systemName === "multiple" ? getSystemsNames(alert.alertItemContainerIds.systemIds) : alert.systemName}</div>
                  </div>
                  : alert.systemName ?
                    <div className={classes.infoGroup}>
                      <div className={classes.infoTitle}>{t`System`}</div>
                      <div className={classes.infoVal}>{alert.systemName}</div>
                    </div> : null}
              </div>

              <div className={classes.infoRow}>
                {alert.siteName && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Site`}</div>
                  <div className={classes.infoVal}>{alert.siteName}</div>
                </div>}
                {alert.customerName && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Customer`}</div>
                  <div className={classes.infoVal}>{alert.customerName}</div>
                </div>}
              </div>

              <div className={classes.infoRow}>
                {alert.deviceName && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{!isLowLineQualityError ? t`Device` : t`Device / Line`}</div>
                  <div className={classes.infoVal}>{!isLowLineQualityError ? alert.deviceName : alert.deviceName + " / L" + alert.lineName}</div>
                </div>}
                {alert?.priority && <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Priority`}</div>
                  <div className={classes.infoVal}>{trapPriorityTypes[alert?.priority]}</div>
                </div>}
              </div>

              <div className={classes.infoRow}>
                <div className={classes.infoGroup}>
                  <div className={classes.infoTitle}>{t`Open Date`}</div>
                  <div className={classes.infoVal}>{alert.time}</div>
                </div>
                {alert.executeTime &&
                  <div className={classes.infoGroup}>
                    <div className={classes.infoTitle}>{isScheduleFailures ? t`Schedule Time` : t`Executed Time`}</div>
                    <div className={classes.infoVal}>{alert.executeTime}</div>
                  </div>
                }
              </div>
              {alert?.clearTime && <div className={classes.longRow}>
                <div className={classes.longRowTitle}>
                  <div className={classes.infoTitle}>{t`Clear Date`} </div>
                  <div className={classes.longRowVal}>{alert?.clearTime} </div>
                  <div className={classes.longRowVal}>{alert?.closeActor || " "}</div>
                </div>
                <div className={classes.infoVal}>{alert.clearReason}</div>
              </div>}

              {(isAckAlert || isAutoAckAlert) &&
                <div className={classes.longRow}>
                  <div className={classes.longRowTitle}>
                    <div className={classes.infoTitle}>{t`Acknowledged Date`} </div>
                    <div className={classes.longRowVal}>{alert?.acknowledgedTime} </div>
                    <div className={classes.longRowVal}>{alert?.acknowledgedActor || " "}</div>
                  </div>
                  <div className={classes.infoVal}>{alert?.acknowledgedDescription}</div>
                </div>
              }

              {isUnitDisAlert &&
                <div className={clsx(classes.infoRow, classes.longInfoRow)}>
                  <div className={clsx(classes.infoGroup, classes.longInfoRow)}>
                    <div className={classes.infoTitle}>{t`Disconnected Units`}</div>
                    <div className={classes.infoVal}>{disconnectedUnits.join(", ")}</div>
                  </div>
                </div>
              }
              {isClosedAlert &&
                <>
                  <div className={classes.infoTitle}>{t`Resolve Description`}</div>
                  <TextField
                    placeholder={t`Resolve Description`}
                    variant="outlined"
                    className={clsx(classes.resolveField, { [classes.resolveDescExist]: resolveDescExist })}
                    multiline
                    fullWidth
                    minRows={resolvedAlertDescription ? 5 : 1}
                    maxRows={10}
                    value={resolvedAlertDescription}
                    onChange={handleInputsChange}
                    disabled={!canUpdate}

                  />
                  {resolveDescExist &&
                    <Button
                      className={classes.saveResolveBtn}
                      onClick={save}
                    >
                      {t`Save`}
                    </Button>}
                </>}

            </div>
            {isCleanFilter &&
              <div className={classes.clearFiltersContainer}>
                <Button className={classes.cleanFilterBtn}
                  disabled={isClosedAlert || !canUpdate}
                  onClick={() => handleOpen(true)}
                >
                  {t`Reset Filter Alert`}
                </Button>
              </div>
            }
            <div className={clsx(classes.alertActions, { [classes.has3Btns]: isOpenAlert && (isScheduleFailures || isSiteMissingDaikinAirnets || isSiteDuplicateDaikinAirnets) })}>
              <Button disabled={!canShareByEmail} className={clsx(classes.longBtn, { [classes.shortBtn]: isOpenAlert && (isScheduleFailures || isSiteMissingDaikinAirnets || isSiteDuplicateDaikinAirnets) })} onClick={() => handleEmailDialog()} >
                <MailOutline style={{ marginRight: "8px" }} />
                {t`Share`}
              </Button>
              {
                ((isOpenAlert || isAckAlert || isAutoAckAlert) && !isControlActionType) &&
                <ToggleButton
                  disabled={!canAcknowledgeUnAcknowledge}
                  value="ack"
                  selected={isAckAlert || isAutoAckAlert}
                  classes={{ root: classes.ackButton1, label: classes.ackButtonLable }}
                  className={classes.ackButton}
                  onChange={() => isOpenAlert ? setOpenAck(true) : setOpenUnAck(true)}
                >
                  <Check className={classes.ackButtonIcon} />
                  <Typography className={classes.ackButtonText}>{isAckAlert ? t`Cancel-Ack` : t`Acknowledge`}</Typography>
                </ToggleButton>
              }
              {(isOpenAlert && (isInterlockAlert || isScheduleFailures)) &&
                <Button
                  disabled={!canClose}
                  white
                  className={classes.cancelBtn}
                  onClick={() => handleCloseEvent(alert.id)}
                >
                  {t`Close`}
                </Button>
              }
              {(isOpenAlert && (isSiteMissingDaikinAirnets || isSiteDuplicateDaikinAirnets)) &&
                <Button
                  white
                  className={classes.cancelBtn}
                  onClick={() => getSiteUnitsInfo(alert.siteId, alert.type)}
                >
                  {t`Get Info`}
                </Button>
              }
            </div>
          </div>
        </Grid>
      )}
      <Dialog
        open={isOpen}
        onClose={() => handleOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Reset Filter Alert`}</Typography>
          <IconButton disableRipple onClick={() => handleOpen(false)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          <Typography>{hasSys && systemObj ? t`What would you like to do?` : t`Are you sure ?`}</Typography>
        </DialogContent>
        <div className={classes.actionsHolder}>
          {hasSys && systemObj ?
            (
              <>
                <Button
                  onClick={() => handleAgree("system")}
                  autoFocus={true}
                  width={150}
                  marginRight
                >
                  {t`Reset System` + ` ${alert?.systemName}`}
                </Button>
                <Button
                  onClick={() => handleAgree("unit")}
                  autoFocus={true}
                  width={150}
                  marginRight
                >
                  {t`Reset Unit` + ` ${alert?.unitName}`}
                </Button>

                <Button
                  onClick={() => handleAgree("site")}
                  autoFocus={true}
                  width={150}
                >
                  {t`Reset Site` + ` ${alert?.siteName}`}
                </Button>

              </>
            )
            :
            (
              <>
                <Button
                  onClick={() => handleOpen(false)}
                  white
                  width={150}
                  marginRight
                >{t`Cancel`}</Button>
                <Button
                  onClick={() => handleAgree("unit")}
                  autoFocus={true}
                  width={150}
                >
                  {t`Ok`}
                </Button>
              </>
            )}
        </div>
      </Dialog>
      <Dialog
        open={isEmailDialogOpen}
        onClose={handleEmailDialog}
        aria-labelledby="email-alert-dialog-title"
        aria-describedby="email-alert-dialog-description"
        maxWidth="sm"
        classes={{ paper: classes.emailDialogStyle }}
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Share alert`}</Typography>
          <IconButton disableRipple onClick={handleEmailDialog} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.emailDialogContent}>
          <Typography>{t`Recipients`}</Typography>
          <ReactMultiEmail
            placeholder="Email Address/es"
            emails={emails}
            onChange={handleEmails}
            validateEmail={validateEmail}
            getLabel={addLabeledEmail}
          />
        </div>
        <div className={classes.actionsHolder}>
          <Button
            white
            width={150}
            marginRight
            onClick={handleEmailDialog}
          >{t`Cancel`}</Button>
          <Button
            type="submit"
            width={150}
            disabled={_.isEmpty(emails)}
            onClick={sendAlertByEmail}
          >
            {t`Share`}
          </Button>
        </div>
      </Dialog>

      {openAck && <Dialog
        open={true}
        onClose={() => closeAckDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Acknowledge Event?`}</Typography>
          <IconButton disableRipple onClick={() => closeAckDialog()} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.deleteDialogContent}>
          <Typography>{t`Acknowledge will move this alert to Acknowledge status meaning:`}</Typography>
          <ul className={classes.ul}>
            <li className={classes.li}>{t`Event will not apear on the Dashboard`}</li>
            <li className={classes.li}>{t`Event will not trigger notification messages until resolved`}</li>
          </ul>
          <TextField
            placeholder={t`Add notes for the acknowledge`}
            variant="outlined"
            className={classes.ackResolveField}
            multiline
            fullWidth
            minRows={2}
            inputProps={{ maxLength: 100 }}
            value={ackDescription}
            onChange={(event: any) => setAckDescription(event.target.value)}
          />
          {!!alert.systemName && alert.systemName !== "multiple" && alert.systemName !== "-" && enableAutoAck &&
            <>
              <FormControlLabel
                classes={{
                  label: classes.autoAckLabel
                }}
                control={<Checkbox
                  checked={autoAck}
                  color="default"
                  onChange={(event: any) => { setAutoAck(event.target.checked); autoAckTimeout && setAutoAckTimeout(false); }}
                />}
                label={<div>
                  {t`Acknowledge this alert `} <span className={classes.ackConditionDetails}>{alert?.errorCode ? alert?.errorCode : alert.alertType}</span>
                  {t` for all units in system `} <span className={classes.ackConditionDetails}>{alert.systemName}</span>
                </div>}
              />
              <FormControlLabel
                classes={{
                  label: classes.autoAckLabel
                }}
                control={<Checkbox
                  checked={autoAckTimeout}
                  color="default"
                  onChange={(event: any) => setAutoAckTimeout(event.target.checked)}
                  disabled={!autoAck}
                />}
                label={<div>
                  {t`Time to wait before sending new notification`}
                  <Input
                    type="number"
                    value={expiryTime}
                    disableUnderline
                    className={classes.timeInput}
                    name={"hours"}
                    placeholder={"hh"}
                    onChange={(e) => onExpiryTimeChange(e.target.value)}
                    inputProps={{ max: 240, min: 1 }}
                    disabled={!autoAckTimeout}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </div>}
              />
              <Typography className={classes.ackTimeError}>{ackTimeError}</Typography>
            </>
          }

        </div>
        <div className={classes.ackActionsHolder}>
          <Button
            white
            width={150}
            marginRight
            onClick={() => closeAckDialog()}
          >{t`Cancel`}</Button>
          <Button
            type="submit"
            width={150}
            onClick={() => handleAckConfirm()}
          >{t`Ok`}</Button>
        </div>
      </Dialog>}
      {openUnAck && <Dialog
        open={true}
        onClose={() => setOpenUnAck(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`UnAcknowledge Event`}</Typography>
          <IconButton disableRipple onClick={() => setOpenUnAck(false)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          {isAutoAckAlert ?
            <>
              <Typography>{t`An alert that was auto acknowledged cannot be manually un-acknowledged. You first have to remove the Auto-Acknowledge rule.`}</Typography>
              <Typography>{t` What would you like to do?`}</Typography>
            </>
            : <Typography>{t`Are you sure ?`}</Typography>
          }
        </DialogContent>
        <div className={classes.actionsHolder}>
          {isAutoAckAlert ?
            (
              <>
                <Button
                  white
                  onClick={() => setOpenUnAck(false)}
                  autoFocus={true}
                  width={150}
                  marginRight
                >
                  {t`cancel`}
                </Button>
                <Button
                  onClick={() => handleCancelAutoAckRule(alert?.autoAcknowledgeId)}
                  autoFocus={true}
                  width={150}
                >
                  {t`Remove the Auto-Acknowledge rule`}
                </Button>
              </>
            )
            :
            (
              <>
                <Button
                  onClick={() => setOpenUnAck(false)}
                  white
                  width={150}
                  marginRight
                >{t`Cancel`}</Button>
                <Button
                  onClick={() => {
                    setAcknowledgedEvent({ id: alert.id, data: { value: false } })
                      .then(() => {
                        updateAlertLocally(alert, { status: "Open" });
                      })
                      .catch((err: any) => addMessage({ message: err.message }));
                    setOpenUnAck(false);
                  }}
                  autoFocus={true}
                  width={150}
                >
                  {t`Yes`}
                </Button>
              </>
            )}
        </div>
      </Dialog>}
      {(openSiteUnitsInfo && !!siteUnitsInfo) &&
        <UnitsInfoDialog
          setOpenSiteUnitsInfo={setOpenSiteUnitsInfo}
          siteUnitsInfo={siteUnitsInfo}
          title={isSiteMissingDaikinAirnets ? t`Units With Missing Airnets` : t`Units With Duplicate Airnets`}
        />
      }

      {prevFile && (
        <Dialog
          open={prevFile}
          keepMounted
          onClose={() => setPrevFile(null)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{ paper: classes.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title">{`Anomaly attachment preview`}</DialogTitle>
          <DialogContent style={{ height: "100%" }}>
            {prevFile.isPhoto ?
              <img src={prevFile.url} className={classes.imagePreview} /> :
              ((prevFile?.isPdf || prevFile?.isDoc) ? <iframe
                src={`${prevFile.isPdf ? "https://docs.google.com/gview?url=" : "https://view.officeapps.live.com/op/embed.aspx?src="}${prevFile.url}${prevFile.isPdf ? "&embedded=true" : ""}`}
                width="100%"
                height="100%"
                frameBorder="0"
                className={classes.frameStyle}
              >
              </iframe> :
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%"
                }}>
                  <MdBrokenImage style={{ fontSize: "80px" }} />
                  <Typography style={{ fontSize: "30px" }}>
                    {t`Can't preview this file type, you can download it from the bottom`}
                  </Typography>
                </div>)}
          </DialogContent>
          <DialogActions>
            <MUIButton onMouseUp={() => downloadAs(prevFile.url, _.last(prevFile?.url?.split("/")) || "")} color="primary">
              {t`Download`}
            </MUIButton>
            <MUIButton onMouseUp={() => setPrevFile(null)} color="primary">
              {t`Close`}
            </MUIButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default AlertInfo;
