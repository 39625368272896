import {
    Button,
    Collapse,
    createStyles,
    Dialog,
    Divider,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { ChevronRight, Close } from "@material-ui/icons";
import clsx from "clsx";
import { Device as sdkDevice } from "coolremote-sdk";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        dialogHeader: {
            paddingLeft: 20,
            paddingRight: 13,
            ...theme.dialogs.header,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            minHeight: 60
        },
        actionsHolder: {
            padding: 20,
            display: "flex",
            justifyContent: "flex-end"
        },
        dialogContent: {
            flex: "1 1 auto",
            padding: 20,
            overflowY: "auto",
            display: "flex"
        },
        iconBtnStyle: {
            width: 30,
            height: 30,
            borderRadius: 6,
            padding: 0
        },
        headerTitle: {
            ...theme.dialogs.title
        },
        lineInfoContainer: {
            width: "0px",
            height: "100%",
            display: "flex",
            transition: "width 0.5s ease-in-out"
        },
        expandedInfo: {
            flex: 1.5,
            width: "900px"
        },
        unitsCont: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowY: "auto"
        },
        unitsTypeCont: {
            display: "flex",
            flexDirection: "column",
            marginBottom: "35px",
            "& p": {
                color: theme.palette.primary.main,
                fontWeight: 500,
                fontSize: "16px"
            }
        },
        selectedLineBtn: {
            backgroundColor: "#454545",
            color: "#fff",
            "& p": {
                justifyContent: "flex-end"
            },
            "&:hover": {
                backgroundColor: "#343434",
                color: "#fff"

            }
        }
    })
);

const EditSubType = (props: any) => {
    const { isOpen, close, deviceId, setError, device } = props;
    const { canUpdate: canEdit = true } = device || {};

    const [deviceLines, setDeviceLines] = useState<any>(null);
    const [deviceLinesOrg, setDeviceLinesOrg] = useState<any>(null);
    const [hvacMap, setMap] = useState<any>(null);
    const [selectedLine, setSelectedLine] = useState<any>(null);
    const [lineUnits, setLineUnits] = useState<any>({ all: {}, IDU: {}, ODU: {}, BS: {}, srv: {} });

    const types = useStoreState((state) => state.types);
    const allDevices = useStoreState((state) => state.devices.allDevices);
    const getDeviceLines = useStoreActions((actions) => actions.devices.getDeviceLines);
    const updateSubType = useStoreActions((actions) => actions.devices.updateSubType);
    const getDeviceUnits = useStoreActions((actions) => actions.devices.getDeviceUnitsAPI);
    const unitTypesMirror = useStoreState((state) => state.unitTypesMirrror);
    const getDevicesTypesNames = useStoreState((state) => state.getDevicesTypesNames);

    const { hvacBrands, hvacBrandsSubTypes } = types;

    useEffect(() => {
        if (!isOpen || !deviceId) {
            return;
        }

        getDeviceLines(deviceId)
            .then((resp: any) => {
                const linesMap = resp?.reduce((map: any, curr: any) => {
                    map[curr.id] = curr;
                    return map;
                }, {});

                setSelectedLine(+Object.keys(linesMap)[0] || null); //open first collapse by default
                setDeviceLinesOrg(linesMap);
                setDeviceLines(linesMap);
            });

        const map = hvacBrands?.reduce((mapVal: any, currentVal: any) => {
            mapVal[currentVal?.value] = currentVal?.subtypeName || currentVal.name;
            return mapVal;
        }, {});
        setMap(map);

    }, [isOpen, deviceId]);

    useEffect(() => {
        if (!selectedLine) {
            setLineUnits({ all: {}, IDU: {}, ODU: {}, BS: {}, srv: {} });
            return;
        }
        getDeviceUnits({ id: deviceId, line: selectedLine })
            .then((resp: any) => {
                const unitsData: any = Object.values(resp).reduce((result: any, item: any) => {

                    if (unitTypesMirror[item.type] === "indoor") {
                        result.IDU[item.id] = item;
                    }
                    if (unitTypesMirror[item.type] === "outdoor") {
                        result.ODU[item.id] = item;
                    }
                    if (unitTypesMirror[item.type] === "bsBox") {
                        result.BS[item.id] = item;
                    }
                    if (unitTypesMirror[item.type] === "service") {
                        result.srv[item.id] = item;
                    }

                    return result;
                }, { IDU: {}, ODU: {}, BS: {}, srv: {} });
                setLineUnits({ all: lineUnits, ...unitsData });
            });

    }, [selectedLine]);

    const updateSubTypeLocally = (e: any, line: number) => {
        setDeviceLines({ ...deviceLines, [line]: { ...deviceLines[line], subBrand: +e.target.value } });
    };

    const updateControlLineLocally = (e: any, line: number) => {
        setDeviceLines({ ...deviceLines, [line]: { ...deviceLines[line], controlLine: e.target.value } });
    };

    const updateLine = (lineId: number, lineNumber: number) => {
        const { brand, subBrand, controlLine } = deviceLines[lineNumber];
        const promises = [];
        if (subBrand !== deviceLinesOrg[lineId]?.subBrand) {
            promises.push(updateSubType({ deviceId, line: lineId, brand, subBrand }));
        }

        if (controlLine !== deviceLinesOrg[lineNumber]?.controlLIne) {
            promises.push(sdkDevice.assoLineWithControlLine(deviceId, lineId, controlLine));
        }

        if (!promises.length) {
            return;
        }

        Promise.all(promises)
            .then(([resp1]: any) => {
                setDeviceLinesOrg({ ...deviceLines });
                if (resp1?.bootRequired) {
                    setError(t`your settings have been saved, but will not take effect until you reboot your device`);
                }
                setDeviceLinesOrg({ ...deviceLines });
            })
            .catch((error: any) => {
                setError(error?.message);
            });
    };

    const openLineDetails = (lineId: number) => {
        if (lineId === selectedLine) {
            setSelectedLine(null);
            return;
        }
        setSelectedLine(lineId);
    };

    const classes = useStyles();
    const selectedDeviceObj = allDevices && deviceId && allDevices[deviceId];
    const { type: deviceBrand = 0, deviceType = 1, protocolVersion, serial } = selectedDeviceObj || {};
    const isOldProtocol: any = protocolVersion < 6;

    const deviceTypeName = getDevicesTypesNames(deviceType, deviceBrand);

    return (
        <Dialog
            open={!!selectedDeviceObj && isOpen}
            onClose={() => close(null)}
            aria-labelledby="mapping-dialog-title"
            aria-describedby="mapping-dialog-description"
            maxWidth="xl"
            PaperProps={{
                style: { height: "100%" }
            }}
        >
            <div className={classes.dialogHeader}>
                <Typography className={classes.headerTitle}>{t`Device settings:` + ` ${deviceTypeName} ${serial}`}</Typography>
                <IconButton onClick={() => close(null)} className={classes.iconBtnStyle}>
                    <Close />
                </IconButton>
            </div>
            <div className={classes.dialogContent}>

                {
                    <Table
                        style={{ flex: 1 }}
                    >
                        <TableHead>
                            <TableRow>

                                <TableCell
                                    align="center"
                                >
                                    {t`Line Number`}
                                </TableCell>
                                <TableCell
                                    align="center"
                                >
                                    {t`Line Type`}
                                </TableCell>
                                <TableCell
                                    align="center"
                                >

                                </TableCell>
                                <TableCell
                                    align="center"
                                >
                                    {t`Control Line`}
                                </TableCell>
                                <TableCell
                                    align="center"
                                />
                                <TableCell
                                    align="center"
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                deviceLines && deviceLinesOrg && Object.values(deviceLines)?.map((line: any, index: number) => {
                                    const { id, brand, subBrand, controlLine, _id } = line;
                                    const isUMM = brand === 16; //UMM brand ID
                                    const subTypes = hvacBrandsSubTypes[brand] || false;
                                    const isChanged = (subBrand !== deviceLinesOrg[line.id]?.subBrand) || (controlLine !== deviceLinesOrg[line.id]?.controlLine);
                                    const isSelected = id === selectedLine;
                                    const supportControlLine = +brand === 3 || +brand === 12 || +brand === 18;
                                    return (
                                        <TableRow key={`line-${id}`} >
                                            <TableCell align="center">
                                                <Typography>{id}</Typography>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Typography>{isUMM ? subTypes[subBrand]?.name : (hvacMap[brand] || "Unassigned")}</Typography>
                                            </TableCell>

                                            <TableCell align="center">
                                                {!isOldProtocol && !isUMM && subTypes ?
                                                    <Select value={subBrand} disabled={!canEdit} onChange={(e: any) => updateSubTypeLocally(e, id)}>
                                                        {Object.keys(subTypes).map((subType: any, index: any) => (<MenuItem key={`subtype-${index}-${subType}`} value={subType}>{subTypes[subType]?.name}</MenuItem>))}
                                                    </Select>
                                                    : <Typography></Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {supportControlLine &&
                                                    <Select value={controlLine || ""} disabled={!canEdit} displayEmpty onChange={(e: any) => updateControlLineLocally(e, id)}>
                                                        <MenuItem key={`lineOption-000`} value={""}>{t`Unassigned`}</MenuItem>
                                                        {Object.keys(deviceLines).map((lineId: any, index: any) => {
                                                            return (<MenuItem key={`lineOption-${index}`} disabled={+lineId === +id} value={deviceLines[lineId]?._id}>{`${lineId} ${hvacMap[deviceLines[lineId].brand] || ""}`}</MenuItem>);
                                                        })}
                                                    </Select>
                                                }
                                            </TableCell>

                                            <TableCell align="center">
                                                {isChanged ?
                                                    <Button disabled={!canEdit} onClick={() => updateLine(_id, id)}>
                                                        {t`Update`}
                                                    </Button>
                                                    : <div style={{ width: "70px" }} />
                                                }
                                            </TableCell>
                                            <TableCell align="center">

                                                <Button
                                                    onClick={() => openLineDetails(id)}
                                                    variant="outlined"
                                                    style={{
                                                        width: "135px"
                                                    }}
                                                    className={clsx(isSelected && classes.selectedLineBtn)}
                                                >
                                                    <Typography
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    >
                                                        {t`Line Info`}
                                                        {isSelected && <ChevronRight />}
                                                    </Typography>
                                                </Button>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>}

                <div className={clsx(classes.lineInfoContainer, selectedLine && classes.expandedInfo)}>
                    {
                        selectedLine &&
                        <Divider
                            orientation="vertical"
                            style={{
                                width: "2px",
                                height: "100%",
                                backgroundColor: "rgb(194, 194, 194)",
                                margin: "0 7%"
                            }}
                        />
                    }
                    <div className={classes.unitsCont}>

                        <div className={classes.unitsTypeCont}>
                            <Typography>{t`Outdoor Units`}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>

                                        <TableCell>
                                            {t`Unit Name`}
                                        </TableCell>
                                        <TableCell style={{ width: "210px" }}>
                                            {t`Address`}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.values(lineUnits.ODU).map((unit: any) => (
                                            <TableRow key={unit.id}>
                                                <TableCell style={{ wordBreak: "break-all" }}>
                                                    {unit.name}
                                                </TableCell>
                                                <TableCell>
                                                    {unit.address}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>

                        <div className={classes.unitsTypeCont}>
                            <Typography>{t`Branch box Units`}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>

                                        <TableCell>
                                            {t`Unit Name`}
                                        </TableCell>
                                        <TableCell>
                                            {t`Address`}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.values(lineUnits.BS).map((unit: any) => (
                                            <TableRow key={unit.id}>
                                                <TableCell>
                                                    {unit.name}
                                                </TableCell>
                                                <TableCell>
                                                    {unit.address}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>

                        <div className={classes.unitsTypeCont}>
                            <Typography>{t`Control Units`}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t`Unit Name`}
                                        </TableCell>
                                        <TableCell>
                                            {t`Address`}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.values(lineUnits.IDU).map((unit: any) => (
                                            <TableRow key={unit.id}>
                                                <TableCell>
                                                    {unit.name}
                                                </TableCell>
                                                <TableCell>
                                                    {unit.address}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </div>
                        <div className={classes.unitsTypeCont}>
                            <Typography>{t`Service Units`}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>

                                        <TableCell>
                                            {t`Unit Name`}
                                        </TableCell>
                                        <TableCell>
                                            {t`Address`}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.values(lineUnits.srv).map((unit: any) => (
                                            <TableRow key={unit.id}>
                                                <TableCell>
                                                    {unit.name}
                                                </TableCell>
                                                <TableCell>
                                                    {unit.address}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </div>
                    </div>

                </div>
            </div>
        </Dialog>
    );
};

export default EditSubType;
