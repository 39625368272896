import {
  Button as MUIButton,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { Html5QrcodeScanner } from "html5-qrcode";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { TopBar } from "../../components";
import Button from "../../cool_widgets/Button";
import airwellQR from "../../images/airwellQR.png";
import HVACImg from "../../images/coolmaster.png";
import QRImg from "../../images/QR.png";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ArrowBack } from "../../svgComponents";
import { registerNewDeviceStyle } from "./registerNewDevice.style";
import { getHostname } from "../../services/utils";

const createConfig = (props: any) => {
  let config: any = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const qrcodeRegionId = "html5qr-code-full-region";

const RegisterNewDevice: React.FC<any> = (props) => {
  const QRImage =
    getHostname().indexOf("airconnectpro") > -11 ? airwellQR : QRImg;

  const allCustomers = useStoreState((states) => states.customers.allCustomers);
  const { deviceRegistrationProgress = {} } = useStoreState(
    (states) => states.devices
  );
  const { setDeviceRegistrationProgress } = useStoreActions(
    (actions) => actions.devices
  );
  const [siteOptions, setSiteOptions] = useState<any>();
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [serial, setSerial] = useState<string>(
    deviceRegistrationProgress.serial || null
  );
  const [description, setDescription] = useState<string>(
    deviceRegistrationProgress.description || ""
  );
  const [pin, setPin] = useState<string>(
    deviceRegistrationProgress.pin || null
  );
  const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const startLoader = useStoreActions((actions) => actions.loader.startLoader);
  const finishLoader = useStoreActions(
    (actions) => actions.loader.finishLoader
  );
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getSites = useStoreActions((actions) => actions.customers.getSites);
  const addNewDevice = useStoreActions((action) => action.createDevice);
  const getAccountByDeviceSerial = useStoreActions(
    (actions) => actions.devices.getAccountByDeviceSerial
  );
  const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
  const { customerId: selectedCustomer } = useStoreState(
    (s) => s.selections.mobileSelections
  );
  const getCustomer = useStoreState((state) => state.customers.getCustomer);

  const {
    match: {
      params: {
        siteId,
        customerId = selectedCustomer || Object.keys(allCustomers || {})?.[0]
      }
    },
    history,
    history: { location: { search = "" } = {} } = {}
  } = props;

  const customer = getCustomer(customerId);
  const { canCreateSites } = customer?.permissions || {};
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const isSiteSelected = siteId && siteId !== "sites";

  const useStyles = makeStyles(registerNewDeviceStyle);
  const classes = useStyles();

  useEffect(() => {
    if (!search) {
      return;
    }
    const searchParams = new URLSearchParams(search);
    setSerial(searchParams.get("serial") || "");
    setPin(searchParams.get("pin") || "");
  }, [search]);

  useEffect(() => {
    if (!isCameraOpen) {
      return;
    }

    const config = createConfig(props);
    const verbose = props.verbose === true;
    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    html5QrcodeScanner.render(onScanningQR, props.qrCodeErrorCallback);

    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, [isCameraOpen]);

  useEffect(() => {
    if (!isLoggedIn || !customerId) {
      return;
    }
    history.push(
      `/device-registration/${siteId || "sites"
      }/user-selection/${customerId}${search}`
    );
  }, [isLoggedIn, customerId, history, siteId, search]);

  useEffect(() => {
    if (!customerId || !isLoggedIn) {
      return;
    }

    startLoader();
    getSites({ customerId })
      .then((sites: any) => {
        const sitesArr = Object.values(sites);
        const sitesList = !!sitesArr.length ? (
          sitesArr.map((site: any) => (
            <MenuItem value={site.id} key={site.id}>
              {site.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={`no-sites-option`} disabled value={""}>
            {t`No sites Options`}
          </MenuItem>
        );
        setSiteOptions(sitesList);
        setSelectedSite(isSiteSelected ? siteId : null);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
      });
  }, [
    addMessage,
    customerId,
    finishLoader,
    getSites,
    isLoggedIn,
    isSiteSelected,
    siteId,
    startLoader
  ]);
  const toggleCamera = () => {
    setIsCameraOpen(!isCameraOpen);
  };
  const onScanningQR = (qrCode: string | null) => {
    if (!qrCode) {
      return;
    }
    const isDiffFormat = qrCode.includes("serial");
    let serial = "";
    let pin = "";
    const qrCodeParts = isDiffFormat
      ? qrCode.split(/[=&]+/)
      : qrCode.split("/");

    if (isDiffFormat) {
      const lastIndex = qrCodeParts.length - 1;
      serial = qrCodeParts[lastIndex - 2];
      pin = qrCodeParts[lastIndex];
    } else {
      serial = qrCodeParts[4];
      pin = qrCodeParts[5];
    }

    setSerial(serial);
    setPin(pin);
    setSelectedSite(selectedSite || "");
    toggleCamera();
    if (!isLoggedIn) {
      handleSubmit({ serial, pin });
    }
  };
  const validationSchema = Yup.object({
    serial: Yup.string()
      .required(t`Required field`)
      .nullable(),
    pin: Yup.string()
      .required(t`Required field`)
      .nullable(),
    site: Yup.string()
      .required(t`Required field`)
      .nullable(),
    description: Yup.string()
  });

  const addDevice = (values: any) => {
    startLoader();
    const { serial, pin, site, description } = values;
    addNewDevice({
      site,
      serial: serial.replace(/\s+/g, " ").trim(),
      pin: pin.replace(/\s+/g, " ").trim(),
      description
    })
      .then((createdDevice: any) => {
        // const deviceId = createdDevice.id;
        history.push(`/dashboard`);
        // history.push(`/device/${deviceId}/system-detection`);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const handleSubmit = async (values: any) => {
    const { pin, serial } = values;

    const deviceInfo: any = await getAccountByDeviceSerial({
      serial: serial.replace(/\s+/g, " ").trim(),
      pin: pin.replace(/\s+/g, " ").trim()
    });

    if (!deviceInfo || !deviceInfo?.isFound) {
      setError(
        `The device ID you entered was not found. Please verify you entered it correctly`
      );
      return;
    }

    if (!deviceInfo?.isAuthenticated) {
      setError(
        t`The device ID and pin do not match. Please verify you entered it correctly`
      );
      return;
    }

    if (!deviceInfo?.isConnected) {
      setError(
        t`The device is not connected. It must be connected to be registered. Please connect it and try again`
      );
      return;
    }

    if (deviceInfo?.isRegistered) {
      setError(
        t`The device is already registered to another account, and can not be registered again`
      );
      return;
    }

    if (isLoggedIn) {
      addDevice(values);
      return;
    } else {
      history.push("/");
    }
  };

  const goBackToSites = () => {
    setDeviceRegistrationProgress({});
    history.push(`/site-management?selectedSite=${siteId}`);
  };

  return (
    <div className={classes.screenContainer}>
      {customerId && (
        <TopBar
          title={t`Connect Device`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBackToSites}
          hideRightComponent={true}
        />
      )}
      <div className={classes.container}>
        <div className={classes.pageContent}>
          <div className={clsx(classes.header)}>
            <Typography className={classes.pageTitle}>
              {t`Lets connect your HVAC Device`}
            </Typography>
            <div className={classes.deviceImageContainer}>
              <div />
              <img
                src={HVACImg}
                alt={"HVAC"}
                className={classes.deviceImgStyle}
              />
            </div>
          </div>

          <div className={classes.formsWrapper}>
            <div className={classes.manualFormWrapper}>
              <Formik
                initialValues={{ serial, pin, site: selectedSite, description }}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize={true}
                validationSchema={validationSchema}
                render={({
                  values,
                  errors,
                  setFieldValue,
                  dirty,
                  ...formikProps
                }) => {
                  return (
                    // @ts-ignore
                    <Form >
                      <div className={classes.addDeviceContainer}>
                        <Typography className={classes.textStyle}>
                          {t`Add Device`}
                        </Typography>
                        <TextField
                          name="serial"
                          value={values.serial}
                          variant={"outlined"}
                          placeholder={t`Device SN MAC`}
                          className={classes.wrapperStyle}
                          onChange={(event: any) => { setSerial(event.target.value); setFieldValue("serial", event.target.value); }}
                          error={!!errors.serial && values.serial !== null}
                          helperText={
                            errors.serial &&
                            values.serial !== null &&
                            errors.serial
                          }
                        />
                        <TextField
                          name="pin"
                          value={values.pin}
                          variant={"outlined"}
                          placeholder={t`Device PIN`}
                          className={classes.wrapperStyle}
                          onChange={(event: any) => { setPin(event.target.value); setFieldValue("pin", event.target.value); }}
                          error={!!errors.pin && values.pin !== null}
                          helperText={
                            errors.pin && values.pin !== null && errors.pin
                          }
                        />
                        {isCameraOpen && <div id={qrcodeRegionId} />}
                        {!isCameraOpen && (
                          <Typography
                            className={classes.qrTypoStyle}
                            style={{ marginBottom: 10 }}
                          >
                            {t`OR`}
                          </Typography>
                        )}
                        <div className={classes.QRWrapper}>
                          <div
                            className={classes.QRForm}
                            onClick={toggleCamera}
                          >
                            <div className={classes.qrDetailsContainer}>
                              <img
                                className={classes.QRImgStyle}
                                src={QRImage}
                                alt={"QR code"}
                              />
                              <Typography className={classes.qrTypoStyle}>
                                {t`Scan QR code`}
                              </Typography>
                            </div>
                            {/* <HelpOutlineOutlined className={classes.hintIcon} /> */}
                          </div>
                        </div>
                      </div>
                      <TextField
                        name="description"
                        variant={"outlined"}
                        placeholder={t`Location`}
                        className={classes.wrapperStyle}
                        onChange={(event: any) => { setDescription(event.target.value); setFieldValue("description", event.target.value); }}
                        error={!!errors.description}
                        helperText={errors.description}
                      />
                      {customerId && (
                        <FormControl style={{ width: "100%" }}>
                          {!values.site && (
                            <InputLabel
                              style={{ top: -8, left: 14 }}
                              id="select-site-label"
                            >{t`Select Site`}</InputLabel>
                          )}
                          <Select
                            labelId="select-site-label"
                            id="select-site"
                            value={values.site}
                            variant="outlined"
                            className={classes.input}
                            onChange={(event: any) =>
                              setFieldValue("site", event.target.value)
                            }
                            label={!values.site && t`Select Site`}
                            error={values.site === ""}
                            displayEmpty
                            MenuProps={{
                              classes: { paper: classes.menuItemsContainer },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {siteOptions}
                          </Select>
                          {values.site === "" && (
                            <FormHelperText
                              style={{ color: "#f44336", marginLeft: 14 }}
                            >
                              {errors.site || t`Required field`}
                            </FormHelperText>
                          )}
                          {canCreateSites && (
                            <MUIButton
                              onClick={() => {
                                setDeviceRegistrationProgress({
                                  serial,
                                  pin,
                                  description
                                });
                                history.push(
                                  `/site-management/customer/${customerId}/add-site`
                                );
                              }}
                              style={{ textDecorationLine: "underline" }}
                              color="primary"
                            >
                              OR Create a new site
                            </MUIButton>
                          )}
                        </FormControl>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            color={"default"}
                            checked={confirmCheck}
                            onChange={() => setConfirmCheck(!confirmCheck)}
                          />
                        }
                        style={{
                          marginBottom: 10,
                          marginTop: 10,
                          marginRight: 0
                        }}
                        classes={{
                          label: classes.lableStyle,
                          root: confirmCheck ? "" : classes.checkBoxRed
                        }}
                        label={t`I confirm I have the official approval to connect to the HVAC system using this device and start pulling all technical and control data using this solution.`}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.nextButton}
                        disabled={
                          !values?.pin?.length ||
                          !values?.serial?.length ||
                          !confirmCheck ||
                          !values?.site?.length
                        }
                      >
                        {t`Next`}
                      </Button>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {error && (
        <Dialog onClose={() => { }} open={error}>
          <div className={classes.errDialogHeader}>
            <Typography className={classes.errTitle}>
              <ErrorOutline />
              {t`Device registration error`}
            </Typography>
          </div>
          <DialogContent className={classes.errContent}>
            <Typography>{error}</Typography>
            <MUIButton
              className={classes.errBtn}
              onClick={() => setError(null)}
              variant="contained"
              color="primary"
            >
              {t`Close`}
            </MUIButton>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default RegisterNewDevice;
