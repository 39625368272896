import React from "react";

const RedAlert = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.79957 5.65557L4.43686 15.9113C3.29578 18.1019 4.47871 19.9999 6.95452 19.9999H17.0362C19.5376 20.0191 20.7075 18.1268 19.5539 15.9113L14.1648 5.655C13.0003 3.44824 10.9545 3.44824 9.79957 5.65557ZM13.5113 5.99904L18.8994 16.2536C19.7945 17.9724 18.9883 19.2763 17.039 19.2614H6.95452C5.02829 19.2614 4.20913 17.947 5.09187 16.2524L10.454 5.99781C11.3327 4.31835 12.6244 4.31835 13.5113 5.99904ZM12.2123 8.90063H13.1223V15.0964H11.2615V9.81462V9.79884V8.90063H12.1749C12.1874 8.90039 12.1999 8.90039 12.2123 8.90063ZM12.1912 15.8948H13.0741V16.7785V17.6613H12.2034C12.1993 17.6613 12.1953 17.6613 12.1912 17.6613C12.1871 17.6613 12.1831 17.6613 12.179 17.6613H11.3082V16.7785V15.8948H12.1912Z"
      fill="#F05146"
    />
  </svg>
);

export default RedAlert;
