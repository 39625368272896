import {
  Button as MButton,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { ArrowDownO, Close, Plus } from "../../icons";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./AddRule.style";

const CustomSelect = ({ disableTooltip = false, notDisabledOptions, className, error, disabled, options = [], value, placeholder, onChange, optionValue = "value", label = "name", name }: any) => {

  const classes = styles();
  const replacedValue = value === undefined ? "" : (value || "");
  const selectedValue = options.filter((option: any) => String(option[optionValue]) === String(value))[0];
  const valueName = selectedValue?.[label];
  const [open, setOpen] = useState<boolean>(false);

  return <Tooltip open={open} placement="bottom" title={disableTooltip ? "" : valueName || ""}>
    <FormControl variant="outlined" className={clsx(classes.formControl, { [className]: className })}>
      <InputLabel id="demo-simple-select-outlined-label" className={clsx(classes.labelSelect, { [classes.errorLabelSelect]: error })}>{placeholder}</InputLabel>
      <Select
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(false)}
        name={name}
        displayEmpty
        disabled={disabled}
        value={replacedValue}
        onChange={onChange}
        error={error}
        variant="outlined"
        disableUnderline
        classes={{ icon: classes.arrowDownIcon }}
        IconComponent={ArrowDownO}
        className={clsx(classes.select, { [classes.disabledSelect]: options.length === 0 || disabled })}
        label={placeholder}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {_.isEmpty(options) ? <MenuItem value={"none"} disabled>{t`No Options`}</MenuItem> : <MenuItem value={""} disabled
          style={{ display: "none" }}
        ></MenuItem>}
        {options.map((option: any) => {
          if (!!notDisabledOptions && notDisabledOptions.includes(option[optionValue])) {
            return null;
          }
          return (<MenuItem key={option[optionValue]} value={option[optionValue]}>
            {option[label]}
          </MenuItem>);
        }
        )}
      </Select >
    </FormControl>
  </Tooltip>;
};

const Box = ({ title, children, small, bLabel1, bLabel2, bDisabled1, bDisabled2, action1, action2, className, addBottomMargin }: any) => {

  const classes = styles();

  return <div id="box1" className={clsx(classes.boxContainer, { [classes.small]: small, [classes.addBottomMargin]: addBottomMargin, [className]: className })}>
    <div id="box-header1" className={classes.boxHeader}>
      <Typography className={classes.boxTitle}>{title}</Typography>
      <div>
        {action1 && <Button
          white
          width={small ? 130 : 144}
          className={clsx(classes.btn, classes.addMargin13, { [classes.disabledWhiteButton]: bDisabled1 })}
          startIcon={<Plus className={classes.plusIcon} />}
          disabled={bDisabled1}
          onClick={action1}
          onMouseDown={(event: any) => { event.preventDefault(); }}
        >
          {bLabel1}
        </Button>}
        <Button onMouseDown={(event: any) => { event.preventDefault(); }} white width={110} className={clsx(classes.btn, { [classes.disabledWhiteButton]: bDisabled2 })} disabled={bDisabled2} onClick={action2}
          startIcon={small ? <Plus className={classes.plusIcon} /> : undefined} >
          {bLabel2}
        </Button>
      </div>
    </div>
    <div className={clsx(classes.boxContent, { [classes.unitsAppliedBox]: small })}>{children}</div>
  </div >;
};

const CustomedTextField = ({ maxLength = "", error, label, disabled, placeholder, value, width, normalMargin, className, onChange, name, type = "string" }: any, props: any) => {

  const classes = styles();

  return <TextField
    label={label}
    disabled={disabled}
    classes={{ root: classes.label }}
    name={name}
    value={value}
    style={width ? { width } : undefined}
    onChange={onChange}
    placeholder={placeholder}
    variant="outlined"
    error={error}
    className={clsx(classes.textField, { [classes.addMargin]: !normalMargin, [className]: className })}
    type={type}
    inputProps={{ maxLength }}
  />;
};
const GButton = ({ inGroup, selected = "and", onClick, disabled }: any) => {

  const classes = styles();

  return <ButtonGroup style={{ borderRadius: 6, margin: inGroup ? "5px 0" : "10px 0", marginLeft: inGroup ? 13 : 30 }}
  >
    <MButton disabled={disabled} onMouseUp={() => onClick("and")} className={clsx(classes.andOrButton, { [classes.selectedAndOrButton]: selected === "and" })}>And</MButton>
    <MButton disabled={disabled} onMouseUp={() => onClick("or")} className={clsx(classes.andOrButton, { [classes.selectedAndOrButton]: selected === "or" })}>Or</MButton>
  </ButtonGroup>;
};

const UnitWrapper = ({ onRemove, siteName, systemName, unitName, disabled = false }: any) => {

  const classes = styles();

  return <div className={classes.wrapperContainer}>
    <Tooltip arrow interactive placement="top-start" title={siteName + " /" + systemName + " /" + unitName}>
      <Typography className={classes.namesContainer}><span className={classes.name}>{siteName || ""}</span> <span className={classes.middle}>/</span> <span className={classes.name}>{systemName || ""}</span><span className={classes.middle}>/</span> <span className={classes.name}>{unitName || ""}</span></Typography>
    </Tooltip>
    <IconButton disableRipple disabled={disabled} className={classes.removeButton} onClick={onRemove}><Close color="#fff" /></IconButton></div>;
};

const SystemWrapper = ({ onRemove, siteName, systemName, disabled = false }: any) => {

  const classes = styles();

  return <div className={classes.wrapperContainer}>
    <Tooltip arrow interactive placement="top-start" title={siteName + " /" + systemName} >
      <Typography className={classes.namesContainer}><span className={classes.name}>{siteName || ""}</span> <span className={classes.middle}>/</span> <span className={classes.name}>{systemName}</span></Typography>
    </Tooltip>
    <IconButton disableRipple disabled={disabled} className={classes.removeButton} onClick={onRemove}><Close color="#fff" /></IconButton></div>;
};

const TextInput = ({
  id,
  label,
  error,
  className,
  onChange,
  ...props
}: any) => {
  const classes = clsx(
    "input-group",
    {
      "animated shake error": !!error
    }
    // className
  );
  return (
    <div className={classes}>
      <label htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        className="text-input"
        onChange={onChange}
        {...props}
      />
      <p>]{error} </p>
    </div>
  );
};

const CustomAutoComplete = ({ useTooltip = false, tooltipProp = "", notDisabledOptions, className, error, disabled, options = [], value, placeholder, onChange, optionValue = "value", label = "name", name }: any) => {

  const classes = styles();
  const replacedValue = value === undefined ? "" : (value || "");
  const autoCompleteProps: any = {};
  if(useTooltip){
    autoCompleteProps.renderOption = (props: any, option: any) => {
      return(
        <li {...props} title={props[tooltipProp]} style={{width: "100%"}}>{props[label]}</li>
   )}
 }


  return (
    <Autocomplete
      className={clsx(classes.autoComplete, className)}
      classes={{
        // popper: classes.paper, clearIndicator: classes.clearIndicator,
        input: classes.inputRoot,
        endAdornment: classes.endAdornment,
        popupIndicator: classes.popupIndicator
      }}
      popupIcon={<div style={{
        height: "36px",
        width: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}><ArrowDownO /></div>}
      noOptionsText={t`No Options`}
      closeText=""
      // clearOnEscape={true}
      // clearOnBlur={false}
      disableClearable={true}
      disabled={disabled}
      value={value}
      options={options}
      getOptionLabel={(option: any) => option[label] || ""}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} classes={{ root: classes.label }} className={classes.textField} name={name} variant="outlined" disabled={disabled} label={placeholder} error={error} />
      )}
      {...autoCompleteProps}
    />
  );
};

export { CustomAutoComplete, CustomSelect, Box, CustomedTextField, GButton, SystemWrapper, UnitWrapper, TextInput };
