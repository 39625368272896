import React from "react";

const SvgEdit = props => (
  <svg
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    {...props}
  >
    <defs>
      <path id={`edit_svg__a-${props.id}`} d="M0 1.047h19.005v20.006H0z" />
      <path id={`edit_svg__c-${props.id}`} d="M.101.197h11.916v12.544H.101z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 -.197)">
        <mask id={`edit_svg__b-${props.id}`} fill="#fff">
          <use xlinkHref={`edit_svg__a-${props.id}`} />
        </mask>
        <path
          fill="#AAA2AA"
          d="M15.557 21.053H3.447c-1.9 0-3.447-1.629-3.447-3.63V4.677c0-2.001 1.547-3.63 3.448-3.63h9.585c.453 0 .819.387.819.862 0 .477-.366.864-.82.864H3.449c-.997 0-1.81.853-1.81 1.904v12.746c0 1.05.813 1.904 1.81 1.904h12.109c.998 0 1.809-.853 1.809-1.904V7.638c0-.476.367-.862.819-.862.453 0 .82.386.82.862v9.785c0 2.001-1.547 3.63-3.448 3.63"
          mask={`url(edit_svg__b-${props.id})`}
        />
      </g>
      <g transform="translate(8 -.197)">
        <mask id={`edit_svg__a-${props.id}`} fill="#fff">
          <use xlinkHref={`edit_svg__c-${props.id}`} />
        </mask>
        <path
          fill="#AAA2AA"
          d="M1.623 12.74l-1.461-.065-.061-1.537L10.179.528a1.042 1.042 0 0 1 1.523 0 1.175 1.175 0 0 1 0 1.603L1.623 12.741z"
          mask={`url(edit_svg__d-${props.id})`}
        />
      </g>
    </g>
  </svg>
);

export default SvgEdit;
