import { createStyles } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": {
        width: "18px",
        height: "18px",
        borderRadius: "3px",
        backgroundColor: "#fff"
      }
    }
  });
