import React from 'react';

function SvgAlert(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 18" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#F05347"
          d="M17.64 13.263l-6.2-11.198c-1.18-2.122-3.1-2.122-4.27 0L1 13.263c-1.17 2.132 0 3.875 2.52 3.875h11.6c2.56.019 3.7-1.724 2.52-3.875z"
        />
        <path
          fill="#F05347"
          fillRule="nonzero"
          d="M8.3 5.855a.95.95 0 01.7-.237h.6a.96.96 0 01.71.237c.18.176.271.418.25.663l-.18 4.168c-.053.525-.519.925-1.075.925-.556 0-1.022-.4-1.075-.925l-.18-4.168a.827.827 0 01.25-.663zm1 8.962a1.27 1.27 0 01-1.173-.743 1.157 1.157 0 01.275-1.311 1.32 1.32 0 011.384-.26c.475.185.784.624.784 1.11.003.32-.13.628-.369.854a1.297 1.297 0 01-.901.35z"
        />
      </g>
    </svg>
  );
}

export default SvgAlert;
