import React from "react";

const SvgSwingOff = (props) => (
  <svg
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    {...props}
  >
    <rect width="50" height="50" rx="25" fill="none" opacity={props.opacity} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11L10 19.1C14.4735 19.1 18.1 15.4735 18.1 11L10 11Z"
      fill={props.color1 || "#FDFDFE"}
    />
    <path
      d="M25.97 30.5V30.9688C25.97 31.6133 25.886 32.1914 25.718 32.7031C25.55 33.2148 25.3098 33.6504 24.9973 34.0098C24.6887 34.3691 24.3176 34.6445 23.884 34.8359C23.4504 35.0234 22.97 35.1172 22.4426 35.1172C21.9192 35.1172 21.4407 35.0234 21.0071 34.8359C20.5774 34.6445 20.2043 34.3691 19.8879 34.0098C19.5715 33.6504 19.3254 33.2148 19.1497 32.7031C18.9778 32.1914 18.8918 31.6133 18.8918 30.9688V30.5C18.8918 29.8555 18.9778 29.2793 19.1497 28.7715C19.3215 28.2598 19.5637 27.8242 19.8762 27.4648C20.1926 27.1016 20.5657 26.8262 20.9954 26.6387C21.429 26.4473 21.9075 26.3516 22.4309 26.3516C22.9583 26.3516 23.4387 26.4473 23.8723 26.6387C24.3059 26.8262 24.679 27.1016 24.9915 27.4648C25.304 27.8242 25.5442 28.2598 25.7122 28.7715C25.884 29.2793 25.97 29.8555 25.97 30.5ZM24.4993 30.9688V30.4883C24.4993 30.0117 24.4524 29.5918 24.3586 29.2285C24.2688 28.8613 24.134 28.5547 23.9543 28.3086C23.7786 28.0586 23.5618 27.8711 23.304 27.7461C23.0461 27.6172 22.7551 27.5527 22.4309 27.5527C22.1067 27.5527 21.8176 27.6172 21.5637 27.7461C21.3098 27.8711 21.093 28.0586 20.9133 28.3086C20.7375 28.5547 20.6028 28.8613 20.509 29.2285C20.4153 29.5918 20.3684 30.0117 20.3684 30.4883V30.9688C20.3684 31.4453 20.4153 31.8672 20.509 32.2344C20.6028 32.6016 20.7395 32.9121 20.9192 33.166C21.1028 33.416 21.3215 33.6055 21.5754 33.7344C21.8293 33.8594 22.1184 33.9219 22.4426 33.9219C22.7708 33.9219 23.0618 33.8594 23.3157 33.7344C23.5696 33.6055 23.7844 33.416 23.9602 33.166C24.136 32.9121 24.2688 32.6016 24.3586 32.2344C24.4524 31.8672 24.4993 31.4453 24.4993 30.9688ZM28.9114 26.4688V35H27.4407V26.4688H28.9114ZM32.3918 30.2012V31.3672H28.5364V30.2012H32.3918ZM32.8782 26.4688V27.6406H28.5364V26.4688H32.8782ZM35.5208 26.4688V35H34.05V26.4688H35.5208ZM39.0012 30.2012V31.3672H35.1458V30.2012H39.0012ZM39.4875 26.4688V27.6406H35.1458V26.4688H39.4875Z"
      fill={props.color1 || "#FDFDFE"}
    />
    <path
      d="M10.9 26.3V39.8"
      stroke={props.color2 || "#FFF"}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M25.3 11.9H38.8"
      stroke={props.color2 || "#FFF"}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgSwingOff;
