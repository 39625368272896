import { Unit as sdkUnit } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import PowerCircle from "./PowerCircle";
import PowerConsumptionGraph from "./PowerConsumptionGraph";
import SiteCountCard from "./SiteCountCard";

const OperationalOverview: React.FC<any> = (props) => {
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const isIdle = useStoreState((s) => s.isIdle);
  const { selections } = useStoreState((s) => s.selections);
  const getControlSitesUnits = useStoreState((state) => state.sites.getControlSitesUnits);
  const [outdoors, setOutdoors] = useState<any>({});
  const [power, setPower] = useState<any>({ realMaxPower: 0, max: 0, actual: 0, systemsNumber: 0, includedSystems: 0 });
  const [systemsPower, setSystemsPower] = useState<any>({});

  const { realMax, max, actual, systemsNumber, includedSystems } = power;
  const { customerId, siteId } = selections;

  useEffect(() => {
    sdkUnit.getUnits(2) // get outdoor units
      .then((units: any) => {
        const oUnits: any = {};
        Object.values(units).forEach((unit: any) => {
          if (unit.type === 2) {
            oUnits[unit.id] = unit;
          }
        });
        setOutdoors(oUnits);
      });
  }, []);

  useEffect(() => {
    let timer: any = null;
    if (isIdle) {
      clearInterval(timer);
      timer = null;
      return;
    }

    timer = setInterval(() => {
      sdkUnit.getUnits(2)
        .then((units: any) => {
          const oUnits: any = {};
          Object.values(units).forEach((unit: any) => {
            if (unit.type === 2) {
              oUnits[unit.id] = unit;
            }
          });
          setOutdoors(oUnits);
        });
    }, 60000); // 1000 * 60 --> 1 minute

    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, [isIdle]);

  useEffect(() => {
    let maxPower = 0;
    let actualPower = 0;
    let systems: any = {};
    let realMaxPower = 0;
    let systemsPowerData: any = {};
    _.map(outdoors, (unit: any) => {
      if (siteId ? unit.site === siteId : unit.customer === customerId) {
        const unitMaxPowerConsumption = unit.maxPowerConsumption === -1 ? 0 : unit.maxPowerConsumption;
        const unitLastPowerConsumption = unit.lastPowerConsumption === -1 ? 0 : unit.lastPowerConsumption;
        const unitRealPower = unit?.realMaxPowerConsumption === -1 || !unit?.realMaxPowerConsumption ? 0 : unit?.realMaxPowerConsumption;

        maxPower = maxPower + unitMaxPowerConsumption;
        actualPower = actualPower + unitLastPowerConsumption;
        realMaxPower = realMaxPower + unitRealPower;
        if (!unit.system) {
          return;
        }

        if (!unit.realMaxPowerConsumption || unit.realMaxPowerConsumption === 0) {
          systems[unit.system] = false;
        } else {
          systems[unit.system] = true;
        }

        if (!systemsPowerData[unit.system]) {
          systemsPowerData[unit.system] = { realMax: unitRealPower, max: unitMaxPowerConsumption, actual: unitLastPowerConsumption, systemName: allSystems[unit.system]?.name };

        } else {
          systemsPowerData[unit.system] = { realMax: systemsPowerData[unit.system].realMax + unitRealPower, max: systemsPowerData[unit.system].max + unitMaxPowerConsumption, actual: systemsPowerData[unit.system].actual + unitLastPowerConsumption, systemName: allSystems[unit.system]?.name };
        }
      }
    });
    setSystemsPower(systemsPowerData);

    const includedSystems = Object.values(systems).filter(Boolean).length;
    const systemsNumber = Object.values(systems).length;
    setPower({ realMax: realMaxPower, max: maxPower, actual: actualPower, systemsNumber, includedSystems });

  }, [outdoors, siteId, customerId]);

  const units = getControlSitesUnits();

  const indoorUnits = units.length;

  const heatModeCount = { indoorUnits, onIndoorUnits: 0, coolIndoorUnits: 0, heatIndoorUnits: 0 };
  let { onIndoorUnits, coolIndoorUnits, heatIndoorUnits } = heatModeCount;
  Object.values(units).forEach((unit: any) => {
    const { activeOperationMode, activeOperationStatus } = unit;

    if (activeOperationStatus === 1) { //on
      heatModeCount.onIndoorUnits = ++onIndoorUnits;

      if (activeOperationMode === 0) { //cool
        heatModeCount.coolIndoorUnits = ++coolIndoorUnits;
      }

      if (activeOperationMode === 1) { //heat
        heatModeCount.heatIndoorUnits = ++heatIndoorUnits;
      }
    }
  });

  const connectedModeCount = { indoorUnits, connectedIndoorUnits: 0, disconnectedIndoorUnits: 0 };
  let { connectedIndoorUnits, disconnectedIndoorUnits } = connectedModeCount;

  Object.values(units).forEach((unit: any) => {
    const { isConnected } = unit;

    if (isConnected) { //on
      connectedModeCount.connectedIndoorUnits = ++connectedIndoorUnits;
    } else {
      connectedModeCount.disconnectedIndoorUnits = ++disconnectedIndoorUnits;
    }
  });

  return (
    <>
      <div style={{ display: "flex" }}>
        <SiteCountCard
          totalIndoorUnits={indoorUnits}
          conditionIndoorUnits={onIndoorUnits}
          blueIndoorUnits={coolIndoorUnits}
          redIndoorUnits={heatIndoorUnits}
          title={t`Indoor Units ON`}
          customerId={customerId}
          siteId={siteId}
        />
        <PowerCircle
          maxPower={max}
          realMaxPower={realMax}
          actualPower={actual}
          systemsNumber={systemsNumber}
          includedSystems={includedSystems}
          title={t`Current power Estimated`}
          systemsPower={systemsPower}
        />
      </div>
      <PowerConsumptionGraph />
    </>
  );
};

export default OperationalOverview;
