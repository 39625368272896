import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },

  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 265px)",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
  },

  "@media (max-width: 970px)": {
    contentArea: {
      overflowX: "unset",
    },
  },

  siteViewContainer: {
    background: "white",
    marginBottom: "1rem !important",
    borderRadius: "4px",
    boxSizing: "border-box",
    display: "flex",
  },
  sitesContainer: {
    padding: "0 20px",
    display: "flex",
    overflow: "auto",
  },

  editSiteDialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "30%",
  },

  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "-webkit-fill-available",
  },
  whiteMoreVert: { height: 24, width: 3, "& path": { fill: "#fff" } },
  dragContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    backgroundColor: "#aaa2aa",
  },
  siteHolder: { width: "100%", padding: "18px 12px 8px" },
  siteviewHolder: { width: "100%" },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px",
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px",
  },
  searchIcon: {
    color: "#fff",
  },
  autoCompletePoper: {
    backgroundColor: theme.palette.primary.dark,
  },
  autoCompleteItem: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "#fff",
    "& li": {
      margin: "5px 10px",
    },
  },
  autoCompletePaper: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  autoCompleteGroup: {
    backgroundColor: theme.palette.primary.dark, //"rgba(0,0,0,0)",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "17px",
  },
  autoCompleteGroupUl: {
    backgroundColor: theme.palette.background.searchList,
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
  },

  ////////////approval dialog
  actionIcon: {
    "&hover": {
      backgroundColor: "white",
    },
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  dialog: {
    borderRadius: "0.5rem",

    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5",
  },
  closeIcon: {
    width: "24px",
    height: "24px",
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
  },

  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964",
  },
  noBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none",
  },
  yesBtn: {
    marginRight: "17px",
  },
  dialogActions: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
  },
  AddDeviceActions: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },

  hover: {},
  "MuiIconButton-root": {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  "MuiDialogTitle-root": {
    padding: "4px 24px",
  },
  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },

  ///////////////////////

  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  deleteInfoBlock: {
    marginTop: "15px",
  },
  deleteNameInput: {
    width: "100%",
    marginTop: "15px",
  },
  boldText: {
    fontWeight: "bold",
  },
  redItemName: {
    color: "red",
  },
  innerInfoCounter: {
    minWidth: "30px",
    width: "30px",
    display: "inline-block",
  },

  dialogPaper: {
    width: "fit-content",
    maxWidth: "65% !important",
    minWidth: "850px",
    height: "fit-content",
    maxHeight: "calc(100vh - 100px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden",
    position: "absolute",
    top: "70px",
  },
}));
export default useStyles;
