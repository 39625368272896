import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AcUnit from "@material-ui/icons/AcUnit";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import CoolTable, { ICoolTablePage, IHeadCell } from "../../components/CoolTable/CoolTable";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Tooltip from "../../components/Tooltip/LightTooltip";
import urlFlagMap from "../../constants/urlToFlagMapping";
import {
  AppLogic,
  AppUser as UserIcon,
  HomeAuto as HomeIcon,
  HVACIcon,
  Timer as ScheduleIcon
} from "../../icons/";
import { IAuditMap } from "../../models/Audits";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import { auditActionMap, showAuditAction } from "./AuditsActionMap";
import { AuditsFilter, IAuditsFilter } from "./AuditsFilter";
import useStyle from "./AuditsList.style";
import FilterRequire from "../../components/FilterRequire/FilterRequire";

export interface IAuditRow {
  icon: any;
  date: string;
  actorId: string;
  actionId: string;
  // actionOnSubject: { actionId: string; action: string; subject: string };
  data: any;
  // optional fields
  unitId?: string;
  deviceId?: string;
  siteId?: string;
  systemId?: string;
  systemName?: string;
  groupId?: string;
  groupName?: string;
  customerId?: string;
  unitName?: string;
  deviceName?: string;
  siteName?: string;
  customerName?: string;
  user?: string;
  source?: number;
  line?: number;
  sourceType?: string;
  serviceUnits?: any;
  controlUnit?: any;
  serviceSystem?: any;
  type?: any;
  onType?: any;
  timezone?: string;
  value?: string;
  schedule?: string;
  changeover?: string;
  trap?: string;
  sensorId?: string;
  sensorName?: string;
}
type IAuditRowField = keyof IAuditRow;

type Order = "asc" | "desc";

const AuditsList: React.FC = (props: any) => {
  const getCustomerUsers = useStoreActions((actions) => actions.customers.getCustomerUsers);
  const classes = useStyle(),
    getCustomerAudits = useStoreActions((s) => s.audits.getCustomerAudits),
    isInitialized = useStoreState((s) => s.isInitialized),
    getSite = useStoreState((s) => s.sites.getSite),
    getSystem = useStoreState((s) => s.systems.getSystem),
    getSystemName = useStoreState((s) => s.systems.getSystemName),
    getSensorName = useStoreState((s) => s.sensors.getSensorName),
    getGroupName = useStoreState((s) => s.groups.getGroupName),
    getUnit = useStoreState((s) => s.units.getUnit),
    getCustomerName = useStoreState((s) => s.customers.getCustomerName),
    getDeviceName = useStoreState((s) => s.devices.getDeviceName),
    getDevice = useStoreState((s) => s.devices.getDevice),
    getSiteName = useStoreState((s) => s.sites.getSiteName),
    { timeFormat, dateFormat } = useStoreState((state) => state.users),
    temperatureScaleDisplay = useStoreState((s) => s.users.getTemperatureScaleDisplayPlainText),
    types = useStoreState((s) => s.types),
    [audits, setAudits] = useState<IAuditRow[]>([]),
    users = useStoreState((s) => s.users.users),
    setStatUsers = useStoreActions((action) => action.users.setUsers),
    [actorsFilter, setActorsFilter] = useState<IAuditsFilter>({}),
    [actionsFilter, setActionsFilter] = useState<IAuditsFilter>({}),
    [sourcesFilter, setSourcesFilter] = useState<IAuditsFilter>({}),
    selections = useStoreState((s) => s.selections.selections),
    setSelections = useStoreActions((s) => s.selections.setSelections),
    [loading, setLoading] = useState(false),
    setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus),
    allUnits = useStoreState((state) => state.units.allUnits),
    allSystems = useStoreState((s) => s.systems.allSystems),
    allSites = useStoreState((s) => s.sites.allSites);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const { customerId } = selections;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { operationStatuses, operationModesExtended, fanModes, swingModes, curveTempModes, actionSources } = types;

  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const getCustomerChangeovers = useStoreActions((action) => action.operationAutomate.getCustomerChangeovers);
  const getCustomerTraps = useStoreActions((action) => action.traps.getCustomerTraps);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const updateUserPreferences = useStoreActions((actions) => actions.users.updateUserPreferences);

  const [allSchedules, setAllSchedules] = useState<any>({});
  const [allChangeovers, setAllChangeovers] = useState<any>({});
  const [allTraps, setAllTraps] = useState<any>({});

  const getUsername = (id: string) => {
    return users[id]?.username || "-";
  };
  setUnitUpdateStatus({ status: "" });

  const updateFiltersFromUserPreferences = (filters: any) => {
    const { actorsFilter, sourcesFilter, actionsFilter } = userPreferences?.auditsFilters || filters
    setActorsFilter(actorsFilter)
    setSourcesFilter(sourcesFilter)
    setActionsFilter(actionsFilter)
  }

  useEffect(() => {
    if (!selections.dateRange || !customerId) {
      return;
    }

    for (const x of [actorsFilter, sourcesFilter, actionsFilter, userPreferences]) {
      if (_.isEmpty(x))
        return;
    }

    const filters: any = { actorsFilter, sourcesFilter, actionsFilter }
    for (const filter in filters) {
      for (const key in filters[filter]) {
        const userPreferencesValue = !!userPreferences?.auditsFilters?.[filter]?.[key]
        const localFilterValue = !!filters?.[filter]?.[key]
        if (userPreferencesValue !== localFilterValue) {
          updateUserPreferences({ auditsFilters: filters });
          return;
        }
      }
    }
  }, [actorsFilter, sourcesFilter, actionsFilter]);



  useEffect(() => {
    if (!!selections?.dateRange?.endDate && !!selections?.dateRange?.startDate) {
      return;
    }
    setSelections({
      siteId: null,
      unitId: null,
      dateRange: {
        startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
        endDate: new Date()
      }
    });

    updateFiltersFromUserPreferences({ actorsFilter, sourcesFilter, actionsFilter })

  }, []);

  useEffect(() => {
    if (!selections.dateRange || !customerId) {
      return;
    }
    try {
      fetchAudits();
    } catch (error) {
      console.log("fetchAudits error:", error)
    }
  }, [selections.dateRange, customerId]);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    Promise.allSettled([getCustomerSchedules(customerId), getCustomerChangeovers(customerId), getCustomerTraps({ customerId, type: 201 }), getCustomerUsers(customerId)])
      .then((results) => {
        for (let index in results) {
          const result = results[index];

          const { status, reason, value }: any = result;
          if (status === "rejected") {
            console.error("error", reason.message);
            continue;
          }

          if (+index === 0)
            setAllSchedules(value);
          else if (+index === 1)
            setAllChangeovers(value)
          else if (+index === 2)
            setAllTraps(value)
          else if (+index === 3) {
            setStatUsers(value)
          }
        }
      });

  }, [customerId]);

  const fetchAudits = async () => {
    if (!selections.dateRange || !customerId) {
      return;
    }

    setLoading(true);

    // Get and parse audits from API into our current state
    const startTime = Date.UTC(selections.dateRange?.startDate.getFullYear(), selections.dateRange?.startDate.getMonth(), selections.dateRange?.startDate.getDate()) - 32400000;
    const endTime = Date.UTC(selections.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), 23, 59, 59) + 32400000;

    getCustomerAudits({ customerId, params: { startTime, endTime } }).then(async (response: IAuditMap) => {
      const auditMap: IAuditMap = response;
      let allUsers = users
      if (_.isEmpty(allUsers)) {
        allUsers = await getCustomerUsers(customerId)
      }

      const actorsFilter = userPreferences?.auditsFilters?.actorsFilter
      const auditsArr: any = parseAudits(auditMap, allUsers);
      auditsArr.length && setAudits(auditsArr);
      // Create filters from users and actions
      const actorsFilterMap = parseFilter(allUsers, "id");
      setActorsFilter({ "all": !actorsFilter || actorsFilter?.all, "noUser": !actorsFilter || actorsFilter?.all || actorsFilter?.noUser, ...actorsFilterMap });
      const actionsFilterMap = parseFilter(auditMap, "action");
      setActionsFilter(actionsFilterMap);
      const sourcesFilterMap = parseFilter(auditMap, "source");
      setSourcesFilter(sourcesFilterMap);
      setLoading(false);
    })

  },
    getFullName = ({ username, firstName, lastName }: any) => {
      if (firstName) {
        if (lastName) {
          return firstName + " " + lastName;
        } else {
          return firstName;
        }
      } else {
        return username || "-";
      }
    },
    // Parse IAuditMap received form API into our internal structure: IAuditRow
    parseAudits = (auditMap: IAuditMap, allUsers: any = users) => {
      const auditsArr = Object.values(auditMap);
      const filteredAudtis: any = [];

      for (let auditAPI of auditsArr) {

        if (!auditAPI) return [];

        const pageFlag = urlFlagMap.audits;
        if (auditAPI.site && sitesFlags[auditAPI.site] && !sitesFlags[auditAPI.site][pageFlag]) {
          continue;
        }

        const timezone = getSite(auditAPI.site)?.timezone || moment.tz.guess();

        if (!(auditAPI.unit && !allUnits[auditAPI.unit])) {
          if (auditAPI.system && (!auditAPI.site || auditAPI.customer)) {
            if (!auditAPI.site) {
              const device = getSystem(auditAPI.system)?.device;
              auditAPI.site = getDevice(device)?.site;

            }
            auditAPI.customer = auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer;
          }

          let data: any = {};

          if (auditAPI?.value || auditAPI?.value === 0) {
            data["value"] = auditAPI.value;
          }

          if (auditAPI?.data) {
            data = { ...data, ...auditAPI.data };
          }

          let unitName: string = "";
          const unit = getUnit(auditAPI.unit);
          if (unit) {
            if (!unit.isVisible) {
              continue;
            }
            unitName = unit.name;
            if (+unit?.type === 3) { // is service unit
              const controlUnitId: any = unit.controlUnit;
              const controlName = getUnit(controlUnitId)?.name || t`Unassigned`;
              unitName = `${controlName} (${unit.address})`;
            }
          }

          const valueMapping = (value: number, action: string) => {
            const lowerCaseAction = action.toLowerCase();
            if (lowerCaseAction.includes("setpoint")) { return value; }
            if (lowerCaseAction.includes("operationstatus")) { return operationStatuses[value] || value; }
            if (lowerCaseAction.includes("operationmode")) { return operationModesExtended[value] || value; }
            if (lowerCaseAction.includes("fanmode")) { return fanModes[value] || value; }
            if (lowerCaseAction.includes("swingmode")) { return swingModes[value] || value; }
            if (lowerCaseAction.includes("tempcurve")) { return `${curveTempModes[value] || value} (${value})`; }
            return value;
          };

          const row: IAuditRow = {
            icon: <AcUnit />,
            date: auditAPI.timestamp,
            actorId: auditAPI.actor,
            actionId: auditAPI.action,
            data,
            unitId: auditAPI.unit,
            unitName,
            siteId: auditAPI.site,
            systemId: auditAPI.system,
            sensorId: auditAPI.sensor,
            systemName: getSystemName(auditAPI.system),
            sensorName: getSensorName(auditAPI.sensor),
            groupId: auditAPI.group,
            groupName: getGroupName(auditAPI.group),
            siteName: getSiteName(auditAPI.site),
            deviceId: auditAPI.device,
            deviceName: getDeviceName(auditAPI.device),
            customerId: auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer,
            customerName: getCustomerName(
              auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer
            ),
            user: auditAPI?.actor ? getFullName(allUsers?.[auditAPI.actor] || {}) : "-",
            line: auditAPI.line,
            source: auditAPI.source,
            sourceType: auditAPI?.sourceType,
            onType: auditAPI.unit && allUnits[auditAPI.unit] ? allUnits[auditAPI.unit].type : null,
            timezone,
            value: valueMapping(auditAPI.value, auditAPI.action),
            schedule: auditAPI.schedule,
            changeover: auditAPI.changeover,
            trap: auditAPI.trap,
          };
          row.onType && row.onType !== 3 && (row.serviceUnits = allUnits[auditAPI.unit].serviceUnits);
          filteredAudtis.push(row);
        }

      }
      return filteredAudtis;
    },
    parseFilter = (valueMap: { [key: string]: any }, field: "id" | "action" | "source") => {
      const filterMap: IAuditsFilter = {};
      const { actorsFilter, actionsFilter, sourcesFilter } = userPreferences?.auditsFilters || {}
      const filters = { id: actorsFilter, action: actionsFilter, source: sourcesFilter }
      _.forEach(Object.keys(valueMap), (key) => {
        let fieldValue: string = "" + valueMap[key][field];
        const value = (!!(field === "id" && filters[field]?.all) || (filters[field]?.[fieldValue] === true || !filters[field]))
        if (_.isUndefined(fieldValue)) {
          filterMap["undefined"] = value;
        } else {
          if (fieldValue.includes("Trigger")) {
            fieldValue = fieldValue.replace(/Trigger/g, "Trap");
          }
          filterMap[fieldValue] = value;
        }
      });
      return filterMap;
    },
    // Apply filters and selections to audits
    getFilteredAudits = (audits: IAuditRow[]) => {
      function applyFilters(audits: IAuditRow[]) {
        if (actorsFilter.all) {
          return _(audits)
            .filter((audit) => actionsFilter[audit.actionId?.replace(/Trigger/g, "Trap")])
            .filter((audit) => sourcesFilter[audit.source || ""])
            .filter((audit: any) => {
              return searchTerm?.length
                ? (
                  getUsername(audit?.actorId)?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                  || showAuditAction(audit?.actionId)?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                  || audit?.unitName?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                  || audit?.siteName?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                )
                : true;
            })
            .value();
        }

        return _(audits)
          .filter((audit) => actorsFilter[audit.actorId] || (actorsFilter.noUser && (!audit.actorId || !users[audit.actorId])))
          .filter((audit) => sourcesFilter[audit.source || ""])
          .filter((audit) => actionsFilter[audit.actionId])
          .filter((audit: any) => {
            return searchTerm?.length
              ? (
                getUsername(audit?.actorId)?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                || showAuditAction(audit?.actionId)?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                || audit?.unitName?.toUpperCase()?.includes(searchTerm?.toUpperCase())
                || audit?.siteName?.toUpperCase()?.includes(searchTerm?.toUpperCase())
              )
              : true;
          })
          .value();
      }
      function applySelections(audits: IAuditRow[]) {
        return _(audits)
          .filter((audit: any) => {

            return selections.unitId ? (audit.unitId === selections.unitId || (audit.unitId ? audit.unitId === allUnits[selections.unitId].controlUnit : false)) : true;

          })
          .filter((audit: any) => {
            if (selections.unitId) return true;

            //if service check selectionsystem.includes its id else check its service units
            if (selections.systemId) {
              if (audit.systemId) return audit.systemId === selections.systemId;
              if (audit.onType === 3) {
                if (allSystems[selections.systemId]) {
                  return allSystems[selections.systemId].units.includes(audit.unitId);
                }
                else {
                  return allUnits[audit.unitId].line.toString() === selections.systemId.split("_")[0] && allUnits[audit.unitId].device === selections.systemId.split("_")[1] && selections.siteId && _.includes(allSites[selections.siteId].devices, allUnits[audit.unitId].device) ? true : false;
                }

              } else {

                return audit.serviceUnits && allSystems[selections.systemId] ? allSystems[selections.systemId].units.includes(audit.serviceUnits[0]) : false;
              }
            }

            return true;

          })
          .filter((audit: any) => {
            if (selections.systemId) return true;
            return selections.siteId ? audit.siteId === selections.siteId : true;
          })
          .filter((audit: any) =>

            selections.customerId ? audit.customerId === selections.customerId : true
          )
          .value();

      }
      return applyFilters(applySelections(audits));
    },
    // Get one page out of audits array
    getAuditsPage = ({ page, rowsPerPage, order, orderBy }: ICoolTablePage<IAuditRow>) => {
      function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) { return order; }
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }
      function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
        return order === "desc"
          ? (a, b) => descendingComparator<T>(a, b, orderBy)
          : (a, b) => -descendingComparator<T>(a, b, orderBy);
      }

      return stableSort<IAuditRow>(getFilteredAudits(audits), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    };

  // get source cell in the table by source id
  const SourceAuditCell = ({ source }: { source: string | number }) => {
    switch (+source) {
      case +actionSources.homeAutomation:
        return (<div className={classes.iconWrapper}>
          <HomeIcon className={classes.homeIcon} />
        </div>);
      case +actionSources.schedule:
        return (<div className={classes.iconWrapper}>
          <ScheduleIcon className={classes.scheduleIcon} />
        </div>);
      case +actionSources.user:
        return (<div className={classes.iconWrapper}>
          <UserIcon className={classes.userIcon} />
        </div>);
      case +actionSources.hvac:
        return (<div className={classes.iconWrapper}>
          <HVACIcon className={classes.hvacIcon} />
        </div>);
      case +actionSources.applicationLogic:
        return (<div className={classes.iconWrapper}>
          <AppLogic width="27" height="27" />
        </div>);
      case +actionSources.ecobee:
        return <div className={classes.iconWrapper}>
          eco
        </div>

      default:
        return <div className={classes.noIcon}>{source || "-"}</div>;
    }
  };

  // Define columns (table header)
  interface IAuditTableColumn extends IHeadCell<IAuditRow> {
    id: IAuditRowField;
    title: string | undefined;
    tableCellClassName?: string | undefined;
  }

  const tableColumns: IAuditTableColumn[] = [
    {
      id: "source",
      title: t`Source`,
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      width: "10ch"

    },
    {
      id: "unitId",
      title: t`Unit`,
      // width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      minWidth: "20ch",
      tableCellClassName: classes.tableCell__unit
    },
    {
      id: "siteId",
      title: t`Site`,
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      minWidth: "20ch",
      tableCellClassName: classes.tableCell__unit
    },
    {
      id: "customerId",
      title: t`Customer`,
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__customer
    },
    {
      id: "actionId",
      title: t`Action`,
      width: "22ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__actionOnSubject,
      minWidth: "20ch"
    },
    {
      id: "actorId",
      title: t`User`,
      width: "20ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__actor,
      minWidth: "15ch"
    },
    {
      id: "date",
      title: t`DATE/TIME`,
      width: "20ch",
      minWidth: "20ch",
      disablePadding: true,
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__date
    },
    {
      id: "value",
      title: "Value",
      disableSort: true,
      tableCellClassName: classes.tableCell__value
    }
  ];

  const getEntityName = (audit: any) => {
    if (audit.unitId) return audit.unitName;
    if (audit.systemId) return audit.systemName;
    if (audit.sensorId) return audit.sensorName;
    if (audit.deviceId) return audit.deviceName;
    if (audit.groupId) return audit.groupName;
    if (audit.customerId) return audit.customerName;
    return audit.user;
  };

  // Returns JSX formatted cell value for MUI Table
  const formatAuditRowField = (audit: IAuditRow, id: IAuditRowField) => {

    const value = audit[id];
    switch (id) {
      case "icon":
        return null;
      case "date":
        const { timezone } = audit;
        const date = timezone ? moment(value).tz(timezone).format(`ll ${timeFormat}`) : moment(value).format(`ll ${timeFormat}`);
        return date;
      case "actorId":
        return getUsername(value);
      case "actionId":
        return <span>{showAuditAction(audit.actionId)}</span>;
      case "unitId":
        return getEntityName(audit);
      case "customerId":
        return audit.customerName;
      case "siteId":
        return audit.siteName;
      case "source":
        return <Tooltip title={sourceMap(audit.source, audit.sourceType)}>
          <span>
            <SourceAuditCell source={audit.source || ""} />
          </span>
        </Tooltip>;
      default:
        return value;
    }
  };

  if (!isInitialized) { return <Loading />; }

  const dataPropertiesReplacement: { [key: string]: any } = {
    isRegistered: "Connected",
    isExpired: "Expired"
  },
    notReadable = [
      "customer",
      "isAcceptedTOU",
      "_id",
      "__v",
      "user",
      "userLocationsMetadata",
      "password",
      "randId",
      "id"
    ],
    getReadableData = (data: any, actionId: any) => {
      if (_.isEmpty(data)) {
        return "";
      }
      const lowerCaseAction = actionId?.toLowerCase(),
        { value } = data;

      if (lowerCaseAction.includes("setpoint")) {
        return `setpoint value: ${value} ${temperatureScaleDisplay()}`;
      }
      if (lowerCaseAction.includes("operationstatus")) {
        return `operation status: ${types?.operationStatuses[value]}`;
      }
      if (lowerCaseAction.includes("operationmode")) {
        return `operation mode: ${types?.operationModesExtended[value]}`;
      }
      if (lowerCaseAction.includes("fanmode")) {
        return `fan mode: ${types?.fanModes[value]}`;
      }
      if (lowerCaseAction.includes("swingmode")) {
        return `swing mode: ${types?.swingModes[value]}`;
      }
      if (lowerCaseAction.includes("tempcurve")) {
        return `temp curve: ${types?.curveTempModes[value]}`;
      }

      let dataString = "";
      Object.keys(data).forEach((key: any) => {
        if (typeof data[key] !== "string" || notReadable.includes(key)) {
          return;
        }

        const replacement = dataPropertiesReplacement[key];
        dataString += `${dataString ? "| " : ""}${replacement ? replacement : key}: ${data[key]}`;
      });
      return dataString;
    },
    sourceMap = (source: any, sourceType: any = "") => {
      const sourceMatching: any = {
        1: "Application User",
        2: "Scheduled Operation",
        3: sourceType ? sourceType : "Home Automation",
        4: "HVAC",
        5: sourceType ? sourceType : "Application logic",
        6: sourceType ? sourceType : "Ecobee"
      };
      return sourceMatching[source] || "-";
    },
    handleAuditShare = () => {
      const filteredAudtis = getFilteredAudits(audits),
        sortedAudtis = filteredAudtis.sort((a: any, b: any) => {
          const dateA: any = new Date(a.date),
            dateB: any = new Date(b.date);

          return dateB - dateA;
        }),
        headers = "SOURCE,UNIT,SITE,CUSTOMER,ACTION,USER,DATE/TIME,DATA";
      let rows = "";
      sortedAudtis.forEach((audit: any) => {
        const formatedData = getReadableData(audit.data, audit.actionId),
          timezone = getSite(audit.siteId)?.timezone || "",
          dateFormated = timezone ? moment(audit.date).tz(timezone).format(`${dateFormat} ${timeFormat}`) : moment(audit.date).format(`${dateFormat} ${timeFormat}`),
          source = sourceMap(audit.source, audit.sourceType),
          row: any = [
            source,
            audit.unitName,
            audit.siteName,
            audit.customerName,
            auditActionMap[audit.actionId]?.name || audit.actionId,
            getUsername(audit.actorId),
            dateFormated,
            formatedData
          ];
        rows += row.join(",") + "\r\n";
      });

      let link = window.document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(headers + "\r\n" + rows));
      link.setAttribute("download", "Audits.csv");
      link.click();
    };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Audit"]}
          showDateRangePicker
          searchComponent={searchComponent}
          screenTitle="audits"
          applySiteTypeFiltering
        />

        {!customerId ?
          <FilterRequire type={t`customer`} expand={true} /> :
          <>
            {!loading && <div className={classes.headerContainer}>
              <Button
                disableRipple
                variant="contained"
                className={classes.shareButton}
                startIcon={
                  <Icon style={{ transform: "rotateY(180deg)", marginTop: "-3px" }}>reply</Icon>
                }
                onMouseUp={handleAuditShare}
              >
                {t`Export Log to CSV`}
              </Button>
            </div>}
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
                <Typography variant="h5">{t`Loading Audit Reports...`}</Typography>
              </div>
            ) : (
              <Grid container justify="flex-start" alignItems="stretch" className={classes.wrapper}>
                <Grid item xs={2} className={classes.filterContainer}>
                  <Grid item className={classes.filterGridItem}>
                    <AuditsFilter
                      title={t`Users`}
                      className={classes.filter}
                      filters={actorsFilter}
                      setFilter={setActorsFilter}
                      getFilterName={(actorId) => getUsername(actorId)}
                      usersFilter
                    />
                  </Grid>
                  <Grid item className={classes.filterGridItem}>
                    <AuditsFilter
                      title={t`Actions`}
                      className={classes.filter}
                      filters={actionsFilter}
                      setFilter={setActionsFilter}
                      getFilterName={(action) => auditActionMap[action]?.name || action}
                    />
                  </Grid>
                  <Grid item className={classes.filterGridItem}>
                    <AuditsFilter
                      title={t`Sources`}
                      className={classes.filter}
                      filters={sourcesFilter}
                      setFilter={setSourcesFilter}
                      getFilterName={sourceMap}
                      getFilterJSX={(source) => <SourceAuditCell source={source} />}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10} className={classes.tableWrapper}>
                  <CoolTable
                    headCells={tableColumns}
                    rows={getFilteredAudits(audits)}
                    getPageRows={(page, rowsPerPage, order, orderBy) =>
                      getAuditsPage({ page, rowsPerPage, order, orderBy })
                    }
                    renderCell={formatAuditRowField}
                    defaultOrderBy={"date"}
                    options={{
                      classNameForTableContainer: classes.tableContainer,
                      rowsPerPage: 20,
                      rowsPerPageOptions: [20, 30, 50, 100]
                    }}
                    schedules={allSchedules}
                    changeovers={allChangeovers}
                    traps={allTraps}
                  />
                </Grid>
              </Grid>
            )}
          </>
        }
      </div>
    </div >
  );
};

export default AuditsList;
