import React from 'react';

function SvgDisconnect(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 24" {...props}>
      <path
        d="M7.482 17.143c-2.87 0-5.196-2.303-5.196-5.144 0-2.839 2.327-5.142 5.196-5.142h3.947m9.521 0c2.947 0 5.336 2.303 5.336 5.142 0 2.841-2.389 5.144-5.336 5.144h-3.807m1.143-13.714l-8 17.143"
        fill="none"
        fillRule="evenodd"
        stroke="#F05146"
        strokeLinecap="round"
        strokeWidth={1.4}
      />
    </svg>
  );
}

export default SvgDisconnect;
