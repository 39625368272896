import { Slider, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import Switch from "../../components/SmallSwitch/Switch";
import Button from "../../cool_widgets/Button";
import { MobileLogo } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  AutoDSMode,
  AutoMode,
  ColdBlue,
  ColdMode,
  HotMode,
  HotRed,
} from "../../svgComponents";
import FilterRequire from "../MobileFilterRequire/FilterRequire";
import ModesList from "./ModesList/ModesList";
import useStyles from "./unitManagement.style";
import { dsRanges } from "../../constants/unitsConsts";
import SliderDS from "../../components/Slider/index";

const unitSubTypesModes: any = {
  operationModesExtended: "subTypesOperationModes",
  fanModes: "subTypesFanModes",
  swingModes: "subTypesSwingModes"
};

const UnitManagement = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const [unit, setUnit] = useState<any>({});

  const types: any = useStoreState((states) => states.types);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const getUnit = useStoreActions((actions) => actions.units.getUnitByIdSDK);

  const { unitId } = useStoreState((state) => state.selections.mobileSelections);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const me = useStoreState((state) => state.users.me);
  const { temperatureScale } = me;
  const getDeviceById = useStoreState((state) => state.devices.getDeviceById);
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const isFahrenheit = +temperatureScaleMirror.fahrenheit === temperatureScale;

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [reloadUnitsInTopFilters, setReloadUnitsInTopFilters] = useState<boolean>(false);
  const [disableMessage, setDisableMessage] = useState<string>("");
  // const [autoTimerEnabled, setAutoTimerEnabled] = useState<boolean>(false);
  // setAutoTimerEnabled(unit.showControlAppAutoOffTimer || false);

  const {
    name,
    isVisible,
    supportedOperationModes,
    supportedFanModes,
    supportedSwingModes,
    enableHeatMode,
    enableCoolMode,
    enableAutoMode,
    temperatureLimits,
    isHalfCDegreeEnabled,
    capabilityFlags = {},
    subType = 0,
    showControlAppAutoOffTimer = false,
    permissions = {}
  } = unit;

  const { canUpdate } = permissions;

  const {
    enableAppSettingsOnOffLock = false, enableAppSettingsSetpointChangeLock = false,
    enableAppSettingsModeControl = false, enableAppSettingsFanControl = false,
    enableAppSettingsSwingControl = false, enableAppSettingsLimitCoolingSetpoint = false,
    enableAppSettingsLimitHeatingSetpoint = false, enableAppSettingsLimitAutoSetpoint = false,
    enableAppSettingsSetpointControlResolution = false, enableAppSettingsModeLock = false, enableAppSettingsTimer = false
  } = capabilityFlags;

  const slidersRef: any = useRef([]);

  const userTemperatureScale = useStoreState((s) => s.users.me.temperatureScale || 1);
  const isCelsius = +temperatureScaleMirror.celsius === +userTemperatureScale;
  const defaultCool = isCelsius ? 28 : 82;
  const defaultHeat = isCelsius ? 18 : 64;
  const [bottomLimit, setBottomLimit] = useState<number>(defaultHeat);
  const [topLimit, setTopLimit] = useState<number>(defaultCool);
  const { createDualSetpiontRule, updateDualSetpiontRule, getUnitDualSetpiontRule } = useStoreActions((actions) => actions.units);
  const [ruleId, setRuleId] = useState<string>("");


  useEffect(() => {
    if (slidersRef.current.length) {
      slidersRef.current.forEach((slider: any) => {
        slider?.addEventListener(
          "touchstart",
          (e: any) => {
            const isThumb = e.target?.dataset.index;
            if (!isThumb) {
              e.stopPropagation();
            }
          },
          { capture: true }
        );
      });
    }
  }, [slidersRef.current.length]);

  useEffect(() => {
    if (!unitId) {
      return;
    }

    if (unitId) {
      getUnit(unitId)
        .then((unitResp: any) => {
          if (unitResp?.type !== 1) {
            setUnit({});
            return;
          }
          setUnit(unitResp);
        })

      getUnitDualSetpiontRule(unitId).then((dualSetpoint: any) => {
        if (dualSetpoint) {
          setRuleId(dualSetpoint.id);
          const {
            heatSP,
            coolSP,
          } = dualSetpoint || {}
          setTopLimit(coolSP);
          setBottomLimit(heatSP)
        } else {
          setTopLimit(defaultCool);
          setBottomLimit(defaultHeat);
        }
      })
    }

    setUnit(unit);
    const device: any = getDeviceById(unit?.device || "");
    if (!device?.isConnected) {
      setDisableMessage(t`Device is disconnected`);
      return;
    }
    setDisableMessage("");
  }, [unitId]);

  const getUnitKeyOfMode = (typesMode: string) => {
    let unitModeName: any = "";
    switch (typesMode) {
      case "operationModesExtended":
        unitModeName = "supportedOperationModes";
        break;
      case "fanModes":
        unitModeName = "supportedFanModes";
        break;
      case "swingModes":
        unitModeName = "supportedSwingModes";
        break;

      default:
        unitModeName = null;
        break;
    }
    return unitModeName;
  };

  const updateLocalUnit = (modeName: string, modeNumber: any) => {
    const locUnit = { ...unit };
    const unitModeName = getUnitKeyOfMode(modeName);
    if (!unitModeName) {
      return;
    }

    const checkIndex = locUnit[unitModeName].indexOf(+modeNumber);
    if (checkIndex > -1) {
      locUnit[unitModeName].splice(checkIndex, 1);
    } else {
      locUnit[unitModeName] = [...locUnit[unitModeName], +modeNumber];
    }
    setIsSaveDisabled(false);
    setUnit(locUnit);
  };

  const saveUnit = () => {
    const {
      name,
      isVisible,
      supportedOperationModes,
      supportedFanModes,
      supportedSwingModes,
      enableAutoMode,
      enableCoolMode,
      enableHeatMode,
      temperatureLimits,
      isHalfCDegreeEnabled,
      showControlAppAutoOffTimer
    } = unit;

    if (!(bottomLimit <= topLimit - 2)) {
      setTopLimit(defaultHeat);
      setBottomLimit(defaultCool);
      addMessage({ message: t`Cool has to be higher than heat by at least 2C degrees` })
    } else {
      ruleId ?
        updateDualSetpiontRule({
          ruleId,
          heatSP: bottomLimit,
          coolSP: topLimit,
        }).catch((err: any) => addMessage({ message: err.message }))
        :
        createDualSetpiontRule({
          heatSP: bottomLimit,
          coolSP: topLimit,
          unit: unitId,
        }).catch((err: any) => addMessage({ message: err.message }));
    }

    updateUnit({
      id: unitId,
      updatedData: {
        name,
        isVisible,
        supportedOperationModes: enableAppSettingsModeControl ? supportedOperationModes : undefined,
        supportedFanModes: enableAppSettingsFanControl ? supportedFanModes : undefined,
        supportedSwingModes: enableAppSettingsSwingControl ? supportedSwingModes : undefined,
        enableCoolMode: enableAppSettingsLimitCoolingSetpoint ? enableCoolMode : undefined,
        enableHeatMode: enableAppSettingsLimitHeatingSetpoint ? enableHeatMode : undefined,
        enableAutoMode: enableAppSettingsLimitAutoSetpoint ? enableAutoMode : undefined,
        temperatureLimits: enableAppSettingsLimitCoolingSetpoint || enableAppSettingsLimitHeatingSetpoint || enableAppSettingsLimitAutoSetpoint ? temperatureLimits : undefined,
        isHalfCDegreeEnabled: enableAppSettingsSetpointControlResolution ? isHalfCDegreeEnabled : undefined,
        showControlAppAutoOffTimer: enableAppSettingsTimer ? showControlAppAutoOffTimer : undefined
      }
    }).then(() => {
      // if(name )
      setReloadUnitsInTopFilters(true);
      setIsSaveDisabled(true);
    }).catch((error: any) => {
      addMessage({ message: error.message });
    });
  };

  const updateUnitName = (e: any) => {
    const { value } = e.target;
    setUnit({ ...unit, name: value });
    setIsSaveDisabled(false);
  };

  const changeSupportedValues = (e: any, mode: string) => {
    const isChecked = e.target.checked;
    const unitModeName = getUnitKeyOfMode(mode);
    const updatedUnit = { ...unit };

    if (!unitModeName) {
      return;
    }

    if (!isChecked) {
      updatedUnit[unitModeName] = [];
    } else {
      const supportedSubTypesModesName = unitSubTypesModes[mode];
      const values = types[supportedSubTypesModesName]?.[subType] || [];
      updatedUnit[unitModeName] = values;
    }

    setUnit(updatedUnit);
    setIsSaveDisabled(false);
  };

  const changeUnitVisibility = (isVisible: boolean) => {
    setUnit({ ...unit, isVisible });
    setIsSaveDisabled(false);
  };

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + temperatureSymbol;
    return `${valueToShow}`;
  };

  const handleCoolRange = (event: any, newValue: number | number[]) => {
    const { temperatureLimits } = unit;
    setUnit({ ...unit, temperatureLimits: { ...temperatureLimits, 0: newValue } });
    setIsSaveDisabled(false);
  };

  const handleHeatRange = (event: any, newValue: number | number[]) => {
    const { temperatureLimits } = unit;
    setUnit({ ...unit, temperatureLimits: { ...temperatureLimits, 1: newValue } });
    setIsSaveDisabled(false);
  };

  const handleAutoRange = (event: any, newValue: number | number[]) => {
    const { temperatureLimits } = unit;
    setUnit({ ...unit, temperatureLimits: { ...temperatureLimits, 2: newValue } });
    setIsSaveDisabled(false);
  };

  const disabled = !canUpdate;
  return (
    <>
      <TopBar leftAction={() => history.push("/dashboard")}
        leftIconComponent={<MobileLogo />}
        reload={reloadUnitsInTopFilters}
        screenTitle="unitSettings"
        hideOutdoor
        hideBsBox
        hideIndoor
        hideOther
      />
      {!unitId || _.isEmpty(unit) ? <FilterRequire type={t`unit`} /> : (
        <div className={classes.root}>
          <div className={classes.pageContent}>
            {disableMessage && <Typography className={classes.errorLabelStyle}>{disableMessage}</Typography>}
            <div className={classes.nameSection}>
              <TextField
                disabled={disabled}
                value={name || ""}
                onChange={updateUnitName}
                className={classes.textStyle}
                label={t`Unit Name`}
                variant="outlined"
              />
            </div>

            {enableAppSettingsModeControl &&
              <div className={clsx(classes.section, classes.controlSection)}>
                <div className={classes.controlSectionHead}>
                  <Typography
                    className={classes.controlSectionTitle}
                  >{t`Mode Settings`}</Typography>
                  <Switch
                    checked={
                      !!(supportedOperationModes && supportedOperationModes.length)
                    }
                    switchChange={(e: any) => changeSupportedValues(e, "operationModesExtended")}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
                <div className={classes.controlSectionBody}>
                  <ModesList
                    subType={subType}
                    modeName={"operationModesExtended"}
                    activeModes={supportedOperationModes}
                    action={updateLocalUnit}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
              </div>}

            {enableAppSettingsFanControl &&
              <div className={clsx(classes.section, classes.controlSection)}>
                <div className={classes.controlSectionHead}>
                  <Typography
                    className={classes.controlSectionTitle}
                  >{t`Fan Speed Options`}</Typography>
                  <Switch
                    checked={!!(supportedFanModes && supportedFanModes.length)}
                    switchChange={(e: any) => changeSupportedValues(e, "fanModes")}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
                <div className={classes.controlSectionBody}>
                  <ModesList
                    subType={subType}
                    modeName={"fanModes"}
                    activeModes={supportedFanModes}
                    action={updateLocalUnit}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
              </div>}

            {enableAppSettingsSwingControl &&
              <div className={clsx(classes.section, classes.controlSection)}>
                <div className={classes.controlSectionHead}>
                  <Typography
                    className={classes.controlSectionTitle}
                  >{t`Swing Options`}</Typography>
                  <Switch
                    checked={!!(supportedSwingModes && supportedSwingModes.length)}
                    switchChange={(e: any) => changeSupportedValues(e, "swingModes")}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
                <div className={classes.controlSectionBody}>
                  <ModesList
                    subType={subType}
                    modeName={"swingModes"}
                    activeModes={supportedSwingModes}
                    action={updateLocalUnit}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
              </div>}

            <div className={clsx(classes.controlSectionHead, classes.normalSection)}>
              <Typography className={classes.controlSectionTitle}>{t`Display Unit in Control Tab`}</Typography>
              <Switch
                checked={isVisible}
                disableRipple={true}
                switchChange={(e: any) => changeUnitVisibility(!isVisible)}
                value="checkedA"
                disabled={!!disableMessage || disabled}
              />
            </div>

            {enableAppSettingsLimitCoolingSetpoint &&
              <div className={classes.controlSectionContainer}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.controlSectionTitle}>
                    <ColdBlue className={classes.coldIcon} />
                    {t`Min/Max Setpoint Cooling`}
                  </Typography>
                  <Switch
                    checked={enableCoolMode}
                    disableRipple={true}
                    switchChange={() => { setUnit({ ...unit, enableCoolMode: !enableCoolMode }); setIsSaveDisabled(false); }}
                    value="checkedA"
                    disabled={!!disableMessage || disabled}
                  />
                </div>
                <div className={classes.sliderHolder}>
                  <Slider
                    ref={(el) => slidersRef.current[0] = el}
                    classes={{
                      root: classes.sliderRoot,
                      rail: classes.sliderRail,
                      valueLabel: classes.sliderValueLabel
                    }}
                    className={classes.coolSlider}
                    onChange={handleCoolRange}
                    aria-labelledby="discrete-slider-always"
                    min={temperatureScale === 1 ? 0 : 32}
                    max={temperatureScale === 1 ? 50 : 122}
                    value={temperatureLimits[0]}
                    valueLabelDisplay="on"
                    valueLabelFormat={valueLabelFormat}
                    step={1}
                    disabled={!enableCoolMode || !!disableMessage || disabled}
                  />
                </div>
              </div>}

            {enableAppSettingsLimitHeatingSetpoint &&
              <div className={classes.controlSectionContainer}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.controlSectionTitle}>
                    <HotRed className={classes.coldIcon} style={{ transform: "scale(1.778)" }} />
                    {t`Min/Max Setpoint Heating`}
                  </Typography>
                  <Switch
                    checked={enableHeatMode}
                    disableRipple={true}
                    switchChange={() => { setUnit({ ...unit, enableHeatMode: !enableHeatMode }); setIsSaveDisabled(false); }}
                    value="checkedA"
                    disabled={!!disableMessage || disabled}
                  />
                </div>
                <div className={classes.sliderHolder}>
                  <Slider
                    ref={(el) => slidersRef.current[1] = el}
                    classes={{
                      root: classes.heatSliderRoot,
                      rail: classes.sliderRail,
                      valueLabel: classes.sliderValueLabel
                    }}
                    className={classes.heatSlider}
                    onChange={handleHeatRange}
                    aria-labelledby="discrete-slider-always"
                    min={temperatureScale === 1 ? 0 : 32}
                    max={temperatureScale === 1 ? 50 : 122}
                    value={temperatureLimits[1]}
                    valueLabelDisplay="on"
                    valueLabelFormat={valueLabelFormat}
                    step={1}
                    disabled={!enableHeatMode || !!disableMessage || disabled}
                  />
                </div>
              </div>}

            {enableAppSettingsLimitAutoSetpoint &&
              <div className={clsx(classes.controlSectionContainer, isFahrenheit && classes.removeBorder)}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.controlSectionTitle}>
                    <AutoMode maincolor="#aaa2aa" className={classes.autoIcon} />
                    {t`Auto Mode Temp Settings`}
                  </Typography>
                  <Switch
                    checked={enableAutoMode}
                    disableRipple={true}
                    switchChange={() => { setUnit({ ...unit, enableAutoMode: !enableAutoMode }); setIsSaveDisabled(false); }}
                    value="checkedA"
                    disabled={!!disableMessage || disabled}
                  />
                </div>
                <div className={classes.sliderHolder}>
                  <Slider
                    ref={(el) => slidersRef.current[2] = el}
                    classes={{
                      root: classes.autoSliderRoot,
                      rail: classes.sliderRail,
                      valueLabel: classes.sliderValueLabel
                    }}
                    className={classes.autoSlider}
                    onChange={handleAutoRange}
                    aria-labelledby="discrete-slider-always"
                    min={temperatureScale === 1 ? 0 : 32}
                    max={temperatureScale === 1 ? 50 : 122}
                    value={temperatureLimits[2]}
                    valueLabelDisplay="on"
                    valueLabelFormat={valueLabelFormat}
                    step={1}
                    disabled={!enableAutoMode || !!disableMessage || disabled}
                  />
                </div>
              </div>}
            {enableAppSettingsTimer &&
              <div className={clsx(classes.controlSectionContainer)}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.controlSectionTitle}>
                    <AutoMode maincolor="#aaa2aa" className={classes.autoIcon} />
                    {t`Auto Timer Off Button`}
                  </Typography>
                  <Switch
                    checked={showControlAppAutoOffTimer}
                    // switchChange={(e: any) => setAutoTimerEnabled(e.target.checked)}
                    switchChange={() => { setUnit({ ...unit, showControlAppAutoOffTimer: !showControlAppAutoOffTimer }); setIsSaveDisabled(false); }}

                    disableRipple={true}
                    value="checkedA"
                    disabled={!!disableMessage || disabled}
                  />
                </div>
              </div>}

            <div className={classes.controlSectionContainer}>

              <div className={classes.dualSetpointTitle}>
                <Typography className={classes.controlSectionTitle}>
                  <AutoDSMode maincolor="#aaa2aa" className={classes.autoIcon} />
                  {t`Dual Setpoint (Virtual Auto)`}
                </Typography>
              </div>
              <div className={classes.dualSetpointSection}>
                <div className={classes.sliderHolder}>
                  <SliderDS
                    getAriaLabel={(index: number) => index === 0 ? "heat" : "cool"}
                    min={dsRanges[isCelsius ? 'c' : 'f'][0]}
                    max={dsRanges[isCelsius ? 'c' : 'f'][1]}
                    marks={dsRanges[isCelsius ? 'c' : 'f'].map(x => ({ value: x, label: `${x} ${temperatureSymbol}` }))}
                    value={[bottomLimit, topLimit]}
                    onChange={(_event: any, values: any) => {
                      if (!!disableMessage || disabled) {
                        return;
                      }
                      const [bottom, top] = values[0] > values[1] ? [values[1], values[0]] : values;
                      if (!(bottom <= top - 2)) {
                        return;
                      }
                      if (bottom !== bottomLimit) {
                        setBottomLimit(bottom);
                      }
                      if (top !== topLimit) {
                        setTopLimit(top);
                      }
                    }}
                    disabled={!!disableMessage || disabled}
                  />
                </div>
              </div>
            </div>

            {(!isFahrenheit && enableAppSettingsSetpointControlResolution) && <div className={clsx(classes.controlSectionContainer, classes.removeBorder)}>
              <div className={classes.titleContainer}>
                <Typography className={classes.controlSectionTitle}>
                  {t`Setpoint Control Resolution`}
                </Typography>
                <div className={classes.setpointResolutionContainer}>
                  <Typography>0.5 C</Typography>
                  <Switch
                    checked={!isHalfCDegreeEnabled}
                    disableRipple={true}
                    switchChange={() => { setUnit({ ...unit, isHalfCDegreeEnabled: !isHalfCDegreeEnabled }); setIsSaveDisabled(false); }}
                    value="checkedA"
                    disabled={!!disableMessage || disabled}
                    classes={{
                      root: classes.switchRoot,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked
                    }}
                  />
                  <Typography>1 C</Typography>
                </div>

              </div>
            </div>}
          </div>
          <Button
            disabled={isSaveDisabled || !!disableMessage || disabled}
            className={classes.saveButton}
            onClick={() => saveUnit()}
          >{t`Save`}</Button>
        </div>)}
    </>
  );
};

export default UnitManagement;
