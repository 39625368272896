import { Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AlertsList.style";

const AlertsList = ({ alerts }: any) => {
  const alert = alerts.alert[0];
  const classes = useStyles();
  const history = useHistory();
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const setSelectedAlertObj = useStoreActions((actions) => actions.setSelectedAlert);

  const getMoreInfo = () => {
    setSelectedAlertObj(alert);
    history.push("/alerts");
  };

  return (
    <div className={classes.ulContainer}>
      <ul className={classes.container}>
        <li key={alert.id} className={classes.alert}>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}> {t`Error Code`}</Typography>
            <Typography className={classes.info}>
              {alert.errorCode && typeof alert.errorCode === "string" ? `${alert.errorCode}` : (
                alert.severity?.id === "ANOMALIES"
                  ? t`User Defined Rule`
                  : t`No Info`)}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}>{t`Description`}</Typography>
            <Typography className={clsx(classes.info, classes.descriptionText)}> {alert.errorDescription || alert.description}</Typography>
          </div>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}>{t`Customer`}</Typography>
            <Typography className={classes.info}> {alert.customerName}</Typography>
          </div>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}>{t`Site`}</Typography>
            <Typography className={classes.info}> {alert.siteName}</Typography>
          </div>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}>{t`System`}</Typography>
            <Typography className={classes.info}>
              {alert.systemName || t`No Info`}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}>{t`Unit`}</Typography>
            <Typography className={classes.info}>
              {alert.unitName || t`No Info`}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography className={classes.infoTitle}>{t`Time`}</Typography>
            <Typography className={classes.info}>
              {moment(alert.eventTime).tz(alert.timezone).format(`ll ${timeFormat}`)}
            </Typography>
          </div>
          {alert.unitId && !alert.isSensor && !alert.isPowerMeter ? (
            <div className={classes.itemButton}>
              <Button
                className={classes.button}
                onClick={() => getMoreInfo()}
                white
                width={127}
              >
                {t`More Info`}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </li>
      </ul>
    </div>
  );
};

export default AlertsList;
