import React from "react";

function MobileAppControl(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#AAA2AA"
          fillRule="nonzero"
          d="M13.979 8.909a1.007 1.007 0 11-2.015 0 1.007 1.007 0 012.015 0zm4.028 0a1.007 1.007 0 11-2.014 0 1.007 1.007 0 012.014 0zm4.029 0a1.007 1.007 0 11-2.015 0 1.007 1.007 0 012.015 0zm-8.057 4.029a1.007 1.007 0 11-2.015 0 1.007 1.007 0 012.015 0zm4.028 0a1.007 1.007 0 11-2.014 0 1.007 1.007 0 012.014 0zm4.029 0a1.007 1.007 0 11-2.015 0 1.007 1.007 0 012.015 0z"
        ></path>
        <path
          stroke="#AAA2AA"
          strokeWidth="1.6"
          d="M17 16.76a4.22 4.22 0 012.993 1.242 4.223 4.223 0 011.243 2.993 4.223 4.223 0 01-1.243 2.994A4.222 4.222 0 0117 25.231a4.222 4.222 0 01-2.993-1.242 4.223 4.223 0 01-1.243-2.994c0-1.168.476-2.226 1.243-2.993A4.222 4.222 0 0117 16.759z"
        ></path>
        <rect
          width="16.528"
          height="24.527"
          x="8.736"
          y="4.736"
          stroke="#AAA2AA"
          strokeWidth="1.6"
          rx="3"
        ></rect>
      </g>
    </svg>
  );
}

export default MobileAppControl;
