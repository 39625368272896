import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto",
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
  },
  container: {
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "20px",
    maxHeight: "calc(100% - 100px)",
    flexFlow: "column nowrap",
    minHeight: "250px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.colors.purple1,
  },
  titleContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: theme.overrides.MuiButton.contained.border,
    paddingBottom: "20px",
    alignItems: "center",
  },
  btn: {
    height: "36px !important",
    fontSize: "14px !important",
    border: theme.overrides.MuiButton.contained.border,
  },
  plusIcon: { fontSize: "13px !important" },
  noSchedulesStyle: {
    fontSize: "30px",
    color: theme.palette.primary.dark,
    marginTop: "80px",
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  errorMsg: {
    color: "red",
    fontSize: "15px",
    fontFamily: "Roboto",
    height: "20px",
    margin: "0 20px",
  },
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "100px",
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px",
  },
}));

export default useStyles;
