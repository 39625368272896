import * as React from 'react';

function SvgCo2(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#FFF">
          <path d="M10.143 4.543l7.337 5.503v9.046H2.806v-9.046l7.337-5.503z" />
          <g strokeLinecap="round">
            <path d="M18.852 5.457L17.09 6.636M9.898 0l.037 2.021m5.205-.59l-.877 1.763M1 5.457l1.696 1.135M5.074 1.431l.882 1.773" />
          </g>
        </g>
        <path
          d="M6.854 15.501c.486 0 .877-.128 1.174-.385.297-.256.462-.607.494-1.053h-.854c-.019.253-.093.44-.223.56-.13.12-.327.18-.59.18-.293 0-.507-.103-.642-.31-.136-.207-.204-.534-.204-.982v-.313c.004-.429.077-.745.218-.949.141-.204.354-.306.639-.306.262 0 .457.06.585.18.128.121.202.315.223.583h.854c-.046-.463-.215-.823-.507-1.079-.292-.256-.677-.384-1.155-.384-.345 0-.649.081-.91.243-.259.163-.46.395-.6.699a2.463 2.463 0 00-.212 1.053v.253c0 .625.152 1.115.457 1.473.304.358.722.537 1.253.537zm3.874 0c.347 0 .655-.084.923-.25.269-.168.475-.405.62-.712.144-.308.216-.665.216-1.073v-.185c0-.406-.074-.764-.22-1.075a1.653 1.653 0 00-.624-.714 1.717 1.717 0 00-.92-.249c-.346 0-.653.083-.922.249a1.645 1.645 0 00-.623.714c-.147.31-.22.67-.22 1.078v.204c.002.399.076.751.224 1.058.148.306.357.542.627.707.269.165.575.248.919.248zm0-.7a.76.76 0 01-.672-.35c-.155-.234-.233-.562-.233-.985v-.203c.002-.431.08-.758.236-.98a.76.76 0 01.663-.335c.289 0 .51.113.666.338.156.226.233.556.233.99v.203c-.001.43-.08.758-.233.983a.752.752 0 01-.66.337zm4.024 1.614v-.442h-1.188l.628-.662c.172-.189.294-.354.365-.495a.942.942 0 00.106-.418c0-.249-.079-.442-.236-.578-.158-.137-.379-.205-.663-.205-.186 0-.353.04-.5.118a.852.852 0 00-.34.328.905.905 0 00-.12.46h.55c0-.139.035-.25.107-.336a.36.36 0 01.293-.128c.115 0 .204.035.266.105.062.07.093.167.093.29 0 .09-.03.184-.089.284-.058.1-.15.217-.272.351l-.893.952v.376h1.893z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgCo2;
