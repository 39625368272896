import * as React from 'react';

function SvgDiagnostics(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={9.35} cy={9.35} r={8.65} stroke="#FFF" strokeWidth={1.4} />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
          d="M15.95 15.95l4.506 4.506"
        />
        <text fill="#FFF" fontFamily="Roboto-Black, Roboto" fontSize={7.3} fontWeight={700}>
          <tspan x={2.86} y={11.85}>
            {'0'}
          </tspan>
        </text>
        <text fill="#FFF" fontFamily="Roboto-Black, Roboto" fontSize={7.3} fontWeight={700}>
          <tspan x={11} y={9.65}>
            {'1'}
          </tspan>
        </text>
        <text fill="#FFF" fontFamily="Roboto-Black, Roboto" fontSize={7.3} fontWeight={700}>
          <tspan x={7.7} y={15.15}>
            {'7'}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgDiagnostics;
