import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  headers: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "5px",
    paddingTop: "20px"
  },
  dropDownItem: {
    width: "100%",
    padding: "0 16px",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  dialogActions: {
    justifyContent: "end"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    ...theme.dialogs.header,
    "& h6": {
      ...theme.dialogs.title
    }
  },
  container: {
    height: "100%",
    overflowY: "auto",
    boxSizing: "border-box"
  },
  subtitles: {
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#545964"
  }

}));

export default useStyles;
