import {
    Checkbox, Grid, IconButton, List, ListItem,
    ListItemText,
    makeStyles,
    Paper,
    PaperProps,
    Popover,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { Add, Close } from "../../icons";

export interface IPropsFilter {
    [key: string]: any;
}

export interface IPropsFilterProps {
    title: string;
    type: any;
    orderedParams: any;
    paramsObj: any;
    setSystemParams: any
    systemId: any;
    updateUserSystemPreferences: any

}

const useStyles = makeStyles((theme: any) => ({
    filterHeaderContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "0.5rem",
        borderBottom: "2px solid rgba(60, 60, 60, 0.2)"
    },
    filterHeader: {
        paddingLeft: "6px",
        color: "#4b1c46",
        whiteSpace: "nowrap"
    },
    filterList: {
        overflow: "auto",
        height: "calc(100% - 75px)"
    },
    paper: { height: " 100%" },
    limitShownText: {
        "& span": {
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    },
    grayCheckbox: {
        marginRight: "10px",
        "&$checked": {
            color: "#736973"
        }
    },
    addIcon: {
        color: "#aaa2aa",
        marginLeft: "3px",
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    },
    pushedAddIcon: {
        background: "#EFEFF2"
    },
    paramsPopupButton: {
        padding: 20,
        display: "flex",
        justifyContent: "flex-end"

    },
    filterPopupHeader: {
        paddingLeft: 20,
        paddingRight: 13,
        ...theme.dialogs.header,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        minHeight: 60

    },
    filterPopupList: {
        width: "300px",
        height: "calc(100% - 60px)",
        overflowY: "auto",
        paddingLeft: 10,
        paddingRight: 20
    },
    clearIcon: {
        width: "28px",
        height: "28px"
    },
    outdoorPaper: {
        height: "80%",
        maxHeight: "80%",
        overflow: "hidden"
    },
    indoorPaper: {
        height: "47%"
    },
    moveCursor: { cursor: "move !important" },
    iconBtnStyle: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    },
    headerTitle: {
        ...theme.dialogs.title
    },
    customLabel: {
        color: "white",
        borderRadius: "3px",
        padding: "4px 7px",
        fontSize: "11px",
        lineHeight: "normal",
        fontWeight: "normal",
        letterSpacing: "normal",
        width: "fit-content",
        maxHeight: "19px",
        background: "#d7d7df"
    }

}));

export const PropsFilter: React.FC<IPropsFilterProps & Partial<PaperProps>> = (props) => {
    const {
        title,
        type,
        paramsObj,
        orderedParams,
        setSystemParams,
        systemId,
        updateUserSystemPreferences,
        ...rest
    } = props;
    const styles = useStyles();
    const [openParamsDialog, setOpenParamsDialog] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openParamsList = (event: any) => {
        setOpenParamsDialog(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpenParamsDialog(false);
        setAnchorEl(null);
    };

    const reorder = (arr: any, source: any, dest: any) => {
        const sourceCode = arr[source];
        arr.splice(source, 1);
        arr.splice(dest, 0, sourceCode);
        return arr;
    };

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newOrder = reorder(
            [...orderedParams],
            result.source.index,
            result.destination.index
        );

       setSystemParams({type: "reorder", paramsType: type, paramsOrder: [...newOrder], systemId, updateAPI: updateUserSystemPreferences});
    }

    const paramsArr = Object.values(paramsObj);
    const shownInListParams = paramsArr.filter((param: any) => param.showInList)?.length;
    const allChecked = paramsArr.length === shownInListParams;
    const someChecked = !allChecked && (paramsArr.length !== 0);

    return (
        <Paper {...rest} className={styles.paper}>
            <div className={styles.filterHeaderContainer}>
                <Typography variant="h6" className={styles.filterHeader}>
                    {title}
                </Typography>
                <IconButton disableRipple className={clsx(styles.addIcon, someChecked && styles.pushedAddIcon)} onClick={openParamsList} id={`open-${type}`}>
                    <Add />
                </IconButton>
            </div>

            <Popover
                id={`paramsListPopup-${type}`}
                disableAutoFocus={false}
                disableEnforceFocus={false}
                open={openParamsDialog}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                classes={{ paper: type === "outdoor" ? styles.outdoorPaper : type === "indoor" ? styles.indoorPaper : "" }}
            >
                <div className={styles.filterPopupHeader}>
                    <Typography className={styles.headerTitle}>{t`Edit Parameters list`}</Typography>
                    <IconButton disableRipple onClick={handleClose} className={styles.iconBtnStyle}>
                        <Close color="#7f7692" />
                    </IconButton>
                </div>
                {paramsArr?.length
                    ?
                    <List id={`paramsOption-${type}`} disablePadding={true} className={styles.filterPopupList}>
                        <ListItem key={"all"} disableGutters dense autoFocus onClick={(event: any) => setSystemParams({type: "selectUnSelect", paramsType: type, check: true, code: "all" , flag: "showInList", value: event.target.checked, systemId, updateAPI: updateUserSystemPreferences})}>
                            <Checkbox
                                color="default"
                                checked={allChecked}
                                classes={{ root: styles.grayCheckbox }}
                            />
                            <ListItemText id={`checkbox-text-all`} primary={t`All`} />
                        </ListItem>
                        {paramsArr.map((filter: any, index: number) => {
                            return (
                                <ListItem disableGutters dense autoFocus key={index} onClick={(event: any) => setSystemParams({type: "selectUnSelect", paramsType: type, code: filter.code, flag: "showInList", value: event.target.checked, systemId, updateAPI: updateUserSystemPreferences})}>
                                    <Checkbox
                                        color="default"
                                        edge="end"
                                        checked={filter.showInList}
                                        inputProps={{ "aria-labelledby": filter.name }}
                                        classes={{ root: styles.grayCheckbox }}
                                    />
                                    <ListItemText id={`${filter.id}`} primary={filter.name} className={styles.limitShownText} />
                                    {filter?.isCustomParam && <Typography className={styles.customLabel}>{t`CUSTOM`}</Typography>}
                                </ListItem>
                            );
                        })}
                    </List>

                    : <Typography style={{ padding: "15px", fontSize: "18px" }}>{t`No parameters for this system`}</Typography>
                }
            </Popover>
            {/* params side filters */}
            <List id={`selectedParams-${type}`} disablePadding={true} className={styles.filterList}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {(provided: any) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>

                                {orderedParams.map((item: any, index: number) => {
                                  const [code] = item;
                                    const param: any = paramsObj[code];
                                    if (!param) {
                                        return null;
                                    }
                                    const { showInTable, name, showInList, isCustomParam = false } = param;

                                    if (!showInList) {
                                        return null;
                                    }
                                    return <Draggable key={code} draggableId={code} index={index}>
                                        {(provided: any) => (
                                            <ListItem dense divider key={index} disableGutters
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={styles.moveCursor}
                                            >
                                                <Checkbox
                                                    color="default"
                                                    edge="end"
                                                    onChange={(event: any) => setSystemParams({type: "selectUnSelect", paramsType: type, code: param.code, flag: "showInTable", value: event.target.checked, systemId, updateAPI: updateUserSystemPreferences})}
                                                    checked={showInTable}
                                                    inputProps={{ "aria-labelledby": name }}
                                                    classes={{ root: styles.grayCheckbox }}
                                                />
                                                <Tooltip arrow title={name} placement="top-start">
                                                    <ListItemText id={`${code}`} primary={name} className={styles.limitShownText} />
                                                </Tooltip>
                                                {isCustomParam && <Typography className={styles.customLabel}>{t`CUSTOM`}</Typography>}
                                            </ListItem>
                                        )}
                                    </Draggable>;
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List >
        </Paper >
    );
};
