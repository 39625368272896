import { Checkbox, Collapse, Divider, Grid, IconButton, Input, InputLabel, MenuItem, Select as MUISelect, Switch, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { Field, FormikProps } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { ArrowDownO } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUser } from "../../models/Users";
import { DatePicker } from "./DatePicker";
import useStyles from "./UserDetails.style";
import UserDetailsField from "./UserDetailsField";
import { IUserFormValues } from "./UserHelpers";

const appsOptions: any = { 0: { name: "Professional", value: 0 }, 1: { name: "Control", value: 1 } };

const InviteSchema =
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, t`Too Long!`)
      .required(t`Required`),
    lastName: Yup.string()
      .max(20, t`Too Long!`)
      .required(t`Required`),
    email: Yup.string()
      .email(t`Invalid email`)
      .required(t`Required`),
    expiresDate: Yup.number().when(["enableExpired"], {
      is: (enableExpired) => enableExpired,
      then: Yup.number().required(t`Required`),
      otherwise: Yup.number()
    }),
    adminRole: Yup.object().required(t`Required`)

  });

const UserSchema =
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, t`Too Long!`)
      .required(t`Required`),
    lastName: Yup.string()
      .max(20, t`Too Long!`)
      .required(t`Required`),
    email: Yup.string()
      .email(t`Invalid email`)
      .required(t`Required`),
    username: Yup.string()
      .min(6, t`Too Short!`)
      .max(50, t`Too Long!`)
      .required(t`Required`),
    phone: Yup.string()
      .test(
        "phone validation",
        t`Phone number should be 10 chars minimum.`,
        (value) => {
          if (!value) {
            return true;
          }

          let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
          newValue = newValue.replace(/\s/g, "");

          if (newValue.length > 9 || newValue.length === 0) {
            return true;
          }
          return false;
        }
      )
    ,
    password: Yup.string()
      .required(t`Required`)
      .matches(
        /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t`must be at least 8+ characters, 1 capital, 1 number`
      )
  });
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250
      maxHeight: ITEM_HEIGHT * 2 + ITEM_PADDING_TOP
    }
  }
};
export { UserSchema, InviteSchema };

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<IUserFormValues>, "handleSubmit"> {
  user: IUser;
  errors: any;
  touched: any;
  temperatureScale: any;
  pressureScale: any;
  showLanguage?: boolean;
  edit?: boolean;
  withPasswordField?: any;
  inviteEdit?: any;
  inviteOnly?: boolean;
}

const UserDetails: React.FC<IProps> = ({
  user,
  errors,
  touched,
  showLanguage = false,
  values,
  setFieldValue,
  temperatureScale,
  pressureScale,
  handleChange,
  edit = false,
  withPasswordField,
  inviteEdit = false,
  inviteOnly = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={clsx(classes.headerStyle, { [classes.addTopMargin]: edit })}
      >{t`Personal Details`}</Typography>
      {!inviteOnly && <Field
        disabled={inviteEdit}
        label={t`First name`}
        // placeholder={t`First name`}
        value={values.firstName}
        name="firstName"
        component={UserDetailsField}
        error={errors.firstName && touched.firstName ? true : false}
        helperText={
          errors.firstName && touched.firstName ? errors.firstName : t`Up to 20 characters`
        }
      />}
      {!inviteOnly && <Field
        disabled={inviteEdit}
        label={t`Last name`}
        value={values.lastName}
        name="lastName"
        component={UserDetailsField}
        error={errors.lastName && touched.lastName ? true : false}
        helperText={errors.lastName && touched.lastName ? errors.lastName : t`Up to 20 characters`}
      />}
      <Field
        disabled={inviteEdit}
        label={t`Email Address`}
        value={values.email}
        name="email"
        component={UserDetailsField}
        error={errors.email && touched.email ? true : false}
        helperText={errors.email && touched.email ? errors.email : t`Valid email address`}
      />
      <div className={clsx(classes.expiresBox, { [classes.errorBorder]: errors.expiresDate && touched.expiresDate })}>
        <InputLabel className={clsx(classes.expireTitles, classes.expiresLabel)}>
          <Checkbox
            disabled={inviteEdit}
            name="enableExpired"
            value={"User Expires"}
            color="primary"
            className={classes.checkboxStyle}
            onChange={(event: any) => setFieldValue("enableExpired", event.target.checked)}
          />
          {t`User expires`}
        </InputLabel>
        <DatePicker
          onSet={(timestamp: any) => setFieldValue("expiresDate", timestamp)}
          disabled={!values.enableExpired || inviteEdit}
        />

      </div>
    </>
  );
};

export default UserDetails;
