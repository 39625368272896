import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      minHeight: "53px",
      marginBottom: "10px",
      flex: 0.45,
      minWidth: "20px"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "20px",
      marginBottom: "10px"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px"
    },
    content: {
      borderRadius: "6px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "25px"
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },

    radio: {
      "& span": { color: theme.palette.primary.dark }
    },

    Login: {
      MozFlexDirection: "column",
      flexDirection: "column",
      height: "100vh",
      display: "flex",
      fontSize: "1.5rem",
      color: "#29abe2"
    },

    logos: {
      justifyContent: "center",
      display: "flex",
      MozFlexDirection: "column",
      flexDirection: "column",
      alignItems: "center",
      padding: "15px 0",
      // background: theme.palette.primary.dark,
      marginBottom: "30px"
    },

    overrideField: {
      "&>label": {
        "&.Mui-disabled": {
          // color: "rgba(255, 255, 255, 0.7)"
        }
      }
    },

    bottom: {
      height: "48px",
      opacity: 0.6,
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      color: theme.palette.primary.dark,
      textAlign: "center"
    },

    mailto: {
      color: theme.palette.primary.dark,
      opacity: "0.8"
    },

    logo: {
      maxHeight: "60px"
    },

    interactionSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%"
    },

    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%"
    },

    titleStyle: {
      fontSize: "18px",
      color: theme.palette.primary.dark,
      lineHeight: "40px"
    },

    recoverPasswordMessegeStyle: {
      fontSize: "14px",
      overflowWrap: "break-word",
      width: "90%",
      opacity: 0.6,
      textAlign: "center",
      color: theme.palette.primary.dark
    }
  })
);

export default useStyles;
