import { IconButton, Typography } from "@material-ui/core/";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./AuditInfo.style";

const dataPropertiesReplacement: { [key: string]: any } = {
  isRegistered: "Connected",
  isExpired: "Expired"
};

const notReadable = [
  "name",
  "customer",
  "isAcceptedTOU",
  "_id",
  "__v",
  "user",
  "userLocationsMetadata",
  "password",
  "randId",
  "id",
  "device",
  "admin",
  "lat",
  "lon",
  "fileId",
  "type",
  "site"
];

const AuditInfo = (props: any) => {
  const classes = useStyles();

  const { audit, close } = props;
  const { source, sourceType, customerName, actionId, action, formattedDate, user, siteName, deviceName, unitName, line, data } = audit;
  const { operationStatuses, operationModesExtended, fanModes, swingModes, temperatureScale, actionSources } = useStoreState((s) => s.types);

  const getTimeFromMins = (mins: number) => {

    const h = mins / 60 | 0,
      m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format("hh:mm A");
  };
  const valueMapping = (value: number, action: string) => {
    const lowerCaseAction = action.toLowerCase();
    if (lowerCaseAction.includes("setpoint")) { return value; }
    if (lowerCaseAction.includes("operationstatus")) { return operationStatuses[value] || value; }
    if (lowerCaseAction.includes("operationmode")) { return operationModesExtended[value] || value; }
    if (lowerCaseAction.includes("fanmode")) { return fanModes[value] || value; }
    if (lowerCaseAction.includes("swingmode")) { return swingModes[value] || value; }
    return value;
  };
  const valueTitleMapping = (action: string) => {
    const lowerCaseAction = action.toLowerCase();
    if (lowerCaseAction.includes("setpoint")) { return t`Setpoint`; }
    if (lowerCaseAction.includes("operationstatus")) { return t`Operation Status`; }
    if (lowerCaseAction.includes("operationmode")) { return t`Operation Mode`; }
    if (lowerCaseAction.includes("fanmode")) { return t`Fan Mode`; }
    if (lowerCaseAction.includes("swingmode")) { return t`Swing Mode`; }
    return t`value`;
  };
  const replaceTitle = (property: string) => {
    const replacement = dataPropertiesReplacement[property];
    if (replacement) { return replacement; }
    const title = property
      .split(/(?=[A-Z])/)
      .join(" ")
      .trim();
    return title;
  };

  const auditSource = (source: number) => {
    switch (source) {
      case +actionSources.user: return t`Application User`;
      case +actionSources.schedule: return t`Scheduled Operation`;
      case +actionSources.homeAutomation: return t`Home automation / BMS`;
      case +actionSources.hvac: return t`HVAC`;
      case +actionSources.applicationLogic: return t`Application Logic`;
      case +actionSources.ecobee: return t`Ecobee`;
      default: return t`Unknown source`;
    }
  };

  const DataRow = ({ property, content, action, index }: any) => {
    const contentText = typeof content === "boolean" ? (content ? "yes" : "no") : content;
    return (
      <div className={classes.row}>
        <Typography className={classes.title}>
          {property === "value" ? valueTitleMapping(action) : replaceTitle(property)}
        </Typography>
        <Typography className={classes.data}>
          {property === "value"
            ? `${valueMapping(contentText, action)}`
            : property === "temperatureScale"
              ? `${temperatureScale[contentText]}`
              : `${contentText}`}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.infoDialogStyle}>
      <div id="title" className={clsx(classes.dataContainer, { [classes.iosTopHeight]: isIOS })}>
        <Typography className={classes.details}>{t`Audit Data`}</Typography>
        <IconButton disableRipple onClick={close}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.rowsContainer}>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Source`}</Typography>
          <Typography className={classes.data}>{auditSource(source)}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Source Type`}</Typography>
          <Typography className={classes.data}>{sourceType}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Customer`}</Typography>
          <Typography className={classes.data}>{customerName}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Action`}</Typography>
          <Typography className={classes.data}>{action}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Date`}</Typography>
          <Typography className={classes.data}>{formattedDate}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`User`}</Typography>
          <Typography className={classes.data}>{user}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Site`}</Typography>
          <Typography className={classes.data}>{siteName}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Device`}</Typography>
          <Typography className={classes.data}>{deviceName}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Unit`}</Typography>
          <Typography className={classes.data}>{unitName}</Typography>
        </div>
        {line &&
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Line`}</Typography>
            <Typography className={classes.data}>{line}</Typography>
          </div>
        }

        {data &&
          Object.keys(data).map((key: any) => {
            let content = data[key];
            if (
              typeof content !== "string" &&
              typeof content !== "number" &&
              typeof content !== "boolean"
            ) {
              return null;
            }

            if (notReadable.includes(key)) {
              return null;
            }
            if (typeof content === "number" && (key === "powerOffTime" || key === "powerOnTime")) {
              content = getTimeFromMins(content);
            }
            return (
              <DataRow
                key={`audit-data`}
                property={key}
                content={content}
                action={actionId}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AuditInfo;
