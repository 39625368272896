import React from "react";

const CoolSmall = ({ width = 19, height = 21 }) => (
  <svg width={width} height={height} viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#35A8E0" strokeWidth="1.026" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M9.035.439V20.25M11.949 2.674l-2.914 1.78-2.913-1.78M6.122 18.177l2.912-1.78 2.915 1.78M17.583 5.34.488 15.35M17.127 8.982l-3.009-1.614.063-3.414M.804 11.789l3.009 1.615-.064 3.413M.44 5.422l17.12 9.968M3.837 4.028l.073 3.414L.904 9.064M14.302 16.865l-.072-3.413 3.005-1.623" />
    </g>
  </svg>
);

export default CoolSmall;
