import React from "react";

function MobileUsers(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#AAA2AA"
          fillRule="nonzero"
          d="M22.462 16.122c.453 0 .83.34.874.792l.112 1.117c.267.118.52.267.76.442l1.026-.463a.877.877 0 011.122.362l.7 1.213a.877.877 0 01-.248 1.153l-.904.65a3.41 3.41 0 010 .891l.904.65c.368.264.475.76.248 1.153l-.7 1.212a.875.875 0 01-1.121.362l-1.026-.463c-.24.176-.494.323-.76.442l-.113 1.118a.875.875 0 01-.874.79H21.06a.876.876 0 01-.875-.791l-.111-1.117a4.126 4.126 0 01-.76-.442l-1.026.463a.874.874 0 01-1.122-.362l-.7-1.213a.877.877 0 01.247-1.153l.904-.65a3.496 3.496 0 01-.03-.446c0-.158.013-.31.03-.445l-.904-.65a.877.877 0 01-.248-1.153l.7-1.213a.875.875 0 011.123-.362l1.026.463c.24-.176.494-.324.76-.442l.111-1.117a.876.876 0 01.875-.79h1.4zm-.7 3.514a2.2 2.2 0 00-2.197 2.196 2.2 2.2 0 002.197 2.197 2.2 2.2 0 002.196-2.197 2.2 2.2 0 00-2.196-2.196zm-7.629-1.772a.8.8 0 01.027 1.6c-1.2.02-2.47.182-3.811.487-1.138.258-2.38.787-3.726 1.594a.392.392 0 00-.183.255l-.009.081v3.584a.4.4 0 00.32.392l.08.008h7.012a.8.8 0 01.1 1.593l-.1.007H6.831a2 2 0 01-1.994-1.851l-.006-.15V21.88a1.992 1.992 0 01.972-1.71c1.48-.886 2.876-1.481 4.191-1.78 1.446-.328 2.825-.504 4.139-.526z"
        ></path>
        <path
          stroke="#AAA2AA"
          strokeWidth="1.6"
          d="M9.82 10.58a5.218 5.218 0 1110.436 0 5.218 5.218 0 01-10.436 0z"
        ></path>
      </g>
    </svg>
  );
}

export default MobileUsers;