import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%"
  },
  content: {
    padding: "1rem"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
    maxWidth: "calc(100% - 240px)"
  },
  searchIcon: {
    color: "#fff"
  },
  autoCompletePoper: {
    backgroundColor: theme.palette.primary.dark
  },
  autoCompleteItem: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "#fff",
    "& li": {
      margin: "5px 10px"
    }
  },
  autoCompletePaper: {
    backgroundColor: "rgba(0,0,0,0)"
  },
  autoCompleteGroup: {
    backgroundColor: theme.palette.primary.dark, //"rgba(0,0,0,0)",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "17px"
  },
  autoCompleteGroupUl: {
    backgroundColor: theme.palette.background.searchList
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px"
  },
  contentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px",
    overflowY: "auto",
    padding: "0 10px"
  },
  header: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "20px",
    paddingBottom: "15px",
    borderBottom: theme.overrides.MuiButton.outlined.border,
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  frequencySelection: {
    display: "flex",
    justifyContent: "center",
    padding: "30px"
  },
  btnGroupTimeunits: {
    height: "50px",
    width: "300px",
    boxShadow: "0 3px 6px -6px #aaa2aa",
    "& button": {
      flex: 1
    }
  },
  btnText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797d",
    border: "solid 1px #edebed !important",
    textTransform: "unset",
    "&:hover": {
      background: "none"
    }
  },
  selectedBtn: {
    backgroundColor: "#edebed",
    "&:hover": {
      background: "#edebed"
    }
  },
  graphContainer: {
    width: "100%",
    height: "77%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  workingHoursTextStyle: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "15px",
    marginBottom: 10
  },
  workingHours: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: "normal",
    lineHeight: "43px",
    height: 43,
    letterSpacing: "normal"
  },
  legendsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 100px)",
    margin: "auto",
    maxWidth: "350px"
  },
  legendHolder: {
    display: "inline-flex",
    alignItems: "center"
  },
  legendColor: {
    width: 9,
    height: 9,
    borderRadius: 2,
    marginRight: 7
  },
  legendName: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progress: {
    height: "calc(100vh - 125px)",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    left: "50%",
    position: "absolute"
  }
}));

export default useStyles;
