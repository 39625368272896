import { Link, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import React from "react";
import { t } from "ttag";
import packageJson from "../../../package.json";
import Button from "../../cool_widgets/Button";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./About.style";
import { getHostname } from "../../services/utils";

export default function About() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const config = useStoreState(state => state.config);

  const { aboutTxt = {} } = config || {};
  const {
    TOULink = null,
    supportLink = null,
    mainText = null,
    title = null,
    hideVersionTracking = false
  } = aboutTxt;


  const handleClickOpen = () => {
    setOpen(true);
  };

  const domain = getHostname();
  const isAirwell = domain.indexOf("airconnectpro") > -1;
  const userPreferences = useStoreState((state) => state.users.userPreferences);

  const isFr = userPreferences.professionalLanguage === "fr";

  return (
    <>
      <div className={classes.dropDownItem} onClick={handleClickOpen}>{t`About`}</div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.titleContent}>
          <Typography variant="h6">{t`About us`}</Typography>
        </div>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" className={classes.headers}>
            {title ? title : t`About CoolAutomation`}
          </DialogContentText>
          <div className={classes.container}>

            <Typography className={classes.subtitles}>
              {mainText ?
                mainText :
                t`CoolAutomation leads the way in connected HVAC solutions, with a
          pioneering approach we call the Internet of Climate (IoC). The
          Internet of Climate is a universal concept that Internet-enabled any
          climate system, simply and seamlessly. Our mission is to connect the
          traditional world of HVAC with the future of IoT, enabling HVAC to
          play a central role in smart buildings and smart homes.`}{" "}
            </Typography>

            <Typography className={classes.headers}>{t`Version number`}</Typography>

            <Typography className={classes.subtitles}>
              {packageJson ? t`Version` + " " + packageJson.version : ""}
            </Typography>

            {!hideVersionTracking && <Link
              underline={"always"}
              className={classes.subtitles}
              target="_blank"
              href={"https://files.coolremote.net/public/share/Release%20notes.pdf"}
            >
              {t`Version tracking notes`}
            </Link>}

            <Typography className={classes.headers}>{t`Terms of use`}</Typography>

            <Link
              target="_blank"
              href={TOULink ? TOULink : (isAirwell ? (isFr ? "https://www.airwell.com/fr/cgu-airconnectpro" : "https://www.airwell.com/en/tou-airconnectpro") : "https://coolautomation.com/terms-of-use/")}
              className={classes.subtitles}
            >
              {TOULink ? TOULink : (isAirwell ? (isFr ? "www.airwell.com/fr/cgu-airconnectpro" : "www.airwell.com/en/tou-airconnectpro") : "https://coolautomation.com/terms-of-use/")}
            </Link>

            <Typography className={classes.headers}>{t`Support`} </Typography>

            <Link
              className={classes.subtitles}
              target="_blank"
              href={supportLink ? supportLink : (isAirwell ? "https://www.airconnect-pro.com/support" : "https://coolautomation.com/support/")}
            >
              {supportLink ? supportLink : (isAirwell ? "https://www.airconnect-pro.com/support" : "https://coolautomation.com/support/")}
            </Link>

          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => setOpen(false)}
            width={100}
            marginRight
          >
            {t`Close`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
