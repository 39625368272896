
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect } from "react";
import { t } from "ttag";
import * as XLSX from "xlsx";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Export from "../../icons/Export";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { deviceProps, siteProps, systemProps, unitProps } from "./ExcelHelpers";
import useStyles from "./SiteView.style";

export default function ExportExcel(props: any) {
    const classes = useStyles();
    const allUnits = useStoreActions((action) => action.units.allUnits);
    const allSystems = useStoreState((state) => state.systems.allSystems);
    const getSystemsBySite = useStoreState((state) => state.systems.getSystemsBySite);

    const hvacBrands = useStoreState((state) => state.types.hvacBrands);
    const { site } = props;
    const [siteSystems, setSiteSystems] = React.useState<any>([]);
    const [siteDevices, setSiteDevices] = React.useState<any>([]);
    const [serviceUnits, setSiteServiceUnits] = React.useState<any>([]);
    const [outdoorUnits, setSiteOutdoorUnits] = React.useState<any>([]);
    const [indoorUnits, setSiteIndoorUnits] = React.useState<any>([]);

    const getDevicesBySite = useStoreState((state) => state.devices.getDevicesBySite);
    const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);
    const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
    const getDeviceById = useStoreState((state) => state.devices.getDeviceById);
    const getSystemById = useStoreState((state) => state.systems.getSystem);

    useEffect(() => {
        (async () => {
            const systems = getSystemsBySite(site.id);
            const devices = getDevicesBySite(site.id);
            let serviceUnits: any = [];
            let outdoorUnits: any = [];
            let indoorUnits: any = [];

            for (let device of devices) {
                const serviceUnitsRes = getDeviceUnits(device.id, "all", "service");
                const outdoorUnitsRes = getDeviceUnits(device.id, "all", "outdoor");
                const indoorUnitsRes = getDeviceUnits(device.id, "all", "indoor");

                serviceUnits = _.union(serviceUnits, serviceUnitsRes);
                outdoorUnits = _.union(outdoorUnits, outdoorUnitsRes);
                indoorUnits = _.union(indoorUnits, indoorUnitsRes);

            }
            setSiteServiceUnits(serviceUnits);
            setSiteOutdoorUnits(outdoorUnits);
            setSiteIndoorUnits(indoorUnits);

            setSiteDevices(devices);
            setSiteSystems(systems);
        })();
    }, [site, allSystems, allUnits]);

    const handleClick = () => {
        let xlsSystemHeader = _.map(Object.keys(systemProps));
        let xlsUnitHeader = _.map(Object.keys(unitProps));
        let xlsDeviceHeader = _.map(Object.keys(deviceProps));
        let createXLSLFormatObj = [];
        createXLSLFormatObj.push(["Site Information"]);

        let siteRow: any = [];

        _.map(Object.keys(siteProps), (value) => {
            siteRow.push(value);
            siteRow.push(site[siteProps[value].dbName]);
            createXLSLFormatObj.push(siteRow);
            siteRow = [];
        });

        createXLSLFormatObj.push([]);

        createXLSLFormatObj.push(["CA Connectivity devices"]);

        createXLSLFormatObj.push(xlsDeviceHeader);
        let deviceRow: any = [];

        _.map(siteDevices, (device) => {
            deviceRow = [];
            _.map(xlsDeviceHeader, (value) => {
                deviceRow.push(device[deviceProps[value].dbName]);
            });
            createXLSLFormatObj.push(deviceRow);
        });
        createXLSLFormatObj.push([]);

        createXLSLFormatObj.push(["Connectd VRF Systems"]);

        createXLSLFormatObj.push(xlsSystemHeader);
        let systemRow: any = [];

        _.map(siteSystems, (system) => {
            if (!system) {
                return;
            }
            systemRow = [];
            _.map(xlsSystemHeader, (value) => {
                if (value === "CA Device serial") {
                    systemRow.push(getDeviceById(system[systemProps[value].dbName])?.serial);
                } else if (value === "Brand") {
                    const brandName = !_.isNil(_.find(hvacBrands, { value: system.brandNum })) && _.find(hvacBrands, { value: system.brandNum }).name;
                    systemRow.push(brandName);
                } else {
                    systemRow.push(system[systemProps[value].dbName]);
                }
            });
            createXLSLFormatObj.push(systemRow);
        });
        createXLSLFormatObj.push([]);

        createXLSLFormatObj.push(["Units information"]);

        createXLSLFormatObj.push(xlsUnitHeader);
        let unitRow: any = [];

        _.map(serviceUnits, (unit) => {
            unitRow = [];
            _.map(xlsUnitHeader, (value) => {
                if (value === "CA Device") {
                    unitRow.push(getDeviceById(unit[unitProps[value].dbName])?.serial);
                } else if (value === "System") {
                    unitRow.push(getSystemById(unit[unitProps[value].dbName])?.name);

                } else if (value === "Master/Slave (ODU only)") {
                    unitRow.push("");
                } else if (value === "Group address (=ID)") {
                    const controlUnit: any = _.filter(indoorUnits, (u) => (unit[unitProps[value].dbName] && u.id === unit[unitProps[value].dbName]));
                    if (controlUnit.length) {
                        unitRow.push(controlUnit[0].address);
                    }
                    else {
                        unitRow.push("");
                    }
                } else {
                    unitRow.push(unit[unitProps[value].dbName]);
                }
            });
            createXLSLFormatObj.push(unitRow);
        });
        _.map(outdoorUnits, (unit) => {
            unitRow = [];
            _.map(xlsUnitHeader, (value) => {
                if (value === "CA Device") {
                    unitRow.push(getDeviceById(unit[unitProps[value].dbName])?.serial);
                } else if (value === "System") {
                    unitRow.push(getSystemById(unit[unitProps[value].dbName])?.name);

                } else if (value === "Master/Slave (ODU only)") {
                    const task = unit[unitProps[value].dbName] === 1 ? "Master" : (unit[unitProps[value].dbName] === 2 ? "Slave" : "");
                    unitRow.push(task);
                } else {
                    unitRow.push(unit[unitProps[value].dbName]);
                }
            });
            createXLSLFormatObj.push(unitRow);
        });

        let filename = `SiteManagement-${site.name}.xlsx`;

        let ws_name = "Sheet";

        if (typeof console !== "undefined") console.log(new Date());
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
        const wscols = [
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 }
        ];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        if (typeof console !== "undefined") console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== "undefined") console.log(new Date());

    };

    return (
        <LightTooltip title={t`Export Site Info to CSV`}>
            <IconButton disableRipple onClick={handleClick} className={clsx(classes.iconBtnStyle, classes.exportSiteIcon)}><Export /></IconButton>
        </LightTooltip>

    );
}
