import { t } from "ttag";

const AnomaliesUtils = {

  selectableDurations: [
    {
      title: t`Immediately`,
      value: "0"
    },
    {
      title: t`5 min`,
      value: "00:05"
    },
    {
      title: t`10 min`,
      value: "00:10"
    },
    {
      title: t`15 min`,
      value: "00:15"
    },
    {
      title: t`30 min`,
      value: "00:30"
    },
    {
      title: t`60 min`,
      value: "01:00"
    },
    {
      title: t`90 min`,
      value: "01:30"
    },
    {
      title: t`120 min`,
      value: "02:00"
    }
  ],
  getDurationByMin: (duration: string | number) => {
    if (typeof duration === "number") {
      return duration;
    }
    const [h = 0, min = 0] = duration?.split(":");
    return (+h * 60 + +min) || 0;
  }
};

export default AnomaliesUtils;
