import { Reporting as sdkReporting } from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import { getDayStartEndTimeStampOfUTCDate } from "../services/timeService";

export interface IReportsData {
  modeReportsData: {
    date: any;
    data: any[];
    info: any;
  };
  PowerReportsData: {
    date: any;
    data: {
      data: any[];
      total?: any;
      average?: any;
    };
    system: any;
  };
  SysDiversityReportsData: {
    date: any;
    data: any;
  };
  standByTimeReportsData: {
    date: any;
    data: any;
    avgData: any;
    max: any;
  };
  maintenanceReportsData: {
    date: any;
    data: any;
    avgData: any;
    max: any;
  };
  resetAllData: Action<IReportsData, void>;
  setModeGraphData: Action<IReportsData, any>;
  getModeGraphData: Thunk<IReportsData, { date: any; site: any }>;
  setPowerData: Action<IReportsData, any>;
  getPowerData: Thunk<IReportsData, { date: any; site: any; system?: any }>;
  setSysDiversityData: Action<IReportsData, any>;
  getSysDiversityData: Thunk<
    IReportsData,
    { date: any; site?: any; customer?: any }
  >;
  setStandByData: Action<IReportsData, any>;
  getStandByData: Thunk<IReportsData, { date: any; site: any }>;
  setMantenanceData: Action<IReportsData, any>;
  getMantenanceData: Thunk<IReportsData, { date: any; site: any }>;
  getMonthlyPowerData: Thunk<
    IReportsData,
    { date: any; customerId: string; siteId?: any }
  >;
  getAlertsData: Thunk<
    IReportsData,
    { type: "site" | "system"; date: any; id: any }
  >;
  setAlertsGraphData: Action<IReportsData, any>;
  alertsGraphData: {
    data: any;
    date: any;
    system: any;
  };
}

export const ReportsDataModel: IReportsData = {
  modeReportsData: {
    date: null,
    data: [],
    info: {},
  },
  PowerReportsData: {
    date: null,
    data: {
      data: [],
    },
    system: "",
  },
  SysDiversityReportsData: {
    date: null,
    data: [],
  },
  standByTimeReportsData: {
    date: null,
    data: [],
    avgData: [],
    max: null,
  },
  maintenanceReportsData: {
    date: null,
    data: [],
    avgData: [],
    max: null,
  },
  alertsGraphData: {
    data: [],
    date: null,
    system: "",
  },
  resetAllData: action(state => {
    state.modeReportsData = {
      date: null,
      data: [],
      info: {},
    };
    state.PowerReportsData = {
      date: null,
      data: {
        data: [],
      },
      system: "",
    };
    state.SysDiversityReportsData = {
      date: null,
      data: [],
    };
    state.standByTimeReportsData = {
      date: null,
      data: [],
      avgData: [],
      max: null,
    };
    state.maintenanceReportsData = {
      date: null,
      data: [],
      avgData: [],
      max: null,
    };
    state.alertsGraphData = {
      data: [],
      date: null,
      system: "",
    };
  }),
  setMantenanceData: action((state, payload) => {
    state.maintenanceReportsData = {
      ...state.maintenanceReportsData,
      ...payload,
    };
  }),
  getMantenanceData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;

    return sdkReporting
      .getSetpointMaint(
        payload.site,
        getDayStartEndTimeStampOfUTCDate(startDate),
        getDayStartEndTimeStampOfUTCDate(endDate, "end")
      )
      .then((resp: any) => {
        if (_.isEmpty(resp)) {
          return;
        }
        const arr = [{ ...resp.average, name: "Avg all units" }, ...resp.units];
        const maxVal = arr.reduce((result: any, item: any) => {
          return Math.max(result, item.minutes_on_total);
        }, 0);

        actions.setMantenanceData({
          avgData: [{ ...resp.average, name: "Avg all units" }],
          data: resp.units,
          max: maxVal,
        });
      });
  }),
  setStandByData: action((state, payload) => {
    state.standByTimeReportsData = {
      ...state.standByTimeReportsData,
      ...payload,
    };
  }),
  getStandByData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;
    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    return sdkReporting
      .getStandByTime(payload.site, startTimeStamp, endTimeStamp)
      .then((resp: any) => {
        if (_.isEmpty(resp)) {
          return;
        }

        const arr = [{ ...resp.average, name: "Avg all units" }, ...resp.units];
        const maxVal = arr.reduce((result: any, item: any) => {
          return Math.max(result, item.minutes_on_total);
        }, 0);
        actions.setStandByData({
          avgData: [{ ...resp.average, name: "Avg all units" }],
          data: resp.units,
          max: maxVal,
        });
      });
  }),
  setSysDiversityData: action((state, payload) => {
    state.SysDiversityReportsData = {
      ...state.SysDiversityReportsData,
      ...payload,
    };
  }),
  getSysDiversityData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;
    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    const fn = payload.site
      ? sdkReporting.getSiteDivesityReport(
        payload.site,
        startTimeStamp,
        endTimeStamp
      )
      : sdkReporting.getCustomerDivesityReport(
        payload.customer,
        startTimeStamp,
        endTimeStamp
      );

    return fn.then((resp: any) => {
      actions.setSysDiversityData({ data: resp });
    });
  }),
  setPowerData: action((state, payload) => {
    state.PowerReportsData = { ...state.PowerReportsData, ...payload };
  }),
  getPowerData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;
    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    const fn = !!payload.system
      ? sdkReporting.sysPowerConsumptionReport(
        payload.system,
        startTimeStamp,
        endTimeStamp
      )
      : sdkReporting.sitePowerConsumptionReport(
        payload.site,
        startTimeStamp,
        endTimeStamp
      );
    return fn.then((resp: any) => {
      if (_.isEmpty(resp)) {
        return;
      }
      actions.setPowerData({ date: payload.date, data: resp });
    });
  }),
  setModeGraphData: action((state, payload) => {
    state.modeReportsData = { ...state.modeReportsData, ...payload };
  }),
  getModeGraphData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;
    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    return sdkReporting
      .getModeReporing(payload.site, startTimeStamp, endTimeStamp)
      .then((resp: any) => {
        const rows = resp?.reduce(
          (result: any, item: any, index: any) => {
            const keys = [
              "on_minutes_cool",
              "on_minutes_heat",
              "on_minutes_other",
            ];
            const point: any = { ...item };
            const orderedKeys = _.orderBy(keys, (o: any) => +item[o], "asc");
            point["a"] = { key: orderedKeys[0], val: item[orderedKeys[0]] };
            point["b"] = { key: orderedKeys[1], val: item[orderedKeys[1]] };
            point["c"] = { key: orderedKeys[2], val: item[orderedKeys[2]] };

            if (index === 0) {
              result.min = item.minTemp || 1000;
              result.max = item.maxTemp || -1000;
            }

            result.min = Math.min(
              result.min,
              isNaN(item.minTemp) ? Infinity : item.minTemp
            );
            result.max = Math.max(
              result.max,
              isNaN(item.maxTemp) ? -Infinity : item.maxTemp
            );
            const itemAvg = ((item.minTemp || 0) + (item.maxTemp)) / 2
            result.avg = !isNaN(itemAvg)
              ? (itemAvg + (result.avg || 0)) / 2
              : itemAvg;
            result.totalHeat = result.totalHeat + item.on_minutes_heat / 60;
            result.totalCool = result.totalCool + item.on_minutes_cool / 60;
            result.totalOther = result.totalOther + item.on_minutes_other / 60;

            point["maxTempF"] = point?.maxTemp
              ? (point?.maxTemp * 9) / 5 + 32
              : null;
            point["minTempF"] = point?.minTemp
              ? (point?.minTemp * 9) / 5 + 32
              : null;
            point["averageTemperatureF"] = point?.averageTemperature
              ? (point?.averageTemperature * 9) / 5 + 32
              : null;

            result.data.push(point);
            return result;
          },
          {
            data: [],
            avg: null,
            min: null,
            max: null,
            totalHeat: 0,
            totalCool: 0,
            totalOther: 0,
          }
        );

        rows.minF = (rows.min * 9) / 5 + 32;
        rows.maxF = (rows.max * 9) / 5 + 32;
        rows.avgF = (rows.avg * 9) / 5 + 32;

        const { data, ...restInfo } = rows;
        actions.setModeGraphData({
          date: payload.date,
          data,
          info: { ...restInfo },
        });
      });
  }),
  getMonthlyPowerData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;
    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    const fn = !!payload.siteId
      ? sdkReporting.siteMonthlyPowerConsumption(
        payload.siteId,
        startTimeStamp,
        endTimeStamp
      )
      : sdkReporting.customerMonthlyPowerConsumption(
        payload.customerId,
        startTimeStamp,
        endTimeStamp
      );
    return fn;
  }),
  getAlertsData: thunk((actions, payload) => {
    const { startDate, endDate } = payload.date;
    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    const fn = sdkReporting.getAlertsReport(
      payload.type,
      payload.id,
      startTimeStamp,
      endTimeStamp
    );
    return fn.then((resp: any) => {
      if (_.isEmpty(resp)) {
        return;
      }
      actions.setAlertsGraphData({ date: payload.date, data: resp });
    });
  }),
  setAlertsGraphData: action((state, payload) => {
    state.alertsGraphData = { ...state.alertsGraphData, ...payload };
  }),
};
