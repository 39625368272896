import React from "react";

const FanMid = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2939 5.70326C15.2939 5.06296 14.7495 4.61107 14.0483 4.72291C11.7898 5.24987 9.99982 7.24734 9.99982 9.62165C9.99982 11.9959 11.6743 13.9936 14.0483 14.5204C14.7099 14.6337 15.2939 14.1818 15.2939 13.54V5.70319V5.70326Z"
      stroke="white"
      strokeWidth="1.06667"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7057 26.2969C16.7057 26.9371 17.2502 27.3889 17.9514 27.2771C20.2099 26.7502 21.9999 24.7531 21.9999 22.3792C21.9999 19.9685 20.3253 18.0079 17.9514 17.4812C17.2898 17.3664 16.7057 17.7814 16.7057 18.4599V26.297V26.2969Z"
      stroke="white"
      strokeWidth="1.06667"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2965 15.2941C26.9368 15.2941 27.3887 14.7496 27.2769 14.0485C26.7499 11.7899 24.7525 10 22.3781 10C20.0038 10 18.0062 11.6745 17.4794 14.0485C17.3661 14.7101 17.818 15.2941 18.4598 15.2941H26.2966H26.2965Z"
      stroke="white"
      strokeWidth="1.06667"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70313 16.7059C5.06282 16.7059 4.61094 17.2504 4.72278 17.9515C5.24973 20.2101 7.24721 22 9.62151 22C11.9958 22 13.9934 20.3255 14.5202 17.9515C14.6336 17.2899 14.1817 16.7059 13.5399 16.7059H5.70313Z"
      stroke="white"
      strokeWidth="1.06667"
    />
  </svg>
);

export default FanMid;
