function SetbackDiagram(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 471 318"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="3ow41ro0da">
          <path d="M1020 0v875H0V0h1020z" />
        </clipPath>
        <clipPath id="oy95nkx2gb">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="s6uz4lt1oc">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="mi7626lrud">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="4lb9wea4oe">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="djhb51ry5f">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="qne7dehq0g">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="jdx9uag7bh">
          <path d="M2.85 0 5.7 4.774H0L2.85 0z" />
        </clipPath>
        <clipPath id="6klwh7rgyi">
          <path d="M470 0v317H0V0h470z" />
        </clipPath>
        <clipPath id="rz6owpc5uj">
          <path d="m2.892 0 2.893 4.71H0L2.892 0z" />
        </clipPath>
        <clipPath id="nlgycvvxyk">
          <path d="m2.892 0 2.893 4.71H0L2.892 0z" />
        </clipPath>
        <clipPath id="iell49u7nl">
          <path d="m2.892 0 2.893 4.71H0L2.892 0z" />
        </clipPath>
        <clipPath id="qm7v69v0vm">
          <path d="m2.892 0 2.893 4.71H0L2.892 0z" />
        </clipPath>
        <clipPath id="ijombi6osn">
          <path d="m2.892 0 2.893 4.71H0L2.892 0z" />
        </clipPath>
        <clipPath id="brzon8k6uo">
          <path d="m2.892 0 2.893 4.71H0L2.892 0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#3ow41ro0da)" transform="translate(-64 -408)">
        <text
          transform="translate(287.558 431.188)"
          font-family="Roboto-Regular, Roboto"
          font-size="10"
          fill="#7F7182"
          text-anchor="left"
        >
          Threshold Cool (Tc)
        </text>
        <path
          d="M104.403 506.278h382.106"
          stroke="#34A8E0"
          strokeWidth="1.333"
          fill="none"
          strokeLinecap="round"
          stroke-miterlimit="10"
          stroke-dasharray="1.777220606803894 4.443051338195801"
        />
        <path
          d="M132.18 453.963h353.71M132.18 659.033h353.71"
          stroke="#D5D2D5"
          strokeWidth="1.333"
          fill="none"
          strokeLinecap="round"
          stroke-miterlimit="10"
        />
        <path
          d="M132.18 578.73h353.95"
          stroke="#F05146"
          strokeWidth="1.333"
          fill="none"
          strokeLinecap="round"
          stroke-miterlimit="10"
          stroke-dasharray="1.777220606803894 4.443051338195801"
        />
        <path
          d="M252.246 420.018v288.54M431.015 420.018v288.54"
          stroke="#969696"
          strokeWidth="1.154"
          fill="none"
          strokeLinecap="square"
          stroke-miterlimit="10"
        />
        <path
          d="M131.803 573.836c7.453 8.149 13.988 12.223 19.606 12.223 8.427 0 8.427-20.848 18.024-20.848 9.597 0 9.597 20.848 16.543 20.848s11.367-16.241 15.905-16.241c4.537 0 8.46 15.086 13.567 15.086 5.107 0 12.958-13.6 16.941-13.6 3.984 0 3.717 13.6 10.361 13.6s5.367-8.879 10.891-8.879c10.083 0 11.93 90.51 26.832 90.51 14.903 0 9.777-32.72 23.035-32.72 13.257 0 9.947 35.302 20.873 35.302 10.926 0 9.93-37.093 22.455-37.093 12.526 0 15.561 34.51 28.41 34.51 22.815-1.525 44.2-100.746 56.501-100.738 12.302.01 10.452 20.12 17.42 20.263 6.969.143 7.554-19.983 13.291-20.263 5.737-.279 4.385 19.108 10.42 19.108 4.022 0 7.477-5.418 10.365-16.255"
          stroke="#F05146"
          strokeWidth="3.462"
          fill="none"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M338.252 473.872v-16.914"
          stroke="#535863"
          strokeWidth="1.154"
          fill="none"
          strokeLinecap="square"
          stroke-miterlimit="10"
        />
        <g clipPath="url(#oy95nkx2gb)" transform="translate(335.48 452.985)">
          <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
        </g>
        <g
          clipPath="url(#s6uz4lt1oc)"
          transform="matrix(1 0 0 -1 335.48 478.647)"
        >
          <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
        </g>
        <path
          d="M346.677 654.702v-16.915"
          stroke="#535863"
          strokeWidth="1.154"
          fill="none"
          strokeLinecap="square"
          stroke-miterlimit="10"
        />
        <g clipPath="url(#mi7626lrud)" transform="translate(343.904 633.814)">
          <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
        </g>
        <g
          clipPath="url(#4lb9wea4oe)"
          transform="matrix(1 0 0 -1 343.904 659.477)"
        >
          <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
        </g>
        <text
          transform="translate(152.961 474.71)"
          font-family="Roboto-Regular, Roboto"
          font-size="10"
          fill="#7F7182"
          text-anchor="left"
        >
          Room Temp
        </text>
        <text
          transform="translate(152.961 610.81)"
          font-family="Roboto-Regular, Roboto"
          font-size="10"
          fill="#7F7182"
          text-anchor="left"
        >
          Room Temp
        </text>
        <text transform="translate(86.706 568.175)">
          <tspan
            x="14.172"
            y="0"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#F05146"
            text-anchor="right"
          >
            Room{" "}
          </tspan>
          <tspan
            x="3.479"
            y="13.848"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#F05146"
            text-anchor="right"
          >
            Setpoint{" "}
          </tspan>
          <tspan
            x="19.367"
            y="27.697"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#F05146"
            text-anchor="right"
          >
            Heat
          </tspan>
        </text>
        <text transform="translate(84.224 472.578)">
          <tspan
            x="11.672"
            y="0"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#34A8E0"
            text-anchor="right"
          >
            Room{" "}
          </tspan>
          <tspan
            x=".979"
            y="13.848"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#34A8E0"
            text-anchor="right"
          >
            Setpoint{" "}
          </tspan>
          <tspan
            x="17.658"
            y="27.697"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#34A8E0"
            text-anchor="right"
          >
            Cool
          </tspan>
        </text>
        <text
          transform="translate(292.86 683.033)"
          font-family="Roboto-Regular, Roboto"
          font-size="10"
          fill="#7F7182"
          text-anchor="left"
        >
          Threshold Heat (Th)
        </text>
        <text
          transform="translate(132.525 707.569)"
          font-family="Roboto-Regular, Roboto"
          font-size="10"
          fill="#7F7182"
          text-anchor="left"
        >
          Occupied Room Unit on
        </text>
        <text
          transform="translate(311.893 709.87)"
          font-family="Roboto-Regular, Roboto"
          font-size="10"
          fill="#7F7182"
          text-anchor="left"
        >
          Unocupied
        </text>
        <path
          d="M119.551 578.807h7.069"
          stroke="#535863"
          strokeWidth="1.154"
          fill="none"
          strokeLinecap="square"
          stroke-miterlimit="10"
        />
        <g clipPath="url(#djhb51ry5f)" transform="rotate(-90 348.178 233.401)">
          <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
        </g>
        <g>
          <path
            d="M187.907 595.046v7.069"
            stroke="#535863"
            strokeWidth="1.154"
            fill="none"
            strokeLinecap="square"
            stroke-miterlimit="10"
          />
          <g
            clipPath="url(#qne7dehq0g)"
            transform="matrix(-1 0 0 1 190.68 590.272)"
          >
            <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
          </g>
        </g>
        <g>
          <path
            d="M190.602 487.601v-7.068"
            stroke="#535863"
            strokeWidth="1.154"
            fill="none"
            strokeLinecap="square"
            stroke-miterlimit="10"
          />
          <g
            clipPath="url(#jdx9uag7bh)"
            transform="matrix(1 0 0 -1 187.83 492.376)"
          >
            <path fill="#535863" d="M0 0h5.7v4.774H0V0z" />
          </g>
        </g>
        <g>
          <path
            d="M133.147 508.703c8.353-8.028 15.298-12.041 20.835-12.041 8.306 0 8.222 17.707 16.07 17.707 7.847 0 14.439-17.707 19.951-17.707 5.512 0 8.402 21.221 16.64 21.221 8.236 0 11.423-19.506 18.564-19.506s12.467 15.992 17.77 15.992c5.303 0 37.748-72.697 58.48-72.697s21.597 38.765 37.303 38.765c15.706 0 13.5-37.127 27.002-37.127 13.5 0 9.845 37.127 22.472 37.127 11.653 0 6.111-33.016 18.616-33.016 12.505 0 11.77 63.1 23.173 63.1 11.403 0 9.14-13.86 16.969-13.86 7.828 0 8.04 13.763 12.605 14.6 4.566.835 4.648-10.509 9.738-10.509 5.091 0 5.091 7.95 9.242 7.95 2.767 0 5.205-3.441 7.313-10.325"
            stroke="#34A8E0"
            strokeWidth="3.462"
            fill="none"
            strokeLinecap="round"
            stroke-miterlimit="10"
          />
        </g>
        <g clipPath="url(#6klwh7rgyi)" transform="translate(64.5 408.31)">
          <path fill="#F6F6F7" d="M0 0h470v317H0V0z" />
        </g>
        <g>
          <text
            transform="translate(306.097 429.947)"
            font-family="Roboto-Black, Roboto"
            font-size="10"
            font-weight="800"
            fill="#7F7182"
            text-anchor="left"
            letter-spacing=".1"
          >
            Threshold Cool (Tc)
          </text>
          <text transform="translate(84.931 439.054)">
            <tspan
              x="0"
              y="0"
              font-family="Roboto-Black, Roboto"
              font-size="10"
              font-weight="800"
              fill="#34A8E0"
              text-anchor="left"
              letter-spacing=".1"
            >
              Cool Setup{" "}
            </tspan>
            <tspan
              x="0"
              y="14"
              font-family="Roboto-Black, Roboto"
              font-size="10"
              font-weight="800"
              fill="#34A8E0"
              text-anchor="left"
              letter-spacing=".1"
            >
              Setpoint
            </tspan>
          </text>
          <text transform="translate(84.931 640.066)">
            <tspan
              x="0"
              y="0"
              font-family="Roboto-Black, Roboto"
              font-size="10"
              font-weight="800"
              fill="#F05146"
              text-anchor="left"
              letter-spacing=".1"
            >
              Heat SetBack{" "}
            </tspan>
            <tspan
              x="0"
              y="14"
              font-family="Roboto-Black, Roboto"
              font-size="10"
              font-weight="800"
              fill="#F05146"
              text-anchor="left"
              letter-spacing=".1"
            >
              Setpoint
            </tspan>
          </text>
          <path
            d="M85.822 506.618H513.12"
            stroke="#34A8E0"
            strokeWidth="1.333"
            fill="none"
            strokeLinecap="round"
            stroke-miterlimit="10"
            stroke-dasharray="1.777220606803894 4.443051338195801"
          />
          <path
            d="M150.77 455.019h361.997"
            stroke="#34A8E0"
            strokeWidth="1.333"
            fill="none"
            strokeLinecap="round"
            stroke-miterlimit="10"
          />
          <path
            d="M150.77 657.284h361.979"
            stroke="#F05146"
            strokeWidth="1.333"
            fill="none"
            strokeLinecap="round"
            stroke-miterlimit="10"
          />
          <path
            d="M85.822 578.079h426.901"
            stroke="#F05146"
            strokeWidth="1.333"
            fill="none"
            strokeLinecap="round"
            stroke-miterlimit="10"
            stroke-dasharray="1.777220606803894 4.443051338195801"
          />
          <path
            d="M271.314 421.546v290.088M452.76 421.546v290.088"
            stroke="#969696"
            strokeWidth="1.154"
            fill="none"
            strokeLinecap="square"
            stroke-miterlimit="10"
          />
          <path
            d="M155.092 573.252c7.438 8.037 13.96 12.056 19.565 12.056 8.41 0 8.41-20.563 17.986-20.563 9.577 0 9.577 20.563 16.509 20.563 6.931 0 11.343-16.019 15.87-16.019 4.528 0 8.444 14.88 13.54 14.88 5.095 0 12.93-13.415 16.905-13.415 3.975 0 3.71 13.415 10.339 13.415 6.63 0 5.355-8.757 10.868-8.757 10.062 0 11.904 89.271 26.776 89.271 14.871 0 9.756-32.273 22.986-32.273 13.229 0 9.926 34.82 20.829 34.82s9.908-36.586 22.408-36.586c12.5 0 15.528 34.04 28.35 34.04 22.767-1.506 44.106-99.37 56.382-99.361 12.275.008 10.429 19.844 17.383 19.985 6.954.14 7.538-19.71 13.263-19.985 5.725-.276 4.375 18.846 10.397 18.846 4.014 0 7.462-5.344 10.343-16.032"
            stroke="#F05146"
            strokeWidth="3.462"
            fill="none"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M361.213 474.656v-16.683"
            stroke="#535863"
            strokeWidth="1.154"
            fill="none"
            strokeLinecap="square"
            stroke-miterlimit="10"
          />
          <g clipPath="url(#rz6owpc5uj)" transform="translate(358.4 454.054)">
            <path fill="#535863" d="M0 0h5.785v4.71H0V0z" />
          </g>
          <g
            clipPath="url(#nlgycvvxyk)"
            transform="matrix(1 0 0 -1 358.4 479.365)"
          >
            <path fill="#535863" d="M0 0h5.785v4.71H0V0z" />
          </g>
          <path
            d="M369.11 653.012V636.33"
            stroke="#535863"
            strokeWidth="1.154"
            fill="none"
            strokeLinecap="square"
            stroke-miterlimit="10"
          />
          <g clipPath="url(#iell49u7nl)" transform="translate(366.297 632.41)">
            <path fill="#535863" d="M0 0h5.785v4.71H0V0z" />
          </g>
          <g
            clipPath="url(#qm7v69v0vm)"
            transform="matrix(1 0 0 -1 366.297 657.721)"
          >
            <path fill="#535863" d="M0 0h5.785v4.71H0V0z" />
          </g>
          <text
            transform="translate(173.957 475.502)"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#7F7182"
            text-anchor="left"
          >
            Room Temp
          </text>
          <text
            transform="translate(173.957 609.74)"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#7F7182"
            text-anchor="left"
          >
            Room Temp
          </text>
          <text transform="translate(84.931 555.834)">
            <tspan
              x="0"
              y="0"
              font-family="Roboto-Regular, Roboto"
              font-size="10"
              fill="#7F7182"
              text-anchor="left"
            >
              Room
            </tspan>
            <tspan
              x="0"
              y="14"
              font-family="Roboto-Regular, Roboto"
              font-size="10"
              fill="#7F7182"
              text-anchor="left"
            >
              Setpoint Heat
            </tspan>
          </text>
          <text transform="translate(84.931 485.064)">
            <tspan
              x="0"
              y="0"
              font-family="Roboto-Regular, Roboto"
              font-size="10"
              fill="#7F7182"
              text-anchor="left"
            >
              Room
            </tspan>
            <tspan
              x="0"
              y="14"
              font-family="Roboto-Regular, Roboto"
              font-size="10"
              fill="#7F7182"
              text-anchor="left"
            >
              Setpoint Cool
            </tspan>
          </text>
          <text
            transform="translate(312.995 681.975)"
            font-family="Roboto-Black, Roboto"
            font-size="10"
            font-weight="800"
            fill="#7F7182"
            text-anchor="left"
            letter-spacing=".1"
          >
            Threshold Heat (Th)
          </text>
          <text
            transform="translate(150.54 707.532)"
            font-family="Roboto-Regular, Roboto"
            font-size="10"
            fill="#7F7182"
            text-anchor="left"
          >
            Occupied Room Unit on
          </text>
          <g>
            <text
              transform="translate(345.19 707.532)"
              font-family="Roboto-Regular, Roboto"
              font-size="10"
              fill="#7F7182"
              text-anchor="left"
            >
              Unocupied
            </text>
          </g>
          <g>
            <path
              d="M208.904 594.172v6.972"
              stroke="#535863"
              strokeWidth="1.154"
              fill="none"
              strokeLinecap="square"
              stroke-miterlimit="10"
            />
            <g
              clipPath="url(#ijombi6osn)"
              transform="matrix(-1 0 0 1 211.718 589.463)"
            >
              <path fill="#535863" d="M0 0h5.785v4.71H0V0z" />
            </g>
          </g>
          <g>
            <path
              d="M211.639 488.197v-6.972"
              stroke="#535863"
              strokeWidth="1.154"
              fill="none"
              strokeLinecap="square"
              stroke-miterlimit="10"
            />
            <g
              clipPath="url(#brzon8k6uo)"
              transform="matrix(1 0 0 -1 208.825 492.906)"
            >
              <path fill="#535863" d="M0 0h5.785v4.71H0V0z" />
            </g>
          </g>
          <g>
            <path
              d="M155.092 509.01c8.336-7.918 15.267-11.876 20.792-11.876 8.289 0 8.204 17.464 16.036 17.464 7.83 0 14.408-17.464 19.909-17.464 5.5 0 8.385 20.93 16.604 20.93 8.22 0 11.4-19.238 18.526-19.238 7.126 0 12.44 15.772 17.733 15.772 5.291 0 37.667-71.703 58.356-71.703 20.688 0 21.551 38.236 37.224 38.236 15.674 0 13.473-36.62 26.945-36.62 13.473 0 9.824 36.62 22.425 36.62 11.628 0 6.098-32.565 18.577-32.565 12.479 0 11.744 62.237 23.124 62.237 11.38 0 9.122-13.67 16.933-13.67 7.812 0 8.022 13.575 12.578 14.4 4.557.824 4.638-10.365 9.719-10.365 5.08 0 5.08 7.842 9.222 7.842 2.761 0 5.194-3.395 7.297-10.184"
              stroke="#34A8E0"
              strokeWidth="3.462"
              fill="none"
              strokeLinecap="round"
              stroke-miterlimit="10"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default SetbackDiagram;
