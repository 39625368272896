import { makeStyles } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    display: "flex",
    width: "100%",
    ...placeBackgroundColor(theme.palette.background.topHeader),
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 26px 0 8px",
    height: "70px",
    minHeight: "70px",
    boxSizing: "border-box",
    overflowX: "auto",
    overflowY: "hidden",
    zIndex: 1
  },
  selectionMenu: {},
  // ---
  pathContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  pathItem: {
    fontSize: "1.25rem",
    color: "#79797d",
    fontFamily: "RobotoMedium",
    "&:last-child": {
      color: "#29132e",
      fontWeight: "bold"
    }
  },
  pathSeparator: {
    color: "#7f7692",
    fontSize: "1.025rem !important"
  },
  languageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  language: {
    textTransform: "none",
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "white",
    padding: 0,
    justifyContent: "flex-end"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  }
  ,
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px"
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  searchIcon: {
    transform: "scale(0.7)"
  }
}));

export default useStyles;
