import React from "react";

const rightAxis = ({ color = "#4B1C46" }) => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="a" d="M0 0h15v9H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M17.583 0H19v19.182H-.003v-1.417h17.586zM.013 2.804H11.33L9.57 1.043 10.57.04l3.472 3.47-3.472 3.474L9.57 5.983l1.762-1.762H.013z" />
      </g>
      <g transform="matrix(-1 0 0 1 16 8)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path d="M11.642 9c-1.033 0-2.036-.43-2.64-1.138-.829-.968-1.22-2.287-1.565-3.45-.6-2.026-.944-2.845-2.092-2.83-1.129 0-1.45.812-1.97 2.805-.33 1.27-.704 2.708-1.673 3.68-.474.477-1.061.728-1.698.728H0l.002-1.581h.002c.264 0 .481-.095.683-.298.668-.67.969-1.83 1.262-2.952C2.408 2.198 2.979.001 5.333.001 7.688-.05 8.325 2.165 8.85 3.935c.313 1.058.637 2.151 1.246 2.862.339.397.972.643 1.617.621.544-.017 1.005-.222 1.264-.563.265-.363.373-.653.56-1.858l1.463.26c-.206 1.317-.367 1.906-.865 2.583-.547.72-1.389 1.128-2.379 1.159L11.642 9" fill={color} mask="url(#b)" />
      </g>
    </g>
  </svg>


);

export default rightAxis;
