import * as React from 'react';

function SvgNote(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 20" {...props}>
      <g stroke="#AAA2AA" strokeWidth={1.6} fill="none" fillRule="evenodd">
        <path d="M16 2.8c.608 0 1.158.246 1.556.644.398.398.644.948.644 1.556h0v9.2c0 .608-.246 1.158-.644 1.556A2.193 2.193 0 0116 16.4h0-3.31l-1.923 2.137L8.843 16.4H3a2.193 2.193 0 01-1.556-.644A2.193 2.193 0 01.8 14.2h0V5c0-.608.246-1.158.644-1.556A2.193 2.193 0 013 2.8h13z" />
        <path strokeLinecap="round" d="M4.433 7.7h10.134M4.433 12.133h7.6" />
      </g>
    </svg>
  );
}

export default SvgNote;
