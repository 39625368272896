import React from "react";

function SvgExpandMore(props: any) {
    return (
        <svg width="1em" height="17px" viewBox="0 0 20 20" {...props}>
            <g fill="white" fillRule="evenodd">
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={.2}
                    d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"
                />
            </g>
        </svg>
    );
}

export default SvgExpandMore;
