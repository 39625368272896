import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    fontSize: "4rem",
    background: "coral",
    padding: "4rem",
    borderRadius: "10rem",
    marginBottom: "2rem"
  },

  personalDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px"
  },

  generateButton: {
    alignSelf: "center",
    padding: "0.6rem",
    marginTop: "5px"
  },

  customer: {
    margin: "0.5rem auto",
    paddingBottom: "1rem"
  },

  submit: {
    gridColumn: "2/3",
    justifyContent: "end"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  passwordTitle: {
    display: "flex",
    alignItems: "center"
  },
  operationModeContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    height: " 50px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  unitsMeasurementContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    height: " 50px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between"
    },
    marginTop: "10px"
  },
  thumb: {
    backgroundColor: theme.palette.primary.dark
  },
  track: {
    backgroundColor: theme.palette.primary.light
  },
  tempFontStyle: {
    color: "#4b1c46"
  },
  language: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: " 1px solid #d5d2d5",
    marginTop: "1rem",
    paddingLeft: "1rem",
    borderRadius: "6px",
    maxWidth: "380px"
  },

  selectFormat: {
    width: "100%",
    maxWidth: "initial"
  },

  selectFormatMargin: {
    // marginBottom: "10px",
  },

  selectLang: {
    marginRight: "1rem",
    marginBottom: ".5rem",
    marginTop: "0 !important",
    border: "none",
    "&:before": {
      borderColor: "white"
    },

    "&:after": {
      borderColor: "white"
    },
    width: "15rem",
    "&>div": {
      margin: "none"
    }
  },
  itemTitle: {
    display: "flex",
    flex: 2,
    justifyContent: "flex-start"
  },
  itemSwtich: {
    display: "flex",
    flex: 1,
    justifyContent: "center"
  },
  switchContainer: {
    width: "auto",
    minWidth: "200px"
  },
  switchOptionText: {
    width: "70px",
    display: "flex",
    justifyContent: "center"
  },
  passwordfield: {
    width: "300px"
  },
  field: {
    display: "flex",
    flexDirection: "column"

  },
  title: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em"
  },
  chips: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    whiteSpace: "pre-wrap"
  },
  chip: {
    margin: 2
  },
  expiresBox: {
    borderRadius: "6px",
    // border: "solid 1px #d5d2d5",
    // backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    height: "60px",
    marginBottom: "8px"
  },
  expireTitles: {
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    display: "flex",
    alignItems: "center"
    // /* color: rgba(0, 0, 0, 0.54)
  },
  calendarStyle: {
    fontSize: "24px",
    marginRight: "9px"
  },
  expiresLabel: {
    marginLeft: "11px",
    marginRight: "30px",
    whiteSpace: "nowrap"
  },
  checkboxStyle: {
    paddingRight: "5px",
    "& svg": {
      height: "28px",
      width: "28px"
    }
  },
  select: {
      width: "100%",
      height: "45px",
      backgroundColor: "#ffffff",
      borderRadius: "3px",
      border: "solid 1px #d5d2d5",
      "& div": {
        paddingTop: 0,
        paddingBottom: 0,
        height: "45px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "12px"
      },
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      marginTop: "3px"
  },
  arrowDownIcon: {
      top: 20,
      right: 11
  },
  profileTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#7f7182",
    marginTop: "16px",
    marginBottom: "45px"
  },
  headerStyle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e"
  },
  select1: {
      width: "100%",
      marginTop: "16px",
      height: "45px",
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderRadius: "3px",
        border: "solid 1px #d5d2d5"
      },
      "& div": {
        paddingTop: 0,
        paddingBottom: 0,
        height: "45px",
        display: "flex",
        alignItems: "center"
      },
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      marginBottom: 16
  },
  errorBorder: {
    border: "1px solid red"
  },
  autoComplete: {
    // height: 45,
    marginTop: "3px",
    "&:div": {
      height: 45
    }
  },
  clearIndicator: {
    padding: 12
  },
  inputRoot: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important", /// could be 3 if needed
    minHeight: 45
  },
  endAdornment: {
    top: "calc(50% - 21px)",
    right: "5px !important"
  },
  paper: {
    boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
  },
  addTopMargin: {
    marginTop: "40px"
  }
}));

export default useStyles;
