import { alpha, createStyles } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    dialogStyle: {
      padding: "31px 26px",
      minWidth: "276px",
      borderRadius: "0.5rem",
      height: "auto"
    },
    dialogTitle: {
      padding: 0,
      fontSize: "26px",
      fontFamily: "RobotoMedium",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#4b1c46",
      marginBottom: "13px"
    },
    dialogButtons: {
      color: "#686868",
      fontWeight: "bold"
    },
    titleStyle: {
      fontSize: "26px",
      fontWeight: "bold",
      lineHeight: "normal",
      color: "#4b1c46"
    },
    contentStyle: {
      fontSize: "18px",
      color: "#545964",
      lineHeight: "normal",
      fontWeight: "normal",
      margin: 0
    },
    warning: {
      backgroundColor: "#FFA000",
      "&:hover": {
        letterSpacing: "normal",
        color: "#fff",
        backgroundColor: alpha("#FFA000", 0.8)
      },
      "&:focus": {
        letterSpacing: "normal",
        color: "#fff",
        backgroundColor: alpha("#FFA000", 0.8),
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    error: {},
    success: {
      backgroundColor: "#43A047",
      "&:hover": {
        letterSpacing: "normal",
        color: "#fff",
        backgroundColor: alpha("#43A047", 0.8)
      },
      "&:focus": {
        letterSpacing: "normal",
        color: "#fff",
        backgroundColor: alpha("#43A047", 0.8),
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    info: {
      backgroundColor: "#1976D2",
      "&:hover": {
        letterSpacing: "normal",
        color: "#fff",
        backgroundColor: alpha("#1976D2", 0.8)
      },
      "&:focus": {
        letterSpacing: "normal",
        color: "#fff",
        backgroundColor: alpha("#1976D2", 0.8),
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    confirmButtonStyle: {
      color: "#545964",
      border: `solid 1px #4b1c46`,
      width: "129px",
      boxShadow: "none"
    },
    cancelButtonStyle: { width: "129px" },
    dialogTitleText: {
      marginLeft: "10px"
    },
    dialogContentStyle: { padding: 0, marginBottom: "26px" },
    dialogActionStyle: { padding: 0, margin: 0 },
    actionsSpacing: { "& :not(:first-child)": { marginLeft: "15px" } },
    normal: {
      background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
      boxShadow: "none"
    }

  });
