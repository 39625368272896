import {
  Collapse,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Close, ExpandMore, LinkOff, Sort } from "@material-ui/icons";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import CoolButton from "../../cool_widgets/Button";
import { Arrow as SvgArrow, SortUp } from "../../icons";
import { EditIcon, DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AddEditSystem.style";
import AutoMapping from "./AutoMapping";
import ErrorBox from "../../components/WarningBox/ErrorBox";

const typesInfo: any = {
  ODU: t`Outdoor Units`,
  IDU: t`Indoor Units`,
  srv: t`Service Units`,
  BS: t`BsBox Units`
};

const AssignLineUnits = (props: any) => {
  const classes = useStyles();
  const {
    selectedDevice,
    selectedLine,
    actions,
    refreshUnits,
    reCallMappingData,
    setRefreshUnits,
    isService,
    selectedUnit,
    unitType
  } = props;
  const unitTypesMirror = useStoreState((state) => state.unitTypesMirrror);
  const getDeviceUnits = useStoreActions(
    (actions) => actions.devices.getDeviceUnitsAPI
  );
  const getDeviceSystems = useStoreActions(
    (actions) => actions.devices.getDeviceSystemsAPI
  );
  const deleteUnitAPI = useStoreActions((actions) => actions.units.deleteUnit);
  const addUnitToSys = useStoreActions(
    (actions) => actions.units.updateUnitSystem
  );
  const updateUnitLocally = useStoreActions(
    (actions) => actions.units.updateUnitLocally
  );
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const updateUnits = useStoreActions((actions) => actions.units.updateUnits);
  const getDeviceLines = useStoreActions((actions) => actions.devices.getDeviceLines);
  const types = useStoreState((state) => state.types);

  const [deviceSystems, setDeviceSystems] = useState<any>([]);
  const [units, setUnits] = useState<any>({ all: {}, ODU: {}, IDU: {}, srv: {}, BS: {} });
  const [expand, setExpand] = useState<any>({ IDU: true, ODU: true, BS: true, srv: true });
  const [sorting, setSorting] = useState<any>({ IDU: { by: "name", isAsc: true }, ODU: { by: "name", isAsc: true }, srv: { by: "name", isAsc: true }, BS: { by: "name", isAsc: true } });
  const [line, setLine] = useState<any>(null);
  const [lines, setLines] = useState<any>({});
  const [activatedMappingProgress, activateMappingProgress] =
    useState<boolean>(false);
  const [numOfUnits, setNumOfUnits] = useState<number>(0);
  const [refreshLineData, setRefreshLineData] = useState<boolean>(false);
  const { canUpdate = true } = line || {};

  const allUnits = useStoreState((state) => state.units.allUnits);
  const [disrcardPopup, setDisrcardPopup] = useState<boolean>(false);

  const [indoorUnitsDirty, setIndoorUnitsDirty] = useState<boolean>(false);
  const [outdoorUnitsDirty, setOutdoorUnitsDirty] = useState<boolean>(false);
  const [bsUnitsDirty, setBSUnitsDirty] = useState<boolean>(false);
  const [srvUnitsDirty, setSrvUnitsDirty] = useState<boolean>(false);

  const indoorUnitsformikRef = useRef<any>(null);
  const outdoorUnitsformikRef = useRef<any>(null);
  const bsUnitsformikRef = useRef<any>(null);
  const srvUnitsformikRef = useRef<any>(null);

  useEffect(() => {
    getDeviceLines(selectedDevice).then((res: any) => {
      //get system line data for automapping status (only for Daikin)
      let systemLine: any = null;
      for (let i in res) {
        const line = res[i];
        if (line.id === +selectedLine) {
          systemLine = line;
          break;
        }
      }

      if (systemLine?.brand === 1) {
        setLine(systemLine);
        //set counter to keep pulling mapping status if already in progress
        if (systemLine?.isMapping) {
          activateMappingProgress(true);
        }
      }
      setLines(res);
    });
  }, []);

  useEffect(() => {
    let timer: any = null;
    if (!activatedMappingProgress) {
      clearInterval(timer);
      timer = null;
      return;
    }

    timer = setInterval(() => {
      getDeviceLines(selectedDevice).then((res: any) => {
        let systemLine = null;
        for (let i in res) {
          const line = res[i];
          if (line.id === +selectedLine) {
            systemLine = line;
            break;
          }
        }

        setLine(systemLine);
        if (!systemLine?.isMapping) {
          setRefreshLineData(!refreshLineData);
          setTimeout(() => {
            activateMappingProgress(false);
          }, 2000);
        }
        actions.fetchData();
      });
    }, 30000);

    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, [activatedMappingProgress]);

  useEffect(() => {
    if (!selectedDevice || !selectedLine) {
      return;
    }

    let assoLine = 999990000;
    const selectedLineObj: any = Object.values(lines).filter(
      (line: any) => +line.id === +selectedLine
    );
    const assoLineObj =
      selectedLineObj?.length &&
      Object.values(lines).filter(
        (line: any) => line.controlLine === selectedLineObj[0]?._id
      );
    if (assoLineObj?.length) {
      assoLine = assoLineObj[0].id;
    }

    getDeviceSystems({ id: selectedDevice })
      .then((res: any) => {
        const systems = Object.values(res).filter((sys: any) => +sys.line === +selectedLine || +sys.line === +assoLine);
        const sysOptions = systems.map((sys: any) => <MenuItem key={`sysOption-${sys.id}`} value={sys.id}>{sys.name}</MenuItem>);
        setDeviceSystems([<MenuItem value={""} disabled key={`unassigned000`}>{t`Unassigned`}</MenuItem>, ...sysOptions]);
      });
  }, [selectedDevice, selectedLine, refreshLineData, lines, allUnits]);

  useEffect(() => {
    if (!selectedDevice || !selectedLine) {
      return;
    }

    getDeviceUnits({ id: selectedDevice, line: selectedLine }).then(
      (lineUnits: any) => {
        const unitsData: any = Object.values(lineUnits).reduce(
          (result: any, item: any) => {
            if (!!item.system) {
              return result;
            }

            if (unitTypesMirror[item.type] === "indoor") {
              result.IDU[item.id] = item;
            }
            if (unitTypesMirror[item.type] === "outdoor") {
              result.ODU[item.id] = item;
            }
            if (unitTypesMirror[item.type] === "bsBox") {
              result.BS[item.id] = item;
            }
            if (unitTypesMirror[item.type] === "service") {
              result.srv[item.id] = item;
              result.serviceCount += 1;
            }

            return result;
          },
          { ODU: {}, IDU: {}, srv: {}, BS: {}, serviceCount: 0 }
        );

        if (+unitType === +types.unitTypes["service"]) {
          setExpand({ IDU: false, ODU: false, BS: false, srv: true });
        } else {
          //(by default) first block must be expanded and not collpased on first load
          for (let key in unitsData) {
            if (!_.isEmpty(unitsData[key])) {
              setExpand({ ...expand, [key]: true });
              break;
            }
          }
        }
        setUnits({ all: lineUnits, ...unitsData });
        setNumOfUnits(unitsData.serviceCount);
      });
  }, [selectedDevice, selectedLine, refreshUnits, refreshLineData, allUnits]);

  const isOpen = selectedDevice && selectedLine;

  const deleteUnit = (id: string, type: string) => {
    deleteUnitAPI(id)
      .then(() => {
        const { [id]: removeAll, ...restAll } = units.all;
        const { [id]: remoteType, ...restType } = units[type];

        setUnits({
          ...units,
          all: { ...restAll },
          [type]: { ...restType }
        });
      })
      .finally(() => actions.fetchData());
  };

  const updateSort = (type: string, column: string) => {
    const sortObj: any = sorting[type];
    if (sortObj.by === column) {
      sortObj.isAsc = !sortObj.isAsc;
    } else {
      sortObj.by = column;
      sortObj.isAsc = true;
    }

    setSorting({ ...sorting, [type]: { ...sortObj } });
  };


  const handleClose = () => {
    if (_.isEmpty(units.IDU) && _.isEmpty(units.ODU) && _.isEmpty(units.BS) && _.isEmpty(units.srv))
      return actions.openCloseAssignLineUnits(null, null);
    if (indoorUnitsDirty || outdoorUnitsDirty || bsUnitsDirty || srvUnitsDirty)
      setDisrcardPopup(true);
    else
      actions.openCloseAssignLineUnits(null, null);
  };

  // const handleUpdateUnits = () => {
  //   if (isDirty && formikRef.current) { formikRef.current.handleSubmit() }
  //   actions.openCloseAssignLineUnits(null, null);
  // }

  const handleUpdateUnits = () => {
    if (indoorUnitsDirty && indoorUnitsformikRef.current) { indoorUnitsformikRef.current.handleSubmit() }
    if (outdoorUnitsDirty && outdoorUnitsformikRef.current) { outdoorUnitsformikRef.current.handleSubmit() }
    if (bsUnitsDirty && bsUnitsformikRef.current) { bsUnitsformikRef.current.handleSubmit() }
    if (srvUnitsDirty && srvUnitsformikRef.current) { srvUnitsformikRef.current.handleSubmit() }
    actions.openCloseAssignLineUnits(null, null)
  }



  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={!!isOpen}
      maxWidth="lg"
      fullWidth
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Map units to systems of line number ` + selectedLine}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}><Close style={{ color: "#7f7692" }} /></IconButton>
      </div>
      {line && isService && (
        <div className={classes.autoMappingLine}>
          <AutoMapping
            disabled={!canUpdate}
            deviceId={selectedDevice}
            line={line}
            numOfUnits={numOfUnits}
            activatedMappingProgress={activatedMappingProgress}
            activateMappingProgress={activateMappingProgress}
            actions={actions}
            lineControlUnits={Object.keys(units?.IDU)?.length || 0}
            lineControlUnitsNotAssociated={Object.keys(units?.IDU)?.length || 0}
          />
        </div>
      )}
      <div className={classes.dialogContent}>
        {/* //////////////////////////////////////////// IDUs */}
        {!_.isEmpty(units.IDU) && <div className={classes.unitsBox} key={`boxtype-IDU`}>
          <div className={classes.sectionTitle} onClick={() => setExpand({ ...expand, IDU: !expand.IDU })}>
            <ExpandMore
              style={{
                transform: expand.IDU ? "rotate(0deg)" : "rotate(-90deg)",
                marginRight: "7px",
                transitionDuration: "0.5s",
                transitionProperty: "transform"
              }}
            />
            <Typography>{`${typesInfo['IDU']} (${Object.keys(units['IDU'])?.length})`}</Typography>
          </div>
          <Collapse in={expand.IDU} timeout="auto" unmountOnExit>
            <Formik
              innerRef={indoorUnitsformikRef}
              initialValues={{ items: _.orderBy(Object.values(units.IDU), [sorting.IDU.by], [sorting.IDU.isAsc ? "asc" : "desc"]) }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const updatedUnits = _.differenceWith(values.items, Object.values(units.IDU), _.isEqual).map((unit: any) => {
                  const temp: any = {
                    unitId: unit.id,
                    name: unit.name,
                  }
                  if (unit.system) {
                    temp.system = unit.system;
                  }
                  return temp;
                });
                updateUnits({ unitsArr: updatedUnits })
              }}
            >
              {({ dirty, values }) => (
                // @ts-ignore
                <Form style={{ width: "100%" }}>
                  {setIndoorUnitsDirty(dirty)}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{
                          width: "15%",
                          minWidth: "130px"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('IDU', "name")}>
                            {t`Unit Name`}
                            {sorting['IDU'].by === "name" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['IDU'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('IDU', "address")}>
                            {t`Address`}
                            {sorting['IDU'].by === "address" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['IDU'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('IDU', "capacity")}>
                            {t`Capacity`}
                            {sorting['IDU'].by === "capacity" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['IDU'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`Model`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`SN`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`System`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {
                        values.items.map((unit: any, index: any) => {
                          const { canUpdate = true, canDelete = true } = unit || {};
                          return (
                            <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow)}>
                              <TableCell
                                style={{
                                  borderTop: "1px solid rgba(224, 224, 224, 1)"
                                }}>
                                <div className={classes.disconnectedIconParent}>
                                  <Field name={`items[${index}].name`} >
                                    {({ field, form, meta }: any) => {
                                      if (canUpdate) {
                                        return <TextField {...field} variant="outlined" size="small" />;
                                      }
                                      return <Typography>{unit.name}</Typography>;
                                    }}
                                  </Field>
                                  {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                </div>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].address`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.address}(${unit.airnet})`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].capacity`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.capacity} hp`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].model`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.model}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].serialNumber`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.serialNumber}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].system`}>
                                  {({ field, form, meta }: any) => {
                                    if (canUpdate) {
                                      return (
                                        <Select
                                          error={meta.error}
                                          variant="outlined"
                                          className={classes.input}
                                          IconComponent={SvgArrow}
                                          {...field}
                                          displayEmpty
                                          value={field.value || ""}
                                        >
                                          {[...deviceSystems]}
                                        </Select>
                                      );
                                    }
                                    return <Typography>{t`Unassigned`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>

                              <TableCell>
                                {!unit.isConnected &&
                                  <IconButton disabled={!canDelete} onClick={() => deleteUnit(unit.id, 'IDU')}>
                                    <DeleteIcon />
                                  </IconButton>}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </Form>
              )}
            </Formik>
          </Collapse>
        </div>}

        {/* //////////////////////////////////////////// ODUs */}
        {!_.isEmpty(units.ODU) && <div className={classes.unitsBox} key={`boxtype-ODU`}>
          <div className={classes.sectionTitle} onClick={() => setExpand({ ...expand, ODU: !expand.ODU })}>
            <ExpandMore
              style={{
                transform: expand.ODU ? "rotate(0deg)" : "rotate(-90deg)",
                marginRight: "7px",
                transitionDuration: "0.5s",
                transitionProperty: "transform"
              }}
            />
            <Typography>{`${typesInfo['ODU']} (${Object.keys(units['ODU'])?.length})`}</Typography>
          </div>
          <Collapse in={expand.ODU} timeout="auto" unmountOnExit>
            <Formik
              innerRef={outdoorUnitsformikRef}
              initialValues={{ items: _.orderBy(Object.values(units.ODU), [sorting.ODU.by], [sorting.ODU.isAsc ? "asc" : "desc"]) }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const updatedUnits = _.differenceWith(values.items, Object.values(units.ODU), _.isEqual).map((unit: any) => ({
                  unitId: unit.id,
                  name: unit.name,
                  system: unit.system
                }));
                updateUnits({ unitsArr: updatedUnits })
              }}
            >
              {({ dirty, values }) => (
                // @ts-ignore
                <Form style={{ width: "100%" }}>
                  {setOutdoorUnitsDirty(dirty)}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{
                          width: "15%",
                          minWidth: "130px"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('ODU', "name")}>
                            {t`Unit Name`}
                            {sorting['ODU'].by === "name" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['ODU'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('ODU', "address")}>
                            {t`Address`}
                            {sorting['ODU'].by === "address" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['ODU'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('ODU', "capacity")}>
                            {t`Capacity`}
                            {sorting['ODU'].by === "capacity" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['ODU'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`Model`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`SN`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`System`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {
                        values.items.map((unit: any, index: any) => {
                          const { canUpdate = true, canDelete = true } = unit || {};
                          return (
                            <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow)}>
                              <TableCell
                                style={{
                                  borderTop: "1px solid rgba(224, 224, 224, 1)"
                                }}>
                                <div className={classes.disconnectedIconParent}>
                                  <Field name={`items[${index}].name`} >
                                    {({ field, form, meta }: any) => {
                                      if (canUpdate) {
                                        return <TextField {...field} variant="outlined" size="small" />;
                                      }
                                      return <Typography>{unit.name}</Typography>;
                                    }}
                                  </Field>
                                  {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                </div>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].address`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.address}(${unit.airnet})`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].capacity`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.capacity} hp`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].model`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.model}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].serialNumber`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.serialNumber}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].system`}>
                                  {({ field, form, meta }: any) => {
                                    if (canUpdate) {
                                      return (
                                        <Select
                                          error={meta.error}
                                          variant="outlined"
                                          className={classes.input}
                                          IconComponent={SvgArrow}
                                          {...field}
                                          displayEmpty
                                          value={field.value || ""}
                                        >
                                          {[...deviceSystems]}
                                        </Select>
                                      );
                                    }
                                    return <Typography>{t`Unassigned`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>

                              <TableCell>
                                {!unit.isConnected &&
                                  <IconButton disabled={!canDelete} onClick={() => deleteUnit(unit.id, 'ODU')}>
                                    <DeleteIcon />
                                  </IconButton>}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </Form>
              )}
            </Formik>
          </Collapse>
        </div>}
        {/* //////////////////////////////////////////// SRVs */}
        {!_.isEmpty(units.srv) && <div className={classes.unitsBox} key={`boxtype-srv`}>
          <div className={classes.sectionTitle} onClick={() => setExpand({ ...expand, srv: !expand.srv })}>
            <ExpandMore
              style={{
                transform: expand.srv ? "rotate(0deg)" : "rotate(-90deg)",
                marginRight: "7px",
                transitionDuration: "0.5s",
                transitionProperty: "transform"
              }}
            />
            <Typography>{`${typesInfo['srv']} (${Object.keys(units['srv'])?.length})`}</Typography>
          </div>
          <Collapse in={expand.srv} timeout="auto" unmountOnExit>
            <Formik
              innerRef={srvUnitsformikRef}
              initialValues={{ items: _.orderBy(Object.values(units.srv), [sorting.srv.by], [sorting.srv.isAsc ? "asc" : "desc"]) }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const updatedUnits = _.differenceWith(values.items, Object.values(units.srv), _.isEqual).map((unit: any) => ({
                  unitId: unit.id,
                  name: unit.name,
                  system: unit.system
                }));
                updateUnits({ unitsArr: updatedUnits })
              }}
            >
              {({ dirty, values }) => (
                // @ts-ignore
                <Form style={{ width: "100%" }}>
                  {setSrvUnitsDirty(dirty)}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{
                          width: "15%",
                          minWidth: "130px"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('srv', "name")}>
                            {t`Unit Name`}
                            {sorting['srv'].by === "name" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['srv'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('srv', "address")}>
                            {t`Address`}
                            {sorting['srv'].by === "address" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['srv'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('srv', "capacity")}>
                            {t`Capacity`}
                            {sorting['srv'].by === "capacity" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['srv'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`Model`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`SN`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`System`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {
                        values.items.map((unit: any, index: any) => {
                          const { canUpdate = true, canDelete = true } = unit || {};
                          return (
                            <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow)}>
                              <TableCell
                                style={{
                                  borderTop: "1px solid rgba(224, 224, 224, 1)"
                                }}>
                                <div className={classes.disconnectedIconParent}>
                                  <Field name={`items[${index}].name`} >
                                    {({ field, form, meta }: any) => {
                                      if (canUpdate) {
                                        return <TextField {...field} variant="outlined" size="small" />;
                                      }
                                      return <Typography>{unit.name}</Typography>;
                                    }}
                                  </Field>
                                  {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                </div>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].address`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.address}(${unit.airnet})`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].capacity`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.capacity} hp`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].model`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.model}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].serialNumber`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.serialNumber}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].system`}>
                                  {({ field, form, meta }: any) => {
                                    if (canUpdate) {
                                      return (
                                        <Select
                                          error={meta.error}
                                          variant="outlined"
                                          className={classes.input}
                                          IconComponent={SvgArrow}
                                          {...field}
                                          displayEmpty
                                          value={field.value || ""}
                                        >
                                          {[...deviceSystems]}
                                        </Select>
                                      );
                                    }
                                    return <Typography>{t`Unassigned`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>

                              <TableCell>
                                {!unit.isConnected &&
                                  <IconButton disabled={!canDelete} onClick={() => deleteUnit(unit.id, 'srv')}>
                                    <DeleteIcon />
                                  </IconButton>}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </Form>
              )}
            </Formik>
          </Collapse>
        </div>}
        {/* //////////////////////////////////////////// BS */}
        {!_.isEmpty(units.BS) && <div className={classes.unitsBox} key={`boxtype-BS`}>
          <div className={classes.sectionTitle} onClick={() => setExpand({ ...expand, BS: !expand.BS })}>
            <ExpandMore
              style={{
                transform: expand.BS ? "rotate(0deg)" : "rotate(-90deg)",
                marginRight: "7px",
                transitionDuration: "0.5s",
                transitionProperty: "transform"
              }}
            />
            <Typography>{`${typesInfo['BS']} (${Object.keys(units['BS'])?.length})`}</Typography>
          </div>
          <Collapse in={expand.BS} timeout="auto" unmountOnExit>
            <Formik
              innerRef={bsUnitsformikRef}
              initialValues={{ items: _.orderBy(Object.values(units.BS), [sorting.BS.by], [sorting.BS.isAsc ? "asc" : "desc"]) }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const updatedUnits = _.differenceWith(values.items, Object.values(units.BS), _.isEqual).map((unit: any) => ({
                  unitId: unit.id,
                  name: unit.name,
                  system: unit.system
                }));
                updateUnits({ unitsArr: updatedUnits })
              }}
            >
              {({ dirty, values }) => (
                // @ts-ignore
                <Form style={{ width: "100%" }}>
                  {setBSUnitsDirty(dirty)}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{
                          width: "15%",
                          minWidth: "130px"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('BS', "name")}>
                            {t`Unit Name`}
                            {sorting['BS'].by === "name" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['BS'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('BS', "address")}>
                            {t`Address`}
                            {sorting['BS'].by === "address" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['BS'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          <div className={classes.tableHeadCont} onClick={() => updateSort('BS', "capacity")}>
                            {t`Capacity`}
                            {sorting['BS'].by === "capacity" ?
                              <SortUp className={classes.sortIconBase} flip={sorting['BS'].isAsc} /> :
                              <Sort className={classes.sortIconBase} />
                            }
                          </div>

                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`Model`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`SN`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                          {t`System`}
                        </TableCell>
                        <TableCell style={{
                          width: "15%"
                        }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {
                        values.items.map((unit: any, index: any) => {
                          const { canUpdate = true, canDelete = true } = unit || {};
                          return (
                            <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow)}>
                              <TableCell
                                style={{
                                  borderTop: "1px solid rgba(224, 224, 224, 1)"
                                }}>
                                <div className={classes.disconnectedIconParent}>
                                  <Field name={`items[${index}].name`} >
                                    {({ field, form, meta }: any) => {
                                      if (canUpdate) {
                                        return <TextField {...field} variant="outlined" size="small" />;
                                      }
                                      return <Typography>{unit.name}</Typography>;
                                    }}
                                  </Field>
                                  {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                </div>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].address`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.address}(${unit.airnet})`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].capacity`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{`${unit.capacity} hp`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].model`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.model}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].serialNumber`}>
                                  {({ field, form, meta }: any) => {
                                    return <Typography>{unit.serialNumber}</Typography>;
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell>
                                <Field name={`items[${index}].system`}>
                                  {({ field, form, meta }: any) => {
                                    if (canUpdate) {
                                      return (
                                        <Select
                                          error={meta.error}
                                          variant="outlined"
                                          className={classes.input}
                                          IconComponent={SvgArrow}
                                          {...field}
                                          displayEmpty
                                          value={field.value || ""}
                                        >
                                          {[...deviceSystems]}
                                        </Select>
                                      );
                                    }
                                    return <Typography>{t`Unassigned`}</Typography>;
                                  }}
                                </Field>
                              </TableCell>

                              <TableCell>
                                {!unit.isConnected &&
                                  <IconButton disabled={!canDelete} onClick={() => deleteUnit(unit.id, 'BS')}>
                                    <DeleteIcon />
                                  </IconButton>}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </Form>
              )}
            </Formik>
          </Collapse>
        </div>}
      </div>
      <div className={classes.modalActions}>
        <CoolButton
          title={t`close edit`}
          variant="contained"
          onClick={handleClose}
          width={150}
          white
          marginRight
        >
          {t`Close`}
        </CoolButton>

        <CoolButton
          title={t`Save edit`}
          variant="contained"
          onClick={handleUpdateUnits}
          width={150}
          marginRight
          disabled={
            (!indoorUnitsDirty && !outdoorUnitsDirty && !bsUnitsDirty && !srvUnitsDirty) ||
            (_.isEmpty(units.IDU) && _.isEmpty(units.ODU) && _.isEmpty(units.BS) && _.isEmpty(units.srv))
          }
        >
          {t`Save`}
        </CoolButton>
      </div>

      {
        disrcardPopup && <ErrorBox
          title={t`Discard Changes`}
          error={"Are you sure you want to close without saving changes?"}
          cancelTitle={t`Yes`}
          acceptTitle={t`No`}
          onClose={() => actions.openCloseAssignLineUnits(null, null)}
          onAccept={() => setDisrcardPopup(false)}
        />
      }

    </Dialog>
  );
};

export default AssignLineUnits;
