import React from "react";

const SvgSwingCopy = (props) => (
  <svg width={40} height={40} {...props}>
    <g fill="none" fillRule="evenodd" opacity={props.opacity}>
      <g
        stroke={props.color2 || "#fff"}
        strokeLinecap="round"
        strokeWidth={1.8}
      >
        <path d="M8.962 32.608A20.67 20.67 0 0032.424 9.146m-20.04 27.987l-4.412-4.469 4.476-4.42" />
        <path d="M27.976 12.66l4.469-4.412 4.412 4.483" />
      </g>
      <path fill={props.color1 || "#FDFDFE"} d="M3 3v9a9 9 0 009-9H3z" />
      <path
        stroke={props.color1 || "#FDFDFE"}
        strokeLinecap="round"
        strokeWidth={2}
        d="M4 16v15M16 4h15"
      />
    </g>
  </svg>
);

export default SvgSwingCopy;
