import { alpha } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      margin: "auto"
    },
    dayStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "41px",
      borderRadius: "6px",
      backgroundColor: alpha("rgb(255,255,255)", 0.1),
      margin: "4px",
      minWidth: "33px",
      maxWidth: "12%",
      width: "50px",
      flex: 1,
      "& *": {
        color: "white",
        fontSize: "14px"
      },
      cursor: "pointer"
    },
    activeDayStyle: {
      backgroundColor: theme.palette.secondary.main
    },
    disableClickPointer: {
      cursor: "unset"
    },
    notWhite: {
      background: "#eeecee",
      "& *": {
        color: theme.overrides.MuiButton.outlined.color
      }
    },
    notWhiteActiveDayStyle: {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color,
      "& *": {
        color: "white"
      }
    }
  });
