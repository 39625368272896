import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStoreState } from "../../models/RootStore";
import datesListStyles from "./DatesList.style";

const DatesList = (props: any) => {
  const useStyles = makeStyles(datesListStyles);
  const classes = useStyles();
  const { dateFormat } = useStoreState((state) => state.users);
  const dayDateFormat = `dddd  ${dateFormat}`;

  const [aggregatedDates, setAggregatedDates] = useState<any>([]);
  const { dates = [], white = true, customClass } = props;

  useEffect(() => {
    if (!dates) {
      return;
    }
    const sortedDates = dates.map((date: any) => new Date(date));
    sortedDates.sort((a: any, b: any) => a - b);
    let lastMin = sortedDates[0];
    let tempDates = [];
    lastMin && tempDates.push([lastMin]);
    let rangeCount = 0;

    setAggregatedDates([...tempDates]);

    for (let i = 0; i < sortedDates.length; i++) {
      if (i < sortedDates.length - 1) {
        const daysDiff = Math.round((sortedDates[i + 1] - sortedDates[i]) / (1000 * 60 * 60 * 24));
        if (daysDiff === 1) {
          tempDates[rangeCount].push(sortedDates[i + 1]);
        } else {
          tempDates.push([sortedDates[i + 1]]);
          rangeCount++;
        }
      }
    }
    setAggregatedDates(tempDates);
  }, [dates]);

  return (
    <div className={classes.container}>
      {aggregatedDates.map((datesRange: any, index: number) => {
        return (
          <div
            className={clsx(classes.dateStyle, { [classes.notWhiteDate]: !white, [classes.purpleBg]: white, [customClass]: !!customClass })}
            key={index}
          >
            {datesRange?.length === 1 ?
              <Typography className={clsx({ [classes.textStyle]: white })}>{moment(datesRange[0]).format(dayDateFormat)}</Typography>
              : <Typography className={clsx({ [classes.textStyle]: white })}>{moment(datesRange[0]).format(dateFormat)} - {moment(datesRange[datesRange?.length - 1]).format(dateFormat)}</Typography>}
          </div>
        );
      })}
    </div>
  );
};

export default DatesList;
