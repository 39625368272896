import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
({
  dialogStyle: { width: 550, height: 350, padding: 10 },

  tableCell: {
    fontSize: "0.8125rem !important",
    padding: 0,
    textAlign: "center",
    height: "45px",
    "&:last-child": {
      paddingRight: "20px"
    }
  },
  valueTitle: {
    marginBottom: "5px",
    display: "flex"
  },
  tableCellAlignLeft: {
    fontSize: "0.8125rem !important",
    padding: "0 10px",
    paddingRight: "15px",
    textAlign: "left",
    width: "1%",
    whiteSpace: "nowrap"
  },
  nameCell: {
    fontSize: "0.8125rem !important",
    padding: "0 10px",
    paddingRight: "15px",
    textAlign: "left",
    width: "25ch",
    minWidth: "25ch",
    maxWidth: "25ch",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },

  "tableCell:last-child": {
    paddingRight: "1rem"
  },

  "tableCell:nth-last-child(2)": {
    paddingRight: "2rem"
  },

  tableRowUnplottable: {
    backgroundColor: "rgb(249, 249, 249)"
  },

  title: {
    padding: "0.5rem 0",
    color: "#4b1c46"
  },

  lastUpdatedTitle: {
    fontSize: "0.7rem",
    color: "#aaa2aa",
    marginRight: "5px"
  },

  thumbValue: {
    color: "rgb(75, 28, 70)",
    marginTop: "-5px",
    fontSize: "1.3rem"
  },

  thumbSelection: {
    color: "rgb(240, 81, 70)",
    marginTop: "-2px",
    fontSize: "1.7rem",
    opacity: "0.7"
  },

  autoRefreshLabel: {
    fontSize: "0.85rem"
  },
  autoUpdate: {
    minWidth: "8rem"
  },
  removePaddingRight: {
    paddingRight: "5px"
  },
  grayCheckbox: {
    "&$checked": {
      color: "#736973"
    }
  },
  popper: {
    marginBottom: "-20px"
  },
  dialogTitle: {
    borderBottom: "solid 1px #d5d2d5",

    display: "flex",
    justifyContent: "space-between",
    padding: "12px",
    paddingRight: "5px",
    height: "60px",
    backgroundColor: "#f6f6f7",
    alignItems: "center",
    width: "350px"
  },
  paramsPopupButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "15px"
  },
  paramsPopupList: {
    width: "350px",
    overflow: "auto",
    height: "400px",
    paddingRight: 20,
    paddingLeft: 10
  },
  parametersHeader: {
    width: "154px",
    display: "flex",
    alignItems: "center"
  },
  autoUpdateContainer: {
    display: "flex",
    alignItems: "center"
  },
  smallCell: {
    maxWidth: "80px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  moveCursor: { cursor: "move !important" },
  yesBtn: {
    marginRight: "17px"
  },
  dialogActions: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  tableCellValue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "-webkit-fill-available"
  },
  oldValueBox: {
    border: "1px solid #aaa2aa",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    borderRadius: "3px",
    paddingLeft: "0.8rem"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  dialogContent: {
    flex: "1 1 auto",
    overflowY: "auto",
    padding: "10px"
  },
  resetParamsButton: {
    width: "fit-content",
    minWidth: "130px"
  },
  lessHeight: {
    height: "270px"
  },
  dialog: {
    width: "100%",
    borderRadius: "8px",
    height: "600px",
    justifyContent: "space-between"
  },
  paramsContainer: {
    height: "380px"
  },
  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
    paddingRight: "5px"
  },
  rowContainer: {
    display: "flex", alignItems: "center"
  },
  paramHolder: {
    display: "flex",
    alignItems: "center"
  },
  operationSelectLabel: {
    minWidth: "90px", marginLeft: "10px"
  },
  operationSelect: {
    width: "90px"
  },
  autoCompleteStyle: {
    width: "100%",
    margin: "10px"
  },
  constantField: {
    margin: "10px",
    minWidth: "100px",
    maxWidth: "100px",
    marginLeft: "0px"
  },
  addNewParamBtn: {
    alignSelf: "center",
    padding: 0,
    width: "fit-content",
    marginTop: "20px",
    marginBottom: "10px"
  },
  addNewParamSpan: {
    color: "#421a40",
    textTransform: "capitalize",
    borderBottom: "1px solid #421a40",
    lineHeight: "16px",
    fontSize: "16px"
  },
  actionsHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px",
    borderTop: "1px solid #d5d2d5"
  },
  addIcon: {
    transform: "scale(0.7)",
    marginRight: "-7px",
    marginTop: "-5px"
  },
  fullWidth: {
    width: "calc(100% - 20px)",
    margin: "10px"
  },
  addTopMargin: {
    marginTop: "40px"
  }
})
);

export default useStyles;
