import { CircularProgress, IconButton, Switch as MUISwitch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./WorkingUnits.style";

const WorkingUnits = (props: any) => {
  const { loading, workingUnits, setOpenWorkingUnits, openWorkingUnits, allWorkingUnits, mode, dataChanged, setDataChanged } = props;
  const classes = useStyles();

  const { operationStatusesMirror } = useStoreState((state) => state);
  const types = useStoreState((state) => state.types);
  const { operationStatuses } = types;
  const { on: powerOnVal, off: powerOffVal } = operationStatusesMirror;
  const allUnits = useStoreState((state) => state.units.allUnits);
  const unitPowerControl = useStoreActions((state) => state.units.changePowerState);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [internalDataChanged, setInternalDataChanged] = useState<boolean>(false);

  const toggleUnitPower = (event: any, unitId: string, activeOperationStatus: number) => {
    event.stopPropagation();
    event.preventDefault();
    const changeStatusTo: number = operationStatuses[activeOperationStatus] === "on" ? +powerOffVal : +powerOnVal;

    unitPowerControl({ unitId, state: +changeStatusTo })
      .then(() => {
        setInternalDataChanged(true);
      })
      .catch((error: any) => addMessage({ message: error.message }));
  };

  const handleClose = () => {
    internalDataChanged && setDataChanged(dataChanged + 1);
    setOpenWorkingUnits(null);
  };

  return (
    <div className={classes.infoDialogStyle}>
      {loading ?
        <div className={classes.loaderHolder}><CircularProgress /></div>
        :
        <div style={{ height: "100%" }}>
          <div id="title" className={clsx(classes.dataContainer, { [classes.iosTopHeight]: isIOS })}>
            <Typography className={classes.workingUnitsTitle}>{openWorkingUnits + t` working units`}</Typography>
            <IconButton disableRipple onClick={() => handleClose()}>
              <Close className={classes.closeIcon} />
            </IconButton>
          </div>
          <div className={classes.unitsContainer}>
            {workingUnits && workingUnits.sites && Object.entries(workingUnits.sites)?.map((site: any, index: any) => {
              if (!workingUnits.sites) {
                return;
              }
              return (
                site?.map((item: any) => {
                  const modes = ["cool", "heat", "other"];
                  if (!item.name) {
                    return;
                  }
                  const showHeatUnits = mode === "HEAT" && item?.numOfWorkingUnitsHeat > 0;
                  const showCoolUnits = mode === "COOL" && item?.numOfWorkingUnitsCool > 0;

                  return (
                    <>
                      {(allWorkingUnits || showHeatUnits || showCoolUnits) &&
                        <div className={classes.reportsHeader}>
                          <Typography className={classes.siteName}>
                            {item?.name}
                          </Typography>
                          <div className={classes.headerInfoContainer}>
                            {(allWorkingUnits || showCoolUnits) && <div className={classes.headerInfoSection}>
                              <Typography className={classes.headerInfoVal}>
                                {item?.numOfWorkingUnitsCool}
                              </Typography>
                              <Typography
                                className={classes.headerInfoTitle}
                                style={{ color: "#35a8e0" }}
                              >{t`COOL`}</Typography>
                            </div>}
                            {(allWorkingUnits || showHeatUnits) && <div className={classes.headerInfoSection}>
                              <Typography className={classes.headerInfoVal}>
                                {item?.numOfWorkingUnitsHeat}
                              </Typography>
                              <Typography
                                className={classes.headerInfoTitle}
                                style={{ color: "#ff0000" }}
                              >{t`HEAT`}</Typography>
                            </div>}
                            {allWorkingUnits && <div className={classes.headerInfoSection}>
                              <Typography className={classes.headerInfoVal}>
                                {item?.numOfWorkingUnitsOther}
                              </Typography>
                              <Typography
                                className={classes.headerInfoTitle}
                              >{t`OTHER`}</Typography>
                            </div>}
                          </div>
                        </div>
                      }

                      {allWorkingUnits ?
                        <>
                          {modes.map((mode: any, i: any) => {
                            return (
                              <TableContainer key={i} className={classes.tableContainer}>
                                <Table stickyHeader className={classes.table} aria-label="customized table" key={i}>
                                  <TableHead>

                                    {item[`${mode}WorkingUnitIds`].length > 0 && <TableRow >
                                      <TableCell classes={{ root: classes.tableHeadCell }} style={{ minWidth: 250 }} align="left">  {t`Units in ${mode} mode`}
                                      </TableCell>
                                      <TableCell classes={{ root: classes.tableHeadCell }} style={{ width: 200 }} align="right"> {t`Power`}
                                      </TableCell>
                                    </TableRow>}
                                  </TableHead>
                                  <TableBody>
                                    {item[`${mode}WorkingUnitIds`].map((unit: any) => {
                                      const unitObj = allUnits[unit];
                                      if (!unitObj) {
                                        return;
                                      }
                                      const { name, activeOperationStatus, permissions = {} } = unitObj;
                                      const { canControlOperationStatus } = permissions;

                                      const isItemOn = activeOperationStatus === +powerOnVal;

                                      return (
                                        <TableRow key={unit} classes={{ root: classes.overWritePaddingRow }} >
                                          <TableCell
                                            classes={{ root: classes.overWritePadding }}
                                            align="left"
                                          >
                                            {name}
                                          </TableCell>
                                          <TableCell classes={{ root: classes.overWritePadding }} align="right">
                                            <MUISwitch
                                              className={canControlOperationStatus ? "" : classes.switchDisabled}
                                              checked={isItemOn}
                                              disableRipple={true}
                                              onChange={(e: any) => canControlOperationStatus && toggleUnitPower(e, unit, activeOperationStatus)}
                                              value="checkedA"
                                              color="primary"
                                              size="medium"
                                            />
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            );
                          })}
                        </> :
                        (<TableContainer className={classes.tableContainer}>
                          <Table stickyHeader className={classes.table} aria-label="customized table">
                            <TableHead>
                              {item[`${mode.toLowerCase()}WorkingUnitIds`].length > 0 && <TableRow>
                                <TableCell classes={{ root: classes.tableHeadCell }} style={{ minWidth: 250 }} align="left">
                                  {t`Unit Name`}
                                </TableCell>
                                <TableCell classes={{ root: classes.tableHeadCell }} style={{ width: 200 }} align="right">
                                  {t`Power`}
                                </TableCell>
                              </TableRow>}
                            </TableHead>
                            <TableBody>
                              {(item[`${mode.toLowerCase()}WorkingUnitIds`] && item[`${mode.toLowerCase()}WorkingUnitIds`].map((unit: any) => {
                                const unitObj = allUnits[unit];
                                if (!unitObj) {
                                  return;
                                }
                                const { name, activeOperationStatus, permissions = {} } = unitObj;
                                const { canControlOperationStatus } = permissions;
                                const isItemOn = activeOperationStatus === +powerOnVal;

                                return (
                                  <TableRow key={`unit-row-${unit}`} classes={{ root: classes.overWritePaddingRow }}>
                                    <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                      {name}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.overWritePadding }} align="right">
                                      <MUISwitch
                                        className={canControlOperationStatus ? "" : classes.switchDisabled}
                                        checked={isItemOn}
                                        disableRipple={true}
                                        onChange={(e: any) => canControlOperationStatus && toggleUnitPower(e, unit, activeOperationStatus)}
                                        value="checkedA"
                                        color="primary"
                                        size="medium"
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              }))}
                            </TableBody>
                          </Table>
                        </TableContainer>)}
                    </>);
                }));
            })}
          </div>
        </div>}
    </div>
  );
};

export default WorkingUnits;
