import {
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core/";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons/";
import useStyles from "./UnitsInfoDialog.style";

const UnitsInfoDialog = (props: any) => {
  const { setOpenSiteUnitsInfo, siteUnitsInfo, title } = props;
  const classes: any = useStyles();

  return (
    <Dialog
      open={true}
      onClose={() => setOpenSiteUnitsInfo(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{title}</Typography>
        <IconButton disableRipple onClick={() => setOpenSiteUnitsInfo(false)} className={classes.iconBtnStyle}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.columnsTitles}
                  align="left"
                >{t`UNIT`}</TableCell>
                <TableCell
                  className={classes.columnsTitles}
                  align="left"
                >{t`ADDRESS`}</TableCell>
                <TableCell
                  className={classes.columnsTitles}
                  align="left"
                >{t`DEVICE`}</TableCell>
                <TableCell
                  className={classes.columnsTitles}
                  align="left"
                >
                  {t`LINE`}
                </TableCell>
                <TableCell
                  className={classes.columnsTitles}
                  align="left"
                >
                  {t`AIRNET`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.sortBy(siteUnitsInfo, "airnet")?.map((unitInfo: any, index: number) => {
                const { name, address, device, line, airnet } = unitInfo;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell
                      className={classes.cellStyle}
                      align="left" >
                      {name}
                    </TableCell>
                    <TableCell
                      className={classes.cellStyle}

                      align="left"
                    >
                      {address}
                    </TableCell>
                    <TableCell
                      className={classes.cellStyle}
                      align="left"
                    >
                      {device}
                    </TableCell>
                    <TableCell
                      className={classes.cellStyle}
                      align="left"
                    >
                      {line}
                    </TableCell>
                    <TableCell
                      className={classes.cellStyle}
                      align="left"
                    >
                      {airnet}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>
      <div className={classes.actionsHolder}>
        <Button
          onClick={() => setOpenSiteUnitsInfo(false)}
          width={150}
          marginRight
        >{t`Ok`}</Button>
      </div>
    </Dialog>
  );
};

export default UnitsInfoDialog;
