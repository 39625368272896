import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    width: "100%",
    height: "calc(100% - 83px)",
    display: "flex",
    flexFlow: "column nowrap",
    background: "#fff",
    maxHeight: "calc(100% - 83px)",
    justifyContent: "space-between",
    padding: "25px 20px",
    boxSizing: 'border-box'
  },
  iosContectArea: {
    height: "calc(100% - 120px)"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },

  usersContainer: {
    height: "100%",
    display: "flex",
    overflow: "auto",
    paddingBottom: "1rem",
    padding: "0 16px"
  },
  tableContainer1: {
    height: "calc(100vh - 175px)",
    padding: "0.6rem 1rem 1rem 0",
    width: "auto"
  },
  tableHeadRow: {
    // height: '45px'
  },
  overWritePadding: {
    padding: "0 0.8rem",
    cursor: "pointer",
    ...theme.tables.tableCell
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  editIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "green"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },

  title: {
    borderBottom: "1px gray solid",
    marginBottom: "2rem",
    width: "100%",
    lineHeight: "50px"
  },
  addNewUser: {
    borderRadius: "4px",
    width: "15%",
    alignSelf: "flex-end",
    color: "#fff",
    background: "linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    textAlign: "center",
    marginRight: "1rem"
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconButton: {
    minWidth: "fit-content",
    padding: "0"
  },

  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box"
  },
  "[class^=\"NavigationBar\"]": {
    padding: 0
  },
  body: {
    background: "#fafafa"
  },
  emailColor: {
    color: "#0079ff"
  },
  iconsColumnWidth: { maxWidth: "7rem", width: "5rem" },
  limitWidth: { wordBreak: "break-word", width: "15%" },
  breakLine: {
    wordBreak: "break-word", padding: "0 15px",
    height: "35px"
  },
  tableContainer: {
    display: "flex"
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "20px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },

  table: {
    background: theme.palette.background.paper
  },
  inputRoot: {
    color: "#4b4850",
    fontFamily: "Roboto",
    fontSize: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    marginBottom: "25px",
    height: "52px"
    // maxWidth: "70%",
    // marginRight: "40px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  userRowHolder: {
    display: "flex",
    minHeight: "56px",
    borderRadius: "4px",
    backgroundColor: "#f6f6f7",
    marginBottom: "12px",
    alignItems: "center",
    padding: "0 5px 0 13px",
    justifyContent: "space-between"
  },
  searchIcon: {
    width: "22px",
    height: "22px"
  },
  username: {
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 10px)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  roleText: {
    opacity: 0.6,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  arrowForward: {
    opacity: 0.6,
    fill: "#7f7692",
    fontSize: "21px"
  },
  userMiddleHolder: {
    flex: 1,
    maxWidth: "calc(100% - 125px)",
    display: "flex",
    justifyContent: "flex-end"
  },
  closeIcon: {
    fill: "#AAA2AA"
  }
}));

export default useStyles;
