import * as React from 'react';

function SvgTimer(props) {
  const { color = "#FFF" } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
      <g fill={color}>
        <path  d="M9.014.195a8.82 8.82 0 100 17.638 8.82 8.82 0 000-17.638zm0 .844a7.975 7.975 0 110 15.95 7.975 7.975 0 010-15.95z" />
        <path  d="M9.018 3.84c.214 0 .39.158.418.364l.004.057v4.543l2.782 2.123c.168.129.213.36.113.54l-.034.052a.422.422 0 01-.539.113l-.052-.034-3.114-2.376V4.261c0-.233.19-.422.422-.422z" />
      </g>
    </svg>
  );
}

export default SvgTimer;
