import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({

  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },

  dialog: {
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },

  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"
  },

  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto"
  },

  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },

  dialogButton: {
    [theme.breakpoints.down(300)]: {
      width: "90%"
    },
    margin: "10px",
    width: "40%",
    minWidth: "80px",
    maxWidth: "130px"
  },

  dialogActions: {
    padding: "0 10px 10px",
    textAlign: "center"
  },

  "MuiIconButton-root": {
    "&:hover": {
      backgroundColor: "inherit"
    }
  },

  "MuiDialogTitle-root": {
    padding: "4px 24px"
  },

  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  }

}));

export default useStyles;
