import _ from "lodash";
import React, { PropsWithChildren, memo, useMemo, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import GroupSearchMenuItem from "./GroupSearchMenuItem";
import MenuItemIcon from "./MenuItemIcon";
import { useStyles } from "./UnitsMenu.style";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

interface IUnitsMenuProps {
  onCustomerSelect?: (customerId: string | null) => void;
  onSiteSelect?: (siteId: string | null) => void;
  onSystemSelect?: (systemId: string | null) => void;
  onUnitSelect?: (unitId: string | null) => void;
  isSystemHidden?: boolean;
  generalNaming: string;
  showGeneralOption: boolean;
  screenTitle?: string;
  hideOutdoor?: boolean;
  hideBsBox?: boolean;
  hideIndoor?: boolean;
  hideControl?: boolean;
  hideOther?: boolean;
  hideSystemSelection?: boolean;
  applySiteTypeFiltering?: boolean;
  allowUnassignedControls?: boolean;

}

const containsText = (name: any, searchText: any) =>
  name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

const UnitsMenu: React.FC<PropsWithChildren<IUnitsMenuProps>> = (
  props: PropsWithChildren<IUnitsMenuProps>
) => {
  const c = useStyles();
  const {
    applySiteTypeFiltering,
    screenTitle,
    hideOutdoor,
    hideBsBox,
    hideIndoor,
    hideOther,
    hideControl,
    hideSystemSelection,
    allowUnassignedControls
  } = props;

  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selections = useStoreState((s) => s.selections.selections);

  const indoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("indoor", screenTitle, hideSystemSelection, applySiteTypeFiltering));
  const outdoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("outdoor", screenTitle, hideSystemSelection, applySiteTypeFiltering));
  const bsBoxUnits = useStoreState((s) => s.selections.getUnitsBySelection("bsBox", screenTitle, hideSystemSelection, applySiteTypeFiltering));
  const otherUnits = useStoreState((s) => s.selections.getUnitsBySelection("other", screenTitle, hideSystemSelection, applySiteTypeFiltering));
  const controlUnits = useStoreState((s) => s.selections.getUnitsBySelection("control", screenTitle, hideSystemSelection, applySiteTypeFiltering, allowUnassignedControls));
  const allUnits = useStoreState((s) => s.units.allUnits);
  const getUnitName = useStoreState((s) => s.units.getUnitName);

  const [searchText, setSearchText] = useState<any>("");

  const displayedIndoorUnits = useMemo(
    () => hideIndoor ? [] : indoorUnits.filter((option: any) => containsText(getUnitName(option.id, true), searchText)),
    [searchText]
  );
  const displayedOutdoorUnits = useMemo(
    () => hideOutdoor ? [] : outdoorUnits.filter((option: any) => containsText(option?.name, searchText)),
    [searchText]
  );
  const displayedBsBoxUnits = useMemo(
    () => hideBsBox ? [] : bsBoxUnits.filter((option: any) => containsText(option?.name, searchText)),
    [searchText]
  );
  const displayedOtherUnits = useMemo(
    () => hideOther ? [] : otherUnits.filter((option: any) => containsText(option?.name, searchText)),
    [searchText]
  );
  const displayedControlUnits = useMemo(
    () => hideControl ? [] : controlUnits.filter((option: any) => containsText(option?.name, searchText)),
    [searchText]
  );

  const setUnit = (id: string, changePage = true) => {
    if (props.onUnitSelect) {
      props.onUnitSelect && props.onUnitSelect(id);
      return;
    }
    updateSelections({ type: "unit", data: id });
  };

  const getUnitMenuItem = (unit: IUnit) => {
    const isService = +unit?.type === 3;

    let unitName: string = unit.name;

    if (isService) {
      const controlUnitId: any = unit.controlUnit;
      const controlName = allUnits[controlUnitId]?.name || t`Unassigned`;
      unitName = `${controlName} (${unit.address})`;
    }
    if (unit.name) {
      return (
        <div key={unit.id}>
          <MenuItemIcon
            title={unitName}
            onClick={setUnit}
            unit={unit.id}
            isSelected={selections.unitId === unit.id}
          />
        </div>
      );
    }
  };

  return (
    <div className={c.view}>
      <div className={c.main}>
        <div className={props.isSystemHidden ? c.hidden : c.menuWrapper}>
          <TextField
            classes={{ root: c.textField }}
            size="small"
            variant="outlined"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              className: c.input,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#aaa2aa" }} />
                </InputAdornment>
              )
            }}
            onChange={(e: any) => {
              setSearchText(e.target.value);
            }}
            onClick={(e: any) => e.stopPropagation()}
            onKeyDown={(e: any) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
          <div>
            {!props.showGeneralOption && (<MenuItemIcon
              title={props.generalNaming}
              onClick={setUnit}
              unit={"null"}
              isSelected={selections.unitId === null}
            />)}
          </div>
          {!hideOutdoor && displayedOutdoorUnits?.length ? (<GroupSearchMenuItem
            title={t`Outdoor`}
            onClick={_.noop}
            numItems={displayedOutdoorUnits.length}
            className={c.outdoor}
            isOpen={true}
          >
            {_.sortBy(displayedOutdoorUnits, (unit) => [unit.line, unit.device, unit.privateId]).map((unit) =>
              getUnitMenuItem(unit)
            )}
          </GroupSearchMenuItem>)
            : <></>}

          {!hideBsBox && displayedBsBoxUnits?.length ?
            (
              <GroupSearchMenuItem
                title={t`BS Box`}
                onClick={_.noop}
                numItems={displayedBsBoxUnits.length}
                className={c.indoor}
                isOpen={true}
              >
                {_.sortBy(displayedBsBoxUnits, "name").map((unit) => getUnitMenuItem(unit))}
              </GroupSearchMenuItem>
            )
            : <></>
          }

          {!hideIndoor && displayedIndoorUnits?.length ? (<GroupSearchMenuItem
            title={t`Indoor`}
            onClick={_.noop}
            numItems={displayedIndoorUnits.length}
            className={c.indoor}
            isOpen={true}
          >
            {_.sortBy(displayedIndoorUnits, "name").map((unit) => getUnitMenuItem(unit))}
          </GroupSearchMenuItem>)
            : <></>}

          {!hideControl && displayedControlUnits?.length ? (<GroupSearchMenuItem
            title={t`Control`}
            onClick={_.noop}
            numItems={displayedControlUnits.length}
            className={c.indoor}
            isOpen={true}
          >
            {_.sortBy(displayedControlUnits, "name").map((unit) => getUnitMenuItem(unit))}
          </GroupSearchMenuItem>)
            : <></>}

          {!hideOther && displayedOtherUnits?.length ? (<GroupSearchMenuItem
            title={t`Other`}
            onClick={_.noop}
            numItems={displayedOtherUnits.length}
            className={c.indoor}
            isOpen={true}
          >
            {_.sortBy(displayedOtherUnits, "name").map((unit) => getUnitMenuItem(unit))}
          </GroupSearchMenuItem>)
            : <></>}
        </div>
      </div>
    </div>
  );
};

export default memo(UnitsMenu);
