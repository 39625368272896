import { Typography } from "@material-ui/core/";
import { AccessTime } from "@material-ui/icons/";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { Disconnect as DisconnectIcon } from "../../../../icons";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import {
  AutoMode,
  AutoVRMode,
  ColdMode,
  DryIcon,
  FilterAlert,
  HotMode,
  ModeFanIcon,
  RedAlert,
  Timer,
  TimerActive
} from "../../../../svgComponents";
import useStyle from "./UnitItem.style";
import { FanMid } from "../../../../Modes";
import { GreenSwitch } from "../../../../cool_widgets/GreenSwitch";

const modeIcons: any = {
  0: <ColdMode style={{ transform: "scale(1.389)", fontSize: "17.5px", margin: "0 5px" }} />,
  1: <HotMode style={{ transform: "scale(1.2343)", fontSize: "17.5px", margin: "0 5px" }} />,
  2: <AutoMode style={{ transform: "scale(.76)", fontSize: "17.5px", margin: 0 }} />,
  3: <DryIcon style={{ transform: "scale(0.59)", fontSize: "17.5px", margin: "0 5px", marginLeft: "-7px" }} />,
  5: <ModeFanIcon style={{ transform: "scale(0.6945)", fontSize: "17.5px", marginLeft: "-3px", marginRight: "-4px" }} />,
  7: <AutoVRMode style={{ transform: "scale(.76)", fontSize: "17.5px", margin: 0 }} />,
};



const defaultKeys = { modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: { 0: "activeSetpoint", 1: "activeSetpoint", 2: "activeSetpoint", 3: "activeSetpoint", 5: "activeSetpoint", 6: "activeSetpoint", 7: "activeSetpoint" } };
const keysBySubType: any = {
  0: defaultKeys,
  1: { modeKey: "heaterMode", powerKey: "heaterTankONOFF", ambientTempKey: "heaterRoomTemp", setpointKeyPerMode: { 0: "heaterCoolingSP", 1: "heaterHeatingSP", 2: "", 3: "", 5: "" } },
  2: defaultKeys
};
const waterHeaterModesToNormalMap: any = { 0: 2, 1: 1, 2: 0 };

const switchClick = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
};

const ControlItemNew = (props: any) => {
  const classes = useStyle();

  const types = useStoreState((state) => state.types);
  const devices = useStoreState((state) => state.devices.allDevices);
  const user = useStoreState((state) => state.users.me);
  const { operationStatusesMirror: { on: powerOnVal, off: powerOffVal } } = useStoreState((state) => state);
  const unitPowerControl = useStoreActions((state) => state.units.changePowerState);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const userPref = useStoreState((state) => state.users.userPreferences);
  const { updateWaterHeaterDHWTankSwitch, updateUnitLocally } = useStoreActions((state) => state.units);

  const { operationStatuses, temperatureScale: tempScaleTypes, operationModesExtended } = types;
  const { temperatureScale: userTempScale = 0 } = user;
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const isCelsius = +temperatureScaleMirror.celsius === +userTempScale;
  const tempUnit = tempScaleTypes[userTempScale] === "celsius" ? "°C" : "°F";

  const { unitId, siteId, selectItem, groupId, selectedItem, addMessage } = props;
  const unit: any = allUnits[unitId];

  const BasicModeIcons: any = {
    0: <ColdMode />,
    1: <HotMode />,
    2: <AutoMode />,
    3: <DryIcon className={classes.iconMidSize} />,
    5: <FanMid />
  };

  if (!unit) { return null; }
  const {
    name,
    message,
    filter,
    isConnected,
    device,
    schedules = [],
    isHalfCDegreeEnabled,
    capabilityFlags = {},
    subType = 0,
    permissions = {},
    realActiveOperationMode
  } = unit;

  const { canControlOperationStatus } = permissions;

  const {
    enableOperationStatusSelection = false, enableOperationModeSelection = false,
    enableSetpointControl = false, enableAmbientTemperature = false } = capabilityFlags;

  const { modeKey, powerKey, ambientTempKey, setpointKeyPerMode } = keysBySubType[subType] || defaultKeys;
  const mappedMode = unit[modeKey];
  const mode = subType === 1 ? waterHeaterModesToNormalMap[mappedMode] : mappedMode;
  const setpoint = unit[setpointKeyPerMode[mode]];
  const ambientTemperature = unit[ambientTempKey];
  const power = unit[powerKey];

  const toggleUnitPower = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const value: number = operationStatuses[power] === "on" ? +powerOffVal : +powerOnVal;

    if (subType === 1) { //water heater unit
      updateWaterHeaterDHWTankSwitch({ id: unitId, data: { operationStatus: value } })
        .then(() => updateUnitLocally({ id: unitId, unit: { heaterTankONOFF: value } }))
        .catch((err: any) => addMessage({ message: err.message }));
      return;
    }

    unitPowerControl({ unitId, state: value })
      .catch((error: any) => addMessage({ message: error.message }));
  };

  const deviceIsConnected = devices[device] && devices[device].isConnected;
  const hasError = message && message.replace(/\s/g, "").toUpperCase() !== "OK";
  const isSelected = !selectedItem.isGroup && selectedItem.unitId === unitId && selectedItem.groupId === groupId;
  const disconnected = !isConnected || !deviceIsConnected;
  const hasSchedules = schedules.length > 0;

  const isItemOn = +power === +powerOnVal && !disconnected;


  const isHeatOn = (isItemOn && operationModesExtended[mode] === "HEAT") || (isItemOn && operationModesExtended[mode] === `Virtual Auto` && operationModesExtended[realActiveOperationMode] === "HEAT");
  const isCoolOn = (isItemOn && operationModesExtended[mode] === "COOL") || (isItemOn && operationModesExtended[mode] === `Virtual Auto` && operationModesExtended[realActiveOperationMode] === "COOL");
  const isDryOn = (isItemOn && operationModesExtended[mode] === "DRY") || (isItemOn && operationModesExtended[mode] === `Virtual Auto` && operationModesExtended[realActiveOperationMode] === "DRY");



  return (
    <div
      className={clsx(classes.unitStyle, isSelected && classes.selected)}
      onClick={() => selectItem(siteId, { isGroup: false, unitId, groupId, type: "unit" })}    >

      <div className={clsx(classes.statusBox, isHeatOn && classes.statusHeat, isCoolOn && classes.statusCool, isDryOn && classes.statusCool)}>
        {disconnected ?
          <DisconnectIcon /> :
          (isItemOn ?
            (hasError ?
              t`Err` :
              ((isHeatOn || isCoolOn) && _.isNumber(setpoint) ? "\u00A0" + `${isHalfCDegreeEnabled && isCelsius ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint)}${tempUnit}` :
                BasicModeIcons[mode])) :
            t`OFF`)
        }
      </div>

      <div className={classes.midSection}>
        <div className={classes.midUpper}>
          <Typography
            noWrap={false}
            className={classes.nameStyle2Lines}
          >
            {name}
          </Typography>
        </div>

        <div className={classes.midLower}>
          <div className={clsx(classes.midLowerItem, classes.currTemp)}>
            <span>{t`Now`}</span>
            <span>{t`: ` + Math.round(ambientTemperature) + tempUnit}</span>
          </div>
          <div className={classes.midLowerItem}>
            {modeIcons[mode] || ""}
          </div>

          {hasSchedules &&
            <div className={classes.midLowerItem}>
              <AccessTime className={classes.noteIcon} />
            </div>
          }
          {
            unit?.hasActiveTimer &&
            <div className={classes.midLowerItem}>
              {(unit?.hasActiveTimer && isItemOn) ? <TimerActive /> : <Timer />}
            </div>
          }

        </div>

      </div>

      {!disconnected &&
        <div className={classes.powerAlertsSchedulesContainer}>
          {(hasError || filter) &&
            (<>
              <div className={classes.importantNoteSec}>
                {hasError && (
                  <>
                    {unit?.message && <Typography className={classes.alertMessage}>{unit?.message}</Typography>}
                    <RedAlert />
                  </>
                )}
                {(!hasError && filter && userPref?.showClearFilterAlerts) &&
                  <FilterAlert />
                }
              </div>
            </>)
          }

          {enableOperationStatusSelection &&
            <GreenSwitch
              checked={isItemOn}
              onClick={switchClick}
              onChange={toggleUnitPower}
              disabled={!canControlOperationStatus}
            />
          }
        </div>
      }
    </div>
  );
};

export default ControlItemNew;
