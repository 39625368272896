import React from "react";

const ClosedSensor = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#FFF">
        <g>
          <g transform="translate(-702 -62) translate(702 62) translate(1 7)">
            <circle cx="3.897" cy="2.531" r="2.031" />
            <circle cx="14.022" cy="2.531" r="2.031" />
            <path
              strokeLinecap="round"
              d="M0 2.534L1.366 2.534M6.188 2.498L11.812 2.45M16.634 2.511L18 2.511"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ClosedSensor;
