import { CircularProgress, Dialog, IconButton, Typography } from "@material-ui/core";
import { FilterList, Reply } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import mnt from "moment";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Loading from "../../components/Loading/Loading";
import DatePicker from "../../components/MobileDatePicker/MobileDatePicker";
import { Lookup } from "../../components/MobileLookup";
import urlFlagMap from "../../constants/urlToFlagMapping";
import Button from "../../cool_widgets/Button";

import { isIOS } from "react-device-detect";
import {
  AppLogic,
  ArrowDown,
  Close,
  Home as HomeIcon,
  HVACIcon,
  MobileAudit,
  MobileLogo,
  Timer as ScheduleIcon,
  User1 as UserIcon
} from "../../icons";
import { IAuditMap } from "../../models/Audits";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { checkDateInRange } from "../../services/timeService";
import ExportUtils from "../../utils/ExportUtils";
import AuditInfo from "./AuditInfo";
import { formattedActionText } from "./AuditsActionMap";
import useStyle from "./AuditsList.style";

export interface IAuditRow {
  icon: any;
  date: string;
  actorId: string;
  actionId: string;
  action: string;
  data: any;
  unitId?: string;
  deviceId?: string;
  siteId?: string;
  customerId?: string;
  unitName?: string;
  deviceName?: string;
  siteName?: string;
  customerName?: string;
  user?: string;
  userName?: string;
  source?: number;
  line?: number;
  sourceType?: string;
  timezone?: string;
  iconText?: string;
  formattedDate?: string;
  id: string;
  time?: any;
}

const sourceMatching: any = {
  1: { icon: <UserIcon />, text: "Application User" },
  2: { icon: <ScheduleIcon color="#aaa2aa" style={{ height: 21, width: "auto" }} />, text: "Scheduled Operation" },
  3: { icon: <HomeIcon style={{ height: "auto", width: 24 }} />, text: "Home Automation / BMS" },
  4: { icon: <HVACIcon style={{ height: 21, width: "auto" }} />, text: "HVAC" },
  5: { icon: <AppLogic width="27" height="27" />, text: "Application logic" }
};

const dataPropertiesReplacement: { [key: string]: any } = {
  isRegistered: "Connected",
  isExpired: "Expired"
},
  notReadable = [
    "customer",
    "isAcceptedTOU",
    "_id",
    "__v",
    "user",
    "userLocationsMetadata",
    "password",
    "randId",
    "id",
    "unit",
    "site"
  ];

const AuditsList: React.FC = (props: any) => {
  const classes = useStyle();
  const history = useHistory();

  const getMyAudits = useStoreActions((s) => s.audits.getCustomerAudits);
  const sendAuditReportByEmail = useStoreActions((s) => s.audits.shareAuditsByEmail);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const allUsers = useStoreState((s) => s.users.users);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const getCustomerName = useStoreState((s) => s.customers.getCustomerName);
  const getDeviceName = useStoreState((s) => s.devices.getDeviceName);
  const getSiteName = useStoreState((s) => s.sites.getSiteName);
  const getFullName = useStoreState((s) => s.users.getFullName);
  const getUsername = useStoreState((s) => s.users.getUsername);
  const types = useStoreState((s) => s.types);
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const { dateRange } = useStoreState((s) => s.selections.selections);
  const updateSelections = useStoreActions((s) => s.selections.updateSelections);
  const getSite = useStoreState((s) => s.sites.getSite);
  const getUnit = useStoreState((s) => s.units.getUnit);
  const temperatureScaleDisplay = useStoreState((s) => s.users.getTemperatureScaleDisplayPlainText);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const getSystem = useStoreState((s) => s.systems.getSystem);
  const getDevice = useStoreState((s) => s.devices.getDevice);
  const stillLoadingInitialAPIs = useStoreState((state) => state.stillLoadingInitialAPIs);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const { canShareAuditByEmail = true } = getCustomer(selections?.customerId) || {};

  const [loading, setLoading] = useState(false);
  const [audits, setAudits] = useState<IAuditRow[]>([]);
  const { timeFormat, dateFormat } = useStoreState((state) => state.users);
  const [opendatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openFilters, setOpenedFilters] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<any>({ users: {}, source: {}, actions: {} });
  const [usersFilters, setUsersFilters] = useState<any>({});
  const [actionsFilter, setActoinsFilter] = useState<any>({});
  const [sourcesFilters, setSourcesFilters] = useState<any>({});
  const [usersIdsByString, setUsersIdsByString] = useState<any>({});
  const [actionsIdsByString, setActoinsIdsByString] = useState<any>({});
  const [sourcesIdsByString, setSourcesIdsByString] = useState<any>({});
  const [filteredAudtis, setFilteredAudits] = useState<IAuditRow[]>([]);
  const [pagingData, setPagingData] = useState<any>({ rowsNum: 0, pagesNum: 0, currentPageNum: 0 });
  const [emails, setEmails] = useState<string[]>([]);
  const [isEmailDialogOpen, handleEmailDialog] = useState<boolean>(false);
  const [selectedAudit, setSelectedAudit] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const sourceMap = useCallback((source: any, sourceType: any = "") => {
    // if (source === 3 && sourceType) {
    //   return { icon: <HomeIcon />, text: sourceType };
    // }

    return sourceMatching[source] || { icon: "-", text: "No Source" };
  }, []);

  useEffect(() => {
    if (!dateRange?.endDate || !dateRange?.startDate) {
      updateSelections({
        type: "time",
        data: {
          startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
          endDate: new Date()
        }
      });
    }

    const users: any = {};
    const usersIdsByString: any = {};
    Object.values(allUsers).forEach((user: any) => {
      users[user?.username] = true;
      usersIdsByString[user?.username] = user?.id;
    });
    setUsersIdsByString(usersIdsByString);
    setUsersFilters(users);
  }, []);

  useEffect(() => {
    if (!dateRange || stillLoadingInitialAPIs) {
      return;
    }

    setLoading(true);
    const startTime = Date.UTC(dateRange?.startDate.getFullYear(), dateRange?.startDate.getMonth(), dateRange?.startDate.getDate()) - 54000000;
    const endTime = Date.UTC(dateRange?.endDate.getFullYear(), dateRange?.endDate.getMonth(), dateRange?.endDate.getDate(), 23, 59, 59) + 54000000;

    getMyAudits({ customerId: selections?.customerId, params: { startTime, endTime } })
      .then((auditMap: any) => {
        const auditsArr = parseAudits(auditMap);
        const sources: any = {};
        const actions: any = {};
        const sourcesIdsByString: any = {};
        const actionsIdsByString: any = {};

        auditsArr.forEach((audit: any) => {
          sources[audit?.iconText] = true;
          actions[audit?.action] = true;
          sourcesIdsByString[audit?.iconText] = audit.source;
          actionsIdsByString[audit?.action] = audit.actionId;
        });

        setSourcesFilters(sources);
        setActoinsFilter(actions);
        setActoinsIdsByString(actionsIdsByString);
        setSourcesIdsByString(sourcesIdsByString);
        setAudits((_.orderBy(auditsArr, [(i: any) => mnt(i.time)?.unix()], ["desc"])));
      })
      .catch((err: { message: any; }) => {
        setError(err.message || "Something went wrong");
        setAudits([]);
        setLoading(false);
      });

  }, [dateRange, stillLoadingInitialAPIs]);

  useEffect(() => {
    //for filtering the auidts based on selections and selected filters
    if (audits.length === 0) {
      setPagingData({ rowsNum: 0, pagesNum: 0, currentPageNum: 0 });
      loading && setLoading(false);
      setFilteredAudits([]);
    }

    const { customerId, siteId, unitId } = selections;
    const { users, source, actions } = selectedFilters;
    const noUsersSelected = _.isEmpty(users);
    const noSourcesSelected = _.isEmpty(source);
    const noActionsSelected = _.isEmpty(actions);

    const filteredRows = _(audits)
      .filter((audit) => {
        return noUsersSelected ? true : users[audit.userName || ""];
      })
      .filter((audit) => {
        return noSourcesSelected ? true : source[audit.iconText || ""];
      })
      .filter((audit) => {
        return noActionsSelected ? true : actions[audit.action || ""];
      })
      .filter((audit: any) => {
        return unitId ? (audit.unitId === unitId || (audit.unitId ? audit.unitId === allUnits[unitId].controlUnit : false)) : true;
      })
      .filter((audit: any) =>
        siteId ? audit.siteId === siteId : true
      )
      .filter((audit: any) =>
        customerId ? audit.customerId === customerId : true
      )
      .value();

    const rowsNum = filteredRows.length;
    const pagesNum = Math.ceil(rowsNum / 50);

    setPagingData({ rowsNum, pagesNum, currentPageNum: 0, firstIndexInPage: 0, lastIndexInPage: rowsNum > 49 ? 50 : rowsNum });
    setFilteredAudits(filteredRows);
    loading && setLoading(false);
  }, [selectedFilters, selections, audits]);

  const parseAudits = (auditMap: IAuditMap) => {
    const auditsArr = Object.values(auditMap);
    const filteredAudtis: any = [];

    for (let i = 0; i < auditsArr.length; i++) {
      const auditAPI: any = auditsArr[i];

      if (auditAPI.unit && !allUnits[auditAPI.unit]) {
        continue;
      }

      const pageFlag = urlFlagMap.audits;
      if (auditAPI.site && sitesFlags[auditAPI.site] && !sitesFlags[auditAPI.site][pageFlag]) {
        continue;
      }

      const timezone = getSite(auditAPI.site)?.timezone || moment.tz.guess();
      if (!checkDateInRange(moment(dateRange?.startDate).format("MM/DD/YYYY"),
        moment(dateRange?.endDate).format("MM/DD/YYYY"),
        moment(auditAPI.timestamp).tz(timezone).format("MM/DD/YYYY"))
      ) {
        continue;
      }

      const { icon, text } = sourceMap(auditAPI.source, auditAPI?.sourceType);
      let unitName: string = "";
      const unit = getUnit(auditAPI.unit);
      if (unit) {
        if (!unit.isVisible) {
          continue;
        }
        unitName = unit.name;
        if (+unit?.type === 3) { // is service unit
          const controlUnitId: any = unit.controlUnit;
          const controlName = getUnit(controlUnitId)?.name || t`Unassigned`;
          unitName = `${controlName} (${unit.address})`;
        }
      }
      unitName = unitName || "-";

      if (auditAPI.system && (!auditAPI.site || auditAPI.customer)) {
        if (!auditAPI.site) {
          const device = getSystem(auditAPI.system)?.device;
          auditAPI.site = getDevice(device)?.site;

        }
        auditAPI.customer = auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer;
      }
      const row: IAuditRow = {
        icon,
        iconText: text,
        id: auditAPI.id,
        date: auditAPI.timestamp,
        actorId: auditAPI.actor,
        actionId: auditAPI.action,
        action: formattedActionText(auditAPI.action),
        data: (auditAPI?.value || auditAPI?.value === 0) ? { value: auditAPI.value } : auditAPI.data,
        unitId: auditAPI.unit,
        unitName,
        siteId: auditAPI.site,
        siteName: getSiteName(auditAPI.site),
        deviceId: auditAPI.device,
        deviceName: getDeviceName(auditAPI.device),
        customerId: auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer,
        customerName: getCustomerName(
          auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer
        ),
        user: auditAPI?.actor ? getFullName(auditAPI.actor) : "-",
        userName: getUsername(auditAPI.actor),
        line: auditAPI.line,
        source: auditAPI.source,
        sourceType: auditAPI?.sourceType,
        timezone,
        formattedDate: timezone ? moment(auditAPI.timestamp).tz(timezone).format(`${dateFormat} ${timeFormat}`) : moment(auditAPI.timestamp).format(`${dateFormat} ${timeFormat}`),
        time: auditAPI.timestamp
      };
      filteredAudtis.push(row);

    }
    return filteredAudtis;
  };

  const getReadableData = useCallback((data: any, actionId: any) => {
    if (_.isEmpty(data)) {
      return "";
    }
    const lowerCaseAction = actionId.toLowerCase(),
      { value } = data;

    if (lowerCaseAction.includes("setpoint")) {
      return `setpoint value: ${value} ${temperatureScaleDisplay()}`;
    }
    if (lowerCaseAction.includes("operationstatus")) {
      return `operation status: ${types?.operationStatuses[value]}`;
    }
    if (lowerCaseAction.includes("operationmode")) {
      return `operation mode: ${types?.operationModesExtended[value]}`;
    }
    if (lowerCaseAction.includes("fanmode")) {
      return `fan mode: ${types?.fanModes[value]}`;
    }
    if (lowerCaseAction.includes("swingmode")) {
      return `swing mode: ${types?.swingModes[value]}`;
    }

    let dataString = "";
    Object.keys(data).forEach((key: any) => {
      if (typeof data[key] !== "string" || notReadable.includes(key)) {
        return;
      }

      const replacement = dataPropertiesReplacement[key];
      dataString += `${dataString ? "| " : ""}${replacement ? replacement : key}: ${data[key]}`;
    });
    return dataString;
  }, []);

  const shareAuditsByEmail = () => {

    if (!dateRange) {
      return;
    }
    const startTime = Date.UTC(dateRange?.startDate.getFullYear(), dateRange?.startDate.getMonth(), dateRange?.startDate.getDate()) - 54000000;
    const endTime = Date.UTC(dateRange?.endDate.getFullYear(), dateRange?.endDate.getMonth(), dateRange?.endDate.getDate(), 23, 59, 59) + 54000000;
    const { actions, source, users } = selectedFilters;
    const payload: any = {
      params: { startTime, endTime, customerId: selections?.customerId },
      data: {
        emails: emails,
        users: Object.keys(users).map((key) => usersIdsByString[key]),
        sources: Object.keys(source).map((key) => sourcesIdsByString[key]),
        actions: Object.keys(actions).map((key) => actionsIdsByString[key])
      }
    }
    if (selections?.siteId) {
      payload.data.sites = [selections?.siteId]
    }
    if (selections?.unitId) {
      payload.data.unit = selections?.unitId
    }
    sendAuditReportByEmail({ ...payload })
      .catch((err: { message: any; }) => setError(err.message || "Something went wrong"));
    handleEmailDialog(false);
    setEmails([]);
  };

  const onApply = useCallback((selectedFilters: any) => {
    setSelectedFilters({ ...selectedFilters });
    setOpenedFilters(false);
  }, []);

  if (!isInitialized) { return <Loading />; }

  const filterValues: any = {
    users: usersFilters,
    source: sourcesFilters,
    actions: actionsFilter
  };

  const moveToPage = (page: string) => {
    let { currentPageNum, rowsNum, firstIndexInPage, lastIndexInPage } = pagingData;

    if (page === "next") {
      ++currentPageNum;
    } else {
      --currentPageNum;
    }

    firstIndexInPage = currentPageNum * 50;
    lastIndexInPage = (currentPageNum * 50) + 50;
    lastIndexInPage = rowsNum > lastIndexInPage ? lastIndexInPage : rowsNum;
    setPagingData({ ...pagingData, currentPageNum, firstIndexInPage, lastIndexInPage });
  };

  const { firstIndexInPage, lastIndexInPage, rowsNum, currentPageNum, pagesNum } = pagingData;

  const addLabeledEmail = (email: string, index: number, removeEmail: (index: number) => void) => {
    return (
      <div data-tag
        // className={classes.emailPart}
        key={index}>
        {email}
        <span data-tag-handle onClick={() => removeEmail(index)}>
          ×
        </span>
      </div>
    );
  };

  return (
    <>
      <div className={classes.view}>
        <div className={clsx(classes.contentArea, { [classes.iosPadding]: isIOS })}>
          <TopBar
            leftIconComponent={<MobileLogo />}
            leftAction={() => history.push("/dashboard")}
            screenTitle="audits"
            applySiteTypeFiltering
          />
          <div id="title" style={{
            display: "flex", alignItems: "center", minHeight: "53px",
            borderBottom: "solid 1px #e5e2e5",
            padding: "0 20px"
          }}>
            <MobileAudit style={{ width: 25, height: 25 }} />
            <Typography style={{
              marginLeft: "15px",
              fontFamily: "Roboto",
              fontSize: 20,
              fontWeight: 600,
              color: "#15111f"
            }}>{t`Activity Audit`}</Typography>
          </div>

          <div onClick={() => setOpenDatePicker(true)} style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", height: 50, borderBottom: "solid 1px #e5e2e5" }}>
            <Typography style={{
              fontFamily: "Roboto",
              fontSize: 16,
              fontWeight: 600,
              color: "#29132e"
            }}
            >{`${moment(dateRange?.startDate || new Date()).format("MMM DD")} - ${moment(dateRange?.endDate || new Date()).format("MMM DD")}`}</Typography>
            <ArrowDown
              style={{ height: 8, width: 12 }}
            />
          </div>

          <div onClick={() => setOpenedFilters(true)} style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", minHeight: 50, borderBottom: "solid 1px #e5e2e5" }}>
            <Typography style={{
              fontFamily: "Roboto",
              fontSize: 16,
              fontWeight: 600,
              color: "#29132e",
              marginRight: 18,
              whiteSpace: "nowrap"
            }}
            >{t`Filter By`}</Typography>
            <div className={classes.filterItemsContainer}>
              {Object.keys(selectedFilters.users).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.users[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
              {Object.keys(selectedFilters.source).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.source[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
              {Object.keys(selectedFilters.actions).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.actions[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
            </div>
            <FilterList />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 9px", paddingTop: 15, height: 50 }}>
            {(rowsNum !== 0 && !loading) &&
              <>
                <Typography>
                  <span>{firstIndexInPage + 1}</span>
                  <span>-</span>
                  <span>{lastIndexInPage}</span>
                  <span>{" "}of{" "}</span>
                  <span>{rowsNum}</span>
                </Typography>
                <div style={{ marginLeft: 20 }}>
                  <IconButton className={classes.iconBtn} disabled={currentPageNum === 0} onClick={() => moveToPage("prev")}><ArrowDown style={{ transform: "rotate(90deg)", height: 8, width: 12 }} /></IconButton>
                  <IconButton className={classes.iconBtn} disabled={currentPageNum === pagesNum - 1} onClick={() => moveToPage("next")}><ArrowDown style={{ transform: "rotate(270deg)", height: 8, width: 12 }} /></IconButton>
                </div>
              </>
            }
          </div>
          <div id="audits rows" style={{ height: "calc(100% - 270px )", minHeight: "calc(100% - 270px )", maxHeight: "calc(100% - 270px )", overflow: "auto", display: "flex", justifyContent: "center", flexFlow: "row wrap" }}>
            {loading ? <CircularProgress style={{ alignSelf: "center" }} /> :
              (<div style={{ width: "100%" }}>
                {filteredAudtis.slice(firstIndexInPage, lastIndexInPage).map((audit: any, i: any) =>
                  <div key={`audit-row-${audit.id}`} style={{
                    width: "100%", display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    height: "60px",
                    padding: "0 12px", borderBottom: "solid 1px #e5e2e5"
                  }}
                    onClick={() => setSelectedAudit(audit)}
                  >
                    <div style={{ height: "100%", minWidth: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {audit.icon}
                    </div>
                    <div style={{ paddingLeft: 15, display: "flex", flexFlow: "column nowrap", flex: 1, maxWidth: "100%" }}>

                      <div style={{ display: "flex", flexFlow: "row nowrap", height: 19, alignItems: "center", maxWidth: "calc(100%)", justifyContent: "space-between" }}>
                        <Typography style={{
                          flex: 1,
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#545964",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "calc(100vw - 220px)",
                          paddingRight: 5
                          // width: "calc(100% - 177px)"
                        }}>{audit.action}</Typography>
                        <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
                          <Typography style={{
                            width: "120px",
                            fontFamily: "Roboto",
                            fontSize: 14,
                            color: "#15111f"
                          }}>{audit.formattedDate}</Typography>
                          <IconButton>
                            <ArrowDown style={{ height: 8, width: 12, transform: "rotate(-90deg)" }} />
                          </IconButton>
                        </div>
                      </div>

                      <Typography className={classes.itemName}>
                        {`${audit.unitName !== "-" ? audit.unitName + "," : ""} ${audit.siteName !== "-" ? audit.siteName : ""}`}</Typography>
                    </div>
                  </div>
                )}
              </div>
              )
            }
          </div>
          <div style={{ padding: "20px 0" }}>
            <Button
              disabled={loading || !dateRange || !canShareAuditByEmail}
              variant="contained"
              className={classes.shareButton}
              startIcon={<Reply style={{ transform: "rotateY(180deg)", marginTop: "-3px" }} />}
              onClick={() => handleEmailDialog(true)}
            >
              {t`Share Report`}
            </Button>
          </div>
        </div>
      </div>
      {opendatePicker && <DatePicker close={() => setOpenDatePicker(false)} />}
      {openFilters &&
        <Lookup
          filtersList={filterValues}
          appliedFilters={selectedFilters}
          onApply={onApply}
          onClose={() => setOpenedFilters(false)}
          getIcon={(filterName: string, textIcon: string) => filterName === "source" && <span style={{ width: 35 }}>{sourceMatching?.[sourcesIdsByString?.[textIcon]]?.icon}</span>}
          title={t`Audit Log Filters`}
        />
      }
      {isEmailDialogOpen &&
        <Dialog
          open={true}
          onClose={() => handleEmailDialog(false)}
          aria-labelledby="email-alert-dialog-title"
          aria-describedby="email-alert-dialog-description"
          maxWidth="sm"
          classes={{ paper: classes.emailDialogStyle }}
        >
          <div
            className={classes.dialogHeader}
          >
            <Typography
              className={classes.headerTitle}
            >{t`Share alert`}</Typography>
            <IconButton disableRipple onClick={() => handleEmailDialog(false)}
              className={classes.iconBtnStyle}
            >
              {<Close color="#7f7692" />}
            </IconButton>
          </div>
          <div
            className={classes.emailDialogContent}
          >
            <Typography>{t`Recipients`}</Typography>
            <ReactMultiEmail
              placeholder="Email Address/es"
              emails={emails}
              onChange={(emails: any) => setEmails(emails)}
              validateEmail={(email: any) => isEmail(email)}
              getLabel={addLabeledEmail}
            />
          </div>
          <div
            className={classes.actionsHolder}
          >
            <Button
              white
              width={150}
              marginRight
              onClick={() => handleEmailDialog(false)}
            >{t`Cancel`}</Button>
            <Button
              type="submit"
              width={150}
              disabled={_.isEmpty(emails)}
              onClick={shareAuditsByEmail}
            >
              {t`Share`}
            </Button>
          </div>
        </Dialog>}
      {selectedAudit && <AuditInfo
        audit={selectedAudit}
        close={() => setSelectedAudit(null)}
      />}
      {!!error && <ErrorBox error={error} onClose={() => setError(null)} />}
    </>
  );
};

export default AuditsList;
