import { alpha, createStyles, makeStyles } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => createStyles({
  view: {
    display: "flex",
    height: "100%"
  },
  contentArea: {
    width: "calc(100% - 265px)",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    background: "#efeff2"
  },
  controlContainer: {
    flexFlow: "column nowrap",
    height: "100%",
    width: "540px",
    borderLeft: `solid 2px ${theme.palette.colors.controlPageSeparator}`,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: "flex",
    minWidth: "432px",
    flex: 0.45
  },
  floorPlanControlContainer: {
    flexFlow: "column nowrap",
    height: "100%",
    borderLeft: `solid 2px ${theme.palette.primary.dark}`,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: "flex",
    minWidth: "432px",
    flex: 1,
    backgroundImage:
      theme.gradiants.control,
    transition: "all 0.5s",
    position: "relative"
  },
  inputRoot: {
    color: "white",
    fontFamily: "sans-serif",
    fontSize: "14px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "45px",
    borderRadius: "6px",
    backgroundColor: "rgba(253, 253, 254, 0.1)",
    padding: "0 15px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  powerOffButton: {
    minWidth: "120px",
    height: "45px",
    borderRadius: "6px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f05347",
    "&:hover": {
      backgroundColor: alpha("#f05347", 0.7)
    },
    "&:focus": {
      backgroundColor: "#b3b3b3"
    },
    "&:active": {
      backgroundColor: "#f05347"
    },
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginLeft: "30px"
  },
  powerIconStyle: {
    "& path": { stroke: "#fdfdfe" },
    fontSize: "22px !important",
    marginRight: "2px"
  },
  itemsWrapper: {
    paddingLeft: "20px",
    paddingRight: "11px",
    width: "calc(100% - 9px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(255,255,255,0.26)"
    }
  },
  pageContentBox: {
    maxWidth: "calc(100vw - 240px)",
    display: "flex",
    padding: "16px",
    height: "calc(100% - 70px)",
    flexDirection: "column",
    flex: 1
  },
  cardsHolder: {
    overflow: "auto",
    backgroundImage:
      theme.gradiants.control,
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    height: "100%"
  },
  leftSideContainer: {
    minWidth: "500px",
    borderRadius: 4,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    display: "flex",
    flex: 0.55,
    flexFlow: "column nowrap"
  },
  searchBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px"
  },
  toggleBtn: {
    color: `${theme.palette.colors.gray}`,
    backgroundColor: "#fff",
    "& p": {
      marginLeft: "20px"
    }
  },
  toggleBtnSelected: {
    ...placeBackgroundColor(theme.palette.background.toggleBtn),
    color: `#fff !important`
  },
  floorPlanPage: {
    backgroundColor: "#fff",
    display: "flex",
    height: "calc(100% - 65px)",
    justifyContent: "space-between",
    alignItems: "center"
  },
  floorPlansContainer: {
    display: "flex",
    width: "66.6%",
    position: "relative",
    height: "99%",
    overflow: "auto"
  },
  closedControl: {
    flex: 0.0000001,
    minWidth: 0
  },
  collapseBtn: {
    position: "absolute",
    top: "1px",
    left: "1px"
  }
}));

export default useStyles;
