import { alpha, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto"
  },

  container: {
    width: "100%",
    background: "rgb(236, 235, 239)",
    alignContent: "flex-start"
  },
  leftCard: {
    marginBottom: "15px"
    // marginRight: "25px"
  },
  headerStyle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e"
  },
  header: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px"
  },
  rightCard: {
    marginBottom: "15px",
    marginLeft: "15px",
    paddingLeft: "20px",
    borderLeft: "1px solid #d5d2d5",
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },

  submitButton: {
    width: "100%"
  },
  dialog: {
    width: "100%",
    borderRadius: "8px",
    maxWidth: "850px"
  },
  titleContent: {
    backgroundImage: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    height: "50px",
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px"
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden",
    flex: 1,
    justifyContent: "space-between"
  },
  title: {
    height: "60px",
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    alignItems: "center",
    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignContent: "center"
    }
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  iconBtnStyle: {
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fdfdfe"
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "space-around"
  },
  dialogSubContent: {
    padding: 20,
    overflowY: "auto",
    paddingBottom: 10,
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1
  },
  select: {
    width: "100%",
    marginTop: "16px",
    height: "45px",
    backgroundColor: "#ffffff",
    "& fieldset": {
      borderRadius: "3px",
      border: "solid 1px #d5d2d5"
    },
    "& div": {
      paddingTop: 0,
      paddingBottom: 0,
      height: "45px",
      display: "flex",
      alignItems: "center"
    },
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: 30
  },
  arrowDownIcon: {
    top: 20,
    right: 11
  },
  divider: {
    backgroundColor: "#e1e2ea"
  },
  fullDialog: {
    top: 0,
    minWidth: "100%",
    minHeight: "calc(100% - 83px)",
    height: "auto",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    bottom: 0,
    margin: 0,
    borderRadius: 0,
    background: "white",
    width: '100%'
  },
  btnStyle: {
    height: 50
  },
  iosTopHeight: {
    paddingTop: "40px",
    minHeight: "100px"
  },
  deleteIcon: {
    marginRight: 10
  },
  ////////////////////////////////////////
  permissionsSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2% 4%",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "baseline",
    flex: 1
  },
  personalDetailsSections: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "4% 4% 2%",
    boxSizing: "border-box",
    justifyContent: "space-between"
  },
  sectionTitleStyle: {
    width: "100%",
    color: theme.palette.colors.gray5,
    fontSize: "16px",
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  list: {
    width: "100%",
    "& li": {
      height: "50px",
      margin: "auto",
      marginBottom: "15px",
      border: `1px solid ${alpha(theme.palette.colors.gray5, 0.3)}`,
      borderRadius: "6px"
    },
    "& input": {
      width: "100%",
      paddingLeft: "5px",
      color: alpha(theme.palette.colors.gray5, 0.6),
      background: theme.palette.colors.transparent,
      fontSize: "14px",
      fontWeight: "normal"
    },
    "& span": {
      width: "fit-content",
      fontFamily: "Roboto",
      fontSize: "16px",
      color: theme.palette.colors.gray5,
      fontWeight: "normal"
    }
  },
  itemText: { flex: "unset" },
  itemContainer: {
    width: "58%",
    flexFlow: "column",
    justifyContent: "center",
    flex: 1
  },
  emailInput: {
    "& input": {
      color: alpha(theme.palette.colors.gray5, 0.6),
      fontSize: "16px",
      paddingLeft: "24px !important"
    }
  },
  errorStyleText: {
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px",
    color: theme.palette.colors.error,
    position: "absolute",
    bottom: "-15px",
    left: "20px"
  },
  invitationInfoSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2% 4%",
    boxSizing: "border-box",
    alignItems: "baseline",
    flex: 1
  }
}));

export default useStyles;
