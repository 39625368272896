import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({

  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "start",
    alignItems: "center",
    padding: 0,
    width: "100%",
    height: "100%",
    alignSelf: "center",
    overflowY: "auto"
  },
  noUnitText: {
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.overrides.MuiButton.outlinedPrimary.color,
    padding: "0 30px"
  },
  imageContainer: {
    height: "50%",
    display: "flex",
    justifyContent: "center",
    "&>svg": {
      width: "90%",
      height: "100%"
    }
  },
  textContainer: {
    height: "30%"
  }

}));

export default useStyles;
