import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: any) => ({
  root: {
    width: "100%",
    height: "calc(100% - 83px)",
    display: "flex",
    background: "white",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
  },
  pageContent: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    flex: 1,
    overflow: "auto",
    padding: "0 10px",
  },
  section: {
    width: "100%",
    boxSizing: "border-box",
    borderBottom: "solid 1px #e5e2e5",
  },
  nameSection: {
    minHeight: 80,
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    width: "100%",
    boxSizing: "border-box",
    borderBottom: "solid 1px #e5e2e5",
  },
  moreInfo: {
    minHeight: "max-content",
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  controlSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "max-content",
    padding: "0 10px",
  },
  controlSectionHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    height: "60px",
  },
  controlSectionTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    display: "flex",
    alignItems: "center",
  },
  controlSectionBody: {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  saveButton: {
    margin: "25px auto",
    width: "calc(100% - 20px) !important",
    minHeight: 50,
    marginBottom: "55px",
  },
  textStyle: {
    width: "100%",
    height: 50,
    "& div": {
      height: 50,
    },
  },
  unitListVisibility: {
    display: "flex",
    padding: "0 20px 23px 20px",
    justifyContent: "space-between",
  },
  normalSection: {
    width: "100%",
    boxSizing: "border-box",
    borderBottom: "solid 1px #e5e2e5",
    minHeight: 60,
    padding: "0 10px",
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px " + alpha(theme.palette.colors.gray3, 0.63),
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    height: "100%",
    padding: 20,
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: alpha(theme.palette.colors.gray1, 0.1),
    borderRadius: "50%",
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px",
  },
  coolSlider: {
    color: theme.palette.colors.summerSky,
    "& input": {
      fontSize: "8px",
    },
  },
  sliderRoot: {
    touchAction: "auto",
    color: theme.palette.colors.summerSky,
  },
  sliderValueLabel: {
    left: "calc(-50% - 8px)",
    color: "inherit",
    top: 24,
    fontSize: "1rem",
    "& *": {
      background: "transparent",
      color: alpha(theme.palette.colors.gray2, 0.9),
      fontSize: "1rem",
      width: "50px",
    },
  },
  sliderRail: {
    color: alpha(theme.palette.colors.gray3, 0.63),
  },
  controlSectionContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    borderBottom: "solid 1px #e5e2e5",
  },
  sliderHolder: {
    width: "calc(100% - 40px)",
    minHeight: "45px",
    marginBottom: "15px",
  },
  titleContainer: {
    display: "flex",
    minHeight: 60,
    justifyContent: "space-between",
    padding: "0 10px",
    alignItems: "center",
    marginBottom: 15,
    width: "100%",
  },
  coldIcon: {
    transform: "scale(2)",
    marginRight: "21px",
    marginLeft: 10,
  },
  heatSlider: {
    color: theme.palette.colors.carminePink,
    "& input": {
      fontSize: "8px",
    },
  },
  heatSliderRoot: {
    touchAction: "auto",
    color: theme.palette.colors.carminePink,
  },
  autoSlider: {
    color: theme.palette.colors.purple1,
    "& input": {
      fontSize: "8px",
    },
  },
  autoSliderRoot: {
    touchAction: "auto",
    color: theme.palette.colors.purple1,
  },
  autoIcon: {
    width: 32,
    marginRight: 15,
  },
  removeBorder: {
    border: "none",
  },
  errorLabelStyle: {
    color: "red",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "10px",
    minHeight: "15px",
  },
  setpointResolutionContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    width: "130px",
    justifyContent: "space-between",
  },
  switchRoot: {
    width: 56,
    height: 24,
    padding: "5px 7px",
    marginRight: -6,
  },
  switchBase: {
    left: 6,
    padding: 1,
    color: "#D8D8D8",
    "&$checked": {
      left: 0,
      transform: "translateX(26px)",
      color: theme.palette.primary.main,
      "& + $track": {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        border: "none",
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
    },
  },
  thumb: {
    color: "#4b1c46",
    width: 22,
    height: 22,
    backgroundColor: theme.palette.primary.dark,
  },
  track: {
    backgroundColor: "rgba(75, 28, 70, 0.5)",
    borderRadius: 50 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  thumbDisabled: {
    backgroundColor: "#d5d2d5",
  },
  trackDisabled: {
    backgroundColor: "#e6e4d6",
  },
  checked: {},
  focusVisible: {},

  dualSetpointSection: {
    display: "flex",
    width: "100%",
    minHeight: "max-content",
    padding: "0 10px",
  },

  dualSetpointTitle: {
    display: "flex",
    minHeight: 60,
    justifyContent: "space-between",
    padding: "0 10px",
    alignItems: "center",
    width: "100%",
  },

  modeThresholdRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 10px",
    alignItems: "center",
  },
  modeIcon: {
    transform: "scale(1.5)",
    marginRight: "10px",
  },
  thresholdInput: {
    width: "86%",
  },
}));
