import { action, Action } from "easy-peasy";

export interface IMessagesModel {
  message: any;
  addMessage: Action<IMessagesModel, any>;
  hideMessage: Action<IMessagesModel, any>;
}

export const messageModel: IMessagesModel = {
  message: {
    message: ""
  },

  addMessage: action((state, payload) => {
    state.message = { ...payload };
  }),
  hideMessage: action((state, cb) => {
    state.message.message = "";
    if (cb) {
      cb();
    }
  })
};
