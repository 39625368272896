import { Grid, Typography } from "@material-ui/core/";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { symbols } from "../../../../constants/sensorsUnitsSymbols";
import { Switch } from "../../../../cool_widgets/Switch";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { ClosedSensor, CO2, Humidity, OpenSensor, SwitcherOffIcon as SvgPowerOff, SwitcherOnIcon as SvgPowerOn, TempSensor } from "../../../../svgComponents";
import useStyle from "../UnitItem/UnitItem.style";

const icons: any = {
  1: <TempSensor height={24} />,
  2: <CO2 />,
  3: "",
  4: "",
  5: <Humidity width={22} />
};

const sensorsTypesNames: any = {
  9: <Typography style={{ fontSize: 12 }}>VOC</Typography>,
  10: <Typography style={{ fontSize: 12 }}>PM2.5</Typography>,
  11: <Typography style={{ fontSize: 12 }}>PM10</Typography>
};

interface IObject {
  [key: string]: any;
}

const hasValue = (value: any) => {
  return !!value || value === 0;
};

const SensorItem = (props: any) => {

  const types = useStoreState((state) => state.types);

  const allSensors = useStoreState((state) => state.sensors.allSensors);
  const { updateSensorValue, _storeUpdateSensor } = useStoreActions((actions) => actions.sensors);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { sensorId, siteId, selectItem, groupId, selectedItem, isCelsius } = props;
  const { sensorTypes, sensorMeasurementUnits } = types;
  const item = allSensors[sensorId] || {};

  const classes = useStyle();

  const {
    name,
    type,
    readingValue = 0,
    userData = {},
    id,
    canControl = true
  } = item;

  const sensorType = sensorTypes[type] || {};
  const { enableMinMaxSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin } = sensorType;
  const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
  const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
  const { rangeMax: userMax, rangeMin: userMin } = userData;
  const value = +readingValue;

  const displayValue = !sensorUnit || type === 130 || type === 7 ? (enableMinMaxSelection ? hasValue(userMax) && hasValue(userMin) ?
    (((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value : value) :
    sensorUnit === "Temperature" ? (isCelsius ? value : ((value * 9 / 5) + 32)) :
      sensorUnit === "Open/Close" ? type === 129 ? +value === 0 ? `OFF` : t`ON` : (+value === 1 ? t`OPEN` : t`CLOSE`) : value;

  const roundedValue = _.isNumber(displayValue) ? (Math.round(displayValue * 100) / 100) : displayValue;
  const selected = !selectedItem.isGroup && selectedItem.sensorId === sensorId && selectedItem.groupId === groupId;

  const handleOnOff = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const updateValueTo = +value === 0 ? 1 : 0;
    updateSensorValue({ id, data: { value: updateValueTo } })
      .then(() => {
        _storeUpdateSensor({ id, data: { readingValue: updateValueTo } });
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });

  };

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div
      className={clsx(classes.unitStyle, { [classes.selected]: selected })}
      onClick={() =>
        selectItem(siteId, { isGroup: false, sensorId, groupId, type: "sensor" })
      }
    >
      <div className={classes.locSec}>
        <Typography className={classes.nameStyle2Lines}>{name}</Typography>
      </div>
      <div className={classes.tempSec}>
        <div className={classes.iconDiv}>
          {type !== 6 ?
            (sensorsTypesNames[type] || icons[activeMeasurementUnit] || "")
            : value === 1 ? <OpenSensor height="30" width="30" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />
              : <ClosedSensor height="30" width="30" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />}
        </div>
        <Typography className={clsx(classes.tempInfo, classes.tempTextSensor, { [classes.offText]: `OFF` === displayValue })}>
          {roundedValue}<span className={clsx({ [classes.specialFont]: activeMeasurementUnit === 2 || activeMeasurementUnit === 3 || activeMeasurementUnit === 6 })}>{" " + symbols[activeMeasurementUnit || ""] || ""}</span>
        </Typography>
      </div>
      <Grid className={classes.onOffSensor}>
        {type === 129 &&
          <Switch
            disabled={!canControl}
            checked={value === 1}
            disableRipple={true}
            onClick={(e: any) => switchClick(e)}
            switchChange={(e: any) => handleOnOff(e)}
            value="checkedA"
          />
        }
      </Grid>
    </div>
  );
};

export default SensorItem;
