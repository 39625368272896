import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";

export default makeStyles((theme: any) => ({
  menuStyle: { "& ul": { display: "flex", flexFlow: "column nowrap" } },
  menuHeader: {
    borderBottom: "solid 1px #e5e2e5",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: 83,
    alignItems: "flex-end",
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 12
  },
  menuItemStyle: { padding: 0, width: "100%" },
  applyBtn: { margin: "15px 0", width: "90%", alignSelf: "center", height: "50px !important" },
  resetBtn: { width: "90%", alignSelf: "center" },
  optionsContainer: { width: "100%", height: "calc(100% - 245px)", overflow: "auto", padding: "0 20px" },
  optionTextStyle: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    maxWidth: "calc(100vw - 88px)",
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "calc(100% - 70px)"
    }
  },
  filtersPopup: {
    minWidth: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    top: 0,
    margin: 0,
    borderRadius: 0,
    justifyContent: "space-between",
    background: "white",
    alignItems: "center",
    zIndex: 1
  },
  mainTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e"
  },
  tabTitle: {
    fontSize: 16,
    fontWeight: "normal",
    letterSpacing: "normal",
    color: alpha("#15111f", 0.6),
    flex: 1,
    lineHeight: "52px",
    textAlign: "center",
    cursor: "pointer",
    textTransform: "capitalize"
  },
  selectedTab: {
    borderBottom: "solid 1px " + theme.palette.colors.purple1,
    fontFamily: "RobotoMedium",
    fontWeight: 500,
    color: "#15111f"
  },
  iconBtn: {
    borderRadius: "6px", height: 30, width: 30, padding: 0, marginBottom: -4
  },
  tabContainer: {
    display: "flex",
    width: "100%"
  },
  inputRoot: {
    width: "100%",
    paddingLeft: 9,
    paddingRight: 4,
    height: "100%",
    borderRadius: "4px",
    backgroundColor: alpha(theme.palette.colors.gray1, 0.1),
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b4850" //charcoal-grey
  },
  searchBox: {
    height: 52,
    width: "100%",
    margin: "20px 0",
    padding: "0 20px"
  },
  searchIcon: {
    transform: "scale(0.8695)"
  },
  closeIconStyle: {
    width: 25,
    height: 25,
    borderRadius: 6,
    padding: 0,
    "& svg": {
      transform: "scale(0.9)"
    }
  },
  listItemStyle: {
    height: 50,
    cursor: "pointer"
  },
  noValuesText: {
    textAlign: "center"
  },
  filtersContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    height: "100%",
    maxHeight: "calc(100% - 105px)"
  },
  rowSectionTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    borderBottom: "solid 1px #e5e2e5",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    minHeight: 60,
    justifyContent: "space-between"
  },
  coolSlider: {
    color: theme.palette.colors.summerSky,
    "& input": {
      fontSize: "8px"
    }
  },
  sliderRoot: {
    touchAction: "auto",
    color: theme.palette.colors.summerSky
  },
  sliderValueLabel: {
    left: "calc(-50% - 8px)",
    color: "inherit",
    top: 24,
    fontSize: "1rem",
    "& *": {
      background: "transparent",
      color: alpha(theme.palette.colors.gray2, 0.9),
      fontSize: "1rem",
      width: "50px"
    }
  },
  sliderRail: {
    color: alpha(theme.palette.colors.gray3, 0.63)
  },
  controlSectionContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    borderBottom: "solid 1px #e5e2e5"
  },
  sliderHolder: {
    width: "calc(100% - 40px)",
    minHeight: "45px",
    marginBottom: "15px"
  },
  titleContainer: {
    display: "flex",
    minHeight: 60,
    justifyContent: "space-between",
    padding: "0 10px", alignItems: "center", marginBottom: 15, width: "100%"
  },
  coldIcon: {
    transform: "scale(2)", marginRight: "21px", marginLeft: 10
  },
  heatSlider: {
    color: theme.palette.colors.carminePink,
    "& input": {
      fontSize: "8px"
    }
  },
  heatSliderRoot: {
    touchAction: "auto",
    color: theme.palette.colors.carminePink
  },
  autoIcon: {
    width: 32,
    marginRight: 15
  },
  controlSectionTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  removeBorder: {
    border: "none"
  },
  textStyle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "15px",
    width: "100%"
  },
  flexContainer: {
    padding: "20px 10px",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    borderBottom: "solid 1px #e5e2e5"
  },
  label: {
    fontFamily: "RobotoMedium",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: 15,
    width: "100%"
  },
  textFieldStyle: {
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.colors.gray1, 0.1),
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b4850",
    height: 52,
    width: "100%",
    "& div": {
      height: 52
    },
    "& fieldset": {
      border: "none"
    }
  },
  checkboxSection: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    marginBottom: 20
  },
  checkboxStyle: {
    marginRight: "10px",
    padding: 0,
    "& span": { width: "24px", height: "24px", borderRadius: "3px", backgroundColor: "#fff" }
  },
  timeContainer: {
    height: "44px",
    width: "48%",
    backgroundColor: alpha("#fff", 0.1),
    borderRadius: 6,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#79797d",
    textTransform: "capitalize"
  },
  timeSelected: {
  },
  btnsHolder: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  iosTimeInput: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 10,
    visibilty: "none",
    borderRadius: "22px",
    "& input": {
      background: "blue",
      borderRadius: "22px",
      height: "100%",
      padding: 0,
      opacity: 0
    }
  },
  iosTimeLabel: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 9,
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#79797d"
  },
  iosHourButton: {
    position: "relative",
    display: "flex",
    height: "44px",
    width: "48%",
    borderRadius: 6,
    backgroundColor: alpha("#fff", 0.1),
    border: "1px solid #d5d2d5"
  },
  errorLabelStyle: {
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "10px",
    minHeight: "15px"
  },
  root: {
    width: "100%"
  },
  indicatorColor: {
    backgroundColor: theme.palette.primary.main
  },
  container: {
    width: "100%",
    padding: "20px 0",
    maxWidth: "768px",
    display: "flex",
    borderTop: theme.overrides.MuiButton.outlined.border,
    flexDirection: "column",
    alignItems: "start"
  },
  selectModeStyle: {
    fontSize: "18px",
    lineHeight: "normal",
    fontWeight: "normal",
    margin: "10px",
    marginLeft: "0px",
    display: "flex",
    alignItems: "center",
    color: theme.overrides.MuiButton.contained.color
  },
  daysContainer: {
    padding: "0px 0px",
    margin: "0px 0px",
    justifyContent: "space-between",
    width: "auto"
  },
  calendar: {
    fontFamily: "Roboto",
    "& .DayPicker-Month": {
      width: "300px",
      height: "250px"
    },
    "& .DayPicker-Weekday": {
      color: "#000"
    },
    "& .DayPicker-Day": {
      color: "#000"
    },
    "& .DayPicker-Day--disabled": {
      color: alpha("#000", 0.2)
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover": {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    "& .DayPicker:not(.DayPicker--interactionDisabled):hover": {
      backgroundColor: `${alpha("#000", 0.1)} !important`
    },
    "& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover": {
      backgroundColor: `${alpha("#000", 0.1)} !important`
    }
  }

}));

export const paperStyle = {
  marginTop: "43px",
  borderRadius: "6px",
  boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
  backgroundColor: "#FFF",
  padding: "5px",
  minWidth: "200px",
  maxWidth: "200px",
  minHeight: "150px",
  maxHeight: "320px",
  overflow: "hidden"
};
