import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  errorText: {
    color: "rgb(240, 83, 71)"
  },
  siteNameStyle: {
    fontFamily: "RobotoMedium",
    fontSize: "1.375rem",
    color: "rgb(41, 19, 46)",
    whiteSpace: "pre-wrap"
  },
  siteAddressStyle: {
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)"
  },
  siteActionBtnBox: {
    // borderLeft: "1px solid rgb(213, 210, 213)",
    display: "flex",
    // alignSelf: 'flex-end',
    alignItems: "center",
    alignSelf: "stretch"
  },
  ppdBtnBox: {
    width: "3rem",
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    borderRight: "1px solid rgb(213, 210, 213)",
    justifyContent: "space-evenly"
  },
  siteActionsContainer: {
    borderLeft: "1px solid rgb(213, 210, 213)",
    display: "flex",
    // alignSelf: 'flex-end',
    alignItems: "center",
    alignSelf: "stretch"
  },
  iteActions: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    borderLeft: "1px solid rgb(213, 210, 213)",
    width: "300",
    justifyContent: "center"
  },
  deleteSite: {
    color: "#bdb9b98a"
  },
  iconButtonStyle: {
    padding: "6px !important",
    color: "black !important"
  },
  card: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
    fontSize: "1rem",
    border: "1px solid rgb(213, 210, 213)",
    borderTopRightRadius: "0",
    borderTopLeftRadius: "0",
    height: "fit-content",
    backgroundColor: "transparent"
  },
  systemsContainer: {
    display: "flex",
    padding: "10px 0 0 10px !important",
    flexWrap: "wrap"
    // marginBottom: '1rem'
  },
  addSystemContainer: {
    // height: "12rem",
    marginBottom: "10px"
  },
  addIcon: {
    color: "#aaa2aa"
  },
  addNewSystem: {
    borderRadius: "4px !important",
    width: 60,
    minHeight: "10rem",
    background: "#f6f5f6 !important",
    border: "1px solid #d5d2d5 !important",
    height: "100%"
  },
  siteBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0",
    border: "1px solid rgb(213, 210, 213)",
    // borderRadius: '4px',
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    padding: "0 20px",
    paddingRight: "0"
  },
  siteActions: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    borderLeft: "1px solid rgb(213, 210, 213)",
    width: "6rem",
    justifyContent: "space-evenly"
  },
  unitsBox: {
    width: "100%",
    minHeight: "10rem"
  },
  progressBox: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    minHeight: "250px"

  },
  SVGDownload: {
    fontSize: "1.3rem"
  },
  smallActionBox: {
    width: "4rem"
  },
  folderIcon: { color: "rgb(170, 162, 170)" },
  addDeviceBtn: {
    textTransform: "capitalize",
    "&:hover": {
      border: "1px solid #aaa2aa",
      boxShadow:
        "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    "&:active": {
      border: "1px solid #aaa2aa",
      boxShadow:
        "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    }
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  exportSiteIcon: {
    paddingLeft: 5
  },
  textIcon: {
    width: 120,
    height: 40
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  dialogContent: {
    flex: "1 1 auto",
    overflowY: "auto",
    minHeight: "250px",
    alignContent: "center"
  },
  svgIcon: {
    "&span>svg:active": {
      color: "black"
    }
  },
  powerIcon: {
    paddingTop: 4,
    paddingLeft: 4
  },
  siteTitleContainer: {
    margin: "5px 0",
    display: "flex",
    width: "20%",
    alignItems: "center"
  },
  siteNameContainer: {
    display: "flex",
    flexDirection: "column"
  },
  airQualityContainer: {
    width: "500px",
    marginTop: "20px"
  },
  airQualityTitle: {
    color: "black",
    fontSize: "14px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  tooltip: {
    maxWidth: "unset",
    backgroundColor: "#f6f5f6",
    color: "black"
  },
  floorPlanBox: {
    width: "280px",
    height: "180px",
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    padding: "15px",
    border: `1px solid ${theme.palette.colors.gray6 || "#d5d2d5"}`,
    borderRadius: "4px",
    minWidth: "200px"
  },
  isDefultFP: {
    border: "2px solid black"
  },
  floorPlanBoxHead: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    height: "30px"
  },
  floorPlanName: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: 500,
    width: "70%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  wrap: {
    flexWrap: "wrap"
  },
  disabledStyle: {
    cursor: "not-allowed",
    opacity: 0.4
  }
}));
export default useStyles;
