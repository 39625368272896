import React from "react";

function SvgSearch(props: any) {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" {...props}>
            <g fill="none" fillRule="evenodd" stroke="#AAA2AA" strokeLinecap="round" strokeWidth="1.42" transform="translate(.093)">
                <ellipse cx="9.77" cy="9.792" rx="8.863" ry="8.883" />
                <path d="M16.418 16.453L20.681 20.726" />
            </g>
        </svg>

    );
}

export default SvgSearch;
