import { InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import _ from "lodash";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import GroupSearchMenuItem from "./GroupSearchMenuItem";
import MenuItemIcon from "./MenuItemIcon";
import { useStyles } from "./UnitsMenu.style";

const containsText = (name: any, searchText: any) =>
  name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

const UnitsMenu: React.FC<PropsWithChildren<any>> = (
  props: PropsWithChildren<any>
) => {
  const c = useStyles();
  const { handleClose } = props;
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selections = useStoreState((s) => s.selections.selections);
  const sensors = useStoreState((s) => s.selections.getUnitsBySelection("sensor", ""));
  const powerMeters = useStoreState((s) => s.selections.getUnitsBySelection("powerMeters", ""));
  const [searchText, setSearchText] = useState<any>("");

  const displayedSensors = useMemo(
    () => sensors.filter((option: any) => containsText(option?.name, searchText)),
    [searchText]
  );
  const displayedPowerMeters = useMemo(
    () => powerMeters.filter((option: any) => containsText(option?.name, searchText)),
    [searchText]
  );
  const handleSelect = (id: string, type: any) => {
    updateSelections({ type, data: id });
    handleClose();
  };

  return (
    <div className={c.view}>
      <div className={c.main}>
        <div className={props.isSystemHidden ? c.hidden : c.menuWrapper}>
          <div >
          </div>
          <TextField
            classes={{ root: c.textField }}
            size="small"
            variant="outlined"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              className: c.input,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#aaa2aa" }} />
                </InputAdornment>
              )
            }}
            onChange={(e: any) => {
              setSearchText(e.target.value);
            }}
            onClick={(e: any) => e.stopPropagation()}
            onKeyDown={(e: any) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
          {!!displayedSensors.length && <GroupSearchMenuItem
            title={t`Sensors`}
            onClick={_.noop}
            numItems={displayedSensors.length}
            className={c.outdoor}
            isOpen={true}
          >
            {displayedSensors.map((unit: any) => (
              <MenuItemIcon
                key={unit.id}
                title={unit.name}
                onClick={(id: string) => handleSelect(id, "sensor")}
                unit={unit.id}
                isSelected={selections.sensorId === unit.id}
              />
            ))}
          </GroupSearchMenuItem>}
          {!!displayedPowerMeters.length && <GroupSearchMenuItem
            title={t`Power Meters`}
            onClick={_.noop}
            numItems={displayedPowerMeters.length}
            className={c.indoor}
            isOpen={true}
          >
            {displayedPowerMeters.map((unit: any) => (
              <MenuItemIcon
                key={unit.id}
                title={unit.name}
                onClick={(id: string) => handleSelect(id, "powerMeter")}
                unit={unit.id}
                isSelected={selections.powerId === unit.id}
              />
            ))}
          </GroupSearchMenuItem>}
        </div>
      </div>
    </div>
  );
};

export default UnitsMenu;
