import React from "react";

function MobileAudit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <defs>
        <path id="a" d="M0 1.2a1.2 1.2 0 102.4 0 1.2 1.2 0 00-2.4 0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#AAA2AA"
          strokeLinecap="round"
          strokeWidth="1.527"
          d="M17 27.8H8.525c-.621 0-1.125-.537-1.125-1.2V7.4c0-.663.504-1.2 1.125-1.2h15.75c.621 0 1.125.537 1.125 1.2v9.756"
        ></path>
        <path
          stroke="#AAA2AA"
          strokeWidth="1.527"
          d="M21.8 26c1.988 0 3.788-1 5.4-3-1.612-2-3.412-3-5.4-3-1.988 0-3.788 1-5.4 3 1.612 2 3.412 3 5.4 3z"
        ></path>
        <g transform="translate(20.599 21.8)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            fill="#AAA2AA"
            d="M-5.999 8.4h14.4V-6h-14.4z"
            mask="url(#b)"
          ></path>
        </g>
        <path
          stroke="#AAA2AA"
          strokeLinecap="square"
          strokeWidth="1.527"
          d="M11.6 11.6h9.6m-9.6 4.2h9.6M11.6 20H14"
        ></path>
      </g>
    </svg>
  );
}

export default MobileAudit;