import {
  FilledInputProps,
  FormControl,
  InputAdornment,
  makeStyles,
  TextField
} from "@material-ui/core";
import { FilledTextFieldProps } from "@material-ui/core/TextField";
import { VpnKey } from "@material-ui/icons";
import { Lock } from "@material-ui/icons";
import clsx from "clsx";
import { FieldProps } from "formik";
import React from "react";

const useStyles = makeStyles({
  input: {
    width: "100%",
    "& label": {
      color: "rgba(255, 255, 255, 0.7)"
    },
    "& div": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  },
  margin: {
    // margin: '1rem',
  },
  textField: {
    width: "100%"
  }
});
const InputFieldPassword: React.FC<FieldProps & FilledTextFieldProps & FilledInputProps> = ({
  field,
  meta,
  form,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
      <TextField
        error={props.error}
        helperText={props.helperText}
        {...field}
        {...props}
        margin="dense"
        variant="filled"
        // className={classes.input}
        // type={props.type == 'Security token'? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.type === "token" ? (
                <Lock style={{ color: "#aaa2aa" }} />
              ) : (
                <VpnKey style={{ color: "#aaa2aa" }} />
              )}
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

export default InputFieldPassword;
