
import coolremoteSDK from "coolremote-sdk";
import React from "react";
import { useRouteMatch } from "react-router-dom";

const Impersonate: React.FC<any> = (props) => {

  const match = useRouteMatch<{ token: string }>();
  let token: string = match.params.token;
  const Impersonate = () => {
    coolremoteSDK.User.logout();
    coolremoteSDK.xhr.setToken(token);
    localStorage.clear();
    localStorage.setItem("token", token);
    window.location.href = "/dashboard";
  };

  Impersonate();
  return (<> </>);
};

export default Impersonate;
