import { makeStyles, Theme } from "@material-ui/core/styles";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => ({
  optionStyle: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    opacity: 0.7,
    fontFamily: "Roboto",
    fontSize: 17,
    letterSpacing: "normal",
    color: "#fdfdfe",
    width: "100%",
    height: "50px",
    lineHeight: "50px",
    padding: "0 16px"
  },
  optionText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    opacity: 0.7,
    fontFamily: "Roboto",
    fontSize: 17,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fdfdfe"
  },
  selectContainer: {
    height: "50px",
    width: "100%"
  },
  selectStyle: {
    width: "100%",
    color: "#fdfdfe",
    backgroundColor: theme.palette.primary.light || "#523b53",
    borderRadius: "4px",
    paddingLeft: "9px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    margin: 0
  },
  iconStyle: {
    right: "0"
  },
  focusStyle: {
    "&:focus": {
      ...placeBackgroundColor(theme.palette.background.sideNavItemSelected),
      borderRadius: "4px"
    }
  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "50px"
  },
  paperStyle: { backgroundColor: theme.palette.primary.main || "#331e38" },
  selected: {
    ...placeBackgroundColor(theme.palette.background.sideNavItemSelected),
  },
  locationTitle: {
    cursor: "pointer",
    display: "flex",
    lineHeight: "normal",
    verticalAlign: "middle",
    margin: "10px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "white",
    backgroundColor: theme.palette.primary.main || "#331e38"
  },
  endAdornment: {
    top: "unset"
  },
  hide: {
    display: "none"
  },
  groupUI: {
    "& li": {
      padding: "0 !important"
    }
  },
  popperStyle: {
    width: "calc(100% - 40px) !important"
  },
  groupLabel: {
    backgroundColor: theme.palette.background.searchList || "#331e38",
    color: "#fff",
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    textTransform: "capitalize"
  }
}));

export default useStyles;
