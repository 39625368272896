import { OperationAutomate as OperationAutomateSdk } from "coolremote-sdk";
import {
  thunk,
  Thunk
} from "easy-peasy";

export interface IOperationAutomateModel {

  getSiteChangeovers: Thunk<IOperationAutomateModel, string>;
  getCustomerChangeovers: Thunk<IOperationAutomateModel, string>;
  createChangeover: Thunk<IOperationAutomateModel, { data: any }>;
  getChangeoverById: Thunk<IOperationAutomateModel, string>;
  updateChangeover: Thunk<IOperationAutomateModel, { id: string; updatedData: any }>;
  deleteChangeover: Thunk<IOperationAutomateModel, string>;
}

export const OperationAutomateModel: IOperationAutomateModel = {
  getSiteChangeovers: thunk(async (actions, payload) => {
    const allChangeovers = await OperationAutomateSdk.getChangeoversBySite(payload);
    return allChangeovers;
  }),
  getCustomerChangeovers: thunk(async (actions, payload) => {
    const allChangeovers = await OperationAutomateSdk.getChangeoversByCustomer(payload);
    return allChangeovers;
  }),
  createChangeover: thunk((actions, payload) => {
    return OperationAutomateSdk.createChangeover(payload.data);
  }),

  getChangeoverById: thunk(async (actions, payload) => {
    return OperationAutomateSdk.getChangeoverById(payload);
  }),

  updateChangeover: thunk(async (actions, payload) => {
    const data = await OperationAutomateSdk.updateChangeover(payload.id, payload.updatedData);
    return data;
  }),

  deleteChangeover: thunk(async (actions, payload) => {
    return OperationAutomateSdk.deleteChangeover(payload);
  })

};
