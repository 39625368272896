import { Contact as ContactSdk } from "coolremote-sdk";
import { Customer as CustomerSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  thunk,
  Thunk
} from "easy-peasy";
import { IconContext } from "react-icons";

export interface IContact {
  alertGroups: string[];
  id: string;
  firstName: string;
  familyName: string;
  email?: string;
  phone?: string;
  notes?: string;
}

export interface IContactMap {
  [key: string]: IContact;
}

export interface IContactsModel {
  allContacts: IContactMap;
  getSiteContacts: Thunk<IContactsModel, string>;
  getContactById: Thunk<IContactsModel, string>;
  deleteContact: Thunk<IContactsModel, { id: string }>;
  createContact: Thunk<IContactsModel, { id: string, data: IContact }>;
  updateContact: Thunk<IContactsModel, { id: string, data: IContact }>;
  setAllContacts: Action<IContactsModel, any>;
}

export const contactsModel: IContactsModel = {
  allContacts: {},
  getSiteContacts: thunk(async (actions, payload) => {
    const allContacts = await ContactSdk.getSiteContacts(payload);
    actions.setAllContacts(allContacts);
    return allContacts;
  }),
  getContactById: thunk(async (actions, payload) => {
    const contact = await ContactSdk.getContact(payload);
    return contact;
  }),
  createContact: thunk(async (actions, payload) => {
    const newContactData = await ContactSdk.createContact(
      payload.id,
      payload.data
    );

    return newContactData;
  }),
  deleteContact: thunk(async (actions, payload) => {
    const newContactData = await ContactSdk.deleteContact(payload.id);
    return newContactData;
  }),
  updateContact: thunk(async (actions, payload) => {
    const newContactData = await ContactSdk.updateContact(
      payload.id,
      payload.data
    );
    return newContactData;
  }),
  setAllContacts: action((state, payload) => {
    state.allContacts = payload;
  })
};
