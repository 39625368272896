import React from "react";

function SvgControl(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="control_svg__a" d="M0 .227h15.215V4.31H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={props.opacity || 0.6}>
        <g transform="translate(3.3 -.227)">
          <mask id="control_svg__b" fill="#fff">
            <use xlinkHref="#control_svg__a" />
          </mask>
          <path
            fill="#FFF"
            d="M.932 4.31L0 3.375A10.704 10.704 0 017.607.227c2.872 0 5.574 1.119 7.608 3.148l-.932.935a9.39 9.39 0 00-6.676-2.763A9.393 9.393 0 00.932 4.31"
            mask="url(#control_svg__b)"
          />
        </g>
        <path
          fill="#FFF"
          d="M10.907 13.603a1.422 1.422 0 110-2.848 1.422 1.422 0 010 2.848m3.064-5.13H7.845c-.568 0-1.022.428-1.022.95v11.4c0 .523.454.95 1.022.95h6.126c.566 0 1.02-.427 1.02-.95v-11.4c0-.522-.454-.95-1.02-.95m1.014-1.793a5.734 5.734 0 00-4.078-1.688c-1.54 0-2.988.6-4.077 1.689l-.933-.934a7.037 7.037 0 015.01-2.075c1.893 0 3.673.737 5.01 2.075l-.932.934z"
        />
      </g>
    </svg>
  );
}

export default SvgControl;
