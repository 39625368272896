import { createStyles } from "@material-ui/styles";

export const UserIdentifierChangeRequiredStyles = (theme: any) =>
  createStyles({
    dialogStyle: {
      padding: "30px 26px 27px 26px",
      minWidth: "400px",
      height: "auto",
      display: "flex",
      justifyContent: "space-between"
    },
    contentStyle: {
      paddingBottom: "22px",
      color: "#545964",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "18px",
      fontFamily: "Roboto",
      textAlign: "center",
      maxWidth: "100%",
      wordBreak: "break-word",
      whiteSpace: "pre-line"
    },
    form: {
      width: "360px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      height: 150,
      margin: "auto"
    },
    submitButton: {
      height: 50
    }

  });
