import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router";
import { t } from "ttag";
import { CoolMode, HotMode } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./SiteCountCard.style";

const SiteCountCard: React.FC<any> = (props) => {
  const classes = useStyles();
  const { anomalies, communication, systemIndoor, indoorUnits, onIndoorUnits, coolUnits, hotUnits, name, siteId, handleWorkingUnits } = props;
  const history = useHistory();
  const updateMobileSelections = useStoreActions((a) => a.selections.updateMobileSelections);
  const selections = useStoreState((s) => s.selections.mobileSelections);

  const goToPage = (siteId: string, path: string) => {
    updateMobileSelections({ customerId: selections.customerId, siteId });

    history.push({
      pathname: path,
      state: { siteId }
    });
  };

  return (
    <div id="site" className={classes.siteCard}>
      <div className={classes.rowContainer}>
        <Typography className={classes.siteName}>{name}</Typography>
      </div>
      <div className={classes.rowContainer}>

        <div id="row1" className={classes.row1Holder} onClick={() => goToPage(siteId, "/alerts")}>
          <div className={classes.dataRow}>
            <Typography className={classes.dataTitle}>{t`System/Indoor`}</Typography>
            <Typography className={classes.dataCount}>{systemIndoor}</Typography>
          </div>
          <div className={classes.dataRow}>
            <Typography className={classes.dataTitle}>{t`Communication`}</Typography>
            <Typography className={classes.dataCount}>{communication}</Typography>
          </div>
          <div className={clsx(classes.dataRow, classes.removeMarginAndBorder)}>
            <Typography className={classes.dataTitle}>{t`Anomalies`}</Typography>
            <Typography className={classes.dataCount}>{anomalies}</Typography>
          </div>
        </div>
        <div id="row2" className={classes.row2Holder} onClick={() => handleWorkingUnits(onIndoorUnits, "ALL", siteId)}>
          <div className={classes.relativeContainer}>
            <Typography className={classes.onIndoorCountStyle}>{onIndoorUnits}</Typography>
            <Typography className={classes.indoorUnitsCountStyle}>{indoorUnits}</Typography>
            <CircularProgressbarWithChildren
              value={((hotUnits + coolUnits) / indoorUnits) * 100}
              strokeWidth={4}
              styles={buildStyles({
                pathColor: "#f00",
                trailColor: "#eee"
                // strokeLinecap: "butt"
              })}
            >
              {/* Foreground path */}
              <CircularProgressbar
                value={(coolUnits / indoorUnits) * 100}
                strokeWidth={4}
                styles={buildStyles({
                  trailColor: "transparent"
                  // strokeLinecap: "butt"
                })}
              />
            </CircularProgressbarWithChildren>
          </div>
          <Typography className={classes.indoorUnitsText}>Indoor Units On</Typography>
        </div>
        <div id="row3" className={classes.row3Holder} >
          <Typography className={classes.modesCount} onClick={() => handleWorkingUnits(coolUnits, "COOL", siteId)}><CoolMode className={classes.modesIcons} /> {coolUnits}{" "}{t`Units`}</Typography>
          <Typography className={classes.modesCount} onClick={() => handleWorkingUnits(hotUnits, "HEAT", siteId)}><HotMode className={classes.modesIcons} /> {hotUnits}{" "}{t`Units`}</Typography>
        </div>
      </div>
    </div>
  );
};

export default SiteCountCard;
