import _ from 'lodash';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import coolremoteSDK from 'coolremote-sdk';
import { IRootStoreModel } from '../RootStore';
import { ITrigger } from '../Triggers';

export interface ITriggerActions {
  createCustomerTrigger: Thunk<IRootStoreModel, { customerId: string; data: any }>;
  deleteCustomerTrigger: Thunk<IRootStoreModel, { id: string }>;

  _storeCreateCustomerTrigger: Action<IRootStoreModel, { triggerId: string; data: ITrigger }>;
  _storeDeleteCustomerTrigger: Action<IRootStoreModel, { id: string }>;
}

export const triggerActions: ITriggerActions = {
  // ---
  // Thunks: API and action calls
  createCustomerTrigger: thunk(async (actions, payload) => {
    const data: ITrigger = _.isUndefined(payload.data.triggerTemplateId)
      ? await coolremoteSDK.Customer.createTrigger(payload.customerId, payload.data)
      : await coolremoteSDK.Customer.createTriggerFromTriggerTemplate(
          payload.customerId,
          payload.data
        );
    actions._storeCreateCustomerTrigger({ triggerId: data.id, data: data });
    return data;
  }),

  deleteCustomerTrigger: thunk(async (actions, payload) => {
    await coolremoteSDK.Trigger.delete(payload.id);

    actions._storeDeleteCustomerTrigger({ id: payload.id });
  }),

  // ---
  // Actions: store manipulations
  _storeCreateCustomerTrigger: action((state, payload) => {
    state.triggers.allTriggers[payload.triggerId] = payload.data;

    if (
      _.isUndefined(payload.data.customer) ||
      _.isUndefined(state.customers.allCustomers[payload.data.customer])
    ) {
      // TODO: error handling
      //console.log('no customer exists for trigger ?', payload.data);
    } else {
      state.customers.allCustomers[payload.data.customer].triggers.push(payload.triggerId);
    }
  }),

  _storeDeleteCustomerTrigger: action((state, payload) => {
    const trigger: ITrigger = state.triggers.allTriggers[payload.id];

    if (_.isUndefined(trigger)) {
      //console.log(`trigger ${payload.id} not found`);
      return;
    }

    // Remove ourselves from our Customer
    if (
      _.isUndefined(trigger.customer) ||
      _.isUndefined(state.customers.allCustomers[trigger.customer])
    ) {
      // TODO: error handling
      //console.log(`no customer found for trigger ${payload.id}?`);
    } else {
      _.pull(state.customers.allCustomers[trigger.customer].triggers, payload.id);
    }

    // Finally delete the trigger itself
    delete state.triggers.allTriggers[payload.id];
  }),
};
