import {
  Card,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { t } from "ttag";
import StartEndTimePicker from "../../components/StartEndTimePicker/StartEndTimePicker";
import Button from "../../cool_widgets/Button";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Close, SetbackDiagram } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime, stringTimeToUTCMins } from "../../services/timeService";
import { ColdMode, HotMode } from "../../svgComponents";
import useStyle from "./AddEditSetback.style";
import ItemsTree from "./ItemsTree";

const AddEditSetback = (props: any) => {
  const { siteId, isEdit, handleCloseDialog, setbackSchedules, setSetbackSchedules, schedule, isCelsius, is12Hours } = props;
  const types = useStoreState((state) => state.types);
  const { scheduleCategories, weekDays: weekDaysTypes = [] } = types;
  const weekDaysArray = Object.keys(weekDaysTypes);
  const { id, data } = schedule;
  const classes = useStyle();
  const [dirty, setDirty] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const defaultThreshold = isCelsius ? 2 : 4;
  const defaultCool = isCelsius ? 28 : 82;
  const defaultHeat = isCelsius ? 18 : 64;
  const [heatThreshold, setHeatThreshold] = useState<number>(data?.topTargetTemperature - data?.topLimit || defaultThreshold);
  const [coolThreshold, setCoolThreshold] = useState<number>(data?.bottomLimit - data?.bottomTargetTemperature || defaultThreshold);
  const [bottomLimit, setBottomLimit] = useState<number>(data?.bottomLimit || defaultCool);
  const [weekDays, setWeekDays] = useState<any>(schedule?.days || []);
  const [topLimit, setTopLimit] = useState<number>(data?.topLimit || defaultHeat);
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [scheduleCategory, setScheduleCategory] = useState<number>(schedule?.scheduleCategory || scheduleCategories.weekly);
  const [scheduleName, setScheduleName] = useState<any>(schedule?.name || "");
  const [allDay, setAllDay] = useState<boolean>(true);
  const [dates, setDates] = useState<any>(schedule?.dates?.map((date: any) => new Date(date)) || []);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const [currentSelectedUnits, setCurrentSelectedUnits] = useState<any>(schedule?.units?.filter((unit: any) => Object.keys(allUnits).includes(unit)) || []);
  const today = new Date();
  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);
  const { createSetbackSchedule, updateSetbackSchedule } = useStoreActions((actions) => actions.schedules);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const allSites = useStoreState((state) => state.sites.allSites);

  const minThresholdValue = 1;
  const timeFormat = useStoreState((state) => state.users.timeFormat);

  const hasValue = (value: any) => {
    return !!value || value === 0;
  };

  useEffect(() => {
    if (!isEdit) {
      setTimeAllDay();
      return;
    }

    const { powerOnTime, powerOffTime } = schedule;
    if (powerOnTime === 0 && powerOffTime === 1439) {
      setAllDay(true);
    }
    else {
      setAllDay(false);
    }
    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");

  }, [schedule]);

  const handleSave = () => {
    setError("");

    if (!scheduleName) {
      setError("Please enter name");
      return;
    }

    if (!weekDays?.length && scheduleCategory === scheduleCategories.weekly) {
      setError("Please select at least one day");
      return false;
    }

    if (!dates?.length && scheduleCategory === scheduleCategories.calendar) {
      setError("Please select at least one day");
      return false;
    }

    if (!currentSelectedUnits?.length) {
      setError("Please select at least one unit");
      return false;
    }

    if (_.isNil(powerOnTime) || powerOnTime === "" || _.isNil(powerOffTime) || powerOffTime === "") {
      setError("Add start and end hour");
      return;
    }
    const onTime = stringTimeToUTCMins(powerOnTime, is12Hours);
    const offTime = stringTimeToUTCMins(powerOffTime, is12Hours);

    if (onTime > offTime) {
      setError("start time is after end time, please enter valid time");
      return;
    }
    if (heatThreshold < 1) {
      setError("Heat Threshold should be at least 1");
      return;
    }
    if (coolThreshold < 1) {
      setError("Cool Threshold should be at least 1");
      return;
    }
    if (heatThreshold > topLimit) {
      setError("Heat Setback setpoint should be bigger than Heat(Th)");
      return;
    }
    if (coolThreshold > bottomLimit) {
      setError("Cool SetUp setpoint should be bigger than Cool(Ch)");
      return;
    }

    const daysOrDates = scheduleCategory === scheduleCategories.weekly ? { days: weekDays } : { dates };
    const dataObj = {
      topTargetTemperature: topLimit + heatThreshold,
      bottomTargetTemperature: bottomLimit - coolThreshold,
      topLimit,
      bottomLimit
    };

    const data: any = {
      name: scheduleName,
      ...daysOrDates,
      powerOnTime: onTime,
      powerOffTime: offTime,
      scheduleCategory,
      type: "4",
      data: dataObj,
      units: [...currentSelectedUnits],
      site: siteId
    };

    delete data.type;
    if (isEdit) {
      delete data.site;
      updateSetbackSchedule({ id, data })
        .then((res: any) => {
          setSetbackSchedules({ ...setbackSchedules, [id]: res });
          handleCloseDialog();
        })
        .catch((err: any) => addMessage({ message: err.message }));
    } else {
      createSetbackSchedule({ data })
        .then((res: any) => {
          const id = Object.keys(res)[0];
          const schedule = res[id];
          setSetbackSchedules({ ...setbackSchedules, [schedule.id]: schedule });
          handleCloseDialog();
        })
        .catch((err: any) => addMessage({ message: err.message }));
    }
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...weekDays];

    currentDays.includes(selectedDay)
      ? (currentDays = weekDays.filter((day: any) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setWeekDays(currentDays);
    !dirty && setDirty(true);

  };

  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates.concat();
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
    !dirty && setDirty(true);
  };

  const setTimeAllDay = () => {
    let startHour, endHour;
    if (is12Hours) {
      startHour = "12".padStart(2, "0") + ":" + "00".padStart(2, "0") + "am";
      endHour = "11".padStart(2, "0") + ":" + "59".padStart(2, "0") + "pm";
    } else {
      startHour = "00".padStart(2, "0") + ":" + "00".padStart(2, "0");
      endHour = "23".padStart(2, "0") + ":" + "59".padStart(2, "0");
    }
    setPowerOnTime(startHour);
    setPowerOfftime(endHour);
  };

  const toggleAllDay = () => {
    if (allDay) {
      setAllDay(false);
    }
    else {
      setAllDay(true);
      setTimeAllDay();
    }
    !dirty && setDirty(true);

  };

  const onSetTime = (openPicker: string, time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
    !dirty && setDirty(true);
  };

  return (
    <Dialog
      open={true}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
      fullWidth
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{isEdit ? t`Edit Setback Rule` : t`Create Setback Rule`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleCloseDialog}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogContent}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={clsx(classes.section, classes.rightBorder)}>
              <div>
                <Typography className={classes.title}>{t`How it Works`}</Typography>
                <Card className={classes.diagramContainer}>
                  <SetbackDiagram width={400} heighy={400} />
                </Card>
                <Typography className={classes.setbackDescription}>
                  {`Use setbacks to save energy while rooms are unoccupied or to avoid extreme room temperatures when AC is turned off (e.g. unti-freeze, overheat).
                      \nAdd multiple schedules of when to activate setback and on which units. You can set calendar periods of when setback is activated, weekdays or daily hourly activation.`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5} className={clsx(classes.section, classes.rightBorder)}>
              <TextField
                variant="outlined"
                value={scheduleName}
                label="Schedule Name"
                className={classes.nameInput}
                onChange={(e) => {
                  setScheduleName(e.target.value);
                  !dirty && setDirty(true);
                }}
              />
              <div className={classes.inputContainer}>
                <div className={classes.thresholdBlock}>
                  <Typography className={classes.title}>{t`Setback Setpoints`}</Typography>
                  <div className={classes.modeThresholdRow}>
                    <ColdMode className={classes.modeIcon} />
                    <TextField
                      id="Cool-setpoint"
                      label="Cool SetUp"
                      type="number"
                      value={bottomLimit}
                      onChange={(e: any) => { setBottomLimit(+e.target.value); !dirty && setDirty(true); }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.thresholdInput}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.modeThresholdRow}>
                    <HotMode className={classes.modeIcon} />
                    <TextField
                      id="Heat-setpoint"
                      label="Heat SetBack"
                      type="number"
                      value={topLimit}
                      onChange={(e: any) => { setTopLimit(+e.target.value); !dirty && setDirty(true); }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.thresholdInput}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className={classes.thresholdBlock}>
                  <Typography className={classes.title}>{t`Recovery Threshold`}</Typography>
                  <div className={classes.modeThresholdRow}>
                    <ColdMode className={classes.modeIcon} />
                    <TextField
                      id="Cool-setpoint"
                      label="Cool (Tc)"
                      type="number"
                      value={coolThreshold}
                      onChange={(e) => { setCoolThreshold(+e.target.value); !dirty && setDirty(true); }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.thresholdInput}
                      variant="outlined"
                      InputProps={{ inputProps: { min: minThresholdValue } }}
                    />
                  </div>
                  <div className={classes.modeThresholdRow}>
                    <HotMode className={classes.modeIcon} />
                    <TextField
                      id="Heat-Threshold"
                      label="Heat (Th)"
                      type="number"
                      value={heatThreshold}
                      onChange={(e) => { setHeatThreshold(+e.target.value); !dirty && setDirty(true); }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.thresholdInput}
                      variant="outlined"
                      InputProps={{ inputProps: { min: minThresholdValue } }}
                    />
                  </div>
                </div>
              </div>
              <Grid className={classes.daysDatesContent}>
                <Tabs
                  value={scheduleCategory}
                  onChange={(event: any, newValue: number) => setScheduleCategory(newValue)}
                  variant="fullWidth"
                  classes={{ root: classes.root, indicator: classes.indicatorColor }}
                >
                  <Tab value={scheduleCategories.weekly} label={t`Weekly`} className={classes.rightBorder}
                    classes={{ selected: classes.selectedTab }}
                  />
                  <Tab value={scheduleCategories.calendar} label={t`Calendar`}
                    classes={{ selected: classes.selectedTab }}
                  />
                </Tabs>
                {scheduleCategory === scheduleCategories.weekly && <Grid container
                  className={classes.tabContainer}
                >
                  <Typography className={clsx(classes.selectModeStyle)}              >
                    {t`Choose Days`}
                  </Typography>
                  <Grid container className={classes.daysContainer} id="days">
                    <DaysList
                      days={weekDaysArray}
                      activeDays={weekDays}
                      action={addRemoveDay}
                      white={false}
                    />
                  </Grid>
                </Grid>}
                {scheduleCategory === scheduleCategories.calendar && <Grid
                  container
                  className={classes.tabContainer}
                >
                  <Typography className={clsx(classes.selectModeStyle)}              >
                    {t`Choose Dates`}
                  </Typography>
                  <Grid container className={classes.daysContainer} id="dates">
                    <DayPicker
                      selectedDays={dates}
                      onDayClick={handleDayClick}
                      className={classes.calendar}
                      disabledDays={{
                        before: today,
                        after: aYearFromToday
                      }}
                      fromMonth={today}
                      toMonth={aYearFromToday}
                    />
                  </Grid>
                </Grid>}
              </Grid>
              <div>
                <Checkbox
                  checked={!allDay}
                  onChange={toggleAllDay}
                  name="checkedB"
                  color="default"
                />
                <Typography className={classes.timeCheckbox}>{t`Daily Hours`}</Typography>
              </div>
              <StartEndTimePicker
                powerOnTime={powerOnTime}
                powerOffTime={powerOffTime}
                is12Hours={is12Hours}
                onSetTime={onSetTime}
                disabled={allDay}
              />
            </Grid>

            <Grid item xs={3} className={classes.section}>
              <Card className={classes.sideMenuContent}>
                <div className={classes.headerApplyContainer}>
                  <Typography variant="h6">{t`Apply To`}</Typography>
                </div>
                <Grid className={classes.unitsContainer}>
                  <ItemsTree
                    site={allSites[siteId]}
                    currentSelectedUnits={currentSelectedUnits}
                    setCurrentSelectedUnits={setCurrentSelectedUnits}
                    setDirty={setDirty}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className={classes.dialogActions}>
          <Typography className={classes.errorMsg}> {error || " "}</Typography>

          <Button
            onClick={handleCloseDialog}
            onMouseDown={(event: any) => event.preventDefault()}
            marginRight
            width={150}
            white
          >
            {t`Cancel`}
          </Button>
          <Button
            onClick={() => handleSave()}
            onMouseDown={(event: any) => event.preventDefault()}
            width={150}
            disabled={!dirty}
          >
            {t`Save`}
          </Button>
        </div>
      </div>
    </Dialog>

  );
};

export default AddEditSetback;
