import { Card, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import { t } from "ttag";
import {symbols} from "../../constants/sensorsUnitsSymbols";
import {
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./UnitControl.style";

const hasValue = (value: any) => {
    return !!value || value === 0;
  };

export default function UnitControl({id, disabled}: any) {
  const classes = useStyles();

  const {updateSensorValue, _storeUpdateSensor} = useStoreActions((actions) => actions.sensors);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { allSensors} = useStoreState((state) => state.sensors);
  const types = useStoreState((state) => state.types);
  const currentUser = useStoreState((s) => s.users.me);
  const getTemperatureScaleDisplayPlainText = useStoreState((s) => s.users.getTemperatureScaleDisplayPlainText);
  const { temperatureScale: tempScale } = currentUser;

  const { sensorTypes, sensorMeasurementUnits }: any = types;

  const [saveValue, setSaveValue] = useState<number>(0);
  const [sensor, setSensor] = useState<any>({});

  useEffect(() => {

      const { type, readingValue, userData = {}, isWritable, canControl = true }: any = allSensors[id];
      const sensorType = sensorTypes[type] || {};
      const {enableMinMaxSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin} = sensorType;
      const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
      const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
      const {rangeMax : userMax, rangeMin: userMin} = userData;
      const value = +readingValue;
      const hasUserValues = hasValue(userMax) && hasValue(userMin);
      const doConvert =  enableMinMaxSelection && hasUserValues;
      const analogSensor = !sensorUnit || type === 130 || type === 7;
      const hasUserMinMax = analogSensor &&  enableMinMaxSelection && hasUserValues;
      const tempSign = sensorUnit === "Temperature" && type !== 7 ? getTemperatureScaleDisplayPlainText() : "";
      const enums: any = sensorUnit === "Open/Close" ?  type === 129 ? {0: t`OFF` , 1: t`ON`} : {1: t`OPEN`, 0: t`CLOSE`} : null;
      const displayValue = enums ? enums[value] : sensorUnit === "Temperature" ? type === 7 ?
      value : tempScale === 1 ? value : (Math.round(((value * 9 / 5) + 32) * 10) / 10) : hasUserMinMax ? (((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value;

      const sensorData: any = {canControl, unit: activeMeasurementUnit, id, isWritable, value, displayValue, type, valueMax, valueMin, userMin, userMax, sensorUnit, doConvert, tempSign};

      setSaveValue(value);
      for (let key in sensorData){
        if (sensorData[key] !== sensor[key]){
          setSensor(sensorData);
          break;
        }
      }

    }, [id, allSensors]);

  const delayedCall = useCallback(_.debounce((newValue: any) => {
      updateSensorValue({id, data: {value: newValue}})
      .then(() => {
        _storeUpdateSensor({id, data: {readingValue: newValue}});
      })
      .catch((err: any) => {
        setSaveValue(sensor.value);
        addMessage({ message: err.message });
      });
    }, 1000), []);

  const updateValue = (newValue: any) => {
      setSaveValue(newValue);
      delayedCall(newValue);

    };

  const handleOnOff = () => {
      const updateValueTo = saveValue === 0 ? 1 : 0;
      updateSensorValue({id, data: {value: updateValueTo}})
      .then(() => {
        _storeUpdateSensor({id, data: {readingValue: updateValueTo}});
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
    };

  if (sensor.isWritable && sensor.type === 130){
  return (
    <div className={classes.controlContainer}>
        <div className={classes.sensorSliderContainer}>
          <CircularSlider
            size={200}
            minValue={sensor.valueMin}
            maxValue={sensor.valueMax}
            startAngle={0}
            endAngle={180}
            coerceToInt={true}
            angleType={{direction: "cw", axis: "-x"}}
            handle1={{value: saveValue, onChange: updateValue}}
            arcColor={"#FFF"}
            arcBackgroundColor={"#4b1c46"}
            disabled={disabled || !sensor.canControl}
          />
          <div className={classes.sensorValuesContainer}>
            <Typography className={classes.sensorValues}>{sensor.doConvert ? sensor.userMin : sensor.valueMin}</Typography>
            <Typography className={classes.sensorValues}>{sensor.doConvert ? sensor.userMax : sensor.valueMax}</Typography>
          </div>
          <div className={classes.valueControl}>
            <Typography className={classes.sensorValue}>{sensor.doConvert ? ((((saveValue - sensor.valueMin) * (+sensor.userMax - +sensor.userMin)) / (sensor.valueMax - sensor.valueMin)) + +sensor.userMin).toFixed(1) : saveValue.toFixed(1)}</Typography>
            <Typography className={classes.measurementUnit}>{symbols[sensor.unit || ""] || ""}</Typography>
          </div>
        </div>
        </div>
      );
    }

  if (sensor.isWritable && sensor.sensorUnit === "Open/Close"){
      return (
        <div className={clsx(classes.controlContainer, classes.centeredBox)}>
                <IconButton disabled={disabled || !sensor.canControl} onClick={handleOnOff} className={clsx(classes.powerButton)}>
                  {saveValue === 1 ? (
                    <div className={classes.powerOn}>
                      <SvgPowerOn transform="scale(1.5)" className={classes.powerButtonSVG} />
                    </div>
                  ) : (
                      <div className={classes.powerOff}>
                        <SvgPowerOff transform="scale(1.5)" className={classes.powerButtonSVG} />
                      </div>
                    )}
                </IconButton>
              </div>
      );
    }

  return (
    <div className={clsx(classes.controlContainer, classes.centeredBox)}>
    <Typography className={classes.Number}>
      <span>{sensor.displayValue}</span>
      <span className={classes.temperatureScale}>
        {symbols[sensor.unit || ""] || ""}{sensor.tempSign}
      </span>
    </Typography>
    </div>
  );
}
