import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  highLight: {
    border: `3px solid ${theme.palette.primary.dark}`,
    backgroundColor: alpha(theme.palette.primary.dark || "#29132e", 0.15),
  },
  dialogContent: {
    flex: "1 1 auto",
    overflowY: "auto",
    minHeight: "250px",
    alignContent: "center",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    "& th": {
      backgroundColor: "#f6f6f7",
      color: "#968896",
      fontWeight: 500,
      fontSize: "13px",
      textTransform: "uppercase",
      height: "40px",
    },
    "& td p": {
      color: "#545964",
      fontSize: "14px",
    },
  },
  servUnit: {
    borderBottom: "unset",
  },
  lastServUnit: {
    borderBottom: `1px solid ${theme.palette.colors.lightGrey}`,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  inputName: {
    width: "25%",
    marginRight: "1rem",
  },
  inputBrand: {
    width: "20%",
    marginRight: "1rem",
  },
  inputModel: {
    width: "10%",
    marginRight: "1rem",
  },
  inputLine: { width: "9%" },
  inputCapacity: {
    width: "10%",
    marginRight: "1rem",
  },
  inputCapacityMeasurement: {
    width: "8%",
    marginTop: "1.4rem",
  },
  autocomplete: {
    width: "10rem",
    height: "3rem",
  },
  systemContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    minHeight: "159px",
    // padding: '1rem'
  },
  systemInfoTitle: {
    backgroundColor: "#f6f5f6",
    borderBottom: "1px solid #d5d2d5",
    padding: 0,
    paddingLeft: "14px",
    marginBottom: "2rem",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "50px",
    fontWeight: 500,
    color: "#4b1c46",
    height: "50px",
    margin: "0 !important",
  },
  systemControlsContainer: {
    display: "flex",
  },
  dialogTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearButton: {
    width: "40px",
    height: "40px",
  },
  cancelMapping: {
    marginRight: "22px",
  },
  modalActions: {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    alignItems: "flex-end",
  },
  createModalActions: {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    alignItems: "flex-end",
    minHeight: "90px",
  },
  container: {
    paddingRight: "2rem",
  },

  headCell: {
    backgroundColor: "whitesmoke",
  },
  errorRequired: {
    color: "#FF0000",
    position: "absolute",
    marginTop: "1.5px",
  },
  selectError: {
    bottom: "-22px",
  },
  systemAttributes: {
    margin: "1rem",
    width: "100%",
  },

  deviceSystemsContainer: {
    width: "50rem",
    marginBottom: "2rem",
  },
  arrowIcon: {
    right: 10,
    top: 19.5,
  },
  labelStyle: {
    maxHeight: "80px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#7f7182",
    paddingRight: "14px",
  },
  labelStyleControlLines: {
    width: "10rem",
    fontSize: "14px",
    color: "#7f7182",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontWeight: 500,
  },
  addMargin: {
    marginTop: "5px",
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  loaderContainer: {
    alignSelf: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexFlow: "column nowrap",
    justifyContent: "center",
  },
  textField: {
    width: "100%",
    margin: 0,
    // marginTop: "5px"
  },
  input: {
    textAlign: "left",
    height: "40px",
    width: "100%",
    "& label": {
      color: "rgba(255, 255, 255, 0.7)",
      "&focus": {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    "& div": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "& MuiInputBase-root": {
      color: "white",
    },
    "&>svg": {
      width: "25px",
      height: "25px",
    },
  },
  unitsContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    marginTop: "1rem",
    display: "flex",
    flexFlow: "column",
    overflow: "hidden",
    paddingBottom: "14px",
  },
  unitsInfoTitle: {
    // borderBottom: "1px solid #d5d2d5",
    padding: 0,
    paddingLeft: "14px",
    // marginBottom: "14px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    color: "#4b1c46",
    height: "50px",
    lineHeight: "50px",
  },

  ////////////////////
  tableContainer: {},
  headCellName: {
    backgroundColor: "whitesmoke",
  },
  headCellDelete: {
    backgroundColor: "whitesmoke",
    width: "5ch !important",
  },
  unitName: {
    display: "flex",
    "flex-direction": "row",
  },
  inputText: {
    width: "100%",
  },
  disabledTrue: {
    color: "grey",
  },
  systemActionsContainer: {
    display: "flex",
    "align-items": "flex-start",
  },
  dropDown: {
    width: "10rem",
  },
  tableWrapper: {
    // height: "470px",
    overflow: "auto",
    height: "calc(100%  - 7px)",
  },
  headCellType: {
    backgroundColor: "whitesmoke",
    width: "3rem",
  },
  headCellSystem: {
    backgroundColor: "whitesmoke",
    width: "6rem",
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell,
    borderBottom: `1px solid ${theme.palette.colors.lightGrey}`,
  },
  tableCell: {
    padding: "0 16px",
    height: "49.1px",
  },
  servTableCell: {
    padding: "0 16px",
    height: "42px",
  },
  tableField: {
    margin: "1rem 0 1rem 0",
  },
  iconDeleteUnit: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
  },
  unitDisconnected: {
    background: "rgba(240, 81, 70, 0.2)",
  },
  capacityItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& input": {
      fontSize: "14px",
    },
  },
  plusIcon: { fontSize: "13px !important" },
  btn: {
    height: "36px !important",
    fontSize: "14px !important",
  },
  unitsInfoHeader: {
    backgroundColor: "#f6f5f6",
    borderBottom: "1px solid #d5d2d5",
    padding: "0 14px",
    marginBottom: "14px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  // headCellType: {
  //   padding: "0",
  //   paddingLeft: "15px"
  // },
  // headCellSystem: {
  //   padding: "0"
  // },
  // headCell: {
  //   // backgroundColor: 'whitesmoke',
  //   // width: '40px'
  // },
  // arrowIcon: {
  //   top: 19.5,
  //   right: 10
  // },
  removedUnit: {
    color: "red",
  },
  overrideMargin: {
    textAlign: "left",
    paddingRight: "25px !important",
    minWidth: "calc(100% - 39px)",
  },
  headCellAirnet: {
    padding: "0",
    "& div": {
      paddingLeft: "3px",
      // width: "5rem",
    },
  },
  headCellRole: {
    padding: "0",
    "& div": {
      fontSize: "14px",
    },
  },
  wideCell: {
    // width: "15%",
    boxSizing: "border-box",
    ...theme.tables.tableCell,
  },
  snModelWidth: {
    width: "18ch",
  },
  alignLeft: {
    textAlign: "left",
  },
  long30ch: {
    minWidth: "28ch",
  },
  padd15: {
    padding: "0 15px !important",
  },
  selectlongLength: {
    maxWidth: "26ch",
  },
  wid15chr: {
    maxWidth: "15ch",
  },
  wid10chr: {
    maxWidth: "10ch",
  },
  unAssingBtn: {
    textDecoration: "underline",
  },
  tableWaitingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  sortContainer: {
    display: "flex",
    alignItems: "center",
  },
  sortIcon: {
    fontSize: 22,
  },
  blue: {
    color: "#1976d2",
  },
  sortIconsContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    height: 45,
    marginLeft: 10,
    width: 20,
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexFlow: "column",
    height: "72%",
  },
  unitsTableContainer: {
    padding: "0 7px",
    display: "flex",
    overflowY: "auto",
  },
  upIcon: {
    position: "absolute",
    top: "4px",
  },
  downIcon: {
    position: "absolute",
    bottom: "4px",
  },
  mappingContainer: {
    minWidth: "260px",
    paddingRight: "30px",
    width: "fit-content",
    height: "49px",
    alignSelf: "center",
  },
  mappingText: {
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: 500,
    position: "relative",
  },
  mappingPercentage: {
    fontSize: "18px",
    position: "absolute",
    right: 0,
    top: "1px",
  },
  mappingTime: {
    lineHeight: "normal",
    letterSpacing: "normal",
    fontSize: "11px",
    fontWeight: 500,
    marginBottom: "10px",
    marginTop: "3px",
  },
  progressBar: {
    borderRadius: 5,
  },
  progressRoot: {
    borderRadius: 5,
    height: 7,
  },
  disableScreen: {
    height: "calc(100% - 60px)",
    position: "absolute",
    width: "100%",
    left: 0,
    opacity: 0.1,
    zIndex: 3,
    background: "#000",
    top: 60,
  },
  ////////////////////////
  sysInfoBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    "&:not(:first-child)": {
      marginTop: "45px",
    },
  },
  unitsBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    "&:not(:first-child)": {
      marginTop: "15px",
    },
  },
  sectionTitle: {
    display: "flex",
    width: "100%",
    height: "50px",
    alignItems: "center",
    marginBottom: "-8px",
    "& *": {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    "& p": {
      fontSize: "16px",
    },
  },
  clickable: {
    display: "flex",
    cursor: "pointer",
  },
  serviceMapBtn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  disconnectedRow: {
    backgroundColor: "rgba(240, 81, 70, 0.2)",
  },
  disconnectedIconParent: {
    display: "flex",
    alignItems: "center",
  },
  sortIconBase: {
    marginLeft: "5px",
  },
  ascSort: {
    transform: "scaleY(-1)",
  },
  tableHeadCont: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "13px",
  },
  capacityFields: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& :first-child": {
      flex: 2,
    },
    "& :last-child": {
      flex: 1,
    },
  },
  autoBtn: {
    boxShadow: "0",
    textTransform: "none",
    minHeight: "50px",
    width: "260px",
    alignSelf: "end",
  },
  autoMappingSys: {
    margin: "0 0 20px 0",
    marginLeft: "auto",
  },
  autoMappingLine: {
    margin: "20px 20px 0 0",
    marginLeft: "auto",
  },
  serviceMappingBtn: {
    minWidth: "178px",
    height: "36px",
    padding: 0,
    textTransform: "capitalize",
    letterSpacing: "normal",
    marginTop: "-1px",
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column",
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  progress: {
    height: "250px",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    left: "50%",
    position: "absolute",
  },
  loaderWrapper: {
    backgroundColor: `${alpha(
      theme.palette.colors.black || "#000",
      0.6
    )} !important`,
  },
  selectionBlock: {
    margin: "10px 0",
  },
  selectionSection: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  selectStyle: {
    width: "160px",
  },
  tempSeparator: {
    margin: "0 10px ",
  },
}));
export default useStyles;
