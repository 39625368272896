import React from 'react';

function SvgArrowdown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 6" {...props}>
      <path
        d="M.403 1.559l3.771 3.755a.618.618 0 00.885 0l3.755-3.755a.618.618 0 000-.885.613.613 0 00-.435-.186.613.613 0 00-.434.186L4.609 4.01 1.288.69A.613.613 0 00.853.503.613.613 0 00.42.69a.614.614 0 00-.016.869z"
        fill="#6E6C7C"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrowdown;
