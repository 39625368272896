

export default () => {
    // Stag basic walkMe link
    let walkMeSource = "";

    const url = window.location?.host;
    if (url.indexOf("professional-stg.coolremote.net") > -1) {
        walkMeSource = "https://cdn.walkme.com/users/a0e6a9192baf4952a0fa4e50e4b22375/test/walkme_a0e6a9192baf4952a0fa4e50e4b22375_https.js"
    }
    else if (url.indexOf("professional.coolremote.net") > -1) {
        console.log("WM up");
        walkMeSource = "https://cdn.walkme.com/users/a0e6a9192baf4952a0fa4e50e4b22375/walkme_a0e6a9192baf4952a0fa4e50e4b22375_https.js"
    }

    if (!walkMeSource) {
        return;
    }

    let walkme = document.createElement("script");
    walkme.type = "text/javascript";
    walkme.async = true;
    walkme.src = walkMeSource;
    document.head.appendChild(walkme);
    let s = document.getElementsByTagName("script")[0];
    // @ts-ignore
    s.parentNode.insertBefore(walkme, s);
    // @ts-ignore
    window._walkmeConfig = { smartLoad: true };


}
