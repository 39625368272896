import { makeStyles } from '@material-ui/core';

export const outdoorUnitInfoPanelStyle = makeStyles({
  infoLabel: {
    fontSize: '13px',
    color: 'rgb(84, 89, 100)'
  },

  outdoorUnitPanel: {
    // minWidth: '10rem',
    marginLeft: '1rem',
    padding: '1rem',
    borderRadius: 4,
    border: 'solid 1px #d5d2d5',
    backgroundColor: '#ffffff',
    height: 'calc(100% - 19px)',
    ///
    minWidth: "172px"
  },

  outdoorUnitPanelDivider: {
    margin: '5px 0'
  },

  outdoorUnitName: {
    fontWeight: 'bold',
    overflow: "hidden",
    maxWidth: "15ch",
    textOverflow: "ellipsis",
     whiteSpace: "nowrap"
  }
});
