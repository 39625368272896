import { alpha } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const registerNewDeviceStyle = (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      overflow: "auto",
      flexFlow: "column nowrap",
      height: "100%"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      width: "100%",
      flex: 1,
      maxWidth: "600px",
      maxHeight: "fit-content"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1,
      padding: "0 25px",
      boxSizing: "border-box"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "212px"
    },
    pageTitle: {
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.primary.dark
    },
    deviceImgStyle: {
      alignSelf: "center",
      maxWidth: "202px",
      maxHeight: "114px",
      position: "absolute"
    },
    QRWrapper: {
      width: "100%"
    },
    formsWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      height: "100%",
      minHeight: "fit-content"
    },
    wrapperStyle: {
      marginTop: 0,
      marginBottom: "10px",
      fontSize: "30px",
      width: "100%",
      "& input": {
        padding: 14
      }
    },
    nextButton: {
      height: "55px",
      borderRadius: "6px",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      marginBottom: "20px",
      width: "100%"
    },
    inputLabel: {
      fontSize: "18px"
    },
    hintIcon: {
      fontSize: "22px"
    },
    QRForm: {
      padding: "0 14px",
      display: "flex",
      width: "100%",
      minHeight: "56px",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      cursor: "pointer",
      borderRadius: "6px",
      border: theme.overrides.MuiButton.contained.border,
    },
    removeMargin: {
      marginBottom: 0
    },
    QRImgStyle: {
      maxHeight: "50px",
      marginRight: "14px"
    },
    qrTypoStyle: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.primary.dark
    },
    qrReaderStyle: { width: "100%" },
    qrDetailsContainer: {
      display: "inline-flex",
      alignItems: "center"
    },
    deviceImageContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "150px",
      marginTop: "-10px",
      marginBottom: "-30px",
      position: "relative"
    },
    logoTitleStyle: {
      maxHeight: "27px",
      width: "auto"
    },
    logoStyle: {
      width: "100%",
      maxHeight: "60px"
      // marginRight: "10px"
    },
    logoSection: {
      margin: "20px 0",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "80%",
      maxHeight: "60px",
      minHeight: "60px"
    },
    manualFormWrapper: {
      width: "100%"
    },
    addDeviceContainer: {
      paddingBottom: 25,
      borderBottom: "solid 1px #ccc",
      marginBottom: 25
    },
    addMargin: {
      marginTop: "27px"
    },
    textStyle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "normal",
      color: theme.palette.primary.dark,
      marginBottom: "21px"
    },
    selectWrapperStyle: { marginBottom: "32px" },
    widthStyle: { width: "210px" },
    continueContainer: {
      display: "flex",
      justifyContent: "space-between"
    },
    fullButtonStyle: { margin: "20px 0" },
    buttonStyle: {
      margin: "20px 0",
      width: "47%",
      fontSize: "16px"
    },
    backBtn: { marginBottom: "10px" },
    errDialogHeader: {
      backgroundColor: "#f05146",
      height: "50px",
      display: "flex"
    },
    errTitle: {
      fontSize: "20px",
      display: "flex",
      alignItems: "center",
      color: "#fff"
    },
    errContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    errBtn: {
      marginTop: "20px"
    },
    input: {
      textAlign: "left",
      height: "47px",
      width: "100%",
      "&>svg": {
        width: "25px",
        height: "25px"
      }
    },
    menuItemsContainer: {
      maxHeight: "200px",
      transitionDuration: "0s !important"
    },
    lableStyle: {
      lineHeight: "unset",
      letterSpacing: "unset",
      fontSize: 13
    },
    checkBoxRed: {
      "& svg": {
        color: "red"
      }
    },
    /////////////////////////    Styled Tree Item    ///////////////////////////////////
    root: {
      color: "#545964",
      fontSize: "14px",
      width: "100%",
      minHeight: "40px",
      "&:hover > $content": {
        backgroundColor: "transparent"
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #fff)`,
        color: "var(--tree-view-color)"
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent"
      }
    },
    content: {
      color: "#545964",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "10px",
      fontWeight: 400,
      "$expanded > &": {
        fontWeight: 400
      }
    },
    group: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "31px"
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
      padding: 0,
      display: "flex"
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: "9px",
      paddingLeft: 0
    },
    labelIcon: {
      marginRight: "10px"
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      fontSize: 15,
      overflowWrap: "anywhere"
    },
    iconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "5px"
    }
  });
