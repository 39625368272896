import React from "react";

function MobileUnitStatistics(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="#AAA2AA">
        <rect width="3.4" height="14.732" x="15.867" y="9.067" rx="0.8"></rect>
        <rect width="3.4" height="10.199" x="21.533" y="13.6" rx="0.8"></rect>
        <rect width="3.4" height="5.666" x="10.2" y="18.133" rx="0.8"></rect>
        <path d="M6.8 6a.8.8 0 01.794.7l.007.1L7.6 26.4h19.6a.8.8 0 01.793.7l.006.1a.8.8 0 01-.7.794l-.1.006H6.801a.8.8 0 01-.794-.7L6 27.2V6.801a.8.8 0 01.8-.8z"></path>
      </g>
    </svg>
  );
}

export default MobileUnitStatistics;