import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import Backdrop, { BackdropProps } from "@material-ui/core/Backdrop";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import { isIOS } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  Avatar,
  CloseIcon,
  MobileAbout,
  MobileAlert,
  MobileAppControl,
  MobileAudit,
  MobileDashboard,
  MobileDiagnostics,
  MobileGroups,
  MobileLogout,
  MobileProfile,
  MobileRemoteControl,
  MobileSite,
  MobileUnitStatistics,
  MobileUsers,
  MobileWRC
} from "../../svgComponents";
import styles from "./MobileUserMenu.style";

export class BackDropIOSWorkaround extends React.PureComponent<BackdropProps> {

  public render(): JSX.Element {
    return (
      <Backdrop {...this.props} onTouchMove={this.onTouchMove} />
    );
  }
  protected onTouchMove(event: React.TouchEvent<HTMLDivElement>): void {
    event.preventDefault();
  }
}

const MobileUserMenu = (props: any) => {
  const { open, close, userInfo } = props;
  const history = useHistory();
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const { customerId } = selections;
  const displayFlags = displayFlagsMap[customerId || ""] || {};
  const doLogout = useStoreActions((a) => a.doLogout);
  const configFile = useStoreState((s) => s.config);

  const [logoutPopupOpen, handleLogoutPopup] = useState<boolean>(false);
  const classes = styles();

  const {
    firstName,
    lastName,
    permissions,
    enableCommercialAppAccess = false
  } = userInfo;

  const menuOptions = [
    {
      src: <MobileDashboard className={classes.menuIcon} />,
      text: t`Dashboard`,
      path: "/dashboard",
      disabled: false,
      show: true,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileProfile className={classes.menuIcon} />,
      text: t`My Profile`,
      path: "/profile",
      disabled: false,
      show: true,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileRemoteControl className={classes.menuIcon} />,
      text: t`Remote Control`,
      path: "/control",
      disabled: !displayFlags.enableControl,
      show: displayFlags.showControl,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileDiagnostics className={classes.menuIcon} />,
      text: t`Unit Diagnostics`,
      path: "/unit-diagnostics",
      disabled: !displayFlags.enableUnitDiagnostics,
      show: displayFlags.showUnitDiagnostics,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileUnitStatistics className={classes.menuIcon} />,
      text: t`Unit Statistics`,
      path: "/unit-statistics",
      disabled: !displayFlags.enableUnitStatistics,
      show: displayFlags.showUnitStatistics,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileAudit className={classes.menuIcon} />,
      text: t`Activity Audit`,
      path: "/audits",
      disabled: !displayFlags.enableAuditLog,
      show: displayFlags.showAuditLog,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileAlert className={classes.menuIcon} />,
      text: t`Alerts Log`,
      path: "/alerts",
      disabled: !displayFlags.enableAlertLog,
      show: displayFlags.showAlertLog,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileSite className={classes.menuIcon} />,
      text: t`Site Management`,
      path: "/site-management",
      show: displayFlags.showSiteManagement,
      disabled: !displayFlags.enableSiteManagement,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileAppControl className={classes.menuIcon} />,
      text: t`App Control`,
      path: "/settings/unit",
      disabled: !displayFlags.enableSettingsUnitAppControls,
      show: displayFlags.showSettingsUnitAppControls,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileWRC className={classes.menuIcon} />,
      text: t`WRC Restrictions`,
      path: "/settings/restricts",
      disabled: !displayFlags.enableSettingsWRCRestrictions,
      show: displayFlags.showSettingsWRCRestrictions,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileGroups className={classes.menuIcon} />,
      text: t`Groups`,
      path: "/settings/groups",
      disabled: !displayFlags.enableSettingsGroups,
      show: displayFlags.showSettingsGroups,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileUsers className={classes.menuIcon} />,
      text: t`User Management`,
      path: "/users",
      disabled: !displayFlags.enableUserManagementUsers,
      show: displayFlags.showUserManagementUsers,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileAppControl className={classes.menuIcon} />,
      text: t`Register device`,
      path: "/device-registration",
      disabled: !displayFlags.enableRegisterDevice,
      show: displayFlags.showRegisterDevice,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileAbout className={classes.menuIcon} />,
      text: t`About`,
      path: "/about-us",
      show: configFile?.showAbout || false,
      disabled: false,
      action() {
        history.push(this.path);
      }
    },
    {
      src: <MobileLogout className={classes.menuIcon} />,
      text: t`Logout`,
      path: "/",
      disabled: false,
      show: true,
      action() {
        handleLogoutPopup(true);
      }
    }
  ];

  const getUserRole = (permissions: any, commercialAccess: boolean) => {
    if (!permissions || _.isEmpty(permissions)) {
      return commercialAccess ? "" : "Guest";
    }

    if (permissions === "globalAdmin") {
      return "Global Admin";
    }

    const { customers = {}, sites = {} } = permissions;

    const customersKeys = Object.keys(customers);

    if (customers[customersKeys[0]] === "customerAdmin") {
      return "Admin";
    }

    const sitesKeys = Object.keys(sites);
    if (sitesKeys.some((key: string) => sites[key] === "siteAdmin")) {
      return "Site Admin";
    }

    if (commercialAccess) {
      return "";
    }

    if (customers[customersKeys[0]] === "customerManager") {
      return "Manager";
    }

    return "Guest";
  };

  const logout = () => {
    handleLogoutPopup(false);
    doLogout();
  };

  const cancel = () => {
    handleLogoutPopup(false);
  };

  const role = getUserRole(permissions, enableCommercialAppAccess);
  return (
    <>
      <Drawer
        BackdropComponent={BackDropIOSWorkaround}
        transitionDuration={open === "back" ? 0 : 500}
        anchor="right"
        variant="temporary"
        open={!!open}
        color="secondary"
        onClose={close}
        classes={{ paper: classes.drawerPaper, root: classes.root }}
        className={classes.drawerStyle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <div className={classes.menuBody}>
          <Grid container className={classes.menuHeader}>
            <IconButton disableRipple onClick={close} className={clsx(classes.closeButton, { [classes.addMarginIOS]: isIOS })}>
              <CloseIcon />
            </IconButton>
            <Avatar alt="Avatar" className={classes.bigAvatar} />
            <Typography className={classes.usernameStyle}>
              {firstName && firstName}
              {lastName && " " + lastName}
            </Typography>
            <Typography className={classes.roleStyle}>{role}</Typography>
          </Grid>
          <List component="nav" style={{ backgroundColor: "#fff", width: "100%" }}>

            {menuOptions.map((menuOption, index) => {
              const { text, disabled, show, src } = menuOption;
              if (!show) {
                return null;
              }
              return (
                <div key={index}>
                  <ListItem
                    button
                    onClick={() => menuOption.action()}
                    className={clsx(classes.listItemStyle, { [classes.menuItemSelected]: history?.location?.pathname?.indexOf(menuOption.path) !== -1 && menuOption.path !== "/" })}
                    disabled={disabled}
                  >
                    <div className={classes.iconTextContainer}>
                      {src}
                      <ListItemText
                        primary={text}
                        classes={{ primary: classes.listItemText }}
                      />
                    </div>
                  </ListItem>
                  <Divider className={classes.divider} />
                </div>
              );
            })}
          </List>
        </div>
        {logoutPopupOpen && <ConfirmationDialog
          onCancel={cancel}
          onConfrim={logout}
          onClose={cancel}
          title="Log out"
          text="Are you sure?"
          confrimLabel="Logout"
          cancelLabel="No"
          dialogType="normal"
          openDialog={logoutPopupOpen}
        />}
      </Drawer>
    </>
  );
};

export default MobileUserMenu;
