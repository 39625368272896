import {
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close, Lock, Sort } from "@material-ui/icons";
import clsx from "clsx";
import {
  Invite as sdkInvite,
  Policy as sdkPolicy,
  Services as sdkServices,
  Site as sdkSite
} from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { t } from "ttag";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { SortUp } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { capitalize } from "../../services/stringServices";
import { MenuSearch as Search } from "../../svgComponents";
import { EditUser } from "./EditUser";
import { NewInvite } from "./NewInvite";
import useStyle from "./UserList.style";
import FilterRequire from "../../components/FilterRequire/FilterRequire";

const UserList: React.FC = (props: any) => {
  const classes = useStyle();
  const isInitialized = useStoreState((s) => s.isInitialized);
  const getCustomerUsers = useStoreActions((actions) => actions.customers.getCustomerUsers);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const currentUser = useStoreState((s) => s.users.me);
  const { customerId, siteId } = useStoreState((s) => s.selections.selections);
  const deleteInviteThunk = useStoreActions((action) => action.invites.deleteInvite);
  const loggedInUser = useStoreState((state) => state.users.me);
  const getEntityNameById = useStoreState((state) => state.getEntityNameById);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [refresh, setRefresh] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [filteredInvites, setFilteredInvites] = useState<any>([]);
  const [customerUsers, setCustomerUsers] = useState<any>({});
  const [customerInvites, setCustomerInvites] = useState<any>({});
  const [lvlMap, setLvlMap] = useState<any>({});
  const [toDeleteUser, setToDeleteUser] = useState<any>(null);
  const [toDeleteUserInvite, setToDeleteUserInvite] = useState<any>(null);
  const [hoverRow, setHoverRow] = useState<any>(null);
  const [hoverPolicies, setHoverPolicies] = useState<any>(null);
  const [openNewInvite, setOpenNewInvite] = useState<any>(null);
  const [roles, setRoles] = useState<any>({});
  const [selectedInvite, setSelectedInvite] = useState<any>(null);
  const [sortBy, setSortBy] = useState<"name" | "email">("name");
  const [isAsc, setIsAsc] = useState<boolean>(true);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const { canAddInvite = true, canDeleteInvite = true, canDeleteUser = true, name: customerName, permissions = {} } = getCustomer(customerId) || {};
  const { canCreateInvites } = permissions;

  useEffect(() => {
    if (!customerId) {
      return;
    }

    const promises = [sdkServices.getEntityLevel(), sdkServices.getRoles()];
    if (!!siteId) {
      promises.push(sdkSite.getUsers(siteId), sdkSite.getInvites(siteId));
    }
    else {
      promises.push(getCustomerUsers(customerId), sdkInvite.getCustomerInvites(customerId));

    }

    Promise.allSettled(promises)
      .then(([
        policiesResp,
        rolesResp,
        usersResp,
        invitesResp
      ]: any) => {
        if (usersResp?.status === "fulfilled") {
          setCustomerUsers(usersResp.value);
        }
        if (invitesResp?.status === "fulfilled") {
          setCustomerInvites(invitesResp.value);
        }
        if (policiesResp?.status === "fulfilled") {
          const map = policiesResp.value;
          const entityRevMap: any = {};
          for (let entName of Object.keys(map)) {
            entityRevMap[map[entName]] = entName;
          }
          setLvlMap(entityRevMap);
        }
        if (rolesResp.status === "fulfilled") {
          setRoles(rolesResp.value);
        }

      });
  }, [customerId, siteId, refresh]);

  useEffect(() => {
    filterUsers();
  }, [customerUsers, customerInvites, searchTerm]);

  const filterUsers = () => {
    if (!searchTerm) {
      setFilteredUsers(Object.values(customerUsers));
      setFilteredInvites(Object.values(customerInvites));
      return;
    }

    const filteredUsers = !customerId ? [] : Object.values(customerUsers).filter((user: any) => {
      const first = user?.firstName?.toUpperCase();
      const last = user?.lastName?.toUpperCase();
      const email = user?.email?.toUpperCase();
      const username = user?.username?.toUpperCase();
      const search = searchTerm?.toUpperCase();
      return (loggedInUser.id !== user.id && (first?.indexOf(search) >= 0 || last?.indexOf(search) >= 0 || email?.indexOf(search) >= 0 || username?.indexOf(search) >= 0));
    });

    const filteredInvites = !customerId ? [] : Object.values(customerInvites).filter((invite: any) => {
      const email = invite?.email?.toUpperCase();
      const search = searchTerm?.toUpperCase();
      return (email?.indexOf(search) >= 0);
    });
    setFilteredUsers(filteredUsers);
    setFilteredInvites(filteredInvites);
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true,
        classes: { root: classes.inputRoot },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  setUnitUpdateStatus({ status: "" });

  const tryDeleteUser = (id: any) => {
    const user = customerUsers[id];
    const toDelPolicies = user.policies.map((pol: any) => pol.id || pol._id);
    return deletePolicies(toDelPolicies)
      .then(async () => {
        await sdkServices.sendEmailDeleteUserPolicies({ user: id, customer: customerId });
        refreshData();
      })
      .catch((e: any) => addMessage({ message: e.message }));
  };

  const deleteInvite = (id: any) => {
    const invite = customerInvites[id];
    deleteInviteThunk(invite?.inviteId)
      .then(refreshData)
      .catch((e: any) => addMessage({ message: e.message }));
  };

  const handleDeleteClose = () => {
    setToDeleteUser(null);
    setToDeleteUserInvite(null);
  };

  const handlePopoverOpen = (event: any, policies: any) => {
    setHoverRow(event.currentTarget);

    const data = policies.reduce((data: any, policy: any) => {
      const { level, entityId, application, role } = policy;
      const appName = capitalize(application);

      if (!data[level]) {
        data[level] = {
          [entityId]: {
            apps: [appName],
            role: roles[role]?.label
          }
        };

      }
      else if (!data[level][entityId]) {
        data[level][entityId] = {
          apps: [appName],
          role: roles[role]?.label
        };
      }
      else {
        data[level][entityId].apps.push(appName);
      }

      return data;
    }, {});
    setHoverPolicies(data);

  };

  const handlePopoverClose = () => {
    setHoverRow(null);
    setHoverPolicies(null);
  };

  const deletePolicies = (ids: any) => {
    return sdkPolicy.deleteMultiPolicy(ids);
  };

  const calcPermissions = (policies: any[]) => {

    const txtObj: any = {};
    const ents: any = [];
    for (let pol of policies) {
      if (ents.indexOf(pol.entityId) > -1) {
        continue;
      }
      if (txtObj[pol.level]) {
        txtObj[pol.level] = txtObj[pol.level] + 1;
      }
      else {
        txtObj[pol.level] = 1;
      }
      ents.push(pol.entityId);
    }

    const txtArr = Object.keys(txtObj).map((lvl: any) => {
      return `${txtObj[lvl]} ${lvlMap[lvl]}`;
    });
    return txtArr.join(", ");

  };

  const createInvite = (data: any) => {
    const payload = { ...data, customer: customerId };
    return sdkInvite.createInvite(payload);
  };

  const refreshData = () => {
    setRefresh((currentVal) => currentVal + 1);
  };

  if (!isInitialized) { return <Loading />; }
  const currentUserRow = currentUser?.id && customerUsers && customerUsers[currentUser?.id];
  return (
    <>
      <div className={classes.view}>
        <ServiceNavigationBar {...props} />
        <div className={classes.contentArea}>
          <Header
            path={["User Management"]}
            hideSystemSelection
            hideUnitSelection
            searchComponent={searchComponent}
            screenTitle="userMgmt"
          />

          {!customerId ?
            <FilterRequire type={t`customer`} /> :
            <>
              <div className={classes.buttonContainer}>
                <Button
                  disabled={!canAddInvite || !canCreateInvites}
                  onClick={() => setOpenNewInvite(true)}
                >
                  {t`Invite User`}
                </Button>
              </div>
              <Paper elevation={0} className={classes.paperTableContainer}>
                <TableContainer >
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          className={classes.sortableCol}
                          align="left"
                          style={{
                            width: "20%"
                          }}
                          onClick={() => {
                            if (sortBy === "name") {
                              setIsAsc((prev) => !prev);
                              return;
                            }
                            setIsAsc(true);
                            setSortBy("name");
                          }}
                        >
                          <div className={classes.sortedCol}>
                            {t`Name`}
                            {
                              sortBy === "name" ?
                                <SortUp className={classes.sortIcons} flip={!isAsc} /> :
                                <Sort className={classes.sortIcons} />
                            }
                          </div>
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          className={classes.sortableCol}
                          align="left"
                          style={{
                            width: "20%"
                          }}
                          onClick={() => {
                            if (sortBy === "email") {
                              setIsAsc((prev) => !prev);
                              return;
                            }
                            setIsAsc(true);
                            setSortBy("email");
                          }}
                        >
                          <div className={classes.sortedCol}>
                            {t`Email`}
                            {
                              sortBy === "email" ?
                                <SortUp className={classes.sortIcons} flip={!isAsc} /> :
                                <Sort className={classes.sortIcons} />
                            }
                          </div>
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Permission`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          className={classes.iconsColumnWidth}
                          align="left"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentUserRow?.id && <TableRow
                        hover
                        tabIndex={-1}
                        classes={{ root: classes.overWritePadding }}
                        className={clsx(currentUserRow?.isEditable === false && classes.disabledUser, !currentUserRow?.permissions?.canUpdatePolicies && classes.disabledRow)}
                        onDoubleClick={() => {
                          setSelectedUserId(currentUserRow.id);
                        }}
                      >
                        <TableCell
                          scope="row"
                          classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                          align="left"
                        >
                          {`(**You) ${currentUserRow.firstName} ${currentUserRow.lastName}`}
                        </TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                          align="left"
                        >
                          {currentUserRow.email}
                        </TableCell>
                        <TableCell
                          scope="row"
                          classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                          align="left"
                        >
                          <Typography
                            className={classes.tooltip}
                            onMouseEnter={(e) => handlePopoverOpen(e, currentUserRow.policies || [])}
                            onMouseLeave={() => handlePopoverClose()}
                          >
                            {calcPermissions(currentUserRow.policies || [])}
                          </Typography>
                        </TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.actionTableCell, classes.limitWidth) }}
                          align="left">

                        </TableCell>
                      </TableRow>}
                      {_.orderBy((Object.values(filteredUsers)), [(user: any) => user?.permissions?.canUpdatePolicies, (user: any) => sortBy === "name" ? `${user.firstName} ${user.lastName}`?.toUpperCase() : user.email?.toUpperCase()],
                        ["desc", isAsc ? "asc" : "desc"]).map((user: any, index) => {
                          if (user.id === currentUser.id) {
                            return null;
                          }
                          const canEdit = user?.permissions?.canUpdatePolicies;
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              classes={{ root: classes.overWritePadding }}
                              className={clsx(user.isEditable === false && classes.disabledUser, !canEdit && classes.disabledRow)}
                              onDoubleClick={() => {
                                if (!canEdit) {
                                  return;
                                }
                                setSelectedUserId(user.id);
                              }}
                            >
                              <TableCell
                                scope="row"
                                classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                                align="left"
                              >
                                {`${user.firstName} ${user.lastName}`}
                              </TableCell>
                              <TableCell
                                classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                                align="left"
                              >
                                {user.email}
                              </TableCell>
                              <TableCell
                                scope="row"
                                classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                                align="left"
                              >
                                <Typography
                                  className={classes.tooltip}
                                  onMouseEnter={(e) => canEdit ? handlePopoverOpen(e, user.policies) : {}}
                                  onMouseLeave={() => canEdit ? handlePopoverClose() : {}}
                                >
                                  {canEdit ? calcPermissions(user.policies) : ""}
                                </Typography>
                              </TableCell>
                              <TableCell
                                classes={{ root: clsx(classes.actionTableCell, classes.limitWidth) }}
                                align="left">
                                {!canEdit ?
                                  <Lock className={classes.lockIcon} /> :
                                  <LightTooltip title={t`Delete User`}>
                                    <IconButton disabled={!canDeleteUser} disableRipple onClick={() => setToDeleteUser(user)} classes={{ root: classes.overWriteIcon }}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </LightTooltip>
                                }
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {_.orderBy(filteredInvites, [(invite: any) => invite.email?.toUpperCase()],
                        ["asc"]).map((invite: any, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              classes={{ root: classes.overWritePadding }}
                              onDoubleClick={() => setSelectedInvite(invite)}
                            >
                              <TableCell
                                scope="row"
                                classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                                align="left"
                              >
                                <div style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}>
                                  <AiOutlineMail style={{ fontSize: "20px", marginRight: "5px" }} />
                                  {t`Pending Invite`}
                                </div>
                              </TableCell>
                              <TableCell
                                classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                                align="left"
                              >
                                {invite.email}
                              </TableCell>

                              <TableCell
                                scope="row"
                                classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                                align="left"
                              >
                                <Typography
                                  className={classes.tooltip}
                                  onMouseEnter={(e) => handlePopoverOpen(e, invite.policies)}
                                  onMouseLeave={() => handlePopoverClose()}
                                >
                                  {calcPermissions(invite.policies)}
                                </Typography>
                              </TableCell>
                              <TableCell classes={{ root: classes.actionTableCell }} align="left">
                                <div className={classes.invButtonContainer}>
                                  <LightTooltip title={t`Delete Invite`}>
                                    <IconButton disabled={!canDeleteInvite} disableRipple onClick={() => setToDeleteUserInvite(invite)} classes={{ root: classes.overWriteIcon }}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </LightTooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

            </>}
        </div>
      </div>

      {toDeleteUser && (
        <DeleteDialog
          open={!!toDeleteUser}
          type={t`User`}
          object={toDeleteUser}
          detach={tryDeleteUser}
          onClose={handleDeleteClose}
        />)}
      {toDeleteUserInvite && (
        <DeleteDialog
          open={!!toDeleteUserInvite}
          type={t`Invite`}
          object={toDeleteUserInvite}
          detach={deleteInvite}
          onClose={handleDeleteClose}
        />)}

      {
        hoverRow &&
        <Popover
          open={true}
          anchorEl={hoverRow}
          onClose={handlePopoverClose}
          classes={{ paper: classes.popoverPaper }}
          style={{ pointerEvents: "none" }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          disableRestoreFocus
        >
          {
            Object.keys(hoverPolicies)?.map((level: any) => {
              return (
                <div key={`lvl-${level}`} className={classes.popoverLevel}>
                  <Typography className={classes.popoverLevelTitle}>{`${lvlMap[level].charAt(0).toUpperCase() + lvlMap[level].slice(1)}s`}</Typography>
                  {
                    Object.keys(hoverPolicies[level]).map((entity: any) => {
                      return (
                        <div key={entity} className={classes.popoverEntity}>
                          <Typography className={classes.popoverEntityName}>{getEntityNameById(entity)}</Typography>
                          <Typography className={classes.popoverApps}>{`${hoverPolicies[level][entity]?.role} - ${hoverPolicies[level][entity]?.apps?.join(", ")}`}</Typography>
                        </div>
                      );
                    })
                  }

                </div>
              );
            })
          }
        </Popover>
      }
      {
        (openNewInvite || selectedInvite) &&
        <NewInvite
          onClose={() => {
            setOpenNewInvite(false);
            setSelectedInvite(null);
          }}
          createInvite={createInvite}
          refreshData={refreshData}
          customerId={customerId || ""}
          inviteObj={selectedInvite}
          editInvite={!!selectedInvite}
        />
      }
      {
        selectedUserId &&
        <EditUser
          user={customerUsers[selectedUserId]}
          onClose={() => setSelectedUserId("")}
          deletePolicies={deletePolicies}
          customerId={customerId || ""}
          refreshData={refreshData}
          isCurrentUser={currentUser?.id === customerUsers[selectedUserId]?.id}
        />
      }

    </>
  );
};

export default UserList;
