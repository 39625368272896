import {
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Close, LinkOff, Sort } from "@material-ui/icons";
import clsx from "clsx";
import { System as SystemSdk } from "coolremote-sdk";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import { Loader } from "../../components/Loader";
import ErrorBox from "../../components/WarningBox/ErrorBox";
import CoolButton from "../../cool_widgets/Button";
import { Arrow as SvgArrow, SortUp } from "../../icons";
import { DeleteIcon, EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AddEditSystem.style";
import AutoMapping from "./AutoMapping";

const SystemServiceMapping = (props: any) => {
  const classes = useStyles();
  const { selectedSystemInfo, actions, refreshUnits, setRefreshUnits, isService, selectedUnit } = props;

  const unitTypesMirror = useStoreState((state) => state.unitTypesMirrror);
  const { capacityMeasurementUnitTypes } = useStoreState(
    (state) => state.types
  );
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const updateUnitLocally = useStoreActions(
    (actions) => actions.units.updateUnitLocally
  );
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const updateUnits = useStoreActions((actions) => actions.units.updateUnits);
  const updateUnitSystem = useStoreActions((actions) => actions.units.updateUnitSystem);
  const addUnitToSys = useStoreActions((actions) => actions.units.updateUnitSystem);
  const getDeviceLines = useStoreActions((actions) => actions.devices.getDeviceLines);
  const getLineUnits = useStoreActions((actions) => actions.devices.getDeviceUnitsAPI);
  const assoUnit = useStoreActions((actions) => actions.units.assoControlAndService);

  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disrcardPopup, setDisrcardPopup] = useState<boolean>(false);
  const [units, setUnits] = useState<any>({ all: {}, service: {} });
  const [sorting, setSorting] = useState<any>({ service: { by: "name", isAsc: true } });
  const [editField, setEditField] = useState<any>({ unitId: null, fieldId: null });
  const [lineControlUnits, setLineControlUnits] = useState<any>({});
  const [lineControlUnitsNotAssociated, setLineControlUnitsNotAssociated] =
    useState<number>(0);
  const [lines, setLines] = useState<any>([]);
  const [capacityUnits, setCapacityUnits] = useState<any>({});
  const [capacityUnitsOptions, setCapacityUnitsOptions] = useState<any>([]);
  const [systemLine, setSystemLine] = useState<any>(null); //only needed for Daikin line
  const [activatedMappingProgress, activateMappingProgress] =
    useState<boolean>(false);
  const [refreshSystemData, setRefreshSystemData] = useState<boolean>(false);
  const { selectedSystem, selectedDevice } = selectedSystemInfo;
  const sysObj: any = allSystems[selectedSystem];
  const isDaikin: boolean = +sysObj?.brandNum === 1;
  const { isMapping = false } = systemLine || {};
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { canUpdate = true } = sysObj || {};
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    if (_.isEmpty(capacityMeasurementUnitTypes)) {
      return;
    }
    const units = Object.keys(capacityMeasurementUnitTypes).reduce(
      (result: any, item: any) => {
        const val = capacityMeasurementUnitTypes[item];
        result.map[val] = item;
        result.options.push(
          <MenuItem key={item} value={val}>
            {item}
          </MenuItem>
        );
        return result;
      },
      { map: {}, options: [] }
    );
    setCapacityUnits(units.map);
    setCapacityUnitsOptions(units.options);
  }, [capacityMeasurementUnitTypes]);

  useEffect(() => {
    setLoading(true);
    getDeviceLines(selectedDevice)
      .then((res: any) => {
        setLines(Object.values(res));
        //get system line data for automapping status (only for Daikin)
        let systemLine = null;
        for (let i in res) {
          const line = res[i];
          if (line.id === sysObj?.line) {
            systemLine = line;
            break;
          }
        }
        if (systemLine?.brand === 1) {
          setSystemLine(systemLine);

          //set counter to keep pulling mapping status if already in progress
          if (systemLine?.isMapping) {
            activateMappingProgress(true);
          }
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let timer: any = null;
    if (!activatedMappingProgress) {
      clearInterval(timer);
      timer = null;
      return;
    }

    timer = setInterval(() => {
      getDeviceLines(selectedDevice).then((res: any) => {
        let systemLine = null;
        for (let i in res) {
          const line = res[i];
          if (line.id === sysObj?.line) {
            systemLine = line;
            break;
          }
        }

        setSystemLine(systemLine);
        if (!systemLine?.isMapping) {
          setRefreshSystemData(!refreshSystemData);
          setTimeout(() => {
            activateMappingProgress(false);
          }, 2000);
        }
        actions.fetchData();
      });
    }, 30000);

    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, [activatedMappingProgress]);

  useEffect(() => {
    if (!selectedSystem) {
      return;
    }

    SystemSdk.getUnits(selectedSystem).then((sysUnits: any) => {
      const unitsData: any = Object.values(sysUnits).reduce(
        (result: any, item: any) => {
          if (unitTypesMirror[item.type] === "indoor") {
            result.IDU.push(
              <MenuItem key={`${item.id}-ctrlOption`} value={item.id}>
                {item.address}
              </MenuItem>
            );
          }
          if (unitTypesMirror[item.type] === "service") {
            result.service[item.id] = item;
            if (item.system === selectedSystem) {
              result.serviceCount += 1;
            }
          }

          return result;
        },
        {
          IDU: [
            <MenuItem
              key={`00-ctrlOption`}
              value={""}
            >{t`Unassigned`}</MenuItem>
          ],
          service: {},
          serviceCount: 0
        }
      );

      setUnits({ all: sysUnits, service: unitsData.service });
    });
  }, [selectedSystem, refreshSystemData]);

  useEffect(() => {
    if (!selectedDevice || !selectedSystem || !lines?.length) {
      return;
    }

    const sysObject: any = allSystems[selectedSystem];

    if (!sysObject) {
      return;
    }
    const lineObj = lines.filter((l: any) => l.id === sysObject.line)[0];
    const prom = [getLineUnits({ id: selectedDevice, line: sysObject.line })];

    const supportControlLine =
      +sysObject?.brandNum === 3 ||
      +sysObject?.brandNum === 12 ||
      +sysObject?.brandNum === 18;
    if (supportControlLine && lineObj?.controlLine) {
      const controlLineObj = lines.filter(
        (l: any) => l._id === lineObj?.controlLine
      )[0];
      if (controlLineObj) {
        prom.push(
          getLineUnits({ id: selectedDevice, line: controlLineObj.id })
        );
      }
    }

    Promise.all(prom).then((resp: any) => {
      let controlUnitsNotAssociated = 0;
      let data: any = {};
      for (let x of resp) {
        data = { ...data, ...x };
      }

      const tempUnits = Object.values(data)?.reduce((result: any, u: any) => {
        if (unitTypesMirror[u.type] === "indoor") {
          result[u.id] = u;
          if (!u.serviceUnits.length) {
            controlUnitsNotAssociated += 1;
          }
        }
        return result;
      }, {});

      setLineControlUnits(tempUnits);
      setLineControlUnitsNotAssociated(controlUnitsNotAssociated);
    });
  }, [selectedSystem, selectedDevice, lines, refreshSystemData]);

  const isOpen = selectedSystem;

  const handleClose = () => {
    if (isDirty)
      setDisrcardPopup(true);
    else
      actions.openSrvMapDialog(null, null)
  };

  const unlinkUnit = (id: string) => {
    updateUnitSystem({
      unitId: id,
      oldSystem: selectedSystem,
      newSystem: ""
    }).then(() => {
      const { [id]: removeAll, ...restAll } = units.all;
      const { [id]: remoteType, ...restType } = units.service;

      setUnits({
        ...units,
        all: { ...restAll },
        service: { ...restType }
      });
    }).finally(() => actions.fetchData());
  };

  // const updateUnitRow = async (vals: any) => {
  //   const keys = ["name", "isVisible", "serialNumber", "capacity", "model", "capacityMeasurementUnits"];
  //   const updatedData: any = _.pick(vals, keys);
  //   const oldData: any = _.pick(units.all[editField.unitId], keys);
  //   if (!updatedData.model) {
  //     updatedData.model = "";
  //   }
  //   if (!updatedData.serialNumber) {
  //     updatedData.serialNumber = "";
  //   }

  //   if (_.isMatch(updatedData, oldData)) return;

  //   setSaving(true);
  //   const fullUpdatedUnit = { ...units.all[editField.unitId], ...vals };

  //   const controlUnit = vals.controlUnit && units.all[vals.controlUnit];
  //   const isSingleAsso = controlUnit && (!controlUnit?.serviceUnits?.length || (controlUnit?.serviceUnits?.length === 1 && controlUnit?.serviceUnits[0] === editField.unitId));

  //   const allNew = { ...units.all, [editField.unitId]: { ...fullUpdatedUnit } };
  //   if (vals.controlUnit && lineControlUnits[vals.controlUnit]) {
  //     await addUnitToSys({ unitId: vals.controlUnit, oldSystem: "", newSystem: selectedSystem });
  //     const { [vals.controlUnit]: newUnit, ...restOfUnits } = lineControlUnits;
  //     setLineControlUnits({ ...lineControlUnits, [vals.controlUnit]: { ...lineControlUnits[vals.controlUnit], system: selectedSystem } });
  //     allNew[newUnit.id] = newUnit;
  //   }
  //   assoUnit({ controlId: vals.controlUnit, srvId: vals.id })
  //     .then(() => {
  //       return updateUnit({ id: editField.unitId, updatedData, updateAssociated: false });
  //     })
  //     .then(() => {
  //       if (isSingleAsso) {
  //         return updateUnit({
  //           id: controlUnit.id,
  //           updatedData: {
  //             capacity: updatedData?.capacity,
  //             capacityMeasurementUnits: updatedData?.capacityMeasurementUnits
  //           },
  //           updateAssociated: false
  //         });
  //       }
  //     })
  //     .then(() => {
  //       const updatedUnits = {
  //         ...units,
  //         all: allNew,
  //         service: {
  //           ...units.service, [vals.id]: { ...fullUpdatedUnit }
  //         }
  //       };
  //       if (isSingleAsso) {
  //         updatedUnits.all[controlUnit.id] = {
  //           ...updatedUnits.all[controlUnit.id],
  //           capacity: updatedData?.capacity,
  //           capacityMeasurementUnits: updatedData?.capacityMeasurementUnits
  //         };
  //       }
  //       setUnits(updatedUnits);
  //       setRefreshUnits(refreshUnits + 1);
  //     })
  //     .then(() => setEditField({}))
  //     .catch((err: any) => addMessage({ message: err.message }))
  //     .finally(() => {
  //       updateUnitLocally({ id: fullUpdatedUnit.id, unit: fullUpdatedUnit });
  //       setSaving(false);
  //     });
  // };

  const updateSort = (type: string, column: string) => {
    const sortObj: any = sorting[type];
    if (sortObj.by === column) {
      sortObj.isAsc = !sortObj.isAsc;
    } else {
      sortObj.by = column;
      sortObj.isAsc = true;
    }

    setSorting({ ...sorting, [type]: { ...sortObj } });
  };

  // const handleFieldEdit = (values: any, unitId: string, fieldId: string) => {
  //   if (editField.unitId) {
  //     updateUnitRow(values);
  //   }
  //   setEditField({ unitId, fieldId });
  // }


  const handleUpdateUnits = () => {
    if (isDirty && formikRef.current) { formikRef.current.handleSubmit() }
    actions.openSrvMapDialog(null, null);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={!!isOpen}
      maxWidth="lg"
      fullWidth
    >
      {saving && <Loader showLoader={true} className={classes.loaderWrapper} />}

      <div className={classes.dialogHeader}>
        <Typography
          className={classes.headerTitle}
        >{t`Service Address Mapping`}</Typography>
        <IconButton
          disableRipple
          className={classes.iconBtnStyle}
          onClick={handleClose}
        >
          <Close style={{ color: "#7f7692" }} />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        {loading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.unitsBox}>
            {systemLine && isService && (
              <div className={classes.autoMappingSys}>
                <AutoMapping
                  disabled={!canUpdate}
                  deviceId={selectedDevice}
                  line={systemLine}
                  activatedMappingProgress={activatedMappingProgress}
                  activateMappingProgress={activateMappingProgress}
                  actions={actions}
                  lineControlUnits={
                    Object.values(lineControlUnits)?.length || 0
                  }
                  lineControlUnitsNotAssociated={
                    lineControlUnitsNotAssociated || 0
                  }
                />
              </div>
            )}
            <Formik
              innerRef={formikRef}
              initialValues={{ items: _.orderBy(Object.values(units.service), [sorting.service.by], [sorting.service.isAsc ? "asc" : "desc"]) }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const updatedUnits = _.differenceWith(values.items, Object.values(units.all), _.isEqual).map((unit: any) => ({
                  unitId: unit.id,
                  serialNumber: unit.serialNumber,
                  name: unit.name,
                  capacity: unit.capacity,
                  capacityMeasurementUnits: unit.capacityMeasurementUnits,
                  model: unit.model,
                  isVisible: unit.isVisible,
                  controlUnit: unit.controlUnit
                }));
                updateUnits({ unitsArr: updatedUnits });
                setRefreshUnits(refreshUnits + 1);
              }}
            >
              {({ dirty, values }) => (
                // @ts-ignore
                <Form style={{ width: "100%" }}>
                  {setIsDirty(dirty)}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: "20%",
                            minWidth: "155px"
                          }}
                        >
                          <div
                            className={classes.tableHeadCont}
                            onClick={() => updateSort("service", "name")}
                          >
                            {t`SRV Unit Name`}
                            {sorting.service.by === "name" ? (
                              <SortUp
                                className={classes.sortIconBase}
                                flip={sorting.service.isAsc}
                              />
                            ) : (
                              <Sort className={classes.sortIconBase} />
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                            minWidth: "130px"
                          }}
                        >
                          <div
                            className={classes.tableHeadCont}
                            onClick={() =>
                              updateSort(
                                "service",
                                isDaikin ? "airnet" : "address"
                              )
                            }
                          >
                            {isDaikin ? t`AirNet(Internal)` : t`Address`}
                            {sorting.service.by ===
                              (isDaikin ? "airnet" : "address") ? (
                              <SortUp
                                className={classes.sortIconBase}
                                flip={sorting.service.isAsc}
                              />
                            ) : (
                              <Sort className={classes.sortIconBase} />
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "23%"
                          }}
                        >
                          <div
                            className={classes.tableHeadCont}
                            onClick={() => updateSort("service", "capacity")}
                          >
                            {t`Capacity`}
                            {sorting.service.by === "capacity" ? (
                              <SortUp
                                className={classes.sortIconBase}
                                flip={sorting.service.isAsc}
                              />
                            ) : (
                              <Sort className={classes.sortIconBase} />
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "15%"
                          }}
                        >
                          {t`Model`}
                        </TableCell>
                        <TableCell style={{ width: "0%" }}>{t`SN`}</TableCell>
                        <TableCell
                          style={{
                            width: "10%"
                          }}
                        >
                          {t`Control ID`}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%"
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        values.items.map((unit: any, index: any) => {
                          const { canUpdate: canUpdateUnit = true } = unit || {};
                          return (
                            <>
                              <TableRow key={`${unit.id}-${index}`} className={clsx(!unit.isConnected && classes.disconnectedRow, selectedUnit === unit.id && classes.highLight)}>
                                <TableCell>
                                  <div className={classes.disconnectedIconParent}>
                                    <Field name={`items[${index}].name`} >
                                      {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                    </Field>
                                    {!unit.isConnected && <LinkOff style={{ color: "#ff6565", marginLeft: "5px" }} />}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={classes.disconnectedIconParent}>
                                    <Typography>{isDaikin ? `${unit.airnet}(${unit.address})` : unit.address}</Typography>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <Field name={`items[${index}].capacity`}>
                                    {({ field }: any) =>
                                      <TextField
                                        {...field}
                                        variant="outlined"
                                        size="small"
                                        value={field.value || 0}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Field name={`items[${index}].capacityMeasurementUnits`}>
                                                {({ field }: any) =>
                                                  <FormControl
                                                    className={classes.textField}
                                                    variant="filled"
                                                  >
                                                    <Select
                                                      variant="standard"
                                                      className={classes.input}
                                                      IconComponent={SvgArrow}
                                                      disableUnderline
                                                      {...field}
                                                      value={field.value || "hp"}
                                                    >
                                                      {capacityUnitsOptions}
                                                    </Select>
                                                  </FormControl>}
                                              </Field>
                                            </InputAdornment>
                                          )
                                        }}
                                      />}
                                  </Field>
                                </TableCell>
                                <TableCell>
                                  <Field name={`items[${index}].model`}>
                                    {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                  </Field>
                                </TableCell>
                                <TableCell style={{ minWidth: "152px" }}>
                                  <Field name={`items[${index}].serialNumber`}>
                                    {({ field, form, meta }: any) => <TextField {...field} variant="outlined" size="small" />}
                                  </Field>
                                </TableCell>

                                <TableCell>
                                  <Field name={`items[${index}].controlUnit`} >
                                    {({ field, form, meta }: any) =>
                                      <FormControl
                                        className={classes.textField}
                                        variant="filled"
                                      >
                                        <Select
                                          error={meta.error}
                                          variant="outlined"
                                          className={classes.input}
                                          IconComponent={SvgArrow}
                                          displayEmpty={true}
                                          {...field}
                                          value={field.value || ""}
                                        >
                                          <MenuItem value={""}>{t`Unassigned`}</MenuItem>
                                          {Object.values(lineControlUnits).map((u: any) => {
                                            if (u.system === selectedSystem || !u.system) {
                                              return <MenuItem key={u.id} value={u.id}>{`${u.address} ${!u.system ? t`(Unassigned)` : ""}`}</MenuItem>;
                                            }
                                            return null;
                                          })}
                                        </Select>
                                      </FormControl>}
                                  </Field>
                                </TableCell>

                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      width: "100%",
                                      height: "100%"
                                    }}>
                                    <IconButton disabled={isMapping || !canUpdateUnit} onClick={() => unlinkUnit(unit.id)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <div className={classes.modalActions}>
        <CoolButton
          title={t`close edit`}
          variant="contained"
          onClick={handleClose}
          width={150}
          white
          marginRight
        >
          {t`Close`}
        </CoolButton>

        <CoolButton
          title={t`Save edit`}
          variant="contained"
          onClick={handleUpdateUnits}
          width={150}
          marginRight
          disabled={!isDirty}
        >
          {t`Save`}
        </CoolButton>


      </div>
      {
        disrcardPopup && <ErrorBox
          title={t`Discard Changes`}
          error={"Are you sure you want to close without saving changes?"}
          cancelTitle={t`Yes`}
          acceptTitle={t`No`}
          onClose={() => actions.openSrvMapDialog(false)}
          onAccept={() => setDisrcardPopup(false)}
        />
      }

    </Dialog >
  );
};

export default SystemServiceMapping;
