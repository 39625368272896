import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto"
  },

  container: {
    width: "100%",
    background: "rgb(236, 235, 239)",
    alignContent: "flex-start"
  },
  leftCard: {
    marginBottom: "15px"
  },
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px"
  },
  header: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px"
  },
  rightCard: {
    marginBottom: "15px",
    marginLeft: "15px",
    paddingLeft: "20px",
    borderLeft: "1px solid #d5d2d5"
  },

  submitButton: {
    width: "100%"
  },
  dialog: {
    width: "100%",
    borderRadius: "8px"
  },
  title: {
    height: "60px",
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    alignItems: "center",
    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignContent: "center"
    }
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  personalDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title

  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0,
    height: "640px"
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  labelSelect: { transform: "translate(14px, 11px) scale(1)" },
  line: {
    margin: "25px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column"
  },
  secondElement: {
    marginLeft: "30px"
  },
  fullInput: {
    width: "100%"
  },
  confirmBtn: {
    padding: "0 25px"
  },
  arrowIcon: {
    fontSize: "30px",
    top: "unset"
  },
  input: {
    textAlign: "left",
    height: "44px",
    width: "100%",
    "& label": {
      color: "rgba(255, 255, 255, 0.7)",
      "&focus": {
        color: "rgba(255, 255, 255, 0.7)"
      }
    },
    "& div": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    "& MuiInputBase-root": {
      color: "white"
    },
    "&>svg": {
      width: "25px",
      height: "25px"
    }
  },
  frequencyFormControl: {
    width: "30%"
  },
  selectedEmails: {
    backgroundColor: `${theme.palette.background.default} !important`,
    color: `${theme.overrides.MuiButton.contained.color} !important`,
    fontSize: "15px !important",
    "font-weight": "bold !important",
    borderRadius: "10px !important"
  },
  label: {
    color: "#7f7182",
    fontSize: "17px",
    marginBottom: "7px"
  },
  error: {
    color: "red",
    fontSize: "15px",
    fontFamily: "Roboto",
    height: "20px"
  }
}));

export default useStyles;
