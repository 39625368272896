import React from "react";

const SvgArrowDownControl = props => (
  <svg width={36} height={14} {...props}>
    <path
      fill="none"
      stroke="#FFF"
      strokeLinecap="round"
      strokeWidth={1.459}
      d="M35.682 0L17.841 13.838 0 0"
    />
  </svg>
);

export default SvgArrowDownControl;
