import { Device as DeviceSdk, System as SystemSdk } from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { IDevice } from "../Devices";
import { IRootStoreModel } from "../RootStore";
import { ISystem } from "../Systems";
import { IUnit } from "../Units";

export interface ISystemActions {
  deleteSystem: Thunk<IRootStoreModel, { id: string }>;
  assignUnitToSystem: Thunk<
    IRootStoreModel,
    { systemId: string; unitId: string },
    any /*injections*/,
    IRootStoreModel
  >;
  unassignUnitFromSystem: Thunk<
    IRootStoreModel,
    { systemId: string; unitId: string }
  >;

  _storeCreateSystem: Action<
    IRootStoreModel,
    { deviceId: string; newSystemData: ISystem }
  >;
  _storeDeleteSystem: Action<IRootStoreModel, { id: string }>;
  _storeAssignUnitToSystem: Action<
    IRootStoreModel,
    { systemId: string; unitId: string }
  >;
  _storeUnassignUnitFromSystem: Action<
    IRootStoreModel,
    { systemId: string; unitId: string }
  >;
  assignUnitToSystem1: Thunk<
    IRootStoreModel,
    { systemId: string; unitId: string },
    any /*injections*/,
    IRootStoreModel
  >;
}

export const systemActions: ISystemActions = {

  deleteSystem: thunk(async (actions, payload) => {
    return SystemSdk.delete(payload.id)
      .then(() => {
        actions._storeDeleteSystem(payload);
      });

  }),

  assignUnitToSystem: thunk(async (actions, payload, store) => {
    const unit: IUnit | undefined = store.getStoreState().units.allUnits[
      payload.unitId
    ];

    // First, unassign the unit from the previous system in the BE.
    if (unit && unit.system) {
      await SystemSdk.removeUnit(unit.system, payload.unitId);
    }

    await SystemSdk.addUnit(payload.systemId, payload.unitId);
    // TODO: Error handling is now important because we removed the unit from the previous system in the BE at this point.

    actions._storeAssignUnitToSystem(payload);
  }),

  unassignUnitFromSystem: thunk(async (actions, payload) => {
    await SystemSdk.removeUnit(payload.systemId, payload.unitId);

    actions._storeUnassignUnitFromSystem(payload);
  }),

  _storeCreateSystem: action((state, payload) => {
    const device: IDevice | undefined =
      state.devices.allDevices[payload.deviceId];

    state.systems.allSystems[payload.newSystemData.id] = payload.newSystemData;

    if (!_.isUndefined(device)) {
      if (device.systems.indexOf(payload.newSystemData.id) === -1) {
        device.systems.push(payload.newSystemData.id);
      }
    }
  }),

  _storeDeleteSystem: action((state, payload) => {
    const system: ISystem | undefined = state.systems.allSystems[payload.id];
    const device: IDevice | undefined =
      system && system.device
        ? state.devices.allDevices[system.device]
        : undefined;

    if (!_.isUndefined(device)) {
      _.pull(device.systems, payload.id);
    }

    delete state.systems.allSystems[payload.id];
    if (system) {
      _.forEach(system.units, (unitId) => {
        const unit: any = state.units.allUnits[unitId];

        if (!_.isUndefined(unit)) {
          // Sanity.
          if (unit.system === payload.id) {
            delete unit?.system;
          }
        }
      });
    }
    // Reset systems selection if needed
    if (state.selections.selections.systemId === payload.id)
      state.selections.selections.systemId = null;
  }),

  _storeAssignUnitToSystem: action((state, payload) => {
    const system: ISystem | undefined =
      state.systems.allSystems[payload.systemId];
    const unit: IUnit | undefined = state.units.allUnits[payload.unitId];
    const previousSystem: ISystem | undefined =
      unit && unit.system ? state.systems.allSystems[unit.system] : undefined;
    // Note: We do not handle a glitch where a system has a reference to a unit but the unit doesn't.

    if (!_.isUndefined(unit)) {
      unit.system = payload.systemId;
    }

    if (!_.isUndefined(previousSystem)) {
      _.pull(previousSystem.units, payload.unitId);
    }

    if (!_.isUndefined(system)) {
      if (system.units.indexOf(payload.unitId) === -1) {
        system.units.push(payload.unitId);
      }
    }
  }),

  _storeUnassignUnitFromSystem: action((state, payload) => {
    const system: ISystem | undefined =
      state.systems.allSystems[payload.systemId];
    const unit: any = state.units.allUnits[payload.unitId];

    if (!_.isUndefined(system)) {
      _.pull(system.units, payload.unitId);
    }

    if (!_.isUndefined(unit)) {
      delete unit?.system;
    }
  }),
  assignUnitToSystem1: thunk(async (actions, payload, store) => {
    await SystemSdk.addUnit(payload.systemId, payload.unitId);
    actions._storeAssignUnitToSystem(payload);
  })

};
