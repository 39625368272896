import {
  Dialog,
  FormControl,
  FormHelperText,
  IconButton, InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import DeleteWithConfirm from "../../components/Delete/Delete";
import Tooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { Add, ArrowDownO, Close, Delete } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";
import useStyles from "./PowerMetersControl.style";

const PowerMetersControl: React.FC<{ site?: ISite | null, refresh: any, close: any }> = ({ site, refresh, close }) => {
  const createVirtualPowerMeter = useStoreActions((state) => state.powerMeters.createVirtualPowerMeter);
  const updateMultiPowerMeter = useStoreActions((state) => state.powerMeters.updateMultiPowerMeter);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const { powerMeterTypes } = useStoreState((state: any) => state.types);
  const updateSystemPowerMeters = useStoreActions((action) => action.systems.updateSystemPowerMeters);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const fetchSiteSystems = useStoreActions((action) => action.sites.fetchSiteSystems);
  const getSitePowerMeters = useStoreActions((action) => action.sites.getSitePowerMeters);
  const deletePowerMeter = useStoreActions((action) => action.powerMeters.deletePowerMeter);
  const [allSystems, setAllSystems] = useState<any>([]);
  const [allPowerMeters, setAllPowerMeters] = useState<any>({});
  const [metersOptions, setMetersOptions] = useState<any>([]);
  const [errors, setErrors] = useState<any>({ name: false });
  const [newRows, setNewRows] = useState<any>({});
  const [assignedPowerMeters, setAssignedPowerMeters] = useState<any>({});
  const [tab, setTab] = useState<number>(powerMeterTypes.normal);
  const [virtualPMName, setVirtualPMName] = useState<string>("");
  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const [virtualPowerMeters, setVirtualPowerMeters] = useState<any>({});
  const [sortedVirtualPowerMeters, setSortedVirtualPowerMeters] = useState<any>([]);
  const [deleteCounter, setDeleteCounter] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [optionsLoading, setOptionsLoading] = useState<boolean>(true);
  const [disableAdding, setDisableAdding] = useState<boolean>(false);
  const [virtualPowerMetersErrors, setVirtualPowerMetersErrors] = useState<any>({});
  const { canAddPowerMeter = true } = site || {};
  const [devicesIdsBySite, setDevicesIdsBySite] = useState<any>({});

  const classes = useStyles();
  useEffect(() => {
    const newDevicesIdsBySite: any = {}
    Object.entries(allDevices).map(([deviceId, device]: any) => {
      if (newDevicesIdsBySite[device.site])
        newDevicesIdsBySite[device.site].push(deviceId)
      else
        newDevicesIdsBySite[device.site] = [deviceId];
    })
    setDevicesIdsBySite(newDevicesIdsBySite)
  }, [allDevices])

  useEffect(() => {
    let systems: any = [];
    const newRows: any = {};
    const assignedPowerMeters: any = {};

    site && fetchSiteSystems(site.id)
      .then((res: any) => {
        systems = Object.values(res);
        for (let system of systems) {
          if (!_.isEmpty(system.powerMeters || [])) {
            newRows[system.id] = { ids: [] };
            const powerMetersPerSystem: any = {};
            system.powerMeters.forEach((id: string) => {
              newRows[system.id].ids.push(id);
              powerMetersPerSystem[id] = true;
            });
            assignedPowerMeters[system.id] = powerMetersPerSystem;
          }
        }

        setNewRows(newRows);
        setAllSystems(systems);
        setAssignedPowerMeters(assignedPowerMeters);
        setLoading(false);
      });
    if (site) {
      getSitePowerMeters({ id: site.id })
        .then((res: any) => setAllPowerMeters(res));
      getSitePowerMeters({ id: site.id, type: powerMeterTypes.virtual })
        .then((res: any) => {
          setVirtualPowerMeters(res);
          sortVirtual(res);
        });

    } else {
      setAllPowerMeters([]);
      setVirtualPowerMeters([]);
      sortVirtual([]);
    }

    if (!_.isEmpty(devicesIdsBySite[site?.id || ""])) {
      const firstDevice: any = devicesIdsBySite[site?.id || ""]?.[0];
      setSelectedDevice(firstDevice);
    }
  }, [deleteCounter]);

  useEffect(() => {
    setMetersOptions(getOptions(allPowerMeters));
    setOptionsLoading(false);
  }, [allPowerMeters]);

  const getOptions = (powerMeters: any) => {
    const options = _.map(powerMeters, (item: any) => ({
      name: item.name,
      value: item.id,
      key: item.id
    }));

    return [
      {
        name: "Select Power Meter",
        value: "",
        key: "999"
      },
      ...options
    ];
  };

  const onClose = () => {
    close();
    refresh();
  };

  const handleDeletePowerMeter = (id: string) => {
    deletePowerMeter(id)
      .then(() => {
        const { [id]: toDelete, ...restOfVirtual } = virtualPowerMeters;
        const { [id]: toBeDeleted, ...restOfPowerMeters } = allPowerMeters;
        sortVirtual(restOfVirtual);
        setVirtualPowerMetersErrors({ ...virtualPowerMetersErrors, [id]: false });
        setVirtualPowerMeters(restOfVirtual);
        setAllPowerMeters(restOfPowerMeters);
        setDeleteCounter(deleteCounter + 1);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };
  const onSave = async () => {
    if (hasMultiUpdateError) {
      return;
    }
    for (let systemId in newRows) {
      const system = newRows[systemId];
      const { ids = [] } = system;

      if (ids[ids.length - 1] === "") {
        ids.pop();
      }
      updateSystemPowerMeters({ systemId, data: ids });
    }

    const data = Object.values(virtualPowerMeters).reduce((arr: any, item: any) => {
      const { name, sn, model, id } = item;
      arr.push({ id, name, sn, model });
      return arr;
    }, []);
    updateMultiPowerMeter({ powerMetersData: data });

    onClose();
  };

  const deleteMappedPowerMeter = (systemId: string, meterId: string) => {
    newRows[systemId].ids = newRows[systemId].ids.filter((id: string) => id !== meterId);
    if (meterId === "") {
      newRows[systemId].disabled = false;
    } else {
      delete assignedPowerMeters[systemId][meterId];
      setAssignedPowerMeters({ ...assignedPowerMeters });
    }
    setNewRows({ ...newRows });
  };

  const sortVirtual = (virtualPowerMeters: any) => {
    const sorted = _.orderBy(virtualPowerMeters, ["name"], ["asc"]);
    setSortedVirtualPowerMeters(sorted);
  };

  const addNewRow = (systemId: string) => {
    if (newRows[systemId]) {
      newRows[systemId].ids = [...newRows[systemId].ids, ""];
    } else {
      newRows[systemId] = { ids: [""] };
    }
    newRows[systemId].disabled = true;
    setNewRows({ ...newRows });
  };

  const validateMutli = (updatedData: string, id: string) => {
    if (!updatedData || updatedData.length < 4 || updatedData.length > 25) {
      setVirtualPowerMetersErrors({ ...virtualPowerMetersErrors, [id]: true });
      return;
    } else {
      setVirtualPowerMetersErrors({ ...virtualPowerMetersErrors, [id]: false });
    }
  };

  const validateValues = () => {
    setErrors({ name: "" });
    if (!virtualPMName) {
      setErrors({ name: t`Power meter name is required` });
      return;
    }
    if (virtualPMName.length < 4) {
      setErrors({ name: t`Power meter name should be at least 4 characters ` });
      return;
    }
    if (virtualPMName.length > 25) {
      setErrors({ name: t`Power meter name shouldn't be more than 25 character ` });
      return;
    }
    return true;
  };

  const createVirtual = () => {
    if (!validateValues()) {
      return;
    }
    setDisableAdding(true);
    createVirtualPowerMeter({ device: selectedDevice || devicesIdsBySite[site?.id || ""]?.[0], name: virtualPMName })
      .then((res: any) => {
        setVirtualPowerMeters({ [res.id]: res, ...virtualPowerMeters });
        sortVirtual({ [res.id]: res, ...virtualPowerMeters });
        setAllPowerMeters({ [res.id]: res, ...allPowerMeters });
        setVirtualPMName("");
        setErrors({ name: false });
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      })
      .finally(() => {
        setDisableAdding(false);
      });
  };

  const handleSelectChange = (value: string, systemId: string, meterId: string, index: number) => {
    newRows[systemId].ids[index] = value;
    if (meterId === "") {
      newRows[systemId].disabled = false;
      if (assignedPowerMeters[systemId]) {
        assignedPowerMeters[systemId][value] = true;
      } else {
        assignedPowerMeters[systemId] = { [value]: true };
      }
    } else {
      delete assignedPowerMeters[systemId][meterId];
      assignedPowerMeters[systemId][value] = true;
    }
    setAssignedPowerMeters({ ...assignedPowerMeters });
    setNewRows({ ...newRows });
  };

  const hasPowerMeter = metersOptions.length > 1;
  const hasSystems = allSystems.length > 1;
  const hasMultiUpdateError = Object.values(virtualPowerMetersErrors).includes(true);

  const disableEdit = Object.values(allPowerMeters).every(({ canUpdate = true }: any) => !canUpdate);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={true}
      maxWidth="lg"
      classes={{ paperWidthLg: classes.dialogStyle }}
    >

      <div className={classes.dialogHeader}>
        <Tabs
          value={tab}
          onChange={(event: any, newValue: number) => setTab(newValue)}
          variant="fullWidth"
          aria-label="icon label tabs example"
          classes={{ root: classes.root, indicator: classes.indicatorColor }}
        >
          <Tab value={powerMeterTypes.normal} label={t`Map systems to Power meters`} className={classes.tab} />
          <Tab value={powerMeterTypes.virtual} label={t`Virtual Power meters`} className={classes.tab} />
        </Tabs>
        <IconButton
          disableRipple
          onClick={onClose}
          className={classes.closeBtnStyle}
        >
          <Close color={"#7f7692"} />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        {tab === powerMeterTypes.normal &&
          (loading || optionsLoading ? null : hasSystems ? (
            hasPowerMeter ? _.map(allSystems, (system: ISystem, key: string) => {
              return (
                <div key={key} className={classes.systemContainer}>
                  <div className={classes.systemTitleContainer}>
                    {system.name}
                    <Tooltip placement="top" title={t`Add power meter mapping`}>
                      <IconButton disableRipple className={clsx(classes.addIcon, classes.iconBtnStyle, { [classes.disabled]: newRows[system.id] && newRows[system.id].disabled })} onClick={() => addNewRow(system.id)} disabled={(newRows[system.id] && newRows[system.id].disabled) || !canAddPowerMeter}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {newRows[system.id] &&
                    (newRows[system.id].ids).map((meterId: string, index: number) => (
                      <div className={classes.rowContainer} key={`row-${system.id}-${meterId || 999}`}>
                        <Select
                          disabled={!canAddPowerMeter}
                          displayEmpty
                          onChange={(event: any) => handleSelectChange(event.target.value, system.id, meterId, index)}
                          variant="outlined"
                          disableUnderline
                          classes={{ icon: classes.arrowDownIcon }}
                          IconComponent={ArrowDownO}
                          className={classes.selectStyle}
                          placeholder={t`Power Meter`}
                          value={meterId}
                          MenuProps={{
                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            transformOrigin: { vertical: "top", horizontal: "left" },
                            getContentAnchorEl: null
                          }}
                        >
                          {metersOptions.map((option: any) => <MenuItem key={option.key} value={option.value} disabled={assignedPowerMeters[system.id] && assignedPowerMeters[system.id][option.value]} className={clsx({ [classes.hidden]: option.value === "" })}>
                            {option.name}
                          </MenuItem>
                          )}
                        </Select >
                        <IconButton disabled={!canAddPowerMeter} disableRipple className={classes.iconBtnStyle} onClick={() => deleteMappedPowerMeter(system.id, meterId)}>
                          <Delete />
                        </IconButton>
                      </div>
                    ))}
                </div>
              );
            })
              : (<Typography>
                {t`No Power meters connected.`}
              </Typography>)
          )
            : (<Typography>
              {t`No Systems to map power meters to it.`}
            </Typography>)
          )}
        {tab === powerMeterTypes.virtual &&
          (!_.isEmpty(devicesIdsBySite[site?.id || ""]) ? (
            < >
              <div className={classes.createSpan}>
                <Typography className={classes.subTitle}>{t`Create a Virtual Power Meters`}</Typography>
                <TextField
                  disabled={!canAddPowerMeter}
                  variant="outlined"
                  type="text"
                  tabIndex={2}
                  placeholder={t`Virtual Power Meter Name`}
                  value={virtualPMName}
                  onChange={(e: any) => setVirtualPMName(e.target.value)}
                  InputProps={{ classes: { root: classes.outlinedInputRoot } }}
                  error={errors.name}
                />
                {<FormHelperText className={classes.requiredError}>{errors.name || " "}</FormHelperText>}
                <div className={clsx(classes.middleModalActions)}>
                  <Button
                    disabled={!canAddPowerMeter}
                    title={t`cancel`}
                    onClick={() => { setVirtualPMName(""); setErrors({ name: false }); }}
                    width={150}
                    white="white"
                    marginRight={true}
                  >
                    {t`Cancel`}
                  </Button>

                  <Button
                    title={t`add`}
                    type="submit"
                    width={150}
                    disabled={disableAdding || virtualPMName === "" || !canAddPowerMeter}
                    onClick={createVirtual}>
                    {t`Add`}
                  </Button>
                </div>
              </div>
              <div className={classes.editSpan}>
                <Typography className={classes.subTitle}>{t`Edit Virtual Power Meters`}</Typography>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell classes={{ root: classes.tableHeadCell }}> {t`Name`}</TableCell>
                        <TableCell classes={{ root: classes.tableHeadCell }}>{""}</TableCell>
                        <TableCell classes={{ root: classes.tableHeadCell }} >{t`Delete`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedVirtualPowerMeters.map((powerMeter: any) => {
                        const { id } = powerMeter;
                        const { name, canUpdate = true, canDelete = true } = virtualPowerMeters[id];
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={id}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.tableCell, classes.largeWidth) }}
                              align="left"
                            >
                              <FormControl
                                className={classes.textField}
                                variant="filled"
                              >
                                <OutlinedInput
                                  disabled={!canUpdate}
                                  onChange={((event: any) => { setVirtualPowerMeters({ ...virtualPowerMeters, [id]: { ...virtualPowerMeters[id], name: event.target.value } }); validateMutli(event.target.value, id); })}
                                  value={name}
                                  margin="dense"
                                  className={classes.inputFieldStyle}
                                  error={virtualPowerMetersErrors[id]}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.tableCell, classes.smallWidth) }}
                            ></TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.tableCell, classes.smallWidth) }}
                              align="left"
                            >
                              <DeleteWithConfirm
                                disabled={!canDelete}
                                type={t`Virtual Power meter`}
                                object={powerMeter}
                                detach={() => handleDeletePowerMeter(id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          ) : (<Typography>
            {t`You can't add virtual power meters, this site has no devices.`}
          </Typography>)
          )}

      </div>
      <div className={classes.modalActions}>
        {<FormHelperText className={clsx(classes.requiredError, classes.multiPowerMetersError)}>{hasMultiUpdateError ? t`Updated virtual power meter name is required and must be between 4 and 25 characters` : " "}</FormHelperText>}
        <Button
          disabled={disableEdit}
          title={t`close edit`}
          onClick={onClose}
          width={150}
          white="white"
          marginRight={true}
        >
          {t`Cancel`}
        </Button>

        <Button
          title={t`save`}
          type="submit"
          width={150}
          disabled={(tab === powerMeterTypes.normal && !hasPowerMeter) || disableEdit}
          onClick={onSave}>
          {t`Save`}
        </Button>
      </div>
    </Dialog >
  );
};

export default PowerMetersControl;
