import React from "react";

const SvgFan4 = props => (
  <svg width={26} height={28} {...props}>
    <g
      fill={props.color1 || "#FDFDFE"}
      fillRule="evenodd"
      opacity={props.opacity}
    >
      <rect width={3} height={11} y={17} rx={1.5} />
      <rect width={3} height={16} x={7} y={12} rx={1.5} />
      <rect width={3} height={23} x={15} y={5} rx={1.5} />
      <rect width={3} height={28} x={23} rx={1.5} />
    </g>
  </svg>
);

export default SvgFan4;
