import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Group as sdkGroup } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import DatesList from "../../cool_widgets/DatesList/DatesList";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Switch } from "../../cool_widgets/Switch";
import { Close } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import { AddIcon, ArrowBack, Edit } from "../../svgComponents";
import AddEditSchedule from "../AddEditSchedule/AddEditSchedule";
import Tooltip from "../Tooltip/LightTooltip";
import styles from "./scheduleList.style";

const ScheduleList: React.FC<any> = (props: any) => {
  const {
    isGroup,
    hideSchedulesPanel,
    item = {},
    allSchedules,
    setAllSchedules,
    addItemSchedule,
    deleteItemSchedule,
    setOpenDeleteDialog,
    isHalfCDegreeEnabled
  } = props;

  const types = useStoreState((state) => state.types);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getGroup = useStoreActions((action) => action.groups.getGroup);
  const [openAddEditSchedule, handleAddEditSchedule] = useState<string>("");
  const [disabledGroupSchedules, setDisabledGroupSchedules] = useState<any>([]);
  const [disabledSystemScheduleArr, setDisabledSystemScheduleArr] = useState<any>([]);
  const [toDeleteItem, setToDeleteItem] = useState<any>(null);
  const [groupSchedules, setGroupSchedules] = useState<any>([]);

  const { updateSchedule, deleteSchedule } = useStoreActions((actions) => actions.schedules);
  const { getSystemSchedules } = useStoreActions((actions) => actions.systems);
  const { scheduleCategories, weekDays } = types;
  const { id: itemId, schedules = [], system = "", capabilityFlags = {}, permissions = {} } = item;
  const { canUpdateSchedules, canCreateSchedules, canDeleteSchedules } = permissions;
  const { enableSetpointControl = false } = capabilityFlags;

  const itemSchdules = [...new Set([...schedules, ...groupSchedules])];

  useEffect(() => {
    if (_.isEmpty(item) || _.isEmpty(allSchedules)) {
      return;
    }
    if (!isGroup) {
      system && getSystemSchedules(system)
        .then((res: any) => {
          setDisabledSystemScheduleArr(Object.values(res).map((schedule: any) => schedule.id));
        });
      let schedules: string[] = [];
      if (item?.groups) {
        const APIs = [];
        for (const groupId of item?.groups) {
          APIs.push(sdkGroup.getGroupSchdeules(groupId));
          // APIs.push(getGroup(groupId));
        }
        Promise.allSettled(APIs).then((resp: any) => {
          for (let index in resp) {
            const result = resp[index];
            const { status, value: groups = {} }: any = result;
            schedules = [...schedules, ...Object.keys(groups)]
            // if (status === "fulfilled") {
            //   const groupSchedules = group?.schedules || [];
            //   groupSchedules?.forEach((scheduleId: string) => {
            //     allSchedules[scheduleId] && schedules.push(scheduleId);
            //   });
            // }
          }
          setDisabledGroupSchedules([...new Set(schedules)]);

        });
      }
    }
  }, []);

  useEffect(() => {
    if (!isGroup) {
      return;
    }
    sdkGroup.getGroupSchdeules(item.id)
      .then((resp: any) => {
        setGroupSchedules(Object.keys(resp));
      });
  }, [isGroup]);

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        deleteItemSchedule(isGroup, itemId, scheduleId);
      })
      .catch((err: any) =>
        addMessage({ message: err.message })
      )
      .finally(() => {
        handleDeleteClose();
        if (isGroup) {
          sdkGroup.getGroupSchdeules(item.id)
            .then((resp: any) => {
              setGroupSchedules(Object.keys(resp));
            });
        }
      });
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({ id, data: { isDisabled } }).then(() => {
      setAllSchedules({
        ...allSchedules,
        [id]: { ...allSchedules[id], isDisabled }
      });
    })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const saveScheduleData = (scheduleId: string, updatedData: any) => {

    allSchedules[scheduleId] = updatedData;
    setAllSchedules(allSchedules);
  };

  const handleDeleteClose = () => {
    setToDeleteItem(null);
    setOpenDeleteDialog(false);
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const Header = () => <div className={classes.headerStyle}>
    <IconButton onClick={hideSchedulesPanel} className={classes.bigIconBtnStyle}>
      <ArrowBack />
    </IconButton>
    <Typography style={{ color: "#fff", fontSize: 25 }}>{t`Schedules`}</Typography>
    <IconButton disabled={!canCreateSchedules} onClick={() => handleAddEditSchedule("new")} className={classes.bigIconBtnStyle}>
      <AddIcon />
    </IconButton>
  </div>;

  const Schedule = ({ scheduleId, notEditable = false, systemSchedule = false, groupSchedule = false }: any) => {
    const schedule = allSchedules[scheduleId];

    if (!schedule) { return null; }

    const {
      powerOnTime,
      powerOffTime,
      setpoint,
      days = [],
      dates = [],
      isDisabled,
      name: scheduleName,
      scheduleCategory
    } = schedule;
    const startHour =
      powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
    const endHour =
      powerOffTime || powerOffTime === 0
        ? minsToTime(powerOffTime, timeFormat)
        : "";

    return (<div
      className={clsx(classes.scheduleContainer, { [classes.notEditable]: notEditable })}
      key={`schedule-${scheduleId}`}
    >
      <div className={classes.scheduleHeader}>
        <div className={classes.scheduleTitle}>
          <Typography className={classes.longNamestyle}>
            {scheduleName + (groupSchedule ? t` - Group` : systemSchedule ? t` -  System` : "")}
          </Typography>
        </div>
        <div className={classes.scheduleControl}>
          <Tooltip title={t`Edit Schedule`}>
            <IconButton disabled={notEditable || !canUpdateSchedules} onClick={() => handleAddEditSchedule(scheduleId)} className={classes.iconBtnStyle}>
              <Edit className={classes.editIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t`Delete Schedule`}>
            <IconButton
              disableRipple
              disabled={notEditable || !canDeleteSchedules}
              onClick={() => {
                setOpenDeleteDialog(true);
                setToDeleteItem(schedule);
              }}
              classes={{ root: classes.overWriteIcon }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={classes.scheduleBody}>
        <div className={classes.rowContainer}>
          <div className={classes.timeRow}>
            <div className={classes.timeSection}>
              <Typography className={classes.timeText}> {t`Start: ` + (powerOnTime || powerOnTime === 0 ? startHour : `None`)}</Typography>
              <Typography className={classes.timeText}> {t`End: ` + (powerOffTime || powerOffTime === 0 ? endHour : `None`)}</Typography>
            </div>
          </div>

          {(isGroup ? !!setpoint : (enableSetpointControl && !!setpoint)) && (
            <div className={classes.setpointContainer}>
              <div className={classes.setPointSection}>
                <Typography className={classes.setPointStyle}>
                  {isHalfCDegreeEnabled ? Math.floor(setpoint * 2) / 2 : Math.round(setpoint)}
                </Typography>
                <Typography className={classes.tempSymbolStyle}>
                  {temperatureSymbol()}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className={classes.bodyRow}>
          {scheduleCategory === scheduleCategories.weekly &&
            <DaysList
              days={Object.keys(weekDays)}
              activeDays={days}
              action={() => { }}
              disablePointer
            />}
          {scheduleCategory === scheduleCategories.calendar &&
            <DatesList
              dates={dates}
            />}
        </div>
        <div className={classes.bodyRow} style={{ padding: "16px 0" }}>
          <Typography className={classes.scheduleStatusStyle}>
            {isDisabled ? t`Status: Inactive` : t`Status: Active`}
          </Typography>
          <div className={classes.controlSec}>
            <Switch
              disabled={notEditable || !canUpdateSchedules}
              checked={!isDisabled}
              disableRipple={true}
              onChange={(e: any) =>
                changeScheduleActiveState(e, scheduleId)
              }
              value={true}
            />
          </div>
        </div>
      </div>
    </div>
    );
  };

  return <div className={classes.screenContainer}>
    {!openAddEditSchedule && <>
      <Header />
      <div className={classes.schedulesContainer}>
        {itemSchdules.length === 0 && disabledGroupSchedules.length === 0 && disabledSystemScheduleArr.length === 0 ? (
          <Typography
            className={classes.noSchedulesStyle}
          >{t`There are no schedules to show`}</Typography>
        ) : (
          <>
            {itemSchdules.map((scheduleId: string) => <Schedule scheduleId={scheduleId} key={`schedule-${scheduleId}`} />)}
            {disabledGroupSchedules.map((scheduleId: string) => <Schedule scheduleId={scheduleId} key={`schedule-${scheduleId}`} notEditable groupSchedule />)}
            {disabledSystemScheduleArr.map((scheduleId: string) => <Schedule scheduleId={scheduleId} key={`schedule-${scheduleId}`} notEditable systemSchedule />)}
          </>)}
      </div>
    </>}
    {openAddEditSchedule && <AddEditSchedule
      editMode={openAddEditSchedule !== "new"}
      itemId={itemId}
      isGroup={isGroup}
      scheduleData={{ ...allSchedules[openAddEditSchedule] } || {}}
      hideSchedulesPanel={hideSchedulesPanel}
      closeAddEditSchedule={() => handleAddEditSchedule("")}
      saveScheduleData={saveScheduleData}
      addItemSchedule={addItemSchedule}
      isHalfCDegreeEnabled={isHalfCDegreeEnabled}
    />
    }
    {toDeleteItem && (
      <Dialog
        open={toDeleteItem}
        onClose={(event: any) => {
          event.stopPropagation();
          handleDeleteClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`Delete Schedule`}</Typography>
          <IconButton disableRipple onClick={() => setToDeleteItem(null)} className={classes.overWriteIcon}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.DialogContentText}>
            {t`Are you sure you want to delete ` + toDeleteItem?.name + ` schedule?`}
          </Typography>
        </div>
        <div className={classes.deleteDialogActions}>
          <Button
            onClick={() => deleteSelectedSchedule(toDeleteItem.id)}
            className={classes.yesBtn}
            white width={130}
            marginRight
          >
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={() => handleDeleteClose()}
            width={130}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>
    )}
  </div>;
};

export default ScheduleList;
