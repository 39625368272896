import { alpha } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 83px)",
      maxHeight: "calc(100% - 83px)",
      display: "flex",
      background: "white",
      flexFlow: "column nowrap",
      justifyContent: "space-between"
    },
    pageContent: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      flex: 1,
      overflow: "auto",
      maxHeight: "calc(100% - 83px)",
      height: "calc(100% - 83px)"
    },
    tabStyle: {
      marginTop: "10px"
    },
    tabMedia: {
      textTransform: "capitalize",
      "&.MuiTab-textColorInherit.Mui-selected": {
        color: "#4b1c46"
      },
      "@media (min-width: 600px)": {
        minWidth: "unset"
      }
    },
    indicator: {
      backgroundColor: "#4b1c46"
    },
    workingHoursContainer: {
      backgroundColor: alpha("#aaa2aa", 0.15),
      height: 71,
      minHeight: 71,
      minWidth: 175,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
      borderRadius: 6,
      margin: "auto",
      padding: "0 20px"
    },
    workingHoursTextStyle: {
      fontFamily: "Roboto",
      fontSize: 13,
      fontWeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      lineHeight: "15px",
      marginBottom: 10
    },
    graphContainer: {
      width: "100%",
      height: "78%",
      display: "flex",
      alignItems: "center"
    },
    workingHours: {
      fontFamily: "Roboto",
      fontSize: 32,
      fontWeight: "normal",
      lineHeight: "43px",
      height: 43,
      letterSpacing: "normal",
      color: "#4b1c46"
    },
    legendsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // width: "calc(100% - 100px)",
      margin: "auto",
      maxWidth: "345px"
    },
    legendHolder: {
      display: "inline-flex",
      alignItems: "center"
    },
    legendColor: {
      width: 9,
      height: 9,
      borderRadius: 2,
      marginRight: 7
    },
    legendName: {
      fontFamily: "Roboto",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#4d4d4d"
    }
  });
