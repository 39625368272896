import { Box, Dialog, DialogActions, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { Close } from "../../icons";
import useStyles from "./SiteCountCard.style";

const dashStyle = {
  strokeLinecap: "butt",
  rotation: 1 / 2 + 1 / 9.9,
  trailColor: "#d5d2d5",
  pathColor: "#00b259"
};
const dashesNum = 20;
const oneDegree = 1 / dashesNum;

const PowerCircle: React.FC<any> = (props) => {
  const { realMaxPower, maxPower, actualPower, title, systemsNumber, includedSystems, systemsPower } = props;
  const classes = useStyles();
  const notAllSystems = systemsNumber > includedSystems;

  const [openSystemsPower, setOpenSystemsPower] = useState<boolean>(false);
  const percentage = (actualPower / realMaxPower) * 100;
  const currentMax = realMaxPower !== 0 ? maxPower / (realMaxPower / (360 * 0.8)) : 0;

  const PowerDataTooltip = () => {
    return <div className={classes.tooltipTitle}>
      <Typography className={classes.addMarginBottom}>{t`Calculated Total Power for the Site:`}</Typography>
      <Typography><span className={classes.boldFont}>{t`Now:`}</span> {actualPower} kW</Typography>
      <Typography><span className={classes.boldFont}>{t`Highest since connection:`}</span> {maxPower?.toFixed()} kW</Typography>
      <Typography className={classes.addMarginBottom}><span className={classes.boldFont}>{t`Maximum limit:`}</span> {realMaxPower?.toFixed()} kW</Typography>
      <Typography>{t`Click the graph to see detailed Power measures for each system.`}</Typography>
      <Typography className={classes.noteStyle}><span className={classes.boldFont}>{t`Note: `}</span> {t`All numbers are estimated calculation and not based on actual power meters.`}</Typography>
    </div>;
  };
  return (
    <div id="site" className={classes.siteCard}>
      <div className={classes.container}>
        <div className={classes.cardHeader}>
          <Typography className={classes.cardTitle}>{t`Power`}</Typography>
        </div>

        <div onClick={() => !_.isEmpty(systemsPower) && setOpenSystemsPower(true)}>
          <div className={classes.powerRow}>
            <div id="column1" className={clsx(classes.column1Holder, classes.powerWidth)}>
              <Tooltip arrow interactive placement="bottom" title={<PowerDataTooltip />} >
                <div className={classes.relativeContainer}>
                  <CircularProgressbarWithChildren
                    value={realMaxPower !== 0 ? percentage : 0}
                    strokeWidth={10}
                    styles={buildStyles(dashStyle)}
                    circleRatio={0.8}
                  >
                    <Typography className={classes.powerText}>
                      {realMaxPower !== 0 ? (Math.round(actualPower)) : 0}
                      <span className={classes.powerKW}>kW</span>

                    </Typography>
                    {_.range(dashesNum).map((index) => {
                      return (
                        <div
                          key={`num-${index}`}
                          style={{ transform: `rotate(${index * oneDegree}turn)` }}
                          className={classes.dashDegree}
                        >
                          <div className={classes.dash} />
                          {(index === 8 || index === 12) &&
                            <div className={classes.edges}></div>
                          }
                        </div>
                      );
                    })}
                    {
                      <div
                        style={{ transform: `rotate(${currentMax - 144}deg)` }}
                        className={classes.currentMaxContainer}
                      >
                        <div className={classes.redCurrentMax}></div>
                      </div>
                    }

                    <Typography className={classes.powerInidcator}>0 kW</Typography>
                    <Typography className={clsx(classes.powerInidcator, classes.rightPowerIndicator)}>
                      {Math.round(realMaxPower)} kW
                    </Typography>
                  </CircularProgressbarWithChildren>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classes.powerRow}>
            <Typography className={classes.indoorUnitsText}>{title}</Typography>
          </div>
          {notAllSystems &&
            <div className={classes.powerRow}>
              <Typography className={classes.indoorUnitsText}>{`${includedSystems}/${systemsNumber}` + t` systems`}</Typography>
            </div>
          }
        </div>
      </div>
      {openSystemsPower &&
        <Dialog
          open={true}
          onClose={() => setOpenSystemsPower(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{t`Power usage per system`}</Typography>
            <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setOpenSystemsPower(false)}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
          <DialogContent className={classes.dialogContent} style={{ padding: "20px" }}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell classes={{ root: classes.tableHeadCell }}>{t`System Name`}</TableCell>
                    <TableCell classes={{ root: classes.tableHeadCell }}>{t`Maximum`}</TableCell>
                    <TableCell classes={{ root: classes.tableHeadCell }}>{t`Highest`}</TableCell>
                    <TableCell classes={{ root: classes.tableHeadCell }}>{t`Now`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.orderBy(Object.values(systemsPower), ["realMax", "max", "actual"], ["desc", "desc", "desc"]).map((systemData: any, index: any) => {
                    const { realMax, max, actual, systemName } = systemData;
                    const realPowerExists = realMax && realMax !== 0;

                    return (
                      <TableRow key={`unit-row-${index}`}>
                        <TableCell classes={{ root: classes.tableCell }}>
                          {systemName}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>
                          {realPowerExists ? realMax : t`N/A`}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>
                          {realPowerExists ? max : t`N/A`}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }}>
                          {realPowerExists ? actual : t`N/A`}
                        </TableCell>
                      </TableRow>

                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions className={classes.actionsHolder}>
            <Typography>
              <Box fontWeight="bold" display="inline">{t`*Estimated power, `}</Box>
              {t`available for specific brand & model only`}
            </Typography>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

export default PowerCircle;
