import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AddEditSystem.style";

const SystemServiceMapping = (props: any) => {
  const classes = useStyles();
  const { disabled = false, activatedMappingProgress, activateMappingProgress, actions, deviceId, line, lineControlUnits = 0, lineControlUnitsNotAssociated = 0 } = props;

  const allDevices = useStoreState((state) => state.devices.allDevices);
  const mapLineIndoors = useStoreActions((actions) => actions.devices.mapLineIndoors);

  const [open, setOpen] = useState<boolean>(false);
  const [preventMappingAlreadyMapped, setPreventMappingAlreadyMapped] = useState<boolean>(false);
  const [temperatureRange, setTemperatureRange] = useState<boolean>(false);
  const [minTemp, setMinTemp] = useState<number>(16);
  const [maxTemp, setMaxTemp] = useState<number>(32);

  const { isMapping = false, mappingCurrent, mappingTotal } = line || {};
  const isDeviceSupportMapping = allDevices[deviceId]?.protocolVersion >= 2;
  const startAutoMapping = async () => {
    let data: any = {
      unmappedUnitsOnly: preventMappingAlreadyMapped,
      enableSetpointRange: temperatureRange
    };
    if (temperatureRange) {
      data.setpointMax = maxTemp;
      data.setpointMin = minTemp;
    }

    await mapLineIndoors({ deviceId, lineId: `${line?._id}`, data });
    setOpen(false);
    activateMappingProgress(true);
    actions.fetchData();
  };

  return (
    <>
      {(isMapping || activatedMappingProgress) ?
        <div className={classes.mappingContainer}>
          <Typography className={classes.mappingText}>
            {t`Mapping`}... ({mappingCurrent}/{mappingTotal})
            <span className={classes.mappingPercentage}>{mappingCurrent === 0 && mappingTotal === 0 ? "100" : Math.round(mappingCurrent / mappingTotal * 100)}%</span>
          </Typography>
          <Typography className={classes.mappingTime}>{(Math.round((mappingTotal - mappingCurrent) / 2) * 100) / 100} {t`min left`}</Typography>
          <LinearProgress classes={{ root: classes.progressRoot, bar: classes.progressBar }} variant="determinate" value={mappingCurrent === 0 && mappingTotal === 0 ? 100 : Math.round(mappingCurrent / mappingTotal * 100)} />
        </div> :
        <Button
          disabled={disabled}
          white
          onMouseUp={() => setOpen(true)}
          className={classes.autoBtn}
        >
          {t`Automatic Address Mapping`}
        </Button>
      }

      {open &&
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="mapping-dialog-title"
          aria-describedby="mapping-dialog-description"
          maxWidth="sm"
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{isDeviceSupportMapping ? t`Automatic unit address mapping` : t`Auto Mapping in Progress...`}</Typography>
            <IconButton onClick={() => setOpen(false)} className={classes.iconBtnStyle}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            {isDeviceSupportMapping ?
              <>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  <Box fontWeight="fontWeightMedium" display="inline">{t`Be Advised: `}</Box>
                  {t`\nThe units will be switched off and into fan mode and then restored to their original status upon completion (on/off, mode, setpoint). Please avoid modifying the units' settings until it is done.`}
                </Typography>

                <div className={classes.selectionBlock}>
                  <FormControlLabel
                    control={<Checkbox
                      checked={preventMappingAlreadyMapped}
                      color="default"
                      onChange={(event: any) => setPreventMappingAlreadyMapped(event.target.checked)}
                    />}
                    label={<Typography> {t`Do not try to map units that are already mapped`}</Typography>}
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={temperatureRange}
                      color="default"
                      onChange={(event: any) => {
                        setTemperatureRange(event.target.checked);
                        if (!event.target.checked) {
                          setMinTemp(16);
                          setMaxTemp(32);
                        }
                      }}
                    />}
                    label={<Typography> {t`Use custom mapping temperature range (in case units have a temperature limitation)`}</Typography>}
                  />
                  {/* min from 16 to 26, max from 22 to 32*/}
                  <div className={classes.selectionSection}>
                    <FormControl variant="outlined" className={classes.selectStyle}>
                      <InputLabel>{t`Minimal temperature`}</InputLabel>
                      <Select
                        disabled={!temperatureRange}
                        value={minTemp}
                        variant="outlined"
                        label={t`Minimal temperature`}
                        onChange={(event: any) => {
                          setMinTemp(event.target.value);
                          if (maxTemp < event.target.value + 6)
                            setMaxTemp(event.target.value + 6);
                        }}
                      >
                        {/* end value is not in the range */}
                        {_.range(16, 27).map((number: any) => (
                          <MenuItem value={number} key={number}>{number}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span className={classes.tempSeparator}>-</span>
                    <FormControl variant="outlined" className={classes.selectStyle}>
                      <InputLabel>{t`Maximal temperature`}</InputLabel>
                      <Select
                        disabled={!temperatureRange}
                        value={maxTemp}
                        variant="outlined"
                        label={t`Maximal temperature`}
                        onChange={(event: any) => setMaxTemp(event.target.value)}
                      >
                        {/* end value is not in the range */}
                        {_.range(22, 33).map((number: any) => (
                          <MenuItem disabled={number - minTemp < 6} value={number} key={number}>{number}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                </div>

                <Typography style={{ whiteSpace: "pre-line" }}>
                  {t`We estimate the mapping will take about` + ` ${Math.ceil((preventMappingAlreadyMapped ? lineControlUnitsNotAssociated : lineControlUnits) / (maxTemp - minTemp - 1)) * 6} ` + t`minutes. Do you wish to initiate address mapping?`}
                </Typography>
              </>
              :
              <Typography style={{ whiteSpace: "pre-line" }}>
                {t`Auto mapping requires upgrade to your device FW. Please contact CoolAutomation Support to upgrade`}
              </Typography>
            }
          </div>
          <div className={classes.actionsHolder}>
            {
              isDeviceSupportMapping ? (

                <>
                  <Button
                    onClick={() => setOpen(false)}
                    autoFocus={true}
                    width={100}
                    marginRight
                  >{t`No`}
                  </Button>
                  <Button
                    onClick={startAutoMapping}
                    white
                    width={100}
                  >
                    {t`Yes`}
                  </Button>
                </>) :
                <Button
                  onClick={() => setOpen(false)}
                  width={100}
                >
                  {t`OK`}
                </Button>
            }
          </div>
        </Dialog>
      }
    </>
  );
};

export default SystemServiceMapping;
