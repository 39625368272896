import * as React from "react";

function SvgMobileAudit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <filter id="MobileAudit_svg__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.898039 0 0 0 0 0.886275 0 0 0 0 0.898039 0 0 0 1.000000 0"
          />
        </filter>
        <path
          id="MobileAudit_svg__b"
          d="M0 .75a.75.75 0 101.5 0 .75.75 0 00-1.5 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={12.5} cy={12.5} r={12.5} fill="#4B1C46" />
        <g filter="url(#MobileAudit_svg__a)">
          <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeWidth={1.4}
            d="M12.5 19.25H7.203c-.388 0-.703-.336-.703-.75v-12c0-.414.315-.75.703-.75h9.844c.388 0 .703.336.703.75v6.097"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.4}
            d="M15.5 18.125c1.242 0 2.367-.625 3.375-1.875-1.008-1.25-2.133-1.875-3.375-1.875s-2.367.625-3.375 1.875c1.008 1.25 2.133 1.875 3.375 1.875z"
          />
          <g transform="translate(14.75 15.5)">
            <mask id="MobileAudit_svg__c" fill="#fff">
              <use xlinkHref="#MobileAudit_svg__b" />
            </mask>
            <path
              fill="#FFF"
              d="M-3.75 5.25h9v-9h-9z"
              mask="url(#MobileAudit_svg__c)"
            />
          </g>
          <path
            stroke="#FFF"
            strokeLinecap="square"
            strokeWidth={1.4}
            d="M9.125 9.125h6m-6 2.625h6m-6 2.625h1.5"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgMobileAudit;
