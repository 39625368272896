import React from "react";

const HeatSmall = ({ width = 19, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#F05146" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M13.719 9.79c0 2.568-1.979 4.65-4.419 4.65-2.44 0-4.418-2.082-4.418-4.65 0-2.569 1.978-4.652 4.418-4.652 2.44 0 4.419 2.083 4.419 4.652z" strokeWidth=".866" />
      <path strokeWidth="1.056" d="M9.3 19.093v-2.462M9.3 2.948V.488M13.719 17.897l-1.19-2.168M6.028 3.876 4.882 1.787M14.907 13.25h.002l2.045 1.244M3.65 6.408 1.647 5.191M18.138 9.843h-2.34M2.802 9.841H.464M16.954 5.19 14.95 6.408M1.647 14.493l2.045-1.244h.002M13.719 1.787l-1.147 2.09M6.072 15.73l-1.19 2.167" />
    </g>
  </svg>
);

export default HeatSmall;
