import { Dialog, DialogContentText, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { messageStyles } from "./ErrorStoreMessage.style";

const ErrorStoreMessage = (props: any) => {
  const [open, setOpen] = useState(false);
  const { message } = props;
  const {
    message: messageText,
    buttonText,
    onClick,
    textNotCentered,
    secondaryText,
    mainAction,
    allowDialogClose = true
  } = message;

  const useStyles = makeStyles(messageStyles);
  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const onOkClick = () => {
    setOpen(false);
    onClick && onClick();
  };

  const mainAction1 = () => {
    setOpen(false);
    mainAction && mainAction();
  };

  return (
    <Dialog
      open={open}
      onClose={() => allowDialogClose && setOpen(false)}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogContentText
        className={clsx(classes.contentStyle, {
          [classes.textNotCentered]: textNotCentered
        })}
      >{messageText}</DialogContentText>
      <div className={clsx(classes.btnsContainer, secondaryText ? classes.moveToRight : "")}>
        {secondaryText && <Button onClick={onOkClick} variant="contained" white>
          {secondaryText}
        </Button>}
        <Button onClick={() => mainAction ? mainAction1() : onOkClick()} variant="contained">
          {buttonText || t`Ok`}
        </Button>
      </div>
    </Dialog>
  );
};

export default ErrorStoreMessage;
