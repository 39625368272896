import { makeStyles } from "@material-ui/core/styles";

const aboutUsStyle = makeStyles((theme: any) =>
({
  secreenContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "90%"
  },
  container: {
    height: "100%",
    padding: "53px 17px 0px 26px",
    overflowY: "auto",
    boxSizing: "border-box"
  },

  headers: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "5px"
  },
  subHeaders: {
    paddingTop: "36px"
  },
  subtitles: {
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#545964"
  }
}));

export default aboutUsStyle;
