import { alpha, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    background: "rgb(236, 235, 239)",
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 265px)",
    position: "relative",
  },
  content: {
    padding: "12px",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#ECEBEF",
    position: "relative",
    overflowY: "auto",
  },
  reportContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "45%",
    maxHeight: "45%",
    height: "45%",
    flex: 1,
    marginRight: "12px",
    marginBottom: "12px",
    position: "relative",
  },
  smallContainer: {
    width: "30%",
    minWidth: "30%",
  },
  maxedContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    position: "absolute",
    height: "100%",
    maxHeight: "90%",
    minHeight: "90%",
    margin: "40px",
    width: "unset",
    minWidth: "unset",
  },
  reportContainerHeader: {
    height: "60px",
    width: "100%",
    backgroundColor: "#F6F6F7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
  },
  reportTitle: {
    fontSize: "18px",
    color: "#4b1c46",
    fontWeight: 500,
    marginLeft: "10px",
  },
  reportContainerBody: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#fefefe",
    display: "flex",
    // alignItems: "center",
    padding: "5px 10px",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
  },

  graphTooltip: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    border: `0.5px solid #d5d2d5`,
    borderRadius: "4px",
    width: "180px",
    padding: "5px 10px",
    zIndex: 99999,
  },
  reportHeaderField: {
    width: "160px",
    backgroundColor: "#fff",
  },
  reportHeaderActions: {
    width: "65px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  reportsHeader: {
    width: "100%",
    height: "70px",
    padding: "30px 20px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  customerName: {
    color: "#4b1c46",
    fontSize: "20px",
    fontWeight: 500,
  },
  headerInfoContainer: {
    display: "flex",
    width: "500px",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginLeft: "80px",
  },
  headerInfoSection: {
    display: "flex",
  },
  headerInfoVal: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    marginRight: "6px",
  },
  headerInfoTitle: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#545964",
  },
  headerInfoDivider: {
    width: "1px",
    height: "20px",
    backgroundColor: "#d5d2d5",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0, 0.5)",
    zIndex: 99999,
  },
  system: {
    display: "flex",
    width: "100%",
    backgroundColor: "#f4f4f6",
    margin: "10px 0",
    height: "90px",
  },
  systemInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "44%",
    backgroundColor: "#edebed",
    height: "100%",
    padding: "15px",
    "& .sysCustomer": {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.primary.dark,
    },
    "& .sysInfo": {
      fontSize: "14px",
      fontWeight: "normal",
      color: "#5e5764",
    },
  },
  systemGraphs: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },
  systemGraph: {
    display: "flex",
    flex: 1,
    paddingLeft: "10px",
    width: "90%",
  },
  barRoot: {
    backgroundColor: "transparent",
    height: "8px",
    borderRadius: "6px",
  },
  darkBar: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "6px",
  },
  minBar: {
    backgroundColor: alpha(theme.palette.primary.dark, 0.7),
    borderRadius: "6px",
  },
  lightBar: {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: "6px",
  },
  barLabel: {
    color: theme.palette.primary.dark,
    fontSize: "14px",
    fontWeight: "normal",
    marginRight: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "63px",
  },
  diversityGraphsContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    margin: "10px 0",
    paddingRight: "5px",
  },
  maxShader: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    position: "absolute",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  headerMid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    padding: "0 15px",
    height: "100%",
  },
  searchCont: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  customLegend: {
    display: "flex",
    width: "100%",
    height: "70px",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "58px",
  },
  legendBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F7F7F8",
    height: "90%",
    padding: "5px 10px",
    marginRight: "10px",
    "& p": {
      fontSize: "12px",
    },
  },
  legendBlockInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#545964",
    fontSize: "12px",
  },
  LegendTemp: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  legendTempDivider: {
    backgroundColor: "#d5d2d5",
    width: "2px",
    height: "19px",
    margin: "0 8px",
  },
  legendTempVal: {
    fontSize: "12px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
  },
  purpleBold: {
    color: "#4b1c46",
    fontWeight: "bold",
  },
  doubleGraphCont: {
    display: "flex",
    width: "calc(100% - 30px)",
    flex: 1,
    height: "calc(100% - 15px)",
    paddingTop: "20px",
  },
  customDoubleGraphCont: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    height: "calc(100% - 15px)",
    paddingTop: "20px",
  },
  hide: {
    display: "none",
  },
  lineLegendContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    flex: 1,
    height: "90%",
  },
  lineLegendBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      width: "80px",
      fontSize: "13px",
    },
  },
  lineLegendColor: {
    width: "17px",
    height: "2px",
    marginRight: "5px",
  },
  capacityMsgError: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#f00",
    paddingLeft: "15px",
    position: "absolute",
    bottom: "-10px",
  },
  error: {
    color: "#f00 !important",
  },
  systemHolder: {
    position: "relative",
  },
}));

export default useStyles;
