import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  shrinkStyle: {
    // transform: "translate(3px, -15px) scale(0.75) !important",
    color: theme.palette.primary.light
  },
  helperTextStyle: {
    marginLeft: "2px"
  },
  inputStyle: {
    padding: "16px"
  },
  rootStyle: {
    // backgroundColor: "#efeeefbf"
  }
}));
export default useStyles;
