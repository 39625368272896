import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: any) => ({

  root: {
    width: "100%",
    height: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    display: "flex",
    background: "white",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  pageContent: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    flex: 1,
    height: "calc(100% - 60px)",
    maxHeight: "calc(100% - 60px)"
  },
  screenContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#15111F"
  },
  gridStyle: {
    width: "100%",
    maxWidth: "768px",
    display: "flex",
    flexDirection: "column"
  },
  sitesContainer: {
    display: "flex",
    height: "100%"
  },
  listStyle: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "7px",
    paddingBottom: "0",
    overflow: "auto",
    width: "100%",
    height: "calc(100% - 83px)",
    background: "#f2f2f7"
  },
  listItemStyle: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    padding: "0 20px",
    background: "#fff",
    marginBottom: "7px"
  },
  siteNameContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "55px",
    paddingRight: "10px"
  },
  listItemTextStyle: {
    // opacity: 0.89,
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.dark,
    width: "100%"
  },
  iconHolderStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "45px",
    height: "40px"
  },
  menuStyle: {
    "& ul": {
      padding: "2px"
    },
    "& .MuiListItem-root.Mui-focusVisible": {
      backgroundColor: "unset"
    }
  },
  menuItemStyle: {
    padding: "7px",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    minHeight: "unset"
  },
  overflowIcon: {
    "& g": {
      fill: "#acacac",
      stroke: "#acacac"
    }
  },
  deleteIconStyle: {
    marginRight: "10px"
  },
  editIcon: {
    fontSize: "17px",
    margin: "0 10px 0 2px",
    width: "18px",
    height: "19px"
  },
  plusIconStyle: {
    margin: "0 12px 0 1px",
    "& path": {
      fill: "rgb(170, 162, 170)"
    },
    "& use": {
      fill: "rgb(170, 162, 170)"
    }
  },
  systemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    minHeight: "120px",
    padding: "10px 0"
  },
  systemCard: {
    minHeight: "100px",
    width: "95%",
    borderRadius: "6px",
    background: "#f6f6f7",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start !important",
    justifyContent: "space-between",
    padding: "12px",
    margin: "10px 0"
  },
  systemInfoContainer: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexFlow: "row nowrap"
  },
  systemNameContainer: { display: "flex", width: "75%" },
  systemNameStyle: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginRight: "5px",
    marginBottom: "10px",
    alignSelf: "flex-start"
  },
  systemMenuIconStyle: {
    "& g": {
      fill: "#acacac"
    },
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  systemControlsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    alignSelf: "stretch"
  },
  modeButton: {
    borderRight: "none",
    height: "40px",
    width: "40px",
    cursor: "pointer",
    color: "#fff"
  },
  scheduleIcon: {
    transform: "scale(0.75)",
    marginLeft: "-6px",
    "& g": {
      opacity: 1
    }
  },
  scheduleIconBtn: {
    padding: 0,
    height: "25px",
    width: "30px",
    color: "#aaa2aa",
    "&:hover": {
      background: "none"
    }
  },
  badge: {
    position: "absolute",
    top: "-10px",
    right: "-7px",
    padding: "3px 7px",
    background: theme.palette.primary.main,
    color: "white",
    fontSize: "12px",
    lineHeight: "normal",
    borderRadius: "10px"
  },
  iconContainer: {
    objectFit: "contain",
    height: "50px",
    display: "flex",
    alignItems: "flex-end"
  },
  mainIconsStyle: {
    padding: 0,
    width: "40px",
    height: "36px",
    "&:hover": {
      background: "none"
    }
  },
  systemPowerButton: {
    "& :clicked": {
      backgroundColor: "red"
    }
  },
  systemControlSecondRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flex: 1,
    width: "100%"
  },
  expand: {
    opacity: 0.6,
    marginRight: "-15px",
    transform: "rotate(0deg)",
    padding: 0
  },
  expandOpen: {
    marginRight: "-15px",
    opacity: 0.6,
    transform: "rotate(180deg)",
    padding: 0

  },
  collapseStyle: {
    width: "100%",
    marginBottom: "10px"
  },
  siteFirstRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px"
  },
  typoStyle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlined.color
  },
  blockContainer: {
    borderRadius: "6px",
    border: theme.overrides.MuiButton.contained.border,
    marginBottom: "10px"
  },
  disconnectedUnits: {
    borderTop: theme.overrides.MuiButton.contained.border
  },
  deviceTypoStyle: {
    paddingLeft: "15px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.mid
  },
  siteFontStyle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 3.13,
    letterSpacing: "normal",
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px"
  },
  redText: {
    color: "#f05347"
  },
  alertIcon: {
    marginLeft: "5px"
  },
  disconnectedUnitsExpand: {
    opacity: 0.6,
    padding: 0,
    marginRight: "10px",
    transform: "rotate(0deg)"
  },
  deviceInfoFontStyle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 3.13,
    letterSpacing: "normal",
    width: "100%",
    borderBottom: theme.overrides.MuiButton.contained.border,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    paddingRight: "5px"
  },
  notMappedMessage: {
    fontSize: "16px",
    color: "#f05347",
    padding: "15px"
  },
  disconnectedUnitsExpandOpen: {
    transform: "rotate(180deg)"
  },
  unitContainerStyle: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    padding: "5px 10px",
    margin: "10px",
    borderRadius: "5px"
  },
  rowStyle: {
    height: "43px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%"
  },
  labelStyle: {
    width: "90px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  dividerStyle: {
    width: "100%",
    height: "2px",
    backgroundColor: alpha("#fdfdfe", 0.1)
  },
  disconnectedUnitInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  disconnectedUnitName: {
    display: "flex",
    alignItems: "center"
  },
  dialogStyle: {
    padding: "31px 26px",
    minWidth: "276px",
    width: "276px",
    height: "auto"
  },
  dialogTitle: {
    padding: 0,
    fontSize: "26px",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#4b1c46",
    marginBottom: "13px",
    fontFamily: "RobotoMedium"
  },
  contentStyle: {
    fontSize: "18px",
    color: "#545964",
    lineHeight: "normal",
    fontWeight: "normal",
    margin: 0
  },
  dialogContentStyle: { padding: 0, marginBottom: "26px", paddingRight: 10 },
  cancelButtonStyle: { width: "129px" },
  dialogActionStyle: { padding: 0, margin: 0, justifyContent: "flex-start" },
  confirmButtonStyle: {
    border: "none",
    width: "129px",
    boxShadow: "none",
    background: "red"
  },
  actionsSpacing: { "& :not(:first-child)": { marginLeft: "15px" } },
  deleteInfoBlock: {
    marginTop: "15px",
    marginBottom: "1px"
  },
  deleteNameInput: {
    width: "100%",
    marginTop: "15px"
  },
  boldText: {
    fontWeight: "bold"
  },
  redItemName: {
    color: "red"
  },
  innerInfoCounter: {
    minWidth: "30px",
    width: "30px",
    display: "inline-block"
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  inputStyle: {
    "& input": {
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87) !important"
    }
  },
  pendingSystems: {
    minHight: "100px",
    display: "flex",
    flexDirection: "column",
    width: "95%",
    borderRadius: "6px",
    background: theme.palette.primary.light,
    flexFlow: "column nowrap",
    alignItems: "flex-start !important",
    justifyContent: "space-between",
    padding: "12px",
    margin: "10px 0"
  },
  pendingAck: {
    opacity: "0.2",
    width: "100%"
  },
  pendingAckText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "normal",
    color: "#fdfdfe",
    padding: "13px 0",
    width: "100%",
    textAlign: "center",
    borderRadius: "5px"
  },
  ackButton: {
    borderRadius: "5px",
    width: "100%",
    height: "50px",
    marginTop: "10px",
    border: "none"
  },
  ackButtonText: {
    fontSize: "16px",
    textTransform: "capitalize"
  },
  ackButtonIcon: {
    fontSize: "19px",
    marginRight: "7px"
  },
  IOSListStyle: {
    height: "calc(100% - 123px)"
  }
}));

export default useStyles;

export const paperStyle = {
  marginTop: "43px",
  marginLeft: "-25px",
  borderRadius: "6px",
  boxShadow: `rgba(0, 0, 0, 0.46) 0px 6px 17px 3px`,
  backgroundColor: "#FFF",
  padding: "5px"
};
