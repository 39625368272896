import { Checkbox, Collapse, FormControlLabel, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { ChevronRight, Close, ExpandMore, Search } from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import clsx from "clsx";
import { Customer as sdkCustomer, Services as sdkServices } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { t } from "ttag";
import { useStoreState } from "../../../models/RootStore";
import useStyles from "./PermissionsSummary.style";

interface IPermissionsSummary {
    policies: any;
}

const PermissionsSummary = ({ policies }: IPermissionsSummary) => {

    const [expand, setExpand] = useState<boolean>(true);
    const [parsedPols, setParsedPols] = useState<any>({});
    const [lvlMap, setLvlMap] = useState<any>({});
    const [roles, setRoles] = useState<any>({});

    const getEntityNameById = useStoreState((state) => state.getEntityNameById);

    useEffect(() => {
        sdkServices.getEntityLevel()
            .then((policiesResp: any) => {
                const map = policiesResp;
                const entityRevMap: any = {};
                for (let entName of Object.keys(map)) {
                    entityRevMap[map[entName]] = entName;
                }
                setLvlMap(entityRevMap);
                return sdkServices.getRoles();
            })
            .then((resp: any) => {
                setRoles(resp);
            });
    }, []);

    useEffect(() => {

        if (_.isEmpty(roles)) {
            return;
        }

        const arrPolicies: any = Object.values(policies).reduce((data: any, entity: any) => {
            const apps: any = Object.values(entity);
            data.push(...apps);
            return data;
        }, []);

        const data = arrPolicies.reduce((data: any, policy: any) => {
            const { level, entityId, application, role } = policy;
            const appName = application ? application.charAt(0).toUpperCase() + application.slice(1) : "";

            if (!data[level]) {
                data[level] = {
                    [entityId]: {
                        apps: [appName],
                        role: roles[role]?.label
                    }
                };

            }
            else if (!data[level][entityId]) {
                data[level][entityId] = {
                    apps: [appName],
                    role: roles[role]?.label
                };
            }
            else {
                data[level][entityId]?.apps?.push(appName);
            }

            return data;
        }, {});
        setParsedPols(data);

    }, [policies, roles]);

    const classes = useStyles();
    return (
        <div className={classes.permissionsBox}>
            <div
                className={classes.summaryHead}
                onClick={() => setExpand((prev) => !prev)}
            >
                <FiChevronDown className={clsx(classes.expandIcon, expand && classes.expandedIcon)} />
                <Typography className={classes.summaryTitle}>{t`Permissions`}</Typography>
            </div>

            <Collapse in={expand} timeout="auto" unmountOnExit>
                {
                    !_.isEmpty(lvlMap) && !_.isEmpty(parsedPols) && Object.keys(parsedPols)?.map((level: any) => {
                        return (
                            <div key={`lvl-${level}`} className={classes.popoverLevel}>
                                <Typography className={classes.popoverLevelTitle}>{`${lvlMap[level].charAt(0).toUpperCase() + lvlMap[level].slice(1)}s`}</Typography>
                                {
                                    Object.keys(parsedPols[level]).map((entity: any) => {
                                        return (
                                            <div key={entity} className={classes.popoverEntity}>
                                                <Typography className={classes.popoverEntityName}>{getEntityNameById(entity)}</Typography>
                                                <Typography className={classes.popoverApps}>{`${parsedPols[level][entity]?.role} - ${parsedPols[level][entity]?.apps?.join(", ")}`}</Typography>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        );
                    })
                }
            </Collapse>

        </div>
    );
};

export default PermissionsSummary;
