import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./NavigationMenuItem.style";
interface INavigationMenuItemProps {
  Icon: React.FC<any>;
  name: string;
  path?: string;
  disabled?: boolean;
  dropdown?: any;
  endIcon?: any;
  showDropDown?: boolean;
  onNavigate?: () => void;
  setShowDropDown?: () => void;
  onClick?: (e?: any) => void;
}

const NavigationMenuItem = ({
  Icon,
  name,
  path = "",
  disabled = false,
  onNavigate,
  dropdown,
  endIcon,
  setShowDropDown = _.noop,
  showDropDown = false,
  onClick: doAction
}: INavigationMenuItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const variants = {
    item: {
      default: classes.navMenuItem,
      selected: classes.navMenuItemSelected,
      disabled: classes.navMenuItemDisabled
    },
    subItem: {
      default: classes.navMenuSubItem,
      selected: classes.navMenuItemSelected,
      disabled: classes.navMenuSubItemDisabled
    },
    icon: {
      default: classes.navIcon,
      selected: classes.navIconSelected,
      disabled: classes.navIconDisabled
    }
  };
  const { selections } = useStoreState((state) => state.selections);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);

  const { siteId } = selections;


  const getClassForPath = (path: string, variant: "item" | "icon" | "subItem", disabled: boolean) => {
    if (disabled)
      return clsx(variants[variant].default, variants[variant].disabled);
    return path?.length && history.location.pathname?.indexOf(path) > -1
      ? clsx(variants[variant].default, variants[variant].selected)
      : variants[variant].default;
  };

  const handleClick = (isDisabled: boolean, path: string) => {
    if (!isDisabled) history.push(path);
    if (siteId === "groups") {
      updateSelections({ type: "site", data: null });
    }
    if (onNavigate) onNavigate();
  };

  if (dropdown && dropdown.length) {
    const EndIcon: any = !showDropDown ? endIcon[0] : endIcon[1];

    // highlight dropdown menu only if it's children are chosen && dropdown closed
    const childSelected = dropdown.some((element: any) => element.path === history.location.pathname);

    //should remove the ! from showDropDown as a logic, need to check if they need the select style for menu item
    const highlightMenuItem =
      disabled ? variants.item.disabled :
        childSelected && !showDropDown
          ? clsx(variants.item.default, variants.item.selected)
          : variants.item.default;

    return (
      <div className={classes.navMenuItemContainer}>
        <Button
          startIcon={<Icon className={getClassForPath(path, "icon", disabled)} />}
          endIcon={<EndIcon style={{ "marginLeft": "10px" }} />}
          onClick={() => { if (!disabled) { setShowDropDown(); } }}
          className={highlightMenuItem}
        >
          <Typography variant="subtitle1" className={classes.navItemTitle}>
            {name}
          </Typography>
        </Button>
        {showDropDown &&
          _.map(dropdown, (dropdownData, i) => {
            if (dropdownData.show) {
              return (
                <Button
                  key={i}
                  onClick={() => handleClick(dropdownData.isDisabled, dropdownData.path)}
                  className={getClassForPath(dropdownData.path, "subItem", dropdownData.isDisabled)}
                >
                  <Typography variant="subtitle1">
                    {dropdownData.name}
                  </Typography>
                </Button>
              );
            }
          })
        }
      </div>
    );
  }

  return (
    <Button
      startIcon={<Icon className={getClassForPath(path, "icon", disabled)} />}
      onMouseUp={() => { path !== "" ? handleClick(disabled, path) : doAction && doAction(); }}
      className={getClassForPath(path, "item", disabled)}
    >
      <Typography variant="subtitle1" className={classes.navItemTitle}>
        {name}
      </Typography>
    </Button>
  );
};

export default NavigationMenuItem;
