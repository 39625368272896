import { Close } from "@material-ui/icons";
import { Site as sdkSite } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { t } from "ttag";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import SensorScheduleList from "../../components/SensorScheduleList/ScheduleList";
import { FloorPlan, List, OnGrey as PowerIcon } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { SearchIcon } from "../../svgComponents";
import useStyle from "./Control.style";
import { GroupItem, GroupPanel, PowerMeterPanel, UnitPanel } from "./ControlComponents";
import { SensorPanel } from "./ControlComponents/SensorPanel";

import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@material-ui/core/";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MenuDropDown from "../../components/MenuDropDown/MenuDropDown";
import FloorPlansControl from "./FloorPlansControl";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Control = (props: any) => {
  const classes = useStyle();
  const serviceParams = useStoreState((s) => s.serviceParams);
  const types = useStoreState((state) => state.types);
  const fetchAllUnits = useStoreActions((actions) => actions.units.getUnits);
  const fetchSiteUnits = useStoreActions((actions) => actions.units.fetchSiteUnits);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const setActiveSetpoint = useStoreActions((actions) => actions.units.setActiveSetpoint);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const updateUserPreferences = useStoreActions((action) => action.users.updateUserPreferences);
  const fetchSiteSensors = useStoreActions((action) => action.sensors.getSiteSensors);
  const setActiveFanMode = useStoreActions((state) => state.units.setUnitActiveFanMode);
  const setActiveSwingMode = useStoreActions((state) => state.units.setUnitActiveSwingMode);
  const setActiveOperationMode = useStoreActions((state) => state.units.setActiveOperationMode);
  const setActiveOperationStatus = useStoreActions((state) => state.units.changePowerState);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const {
    fanModesMirror,
    operationStatusesMirror,
    operationModesMirror,
    swingModesMirror,
    temperatureScaleMirror,
    unitSubTypesMirror
  } = useStoreState((state) => state);

  const user = useStoreState((state) => state.users.me);
  const {
    selections,
    getControlUnitsBySite,
    getSitePowerMeters
  } = useStoreState((state) => state.selections);

  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const updateUnit = useStoreActions((a) => a.units._storeUpdateUnit);

  const { allUnits } = useStoreState((s) => s.units);
  const allSites = useStoreState((state) => state.sites.allSites);
  const sitesFlags = useStoreState((state) => state.sites.sitesFlags);
  const { allSensors } = useStoreState((s) => s.sensors);
  const { changeSitePower } = useStoreActions((state) => state.sites);
  const { getTemperatureScaleDisplay } = useStoreState((s) => s.users);
  const getSite = useStoreState((state) => state.sites.getSite);
  const allPowerMeters = useStoreState((state) => state.powerMeters.allPowerMeters);
  const getSiteGroups = useStoreActions((actions) => actions.groups.getSiteGroups);
  const fetchSitePowerMeters = useStoreActions((state) => state.powerMeters.fetchSitePowerMeters);

  const { temperatureScale = 1 } = user;
  const { operationStatuses, fanModes, operationModesExtended, swingModes, unitTypes, unitSubTypes, sensorTypes, sensorMeasurementUnits } = types;
  const isCelsius = +temperatureScaleMirror?.celsius === +temperatureScale;
  const { siteId, customerId } = selections;
  let timer: any = null;
  const [allGroups, setAllGroups] = useState<any>("loading");
  const [newSitePowerInfo, setNewSitePowerInfo] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<any>("");
  const [fullSiteGroups, setFullSiteGroups] = useState<any>({});
  const [filteredUnitsGroups, setFilteredUnitsGroup] = useState<any>({});
  const [siteGroupsArray, setSiteGroupsArray] = useState<any>([]);
  const [filteredSiteGroupsArray, setFilteredSiteGroupsArray] = useState<any>([]);
  const [allSchedules, setAllSchedules] = useState<any>({});
  const [unitsTogroupsMapping, setUnitsToGroupsMapping] = useState<any>({});
  const [siteFloorPlans, setSiteFloorPlans] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>({
    groupId: "",
    unitId: "",
    sensorId: "",
    isGroup: true
  });
  const [externalTemp, setExternalTemp] = useState<any>({ C: null, F: null });
  const [openSchedulesPanel, handleSchedulesPanel] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [siteOffset, setSiteOffset] = useState<number>(0);
  const [pageMode, setPageMode] = useState<"list" | "plans">("list");
  const [selectedFP, setSelectedFP] = useState<any>(null);

  const onSelectItem = (siteId: any, itemSelectedIds: any) => {
    const lastSelectedItems = userPreferences?.serviceLastSelectedItemsPerSite || {};
    updateUserPreferences({ serviceLastSelectedItemsPerSite: { ...lastSelectedItems, [siteId]: itemSelectedIds } });
    handleSchedulesPanel(false);
    setSelectedItem(itemSelectedIds);
  };

  useEffect(() => {
    if (!siteId) {
      setSiteFloorPlans([]);
      return;
    }

    sdkSite.getFloorPlans(siteId)
      .then((floorPlans: any) => {
        setSiteFloorPlans(Object.values(floorPlans));
        return floorPlans;
      })
      .then((floorPlansResp: any) => {
        const lsStateString = localStorage.getItem("viewPerSite") || "{}";
        const lsStateObj = JSON.parse(lsStateString);

        const lsSelectedFPString = localStorage.getItem("selectedFloorPlanPerSite") || "{}";
        const selectedFpObj = JSON.parse(lsSelectedFPString);

        if (lsStateObj[siteId]) {
          setPageMode(!sitesFlags[siteId]?.hasFloorPlans || !lsStateObj[siteId] ? "list" : lsStateObj[siteId]);
        }
        const selectedFPExists = !!floorPlansResp[selectedFpObj[siteId]]; //Object.key(floorPlansResp).filter((FP: any) => FP.id === selectedFpObj[siteId]).length;
        const defaultFPExists = !!floorPlansResp[allSites[siteId]?.defaultFloorPlan]; //Object.keys(filter)((FP: any) => FP.id === allSites[siteId]?.defaultFloorPlan).length;

        if (selectedFpObj[siteId] && selectedFPExists) {
          selectFloorPlan(selectedFpObj[siteId]);
        }
        else if (allSites[siteId]?.defaultFloorPlan && defaultFPExists) {
          selectFloorPlan(allSites[siteId]?.defaultFloorPlan);
        }
        else {
          selectFloorPlan(floorPlansResp[0]?.id);
        }
      });

  }, [siteId]);

  useEffect(() => {
    if (!siteId || !customerId) {
      clearInterval(timer);
      return;
    }

    const customerObj = allCustomers[customerId];
    const shouldNotUseWebsocket = customerObj && customerObj?.useWebsocket === false;

    if (!shouldNotUseWebsocket) {
      clearInterval(timer);
      return;
    }

    timer = setInterval(() => {
      fetchSiteUnits(siteId);
      fetchSiteSensors(siteId);
      fetchSitePowerMeters(siteId);
    }, 15000);

    return () => clearInterval(timer);

  }, [siteId, customerId]);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    fetchSiteUnits(siteId);
    fetchSiteSensors(siteId);
    fetchSitePowerMeters(siteId);
  }, [siteId]);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    getCustomerSchedules(customerId).then((res: any) => setAllSchedules(res))
      .catch((err: any) => addMessage({ message: err.message }));
  }, [customerId, temperatureScale]);

  const changeSearchValue = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);

    if (_.isEmpty(siteGroupsArray)) {
      return;
    }

    if (!value) {
      setFilteredSiteGroupsArray(siteGroupsArray);
      setFilteredUnitsGroup({ ...fullSiteGroups });
      return;
    }
    const { groupsObjectF, groupsArrayF } = filterUnits(value, siteGroupsArray, fullSiteGroups);
    setFilteredUnitsGroup(groupsObjectF);
    setFilteredSiteGroupsArray(groupsArrayF);
  };

  const filterUnits = (value: string, siteGroupsArray: any, fullSiteGroups: any) => {
    const lowCaseValue = value.toLowerCase();
    let newFilteredData = Object.create(filteredUnitsGroups);
    let filteredGroupsArray: any = [];

    siteGroupsArray.forEach((group: any) => {
      const groupId = group[0];
      const { units, name, powerMeters = [], itemsOrder = [] } = fullSiteGroups[groupId];
      const unitsMap = new Map();
      const powerMetersMap = new Map();
      let atleastOneUnitMatch = false;

      itemsOrder.forEach((unit: any) => {
        if (allUnits[unit[0]]) {
          if ((allUnits[unit[0]]?.name)?.toLowerCase().includes(lowCaseValue)) {
            atleastOneUnitMatch = true;
            unitsMap.set(unit[0], true);
            return;
          }
        }
        if (allSensors[unit[0]]) {
          if ((allSensors[unit[0]]?.name)?.toLowerCase().includes(lowCaseValue)) {
            atleastOneUnitMatch = true;
            unitsMap.set(unit[0], true);
            return;
          }
        }
        unitsMap.set(unit[0], false);
      });

      powerMeters.forEach((item: any) => {
        if ((allPowerMeters[item[0]]?.name).toLowerCase().includes(lowCaseValue)) {
          atleastOneUnitMatch = true;
          powerMetersMap.set(item[0], true);
          return;
        }
        powerMetersMap.set(item[0], false);
      });

      const groupMatchSearchValue: any = (fullSiteGroups[groupId]?.name || "").toLowerCase().includes(lowCaseValue);
      newFilteredData[groupId] = { ...newFilteredData[groupId], id: groupId, name, itemsOrder: Array.from(unitsMap), powerMeters: Array.from(powerMetersMap) };
      (atleastOneUnitMatch || groupMatchSearchValue) && filteredGroupsArray.push([groupId, atleastOneUnitMatch ? false : true]);
    });

    return { groupsObjectF: newFilteredData, groupsArrayF: filteredGroupsArray };
  };

  useEffect(() => {
    const cleanStates = () => {
      setFilteredSiteGroupsArray([]);
      setSelectedItem({
        unitId: "",
        sensorId: "",
        groupId: "",
        type: "",
        isGroup: false
      });
      props.unitUpdateStatusUpdate("control");
      handleSchedulesPanel(false);
    };

    if (!siteId) {
      cleanStates();
      return;
    }
    Promise.all([getSiteGroups(siteId)])
      .then((res: any) => {
        const allGroups = res[0];

        const siteSensors: any = {};
        Object.values(allSensors).forEach((sensor: any) => {
          if (sensor.site === siteId && sensorTypes[sensor?.type].enableView) {
            siteSensors[sensor.id] = sensor;
          }
        });

        const sensors = _.orderBy(siteSensors, [(sensor: any) => sensor?.name], ["asc"]);
        const storedUnitOrders = userPreferences?.serviceStoredUnitOrders || {};
        const specificSiteUnitOrder = storedUnitOrders[siteId] || {};
        const allUnitsOrder = new Map(specificSiteUnitOrder["allUnits"] || []);
        const allPowerMetersOrder = new Map(specificSiteUnitOrder["powerMeters"] || []);
        const powerMeters = getSitePowerMeters(siteId, allPowerMetersOrder);
        const units: any = getControlUnitsBySite(siteId, allUnitsOrder);

        if (_.isEmpty(units) && _.isEmpty(sensors) && _.isEmpty(powerMeters)) {
          return;
        }

        const site = getSite(siteId);
        const timezone = site?.timezone || "";
        const offset = moment().tz(timezone).utcOffset();
        setSiteOffset(offset);

        const temp = site?.lastTemperature;
        const fTemp = (temp * 9 / 5) + 32;
        setExternalTemp({ C: temp || null, F: fTemp });

        const lastSelectedItems = userPreferences?.serviceLastSelectedItemsPerSite || {};
        const storedSite = lastSelectedItems[siteId] || {};
        const { unitId, groupId, isGroup = true } = storedSite;
        const storedGroupsOrders = userPreferences?.serviceStoredGroupsOrders || {};
        let specificSiteGroupOrder: any = new Map(storedGroupsOrders[siteId] || []);
        const sortedSiteGroups: any = new Map();
        const unitsInSiteGroups: any = {};

        let groups: any = {};
        let unitsTogroups: any = {};
        const groupsMap: any = new Map();

        //remove inVisible items (units/sensors) from groups
        Object.keys(allGroups).forEach((groupId) => {
          const group = allGroups[groupId];
          const { id = "", units = [], sensors = [] }: any = group;
          const storedOrderedUnitsMap = new Map(specificSiteUnitOrder[id] || []);
          const currentGroupUnitsMap = new Map();
          const sensorIds: any = [];
          const unitIds: any = [];

          units.forEach((unitId: any) => {
            const unit = allUnits[unitId];
            if (!unit || !unit?.isVisible) {
              return;
            }
            unitIds.push(unitId);
            currentGroupUnitsMap.set(unitId, true);
            storedOrderedUnitsMap.set(unitId, true);
            unitsInSiteGroups[unitId] = true;
            if (!unitsTogroups[unitId]) {
              unitsTogroups[unitId] = [id];
            } else {
              unitsTogroups[unitId] = [...(unitsTogroups[unitId] || []), id];
            }
          });

          Object.values(sensors).forEach((sensorId: any) => {
            const sensor = siteSensors[sensorId];
            if (!sensor) {
              return;
            }
            sensorIds.push(sensorId);
            currentGroupUnitsMap.set(sensorId, true);
            storedOrderedUnitsMap.set(sensorId, true);
            if (!unitsTogroups[sensorId]) {
              unitsTogroups[sensorId] = [id];
            } else {
              unitsTogroups[sensorId] = [...(unitsTogroups[sensorId] || []), id];
            }
          });

          if (unitIds.length || sensorIds.length) {
            Array.from(storedOrderedUnitsMap).forEach((unit: any) => ((!allUnits[unit[0]] && !allSensors[unit[0]]) || !currentGroupUnitsMap.has(unit[0])) && storedOrderedUnitsMap.delete(unit[0]));
            group.units = unitIds;
            group.sensors = sensorIds;
            group.itemsOrder = Array.from(storedOrderedUnitsMap);
            groups[id] = group;
            groupsMap.set(id, true);
            sortedSiteGroups.set(id, true);
          }

        });
        setAllGroups(allGroups);
        const arrayOfSortedGroups = Array.from(specificSiteGroupOrder.keys());
        if ((arrayOfSortedGroups.pop() === "sensors" && arrayOfSortedGroups.pop() === "allUnits") || Array.from(specificSiteGroupOrder.keys()).pop() === "allUnits") {
          specificSiteGroupOrder.delete("allUnits");
          specificSiteGroupOrder.delete("sensors");
        }
        specificSiteGroupOrder = new Map([...specificSiteGroupOrder, ..._.orderBy([...sortedSiteGroups.entries()], [(groupId: any) => groups[groupId[0]]?.name], ["asc"])]);
        const unGroupedUnits = _.isEmpty(groups) ? units : units.filter((item: any) => !unitsInSiteGroups[item[0]]);
        if (unGroupedUnits.length !== 0) {
          groups = { ...groups, allUnits: { name: _.isEmpty(groups) ? t`All Units` : t`Ungrouped Units`, id: "allUnits", units: unGroupedUnits.map((item: any) => item[0]), sensors: [], itemsOrder: unGroupedUnits } };
          groupsMap.set("allUnits", true);
          specificSiteGroupOrder.set("allUnits", true);
        }
        if (sensors.length !== 0) {
          const storedOrderedSensorsMap = new Map(specificSiteUnitOrder?.sensors || []);
          sensors.forEach(({ id }: any) => {
            storedOrderedSensorsMap.set(id, true);
          });

          groups = { ...groups, sensors: { name: t`All Sensors`, id: "sensors", units: [], sensors, itemsOrder: Array.from(storedOrderedSensorsMap) } };
          groupsMap.set("sensors", true);
          specificSiteGroupOrder.set("sensors", true);
        }

        if (powerMeters.length !== 0) {
          groups = { ...groups, powerMeters: { name: t`All Power Meters`, id: "powerMeters", units: [], sensors: [], powerMeters } };
          groupsMap.set("powerMeters", true);
          specificSiteGroupOrder.set("powerMeters", true);
        }

        Array.from(specificSiteGroupOrder).forEach((group: any) => !groupsMap.has(group[0]) && specificSiteGroupOrder.delete(group[0]));

        const { groupsObjectF, groupsArrayF } = searchValue ? filterUnits(searchValue, Array.from(specificSiteGroupOrder), groups) : { groupsObjectF: groups, groupsArrayF: Array.from(specificSiteGroupOrder) };

        const storedGroupStillExist = groups.hasOwnProperty(groupId);
        const bothSelectedIdsStillMatch = unitId
          ? storedGroupStillExist && new Map(groups[groupId]?.itemsOrder).has(unitId)
          : storedGroupStillExist;
        setSelectedItem(bothSelectedIdsStillMatch ? { ...storedSite, firstTimeLoading: true }
          : { unitId: "", isGroup: true, groupId: specificSiteGroupOrder.keys()?.next()?.value || "", firstTimeLoading: true });

        setFilteredUnitsGroup(groupsObjectF);
        setFilteredSiteGroupsArray(groupsArrayF);
        setFullSiteGroups(groups);
        setSiteGroupsArray(Array.from(specificSiteGroupOrder));
        setUnitsToGroupsMapping(unitsTogroups);
      })
      .catch(cleanStates);

  }, [siteId, allPowerMeters]);

  const onDragEnd = (result: any) => (groupId: any = "") => {

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder = reorder(
      !groupId ? filteredSiteGroupsArray : filteredUnitsGroups[groupId]?.itemsOrder,
      result.source.index,
      result.destination.index
    );

    if (!groupId) {
      setFilteredSiteGroupsArray(newOrder);
      const currentStored = userPreferences?.serviceStoredGroupsOrders || {};
      const currentSiteId = siteId || "";

      if (!currentStored[currentSiteId]) {
        currentStored[currentSiteId] = [];
      }
      currentStored[currentSiteId] = newOrder;
      updateUserPreferences({ serviceStoredGroupsOrders: currentStored });
      return;
    }

    filteredUnitsGroups[groupId].itemsOrder = newOrder;
    setFilteredUnitsGroup({ ...filteredUnitsGroups });
    const currentStored = userPreferences?.serviceStoredUnitOrders || {};
    const currentSiteId = siteId || "";

    if (!currentStored[currentSiteId]) {
      currentStored[currentSiteId] = {};
    }
    currentStored[currentSiteId] = { ...currentStored[currentSiteId], [groupId]: newOrder };
    updateUserPreferences({ serviceStoredUnitOrders: currentStored });

  };

  const addItemSchedule = (isGroup: boolean, itemId: any, scheduleId: any) => {
    if (isGroup) {
      if (fullSiteGroups[itemId]) {
        const schedules = fullSiteGroups[itemId].schedules || [];
        schedules.push(scheduleId);
        fullSiteGroups[itemId].schedules = [...new Set(schedules)];
        setFullSiteGroups(fullSiteGroups);
      }

      if (allGroups[itemId]) {
        const schedules = allGroups[itemId].schedules || [];
        schedules.push(scheduleId);
        allGroups[itemId].schedules = [...new Set(schedules)];
        setAllGroups(allGroups);
      }
      return;
    }
    const unit = allUnits[itemId];
    unit && updateUnit({ id: unit.id, data: { ...unit, schedules: [...(unit.schedules || []), scheduleId] } });
  };

  const deleteItemSchedule = (isGroup: boolean, itemId: any, scheduleId: any) => {
    if (isGroup) {
      if (allGroups[itemId]) {
        const group = allGroups[itemId];
        group.schedules = group?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
        setAllGroups({ ...allGroups });
      }
      const group1 = fullSiteGroups[itemId];
      group1.schedules = group1?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
      setFullSiteGroups({ ...fullSiteGroups });
      return;
    }

    const unit = allUnits[itemId];
    const schedules = unit?.schedules.filter((schedule: any) => schedule !== scheduleId) || [];
    unit && updateUnit({ id: unit.id, data: { ...unit, schedules } });

  };

  const handlePageModeChange = (event: any, mode: "list" | "plans") => {
    if (!mode) {
      return;
    }
    setPageMode(mode);
    const lsStateString = localStorage.getItem("viewPerSite") || "{}";
    const lsStateObject: any = JSON.parse(lsStateString);
    lsStateObject[siteId as any] = mode;
    localStorage.setItem("viewPerSite", JSON.stringify(lsStateObject));
  };

  const selectFloorPlan = (value: any) => {
    setSelectedFP(value);
    const lsFp = localStorage.getItem("selectedFloorPlanPerSite") || "{}";
    const lsObj = JSON.parse(lsFp);
    lsObj[siteId as any] = value;
    localStorage.setItem("selectedFloorPlanPerSite", JSON.stringify(lsObj));
  };

  const floorPlanSelector = (
    <MenuDropDown
      onChange={selectFloorPlan}
      hideArrow={siteFloorPlans?.length === 1}
      value={selectedFP || ""}
      options={[...(!siteFloorPlans?.length ?
        [{
          name: t`No floor plans`,
          key: "na",
          value: "",
          type: 0
        }] :
        [
          {
            name: t`Select Floor Plan`,
            key: "na",
            value: "",
            type: 0
          },
          ...siteFloorPlans?.map((p: any) => {
            return {
              name: p.name,
              key: p.id,
              value: p.id,
              type: 0
            };
          })])]}
    />
  );

  const { canControlSiteOperationStatus } = allSites[siteId || ""]?.permissions || {};

  const onSiteSelect = (newSiteId: any) => {
    setSelectedFP("");
    if (!sitesFlags[newSiteId]?.hasFloorPlans) {
      setPageMode("list");
    }
  };

  const isDragLocked = userPreferences?.lockItemsReorder || false;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Control"]}
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          hideOutdoor
          countControlUnits
          screenTitle="control"
          onSiteSelect={onSiteSelect}
          pageCustomSelector={pageMode === "list" ? null : floorPlanSelector}
        />
        {!siteId ? (
          <FilterRequire type={t`site`} />
        ) : (
          <div id="pageContent" className={classes.pageContentBox}>
            {(siteId && sitesFlags[siteId]?.hasFloorPlans) &&
              <ToggleButtonGroup
                value={pageMode}
                exclusive
                onChange={handlePageModeChange}
                aria-label="text alignment"
                style={{
                  height: "45px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px"
                }}
                color="primary"
              >
                <ToggleButton
                  value="list"
                  classes={{ root: classes.toggleBtn, selected: classes.toggleBtnSelected }}
                  style={{
                    textTransform: "none"
                  }}
                >
                  <List />
                  <Typography>{t`List`}</Typography>
                </ToggleButton>
                <ToggleButton
                  value="plans"
                  classes={{ root: classes.toggleBtn, selected: classes.toggleBtnSelected }}
                  style={{
                    textTransform: "none"
                  }}
                >
                  <FloorPlan />
                  <Typography>{t`Floor Plan`}</Typography>
                </ToggleButton>
              </ToggleButtonGroup>}

            {pageMode === "list" ?
              <div id="cardsHolder" className={classes.cardsHolder}>
                <div id="itemsContainer" className={classes.leftSideContainer}>
                  <div id="searchHeader" className={classes.searchBar}>
                    <Input
                      placeholder="Search Unit"
                      value={searchValue}
                      onChange={changeSearchValue}
                      disableUnderline={true}
                      classes={{ root: classes.inputRoot }}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        searchValue && (
                          <IconButton
                            onClick={() => {
                              setSearchValue("");
                              setFilteredSiteGroupsArray(siteGroupsArray);
                              setFilteredUnitsGroup({ ...fullSiteGroups });
                            }}
                            className={classes.closeIconStyle}
                          >
                            <Close />
                          </IconButton>
                        )
                      }
                    />
                    {<Button
                      disabled={!canControlSiteOperationStatus}
                      disableRipple
                      className={classes.powerOffButton}
                      onMouseUp={() => setNewSitePowerInfo({ state: 2, siteId })}
                      startIcon={<PowerIcon className={classes.powerIconStyle} />}
                    >
                      {t`ALL OFF`}
                    </Button>}
                  </div>
                  <div className={classes.itemsWrapper}>
                    <DragDropContext
                      onDragEnd={(result: any) => onDragEnd(result)("")}
                    >
                      <Droppable droppableId="droppable" type="groupsDropDown">
                        {(provided: any) => (
                          <div ref={provided.innerRef}>
                            {filteredSiteGroupsArray.map(
                              (item: any, index: number) => (
                                <Draggable
                                  key={`group-${item[0]}`}
                                  draggableId={item[0]}
                                  index={index}
                                  isDragDisabled={searchValue || isDragLocked}
                                >
                                  {(provided: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <GroupItem
                                        handleDragging={provided.dragHandleProps}
                                        key={`u-group-${item[0]}`}
                                        onDragEnd={onDragEnd}
                                        changeSitePower={changeSitePower}
                                        selectedItem={selectedItem}
                                        item={filteredUnitsGroups[item[0]] || {}}
                                        siteId={siteId}
                                        selectItem={onSelectItem}
                                        hasSchedules={true}
                                        disableDragging={!!searchValue || isDragLocked}
                                        addMessage={addMessage}
                                        openCollapse={item[1]}
                                        isCelsius={isCelsius}
                                        canControlSiteOperationStatus={canControlSiteOperationStatus}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
                <div className={classes.controlContainer}>
                  {openSchedulesPanel ? selectedItem.sensorId ?
                    <SensorScheduleList sensorId={selectedItem.sensorId} hideSchedulesPanel={() => handleSchedulesPanel(false)} setOpenDeleteDialog={setOpenDeleteDialog} /> :
                    <ScheduleList
                      timezoneOffset={siteOffset}
                      isGroup={selectedItem.isGroup}
                      item={selectedItem.isGroup ? fullSiteGroups[selectedItem.groupId] : allUnits[selectedItem.unitId]}
                      hideSchedulesPanel={() => handleSchedulesPanel(false)}
                      allSchedules={allSchedules}
                      setAllSchedules={setAllSchedules}
                      deleteItemSchedule={deleteItemSchedule}
                      addItemSchedule={addItemSchedule}
                      unitGroups={selectedItem.isGroup ? [] : unitsTogroupsMapping[selectedItem.unitId] || []}
                      allGroups={fullSiteGroups}
                      setOpenDeleteDialog={setOpenDeleteDialog}
                      isHalfCDegreeEnabled={selectedItem.type === "unit" && allUnits[selectedItem.unitId]?.isHalfCDegreeEnabled && isCelsius}
                    />
                    : <>
                      {selectedItem.isGroup && fullSiteGroups[selectedItem.groupId] && (
                        <GroupPanel
                          group={fullSiteGroups[selectedItem.groupId]}
                          key={selectedItem.groupId}
                          user={user}
                          types={types}
                          navigateToSchedules={() => handleSchedulesPanel(true)}
                          tempSymbol={getTemperatureScaleDisplay()}
                          temperatureScale={temperatureScale}
                          operationStatusesMirror={operationStatusesMirror}
                          isCelsius={isCelsius}
                          addMessage={addMessage}
                          siteTemp={externalTemp}
                          siteId={siteId}
                          changeSitePower={changeSitePower}
                          canControlSiteOperationStatus={canControlSiteOperationStatus}
                        />
                      )}
                      {(selectedItem.type === "unit" && selectedItem.unitId && allUnits[selectedItem.unitId]) ?
                        <UnitPanel
                          tempSymbol={getTemperatureScaleDisplay()}
                          siteTemp={externalTemp}
                          key={selectedItem.unitId}
                          types={{
                            operationStatuses,
                            fanModes,
                            operationModesExtended,
                            swingModes,
                            unitSubTypes,
                            unitTypes
                          }}
                          unit={allUnits[selectedItem.unitId]}
                          setActiveSetpoint={setActiveSetpoint}
                          setActiveOperationMode={setActiveOperationMode}
                          setActiveFanMode={setActiveFanMode}
                          setActiveSwingMode={setActiveSwingMode}
                          setActiveOperationStatus={setActiveOperationStatus}
                          operationModesMirror={operationModesMirror}
                          fanModesMirror={fanModesMirror}
                          swingModesMirror={swingModesMirror}
                          operationStatusesMirror={operationStatusesMirror}
                          temperatureScale={temperatureScale}
                          isCelsius={isCelsius}
                          navigateToSchedules={() => handleSchedulesPanel(true)}
                          addMessage={addMessage}
                          enableAirQuality={allSites[siteId]?.enableAirQuality}
                          airQualityParams={allSites[siteId]?.airQualityParams}
                          serviceParams={serviceParams}
                        /> : null}
                      {selectedItem.type === "sensor" && allSensors[selectedItem.sensorId]?.type !== 0 && (
                        <SensorPanel
                          timezoneOffset={siteOffset}
                          key={selectedItem.sensorId}
                          tempSymbol={getTemperatureScaleDisplay()}
                          siteTemp={externalTemp}
                          types={{ unitSubTypes, sensorTypes, sensorMeasurementUnits }}
                          sensor={allSensors[selectedItem.sensorId]}
                          isCelsius={isCelsius}
                          navigateToSchedules={() => handleSchedulesPanel(true)}
                        />
                      )}
                      {selectedItem.type === "powerMeter" &&
                        <PowerMeterPanel
                          tempSymbol={getTemperatureScaleDisplay()}
                          siteTemp={externalTemp}
                          id={selectedItem.id}
                          isCelsius={isCelsius}
                          timezoneOffset={siteOffset}
                        />
                      }
                    </>}
                </div>
              </div> :
              <FloorPlansControl
                siteId={siteId}
                planId={selectedFP}
                fullSiteGroups={fullSiteGroups}
                selectFloorPlan={selectFloorPlan}
              />
            }
          </div>
        )}
      </div>
      <ConfirmationDialog
        onCancel={() => setNewSitePowerInfo(null)}
        onConfrim={() => { changeSitePower(newSitePowerInfo); setNewSitePowerInfo(null); }}
        onClose={() => setNewSitePowerInfo(null)}
        title={t`Confirmation`}
        text={t`Are you sure you want to proceed?`}
        confrimLabel={t`Yes`}
        cancelLabel="Cancel"
        dialogType="normal"
        openDialog={!!newSitePowerInfo}
      />
    </div>
  );
};
export default Control;
