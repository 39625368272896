import { Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import packageJson from "../../../package.json";
import { TopBar } from "../../components";
import { useStoreState } from "../../models/RootStore";
import { ArrowBack } from "../../svgComponents";
import aboutUsStyle from "./AboutUs.style";
import { getHostname } from "../../services/utils";

interface IProps {
  history: any;
}

export const AboutUs: React.FC<IProps> = ({ history }) => {
  const classes = aboutUsStyle();
  const config = useStoreState(state => state.config);

  const { aboutTxt = {} } = config || {};
  const {
    TOULink = null,
    supportLink = null,
    mainText = null,
    title = null
  } = aboutTxt;

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  const domain = getHostname();
  const isAirwell = domain.indexOf("airconnectpro") > -1;
  const userPreferences = useStoreState((state) => state.users.userPreferences);

  const isFr = userPreferences.professionalLanguage === "fr";

  return (
    <div className={classes.secreenContainer}>
      <TopBar
        title={t`About`}
        leftIconComponent={<ArrowBack />}
        leftAction={goBack}
      />
      <div className={classes.container}>
        <Typography className={classes.headers}>
          {title ? title : t`About CoolAutomation`}
        </Typography>

        <Typography className={classes.subtitles}>
          {mainText ?
            mainText :
            t`CoolAutomation leads the way in connected HVAC solutions, with a
          pioneering approach we call the Internet of Climate (IoC). The
          Internet of Climate is a universal concept that Internet-enabled any
          climate system, simply and seamlessly. Our mission is to connect the
          traditional world of HVAC with the future of IoT, enabling HVAC to
          play a central role in smart buildings and smart homes.`}{" "}
        </Typography>

        <Typography className={clsx(classes.headers, classes.subHeaders)}>{t`Version number`}</Typography>

        <Typography className={classes.subtitles}>
          {packageJson ? t`Version` + " " + packageJson.version : ""}
        </Typography>

        <Typography className={clsx(classes.headers, classes.subHeaders)}>{t`Terms of use`}</Typography>

        <Link
          target="_blank"
          href={TOULink ? TOULink : (isAirwell ? (isFr ? "https://www.airwell.com/fr/cgu-airconnectpro" : "https://www.airwell.com/en/tou-airconnectpro") : "https://coolautomation.com/terms-of-use/")}
          className={classes.subtitles}
        >
          {TOULink ? TOULink : (isAirwell ? (isFr ? "www.airwell.com/fr/cgu-airconnectpro" : "www.airwell.com/en/tou-airconnectpro") : "https://coolautomation.com/terms-of-use/")}
        </Link>

        <Typography className={clsx(classes.headers, classes.subHeaders)}>{t`Support`} </Typography>

        <Link
          className={classes.subtitles}
          target="_blank"
          href={supportLink ? supportLink : (isAirwell ? "https://www.airconnect-pro.com/support" : "https://coolautomation.com/support/")}
        >
          {supportLink ? supportLink : (isAirwell ? "https://www.airconnect-pro.com/support" : "https://coolautomation.com/support/")}
        </Link>
      </div>
    </div>
  );
};
