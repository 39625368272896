import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "calc(100% - 83px)"
      // background: theme.gradient.schedule,
    },
    gridStyle: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      width: "100%"
      // maxWidth: "768px"
    },
    iconStyle: {
      fontSize: "22px"
      // color: theme.palette.colors.white
    },
    fieldContainerStyle: { width: "80%", overflow: "auto" },
    typographyMargin: { marginBottom: "-10px" },
    testclass: {
      width: "50px",
      minWidth: "60px"
    },
    schedulesContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1,
      padding: "0 20px",
      boxSizing: "border-box",
      overflowY: "auto"
    },
    scheduleHeader: {
      display: "flex",
      justifyContent: "space-between",
      // backgroundColor: alpha(theme.palette.colors.lightWhite, 0.2),
      width: "100%",
      height: "50px",
      borderRadius: "6px",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: "10px",
      boxSizing: "border-box"
    },
    scheduleTitle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 5,
      "& *": {
        fontSize: "18px",
        fontWeight: "500",
        // color: theme.palette.colors.white,
        marginRight: "7px"
      }
    },
    scheduleControl: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "140px"
    },
    scheduleBody: {
      display: "flex",
      flex: 1,
      height: "235px",
      flexDirection: "column",
      width: "100%",
      borderRadius: "6px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: "10px",
      paddingBottom: 0,
      boxSizing: "border-box"
    },
    scheduleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "12px 0",
      minHeight: "max-content"
    },
    bodyRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
      height: "100%"
    },
    timeSection: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      justifyContent: "space-between",
      marginRight: "15px"
    },
    setPointSection: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "15px"
    },
    setPointStyle: {
      fontSize: "50px"
    },
    timeStyle: {
      fontSize: "16px"
    },
    tempSymbolStyle: {
      fontSize: "30px"
    },
    dividerStyle: {
      marginTop: 10
    },
    controlSec: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%"
    },
    scheduleStatusStyle: {
      fontSize: "18px"
    },
    noSchedulesStyle: {
      fontSize: "30px",
      marginTop: "20px"
    },
    longNamestyle: {
      maxWidth: "20ch",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      display: "inline-block",
      fontFamily: "RobotoMedium",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#4b1c46"
    },
    rowContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minHeight: "115px",
      margin: "10px 0"
    },
    setpointContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },

    inputWrapperStyle: {
      marginTop: 0,
      marginBottom: "10px",
      fontSize: "30px",
      "& *": { borderRadius: "6px" },
      "&  div": {
        border: "1px solid rgba(253, 253, 254, 0.3)"
      },
      "& input:focus + fieldset": {
        borderWidth: "1px !important"
      },
      "& input": {
        color: "white",
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        padding: "16px 0px",
        paddingLeft: "20px"
      },
      "& label": {
        color: "white"
      }
    },
    inputClass: {
      "& input": {

        height: "50px",
        padding: 0,
        paddingLeft: "15px",
        paddingRight: "15px"
      },
      fontSize: "16px",
      "& input:hover + fieldset": {
        "&$notchedOutline": {
        }
      },
      "& input:focus + fieldset": {
        "&$notchedOutline": {
        }
      },
      "& input:disabled + fieldset": {
        "&$notchedOutline": {
        }
      },
      "& ::placeholder": {
        opacity: 0.5,
        fontSize: "16px",
        lineHieght: "normal",
        fontWeight: "normal"
      },
      "& label": {
      }
    },
    dialog: {
      borderRadius: "0.5rem",
      boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
      border: " 1px solid #d5d2d5"
    },
    titleContent: {
      paddingLeft: 20,
      paddingRight: 13,
      ...theme.dialogs.header,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    DialogContentText: {
      fontFamily: "Roboto",
      color: "#545964"
    },
    deleteDialogActions: {
      padding: 20,
      display: "flex",
      justifyContent: "center"
    },
    yesBtn: {
      marginRight: "17px"
    },
    headerTitle: {
      ...theme.dialogs.title
    },
    overWriteIcon: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
    dialogContent: {
      flex: "1 1 auto",
      padding: 20,
      overflowY: "auto",
      paddingBottom: 0
    },
    notEditable: {
      opacity: 0.6
    }

  });
