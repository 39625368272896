import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  tableContainer: {
    height: "100%",
  },
  paperTableContainer: {
    flex: "1 1 auto",
    minHeight: 250,
    overflowY: "auto",
  },
  table: {
    background: theme.palette.background.paper,
  },
  overWritePadding: {
    padding: "0px 15px",
    height: "35px",
    ...theme.tables.tableCell,
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell,
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
  },
  nameCell: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "30%", wordBreak: "break-word" },
  descriptionCell: {
    padding: "8px 15px",
  },
  tableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  innerItem: {
    paddingRight: "5px",
  },
  daysItem: {
    padding: "5px 10px",
    margin: "3px",
    borderRadius: "3px",
    background: "#efeff2",
  },
  disabledStyle: {
    cursor: "not-allowed",
    opacity: 0.4,
  },
}));
export default useStyles;
