import * as React from 'react';

function SvgHvca(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#AAA2AA">
          <path d="M9.558 21.776L8 23.987h3.115zM16.557 23.987l1.557-2.21H15z" />
        </g>
        <rect
          stroke="#AAA2AA"
          strokeWidth={1.5}
          x={0.75}
          y={0.75}
          width={24.5}
          height={25.5}
          rx={4}
        />
        <g stroke="#AAA2AA" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.09}>
          <path d="M15.002 14.714L15.004 6a2.014 2.014 0 00-2.002-2.025A2.014 2.014 0 0011 6l-.002 8.714a3.052 3.052 0 00-.8 3.341A2.995 2.995 0 0013 20a2.995 2.995 0 002.802-1.945 3.052 3.052 0 00-.8-3.34zM17 9.5h3M17 6.5h3M17 12.5h3" />
        </g>
        <circle stroke="#AAA2AA" fill="#AAA2AA" cx={13} cy={17} r={1} />
        <path stroke="#AAA2AA" strokeLinecap="round" d="M13 16.5v-8" />
      </g>
    </svg>
  );
}

export default SvgHvca;
