import React from "react";

const SvgHotRed = props => (
  <svg width={18} height={18} {...props}>
    <path
      d="M8.973 4.38a4.595 4.595 0 1 0 .001 9.19 4.595 4.595 0 0 0 0-9.19zm0 .9a3.695 3.695 0 1 1 0 7.39 3.695 3.695 0 0 1 0-7.39zm.05 9.34c.254 0 .463.17.496.39l.004.06v2.194c0 .248-.223.45-.5.45-.253 0-.462-.17-.495-.39l-.005-.06V15.07c0-.249.224-.45.5-.45zm0-14.385c.254 0 .463.169.496.388l.004.062v2.193c0 .248-.223.45-.5.45-.253 0-.462-.17-.495-.39l-.005-.06V.685c0-.249.224-.45.5-.45zm2.754 13.642a.45.45 0 0 1 .58.113l.035.051 1.116 1.933a.45.45 0 0 1-.745.5l-.034-.05-1.117-1.933a.45.45 0 0 1 .165-.614zM4.605 1.453a.45.45 0 0 1 .58.114l.034.05L6.294 3.48a.45.45 0 0 1-.745.5l-.034-.05L4.44 2.068a.45.45 0 0 1 .165-.615zm9.628 10.154h.122l2.023 1.169a.45.45 0 0 1-.395.806l-.055-.027-1.922-1.11a.45.45 0 0 1-.22-.327l-.003-.061a.45.45 0 0 1 .389-.446l.06-.004zM1.405 4.65a.45.45 0 0 1 .56-.192l.055.027L3.899 5.57a.45.45 0 0 1-.395.807l-.055-.027-1.88-1.085a.45.45 0 0 1-.164-.615zm15.859 3.92c.248 0 .45.225.45.5 0 .254-.17.463-.39.496l-.06.005h-2.195c-.249 0-.45-.224-.45-.5 0-.253.17-.462.389-.496l.061-.004h2.195zm-14.385 0c.248 0 .45.224.45.5 0 .253-.17.462-.39.495l-.06.005H.685c-.249 0-.45-.224-.45-.5 0-.253.169-.462.389-.495l.06-.005H2.88zm13.049-4.084a.45.45 0 0 1 .5.745l-.05.034L14.5 6.35a.45.45 0 0 1-.5-.745l.05-.034 1.879-1.085zM3.714 11.607a.45.45 0 0 1 .227.838l-.013.007-1.908 1.103a.45.45 0 0 1-.58-.113l-.035-.051a.45.45 0 0 1 .114-.58l.05-.035 2.024-1.17h.121zm9.014-9.99a.45.45 0 0 1 .807.395l-.027.055-1.075 1.863a.45.45 0 0 1-.806-.395l.027-.055 1.074-1.863zM5.555 14.042a.45.45 0 0 1 .807.395l-.027.055-1.116 1.932a.45.45 0 0 1-.806-.395l.027-.055 1.115-1.932z"
      fill="#F05146"
    />
  </svg>
);

export default SvgHotRed;
