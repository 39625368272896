import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  tableContainer: {
    display: "flex"
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    overflow: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
    marginTop: 0
  },

  table: {
    background: theme.palette.background.paper
  },
  overWritePadding: {
    ...theme.tables.tableCell,
    padding: "0 15px",
    height: "35px"
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  editIcon: {
    marginLeft: "-6px"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial"
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem"
  },

  titleBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  title: {
    fontSize: "1.25rem",
    fontFamily: "RobotoMedium",
    display: "inline-block",
    color: "rgb(75, 28, 70)"
  },
  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  iconColor: {
    color: "#AAA2AA"
  },
  disabledIcon: {
    color: "#d5d0d5",
    opacity: 0.4
  },
  dialog: {
    height: "15.625rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },
  dialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem"
  },
  yesBtn: {
    marginRight: "17px"
  },
  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "16px"
  },
  timeWidth: {
    minWidth: "143px",
    maxWidth: "143px"
  },
  iconsColumnWidth: {
    width: 1
  },
  loaderContainer: {
    alignSelf: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexFlow: "column nowrap",
    justifyContent: "center"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    height: "100%",
    alignSelf: "center"
  },
  pageContentBox: {
    maxHeight: "100%",
    overflow: "auto",
    maxWidth: "calc(100vw - 240px)",
    display: "flex",
    padding: "16px",
    height: "100%"
  },
  halfTable: {
    height: "30%"
  },
  loaderPaper: {
    minHeight: "calc(100% - 102px)"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  }
}));
export default useStyles;
