import { Typography } from "@material-ui/core";
import React from "react";
import SingleTimePicker from "./SingleTimePicker";

const StartEndTimePicker: React.FC<any> = (props) => {
  const { powerOnTime, powerOffTime, is12Hours, onSetTime, disabled } = props;

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", whiteSpace: "pre" }}>
      <Typography> From</Typography>

      <SingleTimePicker
        time={powerOnTime}
        overWriteCss={true}
        is12Hours={is12Hours}
        onSetTime={onSetTime}
        openPicker="start"
        disabled={disabled}
      />
      <Typography> -   To</Typography>
      <SingleTimePicker
        time={powerOffTime}
        overWriteCss={true}
        is12Hours={is12Hours}
        onSetTime={onSetTime}
        openPicker="end"
        disabled={disabled}
      />
    </div>
  );

};

export default StartEndTimePicker;
