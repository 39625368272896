import {
  Dialog,
  IconButton,
  makeStyles,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import { toC, toF } from "../../services/converter";
import ErrorBox from "../WarnningBox/ErrorBox";
import styles from "./AddEditScript.style";
import Condition from "./Condition";
import conditionUtils from "./conditionUtils";
import { Box, CustomedTextField, CustomSelect } from "./CustomedComponents";

const defaultScript = { command: "", type: "", value: "", proceedOnFailure: true };

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { siteId, trapTemplatesToBrands = {}, trapTemplatesMap = {}, close, createScript, editScript, updateScript, canEdit = true } = props;

  const types = useStoreState((s) => s.types);
  const temperatureScaleMirror = useStoreState((s) => s.temperatureScaleMirror);
  const user = useStoreState((s) => s.users.me);
  const [ruleName, setRuleName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<any>("");
  const [conditions, setConditions] = useState<any>([]);
  const [brandSystems, setBrandSystems] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cancelClicked, setCancelClicked] = useState<boolean>(false);
  const [isParallel, setIsParallel] = useState<boolean>(true);
  const descriptionRef: any = useRef(null);
  const { temperatureScale = 1 } = user;
  const { hvacBrands = [], procedureDeviceCommands, procedureStepTypes, procedureConditions, procedureRunningModes } = types;

  const allbrandsOption = [{ value: "all", name: "All" }, ...hvacBrands];

  useEffect(() => { conditionUtils.generateId.reset(); }, []);

  const addCondition = (conditions: any, ruleName: string, brand: any) => {
    const id = conditionUtils.generateId();
    setConditions([...conditions, { id, ...defaultScript }]);
    setRuleName(ruleName);
    setSelectedBrand(brand);
  };

  const deleteCondition = (conditions: any, index: number, ruleName: string, brand: any) => {
    setConditions(conditions.filter((condition: any) => condition.id !== index));
    setRuleName(ruleName);
    setSelectedBrand(brand);
  };

  const onSubmit = (values: any) => {
    setErrorMessage("");
    const { conditions, selectedBrand, ruleName } = values;
    if (_.isEmpty(conditions)) {
      setErrorMessage("one step at least is required");
      return;
    }

    const conditions1: any = conditions;

    Object.values(conditions1).forEach((condition: any) => {
      delete condition["id"];
      if (condition.type === procedureStepTypes.wait) {
        delete condition["command"];
        condition["value"] = condition["value"] * 60000;
        delete condition["proceedOnFailure"];
        delete condition["scale"];
        delete condition["scaleType"];
        return;
      }
      const addScale = condition.command === procedureDeviceCommands.setUnitSetpoint ||
        condition.condition === procedureConditions.unitSetpoint ||
        condition.condition === procedureConditions.ambientTemperature;

      if (addScale) {
        condition.scale = temperatureScale;
        condition.scaleType = 1;
      }

      if (!addScale) {
        delete condition["scale"];
        delete condition["scaleType"];
      }

      if (condition.type === procedureStepTypes.condition) {
        delete condition["command"];
      }
      if (condition.type === procedureStepTypes.command) {
        delete condition["proceedOnFailure"];
      }
    });

    // const mode = isParallel ? procedureRunningModes["parallel"] : procedureRunningModes["serial"];
    const mode = procedureRunningModes["parallel"];
    const scriptBody: any = {
      name: ruleName,
      description: descriptionRef?.current?.value || "",
      userSelections: {
        brand: selectedBrand
      },
      steps: Object.values(conditions1),
      runningMode: mode
    };

    if (editScript) {
      updateScript(scriptBody, editScript.id)
        .finally(() => close());
    }
    else {
      createScript({ ...scriptBody, type: 1 }, siteId)
        .finally(() => close());
    }
  };

  const schema = Yup.object().shape({
    ruleName: Yup.string()
      .required(t`Required`),
    conditions: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required(t`Required`),
          value: Yup.number()
            .required(t`Required`),
          command: Yup.string().when(["type"], {
            is: (type) => +type === procedureStepTypes.command,
            then: Yup.string().required(t`Required`),
            otherwise: Yup.string()
          })
        })

      )

  });

  useEffect(() => {
    if (!editScript) {
      return;
    }
    const { steps, name, description, userSelections = {}, runningMode = procedureRunningModes["parallel"] } = editScript;
    const { brand = "" } = userSelections;
    const conditions: any = [];

    steps.forEach((step: any) => {
      const id = conditionUtils.generateId();
      const currentStep = { ...step };
      if (currentStep.type === procedureStepTypes.wait) {
        currentStep.value = currentStep.value / 60000;
      }
      if (currentStep.scaleType && temperatureScale !== currentStep.scale) {
        currentStep.value = currentStep.scale === 1 ? toF(currentStep.value) : toC(currentStep.value);
      }
      conditions.push({ id, command: "", ...currentStep });
    });

    setRuleName(name);
    setSelectedBrand(brand);
    setDescription(description);
    // setIsParallel(runningMode === procedureRunningModes["parallel"] ? true : false);
    setIsParallel(true);
    setConditions(conditions);
  }, [editScript]);

  const onDragEnd = (result: any) => (steps: any, ruleName: string) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder: any = reorder(
      steps,
      result.source.index,
      result.destination.index
    );

    setConditions(newOrder);
    setRuleName(ruleName);
  };

  const handleModeChange = () => {
    setIsParallel(!isParallel);
  };
  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{editScript ? t`Edit procedure script` : t`Add new procedure script`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>

      <div id="dialogContent" className={classes.dialogContent}>
        <div style={{ position: "relative" }}>
          <div id="basic-info" className={clsx(classes.basicInfoContainer)}>
            <div className={clsx(classes.mainDataContainer, classes.topMargin)}>
            </div>
            <TextareaAutosize
              disabled={!canEdit}
              className={classes.textArea}
              aria-label="minimum height"
              minRows={4}
              maxRows={4}
              placeholder={t`Insert Script Description`}
              defaultValue={description}
              ref={descriptionRef}
            />
            <div className={classes.switchContainer} style={{ display: "none" }}>
              sequential{<CoolSwitch disabled={!canEdit} checked={isParallel} onChange={handleModeChange} name="ParellelSwitch" />}parallel
            </div>
          </div>
          <Formik
            initialValues={{ ruleName, selectedBrand, conditions }}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={schema}
            render={({ values, setFieldValue, errors, touched
            }) => {

              return (
                // @ts-ignore
                <Form >
                  <div className={classes.mainDataInPlace}>
                    <CustomedTextField
                      disabled={!canEdit}
                      value={values.ruleName}
                      name="ruleName"
                      onChange={(event: any) => setFieldValue("ruleName", event.target.value)}
                      label={t`Script Name`}
                      error={errors.ruleName && touched.ruleName}
                    />
                    <div>
                      <CustomSelect disabled={!canEdit} className={classes.mainSelect} error={errors.selectedBrand && touched.selectedBrand} placeholder="Select Brand" name="selectedBrand" value={values.selectedBrand || "all"} options={allbrandsOption} onChange={(event: any) => setFieldValue("selectedBrand", event.target.value)} />
                    </div>
                  </div>
                  <Box
                    disabled={!canEdit}
                    title={t`Steps`}
                    bLabel={t`Add Step`}
                    action={() => addCondition(values.conditions, values.ruleName, values.selectedBrand)}
                    addBottomMargin
                  >
                    <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(values.conditions, values.ruleName)} >
                      <Droppable droppableId="droppable" type="steps">
                        {(provided: any) => (
                          <div ref={provided.innerRef} >
                            <FieldArray
                              name="conditions"
                              render={() => (values.conditions.map((condition: any, index: number) =>
                                <Draggable
                                  key={`step-${condition.id}`}
                                  draggableId={`step-${condition.id}`}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} >
                                      <div key={index}>
                                        <Condition
                                          disabled={!canEdit}
                                          errors={errors}
                                          touched={touched}
                                          key={condition.id}
                                          index={index}
                                          condition={condition}
                                          setFieldValue={setFieldValue}
                                          deleteCondition={() => deleteCondition(values.conditions, condition.id, values.ruleName, values.selectedBrand)}
                                          name={"conditions"}
                                          stepTypes={procedureStepTypes}
                                          commandsTypes={procedureDeviceCommands}
                                          moveProps={provided.dragHandleProps}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            />
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                  <div className={classes.actionsContainer}>
                    {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
                    <Button disabled={!canEdit} onClick={() => setCancelClicked(true)} onMouseDown={(event: any) => event.preventDefault()} marginRight white width={150}> {t`cancel`}</Button>
                    <Button disabled={!canEdit} type="submit" onMouseDown={(event: any) => event.preventDefault()} width={150}> {t`save`}</Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
      {cancelClicked && <ErrorBox error={"Do you want to discard changes?"} onAccept={close} onClose={() => setCancelClicked(false)} />}
    </Dialog>
  );
};
