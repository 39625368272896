import {
    Action,
    action,
    actionOn,
    ActionOn,
    Computed,
    computed,
    debug,
    memo,
    Thunk,
    thunk
} from "easy-peasy";
import _ from "lodash";

export interface INoteModel {
    getUnitNotes: Thunk<INoteModel, string>;
    newNote: Thunk<INoteModel, { type: "unit" | "system", id: string, message: string }>;
    deleteNote: Thunk<INoteModel, string>;
}

export const notesModel: INoteModel = {
    getUnitNotes: thunk(async (actions, payload, { injections }) => {
        const { sdkNote } = injections;
        const notes = await sdkNote.getUnitNotes(payload);
        return notes;
    }),
    newNote: thunk(async (actions, payload, { injections }) => {
        const { sdkNote } = injections;
        const { type, id, message } = payload;
        const newNote = await sdkNote.createNote(type, id, { note: message });
        return newNote;
    }),
    deleteNote: thunk(async (actions, payload, { injections }) => {
        const { sdkNote } = injections;
        const resp = await sdkNote.delete(payload);
        return resp;
    })
};
