import React from "react";

const SvgArrowBack = props => (
  <svg width={40} height={40} {...props}>
    <path
      fill="#FDFDFE"
      d="M17.58 27.664l-8.085-8.27a1.074 1.074 0 0 1-.357-1.434.982.982 0 0 1 .178-.239l.11-.098 8.154-8.34a1.015 1.015 0 0 1 1.432.026c.393.402.404 1.05.026 1.465l-6.518 6.638h17.449c.57 0 1.031.472 1.031 1.055a1.057 1.057 0 0 1-1.031 1.054H12.56l6.477 6.653c.275.262.389.658.296 1.032a1.043 1.043 0 0 1-.744.761 1.017 1.017 0 0 1-1.01-.303z"
    />
  </svg>
);

export default SvgArrowBack;
