import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
interface IInviteUser {
  inviteId: string;
  data: any;
}
export interface IInvitesModel {
  createInvite: Thunk<IInvitesModel, { data: any, id: string }>;
  deleteInvite: Thunk<IInvitesModel, string>;
  sendInviteEmail: Thunk<IInvitesModel, { email: string; inviteId: string }>;
  getInvites: Thunk<IInvitesModel, string>;
  getAllInvites: Thunk<IInvitesModel, any>;
  createMultiInvites: Thunk<IInvitesModel, { data: any, id: string }>;
  sendMultiInvitesEmails: Thunk<IInvitesModel, {}>;
  getInvite: Thunk<IInvitesModel, string, any>;
  acceptInvite: Thunk<IInvitesModel, IInviteUser, any>;
}

export const invitesModel: IInvitesModel = {
  createInvite: thunk(async (actions, payload) => {
    const { data, id } = payload;
    const invite = await coolremoteSDK.Customer.createInvite(id, data);
    return invite;
  }),
  deleteInvite: thunk(async (actions, payload) => {
    const invite = await coolremoteSDK.Invite.delete(payload);
    return invite;
  }),
  sendInviteEmail: thunk(async (actions, payload) => {
    const { email, inviteId } = payload;
    return coolremoteSDK.Invite.sendInviteEmail(inviteId, { email });
  }),
  getInvites: thunk(async (actions, payload) => {
    return coolremoteSDK.Customer.getCustomerInvites(payload);
  }),
  getAllInvites: thunk(async (actions, payload) => {
    // const invites = await coolremoteSDK.Invite.getAllIvites();
    // actions.setInvites(invites);
    // return invites;
  }),
  createMultiInvites: thunk(async (actions, payload) => {
    const { data, id } = payload;
    const invite = await coolremoteSDK.Customer.createMultiInvites(id, data);
    return invite;
  }),
  sendMultiInvitesEmails: thunk(async (actions, payload) => {
    return coolremoteSDK.Services.sendMultiInvitesEmails(payload);
  }),
  getInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;
    return sdkInvite.getInviteInfo(payload);
  }),

  acceptInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;
    const { inviteId, data } = payload;

    return sdkInvite.acceptInvite(inviteId, data);
  })
};
