import { makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import clsx from "clsx";
import React, { useState } from "react";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { ArrowDownO, CheckboxChecked } from "../../icons";
import { useStoreState } from "../../models/RootStore";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
({
  root: {
    color: "#545964",
    fontSize: "14px",
    width: "100%",
    minHeight: "40px",
    "&:hover > $content": {
      backgroundColor: "transparent"
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, #fff)`,
      color: "var(--tree-view-color)"
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent"
    }
  },
  content: {
    color: "#545964",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingRight: "10px",
    fontWeight: 400,
    "$expanded > &": {
      fontWeight: 400
    }
  },
  group: {
    borderLeft: "1px solid #d5d2d5",
    marginLeft: "31px"
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
    padding: 0,
    display: "flex"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: "9px",
    paddingLeft: 0
  },
  labelIcon: {
    marginRight: "10px"
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    fontSize: 15
  },
  iconContainer: {
    alignItems: "center",
    width: "unset",
    marginRight: "5px"
  }
})
);

const Checkbox1 = (props: any) => {
  const classes = useStyles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={clsx(classes.labelRoot, { [className]: className })}>
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      } as any}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  ({
    root: {
      // height: "100%",
      height: "500px",
      flexGrow: 1,
      // maxWidth: 400,
      maxHeight: "calc(100% - 65px)",
      overflow: "auto",
      background: "#fff",
      padding: "0",
      paddingTop: "10px"
    },
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": {
        width: "18px",
        height: "18px",
        borderRadius: "3px",
        backgroundColor: "#fff"
      }
    },
    label: {
      color: "#545964",
      padding: 0
    },
    textField: {
      width: "100%",
      padding: "10px 20px",
      paddingBottom: "0px",
      " & div": {
        height: "40px",
        width: "100%"
      }
    }
  })
);

export default ({ unitId, site, currentSelectedUnits, setCurrentSelectedUnits, setDirty }: any) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string>("");
  const getUnits = useStoreState((state) => state.units.getFilteredUnits);
  const expanded = [`site-${site.id}`];

  const siteNodes = () => {
    const items: any = [];
    const { id, name } = site;

    const siteUnits = getUnits("site", id, { type: "indoor" }, true).sort((a: any, b: any) => {
      let nameA = a?.name?.toUpperCase();
      let nameB = b?.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    id && items.push(
      <StyledTreeItem
        key={`site-${id}`}
        nodeId={`site-${id}`}
        labelText={name}
        color="#545964"
        bgColor="#fff"
      >
        {unitsNodes(siteUnits)}
      </StyledTreeItem>);

    return items;
  };

  const unitsNodes = (units: any) => {
    const items: any = [];
    for (const i in units) {
      const unit = units[i];
      const { id, name = "" } = unit;
      let unitName: string = name;

      const search = searchValue?.toUpperCase();
      const finalUnitName = unitName?.toUpperCase();
      const disabled = false;
      if (unitId === id) {
        continue;
      }
      if (!search || (!!search && finalUnitName?.indexOf(search) >= 0)) {
        unit && items.push(
          <StyledTreeItem
            key={`unit-${id}`}
            nodeId={`unit-${id}`}
            labelText={unitName}
            style={{ opacity: disabled ? 0.5 : 1 }}
            node={
              <Checkbox1
                disabled={disabled}
                checked={currentSelectedUnits.includes(id)}
                onChange={(event: any) => {
                  event.target.checked ? setCurrentSelectedUnits([...currentSelectedUnits, id]) : setCurrentSelectedUnits(currentSelectedUnits.filter((index: any) => id !== index));
                  setDirty(true);
                }}
              />}
            color="#545964"
            bgColor="#fff"
          />);
      }
    }
    return items;
  };

  return (
    <>
      <TextField
        placeholder="Search unit name"
        variant="outlined"
        value={searchValue}
        onChange={(event: any) => setSearchValue(event.target.value)}
        className={classes.textField}
      />
      <TreeView
        className={classes.root}
        defaultExpanded={expanded}
        defaultCollapseIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ marginLeft: "7px", marginRight: "2px" }} /> </>}
        defaultExpandIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ transform: "rotate(-90deg)", marginLeft: "7px", marginRight: "2px" }} /></>}
        defaultEndIcon={<div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} />}
      >
        {siteNodes()}
      </TreeView>

    </>
  );
};
