import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: any) =>
({
  rootField: {
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    padding: 0,
    height: "50px",
    "& ::placeholder": {
      color: "#545964",
      fontSize: "13px",
      opacity: 1
    },
    "& fieldset": {
      border: 0
    }
  },
  disabledInput: {
    color: alpha("#545964", 0.6),
    "& ::placeholder": { opacity: 0.6 }
  },
  inputAdornment: {
    // marginLeft: "13px",
    // marginRight: "15px",
    // width: "21px"
    fontSize: "24px",
    marginRight: "9px"
  },
  notchedOutline: {
    borderColor: `${alpha("#545964", 0.3)} !important`
  },
  maxWidth: {
    width: "100%",
    "& div.MuiFormControl-root": { width: "100%" }
  }
}));
