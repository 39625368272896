import {
  CircularProgress,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, Formik } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { Arrow as SvgArrow, Close } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import useStyles from "./addEditSite.style";
import { countries } from "./countryList";
import InputField from "./InputField";
import { stateList } from "./stateList";

const initialOptions: any = {
  countryOptions: [], timezonesOptions: [], stateOptions: [], customersOptions: [], timezonesObject: {}
};

export default function Site(props: { siteId: null | true | string; close: any; actions: any; item: any }) {
  const [site, setSite] = React.useState<any>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState<any>(initialOptions);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const { customerId } = useStoreState((state) => state.selections.selections);
  const getSite = useStoreActions((actions) => actions.sites.getSiteById);
  const createSite = useStoreActions((action) => action.createSite);
  const updateSite = useStoreActions((action) => action.updateSite);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getSiteFlags = useStoreActions((actions) => actions.sites.getSiteFlags);

  const { siteId, close, actions, item } = props;
  const { canUpdate = true } = item || {};
  const isNew = siteId === true;

  useEffect(() => {
    //siteId === true means it's a new site not edit
    if (!siteId || siteId === true) {
      return;
    }

    getSite(siteId)
      .then((siteData: any) => {
        setSite(siteData);
      });

  }, [siteId]);

  useEffect(() => {
    const timezonesObject: any = {};

    const countryOptions = countries.map((country: any) => ({
      value: country.code,
      label: country.name
    }));
    const timezonesOptions = tz.names().map((timezone: any) => {
      const label = `${timezone}  ${tz(timezone).format("Z")}`;
      timezonesObject[timezone] = label;
      return { value: timezone, label };
    });
    const stateOptions = stateList.map((state: any) => ({
      value: state.value,
      label: state.label
    }));
    const customersOptions = Object.values(allCustomers).map((customer: any) => ({
      value: customer.id,
      label: customer.name
    }));

    setOptions({ countryOptions, timezonesOptions, stateOptions, customersOptions, timezonesObject });
  }, []);

  const classes = useStyles();

  const SiteSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    customer: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    postalCode: Yup.string(),
    country: Yup.string().required("Required"),
    state: Yup.string().when("country", {
      is: (value) => value && value === "US",
      then: Yup.string().required(
        "Required"
      ),
      otherwise: Yup.string()
    }),
    timezone: Yup.object().required("Required").nullable()

  });

  const initialValues: any = {
    name: site ? site.name : "",
    customer: site ? site.customer : customerId || "",
    city: site ? site.city : "",
    address: site ? site.address : "",
    postalCode: site ? site.postalCode : "",
    country: site ? site.country : "",
    state: site ? site.state : "",
    timezone: site?.timezone ? { value: site.timezone, label: options.timezonesObject[site.timezone] } : null

  };

  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;

  const saveSite = async (isNew: boolean, site: any, siteId: any) => {
    if (!site) {
      return;
    }
    setSaving(true);
    if (site.country !== "US") {
      site.state = "";
    }
    const data = {
      name: site.name,
      country: site.country,
      city: site.city,
      state: site.state,
      address: site.address,
      postalCode: site.postalCode,
      timezone: site.timezone.value
    };
    const method = isNew ? createSite({ customer: site.customer, ...data }) :
      updateSite({ siteId, updatedData: data });

    method.then(() => {
      close();
      // setTimeout(() => , 2000);
      actions.fetchData();
      isNew && getSiteFlags();
    })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setSaving(false));
  };

  return (
    <Dialog
      open={!!siteId}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      {saving && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 999 }}>
        <CircularProgress />
      </div>}

      <div className={classes.titleContent}>
        <Typography className={classes.headerTitle}>{!isNew ? t`Edit site` : t`Add New Site`}</Typography>
        <IconButton disableRipple onClick={() => close()} className={classes.iconBtnStyle}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        {errorBox}
        <Grid container spacing={3} direction="row">
          <Grid item xs={6}>
            <div className={classes.imgContainer}>
              <img
                className={classes.deviceImg}
                src={assetsService.getImagesPathFromDomainFolder("cmnet.png")}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => saveSite(isNew, values, siteId)}
              enableReinitialize={true}
              validationSchema={SiteSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleSubmit, errors, touched, values, setFieldValue, isSubmitting, ...restFormikProps }) => {

                return (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`Customer`}</Typography>
                      <Field
                        disabled={true}
                        name="customer"
                        options={options.customersOptions}
                        component={Select}
                        variant="outlined"
                        error={errors.customer}
                        classes={{ icon: classes.arrowIcon }}
                        IconComponent={SvgArrow}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      />
                    </div>
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`Site Name`}</Typography>
                      <Field
                        disabled={!canUpdate}
                        value={values.name}
                        name="name"
                        component={InputField}
                        error={((errors.name && touched.name)) ? true : false}
                      />
                      {<FormHelperText className={classes.requiredError}>{errors.name || " "}</FormHelperText>}
                    </div>
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`Street Address`}</Typography>
                      <Field
                        disabled={!canUpdate}
                        value={values.address}
                        name="address"
                        component={InputField}
                        error={((errors.address && touched.address)) ? true : false}
                      />
                      {<FormHelperText className={classes.requiredError}>{errors.address || " "}</FormHelperText>}
                    </div>
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`City`}</Typography>
                      <Field
                        disabled={!canUpdate}
                        value={values.city}
                        name="city"
                        component={InputField}
                        error={((errors.city && touched.city)) ? true : false}
                      />
                      {<FormHelperText className={classes.requiredError}>{errors.city || " "}</FormHelperText>}
                    </div>
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`Zip Code`}</Typography>
                      <Field
                        disabled={!canUpdate}
                        value={values.postalCode}
                        name="postalCode"
                        component={InputField}
                        error={((errors.postalCode && touched.postalCode)) ? true : false}
                      />
                      {<FormHelperText className={classes.requiredError}>{errors.postalCode || " "}</FormHelperText>}
                    </div>
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`Country`}</Typography>
                      <Field
                        disabled={!canUpdate}
                        options={options.countryOptions}
                        name="country"
                        component={Select}
                        variant="outlined"
                        error={errors.country}
                        classes={{ icon: classes.arrowIcon }}
                        IconComponent={SvgArrow}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      />
                    </div>
                    {values.country === "US" && <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`State`}</Typography>
                      <Field
                        disabled={!canUpdate}
                        // value={values.state}
                        options={options.stateOptions}
                        name="state"
                        component={Select}
                        variant="outlined"
                        error={errors.state}
                        classes={{ icon: classes.arrowIcon }}
                        IconComponent={SvgArrow}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      />
                    </div>}
                    <div className={classes.inputFieldWithLabel}>
                      <Typography>{t`Time zone`}</Typography>
                      <Autocomplete
                        disabled={!canUpdate}
                        id="combo-box-demo"
                        options={options.timezonesOptions}
                        getOptionLabel={(option) => option.label}
                        value={values.timezone}
                        onChange={(event: any, newValue: any | null) => setFieldValue("timezone", newValue)}
                        renderInput={(params) => <TextField {...params} placeholder="Timezone" variant="outlined"
                          error={((errors.timezone && touched.timezone)) ? true : false}
                        />}
                      />
                      {<FormHelperText className={classes.requiredError}>{errors.timezone || " "}</FormHelperText>}
                    </div>

                    <div className={classes.modalActions}>
                      <Button
                        onClick={close}
                        onMouseUp={close}
                        white
                        width={150}
                        marginRight
                        disabled={!canUpdate}
                      >
                        {t`Cancel`}
                      </Button>

                      <Button
                        width={150}
                        onClick={handleSubmit}
                        onMouseUp={handleSubmit}
                        disabled={saving || !canUpdate}
                      >
                        {t`Save`}
                      </Button>
                    </div>

                  </form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
