import { Button, Grid, } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Services as sdkServices } from "coolremote-sdk";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import CButton from "../../cool_widgets/Button";
import { useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import useStyles from "./ForgotPassword.style";
import InputField from "./InputField";

interface ILoginState {
  email: string;
}
const ForgotPasswordSchema = Yup.object({
  email: Yup.string().matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    t`Invalid email`
  )
});

const ForgotPassword: React.FC<any> = () => {
  const classes = useStyles();
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isLoginBack, setIsLoginBack] = useState(false);
  const initialValues: ILoginState = {
    email: ""
  };
  const configFile = useStoreState((s) => s.config);
  const { supportEmail } = configFile || {};

  const [error, setError] = useState<any>(null);
  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;

  const handleBack = () => {
    setIsLoginBack(true);
  };

  const handleSetPassword = (values: ILoginState) => {
    if (values.email) {
      const payload: any = { email: values.email, app: "professional" };

      sdkServices.forgotPassword(payload)
        .then((data: any) => {
          if (data)
            setIsPasswordSet(true);
          else
            setError("Email not found");
        })
        .catch((err: any) => {
          setError(err.message);
        });
    }
  };

  if (isLoginBack) {
    return <Redirect to={"/"} />;
  }
  if (isPasswordSet) {
    return <Redirect to={"/reset-password"} />;
  }

  return (
    <div className={classes.Login}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("logo.png")}
            className={classes.logo}
          />
        </div>

        <div className={classes.interactionSection}>
          <div className={classes.content}>
            <Typography
              className={classes.title}
            >{t`Password Recovery`}</Typography>
            {errorBox}
            <Formik
              initialValues={initialValues}
              onSubmit={handleSetPassword}
              enableReinitialize={true}
              validationSchema={ForgotPasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleSubmit,
                errors,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container className={classes.container}>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        classes={{
                          root: classes.overrideField
                        }}
                        label={t`Your username/email`}
                        value={values.email}
                        name="email"
                        component={InputField}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        title={t`Back to Login`}
                        className={classes.buttonBack}
                        onMouseUp={handleBack}
                      >
                        {t`Back to Login`}
                      </Button>
                      <CButton
                        title={t`Reset Password`}
                        className={classes.buttonReset}
                        type="submit"
                        red
                      >
                        {t`Reset Password`}
                      </CButton>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            <Typography className={classes.bottom}>
              {t`Forgot your username or email?`}
              <br />
              {t`Please contact `}
              <a className={classes.mailto} target="_blank" href={`mailto:` + supportEmail}>{supportEmail}</a>
            </Typography>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ForgotPassword;
