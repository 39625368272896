import {
  Button as MUiButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import useStyles, { paperStyle } from "./lookup.style";

export default function ErrorBox(props: any) {
  const classes = useStyles();
  const { onApply, lookupAnchor, filtersMap = {}, onClose } = props;
  const [map, setMap] = React.useState<any>(filtersMap);
  const filtersList = Object.values(map);

  const apply = () => {
    onApply &&
      onApply(map);
  };

  const toggleFilter = (val: boolean, id: string) => {
    setMap({ ...map, [id]: { ...map[id], show: val } });
  };

  const showHideAllUnits = (val: boolean) => {
    const itemsArr = Object.keys(map);
    const itemsMap = { ...map };
    for (let itemId of itemsArr) {
      itemsMap[itemId].show = val;
    }

    setMap(itemsMap);
  };

  const showingItems = Object.values(map).filter((item: any) => item.show);
  return (
    <Menu
      id="filters--menu"
      anchorEl={lookupAnchor}
      keepMounted
      open={true}
      onClose={onClose}
      className={classes.menuStyle}
      PaperProps={{
        style: paperStyle
      }}
    >
      <div className={classes.menuHeader}>
        <Typography>{t`Filters`}</Typography>
        <Clear onClick={onClose} />
      </div>
      <List disablePadding={true} className={classes.optionsContainer}>
        {(!filtersList || !filtersList.length) && (<Typography>{t`No Values to select`}</Typography>)}
        {filtersList && filtersList.length > 1 && (
          <ListItem dense autoFocus divider key={"all"}>
            <ListItemText id={"all"} primary={` All`} />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onChange={(e: any) => showHideAllUnits(e.target.checked)}
                checked={filtersList.length === showingItems?.length}
                indeterminate={filtersList.length !== showingItems.length && showingItems.length > 0}
                inputProps={{ "aria-labelledby": `toggle all` }}
              />
            </ListItemSecondaryAction>
          </ListItem>)}
        {filtersList.map((option: any, index: number) => (
          <ListItem dense autoFocus divider key={`filter-option-${option.id}`}>
            <ListItemText id={`${index}`} primary={option?.name} className={classes.optionTextStyle} />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onClick={(e: any) => toggleFilter(e.target.checked, option.id)}
                checked={map[option.id].show}
                inputProps={{ "aria-labelledby": option }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Button
        className={classes.applyBtn}
        onClick={apply}
      >
        {t`Apply`}
      </Button>
    </Menu>
  );
}
