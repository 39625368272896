import React from "react";

const FloorPlan = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.755 3.3a1.7 1.7 0 0 1 1.694 1.553l.006.147v13.934a1.7 1.7 0 0 1-1.553 1.693l-.147.007h-.994v-1.4h.994a.3.3 0 0 0 .292-.232l.008-.068-.001-6.704h-2.971v-1.4h2.971V5a.3.3 0 0 0-.23-.292l-.07-.008H13.44v6.13h2.465v1.4h-2.465v1.8h-1.4V4.7H4a.3.3 0 0 0-.292.231L3.7 5v3.723h5.277l-.001 10.51h3.063v-3.124h1.4v3.124h3.395v1.4H4a1.7 1.7 0 0 1-1.694-1.553l-.006-.146V5a1.7 1.7 0 0 1 1.553-1.694L4 3.3h15.755zM7.576 10.123H3.7v8.81a.3.3 0 0 0 .231.293l.069.008 3.576-.001v-9.11z"
      fill="#AAA2AA"
      fillRule="nonzero" />
  </svg>

);

export default FloorPlan;
