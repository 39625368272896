import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Invite as sdkInvite,
  Services as sdkServices,
  User as sdkUser,
  xhr as sdkXhr
} from "coolremote-sdk";
import { Field, Formik } from "formik";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import * as Yup from "yup";
import { ErrorStoreMessage } from "../../../components";
import CustomConfirmationDialog from "../../../components/CustomConfirmationDialog/CustomConfirmationDialog";
import Button from "../../../cool_widgets/Button";
import { SupportTicket } from "../../../icons/";
import SvgArrow from "../../../icons/Arrow";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import assetsService from "../../../services/assetsService";
import ReportProblem from "../../ReportProblem/ReportProblem";
import useStyles from "./AcceptInvite.style";
import InputField from "./InputField";

interface IAcceptInvite {
  username: string;
  password: string;
  error?: string;
  subscriptionCode?: string;
}

const AcceptInvite: React.FC<any> = (props) => {
  const match = useRouteMatch<{ inviteToken: string }>();
  const history = useHistory();
  const configFile = useStoreState((s) => s.config);
  const types = useStoreState((s) => s.types);
  const logout = useStoreActions((a) => a.doLogout);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const currUser = useStoreState((s) => s.users.me);
  const userPref = useStoreState((s) => s.users.userPreferences);
  const updateUserPreferences = useStoreActions((a) => a.users.updateUserPreferences);
  const getUserPreferences = useStoreActions((a) => a.users.getUserPreferences);
  const startLoader = useStoreActions((a) => a.loader.startLoader);
  const getMe = useStoreActions((a) => a.users.getMe);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  let inviteToken: string = match.params.inviteToken;

  const [showAcceptOptions, setShowAcceptOptions] = useState(false);
  const [inputSubCode, setInputSubCode] = useState(false);
  const [err, setErr] = useState("");
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [expired, setIsExpired] = useState<boolean>(false);
  const [inviteObj, setInviteObj] = useState<any>(null);
  const [subCode, setSubCode] = useState<any>("");
  const [mode, setMode] = useState<"existing" | "new" | null>(null);
  const { temperatureScale: tempOptions } = types;
  const [lists, setLists] = useState<any>({
    temperatureScaleOptions: [],
    timeFormatOptions: [],
    dateFormatOptions: []
  });
  const [loginState, setLoginState] = useState<any>({});
  const classes = useStyles();

  const doLogin = useStoreActions((a) => a.doLogin);
  const verify2FA = useStoreActions((a) => a.verify2FA);
  const [open2FADialog, setOpen2FADialog] = useState<boolean>(false);
  const [error2FA, setError2FA] = useState<any>(null);

  const newUserValidation = Yup.object({
    firstName: Yup.string().required(t`Required`),

    lastName: Yup.string().required(t`Required`),

    password: Yup.string()
      .min(8, t`Password is too short - should be 8 chars minimum.`)
      .matches(/[A-Z]/, t`password must include capital letters`)
      .matches(/[a-z]/, t`password must include lower case letters`)
      .matches(/[0-9]/, t`password must include numbers`)
      .required(t`Required`),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t`Passwords must match`)
      .required(t`Confirm password is required`),

    email: Yup.string()
      .required(t`Required`)
      .test("email validation", t`Invalid email`, (value) => {
        if (!value) {
          return true;
        }

        const emailSchema =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailSchema.test(String(value).toLowerCase())) {
          return true;
        }
        return false;
      }),
    phone: Yup.string().test(
      "phone validation",
      t`Phone number should be 10 chars minimum.`,
      (value) => {
        if (!value) {
          return true;
        }

        let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
        newValue = newValue.replace(/\s/g, "");

        if (newValue.length > 10 || newValue.length === 0) {
          return true;
        }
        return false;
      }
    ),
    subscriptionCode: inviteObj?.setSubscription
      ? Yup.string().required("This field is reqired for service providers!")
      : Yup.string()
  });

  const LoginSchema = Yup.object({
    password: Yup.string().required(),
    username: Yup.string().required().min(6, "Too Short!").max(50, "Too Long!"),
    subscriptionCode: inviteObj?.setSubscription
      ? Yup.string().required("This field is reqired for service providers!")
      : Yup.string()
  });

  const cancel = () => {
    setOpen2FADialog(false);
    setError2FA(null);
  };

  const handleVerify = (code: string) => {
    verify2FA({ code }).catch((response: any) => {
      if (response) {
        setError2FA(
          response.message
            ? t`The code you entered is invalid or expired. Please check again you entered the right code, or try logging in again to receive a new code.`
            : null
        );
      }
    });
  };

  useEffect(() => {
    if (!inviteToken || !types || _.isEmpty(types)) {
      return;
    }

    startLoader();
    sdkInvite
      .getInviteInfo(inviteToken)
      .then((resp: any) => {
        setInviteObj(resp);
        return resp;
      })
      .then((invite: any) => {
        return sdkUser.isUsernameAvailable(invite.email);
      })
      .then((resp: any) => {
        setMode(!resp?.available ? "existing" : "new");
        return resp?.available;
      })
      .catch(({ message }: any) => {
        setIsExpired(true);
        addMessage({ message, onClick: goToHome });
      })
      .finally(finishLoader);
  }, [inviteToken, types]);

  useEffect(() => {
    if (expired || !currUser || !inviteObj || !isLoggedIn) {
      return;
    }
    if (currUser?.email?.toLowerCase() === inviteObj?.email?.toLowerCase()) {
      if (!subCode?.length && inviteObj?.setSubscription) {
        setInputSubCode(true);
        return;
      }
      acceptInvite(inviteToken, subCode);
      return;
    }
    if (!currUser?.id && inviteObj?.email) {
      getMe().then((currUser: { email: string }) => {
        if (
          currUser?.email?.toLowerCase() === inviteObj?.email?.toLowerCase()
        ) {
          acceptInvite(inviteToken, subCode);
          return;
        }
      });
    }
    if (currUser?.username?.length && currUser?.username !== inviteObj?.email) {
      setShowAcceptOptions(true);
      return;
    }
  }, [isLoggedIn, expired, inviteObj]);

  useEffect(() => {
    setLoginState({});
  }, [mode]);

  useEffect(() => {
    if (!tempOptions) {
      return;
    }
    const dropdownkeys = Object.keys(tempOptions);
    const dropdownOptions: [object] = [{}];
    dropdownOptions.shift();
    dropdownkeys.map((key) => {
      if (tempOptions[key] !== "unknown") {
        dropdownOptions.push(
          <MenuItem
            value={+key}
            style={{ textTransform: "capitalize" }}
            key={`tempOption-${+key}`}
          >
            {tempOptions[key]}
          </MenuItem>
        );
      }
      return key;
    });

    const timeOptions = types.timeFormat.map((item: any) => (
      <MenuItem value={item.value} key={`timeOption-${item.value}`}>
        {item.text}
      </MenuItem>
    ));
    const dateOptions = types.dateFormat.map((item: any) => (
      <MenuItem value={item.value} key={`dateOption-${item.value}`}>
        {item.text}
      </MenuItem>
    ));
    setLists({
      temperatureScaleOptions: dropdownOptions,
      timeFormatOptions: timeOptions,
      dateFormatOptions: dateOptions
    });
  }, [tempOptions]);

  const acceptInvite = async (token: string, subCode: string) => {
    let newUserPref = userPref || {}
    if (_.isEmpty(newUserPref)) {
      newUserPref = await getUserPreferences()
    }
    if (inviteObj.customerNickname && !newUserPref?.nicknames?.[inviteObj.customer]) {
      const newNicknames = newUserPref?.nicknames || {};
      newNicknames[inviteObj.customer] = inviteObj.customerNickname;
      updateUserPreferences({ ...newUserPref, nicknames: newNicknames });
    }
    setShowAcceptOptions(false);
    startLoader();
    return sdkInvite
      .acceptInvite(token, subCode)
      .then(() => {
        history.push("/");
        window.location.reload();
      })
      .catch((error: any) => {
        setLoginState({ ...loginState, error: error.message });
        setErr(error.message);
      })
      .finally(() => {
        finishLoader();
      });
  };

  const goToHome = () => {
    setMode(null);
    setOpen2FADialog(false);
    setShowAcceptOptions(false);
    window.location.href = "/";
  };

  const handleLogin = (values: IAcceptInvite) => {
    if (inviteObj?.setSubscription) {
      return sdkServices
        .verifySubCode(inviteToken, values.subscriptionCode)
        .then(() => {
          setSubCode(values.subscriptionCode);
          return doLogin({
            username: values.username,
            password: values.password
          });
        })
        .then((res: any) => {
          const is2FA = !!res?.is2FA;
          setOpen2FADialog(is2FA);
        })
        .catch((response: any) => {
          if (response) {
            setLoginState({ ...loginState, error: response.message });
          }
        });
    }

    return doLogin({
      username: values.username,
      password: values.password
    })
      .then((res: any) => {
        const is2FA = !!res?.is2FA;
        setOpen2FADialog(is2FA);
      })
      .catch((response: any) => {
        if (response) {
          setLoginState({ ...loginState, error: response.message });
        }
      });
  };

  const handleForgotPassword = () => {
    return history.push("/forgot-password");
  };

  const createUser = (values: any, actions: any) => {
    const { phone, email } = values;
    const newPhone = phone.replace(/[+]/g, "");

    const userData = {
      ...values,
      phone: newPhone,
      invite: inviteToken,
      username: email,
      appId: "CoolCommercial"
    };

    const { confirmPassword, ...restData } = userData;

    startLoader();

    sdkUser
      .createUser({ ...restData })
      .then((resp: any) => {
        if (!resp) {
          return;
        }
        localStorage.setItem("token", resp.token);
        return sdkXhr.setToken(resp.token);
      })
      .then(() => {
        if (inviteObj.customerNickname) {
          const userPref = {
            nicknames: {
              [inviteObj.customer]: inviteObj.customerNickname
            }
          }
          updateUserPreferences(userPref).then(() => {
            goToHome();
          })
        } else {
          goToHome();
        }
      })
      .catch(({ message }: any) => addMessage({ message, onclick: goToHome }))
      .finally(finishLoader);
  };

  const logoutAndRefresh = async () => {
    await logout();
    window.location.reload();
  };

  const renderInteractionSection = () => {
    switch (mode) {
      case "new":
        return (
          <>
            {/* /////////////////////CREATE USER */}
            <Typography
              className={classes.title}
            >{t`To accept the invite, you need to create a user`}</Typography>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: inviteObj?.email || "",
                password: "",
                confirmPassword: "",
                phone: "",
                timeFormat: 0,
                dateFormat: 0,
                temperatureScale: 1,
                subscriptionCode: ""
              }}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values, actions) => createUser(values, actions)}
              enableReinitialize={true}
              validationSchema={newUserValidation}
            >
              {({ handleSubmit, errors, touched, values, setFieldValue }) => {
                return (
                  <form>
                    <Grid container className={classes.container}>
                      <Grid item className={classes.fieldContainer}>
                        <Field
                          tabIndex={2}
                          label={t`First Name`}
                          value={values.firstName}
                          name="firstName"
                          component={InputField}
                          error={
                            errors.firstName && touched.firstName ? true : false
                          }
                          helperText={
                            errors.firstName && touched.firstName
                              ? errors.firstName
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item className={classes.fieldContainer}>
                        <Field
                          tabIndex={3}
                          label={t`Last Name`}
                          value={values.lastName}
                          name="lastName"
                          component={InputField}
                          error={
                            errors.lastName && touched.lastName ? true : false
                          }
                          helperText={
                            errors.lastName && touched.lastName
                              ? errors.lastName
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item className={classes.fieldContainer}>
                        <Field
                          tabIndex={4}
                          label={t`Email`}
                          value={values?.email || ""}
                          name="email"
                          component={InputField}
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email ? errors.email : ""
                          }
                          disabled
                        />
                      </Grid>
                      {inviteObj?.setSubscription && (
                        <Grid item className={classes.fieldContainer}>
                          <Field
                            tabIndex={4}
                            label={t`Subscription Code`}
                            value={values?.subscriptionCode || ""}
                            name="subscriptionCode"
                            component={InputField}
                            error={
                              errors.subscriptionCode &&
                              touched.subscriptionCode
                            }
                            helperText={
                              errors.subscriptionCode &&
                              touched.subscriptionCode
                                ? errors.subscriptionCode
                                : ""
                            }
                          />
                        </Grid>
                      )}
                      <Grid item className={classes.fieldContainer}>
                        <MuiPhoneNumber
                          error={errors.phone}
                          tabIndex={5}
                          defaultCountry={"us"}
                          placeholder="Enter Phone Number"
                          label="Enter Phone Number"
                          value={values.phone}
                          onChange={(e: any) => setFieldValue("phone", e)}
                          disableAreaCodes={true}
                          variant="outlined"
                          className={classes.textFieldInputClass}
                        />
                      </Grid>
                      {!!errors.phone && (
                        <FormHelperText error={true} className={classes.error}>
                          {" "}
                          {errors.phone}
                        </FormHelperText>
                      )}

                      <Grid item className={classes.fieldContainer}>
                        <FormControl variant="filled">
                          <InputLabel
                            className={classes.selectLabel}
                          >{t`Temperature Units`}</InputLabel>
                          <Select
                            tabIndex={6}
                            variant="filled"
                            className={classes.select}
                            value={values.temperatureScale || 1}
                            onChange={(event: any) =>
                              setFieldValue(
                                "temperatureScale",
                                event.target.value
                              )
                            }
                            IconComponent={SvgArrow}
                          >
                            {lists?.temperatureScaleOptions}
                          </Select>
                          <span>{props.helperText}</span>
                        </FormControl>
                      </Grid>

                      <Grid item className={classes.fieldContainer}>
                        <FormControl variant="filled">
                          <InputLabel
                            className={classes.selectLabel}
                          >{t`Time Format`}</InputLabel>
                          <Select
                            tabIndex={7}
                            variant="filled"
                            className={classes.select}
                            value={values.timeFormat || 0}
                            onChange={(event: any) =>
                              setFieldValue("timeFormat", event.target.value)
                            }
                            IconComponent={SvgArrow}
                          >
                            {lists?.timeFormatOptions}
                          </Select>
                          <span>{props.helperText}</span>
                        </FormControl>
                      </Grid>

                      <Grid item className={classes.fieldContainer}>
                        <FormControl variant="filled">
                          <InputLabel
                            className={classes.selectLabel}
                          >{t`Date Format`}</InputLabel>
                          <Select
                            tabIndex={8}
                            variant="filled"
                            className={classes.select}
                            value={values.dateFormat || 0}
                            onChange={(event: any) =>
                              setFieldValue("dateFormat", event.target.value)
                            }
                            IconComponent={SvgArrow}
                          >
                            {lists?.dateFormatOptions}
                          </Select>
                          <span>{props.helperText}</span>
                        </FormControl>
                      </Grid>

                      <Grid item className={classes.fieldContainer}>
                        <Field
                          type="password"
                          autoComplete="new-password"
                          tabIndex={9}
                          label={t`Password`}
                          value={values.password}
                          name="password"
                          component={InputField}
                          error={
                            errors.password && touched.password ? true : false
                          }
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item className={classes.fieldContainer}>
                        <Field
                          type="password"
                          tabIndex={10}
                          label={t`Confirm Password`}
                          value={values.confirmPassword}
                          name="confirmPassword"
                          component={InputField}
                          error={
                            errors.confirmPassword && touched.confirmPassword
                              ? true
                              : false
                          }
                          helperText={
                            errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item className={classes.fieldContainer}></Grid>
                      <Grid item className={classes.buttonContainer}>
                        <Button
                          title={t`Submit`}
                          className={classes.buttonLogin}
                          uppercase
                          bold
                          red
                          onClick={handleSubmit}
                        >
                          {t`Submit`}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </>
        );
      case "existing":
        return (
          <>
            <Typography
              className={classes.title}
            >{t`Welcome! Please login`}</Typography>
            <Formik
              initialValues={{
                username: inviteObj?.email || "",
                password: "",
                subscriptionCode: ""
              }}
              onSubmit={handleLogin}
              enableReinitialize={true}
              validationSchema={LoginSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleSubmit,
                errors,
                touched,
                values,
                ...restFormikProps
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container className={classes.container}>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        tabIndex={1}
                        label={t`Username`}
                        value={values.username}
                        name="username"
                        disabled
                        component={InputField}
                        error={
                          errors.username && touched.username ? true : false
                        }
                        helperText={
                          errors.username && touched.username
                            ? errors.username
                            : ""
                        }
                      />
                      {errors.username ? (
                        <Typography style={{ color: "red" }}> </Typography>
                      ) : null}
                    </Grid>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        type="password"
                        tabIndex={2}
                        label={t`Password`}
                        value={values.password}
                        name="password"
                        component={InputField}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : ""
                        }
                      />
                    </Grid>
                    {inviteObj?.setSubscription && (
                      <Grid item className={classes.fieldContainer}>
                        <Field
                          tabIndex={3}
                          label={t`Subscription Code`}
                          value={values.subscriptionCode}
                          name="subscriptionCode"
                          component={InputField}
                          error={
                            errors.subscriptionCode && touched.subscriptionCode
                          }
                          helperText={
                            errors.subscriptionCode && touched.password
                              ? errors.subscriptionCode
                              : ""
                          }
                        />
                      </Grid>
                    )}
                    <Grid item className={classes.fieldContainer}>
                      {loginState.error ? (
                        <span className={classes.error}>
                          {loginState.error}
                        </span>
                      ) : null}
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        title={t`LOGIN`}
                        className={classes.buttonLogin}
                        uppercase
                        bold
                        red
                        onClick={handleSubmit}
                      >
                        {t`Login`}
                      </Button>
                      <button type="submit" style={{ display: "none" }}>
                        login2
                      </button>
                      {/* this second button is only to capture the "enter" button.. the first button uses on mouse up due to bug in MUI  */}
                    </Grid>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={handleForgotPassword}
                      className={classes.forgotPassBtn}
                    >
                      {t`Forgot Password?`}
                    </Link>
                  </Grid>
                </form>
              )}
            </Formik>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className={classes.Login}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("logo.png")}
            className={classes.logo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.style.visibility = "hidden";
            }}
          />
        </div>
        {!!configFile ? (
          <div className={classes.interactionSection}>
            {!showAcceptOptions && renderInteractionSection()}
          </div>
        ) : null}
      </div>
      <Button
        disableRipple
        variant="contained"
        className={classes.reportProblemButton}
        onClick={() => {
          if (configFile?.supportBtnLink?.length) {
            window?.open(configFile?.supportBtnLink, '_blank')?.focus();
            return;
          }
          setOpenReport(true)
        }}
        title={t`Open Support Ticket`}
        red
      >
        {
          configFile?.supportImg ?
            <img src={assetsService.getResourcePath(configFile?.supportImg)} alt="" style={{ width: "3.4rem", height: '3.4rem' }} /> :
            <SupportTicket width={"2rem"} height={"2rem"} />
        }
      </Button>
      {openReport && (
        <ReportProblem
          openReport
          location={"login"}
          onClose={() => setOpenReport(false)}
        />
      )}
      {showAcceptOptions && (
        <ErrorStoreMessage
          message={{
            textNotCentered: true,
            message: t`You are currently logged in as ${currUser?.email}.\nYou can Log out and continue registration as ${inviteObj?.email} or stay logged in and complete registration later`,
            buttonText: t`Logout`,
            secondaryText: t`Ignore`,
            onClick: goToHome,
            mainAction: () => {
              logout().then(() => window.location.reload());
            },
            allowDialogClose: false
          }}
        />
      )}
      {inputSubCode && (
        <Dialog
          open={inputSubCode}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Accept Service Provider Invite"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              As a serivce provider, please enter your subscription code to
              accept the invite
            </DialogContentText>
            <TextField
              fullWidth={true}
              variant="outlined"
              label={t`Subscription Code`}
              onChange={(e: any) => setSubCode(e.target.value)}
              value={subCode}
              error={!!err.length}
            />
            {!!err?.length && <span style={{ color: "red" }}>{err}</span>}
          </DialogContent>
          <DialogActions>
            <Button onClick={logoutAndRefresh} white>
              {t`Logout`}
            </Button>
            <Button onClick={() => acceptInvite(inviteToken, subCode)}>
              {t`Accept Invite`}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CustomConfirmationDialog
        onCancel={cancel}
        onConfrim={handleVerify}
        onClose={cancel}
        title={t`Additional authentication required`}
        text={t`You account requires an additional authentication code that was sent to your email address. Please enter the code below`}
        confrimLabel={t`Submit`}
        cancelLabel={t`Cancel`}
        openDialog={open2FADialog}
        errorText={error2FA}
      />
    </div>
  );
};

export default AcceptInvite;
