import {
  Button as MuiButton,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import Switch from "../../../components/SmallSwitch/Switch";
import TimePicker from "../../../components/TimePicker/TimePicker";
import scheduletypes from "../../../constants/scheduletypes";
import Button from "../../../cool_widgets/Button";
import Checkbox from "../../../cool_widgets/CoolCheckbox";
import DaysList from "../../../cool_widgets/DaysList/DaysList";
import { CheckboxChecked } from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../../services/timeService";
import { ColdBlue, HotRed } from "../../../svgComponents";
import useStyles from "./AddEditSchedulePopup.style";

const startHours: any = {
  "12:00am": true,
  "00:00": true,
  "00:00am": true
};
//ask about 12:01111 pm? and 00 -- 12am
const endHours: any = {
  "11:59pm": true,
  "23:59": true
};

export default function AddEditSchedulePopup(props: any) {
  const classes = useStyles();
  const { capabilityFlags = {}, onClose, schedule = "new", itemId, scheduleId, item, updateLocalSchedules, canEdit = true } = props;
  const { temperatureLimits } = item;
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [days, setDays] = useState<[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [openPicker, setOpenPicker] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [tempLimits, setTempLimits] = useState<any>(temperatureLimits);
  const [eWrcEnableOnoff, setEnableOnOff] = useState<boolean>(false);
  const [eWrcEnableMode, setEnableMode] = useState<boolean>(false);
  const [eWrcEnableSetpoint, setEnableSetpoint] = useState<boolean>(false);
  const [eWrcEnableCoolMode, setEnableCoolMode] = useState<boolean>(false);
  const [eWrcEnableHeatMode, setEnableHeatMode] = useState<boolean>(false);
  const [allDay, setAllDay] = useState<boolean>(false);
  const [scheduleCategory, setScheduleCategory] = useState<number>(1);

  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const types = useStoreState((state) => state.types);
  const me = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const { temperatureScale, timeFormat: timeFormatEnum } = me;
  const { weekDays = [""], scheduleCategories } = types;
  const { schedule: scheduleData = {} } = props;
  const { dates: selectedDates = [] } = scheduleData;
  const [dates, setDates] = useState<any>(canEdit ? selectedDates.map((date: any) => new Date(date)) : []);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createObjectSchedules = useStoreActions((actions) => actions.schedules.createObjectSchedules);

  const is12Hours = timeFormatEnum === 1 ? true : false;
  const [weekDaysOptions] = useState<any>(Object.keys(weekDays));

  const {
    enableWrcSettingsOnOffLock = false, enableWrcSettingsSetpointChangeLock = false,
    enableWrcSettingsModeLock = false, enableWrcSettingsLimitCoolingSetpoint = false,
    enableWrcSettingsLimitHeatingSetpoint = false
  } = capabilityFlags;

  const passScheduleInfo = (schedule: any) => {
    const { powerOnTime, powerOffTime, name, days = [], eWrcEnableOnoff, eWrcEnableSetpoint,
      eWrcEnableMode, eWrcEnableCoolMode, eWrcEnableHeatMode, scheduleCategory } = schedule;

    const powerOn = minsToTime(powerOnTime, timeFormat);
    const powerOff = minsToTime(powerOffTime, timeFormat);

    if (startHours[powerOn] && endHours[powerOff]) { setAllDay(true); }
    else { setAllDay(false); }

    setPowerOnTime(powerOn);
    setPowerOfftime(powerOff);
    setName(name);
    setScheduleCategory(scheduleCategory);
    setDays(days as []);
    setEnableOnOff(!!eWrcEnableOnoff);
    setEnableMode(!!eWrcEnableMode);
    setEnableSetpoint(!!eWrcEnableSetpoint);
    setEnableCoolMode(!!eWrcEnableCoolMode);
    setEnableHeatMode(!!eWrcEnableHeatMode);
  };

  const slidersRef: any = useRef([]);

  useEffect(() => {
    if (slidersRef.current.length) {
      slidersRef.current.forEach((slider: any) => {
        slider?.addEventListener(
          "touchstart",
          (e: any) => {
            const isThumb = e.target?.dataset.index;
            if (!isThumb) {
              e.stopPropagation();
            }
          },
          { capture: true }
        );
      });
    }
  }, [slidersRef.current.length]);

  useEffect(() => {
    if (schedule === "new") {
      return;
    }

    passScheduleInfo(schedule);
  }, []);

  const checkRequiredFields = () => {
    setErrorMsg("");

    if (!name) {
      setErrorMsg("Name is required");
      return false;
    }

    if (!powerOnTime || !powerOffTime) {
      setErrorMsg("Add start And end hour");
      return false;
    }

    if (!days?.length && scheduleCategory === scheduleCategories.weekly) {
      setErrorMsg("Pick one day at least");
      return false;
    }

    if (!dates?.length && scheduleCategory === scheduleCategories.calendar) {
      setErrorMsg("Pick one day at least");
      return false;
    }

    if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
      setErrorMsg("End hour must be later than start hour");
      return false;
    }

    return true;
  };

  const editSchedule = () => {

    const startHour = stringTimeToUTCMins(powerOnTime, is12Hours);
    const endHour = stringTimeToUTCMins(powerOffTime, is12Hours);
    const daysOrDates = scheduleCategory === scheduleCategories.weekly ? { days } : { dates };

    updateSchedule({
      id: scheduleId,
      data: {
        ...daysOrDates,
        powerOnTime: startHour,
        powerOffTime: endHour,
        name,
        // type: scheduletypes.eDeviceLimits,
        eWrcTemperatureLimits: enableWrcSettingsLimitCoolingSetpoint || enableWrcSettingsLimitHeatingSetpoint ? tempLimits : undefined,
        eWrcEnableOnoff: enableWrcSettingsOnOffLock ? eWrcEnableOnoff : undefined,
        eWrcEnableMode: enableWrcSettingsModeLock ? eWrcEnableMode : undefined,
        eWrcEnableSetpoint: enableWrcSettingsSetpointChangeLock ? eWrcEnableSetpoint : undefined,
        eWrcEnableCoolMode: enableWrcSettingsLimitCoolingSetpoint ? eWrcEnableCoolMode : undefined,
        eWrcEnableHeatMode: enableWrcSettingsLimitHeatingSetpoint ? eWrcEnableHeatMode : undefined,
        scheduleCategory
      }
    })
      .then((schedule: any) => {
        updateLocalSchedules({ ...schedule, eWrcTemperatureLimits: tempLimits });
        onClose();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const createSchedule = () => {
    const startHour = stringTimeToUTCMins(powerOnTime, is12Hours);
    const endHour = stringTimeToUTCMins(powerOffTime, is12Hours);
    const daysOrDates = scheduleCategory === scheduleCategories.weekly ? { days } : { dates };
    createObjectSchedules({
      data: {
        ...daysOrDates,
        powerOnTime: startHour,
        powerOffTime: endHour,
        name,
        type: scheduletypes.eDeviceLimits,
        eWrcTemperatureLimits: enableWrcSettingsLimitCoolingSetpoint || enableWrcSettingsLimitHeatingSetpoint ? tempLimits : undefined,
        eWrcEnableOnoff: enableWrcSettingsOnOffLock ? eWrcEnableOnoff : undefined,
        eWrcEnableMode: enableWrcSettingsModeLock ? eWrcEnableMode : undefined,
        eWrcEnableSetpoint: enableWrcSettingsSetpointChangeLock ? eWrcEnableSetpoint : undefined,
        eWrcEnableCoolMode: enableWrcSettingsLimitCoolingSetpoint ? eWrcEnableCoolMode : undefined,
        eWrcEnableHeatMode: enableWrcSettingsLimitHeatingSetpoint ? eWrcEnableHeatMode : undefined,
        scheduleCategory
      },
      objId: itemId,
      objectType: "unit"
    })
      .then((schedule: any) => {
        updateLocalSchedules(schedule);
        onClose();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const save = () => {
    const allRequiredNotEmpty = checkRequiredFields();

    if (!allRequiredNotEmpty) {
      return;
    }

    if (scheduleId === "new") {
      createSchedule();
      return;
    }

    editSchedule();
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day: string) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
  };

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
    allDay && setAllDay(false);
  };

  const onSetTime = (time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
    setOpenPicker("");

    if ((openPicker === "start" && startHours[time] && endHours[powerOffTime]) ||
      (openPicker !== "start" && endHours[time] && startHours[powerOnTime])) {
      setAllDay(true);
    } else { setAllDay(false); }
  };

  const hasTime = (time: any) => {
    return time !== "" && time !== null && time >= 0;
  };

  const onInput = (event: any, powerTime: "start" | "end") => {
    const { target } = event.nativeEvent;

    if (target.defaultValue && target.defaultValue === target.value) {
      powerTime === "start" ? setPowerOnTime("") : setPowerOfftime("");
    }

    function iosClearDefault() {
      target.defaultValue = "";
    }
    window.setTimeout(iosClearDefault, 0);
  };

  const handlePowerOffTime = (event: any) => {
    const {
      target: { value }
    } = event;

    setPowerOfftime(value || "");
  };

  const handlePowerOnTime = (event: any) => {
    const {
      target: { value }
    } = event;

    setPowerOnTime(value || "");
  };

  const hasTimeIOS = (time: number | string) => {
    return time || time === 0;
  };

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + temperatureSymbol;
    return `${valueToShow}`;
  };

  const handleCoolRange = (event: any, newValue: number | number[]) => {
    setTempLimits({ ...tempLimits, 0: newValue });
  };

  const handleHeatRange = (event: any, newValue: number | number[]) => {
    setTempLimits({ ...tempLimits, 1: newValue });
  };

  const handleAllDay = (event: any) => {
    const { target: { checked } } = event;
    if (checked) {
      setPowerOnTime(is12Hours ? "12:00am" : "00:00");
      setPowerOfftime(is12Hours ? "11:59pm" : "23:59");
      setAllDay(true);
    } else {
      setPowerOnTime("");
      setPowerOfftime("");
      setAllDay(false);
    }
  };
  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates.concat();
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
  };
  const today = new Date();

  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);

  return (
    <div className={classes.filtersPopup}>
      <div className={classes.filtersContainer}>
        <div className={classes.menuHeader}>
          <Typography className={classes.mainTitle}>{t`Add / Edit WRC Schedule`}</Typography>
          <IconButton className={classes.iconBtn} onClick={onClose}><Clear /></IconButton>
        </div>
        <div style={{ maxHeight: "calc(100% - 100px)", overflow: "auto", padding: "0 10px" }}>
          <div className={classes.flexContainer}>
            <Typography className={classes.label}>{t`Schedule Name`}</Typography>
            <TextField
              disabled={!canEdit}
              value={name}
              onChange={(event: any) => setName(event.target.value)}
              className={classes.textFieldStyle}
              placeholder={t`Schedule Name`}
              variant="outlined"
            />
          </div>
          <div>
            <Tabs
              value={scheduleCategory}
              onChange={(event: any, newValue: number) => setScheduleCategory(newValue)}
              variant="fullWidth"
              classes={{ root: classes.root, indicator: classes.indicatorColor }}
            >
              <Tab value={scheduleCategories.weekly} label={t`Weekly`} />
              <Tab value={scheduleCategories.calendar} label={t`Calendar`} />
            </Tabs>
            {scheduleCategory === scheduleCategories.weekly && <Grid container
              className={classes.container}
            >
              <Typography className={clsx(classes.selectModeStyle)}>
                {t`Choose Days`}
              </Typography>
              <Grid container className={classes.daysContainer} id="days">
                <DaysList
                  days={weekDaysOptions}
                  activeDays={days}
                  action={addRemoveDay}
                  white={false}
                  disablePointer={!canEdit}
                />
              </Grid>
            </Grid>}
            {scheduleCategory === scheduleCategories.calendar && <Grid
              container
              className={classes.tabContainer}
            >
              <Typography className={clsx(classes.selectModeStyle)}>
                {t`Choose Dates`}
              </Typography>
              <Grid container className={classes.daysContainer} id="dates">
                <DayPicker
                  selectedDays={dates}
                  onDayClick={handleDayClick}
                  className={classes.calendar}
                  disabledDays={{
                    before: today,
                    after: aYearFromToday
                  }}
                  fromMonth={today}
                  toMonth={aYearFromToday}
                />
              </Grid>
            </Grid>}
          </div>
          <div className={classes.flexContainer}>
            <Typography className={classes.checkboxSection}>
              <Checkbox
                disabled={!canEdit}
                color="default"
                edge="end"
                variant="outlined"
                onChange={handleAllDay}
                checked={allDay}
                onClick={(event: any) => event.stopPropagation()}
                checkedIcon={<CheckboxChecked />}
                className={classes.checkboxStyle}
              />
              {t`All Day`}
            </Typography>
            <div className={classes.btnsHolder}>

              {isIOS ? (
                <>
                  <div
                    className={clsx(classes.iosHourButton, {
                      [classes.timeSelected]: !!powerOnTime
                    })}
                  >
                    <InputLabel
                      className={classes.iosTimeLabel}
                    >
                      {powerOnTime
                        ? powerOnTime
                        : "Start Hour"}
                    </InputLabel>
                    <Input
                      className={classes.iosTimeInput}
                      disableUnderline
                      type="time"
                      value={powerOnTime}
                      onChange={(event: any) => !canEdit ? {} : handlePowerOnTime(event)}
                      onInput={(event: any) => !canEdit ? {} : onInput(event, "start")}
                    />
                  </div>
                  <div
                    className={clsx(classes.iosHourButton, {
                      [classes.timeSelected]: !!powerOffTime
                    })}
                  >
                    <InputLabel
                      className={classes.iosTimeLabel}
                    >
                      {powerOffTime ? powerOffTime : "End Hour"}
                    </InputLabel>
                    <Input
                      className={classes.iosTimeInput}
                      disableUnderline
                      type="time"
                      value={powerOffTime}
                      onChange={(event: any) => !canEdit ? {} : handlePowerOffTime(event)}
                      onInput={(event: any) => !canEdit ? {} : onInput(event, "end")}
                    />
                  </div>
                </>
              ) : (
                <>
                  <MuiButton
                    disabled={!canEdit}
                    variant="contained"
                    disableRipple
                    disableElevation
                    className={clsx(classes.timeContainer, {
                      [classes.timeSelected]: !!powerOnTime
                    })}
                    onClick={() => setOpenPicker("start")}
                  >
                    {powerOnTime ? powerOnTime : "Start Hour"}
                  </MuiButton>
                  <MuiButton
                    disabled={!canEdit}
                    variant="contained"
                    disableRipple
                    disableElevation
                    className={clsx(classes.timeContainer, {
                      [classes.timeSelected]: !!powerOffTime
                    })}
                    onClick={() => setOpenPicker("end")}
                  >
                    {powerOffTime ? powerOffTime : "End Hour"}
                  </MuiButton>
                </>
              )}
            </div>
          </div>

          {enableWrcSettingsOnOffLock &&
            <Typography className={classes.rowSectionTitle}>{t`Restrict On/OFF`}
              <Switch
                checked={eWrcEnableOnoff}
                disableRipple={true}
                switchChange={(e: any) => setEnableOnOff(!eWrcEnableOnoff)}
                value="checkedA"
                disabled={!canEdit}
              />
            </Typography>}

          {enableWrcSettingsModeLock &&
            <Typography className={classes.rowSectionTitle}>{t`Restrict Mode setting`}
              <Switch
                checked={eWrcEnableMode}
                disableRipple={true}
                switchChange={(e: any) => setEnableMode(!eWrcEnableMode)}
                value="checkedA"
                disabled={!canEdit}
              />
            </Typography>}
          {enableWrcSettingsSetpointChangeLock &&
            <Typography className={classes.rowSectionTitle}>{t`Restrict Setpoint Change`}
              <Switch
                checked={eWrcEnableSetpoint}
                disableRipple={true}
                switchChange={(e: any) => setEnableSetpoint(!eWrcEnableSetpoint)}
                value="checkedA"
                disabled={!canEdit}
              />
            </Typography>}

          {enableWrcSettingsLimitCoolingSetpoint &&
            <div className={classes.controlSectionContainer}>
              <div className={classes.titleContainer}>
                <Typography className={classes.controlSectionTitle}>
                  <ColdBlue className={classes.coldIcon} />
                  {t`Min/max Setpoint cooling`}
                </Typography>
                <Switch
                  checked={eWrcEnableCoolMode}
                  disableRipple={true}
                  switchChange={(e: any) => setEnableCoolMode(!eWrcEnableCoolMode)}
                  value="checkedA"
                  disabled={!canEdit}
                />
              </div>
              <div className={classes.sliderHolder}>
                <Slider
                  ref={(el) => slidersRef.current[0] = el}
                  classes={{
                    root: classes.sliderRoot,
                    valueLabel: classes.sliderValueLabel,
                    rail: classes.sliderRail
                  }}
                  className={classes.coolSlider}
                  onChange={handleCoolRange}
                  aria-labelledby="discrete-slider-always"
                  min={temperatureScale === 1 ? 0 : 32}
                  max={temperatureScale === 1 ? 50 : 122}
                  value={tempLimits[0]}
                  valueLabelDisplay="on"
                  valueLabelFormat={valueLabelFormat}
                  step={1}
                  disabled={!eWrcEnableCoolMode || !canEdit}
                />
              </div>
            </div>}
          {enableWrcSettingsLimitHeatingSetpoint &&
            <div className={classes.controlSectionContainer}>
              <div className={classes.titleContainer}>
                <Typography className={classes.controlSectionTitle}>
                  <HotRed className={classes.coldIcon} style={{ transform: "scale(1.778)" }} />
                  {t`Min/max Setpoint heating`}
                </Typography>
                <Switch
                  checked={eWrcEnableHeatMode}
                  disableRipple={true}
                  switchChange={(e: any) => setEnableHeatMode(!eWrcEnableHeatMode)}
                  value="checkedA"
                  disabled={!canEdit}
                />
              </div>
              <div className={classes.sliderHolder}>
                <Slider
                  ref={(el) => slidersRef.current[1] = el}
                  classes={{
                    root: classes.heatSliderRoot,
                    valueLabel: classes.sliderValueLabel,
                    rail: classes.sliderRail
                  }}
                  className={classes.heatSlider}
                  onChange={handleHeatRange}
                  aria-labelledby="discrete-slider-always"
                  min={temperatureScale === 1 ? 0 : 32}
                  max={temperatureScale === 1 ? 50 : 122}
                  value={tempLimits[1]}
                  valueLabelDisplay="on"
                  valueLabelFormat={valueLabelFormat}
                  step={1}
                  disabled={!eWrcEnableHeatMode || !canEdit}
                />
              </div>
            </div>}
        </div>
      </div>
      {
        errorMsg && (
          <InputLabel
            className={classes.errorLabelStyle}
          >{errorMsg}</InputLabel>
        )
      }
      <Button
        disabled={!canEdit}
        className={classes.applyBtn}
        onClick={save}
      >{t`Save`}</Button>
      {
        !isIOS && (
          <TimePicker
            show={!!openPicker}
            onSet={onSetTime}
            time={openPicker === "start" ? powerOnTime : powerOffTime}
            onDismiss={() => setOpenPicker("")}
            onClear={onClear}
            is12Hours={is12Hours}
          />
        )
      }
    </div >
  );
}
