import React from "react";

const SvgPlusSmall = props => (
  <svg width={19} height={19} {...props}>
    <defs>
      <path
        d="M15.684 4.85c.21-.212.317-.467.316-.767 0-.3-.105-.555-.316-.767A1.046 1.046 0 0014.917 3c-.3 0-.555.105-.768.316L9.5 7.966l-4.65-4.65A1.046 1.046 0 004.084 3c-.3 0-.555.105-.767.316-.21.212-.317.468-.316.767 0 .3.105.555.316.768L7.966 9.5l-4.65 4.65c-.21.212-.317.467-.316.767 0 .3.105.555.316.767.212.21.468.317.767.316.3 0 .555-.105.768-.316l4.649-4.65 4.65 4.65c.212.21.467.317.767.316.3 0 .555-.105.767-.316.21-.212.317-.468.316-.767 0-.3-.105-.555-.316-.768L11.034 9.5l4.65-4.65z"
        id="Plus-small_svg__a"
      />
    </defs>
    <use
      fill="#FDFDFE"
      fillRule="nonzero"
      transform="rotate(-45 9.5 9.5)"
      xlinkHref="#Plus-small_svg__a"
    />
  </svg>
);

export default SvgPlusSmall;
