import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  siteCard: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    borderBottom: "solid 1px #edf0f4",
    padding: "20px 0px",
    flexDirection: "column",
    minHeight: "200px"
  },
  row1Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    marginRight: 20,
    maxWidth: "calc(100% - 190px)",
    "@media only screen and (max-width: 420px)": {
      marginRight: 25
    }
  },
  siteName: {
    marginBottom: "18px",
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: 600,
    color: "#4b1c46",
    display: "inline-block",
    overflow: "hidden",
    // minWidth: 190,
    // width: "100%",
    // maxWidth: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  dataRow: {
    borderBottom: "1px solid #edf0f4",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 30,
    marginBottom: 13,
    minWidth: "130px",
    "@media only screen and (max-width: 400px)": {
      flexFlow: "row nowrap"
    }
  },
  dataTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  dataCount: {
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    marginLeft: "5px"
  },
  row2Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "101px",
    justifyContent: "flex-end",
    marginRight: 10,
    alignItems: "center"
  },
  indoorUnitsText: {
    fontFamily: "Roboto",
    fontSize: "13px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginTop: 15,
    whiteSpace: "nowrap"
  },
  row3Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "80px",
    justifyContent: "center"
  },
  modesCount: {
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    display: "flex",
    alignItems: "center",
    height: "32px"
  },
  modesIcons: { width: 18, height: 21, marginRight: 7 },
  removeMarginAndBorder: {
    marginBottom: 0,
    border: 0,
    height: "auto"
  },
  indoorUnitsCountStyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    top: "66px",
    height: "14px",
    fontFamily: "Roboto",
    fontSize: "12px",
    letterSpacing: "normal",
    color: "#29132e"
  },
  onIndoorCountStyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    height: "40px",
    top: "22px",
    fontFamily: "Roboto",
    fontSize: "34px",
    letterSpacing: "normal",
    color: "#545964"
  },
  relativeContainer: {
    position: "relative",
    minWidth: "100%"
  },
  rowContainer: {
    display: "flex"
  }
}));

export default useStyles;
