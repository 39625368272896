import { Card, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";
import { IUnit } from "../../models/Units";
import useStyles from "./UnitInfo.style";

interface IProps {
  sensorName: string;
  siteName: string;
  isDeviceConnected: boolean;
  type: string;
}

export default function UnitInfo(props: IProps) {
  const classes = useStyles();
  const { sensorName, siteName, isDeviceConnected, type } = props;

  return (
    <Card className={classes.card}>
      <div className={classes.unitInfoHeader}>
      <Typography className={classes.unitInfoTitle}>{type === "sensor" ? t`Sensor Information` : t`Power Meter Information`}</Typography>
        {!isDeviceConnected && <Typography className={classes.unitInfoDisconnectedText}>{type === "sensor" ? t`- Sensor device is disconnected` : t`- Power Meter device is disconnected`}</Typography>}
      </div>
      <div className={classes.itemsWrapper}>
        <div className={clsx(classes.item, classes.firstItem)} style={{ maxWidth: "50%" }}>
          <Typography className={classes.infoTitle}>{type === "sensor" ? t`Sensor Name` : t`Power Meter Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{sensorName}</Typography>
        </div>
        <div className={classes.item} style={{ maxWidth: "50%" }}>
          <Typography className={classes.infoTitle}>{t`Site Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{siteName}</Typography>
        </div>
      </div>
    </Card>
  );
}
