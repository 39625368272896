import { ClickAwayListener, Menu, MenuItem, Typography } from "@material-ui/core";
import { AccountCircle, KeyboardArrowDown } from "@material-ui/icons";
import React from "react";
import { t } from "ttag";
import { User1 as SvgUser } from "../../icons/";
import { useStoreState } from "../../models/RootStore";
import LogOut from "../LogOut/LogOut";
import About from "./About";
import ProfileEdit from "./ProfileEdit";
import useStyles from "./UserMenu.style";

export default function UserMenu(props: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userName, onSearch } = props;
  const configFile = useStoreState((s) => s.config);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div className={onSearch ? classes.userMenuContainer : ""}>
      <ClickAwayListener onClickAway={handleClose}>
        <div onClick={handleClick} className={classes.title}>
          <AccountCircle className={classes.userIcon} />
          <Typography className={classes.greeting}>
            {t`Hello, ` + userName}
          </Typography>
          <KeyboardArrowDown className={classes.icon} />
        </div>
      </ClickAwayListener>
      <Menu
        MenuListProps={{ disablePadding: true }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        {/* this menu item has the same content as the menu button/header, it is used to give the menu the same width as the header */}
        <MenuItem className={classes.hiddenItem}>
          <SvgUser className={classes.userIcon} />
          {t`Hello, ` + userName}
          <div className={classes.icon}>
            <KeyboardArrowDown className={classes.icon} />
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <ProfileEdit />
        </MenuItem>
        {configFile?.showAbout &&
          <MenuItem onClick={handleClose} className={classes.menuItem}>
            <About />
          </MenuItem>}
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <LogOut />
        </MenuItem>
      </Menu>
    </div>
  );
}
