import { Device as DeviceSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  memo,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";
import { ISystem } from "./Systems";
import { IUnit, IUnitType } from "./Units";

export interface IDevice {
  id: string;
  serial: string;
  pin?: string;
  name: string;
  isConnected: boolean;
  site: string;
  units: string[];
  systems: string[];
  role: any;
  hvacLines?: string[];
  protocolVersion?: any;
  serviceSystems?: any;
  deviceType?: any;
  registrationDate?: any;
  type?: any;
}

export interface IDeviceMap {
  [key: string]: IDevice;
}

interface IGetDaikinDevice {
  deviceId: string;
  lineId: string;
}

interface IDeviceTokenPayload {
  serial: string;
  pin: string;
}

export interface IDevicesModel {
  allDevices: IDeviceMap;
  allLines: any;
  deviceRegistrationProgress: any;
  setDeviceRegistrationProgress: Action<any, any>;
  initialize: Action<IDevicesModel, any>;
  initializeLines: Action<IDevicesModel, any>;
  onInitialized: ActionOn<IDevicesModel, IRootStoreModel>;
  onInitializedLines: ActionOn<IDevicesModel, IRootStoreModel>;
  getDevice: Computed<IDevicesModel, (id?: string) => IDevice | undefined>;
  getDeviceName: Computed<IDevicesModel, (id: string | undefined) => string | undefined>;
  getDeviceNameBySerial: Computed<
    IDevicesModel,
    (serial: string | undefined) => string | undefined
  >;

  mapLineIndoors: Thunk<
    IDevicesModel,
    { deviceId: string, lineId: string, data: any }
  >;
  mapLines: Thunk<
    IDevicesModel,
    { deviceId: string, serviceLine: string, controlLine: string }
  >;
  getLineQuality: Thunk<
    IDevicesModel,
    { deviceId: string, lineId: string, startTime: number, endTime: number }
  >;
  getDeviceLines: Thunk<IDevicesModel, string>;
  getDeviceUnitsAPI: Thunk<IDevicesModel, { id: string, line?: number }>;
  getDeviceSystemsAPI: Thunk<IDevicesModel, { id: string, line?: number }>;
  getPPDsAPI: Thunk<IDevicesModel, { id: string, type?: number }>;
  getDeviceSystems: Computed<IDevicesModel, (deviceId: string) => ISystem[], IRootStoreModel>;
  getDeviceUnits: Computed<
    IDevicesModel,
    (
      deviceId: string,
      systemId: "all" | "unassigned" | "disconnected" | "assigned" | string,
      type: IUnitType
    ) => IUnit[],
    IRootStoreModel
  >;
  getVisibleDeviceUnits: Computed<
    IDevicesModel,
    (
      deviceId: string,
      systemId: "all" | "unassigned" | "disconnected" | "assigned" | string,
      type: IUnitType
    ) => IUnit[],
    IRootStoreModel
  >;
  getDeviceBySerial: Computed<IDevicesModel, (serial: string) => IDevice | null>;
  getDevicesBySite: Computed<IDevicesModel, (siteId: string) => IDevice[]>;
  getDeviceById: Computed<IDevicesModel, (id: string) => IDevice | null>;
  updateDevice: Thunk<IDevicesModel, { deviceId: string, data: any }>;
  _storeUpdateDeviceConnection: Action<IDevicesModel, { deviceId: string; data: any }>;
  updateSubType: Thunk<IDevicesModel, { deviceId: string, line: number, brand: number, subBrand: number }>;
  getDeviceSite: Computed<
    IDevicesModel,
    (deviceId: string) => any[],
    IRootStoreModel
  >;
  getDeviceToken: Thunk<IDevicesModel, IDeviceTokenPayload, any>;
  getAccountByDeviceSerial: Thunk<IDevicesModel, { serial: string, pin: string }, any>;
  getDevices: Thunk<IDevicesModel>;
  replaceDevice: Thunk<IDevicesModel, { deviceId: string, data: any }>;
  resetAlthermaSettings: Thunk<IDevicesModel, { deviceId: string, lineId: string | number }>;
}

export const devicesModel: IDevicesModel = {
  allDevices: {},
  allLines: {},
  deviceRegistrationProgress: {},

  setDeviceRegistrationProgress: action((state, payload) => {
    state.deviceRegistrationProgress = payload;
  }),

  initialize: action((state, payload) => {
    state.allDevices = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),

  initializeLines: action((state, payload) => {
    state.allLines = payload;
  }),

  onInitializedLines: actionOn(
    (actions, storeActions) => [actions.initializeLines],
    (state, target) => { }
  ),

  getDevice: computed([(state) => state.allDevices], (allDevices) =>
    memo((id) => {
      if (_.isNil(id)) return undefined;
      return allDevices[id];
    }, 100)
  ),

  _storeUpdateDeviceConnection: action((state, payload) => {
    if (state.allDevices[payload.deviceId]) {
      state.allDevices[payload.deviceId] = payload.data;
    }
  }),

  getLineQuality: thunk(async (actions, payload) => {
    const params = { startTime: payload.startTime, endTime: payload.endTime };
    const data = await DeviceSdk.getLineQuality(payload.deviceId, payload.lineId, params);
    return data;
  }),
  getDeviceUnitsAPI: thunk((actions, payload) => {
    const { id, line = null } = payload;
    return DeviceSdk.getUnits(id, line);
  }),
  getDeviceSystemsAPI: thunk((actions, payload) => {
    const { id, line = null } = payload;
    return DeviceSdk.getSystems(id, line);
  }),
  getPPDsAPI: thunk((actions, payload) => {
    return DeviceSdk.getPowerMeters(payload.id, payload.type);
  }),
  getDeviceLines: thunk(async (actions, payload) => {
    const data = await DeviceSdk.getDeviceLines(payload);
    return data;
  }),
  mapLineIndoors: thunk(async (actions, payload) => {
    await DeviceSdk.mapLineIndoors(payload.deviceId, payload.lineId, payload.data);
  }),
  mapLines: thunk(async (actions, payload) => {
    const data = { serviceLine: payload.serviceLine, controlLine: payload.controlLine };
    await DeviceSdk.mapLines(payload.deviceId, data);
  }),
  getDeviceNameBySerial: computed((state) => (serial) => {
    const noName = "-";

    if (!serial) {
      return noName;
    }
    const device = Object.values(state.allDevices).filter((device) => device.serial === serial)[0];
    if (!device) {
      return noName;
    }

    return device.name ?? noName;
  }),
  getDeviceName: computed((state) => (id) => {
    const noName = "-";

    if (!id) {
      return noName;
    }

    if (!state.allDevices[id]) {
      return noName;
    }

    return state.allDevices[id].name ? state.allDevices[id].name : noName;
  }),

  getDeviceSystems: computed([(state, storeState) => storeState.systems.allSystems], (allSystems) =>
    memo((deviceId) => {
      return Object.values(allSystems).filter((system) => system.device === deviceId);
    }, 100)
  ),

  // Returns an array of device's units
  // deviceId is required, optional filters can be applied:
  // 'systemId' and/or 'indoor/outdoor/service'
  getDeviceUnits: computed([(state, storeState) => storeState.units.allUnits], (allUnits) =>
    memo((deviceId, systemId = "all", type = "all") => {
      return Object.values(allUnits).filter(
        (unit) =>
          unit.device === deviceId &&
          (systemId === "all" ||
            (systemId === "assigned" && !_.isNil(unit.system)) ||
            (systemId === "unassigned" && _.isNil(unit.system)) ||
            (systemId === "disconnected" && (!_.has(unit, "isConnected") || !unit.isConnected)) ||
            unit.system == systemId) &&
          ((type === "all" && ((unit.type === 1) || (unit.type === 2) || (unit.type === 3) || (unit.type === 4) || (unit.type === 5))) ||
            (type === "indoor" && unit.type === 1) ||
            (type === "outdoor" && unit.type === 2) ||
            (type === "service" && unit.type === 3) ||
            (type === "bsBox" && unit.type === 4) ||
            (type === "other" && unit.type === 5))
      );
    }, 100)
  ),
  getVisibleDeviceUnits: computed([(state, storeState) => storeState.units.allUnits], (allUnits) =>
    memo((deviceId, systemId = "all", type = "all") => {
      return Object.values(allUnits).filter(
        (unit) => unit.isVisible &&
          unit.device === deviceId &&
          (systemId === "all" ||
            (systemId === "assigned" && !_.isNil(unit.system)) ||
            (systemId === "unassigned" && _.isNil(unit.system)) ||
            (systemId === "disconnected" && (!_.has(unit, "isConnected") || !unit.isConnected)) ||
            unit.system == systemId) && unit.isVisible &&
          ((type === "all" && ((unit.type === 1) || (unit.type === 2) || (unit.type === 3) || (unit.type === 4) || (unit.type === 5))) ||
            (type === "indoor" && unit.type === 1) ||
            (type === "outdoor" && unit.type === 2) ||
            (type === "service" && unit.type === 3) ||
            (type === "bsBox" && unit.type === 4) ||
            (type === "other" && unit.type === 5))
      );
    }, 100)
  ),
  getDeviceBySerial: computed((state) => (serial) => {
    const foundDevices = _.filter(
      _.values(state.allDevices),
      (device: IDevice) => device.serial === serial
    );

    if (foundDevices.length > 0) {
      if (foundDevices.length > 1) {
        return null;
      }

      return foundDevices[0];
    }

    return null;
  }),
  getDevicesBySite: computed((state) => (siteId) => {
    const foundDevices = _.filter(
      _.values(state.allDevices),
      (device: IDevice) => device.site === siteId
    );

    return foundDevices;
  }),
  getDeviceById: computed((state) => (id) => {
    const device = state.allDevices[id];

    return device ? device : null;
  }),

  updateDevice: thunk((actions, payload) => {
    return DeviceSdk.update(payload.deviceId, payload.data);
  }),
  updateSubType: thunk((actions, payload) => {
    return DeviceSdk.updateSubType(payload.deviceId, payload.line, payload.brand, payload.subBrand);
  }),
  getDeviceSite: computed(
    [(state, storeState) => storeState.sites.allSites],
    (allSites) =>
      memo((deviceId) => {
        return Object.values(allSites).filter(
          (site) => {
            const foundSite = _.find(site.devices, (oneDeviceId) => {
              return oneDeviceId === deviceId;
            });
            return foundSite;

          }
        );
      }, 100)
  ),
  getDeviceToken: thunk((actions, payload, { injections }) => {
    const { serial, pin } = payload;
    const { sdkDevice } = injections;

    return sdkDevice.createDeviceToken({ serial, pin });
  }),
  getAccountByDeviceSerial: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;

    return sdkDevice.getAccountByDeviceSerial(payload);
  }),
  getDevices: thunk(async (actions, payload, { injections }) => {
    const { sdkDevice } = injections;
    const devices = await sdkDevice.getDevices();
    actions.initialize(devices);
    return devices;
  }),

  replaceDevice: thunk((actions, payload) => {
    return DeviceSdk.replaceDevice(payload.deviceId, payload.data);
  }),
  resetAlthermaSettings: thunk((actions, payload) => {
    return DeviceSdk.resetAlthermaSettings(payload.deviceId, payload.lineId);
  })
};
