import React from "react";

const SvgOverflowMenu = props => (
  <svg width={4} height={20} {...props}>
    <g
      fill="#FDFDFE"
      fillRule="evenodd"
      opacity={0.6}
      transform="rotate(-180 2 10)"
    >
      <circle cx={2} cy={2} r={2} />
      <circle cx={2} cy={10} r={2} />
      <circle cx={2} cy={18} r={2} />
    </g>
  </svg>
);

export default SvgOverflowMenu;
