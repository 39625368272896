import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import clsx from "clsx";
import _, { remove } from "lodash";
import React, { useEffect, useState } from "react";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { ArrowDownO, CheckboxChecked, Close } from "../../icons";
import styles from "./ItemsTree.style";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#545964",
      fontSize: "14px",
      width: "100%",
      minHeight: "40px",
      "&:hover > $content": {
        backgroundColor: "transparent"
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #fff)`,
        color: "var(--tree-view-color)"
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent"
      }
    },
    content: {
      color: "#545964",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "10px",
      fontWeight: 400,
      "$expanded > &": {
        fontWeight: 400
      }
    },
    disabledContent: {
      cursor: "not-allowed",
      "&:hover > $label": {
        backgroundColor: "transparent"
      }
    },
    group: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "31px"
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
      padding: 0,
      display: "flex"
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: "9px",
      paddingLeft: 0
    },
    labelIcon: {
      marginRight: "10px"
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      fontSize: 15
    },
    iconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "5px"
    }
  })
);

const Checkbox1 = (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

function StyledTreeItem(props: any) {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, disabled, ...other } = props;
  return (
    <TreeItem
      disabled={disabled}
      label={
        <div className={clsx(classes.labelRoot, { [className]: className })}>
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: disabled ? classes.disabledContent : classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: "calc(100% - 120px)",
      flexGrow: 1,
      maxHeight: 500,
      marginBottom: "30px",
      overflow: "auto",
      background: "#fff",
      padding: "20px"
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      alignItems: "center",
      paddingTop: 10
    },
    indeterminate: {
      color: "#fff"
    },
    indetCheckbox: {
      "& > span": {
        background: "red !important",
        border: "1px solid gray"
      },
      "& svg": { width: "23px", height: "23px" }
    },
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": { width: "18px", height: "18px", borderRadius: "3px", backgroundColor: "#fff" }
    },
    labelStyle: {
      marginLeft: "-19px",
      "& p": { fontWeight: "bold" }
    },
    treeHeader: {
      borderBottom: "solid 1px #d5d2d5",
      width: "1005",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      height: "60px",
      backgroundColor: "#f6f6f7",
      alignItems: "center"
    },
    label: {
      color: "#545964",
      padding: 0
    },
    closeIcon: { padding: "6px", marginRight: "-6px" },
    arrowIcon: { marginLeft: "7px", marginRight: "2px" },
    rotateArrow: { transform: "rotate(-90deg)" },
    arrowContainer: { minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" },
    errorMessage: {
      color: "#f44336",
      fontSize: "14px",
      marginRight: "10px",
      paddingBottom: "7px"
    },
    bottomContainer: {
      backgroundColor: "#fff",
      display: "flex",
      flexFlow: "column nowrap",
      position: "absolute",
      justifyContent: "center",
      width: "100%",
      bottom: "20px",
      padding: "0px 20px",
      paddingTop: 10,
      alignItems: "flex-start"
    },
    startMessage: {
      fontSize: 14,
      marginBottom: 20
    }
  })
);

export default ({ disableAll = false, sitesWithSystems, site, allSystems, selectedSystems, updateSelectedSystems, disabledSystems, setDisabledSystems, dirty, setDirty }: any) => {
  const classes = useStyles();
  const [selectedSite, setSelectedSite] = useState<any>(allSystems[selectedSystems[0]]?.site || null);

  const expanded = [`site-${site?.id}`];

  const handleSelectedSystmeschange = (type: string, systemsIds: any, siteId: string, checked: boolean, allSiteSystemsIds?: any) => {
    if (type === "site") {
      if (checked) {
        if (!!selectedSite && siteId !== selectedSite) {
          const updatedDisabledSystems = disabledSystems.filter((systemId: string) => selectedSystems.indexOf(systemId) === -1);
          setDisabledSystems(updatedDisabledSystems);
        }
        updateSelectedSystems(systemsIds);
        !dirty && setDirty(true);
        setSelectedSite(siteId);
      } else {
        const updatedDisabledSystems = disabledSystems.filter((systemId: string) => allSiteSystemsIds.indexOf(systemId) === -1);
        setDisabledSystems(updatedDisabledSystems);
        updateSelectedSystems([]);
        !dirty && setDirty(true);
        setSelectedSite(null);
      }
    } else {
      if (!!checked) {
        if (!!selectedSite && siteId === selectedSite) {
          if (selectedSystems.indexOf(systemsIds[0]) === -1) {
            updateSelectedSystems([...selectedSystems, ...systemsIds]);
            !dirty && setDirty(true);
          }
        } else {
          updateSelectedSystems(systemsIds);
          !dirty && setDirty(true);
          setSelectedSite(siteId);
        }
      } else {
        const updatedDisabledSystems = disabledSystems.filter((systemId: string) => systemsIds.indexOf(systemId) === -1);
        setDisabledSystems(updatedDisabledSystems);
        const tempSystems = selectedSystems.filter((system: any) => system !== systemsIds[0]);
        updateSelectedSystems(tempSystems);
        !dirty && setDirty(true);
      }
    }

  };

  useEffect(() => {
    if (!!selectedSystems) {
      let mappedPreviously: any = {};
      Object.values(selectedSystems).forEach((key: any) => {
        const system = allSystems[key];
        if (system && system.site) {
          expanded.push(`site-${system.site}`);
        }
        return mappedPreviously[key] = true;
      });
    }
  }, []);

  const systemsNodes = (systems: any, siteId: string) => {

    const items: any = [];
    for (const i in systems) {
      const systemId = systems[i];
      const system = allSystems[systemId];
      const { id, name } = system;
      const checked = selectedSystems.indexOf(systemId) !== -1;
      const disabled = disabledSystems.indexOf(id) !== -1;

      items.push(
        <StyledTreeItem
          key={`system-${systemId}`}
          nodeId={`system-${systemId}`}
          labelText={name}
          disableSelection={disabled && !checked}
          disabled={disabled && !checked}
          node={
            <Checkbox1
              disabled={disabled && !checked || disableAll}
              checked={checked}
              onChange={(event: any) => handleSelectedSystmeschange("system", [systemId], siteId, event.target.checked)}
            />}
          color="#545964"
          bgColor="#fff"
        >
        </StyledTreeItem>
      );
    }
    return items;
  };

  const sitesNodes = () => {
    const items = [];
    const { id, name, hvacAdvancedOperations, predictiveMaintenance } = site;
    if (!hvacAdvancedOperations && !predictiveMaintenance) {
      return;
    }
    const siteSystems = sitesWithSystems[id] || {};
    const siteSystemsArray = Object.keys(siteSystems);
    const enabledSiteSystemsArray = siteSystemsArray.filter((systemId: string) => selectedSystems.indexOf(systemId) !== -1 || disabledSystems.indexOf(systemId) === -1);
    const isAllSystemsChecked = siteSystemsArray.every((systemId: string) => selectedSystems.indexOf(systemId) !== -1);
    const isAllSystemsDisabled = siteSystemsArray.every((systemId: string) => disabledSystems.indexOf(systemId) !== -1);
    const checked = isAllSystemsChecked;
    const indeterminate = !checked && siteSystemsArray.some((systemId: string) => selectedSystems.indexOf(systemId) !== -1);

    id && items.push(
      <StyledTreeItem
        key={`site-${id}`}
        nodeId={`site-${id}`}
        labelText={name}
        node={siteSystemsArray.length > 0 ? <Checkbox1
          indeterminate={!checked && indeterminate}
          checked={checked}
          disabled={(isAllSystemsDisabled && !checked) || disableAll}
          onChange={(event: any) => handleSelectedSystmeschange("site", enabledSiteSystemsArray, site.id, event.target.checked, siteSystemsArray)}
        /> : undefined}
        disabled={isAllSystemsDisabled && !checked}
        color="#545964"
        bgColor="#fff"
      >
        {systemsNodes(siteSystemsArray, site.id)}
      </StyledTreeItem>);
    // }
    // }
    return items;
  };

  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={expanded}
        defaultCollapseIcon={<><div className={classes.arrowContainer} /> <ArrowDownO className={classes.arrowIcon} /> </>}
        defaultExpandIcon={<><div className={classes.arrowContainer} /> <ArrowDownO className={clsx(classes.arrowIcon, classes.rotateArrow)} /></>}
        defaultEndIcon={<div className={classes.arrowContainer} />}
      >
        {sitesNodes()}
      </TreeView>
    </>
  );

};
