import { makeStyles, Theme, Tooltip } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React from "react";
interface ITooltipProps {
    title: string;
    placement?: any;
    arrow?: boolean;
    classes?: any;
    children?: any;
    interactive?: any;
    open?: boolean;
}

const LightTooltipStyle = makeStyles((theme: any) => createStyles({
    tooltip: {
        ...theme.tooltip.box,
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 2px rgb(0 0 0 / 12%)",
        paddingLeft: 12,
        paddingRight: 12,
        whiteSpace: "pre-line"
    },
    arrow: {
        ...theme.tooltip.arrow,
        marginRight: "10px !important"
    },
    popper: {
    }
}));

export default function LightTooltip(props: React.PropsWithChildren<any>) {
    const {
        tooltip,
        arrow,
        popper
    } = LightTooltipStyle();

    return (
        <Tooltip {...props} classes={{ tooltip, arrow, popper, ...props.classes }} title={props.title}>
            {props.children}
        </Tooltip>
    );
}
