import coolremoteSDK from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { IAlert } from "../Alerts";
import { IRootStoreModel } from "../RootStore";

export interface IAlertActions {
  fetchNewAlert: Thunk<IRootStoreModel, { id: string }>;
  _storeFetchNewAlert: Action<IRootStoreModel, { alertId: string; alert: IAlert }>;
}

export const alertActions: IAlertActions = {
  fetchNewAlert: thunk(async (actions, payload) => {
    const alert = await coolremoteSDK.Event.fetch(payload.id);

    actions._storeFetchNewAlert({ alertId: payload.id, alert });
  }),

  _storeFetchNewAlert: action((state, payload) => {
    state.alerts.allOpenAlerts[payload.alertId] = payload.alert;
  })
};
