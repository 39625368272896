import { IconButton, Input, InputAdornment, Typography } from "@material-ui/core/";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { ArrowBack, Check, Close } from "../../icons";
import { SearchIcon } from "../../svgComponents";
import useStyles from "./ParamsList.style";

const ParamsList = (props: any) => {
  const classes = useStyles();
  const { tableParams, setTableParams, setOpenParamsList } = props;
  const [filteredTableParams, setFilteredTableParams] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [tempTableParams, setTempTableParams] = useState<any>({});

  const handleSearchValueChange = (event: any) => {
    const { target: { value } } = event;
    setSearchValue(value);
  };

  const cancelSearch = () => {
    setSearchValue("");
  };

  useEffect(() => {
    if (!tableParams) {
      return;
    }
    setTempTableParams(tableParams);

  }, [tableParams]);

  useEffect(() => {
    const filteredData = Object.values(tempTableParams).filter((param: any) => param?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >= 0);
    setFilteredTableParams(filteredData);
  }, [searchValue, tempTableParams]);

  const isAllSelected = Object.values(filteredTableParams).filter((param: any) => param?.isShowing === true).length === Object.values(filteredTableParams).length;

  const handleSelectAll = (isChecked: boolean) => {
    const test = filteredTableParams.reduce((obj: any, param: any) => {
      obj[param.code] = { ...param, isShowing: isChecked };
      return obj;
    }, {});
    setTempTableParams({ ...tempTableParams, ...test });
  };

  const handleApplyChanges = () => {
    setTableParams(tempTableParams);
    setOpenParamsList(false);
  };

  return (
    <div className={classes.root}>
      <div id="title" className={clsx(classes.titleContainer, { [classes.iosTopHeight]: isIOS })}>
        <ArrowBack className={classes.arrowIcon} onClick={() => setOpenParamsList(false)} />
        <Typography className={classes.pageTitle}>{t`Select Parameters`}</Typography>
        <div></div>
      </div>
      <div className={classes.searchBox}>
        <Input
          placeholder={t`Search Parameter`}
          value={searchValue}
          onChange={handleSearchValueChange}
          disableUnderline={true}
          classes={{ root: classes.inputRoot }}
          endAdornment={
            searchValue ? (
              <IconButton disableRipple onClick={cancelSearch} className={classes.closeIconStyle}>
                <Close color="#7f7692" />
              </IconButton>) : (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            )
          }
        />
      </div>
      <div className={classes.selectAllContainer}>
        <Typography
          className={classes.selectAllText}
          onClick={() => handleSelectAll(!isAllSelected)}
        >{isAllSelected ? t`Unselect all` : t`Select all`}</Typography>
      </div>
      <div className={classes.paramsList}>
        {_.orderBy(filteredTableParams, ["plottable", "isCalculated", "name"], ["desc", "desc", "asc"])?.map((row: any, index: number) => {
          const { name, isShowing, code } = row;
          return (
            <div key={index} className={classes.param}
              onClick={() => setTempTableParams({ ...tempTableParams, [code]: { ...tempTableParams[code], isShowing: !isShowing } })}>
              <div className={classes.paramName}>
                {name}
              </div>
              {isShowing && <Check />}
            </div>
          );
        })}
        <Button
          className={classes.applyBtn}
          onClick={() => handleApplyChanges()}
        >{t`Show Parameters`}</Button>

      </div>
    </div>
  );
};

export default ParamsList;
