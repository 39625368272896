import { Services as ServicesSdk, Trap as TrapSdk } from "coolremote-sdk";
import { Action, action, thunk, Thunk } from "easy-peasy";

export interface ITrapsModel {
  getTraps: Thunk<ITrapsModel, { siteId: string; type: any }>;
  getTrapParams: Thunk<ITrapsModel, string>;
  addTrap: Thunk<ITrapsModel, { data: any }>;
  updateTrap: Thunk<ITrapsModel, { data: any; trapId: string }>;
  deleteTrap: Thunk<ITrapsModel, { id: string }>;
  getServiceParams: Thunk<ITrapsModel, { site: string, brand?: number; unitType?: number }>;
  selectedtrapId: string;
  setSelectedtrapId: Action<ITrapsModel, string>;
  getTrapById: Thunk<ITrapsModel, string>;
  getAllTraps: Thunk<ITrapsModel, any[]>;
  getCustomerTraps: Thunk<ITrapsModel, { customerId: string; type: any }>;

}

export const trapsModel: ITrapsModel = {
  getTraps: thunk((actions, payload) => {
    return TrapSdk.getSiteTraps(payload.siteId, payload.type);
  }),
  getCustomerTraps: thunk((actions, payload) => {
    return TrapSdk.getCustomerTraps(payload.customerId, payload.type);
  }),
  getTrapParams: thunk((actions, payload) => {
    return TrapSdk.getTrapParams(payload);
  }),
  addTrap: thunk((actions, payload) => {
    return TrapSdk.createTrap(payload.data);
  }),
  updateTrap: thunk((actions, payload) => {
    return TrapSdk.update(payload.data, payload.trapId);
  }),
  deleteTrap: thunk((actions, payload) => {
    return TrapSdk.delete(payload.id);
  }),
  getServiceParams: thunk((actions, payload) => {
    return ServicesSdk.getSiteServiceParams(payload);
  }),
  selectedtrapId: "",
  setSelectedtrapId: action((state, payload) => {
    state.selectedtrapId = payload;
  }),
  getTrapById: thunk((actions, payload) => {
    return TrapSdk.fetch(payload);
  }),
  getAllTraps: thunk((action, payload) => {
    return TrapSdk.getAllTraps(payload);
  })
};
