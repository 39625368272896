import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    buttonLogin: {
      minHeight: "53px",
      width: "100%",
      marginBottom: "10px"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "90%",
      margin: "1rem"
    },
    resetButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0",
      width: "100%"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      "& >div": {
        width: "100%"
      },
      "& >div>div": {
        width: "100%"
      }
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%"
    },

    Login: {
      background: "white",
      MozFlexDirection: "column",
      flexDirection: "column",
      height: "100%",
      display: "flex",
      fontSize: "1.5rem",
      color: "#29abe2"
    },

    logos: {
      justifyContent: "center",
      display: "flex",
      MozFlexDirection: "column",
      flexDirection: "column",
      alignItems: "center"
    },

    ProfessionalTitle: {
      fontFamily: "RobotoLight",
      fontSize: "26px",
      fontWeight: 300,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.59px",
      color: "#4d4d4d",
      textTransform: "uppercase"
    },
    title: {
      color: theme.palette.primary.dark,
      paddingBottom: "20px",
      paddingTop: "40px",
      lineHeight: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "26px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal"
    },

    bottom: {
      height: "48px",
      opacity: 0.6,
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff"
    },

    mailto: {
      color: "rgba(255, 255, 255, 0.9)"
    },

    button: {
      height: "53px",
      width: "100%",
      borderRadius: "4px",
      fontFamily: "roboto",
      fontWeight: "bold",
      fontSize: "15px",
      margin: "0",
      marginBottom: "10px",
      backgroundColor: "rgb(240, 83, 71)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        backgroundColor: "rgb(240, 83, 71)"
      }
    },

    splashScreen: {
      width: "100%",
      height: "100%",
      position: "fixed",
      background: "rgba(41, 19, 46, 0.95)",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      zIndex: 100
    },

    logo: {
      paddingTop: "2rem",
      width: "70%",
      maxWidth: "600px"
    },

    logoTitle: {
      paddingBottom: "2rem",
      width: "70%",
      maxWidth: "600px"
    },

    forgotPassLink: {
      boxSizing: "border-box",
      color: "rgb(31, 44, 50)",
      cursor: "pointer",
      display: "inline",
      fontSize: "15px",
      fontWeight: 300,
      listStyleImage: "none, outside, none",
      outline: "rgb(31, 44, 50), none, 0px",
      padding: "5px, 12.5px, 12.5px, 12.5px",
      textAlign: "left",
      textDecoration: "none",

      textSizeAdjust: "100%",
      userSelect: "none",
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      "&:hover": {
        textShadow: "0px 0px 0.5px rgb(31, 44, 50)"
      }
    },

    CoolAutomationName: {
      fontSize: "50px",
      color: "white",
      marginTop: "8rem"
    },

    splashScreenImg: {
      marginTop: "10rem"
    },

    interactionSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%"
    },

    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      MozFlex: "1",
      flex: "1",
      overflowY: "auto",
      paddingTop: "2rem",
      paddingBottom: "2rem"
    },

    forgotPassBtn: {
      backgroundColor: "inherit"
    },

    hidden: {
      display: "none"
    },

    newPasswordForm: {
      display: "inline-flex",
      flexDirection: "column",
      width: "100%"
    },
    buttonReset: {
      minHeight: "53px",
      width: "100%",
      marginBottom: "10px"
    },
    buttonReset1: {
      minHeight: "53px",
      marginBottom: "10px",
      flex: 0.45,
      minWidth: "20px"
    },
    buttonBack: {
      minHeight: "53px",
      marginBottom: "10px",
      borderRadius: "4px",
      border: "1px solid rgba(255, 255, 255, 0.6)",
      fontFamily: "roboto",
      margin: "0",
      color: "rgb(255, 255, 255)",
      "& span": { fontSize: "14px" },
      flex: 0.45
    },

    resetSuccessContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& p": {
        width: "100%",
        textAlign: "center",
        marginBottom: "40px"
      },
      "& button": {
        backgroundColor: "#f05347",
        color: "#fff",
        fontWeight: 500,
        fontSize: "15px",
        width: "40%",
        "&:hover": {
          backgroundColor: "#f05347"
        }
      }
    },
    reportProblemButton: {
      position: "absolute",
      zIndex: 99999,
      left: "15px",
      bottom: "40px",
      borderRadius: "2rem",
      height: "3.5rem",
      width: "3.5rem",
      minWidth: "3rem",
      "& span": {
        "& svg": {
          width: "3rem",
          height: "3rem"
        }
      }
    },
    inputField: {
      "& label": {
        color: `${theme.palette.primary.light} !important`
      },
      "& .Mui-focused": {
        color: `${theme.palette.primary.light} !important`
      },
      "& div": {
        backgroundColor: "white",
        border: theme.overrides.MuiButton.outlined.border,
        borderRadius: "4px 4px 0 0"
      },
      "& .MuiFilledInput-input": {
        color: theme.palette.primary.dark,
        fontSize: "20px",
        backgroundColor: "white"
      },
      "& .MuiFormLabel-filled": {
        color: theme.palette.primary.dark
      },
      "& :-webkit-autofill": {
        "-webkit-text-fill-color": `${theme.palette.primary.dark} !important`,
        "-webkit-box-shadow": "0 0 0 30px white inset !important"
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "none",
        content: "none"
      },
      "& .MuiFilledInput-underline:after": {
        "border-bottom": "none",
        content: "none"
      },
      "& .MuiFilledInput-input:-webkit-autofill": {
        color: `${theme.palette.primary.dark} !important`,
        backgroundColor: "white"
      },
      "&:-internal-autofill-selected": {
        color: `${theme.palette.primary.dark} !important`
      }
    },
    text: {
      color: theme.palette.primary.dark,
      marginBottom: "1rem",
      textAlign: "center"
    }
  })
);
export default useStyles;
