import { alpha } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { placeBackgroundColor } from "../../services/utils";

export default makeStyles((theme: any) =>
({
  screenContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    flexFlow: "column nowrap",
    maxHeight: "100%",
    height: "100%"
  },
  gridStyle: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%"
  },
  iconStyle: { fontSize: "22px", color: "white" },
  fieldContainerStyle: { width: "80%", overflow: "auto" },
  typographyMargin: { marginBottom: "-10px" },
  testclass: {
    width: "50px",
    minWidth: "60px"
  },
  schedulesContainer: {
    display: "flex",
    flexFlow: "row wrap",
    width: "calc(100% - 9px)",
    flex: 1,
    padding: "0 20px",
    boxSizing: "border-box",
    overflowY: "auto",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(255,255,255,0.26)"
    }
  },
  scheduleHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: alpha("#fdfdfe", 0.2),
    width: "100%",
    height: "50px",
    borderRadius: "6px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: "10px",
    boxSizing: "border-box"
  },
  scheduleTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 5,
    overflow: "hidden",
    "& *": {
      fontSize: "18px",
      fontWeight: "500",
      color: "white",
      marginRight: "7px"
    }
  },
  scheduleControl: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "20%"
  },
  scheduleBody: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: alpha("#fdfdfe", 0.1),
    width: "100%",
    borderRadius: "6px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: "10px",
    paddingBottom: "20px",
    paddingTop: "20px",
    boxSizing: "border-box",
    overflowY: "hidden"
  },
  scheduleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "12px 0",
    maxWidth: "47%",
    marginRight: "20px",
    ...placeBackgroundColor(theme.palette.background.scheduleItem),
    borderRadius: '6px'

  },
  bodyRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "hidden"
  },
  timeRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "hidden"
  },
  timeSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    margin: "10px 0"
  },
  setPointSection: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "15px"
  },
  setPointStyle: {
    color: "white",
    fontSize: "50px"
  },
  timeStyle: {
    color: "white",
    fontSize: "16px"
  },
  tempSymbolStyle: {
    color: "white",
    fontSize: "30px"
  },
  dividerStyle: {
    backgroundColor: alpha("#fdfdfe", 0.1)
  },
  controlSec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%"
  },
  scheduleStatusStyle: {
    color: "white",
    fontSize: "18px"
  },
  noSchedulesStyle: {
    fontSize: "30px",
    color: "white",
    marginTop: "20px",
    width: "100%",
    textAlign: "center"
  },
  longNamestyle: {
    maxWidth: "33ch",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    display: "inline-block"
  },
  rowContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    minHeight: "80px",
    paddingBottom: 16
  },
  setpointContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  inputWrapperStyle: {
    marginTop: 0,
    marginBottom: "10px",
    fontSize: "30px",
    "& *": { borderRadius: "6px" },
    "&  div": {
      border: "1px solid rgba(253, 253, 254, 0.3)"
    },
    "& input:focus + fieldset": {
      borderWidth: "1px !important"
    },
    "& input": {
      color: "white",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      padding: "16px 0px",
      paddingLeft: "20px"
    },
    "& label": {
      color: "white"
    }
  },
  removeLeftMargin: {
    marginLeft: 0
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },
  notEditable: {
    opacity: 0.6
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  editIcon: {
    width: 19,
    height: 20
  },
  bigIconBtnStyle: {
    width: 40,
    height: 40,
    borderRadius: 6,
    padding: 0
  },
  addIcon: {
    width: 40,
    height: 40,
    "& path": {
      stroke: "#fdfdfe"
    }
  },
  dialog: {
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  deleteDialogActions: {
    padding: 20,
    display: "flex",
    justifyContent: "center"
  },
  yesBtn: {
    marginRight: "17px"
  },
  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0
  },
  timeText: {
    color: "#fdfdfe",
    margin: "3px 0"
  }
}));
