import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles({
  icon: {
    marginLeft: "10px",
    color: "gray",
    width: "16px"
  },
  title: {
    color: "#ffffff",
    fontFamily: "Roboto",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: "normal",
    height: "70px"
  },
  greeting: {
    fontSize: "0.8125rem"
  },
  "@media screen and (max-width: 1888px)": {
    greeting: {
      display: "none"
    }
  },
  menuItem: {
    display: "flex",
    alignSelf: "flex-end",
    height: 36,
    padding: 0
  },
  menu: {
    "& >div": {
      display: "flex",
      alignSelf: "flex-end",
      top: "60px !important"
    }
  },
  hiddenItem: {
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: "normal",
    height: "0px",
    visibility: "hidden",
    padding: "0px"
  },
  userIcon: {
    marginRight: "5px",
    opacity: "0.5",
    fontSize: "30px"
  },
  userMenuContainer: {
    maxWidth: "30%",
    width: "fit-content"
  }
});
export default useStyles;
