import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  row: {
    display: "flex", flexFlow: "row nowrap",
    justifyContent: "space-between", alignItems: "center", padding: "0 20px", borderBottom: "1px solid #efeff2",
    height: 50,
    maxWidth: "100vw"
  },
  rowInfo: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 116px)"
  },
  rowTitle: {
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: theme.tables.tableCell.color,
    width: "6rem"
  },
  infoContainer: {
    height: "100%",
    padding: "1rem",
    width: "auto",
    overflow: "auto",
    flexFlow: "column nowrap"
  },
  detailsContainer: {
    marginTop: "2rem",
    marginBottom: "1rem"
  },
  description: {
    // marginLeft: "1rem",
    marginTop: "10px",
    color: "#545964",
    fontFamily: "Roboto",
    overflow: "auto",
    lineHeight: 1.63,
    fontSize: 16

  },
  descriptionTitle: {
    fontWeight: 500
  },
  resolveDescription: {
    marginLeft: "1rem",
    marginTop: "1rem",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  resolvedInfo: {
    border: "1px solid grey",
    borderRadius: "3px",
    marginBottom: "5px",
    width: "100%",
    padding: "5px"
  },
  saveBtn: { alignSelf: "flex-end", width: "40%", marginTop: "5px" },
  resolveTitle: {
    fontWeight: 500,
    alignSelf: "flex-start"
  },
  btn: {
    marginLeft: "1rem",
    marginTop: "2rem"
  },
  dialogTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  clearButton: {
    width: "40px",
    height: "40px"
  },
  dialogContent: {
    margin: "2rem"
  },
  alertHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  infoDialogStyle: {
    padding: 0,
    minWidth: "100%",
    height: "calc(100% - 83px)",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    margin: 0,
    borderRadius: 0,
    paddingBottom: 20,
    justifyContent: "space-between",
    background: "white"
  },
  emailDialogActions: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "30px"
  },
  emailDialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "30px 26px 27px 26px",
    maxHeight: "300px",
    overflow: "auto",
    minHeight: "235px"
  },
  cancelBtn: {
    color: "#4b1c46",
    backgroundColor: "#ffffff",
    width: "100px",
    marginRight: "20px"
  },
  sendBtn: {
    width: "100px"
  },
  emailPart: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between !important",
    alignItems: "center"
  },
  deleteTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e"
  },
  deleteDialog: {
    height: "15.625rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },

  deleteDialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0
  },
  deleteContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  deleteNoBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none"
  },
  deleteYesBtn: {
    color: "#4b1c46",
    width: "9rem",
    height: "2.625rem",
    border: "1px solid #421a40"
  },
  deleteDialogActions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "1rem"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#545964"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alertInfoDialogPaper: {
    width: "540px"
  },
  cancelFilter: { marginRight: "22px" },
  shareButton: {
    minHeight: "50px",
    maxHeight: "50px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },
  data: {
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#15111f",
    fontWeight: 600
  },
  alertDetails: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 600,
    color: "#4b1c46"
  },
  ackButtonContainer: {
    marginTop: "20px",
    marginBottom: "40px"
  },
  ackButton: {
    height: "40px !important",
    borderRadius: "6px !important",
    border: "solid 1px #4b1c46 !important"
  },
  ackButtonLable: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
  },
  ackButtonText: {
    fontSize: "16px",
    color: "#545964",
    textTransform: "capitalize"
  },
  ackButtonIcon: {
    fontSize: "19px",
    marginRight: "7px",
    color: "#00b259"
  },
  emailDialogStyle: {
    padding: 0,
    minWidth: "300px",
    width: "400px",
    maxWidth: "calc(100% - 100px)",
    minHeight: "300px",
    height: "auto",
    display: "flex",
    flexFlow: "column nowrap",
    maxHeight: "400px"
  },
  cutLongNames: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 100px)"
  },
  iosDialogHeight: {
    height: "calc(100% - 122px)",
  }
}));
export default useStyles;
