import { Collapse, IconButton, TextField, Typography } from "@material-ui/core";
import React from "react";

import { makeStyles, Theme } from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";
import clsx from "clsx";
import { AnyARecord } from "dns";

const style = makeStyles((theme: Theme) => ({
    row: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        height: "100%",
        transition: "width 1s",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center"
    },
    hide: {
        visibility: "hidden",
        opacity: 0,
        transition: "visibility 0s, opacity 0.5s linear"
    },
    info: {
        fontSize: "16px",
        paddingLeft: "7px"
    },
    infoBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "20%"
    },
    infoTitle: {
        fontSize: "10px",
        color: "#505050"
    }
}));

const InfoRow = (props: any) => {
    const classes = style();
    const {
        unit,
        isOpen,
        setUnit,
        edit
    } = props;

    const {
        name,
        serialNumber,
        model,
        capacity
    } = unit;

    return (
        <div
            className={clsx(classes.row, !isOpen && classes.hide)}
        >
            <IconButton style={{ width: "50px", height: "50px" }} onClick={(e: any) => edit(e, unit)}>
                <Edit />
            </IconButton>
            <div className={classes.infoBox}>
                <Typography className={classes.infoTitle}>{"Name:"}</Typography>
                <Typography className={classes.info}>{name}</Typography>
            </div>

            <div className={classes.infoBox}>
                <Typography className={classes.infoTitle}>{"Serial Number:"}</Typography>
                <Typography className={classes.info}>{serialNumber}</Typography>
            </div>

            <div className={classes.infoBox}>
                <Typography className={classes.infoTitle}>{"Model:"}</Typography>
                <Typography className={classes.info}>{model}</Typography>
            </div>

            <div className={classes.infoBox}>
                <Typography className={classes.infoTitle}>{"Capacity:"}</Typography>
                <Typography className={classes.info}>{capacity}</Typography>
            </div>
        </div>
    );

};

export default InfoRow;