import React from 'react';

function SvgNotifications(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="notifications_svg__a" d="M0 0h19.163v22H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1.1)">
        <mask id="notifications_svg__b" fill="currentColor">
          <use xlinkHref="#notifications_svg__a" />
        </mask>
        <path
          fill="currentColor"
          d="M17.479 17.46H1.685v-1.017a2.619 2.619 0 01.988-2.05l.415-.334c.63-.506.991-1.256.991-2.062v-3.87a5.509 5.509 0 015.503-5.502 5.508 5.508 0 015.5 5.502v3.87c0 .807.36 1.56.992 2.066l.418.337c.624.497.983 1.242.987 2.04v1.02zm-6.044 2.032a2.155 2.155 0 01-1.853 1.074h-.003a2.155 2.155 0 01-1.845-1.074l-.202-.347h4.103l-.2.347zm6.11-6.412l-.417-.335a.959.959 0 01-.36-.748v-3.87a7.19 7.19 0 00-5.793-7.049 1.436 1.436 0 00-2.783 0 7.188 7.188 0 00-5.797 7.049v3.87a.955.955 0 01-.36.748l-.416.335a4.311 4.311 0 00-1.62 3.353v.795c0 1.059.86 1.917 1.918 1.917h3.957a3.833 3.833 0 007.41 0h3.962c1.06 0 1.917-.858 1.917-1.917v-.785a4.313 4.313 0 00-1.618-3.363z"
          mask="url(#notifications_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgNotifications;
