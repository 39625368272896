import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import moment from "moment-timezone";
import { t } from "ttag";
import alertSeverities from "../constants/alertSeverities";
import urlFlagMap from "../constants/urlToFlagMapping";
import { checkDateInRange } from "../services/timeService";
import { IRootStoreModel } from "./RootStore";
const severties: any = alertSeverities;
export interface IAlert {
  id: string;
  type: string;
  eventTime: number;
  site: string;
  trigger: string | null;
  isRead: boolean;
  data: any;
  initiator: string | null;
  clearTime?: number;
  clearReason?: number;
  shortId: string;
  status: number;
}
export interface IAlertParseOptions {
  openAlertsOnly?: boolean;
  withAllOpenAlerts?: boolean;
  onlyInputAlerts?: boolean;
}

export interface IAlertMap {
  [key: string]: IAlert;
}

export interface IAlertsModel {
  allOpenAlerts: IAlertMap;
  allAlerts: IAlertMap;
  initialize: Action<IAlertsModel, any>;
  onInitialized: ActionOn<IAlertsModel, IRootStoreModel>;
  fetchUpdatedAlert: Thunk<IAlertsModel, { id: string }>;
  sendAlertEmail: Thunk<IAlertsModel, { id: string; emails: string[] }>;
  deleteAlert: Thunk<IAlertsModel, string>;
  getAlertsWithParams: Thunk<IAlertsModel, { status: any, type: any }>;
  setAcknowledgedEvent: Thunk<IAlertsModel, { id: string; data: any }>;
  _storeDeleteAlert: Action<IAlertsModel, string>;
  getAlertsPaginated: Thunk<
    IAlertsModel,
    {
      filter: string;
      id: string;
      page: number;
      rowsPerPage: number;
      startDate: any;
      endDate: any;
      types: any;
      codes: any;
      statuses: any;
    }
  >;
  _storeFetchUpdatedAlert: Action<IAlertsModel, { alertId: string; alert: IAlert }>;
  isResolved: Computed<IAlertsModel, (id: string) => boolean>;
  timeResolved: Computed<IAlertsModel, (id: string) => number | undefined>;
  getAlertsByFilter: Computed<
    IAlertsModel,
    (
      filter: {
        customerId?: string | null | undefined;
        siteId?: string | null | undefined;
        systemId?: string | null | undefined;
        unitId?: string | null | undefined;
      },
      showOpenAlertsOnly?: boolean,
      showBothAckAndOpen?: boolean,
      hideUnitDisconnected?: boolean
    ) => any,
    IRootStoreModel
  >;
  getAlertFeildsNamesAndIds: Computed<IAlertsModel, any>;

  parsedAlerts: Computed<IAlertsModel>;

  updateAlert: Thunk<IAlertsModel, { id: string; data: any }>;
  getAlertsByFilters: Thunk<IAlertsModel, { startTime?: any; endTime?: any, status?: any, type?: any, withAllOpenAlerts?: boolean, openAlertsOnly?: boolean }>;
  getNotificationAlerts: Thunk<IAlertsModel, { notificationShortId: string }>;
  updateAlertStore: Action<IAlertsModel, { id: string, data: any }>;
  createAutoAckRule: Thunk<IAlertsModel, any>;
  cancelAutoAckRule: Thunk<IAlertsModel, string>;
  closeEvent: Thunk<IAlertsModel, string>;
}

export const alertsModel: IAlertsModel = {
  allOpenAlerts: {},
  allAlerts: {},

  parsedAlerts: computed([
    (state, storeState: any) => storeState
  ],
    (state) => {
      return parseAlerts({}, {}, state);
    }),

  initialize: action((state, payload) => {
    const { events } = payload;
    state.allAlerts = { ...state.allAlerts, ...events };
  }),

  onInitialized: actionOn(
    (actions) => [actions.initialize],
    () => { }
  ),
  sendAlertEmail: thunk((actions, payload) => {
    return coolremoteSDK.Services.sendEventEmail({ emails: payload.emails }, payload.id);
  }),
  _storeDeleteAlert: action((state, payload) => {
    if (!state.allOpenAlerts[payload]) {
      return;
    }
    const { [payload]: _, ...otherAlerts } = state.allOpenAlerts;

    state.allOpenAlerts = otherAlerts;
  }),
  deleteAlert: thunk(async (actions, payload) => {
    await coolremoteSDK.Event.delete(payload);

    actions._storeDeleteAlert(payload);
  }),
  getAlertsWithParams: thunk(async (actions, payload) => {
    coolremoteSDK.Event.getEventsWithArrayParams({ status: payload.status, type: payload.type })
      .then((events: any) => {
        actions.initialize({ events });
      });
  }),
  setAcknowledgedEvent: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.setAcknowledgedEvent(id, data);
  }),
  updateAlert: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.update(id, data);
  }),
  getAlertsPaginated: thunk(async (actions, payload) => {
    const res = await coolremoteSDK.Event.getEventsPaginated(payload),
      alerts = res.retAlertObj,
      { length } = res,
      data = { alerts, length };
    return data;
  }),

  fetchUpdatedAlert: thunk(async (actions, payload) => {
    const alert = await coolremoteSDK.Event.fetch(payload.id);

    actions._storeFetchUpdatedAlert({ alertId: payload.id, alert });
  }),

  _storeFetchUpdatedAlert: action((state, payload) => {
    const { allOpenAlerts, allAlerts } = state;

    const { alert, alertId } = payload;
    if (alert.status === 1) {
      state.allOpenAlerts[alertId] = alert;
    } else {
      delete allOpenAlerts[alertId];
      state.allOpenAlerts = allOpenAlerts;
    }
  }),
  isResolved: computed([(state) => state.allOpenAlerts], (allOpenAlerts) => (id) => {
    if (_.isUndefined(allOpenAlerts[id]) || !_.isUndefined(allOpenAlerts[id].clearTime)) {
      return true;
    }
    else {
      return false;
    }
  }),

  timeResolved: computed([(state) => state.allOpenAlerts], (allOpenAlerts) => (id) => {
    if (_.isUndefined(allOpenAlerts[id])) {
      return undefined;
    }
    else {
      return allOpenAlerts[id].clearTime;
    }
  }),

  getAlertsByFilter: computed([
    (state) => state.parsedAlerts,
    (state, storeState: any) => storeState.types,
    (state, storeState: any) => storeState.units.allUnits
  ],

    (parsedAlerts, types, allUnits) => (filter, showOpenAlertsOnly = false, showBothAckAndOpen = true, hideUnitDisconnected = true) => {
      const { eventTypes } = types;
      const alertsToFilter = Object.values(parsedAlerts);
      const { customerId, siteId, systemId, unitId } = filter;
      return _.filter(_.values(alertsToFilter), (alert: any) => {
        if (showBothAckAndOpen && alert.status === "Closed") {
          return false;
        }

        if (showOpenAlertsOnly && alert.status !== "Open") {
          return false;
        }

        if (hideUnitDisconnected && alert.type === eventTypes.unitDisconnected) {
          return false;
        }

        if (!hideUnitDisconnected && alert.type === eventTypes.unitsDisconnected) {
          return false;
        }
        if (!hideUnitDisconnected && alert.type === eventTypes.scheduleFailures) {
          return false;
        }

        if (!!systemId && !alert.systemId) {
          return false;
        }

        if (!!unitId && alert.unitId !== unitId) {
          return false;
        }

        // if (!alert.isPowerMeter && !alert.isSensor && alert.unitId) {
        //   return false;
        // }

        if (!!systemId && (alert.systemId ? alert.systemId !== systemId : (alert.systemIds.length > 1 && !alert.systemIds.includes(systemId)))) {
          return false;
        }

        if (!!siteId && alert.siteId !== siteId) {
          return false;
        }

        if (!!customerId && alert.customer !== customerId) {
          return false;
        }

        return true;
      });

    }),
  getAlertFeildsNamesAndIds: computed(
    [(state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.eventTypes,
    (state, storeState: any) => storeState.types.unitTypes,
    (state, storeState: any) => storeState.sensors.allSensors,
    (state, storeState: any) => storeState.devices.allLines,
    (state, storeState: any) => storeState.powerMeters.allPowerMeters,
    (state, storeState: any) => storeState.sites.sitesFlags,
    (state, storeState: any) => storeState.groups.allGroups
    ],
    (allSites, allUnits, allDevices, allSystems, eventTypes, unitTypes, sensors, lines, powerMeters, sitesFlags, groups) => (alert: any) => {
      return mapAlertFields(allSites, allDevices, allSystems, allUnits, eventTypes, unitTypes, alert, sensors, lines, powerMeters, sitesFlags, groups);

    }),
  getAlertsByFilters: thunk(async (actions, { withAllOpenAlerts, openAlertsOnly, ...payload }: any = { withAllOpenAlerts: true, payload: "" }, { getStoreState }) => {
    const alerts = await coolremoteSDK.Event.getEvents(payload);
    return parseAlerts(alerts, { withAllOpenAlerts, openAlertsOnly }, getStoreState());
  }),
  getNotificationAlerts: thunk(async (actions, payload, { getStoreState }) => {
    const alerts: any = await coolremoteSDK.Services.getNotificationAlerts(payload.notificationShortId);
    return parseAlerts(alerts, { onlyInputAlerts: true }, getStoreState());
  }),
  updateAlertStore: action((state, payload) => {
    const { id, data } = payload;
    if (state.allAlerts[id]) {
      state.allAlerts[id] = { ...state.allAlerts[id], ...data };
    }
    if (state.allOpenAlerts[id]) {
      state.allOpenAlerts[id] = { ...state.allOpenAlerts[id], ...data };
    }

  }),
  createAutoAckRule: thunk(async (actions, payload: any) => {
    return coolremoteSDK.Event.createAutoAckRule(payload);
  }),

  cancelAutoAckRule: thunk(async (actions, payload: any) => {
    return coolremoteSDK.Event.cancelAutoAckRule(payload);
  }),
  closeEvent: thunk(async (actions, payload: any) => {
    return coolremoteSDK.Event.closeEvent(payload);
  })

};

const mapAlertFields = (sites: any, devices: any, systems: any, units: any, eventTypes: any, unitTypes: any, alert: any, sensors: any, lines: any, powerMeters: any, sitesFlags: any, groups: any) => {
  const { type, resources = [], status } = alert;

  const fieldsObj: any = { isSensor: false, eventNames: { siteName: "", deviceName: "", systemName: "", unitName: "", lineName: "" }, eventIds: { siteId: "", systemId: "", unitId: "", unitIds: [], systemIds: [] } };
  if (type === eventTypes.lowLineQualityError) {
    const line = lines[resources[0]?.id] || {};
    const site = sites[line?.site || ""];
    const device = devices[line?.device];

    fieldsObj.eventNames.siteName = site?.name;
    fieldsObj.eventIds.siteId = site?.id;
    fieldsObj.eventNames.deviceName = device?.name;
    fieldsObj.eventNames.deviceId = device?.id;
    fieldsObj.eventIds.systemIds = line?.systems;
    fieldsObj.eventNames.systemName = line?.systems ? line?.systems?.length === 1 ? systems[line?.systems[0]]?.name : "multiple" : "-";
    fieldsObj.eventNames.lineName = line?.id;

    return fieldsObj;
  }

  if ((type === eventTypes.customTelemetry || type === eventTypes.powerMeterDisconnected || type === eventTypes.operationalTelemetry) && powerMeters[resources[0]?.id]) {
    const powerMeter = powerMeters[resources[0]?.id];
    const { site, name, device, id } = powerMeter;
    const { name: siteName = "" } = sites[site] || {};
    const { name: deviceName = "" } = devices[device] || {};

    fieldsObj.eventNames.siteName = siteName;
    fieldsObj.eventIds.siteId = site;
    fieldsObj.eventNames.deviceName = deviceName;
    fieldsObj.eventNames.deviceId = device;
    fieldsObj.eventIds.systemIds = "externals";
    fieldsObj.eventNames.systemName = t`External Sources`;
    fieldsObj.eventNames.unitId = id;
    fieldsObj.eventNames.unitName = name;
    fieldsObj.isPowerMeter = true;
    return fieldsObj;
  }

  if (type === eventTypes.scheduleFailures) {
    if (units[resources[0]?.id]) {
      const item = units[resources[0]?.id];
      const { site = "", name, device = "", id, system = "" } = item;

      fieldsObj.eventIds.siteId = site;
      fieldsObj.eventIds.deviceId = device;
      fieldsObj.eventIds.systemId = system;
      fieldsObj.eventIds.unitId = id;
      fieldsObj.eventNames.siteName = sites[site]?.name;
      fieldsObj.eventNames.deviceName = devices[device]?.name;
      fieldsObj.eventNames.systemName = systems[system]?.name;
      fieldsObj.eventNames.unitName = name;
      fieldsObj.disableDoubleClick = true;
      return fieldsObj;
    }
    if (systems[resources[0]?.id]) {
      const item = systems[resources[0]?.id];
      const { site = "", name, device = "", id } = item;

      fieldsObj.eventIds.siteId = site;
      fieldsObj.eventIds.deviceId = device;
      fieldsObj.eventIds.systemId = id;
      fieldsObj.eventIds.unitId = "";
      fieldsObj.eventNames.siteName = sites[site]?.name;
      fieldsObj.eventNames.deviceName = devices[device]?.name;
      fieldsObj.eventNames.systemName = name;
      fieldsObj.eventNames.unitName = "";
      fieldsObj.disableDoubleClick = true;
      return fieldsObj;
    }
    if (sensors[resources[0]?.id]) {
      const item = sensors[resources[0]?.id];
      const { site = "", name, device = "", id } = item;

      fieldsObj.eventIds.siteId = site;
      fieldsObj.eventIds.deviceId = device;
      fieldsObj.eventIds.systemId = "externals";
      fieldsObj.eventIds.unitId = id;
      fieldsObj.eventNames.siteName = sites[site]?.name;
      fieldsObj.eventNames.deviceName = devices[device]?.name;
      fieldsObj.eventNames.systemName = t`External Sources`;
      fieldsObj.eventNames.unitName = name;
      fieldsObj.disableDoubleClick = true;
      return fieldsObj;
    }
    if (groups[resources[0]?.id]) {
      const item = groups[resources[0]?.id];
      const { site = "", name, id } = item;
      if (!item) {
        return null;
      }

      fieldsObj.eventIds.siteId = site;
      fieldsObj.eventIds.deviceId = "";
      fieldsObj.eventIds.systemId = "";
      fieldsObj.eventIds.unitId = id;
      fieldsObj.eventNames.siteName = sites[site]?.name;
      fieldsObj.eventNames.deviceName = "";
      fieldsObj.eventNames.systemName = "";
      fieldsObj.eventNames.unitName = name;
      fieldsObj.disableDoubleClick = true;
      return fieldsObj;
    }

    return null;
  }

  if (type === eventTypes.commercialAction) {
    return null;
  }

  if (((type === eventTypes.serviceAction || type === eventTypes.controlAction) && resources.length === 0)) {
    const site = sites[alert.site || ""];
    fieldsObj.eventNames.siteName = site?.name;
    fieldsObj.eventIds.siteId = site?.id;
    return fieldsObj;
  }
  if (resources.length === 0) { return fieldsObj; }

  const { eventNames, eventIds } = fieldsObj;
  //device disconnected
  if (eventTypes.deviceDisconnected === type || eventTypes.deviceHealthLowIstat === type || eventTypes.deviceHealthLowOstat === type || eventTypes.deviceHealthLowPstat === type || eventTypes.deviceHealthLowLqstat === type || eventTypes.deviceHealthLowSstat === type) {
    const device = devices[resources[0].id];
    eventNames.deviceName = device ? device.serial : (resources[0]?.name || "");
    eventNames.siteName = (device && device.site && sites[device.site]?.name) || "";
    eventIds.siteId = device ? device.site : "";
    if (status === 1) { eventNames.unitName = eventNames.deviceName; }
    return fieldsObj;
  }

  let siteId = "";
  let deviceName = "";
  let isSensor = resources[0] && resources[0].type;
  let items: any = isSensor ? sensors : units;

  for (let i in resources) {
    const { id = "" } = resources[i];
    const storeUnit: any = items[id];

    if (storeUnit) {
      let { site = "", system = "", device = "" } = storeUnit;
      if (!isSensor && storeUnit.type === 1 && storeUnit.serviceUnits.length) {
        const serviceUnit = units[storeUnit.serviceUnits[0]];
        system = serviceUnit ? serviceUnit.system : "";
      }

      if (!siteId) {
        siteId = site;
      }

      if (!deviceName) {
        deviceName = devices[device]?.serial || "";
      }

      (!isSensor && system && !eventIds.systemIds.includes(system)) && eventIds.systemIds.push(system);
      eventIds.unitIds.push(id);
    }
  }

  if (_.isEmpty(eventIds.unitIds)) {
    return fieldsObj;
  }

  const unitId = eventIds.unitIds.length === 1 ? eventIds.unitIds[0] : "";
  const firstUnitId = eventIds.unitIds[0];
  const isServiceSite = sitesFlags[siteId]?.isServiceSite;
  const firstUnit = items[firstUnitId];
  const smartAlertsTypes: any = {
    206: true,
    209: true,
    210: true,
    208: true
  };

  if (!smartAlertsTypes[type] && !isSensor && !anomliesTypes[type] && ((isServiceSite && firstUnit.type === unitTypes.indoor && firstUnit?.serviceUnits?.length > 0) || (!isServiceSite && firstUnit?.type !== unitTypes.indoor))) {
    return null;
  }

  const systemId = eventIds.systemIds.length === 1 ? eventIds.systemIds[0] : "";

  let unitName = "";
  if (unitId) {
    const item = items[unitId];
    unitName = item?.name || "";
    if (+item?.type === 3) {
      const controlUnitId: any = item?.controlUnit;
      const controlName = items[controlUnitId]?.name || `Unassigned`;
      unitName = `${controlName} (${item.address})`;
    }

  } else {
    unitName = "multiple";
  }

  eventNames.unitName = unitId ? unitName : "multiple";
  eventNames.systemName = systemId ? systems[systemId]?.name : _.isEmpty(eventIds.systemIds) ? "" : "multiple";
  eventIds.unitId = unitId;
  eventIds.systemId = systemId;
  eventNames.siteName = sites[siteId]?.name || "";
  eventNames.deviceName = deviceName;
  eventIds.siteId = siteId;
  fieldsObj.isSensor = isSensor;
  return fieldsObj;

};

const parseAlerts = (additionalAlerts: any = {}, alertParseOptions: IAlertParseOptions, state: any) => {

  const userPref = state.users.userPreferences;

  if (!state.alerts)
    return {};
  const { openAlertsOnly = false, withAllOpenAlerts = true, onlyInputAlerts = false } = alertParseOptions || {};
  const { allAlerts: allAlertsState, getAlertFeildsNamesAndIds } = state.alerts || {};
  const errorTypes = state.serviceErrorTypes;
  const allCustomers = state.customers?.allCustomers;
  const { allSites, sitesFlags } = state.sites;
  const allUnits = state.units?.allUnits;
  const allSystems = state.systems?.allSystems;
  const { eventTypes, eventStatusTypes, eventTypeStrings, eventTypesToSeverityMapping } = state.types || {};
  const eventStatusTypesMirror = state.eventStatusTypesMirror;
  const eventClearTypesMirror = state.eventClearTypesMirror;
  const { timeFormat, dateFormat, users: allUsers } = state.users || {};
  const eventTypesMirror = state.eventTypesMirror;
  const selections = state.selections?.selections;

  const allAlerts = onlyInputAlerts ? { ...additionalAlerts } : { ...allAlertsState, ...additionalAlerts };
  const parsedAlerts: any = Object.create(allAlerts);
  Object.values(allAlerts).forEach((alert: any) => {
    const alertData = getAlertFeildsNamesAndIds(alert);
    if (!alertData) {
      return;
    }
    if (openAlertsOnly && eventStatusTypesMirror[alert.status] !== "Open") {
      return;
    }
    const { eventNames, eventIds, isSensor, isPowerMeter } = alertData;
    const isUnitsDisconnectedType = eventTypes.unitsDisconnected === alert.type;
    const isLowLineQualityError = eventTypes.lowLineQualityError === alert.type;
    const isUnitDisconnectedType = eventTypes.unitDisconnected === alert.type;
    const isEntitiesNoDateType = eventTypes.entitiesHealthLowIstat === alert.type
      || eventTypes.entitiesHealthLowOstat === alert.type
      || eventTypes.entitiesHealthLowPstat === alert.type
      || eventTypes.entitiesHealthLowLqstat === alert.type
      || eventTypes.entitiesHealthLowSstat === alert.type;

    const isEntityNoDataType = eventTypes.entityHealthLowIstat === alert.type
      || eventTypes.entityHealthLowOstat === alert.type
      || eventTypes.entityHealthLowPstat === alert.type
      || eventTypes.entityHealthLowLqstat === alert.type
      || eventTypes.entityHealthLowSstat === alert.type;

    //error is here
    if ((!eventIds.siteId && alert.type !== 204) || isUnitDisconnectedType || isEntityNoDataType || isEntitiesNoDateType) {
      return;
    }
    const timezone = allSites[eventIds.siteId]?.timezone || moment.tz.guess();
    if (!timezone) {
      return;
    }

    const pageFlag = urlFlagMap.alerts;
    if (sitesFlags[eventIds?.siteId]?.[pageFlag] !== true) { //hasAlertsLog
      return;
    }

    if (!withAllOpenAlerts && !checkDateInRange(moment(selections?.dateRange?.startDate).format("MM/DD/YYYY"),
      moment(selections?.dateRange?.endDate).format("MM/DD/YYYY"),
      moment(alert.eventTime).tz(timezone).format("MM/DD/YYYY"))
    ) {
      return;
    }

    const severity = severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]];
    const alertTypesHidden: any = {
      // 206: true,
      // 209: true,
      // 210: true,
      // 208: true
    };

    if (!severity || alertTypesHidden[alert.type]) {
      return;
    }

    const { data, type } = alert;
    const errorCode = eventTypes.indoorUnitError === type ||
      eventTypes.outdoorUnitError === type ? data : "";
    let errorDescription = "";

    if (errorCode || errorCode === 0) {
      const brandNum = allSystems[eventIds.systemId]?.brandNum || allUnits[eventIds.unitId]?.brand;
      errorDescription = getErrorDescription(errorTypes.errorCodeTypes, brandNum, errorCode);
      errorDescription = errorDescription;
    }
    if (isLowLineQualityError) {
      errorDescription = t`VRF communication line is too noisy. It may impact system controls.

      Potential causes may be improper/loose terminal connection, cut wire, wrong polarity, malfunction, electromagnetic radiation, wrong DIP switch configuration on the device, a problem with indoor unit communication, or others.`;
    }

    if (!!allUnits[eventIds.unitId]) {
      const controlUnitId = allUnits[eventIds.unitId]?.controlUnit;
      const alertUnit = allUnits[eventIds.unitId];
      if (!alertUnit?.isVisible || (!!allUnits[controlUnitId] && !allUnits[controlUnitId]?.isVisible)) {
        return;
      }
    }
    const status = eventStatusTypesMirror[alert.status] || eventStatusTypesMirror[eventStatusTypes[alert.status?.charAt(0)?.toLowerCase() + alert.status?.slice(1)]] || "";

    parsedAlerts[alert.id] = {
      ...alert,
      type: alert.type,
      acknowledgedTime: alert.acknowledgedTimestamp ? moment(alert.acknowledgedTimestamp).tz(timezone).format(`${dateFormat}   ${timeFormat}`) : false,
      time: moment(alert.eventTime)?.tz(timezone)?.format(`${dateFormat}   ${timeFormat}`),
      clearTime: alert?.clearTime ? moment(alert.clearTime)?.tz(timezone)?.format(`${dateFormat}   ${timeFormat}`) : null,
      executeTime: alert.type === 204 ? moment(alert.eventTime - 60 * 4 * 1000)?.tz(timezone)?.format(`${dateFormat}   ${timeFormat}`) : "",
      status,
      alertType: eventTypeStrings[alert.type],
      siteName: eventNames.siteName,
      unitName: eventNames.unitName || "-",
      deviceName: eventNames.deviceName,
      lineName: eventNames?.lineName,
      systemName: eventNames.systemName || "-",
      unitIds: eventIds.unitIds,
      systemId: eventIds.systemId,
      customerName: (userPref?.nicknames && userPref.nicknames[alert.customer]) || allCustomers[alert.customer]?.name || "",
      clearReason: (alert.clearReason && eventClearTypesMirror[alert.clearReason]) || "",
      userText: !isEntitiesNoDateType ? alert.userText : "",
      shortId: alert.shortId,
      alertItemContainerIds: { ...eventIds, customerId: alert.customer },
      description: alert.trapDescription,
      errorCode: isUnitsDisconnectedType || isEntitiesNoDateType || isLowLineQualityError ? "" : alert.data,
      timestamp: alert.eventTime,
      severity,
      trapTemplateId: alert?.trapTemplateId || "",
      id: alert.id,
      errorDescription,
      alertTypeVal: alert?.type,
      timezone,
      ...eventIds,
      isSensor,
      isPowerMeter,
      acknowledgedDescription: alert?.acknowledgedDescription,
      autoAcknowledgeId: alert?.autoAcknowledgeId,
      acknowledgedActor: allUsers[alert?.acknowledgedActorId]?.username,
      closeActor: allUsers[alert?.closeActorId]?.username,
      trap: alert?.trap || null,
      priority: alert?.priority
    };

  });

  return parsedAlerts;

};

const getErrorDescription = (errorCodeTypes: any = {}, brand: any, errorCode: any) => {
  if (!brand && brand !== 0) {
    return "";
  }

  const brandsErrors = errorCodeTypes[brand] || {};
  if (_.isEmpty(brandsErrors)) {
    return "";
  }

  return brandsErrors[errorCode] || brandsErrors[+errorCode] || "";
};

const anomliesTypes: any = { 1: true, 50: true, 201: true, 202: true };
