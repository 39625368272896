import * as React from 'react';

function SvgStar(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M11.577 2.33a1.303 1.303 0 011.591.659h0l2.382 4.88 5.374.789c.355.052.656.243.854.51.199.267.296.61.243.965-.04.28-.172.539-.373.737h0l-3.9 3.842.916 5.396a1.298 1.298 0 01-1.892 1.365h0L12 18.937l-4.772 2.536a1.295 1.295 0 01-1.758-.537 1.3 1.3 0 01-.134-.828h0l.915-5.396-3.899-3.842a1.297 1.297 0 01-.013-1.838c.198-.202.457-.333.737-.374h0l5.374-.79 2.382-4.88c.157-.322.43-.55.745-.658z"
        stroke="#AAA2AA"
        strokeWidth={1.4}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgStar;
