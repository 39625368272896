import clsx from "clsx";
import React from "react";
import assetsService from "../../services/assetsService";
import styles from "./Loader.module.css";
import useStyles from "./Loading.style";

export default function Loading(props: any) {
  const classes = useStyles();
  const { mobile = false } = props;

  return (
    <div className={classes.loading}>
      <div className={classes.main}>
        <div className={classes.logos}>
          {window.location.href.includes("airconnectpro") ?
            <>
              <img
                alt="logo"
                src={assetsService.getResourcePath("logo_emblem.png")}
                className={clsx(classes.logo, { [classes.mobileLogo]: mobile })}
              />
              <img
                alt="logo title"
                src={assetsService.getResourcePath("logo_title.png")}
                className={classes.logoTitle}
              />
            </> :
            <>
              <div id="loader" className={styles.redMainLoader}>
              </div>
              <div className={classes.logoContainer}>
                <img
                  alt="logo"
                  src={assetsService.getResourcePath("logo_emblem.png")}
                  className={clsx(classes.logo1, { [classes.mobileLogo1]: mobile })}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.style.display = "none";
                  }}
                />
                <img
                  alt="logo title"
                  src={assetsService.getResourcePath("logo_title.png")}
                  className={clsx({ [classes.mobileLogo2]: mobile })}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.style.display = "none";
                  }}
                />
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}
